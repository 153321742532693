import React from 'react';
import { language } from 'constants/language-constants';
import { Lang } from 'models/language/Language';
import { Select } from 'components/Select';
import { IconComponentName } from 'components/SvgIconReact/IconNames';
import { SelectLanguageProps } from './SelectLanguage.model';

export const SelectLanguage: React.FC<SelectLanguageProps> = ({
  id,
  className,
  selectLanguageLabel = '',
  selectedLanguage,
  onChangeLanguage,
}) => {
  const flagIcon = language.flags[selectedLanguage];

  const selectOptions = {
    defaultValue: language.values[selectedLanguage],
    options: [
      {
        label: language.labels.en,
        value: language.values.en,
      },
      {
        label: language.labels.nl,
        value: language.values.nl,
      },
    ],
  };

  return (
    <Select
      ariaLabel="select-language-side-menu"
      className={className}
      dataCy="language-selector"
      defaultValue={selectOptions.defaultValue}
      iconName={flagIcon as IconComponentName}
      id={id}
      label={selectLanguageLabel}
      options={selectOptions.options}
      value={selectedLanguage}
      onChange={(event: React.ChangeEvent<HTMLSelectElement>) => onChangeLanguage(event.target.value as keyof Lang)}
    />
  );
};
