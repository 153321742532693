import styled from 'styled-components/macro';

import { ThemeProps as Props } from 'models/styles/theme';

import { CssGrid } from 'styles/helpers/layout';
import { Paragraph } from 'styles/elements/Texts';
import { Heading3 } from 'styles/elements/Headings';

export const StyledCardLayout = styled(CssGrid)<Props>`
  width: 100%;
  height: 100%;

  gap: 1rem;
  padding: 2.5rem;
  align-items: center;
  justify-content: start;

  grid-template-columns: 3rem 1fr;

  ${Heading3}, ${Paragraph} {
    margin: 0;
  }
`;
