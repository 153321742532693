import styled from 'styled-components/macro';

import { calculateRem } from 'styles/mixins/mixins';

import { ThemeProps as Props } from 'models/styles/theme';

import { Button } from 'components/Button';
import { FontAwesomeIcon } from 'components/FontAwesomeIcon';
import { CountdownTimer } from 'components/CountdownTimer/CountdownTimer';

interface RowProps extends Props {
  $isOdd: boolean;
}

export const StyledRow = styled.tr<RowProps>`
  background-color: ${({ $isOdd, theme }: RowProps) =>
    $isOdd ? theme.palette.neutral._100 : theme.palette.neutral.white};
`;

export const StyledReviewPeriodNotice = styled.div<Props>`
  display: flex;
  column-gap: 1rem;
  align-items: center;

  padding: ${calculateRem(12)};
  border-radius: ${calculateRem(2)};
  background: ${(props: Props) => props.theme.palette.accent._01};
`;

export const StyledMessage = styled.span<Props>`
  flex-grow: 1;
  font-weight: bold;
`;

export const StyledCountdownTimer = styled(CountdownTimer)<Props>`
  color: ${(props: Props) => props.theme.palette.secondary.brand};
  font-weight: bold;
`;

export const StyledButton = styled(Button)<Props>`
  padding: 0.25rem 1rem;
  font-size: ${calculateRem(14)};
  font-weight: bold;
`;

export const StyledCollapsibleIcon = styled(FontAwesomeIcon)<Props>`
  padding: 1rem;
  color: ${(props: Props) => props.theme.palette.neutral._600};

  :hover {
    border-radius: 50%;
    background-color: ${(props: Props) => props.theme.palette.neutral._200};
  }
`;

export const StyledScoreContainer = styled.div<Props>`
  display: flex;
  column-gap: 1rem;
  align-items: center;
  justify-content: space-between;
`;
