import styled from 'styled-components/macro';
import { ThemeProps } from 'models/styles/theme';
import { calculateRem } from 'styles/mixins/calculateRem';
import { Heading2, Heading3 } from 'styles/elements/Headings';

export const StyledUserDetailsPage = styled.div`
  margin-right: 32px;
  width: 100%;
`;

export const StyledUserUpdate = styled.div<ThemeProps>`
  border: 1px solid ${({ theme }: ThemeProps) => theme.palette.neutral._400};
  margin: ${calculateRem(44)} 0 ${calculateRem(72)};
  padding: ${calculateRem(32)} ${calculateRem(60)};
  width: 100%;

  ${Heading2} {
    font-weight: ${({ theme }: ThemeProps) => theme.font.weight.bold};
  }

  ${Heading3} {
    font-weight: ${({ theme }: ThemeProps) => theme.font.weight.bold};
    margin-top: ${calculateRem(64)};
  }
`;

export const StyledUserBasicInfo = styled.div<ThemeProps>`
  display: flex;
  flex-direction: column;
`;
