import styled from 'styled-components/macro';
import { ElearningThemeInterface } from 'themes/elearningInterface.theme';
import { calculateRem } from 'styles/mixins/mixins';

import { Heading6 } from 'styles/elements/Headings';
import { Box } from 'styles/helpers/layout';

interface Props {
  theme: ElearningThemeInterface;
}

export const StyledChapterOverview = styled(Box)<Props>`
  .accordion-content-body {
    display: grid;
    row-gap: 1rem;
  }
`;

export const StyledChapterTitle = styled(Heading6)`
  font-size: 1rem;
  font-weight: ${(props: Props) => props.theme.font.weight.bold};
  color: ${(props: Props) => props.theme.palette.neutral._900};
  margin-bottom: 0;
`;

export const StyledProgressPercentage = styled.b<Props>`
  width: 2rem;
  display: inline-block;
  margin: 0 0.25rem 0 0.75rem;
  font-size: ${(props: Props) => props.theme.font.size._12};
`;

export const TopicButton = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: auto;
  gap: 0px 0px;
  height: ${calculateRem(40)};
  align-items: center;
  grid-template-areas: 'Name Level';
  padding: 0 1.5rem 2rem 4rem;

  &:hover {
    cursor: pointer;
    background-color: ${(props: Props) => props.theme.palette.neutral._100};
  }

  &.disabled {
    color: ${(props: Props) => props.theme.palette.neutral._600};
    cursor: not-allowed;
    background-color: transparent;
  }
`;
