import styled from 'styled-components/macro';

import { ThemeProps as Props } from 'models/styles/theme';
import { calculateRems } from 'styles/mixins/calculateRem';

import { CardStatus } from 'components/CardStatus/CardStatus';
import { FontAwesomeIcon } from 'components/FontAwesomeIcon';

export const StyledWritingSubject = styled.div<Props>`
  text-align: start;
`;

export const StyledCardStatus = styled(CardStatus)<Props>`
  height: fit-content;
  min-height: 6rem;
  column-gap: 1rem;
  grid-template-columns: 4fr 1fr 1fr;

  button {
    width: 9rem;
    padding: ${calculateRems([11.2, 8])};
    font-size: ${(props: Props) => props.theme.font.size._14};
    font-weight: ${(props: Props) => props.theme.font.weight.bold};
  }

  h3,
  p {
    margin-bottom: 0;
  }
`;

export const StyledTitleContainer = styled.div`
  display: flex;
  column-gap: 0.5rem;
  align-items: center;
`;

export const StyledCompletedIcon = styled(FontAwesomeIcon)<Props>`
  color: ${(props: Props) => props.theme.palette.accent._04};
`;

export const StyledProgressContainer = styled.div<Props>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  font-size: 1rem;
  font-weight: normal;
  color: ${(props: Props) => props.theme.palette.neutral.black};

  > span:first-child {
    font-size: 1.1rem;
  }

  > span:last-child {
    font-size: 0.7rem;
    text-align: center;
  }
`;
