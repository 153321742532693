import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

import { useAdmin } from 'hooks/useAdmin';

import { Variant } from 'components/Button/Button.model';
import { copyTextToClipboard } from '_helpers/clipboard';
import { Button } from 'components/Button';

interface SimulateLoginButtonProps {
  accountId: number;
  activeStateVariant: Variant;
  variant: Variant;
}

export const SimulateLoginButton: React.FC<SimulateLoginButtonProps> = observer(
  ({ accountId, activeStateVariant, variant }) => {
    const { t } = useTranslation('admin-environment');
    const { simulateLoginState, simulateLoginAsTeacher, loading, setSimulateLoginState } = useAdmin();

    useEffect(
      () => () => {
        setSimulateLoginState(null);
      },
      [],
    );

    // Only show copy link button is currently loaded login link belongs to selected account
    if (simulateLoginState && simulateLoginState.userId === accountId) {
      return (
        <Button
          data-class="modal-tooltip"
          data-for="login-as-teacher-tooltip"
          data-tip=""
          dataCy="copy-login-link-button"
          variant={activeStateVariant}
          onClick={() => copyTextToClipboard(simulateLoginState.loginURL)}
        >
          {t('header.copyLoginLink.button.label', 'Click to copy the login link')}
        </Button>
      );
    }

    return (
      <Button
        data-class="modal-tooltip"
        data-for="login-as-teacher-tooltip"
        data-tip=""
        dataCy="login-as-teacher-button"
        disabled={loading}
        variant={variant}
        onClick={() => accountId && simulateLoginAsTeacher({ id: accountId })}
      >
        {t('header.loginAsTeacher.button.label', 'Login as teacher')}
      </Button>
    );
  },
);
