import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import { useProfile } from 'hooks/useProfile';

import { RESULT_PAGE_ROUTES } from 'constants/routes';
import { TestType } from 'constants/exam-constants';

import { NavigationMenu } from 'components/NavigationMenu/NavigationMenu';
import { NavigationMenuItem } from 'components/NavigationMenu/NavigationMenu.model';

import { TestEnvMenuSection } from 'layouts/TestEnvMenuSection';
import { HogesLegacyURL } from 'constants/registration-links';
import { serviceENV as getCurrentEnvironment, EnvironmentType } from 'constants/env-constants';
import { buildUrlWithPathParams } from '_helpers/utils/urlBuilder';
import { useFeatureFlags } from 'hooks/useFeatureFlags';

interface ComponentProps {
  className?: string;
  dataCy?: string;
}

export const NoProductsOwnedNavigationMenu: React.FC<ComponentProps> = observer(({ className, dataCy }) => {
  const { t } = useTranslation('homepage');
  const currentEnv = getCurrentEnvironment() as EnvironmentType;
  const { userDetails } = useProfile();
  const { showLegacyResultsButton } = useFeatureFlags();

  const menuItems = useMemo<NavigationMenuItem[]>(
    () => [
      {
        id: 'result-list',
        dataCy: 'result-list',
        expandedByDefault: true,
        title: t('sideMenu.results.title', 'Results'),
        subItems: [
          {
            id: 'results-formative-test',
            dataCy: 'results-formative-test',
            title: t('sideMenu.results.items.formativeTest', 'Formative Test'),
            redirectPath: buildUrlWithPathParams(RESULT_PAGE_ROUTES.TEST_TYPE_RESULTS_LIST, {
              testType: TestType.FORMATIVE_TEST,
            }),
          },
          {
            id: 'results-summative-test',
            dataCy: 'results-summative-test',
            title: t('sideMenu.results.items.summativeTest', 'Summative Test'),
            redirectPath: buildUrlWithPathParams(RESULT_PAGE_ROUTES.TEST_TYPE_RESULTS_LIST, {
              testType: TestType.SUMMATIVE_TEST,
            }),
          },
          {
            id: 'results-freebie-test',
            dataCy: 'results-freebie-test',
            title: t('sideMenu.results.items.freebieTest', 'Intake Test'),
            redirectPath: buildUrlWithPathParams(RESULT_PAGE_ROUTES.TEST_TYPE_RESULTS_LIST, {
              testType: TestType.FREEBIE_TEST,
            }),
          },
          {
            id: 'certificate-results',
            dataCy: 'certificate-results',
            title: t('sideMenu.results.items.certificate', 'Certificates'),
            redirectPath: RESULT_PAGE_ROUTES.OBTAINED_CERTIFICATES,
          },
          ...(showLegacyResultsButton && userDetails?.isMigratedUser
            ? [
                {
                  id: 'old-results',
                  dataCy: 'legacy-hoges-link',
                  title: t('sideMenu.results.items.legacyResults', 'Old Results'),
                  redirectPath: HogesLegacyURL[currentEnv],
                },
              ]
            : []),
        ],
      },
    ],
    [t],
  );

  return (
    <aside>
      <NavigationMenu className={className} dataCy={dataCy} menuItems={menuItems} />
      <TestEnvMenuSection />
    </aside>
  );
});
