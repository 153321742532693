import * as Yup from 'yup';
import i18n from 'i18n';
import { GenericValidator } from 'pages/TeacherEnvironment/TeacherDashboard/subpages/ScheduledTestWizard/validate';

export const createValidationSchema = (): GenericValidator =>
  Yup.object({
    title: Yup.string()
      .label(i18n.t('test-request-form:formFields.title'))
      .required(({ label }) => i18n.t('test-request-form:formErrors.required', { label }))
      .defined(),
    testId: Yup.number()
      .label(i18n.t('test-request-form:formFields.testId'))
      .min(1, ({ label }) => i18n.t('test-request-form:formErrors.requiredSelect', { label }))
      .defined(),
  });
