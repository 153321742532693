import styled, { css } from 'styled-components/macro';

import { LoadingSpinnerWrapper } from 'components/LoadingSpinner/LoadingSpinner.styled';
import { ThemeProps } from 'models/styles/theme';

interface StyledTextQuizTextProps extends ThemeProps {
  $isExpanded: boolean;
}

const textPreviewHeight = 200;

export const StyledReviewExercisePage = styled.div`
  margin: 0 auto;
  max-width: 1136px;
  min-height: calc(100vh - 80px);
  display: flex;
  flex-direction: column;
  ${LoadingSpinnerWrapper} {
    margin: 0 auto;
  }
`;

const getFoldedCss = () => css`
  max-height: ${textPreviewHeight}px;
  overflow: hidden;
  &::after {
    display: block;
    content: '';
    position: absolute;
    width: 100%;
    text-align: center;
    margin: 0;
    padding: ${textPreviewHeight * 0.75}px 0 0 0;
    top: ${textPreviewHeight * 0.25}px;
    background-image: linear-gradient(to bottom, transparent, white);
  }
`;

export const StyledTextQuizContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid ${({ theme }: ThemeProps) => theme.palette.neutral._400};
`;

export const StyledTextQuizText = styled.div<StyledTextQuizTextProps>`
  position: relative;
  ${({ $isExpanded }) => !$isExpanded && getFoldedCss()}
`;

export const StyledAnswersContainer = styled.div`
  display: flex;
  flex-direction: column;
  column-gap: 2rem;
  ${StyledTextQuizText} {
    padding: 0.5rem 1rem;
  }
`;
