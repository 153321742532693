import { AbsolutePositionedView } from 'components/AbsolutePositionedView/AbsolutePositionedView';
import { ModalRenderType } from 'components/Modal/Modal.model';
import React, { ReactElement } from 'react';
import ReactDOM from 'react-dom';
import { CorrectTextQuestionOptionList } from './CorrectTextQuestionOptionList';
import { CorrectTextQuestionOptionListProps } from './CorrectTextQuestionOptionList.model';

interface Props extends CorrectTextQuestionOptionListProps {
  isActive?: boolean;
  x?: number;
  y?: number;
  element?: Element;
  key?: string | null;
  modalRenderType: ModalRenderType;
}

const CorrectTextQuestionOptionListPortal = React.forwardRef<HTMLDivElement, Props>(
  ({ isActive = true, element = document.body, x = 0, y = 0, key, modalRenderType, ...props }, ref): ReactElement => {
    const domElement: ReactElement = (
      <AbsolutePositionedView ref={ref} x={x} y={y}>
        <div style={{ display: isActive ? 'block' : 'none' }}>
          <CorrectTextQuestionOptionList {...(props as CorrectTextQuestionOptionListProps)} />
        </div>
      </AbsolutePositionedView>
    );

    return modalRenderType === ModalRenderType.INLINE ? domElement : ReactDOM.createPortal(domElement, element, key);
  },
);
CorrectTextQuestionOptionListPortal.displayName = 'CorrectTextQuestionOptionListPortal';

export { CorrectTextQuestionOptionListPortal };
