import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDomainHandler } from 'hooks/useDomainHandler';

import { DatePicker } from 'components/DatePicker/DatePicker';
import { TimePicker } from 'components/TimePicker/TimePicker';
import { FormH4Label } from 'pages/TeacherEnvironment/TeacherDashboard/subpages/ScheduledTestWizard/steps/FormStep.styled';
import { Paragraph } from 'styles/elements/Texts';

import { dateToTimePickerString } from '../../utils';
import { CustomReviewDateProps } from './CustomReviewDate.model';
import {
  CustomReviewDateColumn,
  CustomReviewDateRow,
  InputSeparator,
  CustomReviewDateForm,
} from './CustomReviewDate.styled';

export const CustomReviewDate: React.FC<CustomReviewDateProps> = ({
  dataCy,
  hasError,
  language,
  reviewStartDateValue,
  reviewEndDateValue,
  isDisabled = false,
  isEditingScheduledTest = false,
  hideParagraph = false,
  alignment = 'horizontal',
  onStartDateUpdate,
  onEndDateUpdate,
}) => {
  const { t } = useTranslation('scheduled-tests');
  const { getStudentTermByDomain } = useDomainHandler();

  const onUpdateCustomReviewDate = (
    propName: 'reviewStartDate' | 'reviewEndDate',
    props: { date?: Date; hours?: number; minutes?: number },
  ) => {
    if (propName === 'reviewStartDate') {
      onStartDateUpdate(props);
    } else {
      onEndDateUpdate(props);
    }
  };

  return (
    <CustomReviewDateForm data-cy={dataCy}>
      {!hideParagraph && (
        <Paragraph>
          <Trans
            i18nKey="testDateStep.customReviewDate.form.intro"
            t={t}
            values={{ studentTerm: getStudentTermByDomain({ usePlural: true }) }}
          >
            {'Students can automatically view their test up to 24 hours after taking it. Adjust the '}
            <b>inspection session</b>
            {' below:'}
          </Trans>
        </Paragraph>
      )}
      <CustomReviewDateRow alignment={alignment}>
        <CustomReviewDateColumn>
          <div>
            <FormH4Label>{t('testDateStep.customReviewDate.form.fields.startDate')}</FormH4Label>
            <DatePicker
              dataCy="review-start-date-picker"
              disabled={isDisabled}
              hasError={hasError}
              language={language}
              minDate={!isEditingScheduledTest ? new Date() : null}
              value={reviewStartDateValue}
              onChange={date => onUpdateCustomReviewDate('reviewStartDate', { date })}
            />
          </div>
          <div>
            <FormH4Label>{t('testDateStep.customReviewDate.form.fields.startTime')}</FormH4Label>
            <TimePicker
              dataCy="review-start-time-picker"
              disabled={isDisabled}
              hasError={hasError}
              language={language}
              value={dateToTimePickerString(reviewStartDateValue)}
              onChange={(hours, minutes) => onUpdateCustomReviewDate('reviewStartDate', { hours, minutes })}
            />
          </div>
        </CustomReviewDateColumn>
        <InputSeparator>
          <span>{t('testDateStep.customReviewDate.form.until', 'until')}</span>
        </InputSeparator>
        <CustomReviewDateColumn>
          <div>
            <FormH4Label>{t('testDateStep.customReviewDate.form.fields.endDate')}</FormH4Label>
            <DatePicker
              dataCy="review-end-date-picker"
              disabled={isDisabled}
              hasError={hasError}
              language={language}
              minDate={!isEditingScheduledTest ? new Date() : null}
              value={reviewEndDateValue}
              onChange={date => onUpdateCustomReviewDate('reviewEndDate', { date })}
            />
          </div>
          <div>
            <FormH4Label>{t('testDateStep.customReviewDate.form.fields.endTime')}</FormH4Label>
            <TimePicker
              dataCy="review-end-time-picker"
              disabled={isDisabled}
              hasError={hasError}
              language={language}
              value={dateToTimePickerString(reviewEndDateValue)}
              onChange={(hours, minutes) => onUpdateCustomReviewDate('reviewEndDate', { hours, minutes })}
            />
          </div>
        </CustomReviewDateColumn>
      </CustomReviewDateRow>
    </CustomReviewDateForm>
  );
};
