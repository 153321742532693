import {
  WidgetUserRoles,
  SupportWidgetConfig,
  SupportWidgetVariant,
  SupportWidgetVariantConfig,
  isValidWidgetUserRole,
} from '../models/variant.model';

import defaultWidgetVariantSettings from './variants/default';
import businessWidgetVariantSettings from './variants/business';

const SupportWidgetVariantSettings: Record<SupportWidgetVariant, SupportWidgetVariantConfig> = {
  [SupportWidgetVariant.DEFAULT]: defaultWidgetVariantSettings,
  [SupportWidgetVariant.BETERSCHRIJVEN]: businessWidgetVariantSettings,
};

export const getSupportWidgetUrl = (variant: SupportWidgetVariant): string => {
  const apiKey = SupportWidgetVariantSettings[variant].apiKey;
  return `https://static.zdassets.com/ekr/snippet.js?key=${apiKey}`;
};

export const getSupportWidgetSettings = (
  variant: SupportWidgetVariant,
  userRole: WidgetUserRoles,
): SupportWidgetConfig | null => {
  if (!isValidWidgetUserRole(userRole)) {
    return null;
  }

  const variantSettings = SupportWidgetVariantSettings[variant];
  return variantSettings.configPerRole[userRole];
};
