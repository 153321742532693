import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import format from 'date-fns/format';
import { AvailableLanguage } from 'constants/language-constants';
import { modalIds } from 'constants/modals-constants';
import { ModalRenderType } from 'components/Modal/Modal.model';
import { DatePicker } from 'components/DatePicker/DatePicker';
import { LoadingSpinner } from 'components/LoadingSpinner';
import { Modal } from 'components/Modal';
import { useModal } from 'hooks/useModal';
import { Heading3 } from 'styles/elements/Headings';

import {
  StyledAccountProductModal,
  StyledButton,
  StyledInformationLabel,
  StyledInformationContainer,
  StyledInformation,
} from './UpdateProductModal.styled';
import { UpdateProductModalProps } from './UpdateProductModal.model';

export const UpdateProductModal: React.FC<UpdateProductModalProps> = ({ onChangeValidUntilDate }) => {
  const { closeModal, isModalOpen, modalParams } = useModal();
  const { t, i18n } = useTranslation('admin-environment');
  const [validUntilDate, setValidUntilDate] = useState<Date>(new Date());

  useEffect(() => {
    if (modalParams?.validUntilDate) {
      setValidUntilDate(new Date(modalParams.validUntilDate.toString()));
    }
  }, [modalParams]);

  if (!modalParams) {
    return <LoadingSpinner />;
  }

  const validSinceDate = modalParams.validSinceDate ? new Date(modalParams.validSinceDate.toString()) : new Date();

  return (
    <Modal
      dataCy="update-account-product-modal"
      isOpen={isModalOpen(modalIds.updateAccountProductModal)}
      renderToElementType={ModalRenderType.REACT_PORTAL}
      onClose={closeModal}
    >
      <StyledAccountProductModal>
        <Heading3>{modalParams.title}</Heading3>
        <StyledInformationContainer>
          <div>
            <StyledInformationLabel>
              {t('accountDetails.productUpdate.dateOfPurchase', 'Date of purchase')}
            </StyledInformationLabel>
            <StyledInformation>{format(validSinceDate, 'dd-MM-yyyy')}</StyledInformation>
          </div>
          <div>
            <StyledInformationLabel>
              {t('accountDetails.productUpdate.timeOfPurchase', 'Time of purchase')}
            </StyledInformationLabel>
            <StyledInformation>{format(validSinceDate, 'HH:mm')}</StyledInformation>
          </div>
        </StyledInformationContainer>
        <StyledInformationLabel>
          {t('accountDetails.productUpdate.validUntilDate', 'Valid till')}
        </StyledInformationLabel>
        <DatePicker
          dataCy="valid-date-picker"
          language={i18n.language as AvailableLanguage}
          minDate={validSinceDate}
          value={validUntilDate}
          onChange={date => setValidUntilDate(date)}
        />
        <StyledButton
          dataCy="save-product-button"
          variant="primary"
          onClick={() => {
            onChangeValidUntilDate({ id: +(modalParams.id || 0), validUntilDate: validUntilDate.toISOString() });
            closeModal();
          }}
        >
          {t('scheduledTestList.item.updateTestTime.button.label', 'Save')}
        </StyledButton>
      </StyledAccountProductModal>
    </Modal>
  );
};
