import styled from 'styled-components/macro';

import welcomeBoxBgImg from 'assets/images/hogeschool-bubbles.png';
import blueBgImage from 'assets/images/bg-sign-up-blue.jpg';
import { ThemeProps } from 'models/styles/theme';
import { StyledSvgIconReact } from 'components/SvgIconReact/SvgIconReact.styled';
import { calculateRem } from 'styles/mixins/calculateRem';
import { Heading1, Heading2, Heading3, Heading4 } from 'styles/elements/Headings';

export const StyledTestCompletedPage = styled.div`
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 3fr 5fr;
  grid-row-gap: 2.5rem;
  padding-bottom: 4rem;
`;

export const TestCompletedTitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 4rem;
  padding: 4rem;

  > div {
    max-width: 40rem;
    text-align: center;
    ${Heading1} {
      font-size: ${calculateRem(64)};
      line-height: ${calculateRem(80)};
    }
  }

  background-color: ${(props: ThemeProps) => props.theme.palette.secondary._200};
  background-image: url(${welcomeBoxBgImg});
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: contain;
`;

export const TestCompletedReviewDateContainer = styled.div`
  display: grid;
  grid-template-columns: 6fr 5fr;
  grid-column-gap: 4rem;
  margin: 0 4rem;
  padding: 4rem;
  background-color: ${(props: ThemeProps) => props.theme.palette.secondary._200};
  background-image: url(${blueBgImage});
  background-size: contain;
`;

export const TestCompletedReviewDateIntro = styled.div`
  ${Heading1}, ${Heading2} {
    color: ${({ theme }: ThemeProps) => theme.palette.neutral.white};
  }
  ${Heading1} {
    font-size: ${calculateRem(64)};
    line-height: ${calculateRem(80)};
  }
  ${Heading2} {
    font-size: ${calculateRem(32)};
    line-height: ${calculateRem(40)};
    font-weight: normal;
  }
`;

export const TestCompletedReviewDateInfo = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto auto auto;
  grid-row-gap: 1rem;
  background: ${({ theme }: ThemeProps) => theme.palette.neutral.white};
  padding: 4rem;

  ${StyledSvgIconReact} {
    margin: 0.5rem 1rem 0.5rem 0;
  }

  ${Heading3} {
    font-size: ${calculateRem(48)};
    line-height: ${calculateRem(64)};
  }
  ${Heading4} {
    font-size: ${calculateRem(40)};
    line-height: ${calculateRem(54)};
    font-weight: normal;
  }
  span {
    font-size: ${calculateRem(32)};
  }
`;
