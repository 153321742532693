import styled from 'styled-components/macro';
import { ThemeProps } from 'models/styles/theme';
import { clearButtonCss } from 'styles/elements/Buttons';
import { Heading2 } from 'styles/elements/Headings';

export const StyledPasswordWrapper = styled.div<ThemeProps>`
  background: ${({ theme }: ThemeProps) => theme.palette.neutral.white};
  border-radius: 4px;
  padding: 4rem;
  text-align: center;

  > ${Heading2} {
    text-align: center;
    margin-bottom: 2.5rem;
  }

  > button {
    ${clearButtonCss};
    background: ${({ theme }: ThemeProps) => theme.palette.neutral._200};
    padding: 1rem 3.5rem;
  }
`;
