import { User, createTracker, Page } from '@infinitaslearning/module-data-analyticstracker';
import { HST_PROGRESS_EVENTS } from '@he-learning/service-he-common';
import { isProd } from 'constants/env-constants';
import { STUDENT_MODULES, TEACHER_MODULES } from 'analytics/constants/pageTypes';

type TrackerPromise = ReturnType<typeof tracker.elementClicked>;
type HstCustomEventPayload = {
  module: STUDENT_MODULES | TEACHER_MODULES; // kebab-case, identifies the section of the page where the event occured
  itemId: keyof typeof HST_PROGRESS_EVENTS; // kebab-case, identifies the item on which the event occured
  value: string;
};

const tracker = createTracker({
  platform: 'Hogeschooltaal',
  platformVersion: '2.0.0',
  environment: isProd() ? 'production' : 'acceptance',
  opCo: 'noordhoff',
  platformVariant: 'pupil',
});

let isTrackingModuleInitialized = false;
tracker.awaitInitialized().then(() => (isTrackingModuleInitialized = true));

export const trackIdentifyEvent = (eventPayload: User): void => {
  if (isTrackingModuleInitialized) {
    tracker.identify(eventPayload);
  } else {
    console.warn("Cannot track identify event because tracking module hasn't been initialized yet");
  }
};

export const trackPageEvent = (eventPayload: Page): void => {
  if (isTrackingModuleInitialized) {
    tracker.page(eventPayload);
  } else {
    console.warn("Cannot track page event because tracking module hasn't been initialized yet");
  }
};

export const trackPlatformEvent = ({ module, itemId, value }: HstCustomEventPayload): TrackerPromise =>
  tracker.elementClicked({
    module,
    itemId: itemId as string,
    value,
  });
