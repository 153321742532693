import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useLanguage } from 'hooks/useSelectedLanguage';
import { TEST_PAGE_ROUTES } from 'constants/routes';
import { buildUrlWithPathParams } from '_helpers/utils/urlBuilder';
import { DiagnosticTestPagePathParams } from 'models/path/path-params';
import { DiagnosticTestDetailsResult } from 'models/exam/DiagnosticTest';
import { InfoStatus } from 'components/InfoStatus/InfoStatus';
import { InfoCircle } from 'components/InfoCircle/InfoCircle';
import { Variant } from 'components/InfoCircle/InfoCircle.model';
import { StyledDiagnosticTestDetailsPage, StyledCardStatus, StyledSubtitle } from './DiagnosticTestDetailsPage.styled';
import { DiagnosticTestBreadcrumbs } from '../components/DiagnosticTestBreadcrumbs/DiagnosticTestBreadcrumbs';
import { DiagnosticTestContent } from '../components/DiagnosticTestContent/DiagnosticTestContent';
import { useDiagnosticTest } from 'hooks/useDiagnosticTest';
import { useAnswer } from 'hooks/useAnswer';
import { QuestionStatus } from 'constants/exam-constants';

export const getCircleStatusSubText = (t: TFunction, questionsCompleted: number, totalQuestions: number): string => {
  if (questionsCompleted === 0) {
    return t('subject.notStarted.label', 'Not Started');
  }
  if (questionsCompleted === totalQuestions) {
    return t('subject.completed.label', 'Completed');
  }
  return t('subject.progress.label', { questionsCompleted, totalQuestions });
};

export const getCircleStatusVariant = (score: number | null): Variant => {
  if (score === null) {
    return 'default';
  }
  if (score >= 80) {
    return 'success';
  }
  return 'warning';
};

export const DiagnosticTestDetailsPage: React.FC = observer(() => {
  const {
    diagnosticTestListResults,
    diagnosticTestDetailsResults,
    diagnosticTestDetails,
    fetchDiagnosticTestDetailsResults,
    fetchDiagnosticTestListResults,
    fetchDiagnosticTestDetails,
  } = useDiagnosticTest();

  const { setQuestionStatus } = useAnswer();

  const { productId, moduleId, groupId, testId }: DiagnosticTestPagePathParams = useParams();
  const history = useHistory();
  const { t } = useTranslation('diagnostic-test');

  const { currentLanguage } = useLanguage();

  useEffect(() => {
    setQuestionStatus(QuestionStatus.INITIAL);
  }, []);

  useEffect(() => {
    if (productId && moduleId && groupId && testId) {
      fetchDiagnosticTestDetails(+productId, +moduleId, +groupId, +testId);
      fetchDiagnosticTestListResults(+productId, +moduleId, +groupId);
      fetchDiagnosticTestDetailsResults(+productId, +moduleId, +groupId, +testId);
    }
  }, [productId, moduleId, groupId, testId, currentLanguage]);

  const onSelectExercise = (exerciseId: number) => {
    history.push(
      buildUrlWithPathParams(TEST_PAGE_ROUTES.DIAGNOSTIC_TEST_EXAM, {
        productId,
        moduleId,
        groupId,
        testId,
        exerciseId,
      }),
    );
  };

  const getButtonLabel = (results: DiagnosticTestDetailsResult | undefined) => {
    const isStarted = !results || results.answeredQuestions !== 0;

    if (!isStarted) {
      return t('subjects.button.start.label', 'Start');
    }

    const isCompleted = results && results.answeredQuestions === results.totalQuestions;
    return !isCompleted ? t('subjects.button.continue.label', 'Continue') : t('subjects.button.repeat.label', 'Repeat');
  };

  return (
    <StyledDiagnosticTestDetailsPage>
      <DiagnosticTestBreadcrumbs />
      <DiagnosticTestContent
        subtitle={
          <InfoStatus
            dataCy="diagnostic-test-details"
            status={diagnosticTestListResults.find(test => test.id === +testId)?.status || 'default'}
          />
        }
        title={diagnosticTestDetails.title}
      >
        <StyledSubtitle>
          {t('subjects.subtitle', 'The following subjects are covered in this practice test.')}
        </StyledSubtitle>
        {diagnosticTestDetails.exercises?.map(exercise => {
          const exerciseResults = diagnosticTestDetailsResults?.find(e => e.id === exercise.id);
          const buttonLabel = getButtonLabel(exerciseResults);
          const answeredQuestions = exerciseResults?.answeredQuestions || 0;
          const totalQuestions = exerciseResults?.totalQuestions || 0;
          const obtainedScore = exerciseResults ? exerciseResults?.score : null;
          return (
            <StyledCardStatus
              key={exercise.id}
              buttonLabel={buttonLabel}
              circleComponent={
                <InfoCircle
                  dataCy={`exam-progress-circle-${exercise.id}`}
                  subtext={getCircleStatusSubText(t, answeredQuestions, totalQuestions)}
                  value={obtainedScore !== null ? `${Math.round(obtainedScore)}%` : '--'}
                  variant={getCircleStatusVariant(obtainedScore)}
                />
              }
              dataCy={`diagnostic-exercise-${exercise.id}`}
              title={exercise.title}
              onClickButton={() => onSelectExercise(exercise.id)}
            />
          );
        })}
      </DiagnosticTestContent>
    </StyledDiagnosticTestDetailsPage>
  );
});
