import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useDomainHandler } from 'hooks/useDomainHandler';

import { Heading2 } from 'styles/elements/Headings';
import hourglassSvg from 'assets/images/hourglass.svg';

import { StyledPageLayout, StyledContainer, StyledLink, StyledLogo, StyledText } from './AccessDeniedPage.styled';

export const AccessDeniedPage: React.FC = () => {
  const { t } = useTranslation('auth-pages');
  const { getCustomLogoByDomain, getCustomSupportEmailByDomain } = useDomainHandler();

  return (
    <StyledPageLayout>
      <StyledLogo iconName={getCustomLogoByDomain()} />
      <StyledContainer>
        <img alt="Hourglass" src={hourglassSvg} />
        <Heading2>{t('accessDeniedPage.unverifiedTeacher.title')}</Heading2>
        <StyledText>
          <Trans
            components={{
              emailLink: <StyledLink href={`mailto:${getCustomSupportEmailByDomain()}`} />,
            }}
            i18nKey="accessDeniedPage.unverifiedTeacher.description"
            t={t}
            values={{
              supportEmailAddress: getCustomSupportEmailByDomain(),
            }}
          />
        </StyledText>
      </StyledContainer>
    </StyledPageLayout>
  );
};
