import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { buildUrlWithPathParams } from '_helpers/utils/urlBuilder';
import { useModal } from 'hooks/useModal';

import { modalIds } from 'constants/modals-constants';
import { TestType } from 'constants/exam-constants';

import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { useScheduledTests } from 'hooks/useScheduledTests';
import { TEACHER_MONITOR_PAGE_ROUTES, TEACHER_SCHEDULED_TEST_PAGE_ROUTES } from 'constants/routes';

import { Button } from 'components/Button';
import { BackButton } from 'components/BackButton';
import { SvgIconReact } from 'components/SvgIconReact';

import { CssFlex } from 'styles/helpers/layout';
import { Heading2 } from 'styles/elements/Headings';

import { ScheduledTestResultsReportPathParams } from './TestResultsReport.model';
import { StyledFraudLogButton, StyledHeader, StyledCertificateButton } from './TestResultsReport.styled';

import { TestFeatureBox } from './components/TestFeatureBox/TestFeatureBox';
import { ExerciseSummary } from './components/ExerciseSummary/ExerciseSummary';
import { TeacherPageLayout } from '../components/TeacherPageLayout/TeacherPageLayout';
import { StudentScoreTable } from './components/StudentScoreTable/StudentScoreTable';
import { ReportSettingsModal } from './components/ReportSettingsModal/ReportSettingsModal';

export const ScheduledTestResultsDetails: React.FC = observer(() => {
  const history = useHistory();
  const { t } = useTranslation('teacher-dashboard');

  const { toggleModal } = useModal();
  const {
    loading: isLoading,
    scheduledTest,
    ownedScheduledTestResults: scheduledTestResults,
    fetchScheduledTest,
    fetchOwnedScheduledTestResults,
    downloadScheduledTestResultsReport,
    updateStudentScheduledTestsResultsCertificate,
  } = useScheduledTests();

  const { scheduledTestId }: ScheduledTestResultsReportPathParams = useParams();

  const { showAssignCertificatesTeacherButton } = useFeatureFlags();

  useEffect(() => {
    if (!scheduledTest || scheduledTest.id !== +scheduledTestId) {
      fetchScheduledTest(+scheduledTestId);
      fetchOwnedScheduledTestResults(+scheduledTestId);
    }
  }, [scheduledTestId, scheduledTest]);

  // Show modal only if test is cumulative, otherwise trigger download
  const onDownloadReportClick = () => {
    if (scheduledTest?.cumulative) {
      toggleModal(modalIds.scheduledTestResultsReportModal);
    } else {
      downloadScheduledTestResultsReport(+scheduledTestId);
    }
  };

  const onVisitFraudLogsClick = () => {
    if (scheduledTest) {
      history.push(
        buildUrlWithPathParams(TEACHER_MONITOR_PAGE_ROUTES.SCHEDULED_TEST_LIVE_MONITOR, {
          scheduledTestId: scheduledTest.id,
        }),
      );
    }
  };

  const handleAssignCertificates = () => {
    updateStudentScheduledTestsResultsCertificate(+scheduledTestId);
  };

  if (!scheduledTest) {
    return null;
  }

  return (
    <TeacherPageLayout>
      <BackButton
        dataCy="back-to-overview-button"
        label={t('ownedScheduledTestResults.details.buttons.backToOverview.label', 'Back to overview')}
        redirectUrl={TEACHER_SCHEDULED_TEST_PAGE_ROUTES.SCHEDULED_TEST_RESULTS_OVERVIEW}
      />
      <StyledHeader>
        <div>
          <Heading2>{scheduledTest.title}</Heading2>
          <Button
            hasIconRight
            dataCy="download-report-button"
            disabled={isLoading}
            isLoading={isLoading}
            variant="secondary"
            onClick={onDownloadReportClick}
          >
            <span>{t('ownedScheduledTestResults.details.buttons.downloadReport.label', 'Download report')}</span>
            <SvgIconReact iconName="downloadIcon" />
          </Button>
        </div>
        <CssFlex alignItems="center" gap={0.5}>
          <StyledFraudLogButton
            hasIconRight
            dataCy="view-fraud-logs-button"
            disabled={isLoading}
            isLoading={isLoading}
            variant="border"
            onClick={onVisitFraudLogsClick}
          >
            <span>{t('ownedScheduledTestResults.details.buttons.fraudLogs.label', 'To fraudlogs')}</span>
          </StyledFraudLogButton>

          {showAssignCertificatesTeacherButton &&
            scheduledTest &&
            scheduledTest.ownedBySelf &&
            scheduledTest.testType === TestType.SUMMATIVE_TEST && (
              <StyledCertificateButton
                dataCy="assign-certificates-button"
                disabled={isLoading}
                isLoading={isLoading}
                variant="primary"
                onClick={() => handleAssignCertificates()}
              >
                {t('ownedScheduledTestResults.details.buttons.AssignCertificates.label', 'Assign Certificates')}
              </StyledCertificateButton>
            )}
        </CssFlex>
      </StyledHeader>
      {scheduledTestResults && <ExerciseSummary exerciseResults={scheduledTestResults.exercises} />}
      <TestFeatureBox scheduledTest={scheduledTest} />
      {scheduledTestResults && (
        <StudentScoreTable
          exerciseList={scheduledTestResults.exercises}
          passingScore={scheduledTest.passingScore}
          userScheduledTestResults={scheduledTestResults.students}
        />
      )}
      <ReportSettingsModal scheduledTestId={+scheduledTestId} />
    </TeacherPageLayout>
  );
});
