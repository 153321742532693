import styled, { css } from 'styled-components/macro';
import { ElearningThemeInterface } from 'themes/elearningInterface.theme';

interface Props extends React.HTMLAttributes<HTMLButtonElement> {
  theme: ElearningThemeInterface;
  $isActive: boolean;
  $hasIcon: boolean;
}

const getActiveCss = ({ theme }: Props) => css`
  background-color: ${theme.palette.feedback.success._200};
  border-color: ${theme.palette.feedback.success.brand};
  &:hover {
    background-color: ${theme.palette.feedback.success._200};
  }
`;

const getHasIconCss = () => css`
  display: grid;
  grid-template-columns: auto 1fr;
`;

export const StyledToggleButton = styled.button<Props>`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: ${({ theme }: Props) => theme.palette.neutral.black};
  background-color: ${({ theme }: Props) => theme.palette.neutral._200};
  border: 2px solid ${({ theme }: Props) => theme.palette.neutral._200};
  border-radius: 4px;
  padding: 1rem;
  min-width: 18rem;
  font-size: 1rem;
  font-weight: ${({ theme }: Props) => theme.font.weight.bold};
  line-height: 1.5rem;
  &:hover {
    background-color: ${({ theme }: Props) => theme.palette.neutral._400};
  }
  &.active {
    ${props => getActiveCss(props)}
  }
  ${({ $hasIcon }) => $hasIcon && getHasIconCss()}
`;

export const ToggleButtonIcon = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
`;
