import { TestType } from 'constants/exam-constants';

export interface DiagnosticModalParams {
  diagnosticModalShown: string;
}

const modalShownStorageLabel = `${TestType.DIAGNOSTIC_TEST}-modal-has-been-shown`;
const setDiagnosticModalHasBeenShown = (diagnosticModalShown: boolean): void => {
  localStorage.setItem(modalShownStorageLabel, diagnosticModalShown.toString());
};

const getDiagnosticModalHasBeenShown = (): boolean => Boolean(localStorage.getItem(modalShownStorageLabel));

export { setDiagnosticModalHasBeenShown, getDiagnosticModalHasBeenShown };
