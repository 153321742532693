import SeparatorLineProps from 'components/SeparatorLine/SeparatorLine.model';
import { StyledSeparatorLine } from 'components/SeparatorLine/SeparatorLine.styled';
import React from 'react';
import { StyledText } from './SeparatorLineText.styled';

export interface SeparatorLineTextProps extends SeparatorLineProps {
  text: string;
  verticalOffset?: number;
}

export const SeparatorLineText: React.FC<SeparatorLineTextProps> = ({
  direction = 'vertical',
  verticalOffset = 0,
  color,
  height,
  width,
  text,
}) => (
  <>
    <StyledSeparatorLine
      color={color}
      direction={direction}
      height={height && height / 2 + verticalOffset}
      width={width}
    />
    <StyledText>{text}</StyledText>
    <StyledSeparatorLine
      color={color}
      direction={direction}
      height={height && height / 2 - verticalOffset}
      width={width}
    />
  </>
);
