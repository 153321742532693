import React from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import { useProfile } from 'hooks/useProfile';
import useValidate from 'hooks/user/useValidate';

import { AccessPageLayout } from 'layouts/AccessPageLayout';

export const LoginValidatePage: React.FC = observer(() => {
  const { t } = useTranslation('auth-pages');
  const { isRegistrationComplete } = useProfile();

  useValidate();

  return (
    <AccessPageLayout>
      {isRegistrationComplete !== false
        ? t('validateLoginPage.loading.message', 'Your user account is being validated...')
        : t('validateLoginPage.loading.migratingProducts', 'Your new products are being transferred. Please wait...')}
    </AccessPageLayout>
  );
});
