import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/Button';
import { TestCompletedMessage } from 'layouts/TestCompletedMessage';
import { SkillTestCompletedMessageProps } from './SkillTestCompletedMessage.model';
import { useDeterminationTest } from 'hooks/useDeterminationTest';

export const SkillTestCompletedMessage: React.FC<SkillTestCompletedMessageProps> = ({
  onSeeResults,
  onStartPracticing,
}) => {
  const { determinationTest } = useDeterminationTest();
  const { t } = useTranslation('skill-determination-test');

  const subjectTitle = determinationTest?.subject?.title;

  return (
    <TestCompletedMessage
      buttons={
        <Fragment>
          <Button dataCy="see-results-button" fontColor="black" variant="secondary" onClick={() => onSeeResults()}>
            {t('button.seeResults.label', 'See results')}
          </Button>
          <Button fontColor="black" variant="primary" onClick={() => onStartPracticing()}>
            {t('button.startPracice.label', 'Start practising')}
          </Button>
        </Fragment>
      }
      dataCy="finished-questions-skill-test"
      description={
        subjectTitle
          ? t(
              'finishedQuestions.description',
              'You completed a set of questions and now we can present more accurate content according to your current skill level.You’re ready to take the Tenses exercises.',
              { subjectTitle },
            )
          : t(
              'finishedQuestions.descriptionNoSubject',
              'You completed a set of questions and now we can present more accurate content according to your current skill level.You’re ready to take the Tenses exercises.',
            )
      }
      iconName="badgeIcon"
    />
  );
};
