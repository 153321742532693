import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Switch, useHistory } from 'react-router-dom';

import { AUTH_PAGES_ROUTES, PRODUCT_PAGES_ROUTES, RESULT_PAGE_ROUTES, TEST_PAGE_ROUTES } from 'constants/routes';
import { modalIds } from 'constants/modals-constants';
import { TestType } from 'constants/exam-constants';

import { buildUrlWithPathParams } from '_helpers/utils/urlBuilder';

import useRequest from 'hooks/user/useRequest';
import { useModal } from 'hooks/useModal';
import { useProfile } from 'hooks/useProfile';
import { useProduct } from 'hooks/useProduct';
import { useLanguage } from 'hooks/useSelectedLanguage';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { useCurrentPathMatch } from 'hooks/useCurrentPathMatch';
import { useSelectedProductId } from 'hooks/useSelectedProductId';

import { Footer } from 'components/Footer';
import { AuthRoute } from 'layouts/AuthRoute';
import { UnderConstructionMessage } from 'components/UnderConstructionMessage/UnderConstructionMessage';

import { StyledPageLayout } from './StudentDashboard.styled';
import { StudentNavigationMenu } from '../components/NavigationMenu';
import { StudentHeader } from '../components/Header/StudentHeader';
import { NoProductsOwnedNavigationMenu } from '../components/NoProductsOwnedNavigationMenu';

import {
  StudentDashboardPage,
  PersonalProgressPage,
  ModuleDetailsPage,
  WritingSubjectDetailsPage,
  DiagnosticTestGroupsPage,
  DiagnosticTestListPage,
  DiagnosticTestDetailsPage,
  TopicDetailsPage,
  FormativeTestResultsPage,
  SummativeTestResultsPage,
  FreebieTestResultsPage,
  CertificateTestResultsPage,
} from './subpages';

// Pages where the side menu should not appear
// @see https://github.com/infinitaslearning/app-he-hoges-web/pull/1543
const routesWithoutSideMenu: string[] = [];

export const StudentDashboard: React.FC = observer(() => {
  const history = useHistory();
  const { toggleModal } = useModal();
  const { currentLanguage } = useLanguage();
  const selectedProductId = useSelectedProductId();
  const { fetchUserDetails, userDetails, updateUserDetails } = useProfile();

  const { logout } = useRequest();
  const { currentRouteMatchesAny } = useCurrentPathMatch();

  const { fetchProducts, fetchProductDetails, productsList } = useProduct();

  const { showAdaptivePracticeFlow } = useFeatureFlags();

  useEffect(() => {
    fetchUserDetails();
    fetchProducts();
  }, [fetchProducts, fetchUserDetails]);

  // Auto-select first product when list is loaded
  useEffect(() => {
    if (!selectedProductId && productsList && productsList.length > 0) {
      history.replace(buildUrlWithPathParams(PRODUCT_PAGES_ROUTES.PRODUCT_DETAILS, { productId: productsList[0].id }));
    }
  }, [JSON.stringify(productsList)]);

  // Reload product details when user switches their language (because the module descriptions will change)
  useEffect(() => {
    if (selectedProductId) {
      fetchProductDetails(+selectedProductId);
    }
  }, [fetchProductDetails, selectedProductId, currentLanguage]);

  const onToggleSideMenu = () => {
    toggleModal(modalIds.modalSideMenu);
  };

  const goToTestTypeResults = (testTypeParams: string) =>
    buildUrlWithPathParams(RESULT_PAGE_ROUTES.TEST_TYPE_RESULTS_LIST, { testType: testTypeParams });

  const shouldShowSideNav = !currentRouteMatchesAny(routesWithoutSideMenu);

  return (
    <StyledPageLayout $hasSideMenu={shouldShowSideNav}>
      <StudentHeader
        toggleSideMenu={onToggleSideMenu}
        updateUserDetails={updateUserDetails}
        userDetails={userDetails}
        onLogout={logout}
      />
      {shouldShowSideNav && (
        <>
          {!productsList?.length ? (
            <NoProductsOwnedNavigationMenu dataCy="no-product-navigation-menu" />
          ) : (
            <StudentNavigationMenu dataCy="product-navigation-menu" />
          )}
        </>
      )}
      <main>
        <Switch>
          {/* Certificates route has to appear first so React doesn't try rendering the dasboard
            in "/product/certificates" path, which lead to the following bug:
              @see https://github.com/infinitaslearning/he-hst-project-boards/issues/310 */}
          <AuthRoute
            exact
            component={CertificateTestResultsPage}
            path={RESULT_PAGE_ROUTES.OBTAINED_CERTIFICATES}
            redirectPath={AUTH_PAGES_ROUTES.LOG_IN}
          />
          <AuthRoute
            exact
            component={StudentDashboardPage}
            path={PRODUCT_PAGES_ROUTES.PRODUCT_DETAILS}
            redirectPath={AUTH_PAGES_ROUTES.LOG_IN}
          />
          <AuthRoute
            exact
            component={TopicDetailsPage}
            path={PRODUCT_PAGES_ROUTES.PRODUCT_TOPIC_DETAILS}
            redirectPath={AUTH_PAGES_ROUTES.LOG_IN}
          />
          <AuthRoute
            exact
            component={WritingSubjectDetailsPage}
            path={PRODUCT_PAGES_ROUTES.PRODUCT_WRITING_MODULE_DETAILS}
            redirectPath={AUTH_PAGES_ROUTES.LOG_IN}
          />
          <AuthRoute
            exact
            component={showAdaptivePracticeFlow ? ModuleDetailsPage : UnderConstructionMessage}
            path={PRODUCT_PAGES_ROUTES.PRODUCT_MODULE_DETAILS}
            redirectPath={AUTH_PAGES_ROUTES.LOG_IN}
          />
          <AuthRoute
            exact
            component={DiagnosticTestGroupsPage}
            path={TEST_PAGE_ROUTES.DIAGNOSTIC_TEST_GROUPS}
            redirectPath={AUTH_PAGES_ROUTES.LOG_IN}
          />
          <AuthRoute
            exact
            component={DiagnosticTestListPage}
            path={TEST_PAGE_ROUTES.DIAGNOSTIC_TEST_LIST}
            redirectPath={AUTH_PAGES_ROUTES.LOG_IN}
          />
          <AuthRoute
            exact
            component={DiagnosticTestDetailsPage}
            path={TEST_PAGE_ROUTES.DIAGNOSTIC_TEST_DETAILS}
            redirectPath={AUTH_PAGES_ROUTES.LOG_IN}
          />
          {/* --- Result pages --- */}
          <AuthRoute
            exact
            component={PersonalProgressPage}
            path={RESULT_PAGE_ROUTES.PERSONAL_PROGRESS}
            redirectPath={AUTH_PAGES_ROUTES.LOG_IN}
          />
          <AuthRoute
            exact
            component={UnderConstructionMessage}
            path={RESULT_PAGE_ROUTES.WRITING_EXERCISES}
            redirectPath={AUTH_PAGES_ROUTES.LOG_IN}
          />
          <AuthRoute
            exact
            component={UnderConstructionMessage}
            path={RESULT_PAGE_ROUTES.DIAGNOSTIC_TEST}
            redirectPath={AUTH_PAGES_ROUTES.LOG_IN}
          />
          <AuthRoute
            exact
            component={FormativeTestResultsPage}
            path={goToTestTypeResults(TestType.FORMATIVE_TEST)}
            redirectPath={AUTH_PAGES_ROUTES.LOG_IN}
          />
          <AuthRoute
            exact
            component={FreebieTestResultsPage}
            path={goToTestTypeResults(TestType.FREEBIE_TEST)}
            redirectPath={AUTH_PAGES_ROUTES.LOG_IN}
          />
          <AuthRoute
            exact
            component={SummativeTestResultsPage}
            path={goToTestTypeResults(TestType.SUMMATIVE_TEST)}
            redirectPath={AUTH_PAGES_ROUTES.LOG_IN}
          />
        </Switch>
      </main>
      <Footer />
    </StyledPageLayout>
  );
});
