import React from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';

import { verify, VerifyInterface } from 'schemas/signin.schema';

import { Heading3, Heading4 } from 'styles/elements/Headings';
import { Paragraph } from 'styles/elements/Texts';
import {
  StyledButton,
  StyledField,
  StyledIcon,
  StyledInput,
  StyledInputContainer,
} from '../../MigrateAccessPage.styled';

const defaultFormValues = { email: '' } as VerifyInterface;

interface ProvideAccessFormProps {
  onSubmit: (accessPayload: VerifyInterface) => void;
}

export const ProvideAccessForm: React.FC<ProvideAccessFormProps> = ({ onSubmit }) => {
  const { t } = useTranslation('auth-pages');

  const formik = useFormik({
    initialValues: defaultFormValues,
    validationSchema: verify({
      email: {
        requiredMessage: t('migrateAccountPage.emailStep.fields.email.error.required'),
        invalidEmailMessage: t('migrateAccountPage.emailStep.fields.email.error.invalid'),
      },
    }),
    onSubmit,
  });

  return (
    <form noValidate data-cy="migration-email-form" onSubmit={formik.handleSubmit}>
      <Heading3>{t('migrateAccountPage.emailStep.title')}</Heading3>
      <Paragraph>{t('migrateAccountPage.emailStep.description')}</Paragraph>
      <StyledField>
        <Heading4>{t('migrateAccountPage.emailStep.fields.email.label')}</Heading4>
        <StyledInputContainer>
          <StyledIcon iconName="fas fa-envelope" />
          <StyledInput
            dataCy="migrate-access-email"
            errorMessage={formik.errors.email}
            hasError={!!(formik.errors.email && formik.touched.email)}
            name="email"
            placeholder={t('migrateAccountPage.emailStep.fields.email.placeholder')}
            type="text"
            value={formik.values.email}
            onChange={formik.handleChange}
          />
        </StyledInputContainer>
      </StyledField>
      <StyledButton isBlock disabled={formik.isSubmitting} type="submit" variant="primary">
        {t('migrateAccountPage.emailStep.button.submit')}
      </StyledButton>
    </form>
  );
};
