import React from 'react';
import { domToReact } from 'html-react-parser';

import { EmbeddedHtml } from 'components/EmbeddedHtml';
import { Node } from 'components/EmbeddedHtml/EmbeddedHtml.model';
import { buildUrlWithPathParams } from '_helpers/utils/urlBuilder';
import { PRODUCT_PAGES_ROUTES } from 'constants/routes';

import { StyledContent } from './TheoryRenderer.styled';
import { contentSanitizeOptions } from './TheoryRenderer.constants';

export interface TheoryRendererProps {
  content: string | undefined;
  productId: number;
}

export const TheoryRenderer: React.FC<TheoryRendererProps> = ({ content, productId }) => {
  if (!content) {
    return null;
  }

  // Dynamically build URL for crosslinks in theory
  const replaceFunction = ({ tagType, attributes, children }: Node) => {
    if (tagType === 'a' && attributes.class === 'theory_crosslink') {
      return (
        <a
          data-cy="theory-crosslink"
          href={buildUrlWithPathParams(PRODUCT_PAGES_ROUTES.PRODUCT_TOPIC_DETAILS, {
            productId,
            moduleId: +attributes['data-module-id'],
            subjectId: +attributes['data-subject-id'],
            topicId: +attributes['data-topic-id'],
          })}
        >
          {domToReact(children)}
        </a>
      );
    }
  };

  return (
    <StyledContent>
      <EmbeddedHtml rawHtml={content} replaceFunction={replaceFunction} sanitizeOptions={contentSanitizeOptions} />
    </StyledContent>
  );
};
