import { useTranslation } from 'react-i18next';
import { enGB, nl } from 'date-fns/locale';
import { Locale } from 'date-fns';

export const useDateLocale = (): Locale => {
  const { i18n } = useTranslation();
  switch (i18n.language) {
    case 'nl':
      return nl;
    default:
      return enGB;
  }
};
