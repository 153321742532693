import styled from 'styled-components/macro';
import { ElearningThemeInterface } from 'themes/elearningInterface.theme';
import { StyledBreadcrumbsProps } from './Breadcrumbs.model';

interface Props extends StyledBreadcrumbsProps {
  theme: ElearningThemeInterface;
}
export const StyledBreadcrumbs = styled.div<Props>`
  display: grid;
  grid-template-columns: repeat(${props => props.columns}, auto);
  grid-gap: 0.5rem;
  justify-self: baseline;
  width: max-content;
`;
export const StyledBreadCrumb = styled.div<Props>`
  color: ${({ theme }: Props) => theme.palette.neutral._900};

  a {
    color: ${({ theme }: Props) => theme.palette.neutral._900};
  }
`;
