import styled from 'styled-components/macro';
import { ElearningThemeInterface } from '../../themes/elearningInterface.theme';
import { AbsolutePositionedViewProps } from './AbsolutePositionedView.model';

type Props = {
  theme: ElearningThemeInterface;
} & AbsolutePositionedViewProps;

export const StyledAbsolutePositionedView = styled.div<Props>`
  position: absolute;
  z-index: 1002;
  top: ${props => props.y}px;
  left: ${props => props.x}px;
`;
