import { Button } from 'components/Button';
import { ThemeProps } from 'models/styles/theme';
import { FontAwesomeIcon } from 'components/FontAwesomeIcon';
import styled from 'styled-components/macro';

export const StyledSEBCheckBoxWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
`;

export const StyledTable = styled.table`
  width: 100%;

  > tbody {
    > tr {
      > td {
        > label {
          padding-bottom: 0.3rem;
        }
      }
    }
  }
`;

export const KeyWrapper = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const StyledIconWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  min-width: 20rem;
  margin-top: 0.5rem;
`;

export const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  cursor: pointer;
`;

export const StyledCreateNewButton = styled(Button)<ThemeProps>`
  color: ${({ theme }: ThemeProps) => theme.palette.primary.brand};
`;
