import styled from 'styled-components/macro';
import { ThemeProps as Props } from 'models/styles/theme';

import { CssFlex, CssGrid, Typography } from 'styles/helpers/layout';
import { Heading3 } from 'styles/elements/Headings';

export const StyledBox = styled(CssGrid)<Props>`
  height: 100%;
  padding: 1.5rem;
  border: 1px solid ${(props: Props) => props.theme.palette.neutral._400};

  ${Heading3} {
    margin: 0;
    align-self: center;
  }

  ${CssFlex} {
    align-items: center;
    justify-content: center;
  }
`;

export const StyledTitle = styled(Typography)<Props>`
  font-size: ${(props: Props) => props.theme.font.size._12};
`;
