import styled from 'styled-components/macro';
import { ElearningThemeInterface } from 'themes/elearningInterface.theme';

import { Heading6 } from 'styles/elements/Headings';

interface Props {
  theme: ElearningThemeInterface;
}

export const StyledLevelDashGroup = styled.div<Props>`
  display: flex;
  align-items: center;

  .level-dashes {
    display: flex;
    align-items: center;

    div:not(:first-child) {
      margin-left: 0.5rem;
    }
  }
`;

export const StyledLevelLabel = styled(Heading6)`
  width: max-content;
  margin-bottom: 0;
  margin-left: 1rem;
  font-weight: ${(props: Props) => props.theme.font.weight.normal};
  font-size: ${(props: Props) => props.theme.font.size._12};
`;
