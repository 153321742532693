import styled, { css } from 'styled-components/macro';
import { ElearningThemeInterface } from 'themes/elearningInterface.theme';
import { CheckboxProps } from './Checkbox.model';

interface CheckboxLabelProps {
  theme: ElearningThemeInterface;
  checked: boolean;
  disabled: boolean;
  checkedColor?: string;
}

type Props = Omit<CheckboxProps, 'label'>;

export const StyledCheckbox = styled.input<Props>`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`;

export const StyledCheckmark = styled.span<CheckboxLabelProps>`
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  background-color: ${({ theme }: CheckboxLabelProps) => theme.palette.neutral.white};
  border: 1px solid ${({ theme }: CheckboxLabelProps) => theme.palette.neutral._900};
  border-radius: 4px;
  color: ${({ theme }: CheckboxLabelProps) => theme.palette.neutral.white};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledLabel = styled.label<CheckboxLabelProps>`
  display: inline-block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 1rem;
  user-select: none;

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
    `}

  &:hover ${StyledCheckbox} ~ ${StyledCheckmark} {
    background-color: #ccc;
  }

  ${StyledCheckbox} ~ ${StyledCheckmark} svg {
    display: none;
  }

  ${StyledCheckbox}:checked ~ ${StyledCheckmark} svg {
    display: block;
  }

  ${StyledCheckbox}:checked ~ ${StyledCheckmark} {
    border-color: ${({ theme, checkedColor }: CheckboxLabelProps) => checkedColor ?? theme.palette.secondary.brand};
    background-color: ${({ theme, checkedColor }: CheckboxLabelProps) => checkedColor ?? theme.palette.secondary.brand};
  }

  ${StyledCheckbox} ~ ${StyledCheckmark}:after {
    content: '';
    position: absolute;
    display: none;
  }

  ${StyledCheckbox}:checked ~ ${StyledCheckmark}:after {
    display: block;
  }

  ${StyledCheckbox}:disabled ~ ${StyledCheckmark} {
    border-color: #d1d1d1;
    background-color: #d1d1d1;
  }
`;
