import React from 'react';
import classnames from 'classnames';

import { ReactComponent as ArrowTriangleUp } from 'assets/icons/arrow-triangle-up.svg';
import { ReactComponent as ArrowTriangleDown } from 'assets/icons/arrow-triangle-down.svg';
import { StyledArrowButton, StyledArrowContainer } from './SortArrows.styled';
import { SortArrowsProps } from './SortArrows.model';

export const SortArrows: React.FC<SortArrowsProps> = ({
  disabled = false,
  activeDirection = 'none',
  onClick,
  children,
}) => (
  <StyledArrowButton disabled={disabled} onClick={onClick}>
    {children}
    <StyledArrowContainer>
      <ArrowTriangleUp className={classnames(activeDirection === 'asc' && 'active')} />
      <ArrowTriangleDown className={classnames(activeDirection === 'desc' && 'active')} />
    </StyledArrowContainer>
  </StyledArrowButton>
);
