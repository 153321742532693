import { useEffect } from 'react';

import { modalIds } from 'constants/modals-constants';

import { useModal } from './useModal';
import { useAdaptivePractice } from './useAdaptivePractice';

export interface AdaptivePracticeHelperResponseProps {
  onSelectedTopicAlreadyCompleted: () => void;
  onTopicCompleted: (nextTopicId: number) => void;
  onAllNormalTopicsCompleted: () => void;
  onChapterCompleted: () => void;
}

export const useAdaptivePracticeHelper = (): AdaptivePracticeHelperResponseProps => {
  const { toggleModal } = useModal();
  const {
    currentTopic,
    subjectCompleted,
    setCongratulationsMessage,
    setPreviousTopic,
    setNextTopicId,
    setIsReadyForCorrectText,
    setMixedExercise,
  } = useAdaptivePractice();

  const onTopicCompleted = (nextTopicId: number) => {
    if (currentTopic) {
      setCongratulationsMessage('sub-theme');
      setPreviousTopic(currentTopic);
      setNextTopicId(nextTopicId);
    }
  };

  const onAllNormalTopicsCompleted = () => {
    setIsReadyForCorrectText(true);
    toggleModal(modalIds.correctTextQuestionModal);
  };

  const onChapterCompleted = () => {
    setCongratulationsMessage('theme');
    setNextTopicId(null);
  };

  const onSelectedTopicAlreadyCompleted = () => {
    setNextTopicId(null);
    setMixedExercise(true);
  };

  const onSubjectCompleted = () => {
    setCongratulationsMessage('subject');
  };

  useEffect(() => {
    if (subjectCompleted) {
      onSubjectCompleted();
    }
  }, [subjectCompleted]);

  return {
    onTopicCompleted,
    onAllNormalTopicsCompleted,
    onChapterCompleted,
    onSelectedTopicAlreadyCompleted,
  };
};
