import { useCallback } from 'react';
import { matchPath, useLocation } from 'react-router-dom';

interface CurrentPathMatchInterface {
  currentRouteMatches: (expectedPath: string) => boolean;
  currentRouteMatchesAny: (expectedPaths: string[]) => boolean;
}

export const useCurrentPathMatch = (): CurrentPathMatchInterface => {
  const location = useLocation();

  const currentRouteMatches = useCallback(
    (expectedPath: string) => !!matchPath(location.pathname, { exact: true, path: expectedPath }),
    [location.pathname],
  );

  const currentRouteMatchesAny = useCallback(
    (expectedPaths: string[]) => expectedPaths.some(path => currentRouteMatches(path)),
    [location.pathname],
  );

  return {
    currentRouteMatches,
    currentRouteMatchesAny,
  };
};
