import React, { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useDomainHandler } from 'hooks/useDomainHandler';
import { useLogin } from 'hooks/useLogin';
import { PRODUCT_PAGES_ROUTES } from 'constants/routes';
import { buildUrlWithPathParams } from '_helpers/utils/urlBuilder';

import { AccessPageLayout } from 'layouts/AccessPageLayout';
import { Option, SelectedIdType } from 'components/Dropdown/Dropdown.model';

import {
  StyledTitle,
  StyledButton,
  StyledDropdown,
  StyledInput,
  StyledLabel,
  StyledTipMessage,
} from './SchoolDataRequestPage.styled';
import { SchoolType } from 'models/auth/Login';

interface I18nKeys {
  [key: string]: string;
  schoolLabel: string;
  schoolPlaceholder: string;
  educationLabel: string;
  educationPlaceholder: string;
}

const i18nKeysRegularProduct: I18nKeys = {
  schoolLabel: 'schoolDataRequestPage.fields.school.label',
  schoolPlaceholder: 'schoolDataRequestPage.fields.school.placeholder',
  educationLabel: 'schoolDataRequestPage.fields.education.label',
  educationPlaceholder: 'schoolDataRequestPage.fields.education.placeholder',
};

const i18nKeysBusinessProduct: I18nKeys = {
  schoolLabel: 'schoolDataRequestPage.fields.company.label',
  schoolPlaceholder: 'schoolDataRequestPage.fields.company.placeholder',
  educationLabel: 'schoolDataRequestPage.fields.department.label',
  educationPlaceholder: 'schoolDataRequestPage.fields.department.placeholder',
};

export const SchoolDataRequestPage: React.FC = observer(() => {
  const { t } = useTranslation('auth-pages');
  const history = useHistory();
  const { isBusinessDomain } = useDomainHandler();

  const [selectedSchoolId, setSelectedSchoolId] = useState<SelectedIdType>(null);
  const [selectedEducationId, setSelectedEducationId] = useState<SelectedIdType>(null);
  const [studentNumber, setStudentNumber] = useState<string>('');

  const { getSchoolList, schoolList, updateUserInfoLogin, updateUserInfoLoginSucceed } = useLogin();

  useEffect(() => {
    if (!schoolList) {
      getSchoolList(isBusinessDomain() ? SchoolType.COMPANY : SchoolType.SCHOOL);
    }
  }, [schoolList]);

  useEffect(() => {
    if (updateUserInfoLoginSucceed) {
      history.push(buildUrlWithPathParams(PRODUCT_PAGES_ROUTES.PRODUCT_LIST));
    }
  }, [updateUserInfoLoginSucceed]);

  const schoolOptions = useMemo<Option[]>(() => {
    if (!schoolList) {
      return [];
    }

    return schoolList.map(({ id, title }) => ({ id, title }));
  }, [schoolList]);

  const educationOptions = useMemo<Option[]>(() => {
    if (!selectedSchoolId || !schoolList) {
      return [];
    }

    const selectedSchoolEducations = schoolList.find(school => school.id === selectedSchoolId)?.educations;

    return selectedSchoolEducations || [];
  }, [schoolOptions, selectedSchoolId]);

  const onSelectSchool = (schoolId: string | number) => {
    setSelectedSchoolId(schoolId);
    setSelectedEducationId(null);
  };

  const onSelectEducation = (educationId: string | number) => {
    setSelectedEducationId(educationId);
  };

  const onUpdateUserInfo = () => {
    if (selectedEducationId) {
      const studentNumberToSubmit = studentNumber ? studentNumber : 'not-applies';
      updateUserInfoLogin(selectedEducationId.toString(), studentNumberToSubmit);
    }
  };

  const getI18nKeyFor = (key: string): string => {
    return isBusinessDomain() ? i18nKeysBusinessProduct[key] : i18nKeysRegularProduct[key];
  };

  return (
    <AccessPageLayout>
      <StyledTitle>{t('schoolDataRequestPage.title', 'Create an account')}</StyledTitle>
      <StyledLabel data-cy="school-label">
        {t(getI18nKeyFor('schoolLabel'), 'Which organisation do you belong to?')}
      </StyledLabel>
      <StyledDropdown
        dataCy="selector-school"
        defaultTitle={t(getI18nKeyFor('schoolPlaceholder'), 'Select your organisation')}
        options={schoolOptions}
        selectedId={selectedSchoolId}
        onChange={onSelectSchool}
      />
      <StyledLabel data-cy="education-label">
        {t(getI18nKeyFor('educationLabel'), 'What do department do you belong to?')}
      </StyledLabel>
      <StyledDropdown
        dataCy="selector-education"
        defaultTitle={t(getI18nKeyFor('educationPlaceholder'), 'Select your department')}
        disabled={educationOptions.length === 0}
        options={educationOptions}
        selectedId={selectedEducationId}
        onChange={onSelectEducation}
      />
      {!isBusinessDomain() && (
        <>
          <StyledInput
            dataCy="student-number-input"
            placeholder={t('schoolDataRequestPage.fields.studentNumber.placeholder', 'Student number')}
            value={studentNumber}
            onChange={e => setStudentNumber(e.target.value)}
          />
          <StyledTipMessage>
            {t('schoolDataRequestPage.fields.studentNumber.tip', `Only fill in the number, without 'S', 'U' or '000'`)}
          </StyledTipMessage>
        </>
      )}
      <StyledButton
        dataCy="update-user-info-login-button"
        disabled={!selectedEducationId}
        fontColor="white"
        variant="primary"
        onClick={onUpdateUserInfo}
      >
        {t('schoolDataRequestPage.button.submit', 'Last step')}
      </StyledButton>
    </AccessPageLayout>
  );
});
