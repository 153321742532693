import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { TEST_PAGE_ROUTES } from 'constants/routes';
import { buildUrlWithPathParams } from '_helpers/utils/urlBuilder';

import { ActionBanner } from 'components/ActionBanner/ActionBanner';

export interface PathParams {
  productId: string;
  moduleId: string;
}

interface ComponentProps {
  currentGoalLevel?: number | string;
}

export const DiagnosticTestBanner: React.FC<ComponentProps> = ({ currentGoalLevel }) => {
  const { t } = useTranslation('homepage');
  const { productId, moduleId }: PathParams = useParams();
  const history = useHistory();

  const goToDiagnosticTest = () => {
    history.push(
      buildUrlWithPathParams(TEST_PAGE_ROUTES.DIAGNOSTIC_TEST_GROUPS, { productId: productId || 0, moduleId }),
    );
  };

  return (
    <ActionBanner
      actionButtonDisabled={!currentGoalLevel}
      actionButtonTitle={t('diagnosticTest.banner.button')}
      dataCy="start-diagnostic-test-banner"
      iconName="examIcon"
      text={t('diagnosticTest.banner.text')}
      onActionButtonClick={goToDiagnosticTest}
    />
  );
};
