import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

import { useScheduledTests } from 'hooks/useScheduledTests';
import { SvgIconReact } from 'components/SvgIconReact';
import { ScheduledTestTimeLeftStatus } from 'constants/exam-constants';
import { useTestStatusAndTimeLeft } from 'hooks/useTestStatusAndTimeLeft';

import { TimerBarProps } from './TimerBar.model';
import { StyledTimerBar, StyledTimeLeft } from './TimerBar.styled';

export const TimerBar: React.FC<TimerBarProps> = observer(({ duration, code }) => {
  const { scheduledTestStatusAndTimeLeft } = useScheduledTests();
  const { t } = useTranslation('scheduled-tests');

  useTestStatusAndTimeLeft(code);

  const getMinutesLeft = () => {
    const minutesLeft =
      ((scheduledTestStatusAndTimeLeft?.status === ScheduledTestTimeLeftStatus.ONGOING &&
        scheduledTestStatusAndTimeLeft?.counter) ||
        0) / 60;

    return minutesLeft > 0 ? minutesLeft : 0;
  };

  return (
    <StyledTimerBar data-cy="scheduled-test-time-left">
      <SvgIconReact iconName="clockIcon" size={1.5} />
      <StyledTimeLeft timeLeft={(getMinutesLeft() * 100) / duration} />
      {`${Math.round(getMinutesLeft())} ${t('scheduledTestList.item.timeLeft', 'minutes left')}`}
    </StyledTimerBar>
  );
});
