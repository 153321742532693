/**
 *  Replaces placeholder strings of type ":param" in an url by the values
 * specified in the "pathParams" object.
 *
 * @param {string} url - URL that includes path parameter names in ":param"
 *  format.
 *
 * @param {object} pathParams - Object where each key represents the name of
 *  the path parameter, and the value for each of those properties is the
 *  value that shall replace the placeholder in the generated URL.
 *
 * @example
 *  // This code will return '/tags/cats'
 *  buildRouteWithPathParams('/tags/:tagId', { tagId: 'cats' })
 *
 * @returns {string} URL with values assigned to each path param.
 */
export const buildUrlWithPathParams = (url: string, pathParams: Record<string, string | number> = {}): string => {
  let generatedUrl = url;
  Object.keys(pathParams).forEach(name => {
    generatedUrl = generatedUrl.replace(new RegExp(`:${name}`, 'gi'), String(pathParams[name]));
  });
  return generatedUrl;
};

export interface URLFields {
  key: string;
  value?: string | number;
}
/**
 *  Create URL with query parameters based on a set of key - value pairs.
 *
 * @param {string} url - Original URL to which the params will be appended.
 * @param {object[]} fields - Data to send via query params. The objects
 *  in the given array must have the following structure: { key, value }
 *
 * @returns {string} URL with query parameters.
 */
export const buildURLwithQueryParams = (url: string, fields: URLFields[]): string => {
  const stringifiedQueryParams = (fields || []).map(field => `${field.key}=${field.value}`).join('&');

  return `${url}?${stringifiedQueryParams}`;
};

/**
 * Replaces placeholder strings of type ":param" in an url by the values
 * specified in the "pathParams" and "queryParams" objects.
 *
 * @param {string} url - URL that includes path parameter names in ":param"
 *  format.
 *
 * @param {object} pathParams - Object where each key represents the name of
 *  the path parameter, and the value for each of those properties is the
 *  value that shall replace the placeholder in the generated URL.
 *
 * @param {object} queryParams - Object where each key represents the name of
 *  the query parameter, and the value for each of those properties is the
 *  value that shall replace the placeholder in the generated URL.
 *
 * @example
 *  // This code will return '/tags/cats?petId=PET108312'
 *  buildUrlWithPathAndQueryParams('/tags/:tagId', { tagId: 'cats' }, { petId: 'PET108312' })
 *
 * @returns {string} URL with values assigned to each path param and query params.
 */
export const buildUrlWithPathAndQueryParams = (
  url: string,
  pathParams: Record<string, string | number> = {},
  queryParams: Record<string, string | number> = {},
): string => {
  let generatedUrl = url;
  Object.keys(pathParams).forEach(name => {
    generatedUrl = generatedUrl.replace(new RegExp(`:${name}`, 'gi'), String(pathParams[name]));
  });
  const stringifiedQueryParams = Object.keys(queryParams)
    .map(name => `${name}=${queryParams[name]}`)
    .join('&');
  if (stringifiedQueryParams) {
    return `${generatedUrl}?${stringifiedQueryParams}`;
  }
  return `${generatedUrl}`;
};
