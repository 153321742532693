import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { QuestionStatus } from 'constants/exam-constants';

import { EmbeddedHtml } from 'components/EmbeddedHtml';
import { commonSanitizeOptions } from 'layouts/QuestionManager/QuestionManager.constants';

import { TextAreaQuestionAnswer, TextAreaQuestionProps } from './TextAreaQuestion.model';
import { StyledTextAreaQuestion, StyledTextAreaInput } from './TextAreaQuestion.styled';
import { QuestionsManagerPathParams } from '../../QuestionManager.model';
import { useAnswer } from 'hooks/useAnswer';

export const TextAreaQuestion: React.FC<TextAreaQuestionProps> = ({
  answerFeedbackComponent: answerComponent,
  defaultValue = '',
  evaluateAnswer,
  question,
  status,
  isEvaluatingAnswer,
  disableSpellCheck = false,
  prevAnswerValues,
}) => {
  const { t } = useTranslation('skill-determination-test');
  const { setAnswerButtonProps } = useAnswer();
  const prevCurrentAnswers =
    prevAnswerValues && prevAnswerValues.length > 0
      ? prevAnswerValues?.map(({ value }) => value).toString()
      : defaultValue;
  const [currentAnswer, setCurrentAnswer] = useState<TextAreaQuestionAnswer>('');
  const { productId, exerciseId }: QuestionsManagerPathParams = useParams();
  const setExerciseIdValue = !exerciseId ? undefined : +exerciseId;

  useEffect(() => {
    setCurrentAnswer(prevCurrentAnswers);
  }, [prevCurrentAnswers]);

  // Set configuration for answer button
  useEffect(() => {
    setAnswerButtonProps({
      label: t('button.checkAnswer.label', 'Check answer'),
      isDisabled: currentAnswer === '',
      isHidden: status !== QuestionStatus.INITIAL,
      isLoading: isEvaluatingAnswer || false,
      onClick: skipAnswerValidation => {
        evaluateAnswer &&
          evaluateAnswer(
            +productId,
            question.id,
            [{ order: 0, value: currentAnswer }],
            setExerciseIdValue,
            skipAnswerValidation,
          );
      },
    });
  }, [currentAnswer, isEvaluatingAnswer, status, setExerciseIdValue, productId, question]);

  return (
    <StyledTextAreaQuestion status={status}>
      <EmbeddedHtml rawHtml={question.content} sanitizeOptions={commonSanitizeOptions} tagName="section" />
      <div className="answer-container">
        <StyledTextAreaInput
          dataCy="text-area-question"
          id="text-area-question"
          name="text-area-question"
          readOnly={status !== QuestionStatus.INITIAL}
          spellCheck={!disableSpellCheck}
          value={currentAnswer}
          onChange={e => setCurrentAnswer(e.target.value)}
        />
        {answerComponent}
      </div>
    </StyledTextAreaQuestion>
  );
};
