import styled from 'styled-components/macro';

import { Button } from 'components/Button';
import { Modal } from 'components/Modal';

import { ThemeProps as Props } from 'models/styles/theme';

import { Heading3 } from 'styles/elements/Headings';

export const StyledModal = styled(Modal)`
  > div {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    padding: 2.5rem;
    align-items: center;
  }
`;

export const StyledButtonsContainer = styled.div`
  display: flex;
  column-gap: 2rem;
`;

export const StyledSubtitle = styled(Heading3)`
  margin: 0 1.5rem;
  line-height: 1.8rem;
`;

export const StyledButton = styled(Button)`
  font-size: ${(props: Props) => props.theme.font.size._14};
  padding: 12px 16px;
  border-radius: 4px;
  width: 7.3rem;
  height: 3rem;
  min-width: fit-content;
  max-width: 10rem;
  min-height: fit-content;
`;
