import React from 'react';
import { LevelProgressBar, LevelProgressBarProps } from '../LevelProgressBar/LevelProgressBar';
import { StyledOutlinedProgressBar, StyledPercentage, StyledWrapper } from './OutlinedLevelProgressBar.styled';

export interface OutlinedLevelProgressBarProps extends LevelProgressBarProps {
  dataCy?: string;
  showPercentage?: boolean;
  $width?: string;
  $fontSize?: string;
}

export const OutlinedLevelProgressBar: React.FC<OutlinedLevelProgressBarProps> = ({
  dataCy,
  currentLevel,
  maxLevel = 100,
  showPercentage = false,
  $width = '10rem',
  $fontSize = '1rem',
}) => (
  <StyledWrapper data-cy={dataCy}>
    <StyledOutlinedProgressBar $width={$width}>
      <LevelProgressBar borderRadius="1rem" currentLevel={currentLevel} maxLevel={maxLevel} />
    </StyledOutlinedProgressBar>
    {showPercentage && (
      <StyledPercentage $fontSize={$fontSize}>{`${Math.round((currentLevel / maxLevel) * 100)}%`}</StyledPercentage>
    )}
  </StyledWrapper>
);
