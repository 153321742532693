import React from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import { ROLES } from '@he-learning/service-he-common';
import { ROLE } from 'persistence';
import { TEST_ENVIRONMENT_ROUTES } from 'constants/routes';

import { useRedirect } from 'hooks/useRedirect';

import { RoleComponent } from 'layouts/RoleComponent';

import { StyledSection, StyledButton, StyledText } from './TestEnvMenuSection.styled';

export const TestEnvMenuSection: React.FC = observer(() => {
  const { redirectToUrl } = useRedirect();
  const { t } = useTranslation('homepage');

  return (
    <RoleComponent allowedRoles={[ROLES.STUDENT as ROLE, ROLES.TEACHER as ROLE]}>
      <StyledSection>
        <StyledText>{t('sideMenu.testEnvResults.title', 'Do you need to access a test')}</StyledText>
        <StyledButton
          dataCy="test-env-button"
          variant="brand"
          onClick={() => redirectToUrl(TEST_ENVIRONMENT_ROUTES.LANDING_PAGE)}
        >
          {t('sideMenu.testEnvResults.button', 'Go to test environment')}
        </StyledButton>
      </StyledSection>
    </RoleComponent>
  );
});
