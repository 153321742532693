import styled, { css } from 'styled-components/macro';
import { Heading2 } from 'styles/elements/Headings';
import { ThemeProps as Props } from 'models/styles/theme';

export const StyledPageContainer = styled.main<Props & { $hasSideFilters?: boolean }>`
  margin-bottom: 4rem;
  ${({ $hasSideFilters = false }) =>
    $hasSideFilters &&
    css`
      display: flex;
      height: 100%;

      > div:first-child {
        flex-grow: 1;
      }
    `}
`;

export const StyledPageHeader = styled(Heading2)`
  text-align: left;
  font-weight: normal;
  display: flex;
  align-items: center;
`;
