import { FontAwesomeIcon } from 'components/FontAwesomeIcon';
import React, { FC, KeyboardEvent, useEffect, useState } from 'react';
import { object, string } from 'yup';
import { useTranslation } from 'react-i18next';
import { useDomainHandler } from 'hooks/useDomainHandler';
import { onPaste } from '../CreateAccount/CreateAccount.helper';
import {
  StyledInvalidEmail,
  StyledEmailsBox,
  StyledEmailBox,
  StyledRemoveEmailIcon,
  StyledEmailInput,
  StyledEmailInputBox,
} from './MultipleEmailInput.styled';

export interface MultipleEmailInputProps {
  width: string;
  selectedEmails: EmailType[];
  onSelectedEmailsChanged: (emails: EmailType[]) => void;
}

export type EmailType = {
  email: string;
  isIncorrect: boolean;
};

const emailSchema = object({
  email: string().email(),
});

export const MultipleEmailInput: FC<MultipleEmailInputProps> = ({ width, selectedEmails, onSelectedEmailsChanged }) => {
  const [emailList, setEmailList] = useState<EmailType[]>(selectedEmails);
  const [newEmail, setNewEmail] = useState<string>('');
  const { getStudentTermByDomain } = useDomainHandler();

  const { t } = useTranslation('teacher-dashboard');
  const { t: tToast } = useTranslation('toast');

  useEffect(() => {
    if (emailList.length > 0) {
      onSelectedEmailsChanged(emailList);
    }
  }, [emailList]);

  const updateEmailList = (emails: string[]) => {
    const uniqueEmails = Array.from(new Set(emails.filter(email => !!email)));
    const newEmails: EmailType[] = uniqueEmails.map(email => ({
      email: email.trim(),
      isIncorrect: !emailSchema.isValidSync({ email }),
    }));
    setEmailList([...emailList, ...newEmails]);
  };

  const onKeyDownHandler = async (event: KeyboardEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (event.ctrlKey && event.key === 'v') {
      await onPaste(tToast, updateEmailList);
    }
  };

  const onRemoveEmailFromList = (email: string) => {
    setEmailList(emailList.filter(currEmail => currEmail.email !== email));
  };

  return (
    <>
      <StyledEmailsBox
        contentEditable
        suppressContentEditableWarning
        alignContent="flex-start"
        data-cy="create-account-emails"
        flexWrap="wrap"
        height="15rem"
        mt={2}
        padding={1}
        width={width}
        onKeyDown={e => onKeyDownHandler(e)}
        onPaste={e => onPaste(tToast, updateEmailList, e)}
      >
        {emailList.length === 0 &&
          t('studentLicense.createAccounts.createAccountsAction.placeholder', {
            studentTerm: getStudentTermByDomain({ usePlural: true }),
          })}
        {emailList.map(emailType => (
          <StyledEmailBox
            key={`wrapper-${emailType.email}`}
            isIncorrect={!emailSchema.isValidSync({ email: emailType.email })}
          >
            <StyledRemoveEmailIcon
              key={`icon-${emailType.email}`}
              iconName="fal fa-times"
              onClick={() => onRemoveEmailFromList(emailType.email)}
            />
            <span key={emailType.email}>{emailType.email}</span>
          </StyledEmailBox>
        ))}
        {emailList.some(emailType => emailType.isIncorrect) && (
          <StyledInvalidEmail>{t('studentLicense.createAccounts.invalid')}</StyledInvalidEmail>
        )}
      </StyledEmailsBox>
      <StyledEmailInputBox alignItems="center" justifyContent="space-between" padding={1} width={width}>
        <StyledEmailInput
          data-cy="manually-input-email"
          placeholder={t('studentLicense.createAccounts.createAccountsAction.manually')}
          value={newEmail}
          onChange={e => setNewEmail(e.target.value)}
        />
        <FontAwesomeIcon
          data-cy="add-manual-email"
          iconName="far fa-plus"
          onClick={() => {
            updateEmailList([newEmail]);
            setNewEmail('');
          }}
        />
      </StyledEmailInputBox>
    </>
  );
};
