import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import { modalIds } from 'constants/modals-constants';
import { ProductType } from 'constants/hst-constants';

import { ProductSkillLevel } from 'models/product/ProductDetails';
import { UserProgressPerSubject } from 'models/progress/Progress';

import { useModal } from 'hooks/useModal';
import { useProduct } from 'hooks/useProduct';
import { useProfile } from 'hooks/useProfile';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { useDomainHandler } from 'hooks/useDomainHandler';

import { StatCard } from 'components/StatCard';
import { StudentTargetSelectorModal } from '../StudentTargetSelectorModal/StudentTargetSelectorModal';

import { StyledEditIcon } from './AccountOverallProgress.styled';

export interface AccountOverallProgressProps {
  subjects: UserProgressPerSubject[];
  goalLevel: string;
  avgProgress: number;
  productId: number;
  accountId: number;
}

export const AccountOverallProgress: React.FC<AccountOverallProgressProps> = observer(
  ({ accountId, productId, subjects, goalLevel, avgProgress }) => {
    const { t } = useTranslation('teacher-dashboard');
    const { toggleModal } = useModal();
    const { userDetails } = useProfile();
    const { productDetails } = useProduct();
    const { getStudentTermByDomain } = useDomainHandler();
    const { showStudentGoalLevelSelector } = useFeatureFlags();

    const completedSubjectsCount = subjects.filter(({ progress }) => progress === 100).length;

    // Should only allow teachers to edit the goal level of the student if they have enough permissions
    // This feature will be available for business products alone (for now, at least)
    const shouldShowEditGoalLevelButton = useMemo(() => {
      if (productDetails?.id === productId) {
        const isBusinessProduct = productDetails?.type === ProductType.BUSINESS;
        return showStudentGoalLevelSelector && userDetails?.canSetStudentTargetLevel && isBusinessProduct;
      }

      return false;
    }, [productId, productDetails]);

    // Obtain list of skill levels that can be chosen as a goal for the selected product
    const selectableGoalLevels: ProductSkillLevel[] = useMemo(() => {
      if (productDetails?.id === productId) {
        return productDetails?.levels.filter(level => level.selectable) || [];
      }
      return [];
    }, [productId, productDetails]);

    // Obtain numeric value associated to the current goal level of the user (will be used to initialize the switch level form)
    const currentGoalLevel = useMemo(() => {
      const [currentGoal] = selectableGoalLevels.filter(level => level.label === goalLevel) || [];
      return currentGoal?.level;
    }, [selectableGoalLevels, goalLevel]);

    const cards = [
      {
        icon: 'fas fa-bullseye-arrow',
        title: t('basicProgress.forAccount.quickProgressStats.goalLevel', {
          studentTerm: getStudentTermByDomain(),
        }),
        content: (
          <>
            <span data-cy="student-goal-level">{goalLevel}</span>
            {/* Show button to switch student's level only if teacher has enough permissions */}
            {shouldShowEditGoalLevelButton && (
              <>
                <StyledEditIcon
                  data-cy="edit-student-goal-level-button"
                  iconName="fas fa-pencil"
                  marginLeft={1}
                  onClick={() => toggleModal(modalIds.studentTargetSelectorModal)}
                />
                <StudentTargetSelectorModal
                  accountId={accountId}
                  currentGoalLevel={currentGoalLevel}
                  productId={productId}
                  selectableGoalLevels={selectableGoalLevels}
                />
              </>
            )}
          </>
        ),
      },
      {
        icon: 'fas fa-star',
        title: t('basicProgress.forAccount.quickProgressStats.completedSubjects'),
        content: `${completedSubjectsCount} / ${subjects.length}`,
      },
      {
        icon: 'fas fa-chart-line',
        dataCy: 'progress-towards-goal',
        title: t('basicProgress.forAccount.quickProgressStats.progressTowardsGoal'),
        content: `${avgProgress}%`,
      },
    ];

    return (
      <>
        {cards.map(({ icon, dataCy, title, content }) => (
          <StatCard key={title} dataCy={dataCy} iconName={icon} label={title} value={content} />
        ))}
      </>
    );
  },
);
