import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { modalIds } from 'constants/modals-constants';
import { useModal } from 'hooks/useModal';
import { useDomainHandler } from 'hooks/useDomainHandler';
import { Heading2 } from 'styles/elements/Headings';

import { Modal } from 'components/Modal';
import { TestAccessFormFields } from '../TestAccessForm/TestAccessForm.schema';

import { StyledIcon, StyledInput, StyledButton, StyledParagraph, StyledPasswordField } from './PasswordModal.styled';

export const RequestPasswordModal: React.FC = () => {
  const { t } = useTranslation('test-environment');
  const [showPassword, setShowPassword] = useState(false);

  const { closeModal, isModalOpen } = useModal();
  const { getTeacherTermByDomain } = useDomainHandler();

  const formik = useFormikContext<TestAccessFormFields>();

  const passwordRequiredModalId = modalIds.passwordRequiredModal;
  const isPasswordModalOpen = isModalOpen(passwordRequiredModalId);

  // Set flag "requiresPassword" to "true" to ensure users fills in password
  // if the modal happens to be on screen
  useEffect(() => {
    formik.setFieldValue('requiresPassword', isPasswordModalOpen, false);
  }, [isPasswordModalOpen]);

  return (
    <Modal
      wrapInModalBox
      dataCy={passwordRequiredModalId}
      isOpen={isPasswordModalOpen}
      onClose={() => {
        closeModal();
        formik.setFieldValue('password', '');
      }}
    >
      <Heading2>{t('passwordRequiredModal.title', 'You need a password to open this test')}</Heading2>
      <StyledParagraph>
        {t('passwordRequiredModal.description', { teacherTerm: getTeacherTermByDomain() })}
      </StyledParagraph>

      <StyledPasswordField>
        <StyledInput
          dataCy="test-password-input"
          disabled={formik.isSubmitting}
          errorMessage={formik.errors.password}
          hasError={!!(formik.errors.password && formik.touched.password)}
          id="test-password"
          name="password"
          placeholder={t('passwordRequiredModal.fields.password.placeholder', 'Password')}
          type={showPassword ? 'text' : 'password'}
          value={formik.values.password}
          onChange={formik.handleChange}
        />
        <StyledIcon
          iconName={`fal ${!showPassword ? 'fa-eye' : 'fa-eye-slash'}`}
          onClick={() => setShowPassword(!showPassword)}
        />
      </StyledPasswordField>

      <StyledButton
        dataCy="submit-password-button"
        variant="primary"
        onClick={() => {
          formik.validateForm().then(() => {
            if (formik.isValid) {
              closeModal();
              formik.submitForm();
            }
          });
        }}
      >
        {t('passwordRequiredModal.buttons.submit.label', 'Go to the test')}
      </StyledButton>
    </Modal>
  );
};
