import React from 'react';

import { Heading4 } from 'styles/elements/Headings';

import { StyledCard, StyledCardHeader, StyledCardContent } from './CardWithTitleBar.styled';

interface CardWithTitleBarProps {
  className?: string;
  dataCy?: string;
  title: string;
  extraTitleBarContent?: React.ReactNode;
}

/**
 *  Component used to render a panel, consisting on a card with a title bar.
 *
 *  The user must specify the title to display in the bar, and can optionally pass
 * some JSX code to display a custom element at the right side of the header.
 *
 *  This component will receive the panel contents as children elements, like portrayed
 * in below's example:
 *
 *    <CardWithTitleBar title="My panel">
 *      <p>This is the content of my panel</p>
 *      <p>There can be multiple elements inside</p>
 *    </CardWithTitleBar>
 *
 */
export const CardWithTitleBar: React.FC<CardWithTitleBarProps> = ({
  children,
  className,
  dataCy,
  title,
  extraTitleBarContent = null,
}) => (
  <StyledCard className={className} data-cy={dataCy} flexDirection="column">
    <StyledCardHeader alignItems="center" flexDirection="row" gap={1} justifyContent="space-between">
      <Heading4 data-cy={`${dataCy}-title`}>{title}</Heading4>
      {extraTitleBarContent}
    </StyledCardHeader>
    <StyledCardContent flexDirection="column" flexGrow={1} gap={1} justifyContent="space-between">
      {children}
    </StyledCardContent>
  </StyledCard>
);
