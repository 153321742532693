import styled from 'styled-components/macro';
import { ThemeProps as Props } from 'models/styles/theme';
import { Link } from 'react-router-dom';

export const GroupsWrapper = styled.main<Props>`
  display: flex;
  flex-wrap: wrap;

  > * {
    margin: 0.4rem;
  }

  button:hover svg {
    color: ${(props: Props) => props.theme.palette.accent._02};
  }
`;

export const NewGroup = styled(Link)<Props>`
  width: 195px;
  min-height: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 4px dashed ${(props: Props) => props.theme.palette.neutral._400};
  border-radius: 8px;
  color: ${(props: Props) => props.theme.palette.neutral._400};
  text-decoration: none;

  > span {
    display: block;
    margin-top: 1rem;
    font-size: ${(props: Props) => props.theme.font.size._14};
    color: ${(props: Props) => props.theme.palette.neutral.black};
  }

  &:hover {
    border-color: ${(props: Props) => props.theme.palette.neutral._600};
    color: ${(props: Props) => props.theme.palette.neutral._600};
  }
  &:active {
    border-color: ${(props: Props) => props.theme.palette.neutral.black};
    color: ${(props: Props) => props.theme.palette.neutral.black};
  }
`;
