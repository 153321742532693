import React, { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { OwnedTestResultsTable } from '../OwnedScheduledTestResultsOverview/components/OwnedTestResultsTable/OwnedTestResultsTable';
import i18n from 'i18n';
import { AvailableLanguage } from 'constants/language-constants';
import { useScheduledTests } from 'hooks/useScheduledTests';
import { useParams } from 'react-router-dom';
import { OwnedScheduledTestResultsOverviewItem } from 'models/exam/ScheduledTest';
import { ForAccountHeading } from 'pages/TeacherEnvironment/TeacherDashboard/components/ForAccountHeading/ForAccountHeading';
import { SkillsSelectorType } from 'constants/teacher-constants';
import { TEACHER_SCHEDULED_TEST_PAGE_ROUTES } from 'constants/routes';
import { BreadcrumbsRoute } from 'components/Breadcrumbs/Breadcrumbs.model';
import { Box } from 'styles/helpers/layout';
import { SkeletonLoader } from 'components/SkeletonLoader/SkeletonLoader';
import { Heading3 } from 'styles/elements/Headings';
import { useProfile } from 'hooks/useProfile';

interface Params {
  accountId: string;
}

export const ScheduledTestResultsForAccountPage: React.FC = observer(() => {
  const { t } = useTranslation('teacher-dashboard');
  const { fetchUsersByEducationsForTeacher, usersByEducations } = useProfile();
  const { loading, scheduledTestsResultsStudent, fetchScheduledTestsResultsStudent } = useScheduledTests();
  const { accountId }: Params = useParams();

  useEffect(() => {
    accountId && fetchScheduledTestsResultsStudent(+accountId);
  }, [accountId]);

  useEffect(() => {
    fetchUsersByEducationsForTeacher();
  }, []);

  const results = useMemo(
    () =>
      scheduledTestsResultsStudent?.testResults.map<OwnedScheduledTestResultsOverviewItem>(val => ({
        id: val.scheduledTestId,
        requiredPassingScore: val.passingScore,
        title: val.scheduledTestTitle,
        skillLevel: val.skillLevel,
        avgScore: Math.round(val.score),
        startDate: val.startDate,
        type: val.testType,
        numSessions: 0,
      })) ?? [],
    [scheduledTestsResultsStudent],
  );

  if (loading) {
    return <SkeletonLoader $marginTop={2} />;
  }

  if (!scheduledTestsResultsStudent) return null;

  const { email, firstName, middleName, lastName, studentNumber } = scheduledTestsResultsStudent;
  const fullName = `${firstName} ${middleName ?? ''} ${lastName}`;

  const breadCrumbRoutes: BreadcrumbsRoute[] = [
    {
      path: TEACHER_SCHEDULED_TEST_PAGE_ROUTES.SCHEDULED_TEST_RESULTS_OVERVIEW,
      breadcrumb: t('ownedScheduledTestResults.overview.title'),
    },
    {
      path: TEACHER_SCHEDULED_TEST_PAGE_ROUTES.SCHEDULED_TESTS_RESULTS_FOR_STUDENT,
      breadcrumb: fullName,
    },
  ];

  return (
    <ForAccountHeading
      breadCrumbRoutes={breadCrumbRoutes}
      email={email}
      fullName={fullName}
      skillType={SkillsSelectorType.SCHEDULED_TEST_RESULTS}
      studentIds={usersByEducations?.map(user => user.id)}
      studentNumber={studentNumber || ''}
    >
      <Box mt={1}>
        {results.length === 0 ? (
          <Heading3 mt={2}>{t('scheduledTestsResults.forAccount.noResults', { fullName })}</Heading3>
        ) : (
          <OwnedTestResultsTable
            language={i18n.language as AvailableLanguage}
            ownedScheduledTestResults={results}
            scoreIsAverage={false}
            specificStudentId={+accountId}
          />
        )}
      </Box>
    </ForAccountHeading>
  );
});
