import { observer } from 'mobx-react';
import { BasicProgressForAccount, UserInfo } from 'models/progress/Progress';
import React, { FC, useEffect } from 'react';
import { StyledPageContainer } from '../BasicProgress/BasicProgress.styled';
import { useParams } from 'react-router-dom';
import { Formik } from 'formik';
import { ReactComponent as UserIcon } from 'assets/icons/user.svg';

import {
  StyledField,
  StyledInput,
  StyledLoadingWrapper,
  StyledPageHeader,
  StyledPageHeaderContainer,
  StyledUserInfoText,
  StyledUserInfoTextData,
} from './EditAccount.styled';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/Button';
import { BackButton } from 'components/BackButton';
import { useProductProgress } from 'hooks/useProductProgress';
import { useProfile } from 'hooks/useProfile';
import { buildUpdateFormSchema } from './EditStudentAccountForm.schema';
import { LoadingSpinner } from 'components/LoadingSpinner';
import { buildUrlWithPathParams } from '_helpers/utils/urlBuilder';
import { TEACHER_INSIGHT_PAGE_ROUTES } from 'constants/routes';
import { trackPlatformEvent } from 'analytics/MetabaseTracker/metabase-tracker';
import { TEACHER_MODULES } from 'analytics/constants/pageTypes';
import { PLATFORM_EVENT_TYPES } from 'analytics/constants/platformEventTypes';

interface PathParams {
  accountId: string;
  productId: string;
}

const getFullName = (account: UserInfo) =>
  account.middleName
    ? `${account.firstName} ${account.middleName} ${account.lastName}`
    : `${account.firstName} ${account.lastName}`;

export const EditAccountPage: FC = observer(() => {
  const { productId, accountId }: PathParams = useParams();
  const { t } = useTranslation('teacher-dashboard');
  const { fetchProductProgressForAccount, productProgressForAccount, setProductProgressForAccount } =
    useProductProgress();
  const { updateStudentDetails } = useProfile();

  useEffect(() => {
    fetchProductProgressForAccount(+productId, +accountId);
    return () => setProductProgressForAccount({} as BasicProgressForAccount);
  }, [accountId, fetchProductProgressForAccount, accountId]);

  const backUrl = buildUrlWithPathParams(TEACHER_INSIGHT_PAGE_ROUTES.PROGRESS_FOR_ACCOUNT, {
    productId,
    accountId,
  });

  if (!productProgressForAccount || !(Object.keys(productProgressForAccount).length > 0)) {
    return (
      <StyledLoadingWrapper>
        <LoadingSpinner />
      </StyledLoadingWrapper>
    );
  }

  return (
    <StyledPageContainer>
      <BackButton
        dataCy="back-to-overview-button"
        label={t('studentDetailEdit.buttons.back', 'Back')}
        redirectUrl={backUrl}
      />
      <Formik
        enableReinitialize
        initialValues={{
          studentNumber: productProgressForAccount.account.studentNumber,
        }}
        validationSchema={buildUpdateFormSchema()}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          const payload = {
            studentNumber: values.studentNumber,
          };

          trackPlatformEvent({
            module: TEACHER_MODULES.BASIC_PROGRESS_FOR_ACCOUNT,
            itemId: PLATFORM_EVENT_TYPES.BASIC_PROGRESS_FOR_ACCOUNT_EVENT_TYPES.UPDATE_STUDENT_DETAILS_AS_TEACHER,
            value: JSON.stringify(payload),
          });

          updateStudentDetails(accountId, payload);
          setSubmitting(false);
          // reset dirty state, so that the save button is disabled once again
          if (values.studentNumber) resetForm({ values });
        }}
      >
        {formik => {
          return (
            <form noValidate onSubmit={formik.handleSubmit}>
              <StyledPageHeaderContainer>
                <StyledPageHeader>
                  <UserIcon /> {t('studentDetailEdit.editProfile', 'Edit Profile')}{' '}
                  <span data-cy="student-account-name">{getFullName(productProgressForAccount.account)}</span>
                </StyledPageHeader>
                <Button
                  dataCy="save-account-button"
                  disabled={!(formik.isValid && formik.dirty) || formik.isSubmitting}
                  variant="primary"
                >
                  {t('studentDetailEdit.buttons.save', 'Save')}
                </Button>
              </StyledPageHeaderContainer>
              <StyledUserInfoText>
                {t('studentDetailEdit.inputs.email.label')}:{' '}
                <StyledUserInfoTextData data-cy="student-account-email">
                  {productProgressForAccount.account.email}
                </StyledUserInfoTextData>
              </StyledUserInfoText>
              <StyledUserInfoText>
                {t('studentDetailEdit.inputs.goalLevel.label')}:{' '}
                <StyledUserInfoTextData data-cy="student-account-goalLevel">
                  {productProgressForAccount.account.goalLevel}
                </StyledUserInfoTextData>
              </StyledUserInfoText>
              <StyledField>
                <label htmlFor="studentNumber">
                  {t('studentDetailEdit.inputs.studentNumber.label', 'Student Number')}
                </label>
                <StyledInput
                  dataCy="account-student-number"
                  errorMessage={formik.errors.studentNumber}
                  hasError={!!(formik.errors.studentNumber && formik.touched.studentNumber)}
                  name="studentNumber"
                  value={formik.values.studentNumber}
                  onChange={formik.handleChange}
                />
              </StyledField>
            </form>
          );
        }}
      </Formik>
    </StyledPageContainer>
  );
});
