import React, { useState } from 'react';
import { StyledAccordionItem } from './AccordionItem.styled';
import { AccordionItemProps } from './AccordionItem.model';
import { CssFlex } from 'styles/helpers/layout';
import { SanitizedHtml } from 'components/SanitizedHtml';

export const AccordionItem: React.FC<AccordionItemProps> = ({
  title,
  description,
  children,
  headerPanelRight = null,
  basic = false,
  dataCy,
  expandedByDefault = false,
  className,
}) => {
  const [collapsed, setCollapsed] = useState(!expandedByDefault);

  return (
    <StyledAccordionItem basic={basic} className={className} collapsed={collapsed} data-cy={dataCy}>
      <div
        className="tab-label"
        role="button"
        tabIndex={0}
        onClick={() => setCollapsed(!collapsed)}
        onKeyPress={() => setCollapsed(!collapsed)}
      >
        <CssFlex className="tab-title" flexDirection="column" flexGrow={1} rowGap={0.25}>
          <h3 className="tab-title-text" data-cy={`${dataCy}-title`}>
            {title}
          </h3>
          {description && (
            <SanitizedHtml className="description" dataCy={`${dataCy}-description`} dirtyHtml={description} />
          )}
        </CssFlex>
        {headerPanelRight && <div className="header-panel-right">{headerPanelRight}</div>}
      </div>
      <div className="tab-content">{children}</div>
    </StyledAccordionItem>
  );
};
