import React from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import { RecentProgress } from 'models/results/Results';

import { Heading3 } from 'styles/elements/Headings';
import { CssFlex, CssGrid, Typography } from 'styles/helpers/layout';

import { GoalLevelBox } from '../GoalLevelBox/GoalLevelBox';
import { ProgressStatBox } from '../ProgressStatBox/ProgressStatBox';

interface RecentProgressProps {
  recentProgress?: RecentProgress | null;
}

export const RecentProgressSummary: React.FC<RecentProgressProps> = observer(({ recentProgress }) => {
  const { t } = useTranslation('homepage');

  return (
    <CssGrid gap={1} gridTemplateColumns="1fr 3fr">
      <GoalLevelBox />
      <CssGrid data-cy="recent-progress-box" gap={1} gridTemplateColumns="repeat(3, 1fr)">
        <ProgressStatBox
          title={t('subjects.recentProgress.totalAnswered', 'Totaal beantwoorde vragen')}
          value={<Heading3 data-cy="total-answered-questions">{recentProgress?.totalAnswered || 0}</Heading3>}
        />
        <ProgressStatBox
          title={t('subjects.recentProgress.sessionCorrectAnswers', 'Goede antwoorden in deze sessie')}
          value={
            <CssFlex flexDirection="row">
              <Heading3 data-cy="correct-answers-in-current-session">
                {recentProgress?.currentSessionCorrect || 0}
              </Heading3>
              <Typography data-cy="total-answers-in-current-session">
                {`/${recentProgress?.currentSessionQuestions || 0}`}
              </Typography>
            </CssFlex>
          }
        />
        <ProgressStatBox
          title={t('subjects.recentProgress.lastTopic', 'Onlangs behaald')}
          value={
            <Heading3 data-cy="total-last-practiced-subject">
              {recentProgress?.lastPracticedTopic ? recentProgress.lastPracticedTopic : '-'}
            </Heading3>
          }
        />
      </CssGrid>
    </CssGrid>
  );
});
