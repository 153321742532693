import React from 'react';
import { ProductsDropdownProps } from './ProductsDropdown.model';
import { StyledDropdownProducts, StyledProductRow } from './ProductsDropdown.styled';
import { FontAwesomeIcon } from 'components/FontAwesomeIcon';
import { ProductType } from 'constants/hst-constants';

export const ProductsDropdown: React.FC<ProductsDropdownProps> = ({ products, selectedProductId, onSelectProduct }) => {
  const productOptions =
    products?.map(product => ({
      id: product.id,
      title: (
        <StyledProductRow $productType={product.type}>
          <FontAwesomeIcon
            fixedWidth
            iconName={product.type === ProductType.BUSINESS ? 'fad fa-car-building' : 'fad fa-graduation-cap'}
          />
          {product.title}
        </StyledProductRow>
      ),
    })) || [];
  return (
    <StyledDropdownProducts
      dataCy="product-selector"
      dataCyTitle="product-selector-title"
      defaultTitle={products?.find(p => p.id === selectedProductId)?.title}
      options={productOptions}
      selectedId={selectedProductId || null}
      onChange={id => onSelectProduct(id as number)}
    />
  );
};
