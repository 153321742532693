import React, { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Redirect, Switch, matchPath, useHistory, useLocation } from 'react-router-dom';

import { useProfile } from 'hooks/useProfile';
import { useLanguage } from 'hooks/useSelectedLanguage';

import { AUTH_PAGES_ROUTES, TEACHER_HOME_PAGE_ROUTES } from 'constants/routes';

import { TabFilter } from 'pages/AdminEnvironment/AdminPage/components/TabFilter/TabFilter';
import { InvitationList } from './subpages/InvitationList/InvitationList';
import { AuthRoute } from 'layouts/AuthRoute';
import { CreateInviteForm } from './subpages/CreateInviteForm/CreateInviteForm';
import { Box } from 'styles/helpers/layout';

enum InviteTabs {
  CREATE_INVITE = 'new-invitation',
  SENT_INVITE_LIST = 'invitation-list',
}

export const StudentLicense: React.FC = observer(() => {
  const history = useHistory();
  const location = useLocation();
  const { currentLanguage } = useLanguage();
  const { t } = useTranslation('teacher-dashboard');

  const [activeTab, setActiveTab] = useState(InviteTabs.CREATE_INVITE);

  const { userDetails, fetchUserDetails } = useProfile();

  useEffect(() => {
    if (!userDetails) fetchUserDetails();
  }, [userDetails]);

  useEffect(() => {
    const currentRouteMatches = (expectedPath: string) =>
      !!matchPath(location.pathname, { exact: true, path: expectedPath });

    setActiveTab(
      currentRouteMatches(TEACHER_HOME_PAGE_ROUTES.SENT_INVITE_LIST)
        ? InviteTabs.SENT_INVITE_LIST
        : InviteTabs.CREATE_INVITE,
    );
  }, [location.pathname]);

  const tabsFilterValues = useMemo(
    () => [
      {
        value: InviteTabs.CREATE_INVITE,
        dataCy: 'new-invitation-filter',
        text: t('studentLicense.inviteStudents.newInvitation'),
      },
      {
        value: InviteTabs.SENT_INVITE_LIST,
        dataCy: 'invitation-list-filter',
        text: t('studentLicense.invitationList.title'),
      },
    ],
    [currentLanguage],
  );

  const onTabSelected = (selectedTab: string) => {
    switch (selectedTab) {
      case InviteTabs.SENT_INVITE_LIST:
        return history.push(TEACHER_HOME_PAGE_ROUTES.SENT_INVITE_LIST);
      default:
        return history.push(TEACHER_HOME_PAGE_ROUTES.CREATE_INVITE);
    }
  };

  if (!userDetails?.allowedInviteProducts) {
    return <></>;
  }

  return (
    <Box>
      <TabFilter
        $justifyContent="flex-start"
        $marginBottom="2rem"
        selectedTab={activeTab}
        tabsFiltersValues={tabsFilterValues}
        onTabChange={selectedTab => onTabSelected(selectedTab)}
      />
      <Switch>
        <AuthRoute
          exact
          component={CreateInviteForm}
          path={TEACHER_HOME_PAGE_ROUTES.CREATE_INVITE}
          redirectPath={AUTH_PAGES_ROUTES.LOG_IN}
        />
        <AuthRoute
          exact
          component={InvitationList}
          path={TEACHER_HOME_PAGE_ROUTES.SENT_INVITE_LIST}
          redirectPath={AUTH_PAGES_ROUTES.LOG_IN}
        />
        <Redirect to={TEACHER_HOME_PAGE_ROUTES.CREATE_INVITE} />
      </Switch>
    </Box>
  );
});
