import styled from 'styled-components/macro';
import { Button } from 'components/Button';
import { ThemeProps } from 'models/styles/theme';
import { Paragraph } from 'styles/elements/Texts';
import { calculateRem } from 'styles/mixins/mixins';
import { Heading2 } from 'styles/elements/Headings';

export const StyledIcon = styled.div`
  color: ${({ theme }: ThemeProps) => theme.palette.primary.brand};
  margin: ${calculateRem(24)} 0;
`;

export const StyledHeading = styled(Heading2)`
  white-space: pre-wrap;
  line-height: ${calculateRem(40)};
`;

export const StyledContent = styled(Paragraph)`
  color: ${(props: ThemeProps) => props.theme.palette.neutral._900};
  font-style: normal;
  font-weight: normal;
  line-height: ${calculateRem(24)};
  text-align: center;
  white-space: pre-wrap;
  margin-bottom: ${calculateRem(24)};
`;

export const StyledButton = styled(Button)`
  width: ${calculateRem(240)};
  padding: ${calculateRem(12)} ${calculateRem(16)};
  border-radius: ${calculateRem(4)};
  font-size: ${(props: ThemeProps) => props.theme.font.size._16};
  font-weight: ${(props: ThemeProps) => props.theme.font.weight.bold};
`;

export const StyledDividerSpan = styled.span`
  display: block;
  font-size: ${(props: ThemeProps) => props.theme.font.size._16};
  line-height: ${calculateRem(24)};
  color: ${(props: ThemeProps) => props.theme.palette.neutral._900};
  margin: ${calculateRem(16)};
`;

export const StyledBackButton = styled(Button)`
  font-weight: ${(props: ThemeProps) => props.theme.font.weight.bold};
`;
