import { observer } from 'mobx-react';
import img from 'assets/images/banner2.png';
import { TEACHER_SCHEDULED_TEST_PAGE_ROUTES } from 'constants/routes';
import React, { FC } from 'react';
import { Button } from 'components/Button';
import { useTranslation } from 'react-i18next';
import { Heading3, Heading4 } from 'styles/elements/Headings';
import { useHistory } from 'react-router-dom';
import { StyledImageWrapper, StyledScheduleTest } from './ScheduleTest.styled';

export const ScheduleTest: FC = observer(() => {
  const { t } = useTranslation('teacher-dashboard');
  const history = useHistory();

  return (
    <StyledScheduleTest>
      <StyledImageWrapper background={img} />
      <div>
        <Heading3>{t('homePage.scheduleTest.title')}</Heading3>
        <Heading4>{t('homePage.scheduleTest.description')}</Heading4>
        <Button
          dataCy="new-scheduled-test"
          variant="primary"
          onClick={() => history.push(TEACHER_SCHEDULED_TEST_PAGE_ROUTES.SCHEDULED_TEST_REQUEST)}
        >
          {t('homePage.scheduleTest.button')}
        </Button>
      </div>
    </StyledScheduleTest>
  );
});
