import styled from 'styled-components/macro';
import { ThemeProps } from 'models/styles/theme';
import { calculateRem } from 'styles/mixins/calculateRem';
import { Input } from 'components/Input';
import searchIcon from 'assets/icons/search.svg';

export const StyledInputAdminSearchWrapper = styled.div<ThemeProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-end;

  label {
    font-weight: ${({ theme }: ThemeProps) => theme.font.weight.bolder};
    margin-bottom: ${calculateRem(16)};
  }
`;

export const StyledInputSearch = styled(Input)<ThemeProps>`
  background-color: ${({ theme }: ThemeProps) => theme.palette.neutral._200};
  background-image: url(${searchIcon});
  background-position: left;
  background-position-x: ${calculateRem(15)};
  background-repeat: no-repeat;
  background-size: ${calculateRem(25)};
  border-radius: ${calculateRem(8)};
  height: ${calculateRem(48)};
  padding-left: ${calculateRem(50)};
  width: ${calculateRem(412)};
`;
