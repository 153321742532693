import React, { useState, useEffect, Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import styled, { ThemeProvider } from 'styled-components/macro';

import { ModalProvider } from 'contexts/ModalProvider';

import { TooltipContainer } from 'components/TooltipContainer';
import { ToastNotification } from 'components/ToastNotification';
import { LoadingSpinner } from 'components/LoadingSpinner/LoadingSpinner';
import { PlatformDownPage } from 'components/PlatformDownPage';
import { PlatformMaintainanceModal } from 'components/PlatformMaintainanceModal';
import { GlobalAppStyles } from 'styles/GlobalApp.styled';

import { HelmetProvider } from 'contexts/HelmetProvider/HelmetProvider';
import { FeatureFlagsProvider } from 'contexts/FeatureFlagProvider';
import { UsetifulProvider } from 'contexts/UsetifulProvider/UsetifulProvider';
import { hogesTheme } from './themes/hoges';
import { hogesBusinessTheme } from 'themes/hogesBusiness';
import AppRouter from './router/AppRouter';
import ScrollToTop from './router/ScrollToTop';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { useDomainHandler } from 'hooks/useDomainHandler';

import { useMetaBasePageTracker } from 'analytics/MetabaseTracker/useMetabasePageTracker';
import SupportWidget from 'supportWidget/SupportWidget';
import { ErrorBoundary } from 'react-error-boundary';
import { logAPMError, setAxiosLogger } from 'observability';
import { ErrorRenderer } from 'components/ErrorRenderer/ErrorRenderer';

setAxiosLogger();

export const StyledLoaderWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const HSTApp: React.FC = () => {
  const { isLoading, showPlatformIsDownMessageModal } = useFeatureFlags();
  useMetaBasePageTracker();

  return (
    <>
      <GlobalAppStyles />
      <PlatformMaintainanceModal />
      {isLoading ? (
        <StyledLoaderWrapper>
          <LoadingSpinner />
        </StyledLoaderWrapper>
      ) : showPlatformIsDownMessageModal ? (
        <PlatformDownPage />
      ) : (
        <ScrollToTop>
          <AppRouter />
        </ScrollToTop>
      )}
      <ToastNotification />
      <TooltipContainer />
    </>
  );
};

interface ComponentWithChildren {
  children: React.ReactNode;
}

const ThemeContainer: React.FC<ComponentWithChildren> = ({ children }) => {
  const [currentTheme, setCurrentTheme] = useState(hogesTheme);
  const { isBusinessThemeActive } = useFeatureFlags();
  const { isBusinessDomain } = useDomainHandler();

  useEffect(() => {
    setCurrentTheme(isBusinessDomain() && isBusinessThemeActive ? hogesBusinessTheme : hogesTheme);
  }, [isBusinessThemeActive]);

  return <ThemeProvider theme={currentTheme}>{children}</ThemeProvider>;
};

const App: React.FC = () => (
  <Suspense fallback={null}>
    <ErrorBoundary
      fallbackRender={({ error }) => <ErrorRenderer message={error?.message} />}
      onError={(error, { componentStack }) => logAPMError(error, JSON.stringify(componentStack))}
    >
      <FeatureFlagsProvider>
        <ThemeContainer>
          <Suspense
            fallback={
              <StyledLoaderWrapper>
                <LoadingSpinner />
              </StyledLoaderWrapper>
            }
          >
            <BrowserRouter>
              <HelmetProvider>
                <ModalProvider>
                  <UsetifulProvider>
                    <HSTApp />
                    <SupportWidget />
                  </UsetifulProvider>
                </ModalProvider>
              </HelmetProvider>
            </BrowserRouter>
          </Suspense>
        </ThemeContainer>
      </FeatureFlagsProvider>
    </ErrorBoundary>
  </Suspense>
);

export default App;
