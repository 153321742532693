import React from 'react';

import { Button } from 'components/Button';

import { SvgIconReact } from 'components/SvgIconReact';
import { StyledActionBanner, StyledContent, StyledParagraph, StyleTitle } from './ActionBanner.styled';
import { ActionBannerProps } from './ActionBanner.model';

export const ActionBanner: React.FC<ActionBannerProps> = ({
  children,
  title,
  text,
  dataCy,
  actionButtonDisabled = false,
  actionButtonTitle,
  onActionButtonClick,
  iconName,
}) => (
  <StyledActionBanner $hasIcon={!!iconName} data-cy={dataCy}>
    {iconName && (
      <div>
        <SvgIconReact iconName={iconName} size={2.5} />
      </div>
    )}
    <StyledContent>
      {title && <StyleTitle>{title}</StyleTitle>}
      <StyledParagraph>{text}</StyledParagraph>
    </StyledContent>
    <div>
      {children && <div>{children}</div>}
      <Button
        dataCy="start-diagnostic-test-button"
        disabled={actionButtonDisabled}
        variant="primary"
        onClick={onActionButtonClick}
      >
        {actionButtonTitle}
      </Button>
    </div>
  </StyledActionBanner>
);
