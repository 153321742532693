/* eslint-disable @typescript-eslint/no-unsafe-return */
import i18n from 'i18n';
import { ToastMethods } from 'components/ToastNotification';
import { action, observable } from 'mobx';
import { sebKeysRepository } from 'repositories';
import { ResultsAPI } from 'generated/types';

interface SEBKeysStore {
  availableKeys: ResultsAPI.SEBKey[];
  setAvailableKeys: (availableKeys: ResultsAPI.SEBKey[]) => void;
  insertKey: (key: ResultsAPI.InsertSEBKey, onInserted: () => void) => void;
  updateKey: (key: ResultsAPI.UpdateSEBKey, onUpdated: () => void) => void;
  fetchAvailableKeys: (scheduledTestId: number | null) => void;
  fetchSEBHeaderKey: (onSuccess: (headerKey: string) => void) => void;
  authorizeTestWithSebKey: (scheduledTestId: number, sebHeaderKey: string, onSuccess: () => void) => void;
}

// eslint-disable-next-line prefer-const
let store: SEBKeysStore;

const initialState = {
  availableKeys: [],
  upsertedKey: null,
};

const stateSetters = {
  setAvailableKeys: action((availableKeys: ResultsAPI.SEBKey[]) => {
    store.availableKeys = availableKeys;
  }),
};

const apiRequests = {
  fetchAvailableKeys: action((scheduledTestId: number | null) => {
    sebKeysRepository
      .fetchAvailableKeys(scheduledTestId)
      .then(availableKeys => store.setAvailableKeys(availableKeys))
      .catch(() => ToastMethods.showToast(i18n.t('toast:scheduledTest.error.fetchAvailableSEBKeys'), 'error'));
  }),
  fetchSEBHeaderKey: action((onSuccess: (headerKey: string) => void) => {
    sebKeysRepository
      .fetchSEBHeaderKey()
      .then(headerKey => onSuccess(headerKey))
      .catch(() => ToastMethods.showToast(i18n.t('toast:scheduledTest.error.fetchSEBHeaderKey'), 'error'));
  }),
  insertKey: action((key: ResultsAPI.InsertSEBKey, onInserted: () => void) => {
    sebKeysRepository
      .insertKey(key)
      .then(() => onInserted())
      .catch(() => ToastMethods.showToast(i18n.t('toast:scheduledTest.error.upsertSEBKey'), 'error'));
  }),
  updateKey: action((key: ResultsAPI.UpdateSEBKey, onUpdated: () => void) => {
    sebKeysRepository
      .updateKey(key)
      .then(() => onUpdated())
      .catch(() => ToastMethods.showToast(i18n.t('toast:scheduledTest.error.upsertSEBKey'), 'error'));
  }),
  authorizeTestWithSebKey: action((scheduledTestId: number, sebHeaderKey: string, onSuccess: () => void) => {
    sebKeysRepository
      .fetchSEBAuthorization(scheduledTestId, sebHeaderKey)
      .then(({ authorized }) => authorized && onSuccess())
      .catch(() => ToastMethods.showToast(i18n.t('toast:scheduledTest.error.fetchSEBAuthorization'), 'error'));
  }),
};

store = observable({
  ...initialState,
  ...stateSetters,
  ...apiRequests,
} as SEBKeysStore);

export const useSEBKeys = (): SEBKeysStore => store;
