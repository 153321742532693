import { ModalRenderType } from 'components/Modal/Modal.model';
import { modalIds } from 'constants/modals-constants';
import { useModal } from 'hooks/useModal';
import {
  StyledDialog,
  StyledInnerDialog,
} from 'pages/TeacherEnvironment/TeacherDashboard/subpages/ScheduledTestWizard/steps/TestOptionsStep/components/FormSEBListStep/components/SEBKeyUpsertDialog/SEBKeyUpsertDialog.styled';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Heading2 } from 'styles/elements/Headings';
import { StyledModal } from '../../../UserToolsPage/components/TransferResultsModal/TransferResultsModal.styled';
import { UpdateScheduledTest } from '../../../UserToolsPage/components/UpdateScheduledTest/UpdateScheduledTest';

export interface ModifyScheduledTestModalProps {
  id: number;
}

export const ModifyScheduledTestModal: FC<ModifyScheduledTestModalProps> = ({ id }) => {
  const { isModalOpen, closeModal, modalParams } = useModal();
  const { t } = useTranslation('admin-environment');

  if (!modalParams || modalParams.id !== id) return <></>;

  return (
    <StyledModal
      closeOnClickOutside
      showCloseButton
      dataCy="modify-scheduled-test-modal"
      isOpen={isModalOpen(modalIds.updateScheduledTest)}
      renderToElementType={ModalRenderType.REACT_PORTAL}
      onClose={closeModal}
    >
      <StyledDialog>
        <StyledInnerDialog>
          <Heading2>{t('adminTools.scheduledTest.title', { testCode: modalParams.testCode })}</Heading2>
          <UpdateScheduledTest
            cumulative={(modalParams.cumulative as boolean) ?? false}
            id={Number(modalParams.id) ?? 0}
            reviewEndDate={modalParams.reviewEndDate?.toString() ?? new Date().toISOString()}
            reviewStartDate={modalParams.reviewStartDate?.toString() ?? new Date().toISOString()}
          />
        </StyledInnerDialog>
      </StyledDialog>
    </StyledModal>
  );
};
