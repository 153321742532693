import React from 'react';
import { InfoCircleProps } from './InfoCircle.model';
import { StyledInfoCircle } from './InfoCircle.styled';

export const InfoCircle: React.FC<InfoCircleProps> = ({
  value = '--',
  subtext,
  dataCy,
  variant = 'default',
  ...props
}) => (
  <StyledInfoCircle data-cy={dataCy} variant={variant} {...props}>
    <div className="InfoCircle--circle">
      <span className="InfoCircle--value">{value}</span>
    </div>
    {subtext && <span className="InfoCircle--subtext">{subtext}</span>}
  </StyledInfoCircle>
);
