enum BASIC_PROGRESS_OVERVIEW_EVENT_TYPES {
  UPDATE_VIEW_BY_TARGET_LEVEL = 'update-view-by-target-level',
  UPDATE_VIEW_BY_GROUP = 'update-view-by-group',
  UPDATE_VIEW_BY_EDUCATION = 'update-view-by-education',
  CLICK_DOWNLOAD_REPORT = 'click-download-report',
  FILTER_USERS_BY_NAME = 'filter-users-by-name',
}

enum BASIC_PROGRESS_FOR_ACCOUNT_EVENT_TYPES {
  UPDATE_VIEW_BY_TARGET_LEVEL = 'update-view-by-target-level',
  UPDATE_STUDENT_DETAILS_AS_TEACHER = 'update-student-details-as-teacher',
  UPDATE_STUDENT_GOAL_LEVEL_AS_TEACHER = 'update-student-goal-level-as-teacher',
  UPDATE_VIEW_BY_SKILL_TYPE = 'update-view-by-skill-type',
}

enum STUDENT_DASHBOARD_EVENT_TYPES {
  CLICK_BEGIN_ONBOARDING = 'click-begin-onboarding',
  CLICK_CONTINUE_PRACTICING_TOPIC = 'click-continue-practicing-topic',
  CLICK_CONTINUE_PRACTICING_MIX = 'click-continue-practicing-mix',
  CLICK_START_PRACTICING_MODULE = 'click-start-practicing-module',
}

enum STUDENT_MODULES_EVENT_TYPES {
  CLICK_START_DETERMINATION_TEST = 'click-start-determination-test',
  CLICK_CONTINUE_DETERMINATION_TEST = 'click-continue-determination-test',
  CLICK_PRACTICE_SUBJECT = 'click-start-practicing-subject',
}

export const PLATFORM_EVENT_TYPES = {
  BASIC_PROGRESS_FOR_ACCOUNT_EVENT_TYPES,
  BASIC_PROGRESS_OVERVIEW_EVENT_TYPES,
  STUDENT_DASHBOARD_EVENT_TYPES,
  STUDENT_MODULES_EVENT_TYPES,
};
