import { TestType, ScheduledTestTimeLeftStatus } from 'constants/exam-constants';
import { Exercise, ExerciseResult } from './Exam';

export enum ScheduledTestStatus {
  ONGOING = 'ongoing',
  UPCOMING = 'upcoming',
}

export interface ProctoringSettings {
  programFocused: boolean;
  online: boolean;
  fullScreen: boolean;
  otherSoftwareClosed: boolean;
  onlyOneScreen: boolean;
  webcam: boolean;
  faceDetection: boolean;
  screenshots: boolean;
}

export interface ScheduledTestDate {
  startDate: Date;
  duration: number;
  estimatedStudents: number;
}

export interface ScheduledTestBasePayload {
  testId: number;
  title: string;
  extraTime: number | null;
  reviewStartDate: Date | null;
  reviewEndDate: Date | null;
  passingScore: number | null;
  password: string | null;
  cumulative: boolean | null;
  editableAnswers: boolean | null;
  secureMode: boolean | null;
  proctoringSettings: ProctoringSettings;
  supportMessage: string;
  sebKeyIds: number[] | null;
}

export interface ScheduledTestCreatePayload extends ScheduledTestBasePayload {
  schedule: ScheduledTestDate[];
}

export interface ScheduledTestSummary {
  id: number;
  accessCodes: ScheduledTestAccessCodes;
  title: string;
  testLevel: string;
  testType: string;
  startDate: string;
  duration: number;
  extraTime: number;
  cumulative: boolean;
  password: string;
  productId: number;
}

export interface ScheduledTestAccessCodes {
  standard: string;
  withExtraTime: string;
}

export interface ScheduledTest {
  id: number;
  title: string;
  testId: number;
  creatorId: number;
  accessCodes: ScheduledTestAccessCodes;
  startDate: string;
  duration: number;
  extraTime: number;
  passingScore: number;
  cumulative: boolean;
  password: string;
  reviewStartDate?: string;
  reviewEndDate?: string;
  editableAnswers: boolean;
  secureMode: boolean;
  proctoringSettings: ProctoringSettings;
  createdOn: string;
  updatedOn: string;
  sebKeyIds: number[] | null;
  ownedBySelf?: boolean;
}

export interface ScheduledTestDetails extends ScheduledTest {
  testType: string;
  testLevel: string;
  testVersion: string;
  productId: number;
  skillLevelId: number;
  estimatedStudents: number;
}

export interface ScheduledTestExercisePayload {
  testCode: string;
  exerciseId: number;
  testToken?: string;
  randomizeQuestions?: boolean;
}

export type ScheduledTestExercise = Pick<Exercise, 'id' | 'title' | 'text' | 'isTextQuiz' | 'questions'>;

export type ScheduledTestResults = ExerciseResult[];

export interface ScheduledTestExerciseResult {
  id: number;
  title: string;
  score: number;
}

export interface ScheduledTestResultOverview {
  id: number;
  code: string;
  title: string;
  skillLevel: string;
  startDate: string;
  reviewStartDate: string;
  reviewEndDate: string;
  score: number;
  requiredPassingScore: number;
  exercises: ScheduledTestExerciseResult[];
}

export interface ScheduledTestResultList {
  id: number;
  code: string;
  title: string;
  skillLevel: string;
  startDate: string;
  score: number;
  hasPassed: boolean;
  exercises: {
    id: number;
    title: string;
    score: number;
    hasPassed: boolean;
  }[];

  productName: string;
}

export interface ExerciseScoreDetail extends ScheduledTestExerciseResult {
  requiredForPassing: boolean;
}

export interface ScheduledTestScoreDetail {
  id: number;
  title: string;
  productId: number;
  type: TestType;
  reviewStartDate: string;
  reviewEndDate: string;
  score: number;
  exercises: ExerciseScoreDetail[];
  requiredPassingScore: number;
}

export interface UpdateScheduledTestDurationPayload {
  duration: number;
  testId: number;
  title: string;
}

export interface OwnedScheduledTestResultsOverviewItem {
  id: number;
  title: string;
  type: TestType;
  startDate: string;
  skillLevel: string;
  avgScore: number;
  numSessions: number;
  requiredPassingScore: number;
  ownedBySelf?: boolean;
}

export interface ScheduledTestResultsReportSettings {
  cumulativeSinceDate?: Date;
  cumulativeUntilDate?: Date;
}

export interface ScheduledTestExerciseAvgResult {
  id: number;
  order: number;
  score: number;
  title: string;
}

export interface UserScheduledTestExerciseResult {
  id: number;
  score: number;
}

export interface UserScheduledTestResults {
  id: number;
  firstName: string;
  middleName?: string;
  lastName: string;
  email: string;
  code: string;
  withExtraTime: boolean;
  score: number;
  isInvalid: boolean;
  exerciseScores: UserScheduledTestExerciseResult[];
}

export interface OwnedScheduledTestResults {
  exercises: ScheduledTestExerciseAvgResult[];
  students: UserScheduledTestResults[];
}

export interface ScheduledTestReviewPeriodTimer {
  id: number;
  counter: number;
}

export interface ScheduledTestStatusAndTimeLeft {
  status: ScheduledTestTimeLeftStatus;
  counter?: number;
}

export interface LastCompletedTest {
  id: number;
  title: string;
  avgScore: number;
  hadExtraTime: boolean;
}
