import styled from 'styled-components/macro';
import { ElearningThemeInterface } from 'themes/elearningInterface.theme';
import { calculateRem } from 'styles/mixins/mixins';
import { Paragraph } from 'styles/elements/Texts';
import { Heading1, Heading3 } from 'styles/elements/Headings';

interface Props {
  theme: ElearningThemeInterface;
}

export const StyledTestCompletedMessage = styled.div<Props>`
  margin: auto;
  display: grid;
  justify-items: center;
  max-width: ${calculateRem(640)};
`;

export const StyledButtonsSection = styled.section<Props>`
  display: grid;
  grid-auto-flow: column;
  gap: 2rem;
`;

export const StyledParagraphFinishedQuestion = styled(Paragraph)<Props>`
  margin-top: 2rem;
  line-height: 150%;
  color: ${(props: Props) => props.theme.palette.neutral._900};
  text-align: center;
`;

export const StyledHeaderFinishedQuestion = styled(Heading3)<Props>`
  margin-top: 2rem;
`;

export const StyledTitle = styled(Heading1)<Props>`
  line-height: 4rem;
  text-align: center;
`;
