import { SvgIconReact } from 'components/SvgIconReact';
import styled from 'styled-components/macro';

export const StyledLogoWrapper = styled.div`
  width: 100%;
  text-align: center;
  flex-grow: 1;
`;

export const StyledLogo = styled(SvgIconReact)`
  height: 6rem;
  width: 14rem;
  margin-bottom: 6rem;
`;
