import styled from 'styled-components/macro';

import { ThemeProps as Props } from 'models/styles/theme';

export const StyledIpAddressList = styled.ul<Props>`
  list-style: none;
  padding: 0;
  display: flex;
  row-gap: 1rem;
  column-gap: 1rem;
  margin-bottom: 2rem;
  flex-wrap: wrap;
`;

export const StyledIpAddressListItem = styled.li<Props>`
  padding: 1rem 1.75rem;
  font-weight: bold;
  border-radius: 4px;
  background: ${({ theme }: Props) => theme.palette.neutral._200};
`;
