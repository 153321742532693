import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import { matchPath, Redirect, useHistory, useLocation } from 'react-router-dom';

import {
  GENERIC_ROUTES,
  PRODUCT_PAGES_ROUTES,
  TEACHER_HOME_PAGE_ROUTES,
  TEACHER_INSIGHT_PAGE_ROUTES,
} from 'constants/routes';
import { buildUrlWithPathParams } from '_helpers/utils/urlBuilder';

import { useProduct } from 'hooks/useProduct';

import { ProductsDropdown } from 'components/ProductsDropdown/ProductsDropdown';

interface InsightProductSelectorProps {
  selectedProductId: number;
}

const pageRoutes = [
  TEACHER_HOME_PAGE_ROUTES.DASHBOARD,
  TEACHER_INSIGHT_PAGE_ROUTES.PROGRESS_OVERVIEW,
  TEACHER_INSIGHT_PAGE_ROUTES.ACTIVITY,
  TEACHER_INSIGHT_PAGE_ROUTES.WRITING_SKILLS,
  TEACHER_INSIGHT_PAGE_ROUTES.DIAGNOSTIC_TEST,
  TEACHER_INSIGHT_PAGE_ROUTES.PROGRESS_FOR_ACCOUNT,
];

export const InsightProductSelector: React.FC<InsightProductSelectorProps> = observer(({ selectedProductId }) => {
  const history = useHistory();
  const location = useLocation();

  const { productsList } = useProduct();

  // Find out the path we're currenly into
  const currentPath = useMemo(() => {
    let i = 0;
    while (i < pageRoutes.length) {
      const currentRouteMatches = !!matchPath(location.pathname, {
        exact: true,
        path: pageRoutes[i],
      });

      if (currentRouteMatches) {
        if (pageRoutes[i] === TEACHER_INSIGHT_PAGE_ROUTES.PROGRESS_FOR_ACCOUNT) {
          return TEACHER_INSIGHT_PAGE_ROUTES.PROGRESS_OVERVIEW;
        }
        return pageRoutes[i];
      }

      i += 1;
    }
    return GENERIC_ROUTES.NOT_FOUND;
  }, [location.pathname]);

  // Replace product id in current path
  const onSelectProduct = (productId: number) => {
    localStorage.setItem('selectedProduct', productId.toString());
    history.push(buildUrlWithPathParams(currentPath, { productId }));
  };

  if (productsList?.length === 0) {
    return <Redirect to={PRODUCT_PAGES_ROUTES.PRODUCT_LIST} />;
  }

  const selectedProductFromStorage = parseInt(localStorage.getItem('selectedProduct') ?? '');
  if (productsList && productsList.length > 1) {
    return (
      <ProductsDropdown
        products={productsList}
        selectedProductId={isNaN(selectedProductFromStorage) ? +selectedProductId : selectedProductFromStorage}
        onSelectProduct={onSelectProduct}
      />
    );
  } else {
    return null;
  }
});
