import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';

import { QuestionFlag, QuestionType, ExerciseType } from 'constants/exam-constants';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { ExamQuestionWithAnswers, ProgressiveDownloadQuestion, ProgressiveOverviewQuestion } from 'models/exam/Exam';
import { StyledQuestion, StyledQuestionManager } from './QuestionManager.styled';
import { QuestionManagerProps, QuestionsManagerPathParams } from './QuestionManager.model';
import { InputQuestion } from './components/InputQuestion';
import { SingleSelectQuestion } from './components/SingleSelectQuestion';
import { DragAndDropQuestion } from './components/DragAndDropQuestion';
import { TextAreaQuestion } from './components/TextAreaQuestion';
import { MultiInputQuestion } from './components/MultiInputQuestion';
import { CorrectTextQuestion } from './components/CorrectTextQuestion/CorrectTextQuestion';
import { SelectTextQuestion } from './components/SelectTextQuestion';
import { QuestionPropsInterface } from './interfaces/question';
import { ProgressiveTextQuestion } from './components/ProgressiveTextQuestion/ProgressiveTextQuestion';
import { ProgressiveDocumentQuestion } from './components/ProgressiveTextQuestion/components/ProgressiveDocumentQuestion/ProgressiveDocumentQuestion';
import { OverviewQuestion } from './components/ProgressiveTextQuestion/components/ProgressiveOverviewQuestion/OverviewQuestion';
import { useAnswer } from 'hooks/useAnswer';
import { EmailQuestion } from './components/EmailQuestion/EmailQuestion';

export const QuestionManager: React.FC<QuestionManagerProps> = observer(
  ({
    question,
    evaluateAnswer,
    flags,
    hideAnswers = false,
    singleSelectShowLoadingButton = true,
    prevAnswerValues,
    exercise,
  }) => {
    const { questionStatus, questionResult, isEvaluatingAnswer, setQuestionStatus } = useAnswer();

    useEffect(() => {
      setQuestionStatus && setQuestionStatus(questionStatus);
    }, [questionStatus]);

    const { emailComposeExercise } = useFeatureFlags();
    const { productId }: QuestionsManagerPathParams = useParams();
    const getQuestionView = () => {
      const commonProps: QuestionPropsInterface = {
        answerFeedbackComponent: null,
        hideAnswers,
        evaluateAnswer,
        question,
        results: questionResult?.results ?? [],
        status: questionStatus,
        isEvaluatingAnswer,
        prevAnswerValues,
      };

      switch (question.type) {
        case QuestionType.SINGLE_INPUT:
          return <InputQuestion key={question.id} {...commonProps} />;
        case QuestionType.SINGLE_SELECT:
          if (emailComposeExercise && exercise?.type === ExerciseType.COMPOSE_EMAIL) {
            return (
              <EmailQuestion
                key={question.id}
                answerOptions={(question as ExamQuestionWithAnswers).options}
                exercise={exercise}
                showLoadingButton={singleSelectShowLoadingButton}
                {...commonProps}
              />
            );
          }
          return (
            <SingleSelectQuestion
              key={question.id}
              answerOptions={(question as ExamQuestionWithAnswers).options}
              showLoadingButton={singleSelectShowLoadingButton}
              {...commonProps}
            />
          );
        case QuestionType.DRAG:
          return (
            <DragAndDropQuestion
              key={question.id}
              answerOptions={(question as ExamQuestionWithAnswers).options}
              {...commonProps}
            />
          );
        case QuestionType.TEXT_AREA:
          return <TextAreaQuestion key={question.id} {...commonProps} />;
        case QuestionType.MULTIPLE_TEXT_INPUT:
          return <MultiInputQuestion key={question.id} {...commonProps} />;
        case QuestionType.SELECT_TEXT:
          return (
            <SelectTextQuestion
              key={question.id}
              answerOptions={(question as ExamQuestionWithAnswers).options}
              {...commonProps}
            />
          );
        case QuestionType.CORRECT_TEXT:
          return (
            <CorrectTextQuestion
              key={question.id}
              answerCount={question.metadata?.answerCount || 0}
              availableActions={question.metadata?.availableActions}
              isComparingAnswers={flags.includes(QuestionFlag.CORRECT_TEXT_COMPARE_MODE)}
              {...commonProps}
              userAnswers={prevAnswerValues}
            />
          );
        case QuestionType.CORRECT_SENTENCE:
          return (
            <TextAreaQuestion
              key={question.id}
              {...commonProps}
              disableSpellCheck
              // conditional for avoid issues with determination test
              defaultValue={
                prevAnswerValues && prevAnswerValues.length > 0
                  ? prevAnswerValues.map(({ value }) => value).toString()
                  : question.metadata?.defaultValue
              }
            />
          );
        case QuestionType.PROGRESSIVE_TEXT:
          return (
            <ProgressiveTextQuestion
              key={question.id}
              reflectionQuestions={question.metadata?.reflectionQuestions}
              {...commonProps}
            />
          );
        case QuestionType.PROGRESSIVE_OVERVIEW:
          return <OverviewQuestion productId={+productId} question={question as ProgressiveOverviewQuestion} />;
        case QuestionType.PROGRESSIVE_DOCUMENT:
          return <ProgressiveDocumentQuestion question={question as ProgressiveDownloadQuestion} />;
        default:
          return null;
      }
    };

    return (
      <StyledQuestionManager>
        <StyledQuestion data-cy="question-wrapper" data-question-id={question.id}>
          {getQuestionView()}
        </StyledQuestion>
      </StyledQuestionManager>
    );
  },
);
