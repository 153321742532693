import React, { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useProduct } from 'hooks/useProduct';
import { useWriting } from 'hooks/useWriting';
import { useLanguage } from 'hooks/useSelectedLanguage';
import { useDomainHandler } from 'hooks/useDomainHandler';

import { ModuleSubject } from 'models/product/ModuleDetails';

import { Heading2 } from 'styles/elements/Headings';
import { modalIds } from 'constants/modals-constants';
import { DiagnosticTestModal } from 'components/DiagnosticTestModal';
import { EmbeddedHtml } from 'components/EmbeddedHtml';
import { useModal } from 'hooks/useModal';
import { useProductProgress } from 'hooks/useProductProgress';
import { AdaptiveSubject } from './components/AdaptiveSubject/AdaptiveSubject';
import { FinishedSubjectModal } from './components/FinishedSubjectModal/FinishedSubjectModal';
import { WritingSubject } from './components/WritingSubject/WritingSubject';
import { DiagnosticTestBanner } from './components/DiagnosticTestBanner/DiagnosticTestBanner';
import { RecentProgressSummary } from './components/RecentProgressSummary/RecentProgressSummary';
import { StyledModuleDetailsPage, StyledModuleTitle, StyledInfoTextNoLevelSelected } from './ModuleDetailsPage.styled';
import { useAdaptivePractice } from 'hooks/useAdaptivePractice';
import { useTally } from 'hooks/useTally';
import { useProductResults } from 'hooks/useProductResults';
import { ProductType } from 'constants/hst-constants';
import { useDeterminationTest } from 'hooks/useDeterminationTest';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { HstProducts } from 'constants/hst-constants';

export interface PathParams {
  productId: string;
  moduleId: string;
}

export const ModuleDetailsPage: React.FC = observer(() => {
  const { t } = useTranslation('homepage');
  const { productId, moduleId }: PathParams = useParams();
  const { isModalOpen, toggleModal } = useModal();
  const { currentLanguage } = useLanguage();
  const { getTeacherTermByDomain } = useDomainHandler();
  const { fetchModuleDetails, moduleDetails, productsList, productDetails } = useProduct();
  const { fetchWritingSubjectsStatus, writingSubjectsStatus } = useWriting();
  const { fetchModuleProgress, moduleSubjectsProgress, productGoalLevel, fetchProductGoalLevel } = useProductProgress();
  const { getRecentProgress, recentProgress } = useProductResults();
  const { fetchSkillDeterminationStatus, resetDeterminationStatus } = useDeterminationTest();
  const { showStudentGoalLevelSelector } = useFeatureFlags();

  const { setCongratulationsMessage, setMixedExercise, setCurrentTopic, setPreviousTopic, setSkillLevelHasLowered } =
    useAdaptivePractice();
  useTally();

  const goalLevel = productGoalLevel?.goalLevel;

  const shouldShowModal: boolean = useMemo(() => {
    if (moduleDetails && productGoalLevel && +productId === HstProducts.BETER_SCHRIJVEN) {
      // this goalLevel is of type number, the one defined at a bigger scope is of type number | undefined
      const goalLevel = productGoalLevel.goalLevel;
      return moduleDetails.subjects.some((subject: ModuleSubject) => {
        return subject.chapters.some(chapter =>
          chapter.topics.some(
            topic => topic.isTrialTopic && goalLevel >= topic.minSkillLevel && goalLevel <= topic.maxSkillLevel,
          ),
        );
      });
    }
    return true;
  }, [productId, productGoalLevel, moduleDetails]);

  useEffect(() => {
    if (moduleSubjectsProgress.length > 0 && moduleDetails) {
      const anySubjectIncomplete = moduleSubjectsProgress.some(({ progress }) => progress !== 100);
      if (shouldShowModal && moduleDetails.hasDiagnosticTest && !anySubjectIncomplete) {
        // Attempt to toggle modal if it's not open yet (we don't want it to close automatically)
        if (!isModalOpen(modalIds.diagnosticTestModal)) {
          toggleModal(modalIds.diagnosticTestModal);
        }
      }
    }
  }, [shouldShowModal, moduleDetails, moduleSubjectsProgress]);

  useEffect(() => {
    setCongratulationsMessage('none');
    setCurrentTopic(null);
    setPreviousTopic(null);
    setMixedExercise(false);
    setSkillLevelHasLowered(null);
  }, []);

  const isAdaptiveSubject = (subject: ModuleSubject) => subject.type === 'adaptive';
  const isWritingSubject = (subject: ModuleSubject) => subject.type === 'writing';

  const subjectList = moduleDetails?.subjects || [];

  const hasAdaptiveSubject = subjectList.some(isAdaptiveSubject);
  const hasWritingSubject = subjectList.some(isWritingSubject);
  const isMissingWritingExercises = writingSubjectsStatus?.length === 0;

  useEffect(() => {
    if (productId && moduleId) {
      fetchModuleDetails(+productId, +moduleId);
    }
  }, [productId, moduleId, currentLanguage]);

  useEffect(() => {
    if (productId && hasAdaptiveSubject) {
      getRecentProgress(+productId);
      fetchProductGoalLevel(+productId);
    }
  }, [productId, hasAdaptiveSubject]);

  useEffect(() => {
    if (hasAdaptiveSubject) {
      fetchModuleProgress(+productId, +moduleId);
    }
  }, [productId, moduleId, productGoalLevel, hasAdaptiveSubject]);

  useEffect(() => {
    if (subjectList.length > 0 && hasWritingSubject) {
      fetchWritingSubjectsStatus(+productId, +moduleId);
    }
  }, [subjectList, productId, moduleId, hasWritingSubject, currentLanguage]);

  useEffect(() => {
    fetchSkillDeterminationStatus(+productId, +moduleId);
    return () => resetDeterminationStatus();
  }, [productId, moduleId]);

  // Goal level should be set by teacher in business products if feature is enabled
  const isGoalLevelSetByTeacher = useMemo(() => {
    const isBusinessProduct = productDetails?.type === ProductType.BUSINESS;
    return showStudentGoalLevelSelector && isBusinessProduct;
  }, [productDetails, showStudentGoalLevelSelector]);

  // Do not request selection of goal level if no adaptive subjects are available in page
  const forceGoalLevelSelection = hasAdaptiveSubject && !goalLevel;
  const isUserABusinessUser = productsList?.every(product => product.type === ProductType.BUSINESS);

  return (
    <StyledModuleDetailsPage>
      {shouldShowModal && <DiagnosticTestModal />}
      <FinishedSubjectModal />
      <section>
        <StyledModuleTitle data-cy="module-title">{moduleDetails?.title}</StyledModuleTitle>
        {moduleDetails?.description && (
          <EmbeddedHtml dataCy="module-description" rawHtml={moduleDetails?.description} />
        )}
      </section>
      {hasAdaptiveSubject && <RecentProgressSummary recentProgress={recentProgress} />}
      {/* Show warning if user needs to set their initial goal level */}
      {forceGoalLevelSelection && (
        <StyledInfoTextNoLevelSelected dataCy="select-goal-level-info">
          {!isGoalLevelSetByTeacher
            ? t('missingGoalLevelPrompt.setBySelf')
            : t('missingGoalLevelPrompt.setByTeacher', { teacherTerm: getTeacherTermByDomain() })}
        </StyledInfoTextNoLevelSelected>
      )}
      {/* Show warning if there's no content available for writing subjects */}
      {hasWritingSubject && isMissingWritingExercises && (
        <StyledInfoTextNoLevelSelected dataCy="missing-writing-exercises-info">
          {t('missingWritingExercisesPrompt')}
        </StyledInfoTextNoLevelSelected>
      )}
      {/* List of subjects within the module */}
      <section data-cy="module-subjects-container">
        {subjectList.map((subject: ModuleSubject) =>
          isAdaptiveSubject(subject) ? (
            <AdaptiveSubject key={subject.id} moduleId={+moduleId} productId={+productId} subject={subject} />
          ) : (
            <WritingSubject key={subject.id} subject={subject} />
          ),
        )}
      </section>
      {moduleDetails?.hasDiagnosticTest && goalLevel && !isUserABusinessUser && (
        <section data-cy="diagnostic-test-banner-container">
          <Heading2>{t('diagnosticTest.title')}</Heading2>
          <DiagnosticTestBanner currentGoalLevel={goalLevel} />
        </section>
      )}
    </StyledModuleDetailsPage>
  );
});
