/* eslint-disable @typescript-eslint/no-use-before-define */
import { action, observable } from 'mobx';
import { RequestErrorModel } from 'models/error/Error';
import { logsRepository } from 'repositories';

interface LogsStore {
  error: RequestErrorModel;
  setError: (error: RequestErrorModel) => void;
  trackTestEvent: (
    eventName: string,
    eventType: string,
    testToken: string,
    extraData?: Record<string, unknown>,
  ) => void;
}

interface ErrorResponse {
  status: number;
  message: string;
  type: string;
}

const stateSetters = {
  setError: action((error: RequestErrorModel) => {
    store.error = error;
  }),
};

const apiRequests = {
  trackTestEvent: action(
    (eventName: string, eventType: string, testToken: string, extraData?: Record<string, unknown>) => {
      logsRepository.trackTestEvent(eventName, eventType, testToken, extraData).catch(e => {
        const { message, status, type } = e as ErrorResponse;
        store.setError({ message, status, type });
      });
    },
  ),
};

const store: LogsStore = observable({
  ...stateSetters,
  ...apiRequests,
} as LogsStore);

export const useLogs = (): LogsStore => store;
