import React from 'react';
import { useDomainHandler } from 'hooks/useDomainHandler';
import { StyledCard, StyledContainer, StyledHogesLogo, StyledPage } from './AccessPageLayout.styled';

export const AccessPageLayout: React.FC = ({ children }) => {
  const { getCustomLogoByDomain } = useDomainHandler();

  return (
    <StyledPage>
      <StyledCard>
        <StyledHogesLogo dataCy="hogeschooltaal-logo" iconName={getCustomLogoByDomain()} />
        <StyledContainer>{children}</StyledContainer>
      </StyledCard>
    </StyledPage>
  );
};
