import React, { useMemo } from 'react';
import sanitizeHtml from 'sanitize-html';
import parse, { domToReact, DOMNode } from 'html-react-parser';
import { Element, Text } from 'domhandler/lib/node';
import { EmbeddedHtmlProps, ReplaceChildrenFunction } from './EmbeddedHtml.model';

/**
 *  This component receives a string with raw HTML code and replaces some of its
 * tags by a JSX view. This allows to replace native tags by React components to
 * make it easy to handle its behaviour directly within our components, instead
 * of having to rely on vanilla JS code.
 *
 *  The "replaceFunction" will be the one to perform transformations to the
 * received HTML code. For that to happen, this method will receive the tag
 * type and the list of attributes for the different DOM nodes defined within
 * the HTML code. With that data at hand, we can identify the element we've
 * received and, if applicable, return a JSX view that will replace it in the
 * generated view.
 *
 * @param {string} [props.className] - CSS classes to apply to the container
 * @param {string} props.rawHtml - Raw text with HTML code
 * @param {function} [props.replaceFunction] - Method that receives information
 *  on a DOM node (tag type and attributes) and, optionally, returns a JSX view.
 *
 * @returns {object} View generated after making the replacements
 *
 * @see {@link https://www.npmjs.com/package/html-react-parser}
 */

const getInnerText = ({ children }: Element): string => {
  const [firstChild] = children;
  if (children.length !== 1 || firstChild.type !== 'text') {
    return '';
  }

  return (firstChild as Text).data;
};

/* eslint-disable consistent-return, @typescript-eslint/no-explicit-any */
export const EmbeddedHtml: React.FC<EmbeddedHtmlProps> = React.memo(
  ({ tagName = 'div', className = '', dataCy, rawHtml, replaceFunction, sanitizeOptions }): any => {
    const parseOptions = useMemo(
      () =>
        replaceFunction && {
          replace: (domNode: DOMNode) => {
            const element = domNode as Element;
            if (element.type === 'tag') {
              const res = replaceFunction({
                tagType: element.name,
                attributes: element.attribs,
                innerText: getInnerText(element),
                children: element.children as DOMNode[],
              });
              // by returning a function it's possible to also
              // parse children of the current component
              if (typeof res === 'function') {
                return (res as ReplaceChildrenFunction)(
                  // eslint-disable-next-line
                  () => domToReact(element.children as DOMNode[], parseOptions),
                );
              }
              return res;
            }
          },
        },
      [replaceFunction],
    );

    const parsedHtml = useMemo(() => {
      const sanitizedHtml = sanitizeHtml(rawHtml, sanitizeOptions);
      return parse(sanitizedHtml, parseOptions);
    }, [rawHtml, parseOptions, sanitizeOptions]);

    if (!tagName) {
      return parsedHtml;
    }

    const Tag = tagName;
    return (
      <Tag className={className} data-cy={dataCy}>
        {parsedHtml}
      </Tag>
    );
  },
);
