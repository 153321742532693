/* --- Environments -------------------------------------------------------- */

export enum EnvironmentType {
  TEST = 'test',
  LOCAL = 'local',
  DEVELOPMENT = 'development',
  STAGING = 'staging',
  PRODUCTION = 'production',
}

const ENV_SCOPE = {
  COOKIE: 'cookie',
  SERVICE: 'service',
  SEMANTIC: 'semantic',
};

const envType: Record<string, Record<EnvironmentType, string>> = {
  [ENV_SCOPE.COOKIE]: {
    development: 'development',
    staging: 'staging',
    production: 'helearning',
    local: 'local',
    test: 'test',
  },
  [ENV_SCOPE.SERVICE]: {
    development: 'development',
    staging: 'staging',
    production: 'production',
    local: 'local',
    test: 'test',
  },
  [ENV_SCOPE.SEMANTIC]: {
    development: 'Dev',
    staging: 'Acc',
    production: 'Prod',
    local: 'Test',
    test: 'Test',
  },
};

const getENV = (scope: string): string => {
  // @ts-ignore: we have to ignore this https://github.com/cypress-io/cypress/issues/3924#issuecomment-481430796
  if (window.Cypress) {
    return envType[scope].test;
  }

  const rootElement = document.getElementById('root');
  if (!rootElement) {
    return envType[scope].local;
  }

  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return envType[scope][rootElement.dataset.environment] || envType[scope].local;
};

export const cookieENV = (): string => getENV(ENV_SCOPE.COOKIE);
export const serviceENV = (): string => getENV(ENV_SCOPE.SERVICE);
export const semanticENV = (): string => getENV(ENV_SCOPE.SEMANTIC);

/* --- Environment checkers ------------------------------------------------ */

export const isDev = (): boolean => serviceENV() === envType[ENV_SCOPE.SERVICE].development;
export const isStaging = (): boolean => serviceENV() === envType[ENV_SCOPE.SERVICE].staging;
export const isProd = (): boolean => serviceENV() === envType[ENV_SCOPE.SERVICE].production;
export const isLocal = (): boolean => serviceENV() === envType[ENV_SCOPE.SERVICE].local;
export const isTest = (): boolean => serviceENV() === envType[ENV_SCOPE.SERVICE].test;

/* --- Cookies ------------------------------------------------------------- */

export const COOKIE_SESSION_ID = 'session_id';
export const COOKIE_SESSION_TOKEN = 'session_token';
export const COOKIE_REFRESH_TOKEN = 'refresh_token';
export interface CookieOptionsInterface {
  domain?: string;
  secure?: boolean;
  expires?: number;
  sameSite?: 'None';
}

export const getCookieOptions = (): CookieOptionsInterface => {
  const rootElement = document.getElementById('root');
  if (!rootElement) return {};
  return rootElement.dataset.environment === 'production' || rootElement.dataset.environment === 'staging'
    ? {
        secure: true,
        expires: 7,
        sameSite: 'None',
      }
    : {};
};

/* --- Misc. constants ----------------------------------------------------- */

export const NOT_VALID_VALUES = [null, undefined, '', []];
