import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useModal } from 'hooks/useModal';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { modalIds } from 'constants/modals-constants';

import { Button } from 'components/Button';

import MaintainanceImage from 'assets/images/guy-with-glasses-unplug.png';

import { StyledModalContainer, StyledMessageTitle, StyledMessageText } from './PlatformMaintainanceModal.styled';

export const PlatformMaintainanceModal: React.FC = () => {
  const { t } = useTranslation('modal');
  const { isModalOpen, closeModal, toggleModal } = useModal();
  const { showPlatformUnderConstructionModal } = useFeatureFlags();
  const maintainanceModalId = modalIds.platformMaintainanceModal;

  useEffect(() => {
    showPlatformUnderConstructionModal && toggleModal(maintainanceModalId);
  }, [showPlatformUnderConstructionModal]);

  const onCloseModalHandler = () => {
    closeModal();
    window.location.reload();
  };

  return (
    <StyledModalContainer
      dataCy="platform-maintainance-message-modal"
      isOpen={isModalOpen(maintainanceModalId)}
      onClose={onCloseModalHandler}
    >
      <img alt="" src={MaintainanceImage} />
      <StyledMessageTitle>
        {t('underConstructionMessage.title', 'Our platform is currently under construction')}
      </StyledMessageTitle>
      <StyledMessageText>
        {t(
          'underConstructionMessage.text',
          "We're working hard to make this website available again soon. We apologize for the inconvenience.",
        )}
      </StyledMessageText>
      <Button variant="primary" onClick={() => onCloseModalHandler()}>
        {t('underConstructionMessage.button', 'Try again')}
      </Button>
    </StyledModalContainer>
  );
};
