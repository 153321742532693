import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { getMonth, getYear } from 'date-fns';
import sanitizeHtml from 'sanitize-html';
import { useDomainHandler } from 'hooks/useDomainHandler';

import { Modal } from 'components/Modal';
import { LoadingSpinner } from 'components/LoadingSpinner';

import { Heading1, Heading3, Heading4, Heading5 } from 'styles/elements/Headings';
import { Paragraph } from 'styles/elements/Texts';
import landmanSignature from 'assets/images/landman_signature.png';
import noweeSignature from 'assets/images/nowee-signature.png';
import {
  StyledDownloadCertificatePage,
  StyledBody,
  StyledLoader,
  StyledPage,
  StyledCertificateMainWrapper,
  StyledCertificateWrapper,
  StyledFooterWrapper,
  StyledFooter,
  StyledStroke,
  StyledIcon,
} from './DownloadCertificatePage.styled';

interface DownloadReportPageProps {
  onDone: () => void;
  firstName: string;
  middleName: string;
  lastName: string;
  schoolNumber: string;
  skillLevel: string;
  date: string;
  id: string;
  productId: number;
}

const getAppropriateSchoolYear = (dateString: string) => {
  const generatedDate = new Date(dateString);
  const generatedMonth = getMonth(generatedDate);
  const generatedYear = getYear(generatedDate);

  if (generatedMonth <= 5) {
    return `${generatedYear - 1} - ${generatedYear}`;
  }
  return `${generatedYear} - ${generatedYear + 1}`;
};

export const DownloadcertificatePage: React.FC<DownloadReportPageProps> = ({
  onDone,
  firstName,
  middleName,
  lastName,
  schoolNumber,
  skillLevel,
  date,
  id,
  productId,
}) => {
  const isCanceled = useRef(false);
  const { t } = useTranslation('results');
  const { getCustomLogoByDomain, getCustomNameByDomain, getStudentTypeByDomain } = useDomainHandler();
  const elRef = useRef<HTMLDivElement>(null);
  const isDutchProduct = productId === 68;

  useEffect(() => {
    if (elRef.current) {
      // Build the actual PDF
      // code splitted because we don't want to load PDF and html2canvas library
      // when not needed
      import('_helpers/pdf')
        .then(({ pdfFromElement }) => {
          if (isCanceled.current) {
            return undefined;
          }
          return pdfFromElement(
            elRef.current as HTMLElement,
            t('downloadCertificate.filename', 'certificate.pdf'),
            pdf => (isCanceled.current ? undefined : pdf),
            'l',
          );
        })
        .then(onDone);
    }
  }, []);

  const onCancel = () => {
    // make sure the pdf is not downloaded after 'x' press on modal
    isCanceled.current = true;
    onDone();
  };

  const appName = getCustomNameByDomain();
  const userName = firstName.concat(' ', middleName, ' ', lastName);
  const studentNumber = sanitizeHtml(schoolNumber, { allowedTags: [], allowedAttributes: {} });

  const certificateTextRef = isDutchProduct
    ? 'downloadCertificate.certificate.dutchCertificateText'
    : 'downloadCertificate.certificate.englishCertificateText';

  return (
    <StyledDownloadCertificatePage>
      <StyledPage>
        <div ref={elRef}>
          <StyledCertificateMainWrapper>
            <StyledCertificateWrapper>
              <StyledBody>
                <Heading1>{t('downloadCertificate.certificate.header', 'Certificate')}</Heading1>
                <Heading3>{t(`${certificateTextRef}.subtitle`, { skillLevel: skillLevel || '' })}</Heading3>
                <Heading4>
                  {t(`${certificateTextRef}.studentInfo`, {
                    userName: userName || '',
                    schoolNumber: studentNumber || '',
                  })}
                </Heading4>
                <Heading4>
                  {t(`${certificateTextRef}.schoolYear`, { schoolYear: getAppropriateSchoolYear(date) || '' })}
                </Heading4>
                <Heading5>
                  {t(`${certificateTextRef}.infoText`, {
                    appName,
                    skillLevel: skillLevel || '',
                    studentType: t(`downloadCertificate.certificate.studentType.${getStudentTypeByDomain()}`),
                  })}
                </Heading5>
              </StyledBody>
              <StyledFooterWrapper>
                <StyledFooter>
                  <div>
                    <img alt="" data-cy="editor-in-chief-signature-image" src={landmanSignature} />
                    <StyledStroke />
                    <Paragraph>E.C. Landman</Paragraph>
                    <Paragraph>
                      {t('downloadCertificate.certificate.signature.editor', 'Editor-in-chief Testing, SKE-certified')}
                    </Paragraph>
                  </div>
                  <div>
                    <StyledIcon iconName={getCustomLogoByDomain()} size={3} />
                    <StyledIcon iconName="logoCertificateInstitute" size={3} />
                  </div>
                  <div>
                    <img alt="" data-cy="manager-signature-image" src={noweeSignature} />
                    <StyledStroke />
                    <Paragraph>J.N.M. Nowee</Paragraph>
                    <Paragraph>{t('downloadCertificate.certificate.signature.manager', { appName })}</Paragraph>
                  </div>
                </StyledFooter>
                <small>
                  {t('downloadCertificate.certificate.hash', { appName })}
                  {id}
                </small>
              </StyledFooterWrapper>
            </StyledCertificateWrapper>
          </StyledCertificateMainWrapper>
        </div>
      </StyledPage>
      <Modal isOpen closeOnClickOutside={false} onClose={onCancel}>
        <StyledLoader>
          <LoadingSpinner />
          <span>{t('downloadCertificate.loading', 'Generating certificate...')}</span>
        </StyledLoader>
      </Modal>
    </StyledDownloadCertificatePage>
  );
};
