import styled, { css } from 'styled-components/macro';

import { ThemeProps } from 'models/styles/theme';

import { CssFlex } from 'styles/helpers/layout';

interface GroupIconContainerProps extends ThemeProps {
  $ownedBySelf?: boolean;
}

export const StyledGroupIconContainer = styled(CssFlex)<GroupIconContainerProps>`
  border-radius: 50%;

  width: 1.25rem;
  height: 1.25rem;
  font-size: 10px;

  align-items: center;
  justify-content: center;

  ${({ $ownedBySelf }: GroupIconContainerProps) => {
    if ($ownedBySelf) {
      return css`
        color: #f9b002;
        background: #fff3d5;
      `;
    } else {
      return css`
        color: #0078f1;
        background: #eef7ff;
      `;
    }
  }};
`;
