import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { ScheduledTestResultList, ScheduledTestReviewPeriodTimer } from 'models/exam/ScheduledTest';

import { Heading3 } from 'styles/elements/Headings';
import { ScheduledTestResultRow } from '../ScheduledTestResultRow/ScheduledTestResultRow';
import { StyledTable } from './ScheduledNoOwnedTestResultsTable.styled';

interface ScheduledTestResultsTableProps {
  data: ScheduledTestResultList[];
  timers: ScheduledTestReviewPeriodTimer[];
}

export const ScheduledNoOwnedTestResultsTable: React.FC<ScheduledTestResultsTableProps> = ({
  data = [],
  timers = [],
}) => {
  const { t } = useTranslation('results');

  const groupBy = (arr: ScheduledTestResultList[]) =>
    arr.reduce((result: Record<string, ScheduledTestResultList[]>, product: ScheduledTestResultList) => {
      const key = 'productName';
      return Object.assign(result, { [product[key]]: (result[product[key]] || []).concat(product) });
    }, {});

  const groupedElements = groupBy(data);

  return (
    <>
      {Object.entries(groupedElements).map(([productName, testGroup]) => (
        <Fragment key={`row-${productName}`}>
          <Heading3>{productName}</Heading3>
          <StyledTable>
            <thead>
              <tr>
                <th>{t('testResultOverviewTable.columns.date', 'Date')}</th>
                <th>{t('testResultOverviewTable.columns.skillLevel', 'Level')}</th>
                <th>{t('testResultOverviewTable.columns.testTitle', 'Test')}</th>
                <th>{t('testResultOverviewTable.columns.exercises', 'Subjects')}</th>
                <th>{t('testResultOverviewTable.columns.score', 'Score')}</th>
                <th> </th>
              </tr>
            </thead>
            <tbody>
              {testGroup.map((scheduledTest, index) => (
                <ScheduledTestResultRow
                  key={scheduledTest.id}
                  isOddRow={index % 2 === 0}
                  reviewPeriodTimeLeft={timers.find(({ id }) => id === scheduledTest.id)?.counter || 0}
                  scheduledTestOverview={scheduledTest}
                />
              ))}
            </tbody>
          </StyledTable>
        </Fragment>
      ))}
    </>
  );
};
