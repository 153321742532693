import axios, { AxiosError } from 'axios';
import { ERROR_TYPES } from 'constants/error-constants';
import cookieStorage from 'persistence';
import { logError } from 'repositories/utils';
import { RequestError, ApiRequestError as Error } from 'models/error/Error';

const trackTestEvent = async (
  name: string,
  type: string,
  testToken: string,
  extraData?: Record<string, unknown>,
): Promise<void> => {
  try {
    await axios.post(
      '/api/track/event',
      { event: { name, type, extraData } },
      {
        headers: {
          Authorization: cookieStorage.getToken(),
          test_token: testToken,
        },
      },
    );
  } catch (error) {
    const { message, status } = logError(error as AxiosError<Error>);
    throw new RequestError({ message, status, type: ERROR_TYPES.TRACK_TEST_EVENT_FAILED });
  }
};

export { trackTestEvent };
