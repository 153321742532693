import React from 'react';
import { Heading4 } from 'styles/elements/Headings';
import { useTranslation, Trans } from 'react-i18next';
import { useDomainHandler } from 'hooks/useDomainHandler';
import { Button } from 'components/Button';
import { StyledButtonContainer, StyledNotice, StyledParagraph } from './ImportantNotice.styled';
import { ImportantNoticeProps } from './ImportantNotice.model';

export const ImportantNotice: React.FC<ImportantNoticeProps> = ({ onAccept }) => {
  const { t } = useTranslation('test-environment');
  const { getCustomNameByDomain, getTeacherTermByDomain } = useDomainHandler();
  return (
    <>
      <StyledNotice data-cy="instructions-notice-container">
        <Heading4>
          {t(
            'instructions.firstStep.subheading',
            'This test must be taken under supervision in a computer room or through online proctoring.',
          )}
        </Heading4>
        <Trans i18nKey="instructions.firstStep.paragraph" t={t}>
          <StyledParagraph>
            If you continue to use this site, you confirm that you’re taking the test using online proctoring or that
            you’re in a supervised room, selected by your school.
          </StyledParagraph>
          <StyledParagraph>
            In addition, we register the IP address of your computer. We will report any abnormalties in your IP address
            and in the test log to your school. Your {{ teacherTerm: getTeacherTermByDomain() }} has access to this
            data. {{ appName: getCustomNameByDomain() }} is not responsible for the consequences of this notification.
          </StyledParagraph>
        </Trans>
      </StyledNotice>
      <StyledButtonContainer>
        <Button dataCy="instructions-button-important-notice" variant="primary" onClick={onAccept}>
          {t('instructions.importantNotice.button', 'Accept these terms')}
        </Button>
      </StyledButtonContainer>
    </>
  );
};
