import React from 'react';
import { observer } from 'mobx-react';
import { Switch, Redirect, useParams } from 'react-router-dom';

import { AUTH_PAGES_ROUTES, GENERIC_ROUTES, RESULT_PAGE_ROUTES, TEST_ENVIRONMENT_ROUTES } from 'constants/routes';

import { AuthRoute } from 'layouts/AuthRoute';
import { useScheduledTests } from 'hooks/useScheduledTests';
import { buildUrlWithPathParams } from '_helpers/utils/urlBuilder';
import { ScheduledTestScoreDetail } from 'models/exam/ScheduledTest';

import { TestResultsPage, ExerciseResultsPage } from './subpages';
import { ResultsPagePathParams } from './ResultsPage.model';

export const ResultsPage: React.FC = observer(() => {
  const { code }: ResultsPagePathParams = useParams();
  const { scheduledTestScore } = useScheduledTests();

  // Obtain url to redirect to when accessing base path based on loaded test
  const getResultsMainUrl = ({ type: testType }: ScheduledTestScoreDetail): string => {
    const resultsPathUrl = RESULT_PAGE_ROUTES.TEST_TYPE_RESULTS_LIST;
    return buildUrlWithPathParams(resultsPathUrl, {
      testType,
    });
  };

  return (
    <Switch>
      <AuthRoute
        exact
        component={() => (
          <Redirect to={!scheduledTestScore ? GENERIC_ROUTES.HOME_PAGE : getResultsMainUrl(scheduledTestScore)} />
        )}
        path={TEST_ENVIRONMENT_ROUTES.TEST_RESULTS_PATH}
        redirectPath={AUTH_PAGES_ROUTES.LOG_IN}
      />
      <AuthRoute
        exact
        component={TestResultsPage}
        path={TEST_ENVIRONMENT_ROUTES.TEST_RESULTS_PAGE}
        redirectPath={AUTH_PAGES_ROUTES.LOG_IN}
      />
      <AuthRoute
        exact
        component={ExerciseResultsPage}
        path={TEST_ENVIRONMENT_ROUTES.TEST_EXERCISE_RESULTS_PAGE}
        redirectPath={AUTH_PAGES_ROUTES.LOG_IN}
      />

      {/* --- Default path --- */}
      <Redirect to={buildUrlWithPathParams(TEST_ENVIRONMENT_ROUTES.TEST_RESULTS_PAGE, { code })} />
    </Switch>
  );
});
