import { AvailableLanguage } from 'constants/language-constants';
import { parseISO } from 'date-fns';
import i18n from 'i18n';
import { CustomReviewDate } from 'pages/TeacherEnvironment/TeacherDashboard/subpages/ScheduledTestWizard/steps/TestDatesStep/components/CustomReviewDate/CustomReviewDate';
import { updateDateTime } from 'pages/TeacherEnvironment/TeacherDashboard/subpages/ScheduledTestWizard/steps/TestDatesStep/utils';
import React, { FC } from 'react';

export interface ReviewDatesProps {
  startDate: string;
  endDate: string;
  onValueChanged: (startDate: string, endDate: string) => void;
}

export const ReviewDates: FC<ReviewDatesProps> = ({ startDate, endDate, onValueChanged }) => {
  const parsedStartDate = parseISO(startDate);
  const parsedEndDate = parseISO(endDate);

  return (
    <CustomReviewDate
      hideParagraph
      isEditingScheduledTest
      alignment="vertical"
      dataCy="admin-review-date"
      language={i18n.language as AvailableLanguage}
      reviewEndDateValue={parsedEndDate}
      reviewStartDateValue={parsedStartDate}
      onEndDateUpdate={({ date, hours, minutes }) => {
        onValueChanged(
          startDate,
          updateDateTime(
            date ?? parsedEndDate,
            hours ?? parsedEndDate.getHours(),
            minutes ?? parsedEndDate.getMinutes(),
          ).toISOString(),
        );
      }}
      onStartDateUpdate={({ date, hours, minutes }) => {
        onValueChanged(
          updateDateTime(
            date ?? parsedStartDate,
            hours ?? parsedStartDate.getHours(),
            minutes ?? parsedStartDate.getMinutes(),
          ).toISOString(),
          endDate,
        );
      }}
    />
  );
};
