/* eslint-disable @typescript-eslint/no-unsafe-return */
import { sortQuestionsWithAnsweredFirst } from '_helpers/questionHelper';
import { ToastMethods } from 'components/ToastNotification';
import i18n from 'i18n';
import { action, observable } from 'mobx';
import {
  DiagnosticTest,
  DiagnosticTestDetailsResult,
  DiagnosticTestGroup,
  DiagnosticTestResult,
} from 'models/exam/DiagnosticTest';
import { ExamQuestion, Exercise, ExerciseStatusResponse, TestDetails } from 'models/exam/Exam';
import { diagnosticTestRepository } from 'repositories';

interface DiagnosticTestStore {
  loading: boolean;
  selectedQuestionIndex: number;
  diagnosticTest?: Exercise | null;
  diagnosticTestGroups: DiagnosticTestGroup[];
  diagnosticTestList: DiagnosticTest[];
  diagnosticTestListResults: DiagnosticTestResult[];
  diagnosticTestDetails: TestDetails;
  diagnosticTestDetailsResults: DiagnosticTestDetailsResult[];

  setSelectedQuestionIndex: (val: number) => void;
  setDiagnosticTest: (test: Exercise) => void;
  setDiagnosticTestGroups: (diagnosticTestGroups: DiagnosticTestGroup[]) => void;
  setDiagnosticTestList: (diagnosticTestList: DiagnosticTest[]) => void;
  setDiagnosticTestListResults: (diagnosticTestListResults: DiagnosticTestResult[]) => void;
  setDiagnosticTestDetails: (diagnosticTestDetails: TestDetails) => void;
  setDiagnosticTestDetailsResults: (diagnosticTestDetailsResults: DiagnosticTestDetailsResult[]) => void;
  setLoading: (val: boolean) => void;

  fetchDiagnosticTest: (productId: number, exerciseId: number) => void;
  fetchDiagnosticTestGroups: (productId: number, moduleId: number) => void;
  fetchDiagnosticTestList: (productId: number, moduleId: number, groupId: number) => void;
  fetchDiagnosticTestListResults: (productId: number, moduleId: number, groupId: number) => void;
  fetchDiagnosticTestDetails: (productId: number, moduleId: number, groupId: number, testId: number) => void;
  fetchDiagnosticTestDetailsResults: (productId: number, moduleId: number, groupId: number, testId: number) => void;
}

// eslint-disable-next-line prefer-const
let store: DiagnosticTestStore;

const initialState = {
  diagnosticTest: null,
  diagnosticTestGroups: [] as DiagnosticTestGroup[],
  diagnosticTestList: [] as DiagnosticTest[],
  diagnosticTestListResults: [] as DiagnosticTestResult[],
  diagnosticTestDetails: {} as TestDetails,
  diagnosticTestDetailsResults: [] as DiagnosticTestDetailsResult[],
  loading: false,
  selectedQuestionIndex: 0,
};

const stateSetters = {
  setDiagnosticTest: action((test: Exercise) => {
    store.diagnosticTest = test;
  }),
  setDiagnosticTestGroups: action((diagnosticTestGroups: DiagnosticTestGroup[]) => {
    store.diagnosticTestGroups = diagnosticTestGroups;
  }),
  setDiagnosticTestList: action((diagnosticTestList: DiagnosticTest[]) => {
    store.diagnosticTestList = diagnosticTestList;
  }),
  setDiagnosticTestListResults: action((diagnosticTestListResults: DiagnosticTestResult[]) => {
    store.diagnosticTestListResults = diagnosticTestListResults;
  }),
  setDiagnosticTestDetails: action((diagnosticTestDetails: TestDetails) => {
    store.diagnosticTestDetails = diagnosticTestDetails;
  }),
  setDiagnosticTestDetailsResults: action((diagnosticTestDetailsResults: DiagnosticTestDetailsResult[]) => {
    store.diagnosticTestDetailsResults = diagnosticTestDetailsResults;
  }),
  setLoading: action((val: boolean) => {
    store.loading = val;
  }),
  setSelectedQuestionIndex: action((val: number) => {
    store.selectedQuestionIndex = val;
  }),
};

const apiRequests = {
  fetchDiagnosticTest: action(async (productId: number, exerciseId: number) => {
    store.setLoading(true);

    const requests: [Promise<Exercise>, Promise<ExerciseStatusResponse>] = [
      diagnosticTestRepository.fetchExercise(productId, exerciseId),
      diagnosticTestRepository.fetchExerciseStatus(exerciseId),
    ];

    const [exercise, currentStatus] = await Promise.all(requests);
    if (!currentStatus) {
      store.setSelectedQuestionIndex(0);
      store.setDiagnosticTest(exercise);
    } else {
      const { answeredQuestions, totalQuestions, contentSessionToken: prevAttemptToken } = currentStatus;

      // Verify if any of the previously answered questions do not exist in the content anymore
      const hasExerciseContentChanged = answeredQuestions.some(
        questionId => !exercise.questions.find(({ id }) => id === questionId),
      );

      // When resuming an attempt, reorder answers to put previously answered questions first
      // Not doing so could cause "questionIndex" to point to a question that has been answered before,
      // triggering errors when submitting a result
      const sortedQuestions: ExamQuestion[] = hasExerciseContentChanged
        ? exercise.questions
        : sortQuestionsWithAnsweredFirst(exercise.questions, answeredQuestions);

      // If the content has changed or the previous attempt was completed, we should start from scratch
      const shouldStartNewAttempt = hasExerciseContentChanged || answeredQuestions.length === totalQuestions;

      store.setSelectedQuestionIndex(shouldStartNewAttempt ? 0 : answeredQuestions.length);
      store.setDiagnosticTest({
        ...exercise,
        questions: sortedQuestions,
        token: shouldStartNewAttempt ? exercise.token : prevAttemptToken,
      });
    }

    store.setLoading(false);
  }),

  fetchDiagnosticTestGroups: action(async (productId: number, moduleId: number) => {
    store.setLoading(true);
    try {
      const response = await diagnosticTestRepository.fetchDiagnosticTestGroups(productId, moduleId);
      store.setDiagnosticTestGroups(response);
    } catch (e) {
      ToastMethods.showToast(i18n.t('toast:exam.error.getDiagnosticTestGroups'), 'error');
    }
    store.setLoading(false);
  }),

  fetchDiagnosticTestList: action(async (productId: number, moduleId: number, groupId: number) => {
    store.setLoading(true);
    try {
      const response = await diagnosticTestRepository.fetchDignosticTestList(productId, moduleId, groupId);
      store.setDiagnosticTestList(response);
    } catch (e) {
      ToastMethods.showToast(i18n.t('toast:exam.error.getDiagnosticTestList'), 'error');
    }
    store.setLoading(false);
  }),

  fetchDiagnosticTestListResults: action(async (productId: number, moduleId: number, groupId: number) => {
    store.setLoading(true);
    try {
      const response = await diagnosticTestRepository.fetchDiagnosticTestListResults(productId, moduleId, groupId);
      store.setDiagnosticTestListResults(response);
    } catch (e) {
      ToastMethods.showToast(i18n.t('toast:exam.error.getDiagnosticTestListResults'), 'error');
    }
    store.setLoading(false);
  }),

  fetchDiagnosticTestDetails: action(async (productId: number, moduleId: number, groupId: number, testId: number) => {
    store.setLoading(true);
    try {
      const response = await diagnosticTestRepository.fetchDiagnosticTestDetails(productId, moduleId, groupId, testId);
      store.setDiagnosticTestDetails(response);
    } catch (e) {
      ToastMethods.showToast(i18n.t('toast:exam.error.getDiagnosticTestDetails'), 'error');
    }
    store.setLoading(false);
  }),

  fetchDiagnosticTestDetailsResults: action(
    async (productId: number, moduleId: number, groupId: number, testId: number) => {
      store.setLoading(true);
      try {
        const response = await diagnosticTestRepository.fetchDiagnosticTestDetailsResults(
          productId,
          moduleId,
          groupId,
          testId,
        );
        store.setDiagnosticTestDetailsResults(response);
      } catch (e) {
        ToastMethods.showToast(i18n.t('toast:exam.error.getDiagnosticTestDetailsResults'), 'error');
      }
      store.setLoading(false);
    },
  ),
};

store = observable({
  ...initialState,
  ...stateSetters,
  ...apiRequests,
} as DiagnosticTestStore);

export const useDiagnosticTest = (): DiagnosticTestStore => store;
