/* eslint-disable @typescript-eslint/no-use-before-define */
import { downloadExcelFile } from '_helpers/report';
import { ToastMethods } from 'components/ToastNotification';
import i18n from 'i18n';
import { action, observable } from 'mobx';
import { progressRepository, writingRepository } from 'repositories';
import { SubjectsProgressOverviewFilters } from 'models/progress/Progress';

interface ReportStore {
  loading: boolean;
  generateBasicProgressReport: (productId: number, filters: SubjectsProgressOverviewFilters) => void;
  generateWritingResultsReport: (productId: number, filters: SubjectsProgressOverviewFilters) => void;
  setLoading: (val: boolean) => void;
}

const initialState = {
  loading: false,
};
const stateSetters = {
  setLoading: action((loading: boolean) => {
    store.loading = loading;
  }),
};
const apiRequests = {
  generateBasicProgressReport: action((productId: number, filters: SubjectsProgressOverviewFilters) => {
    store.setLoading(true);
    progressRepository
      .generateBasicProgressReport(productId, filters)
      .then(basicProgressReport => {
        downloadExcelFile('Basic progress report.xlsx', basicProgressReport);
      })
      .catch(() => {
        ToastMethods.showToast(i18n.t('toast:admin.generateBasicProgressReport.error'), 'error');
      })
      .finally(() => {
        store.setLoading(false);
      });
  }),

  generateWritingResultsReport: action((productId: number, filters: SubjectsProgressOverviewFilters) => {
    store.setLoading(true);
    writingRepository
      .fetchGeneratedReport(productId, filters)
      .then(report => {
        downloadExcelFile('Writing results report.xlsx', report.document);
      })
      .catch(() => {
        ToastMethods.showToast(i18n.t('toast:writing.error.generateWritingResultsReport'), 'error');
      })
      .finally(() => {
        store.setLoading(false);
      });
  }),
};

const store: ReportStore = observable({
  ...stateSetters,
  ...apiRequests,
  ...initialState,
} as ReportStore);

export const useReport = (): ReportStore => store;
