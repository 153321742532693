import styled from 'styled-components/macro';
import { FontAwesomeIcon } from 'components/FontAwesomeIcon';
import { ThemeProps as Props } from 'models/styles/theme';
import { calculateRem } from 'styles/mixins/calculateRem';
import { StyledSearchBox } from 'components/SearchBox/SearchBox.styled';
import { Heading2 } from 'styles/elements/Headings';
import { Tag } from 'components/Tag/Tag';
import { Button } from 'components/Button';
import { IconButton } from 'components/IconButton/IconButton';
import { SortableTable } from 'components/SortableTable/SortableTable';
import { StyledHeaderCell } from 'components/SortableTable/SortableTable.styled';

export const StyledLiveMonitorPage = styled.div``;

export const StyledLiveMonitorStudents = styled.div<Props>`
  padding-bottom: 15px;
  ${Heading2} {
    font-weight: bold;
  }

  > div {
    border: 1px solid ${({ theme }: Props) => theme.palette.neutral._400};
    border-radius: 4px;

    > ${StyledSearchBox} {
      margin: 1.5rem;
      > input {
        width: 100%;
        box-sizing: border-box;
      }
    }
  }
`;

export const StyledTable = styled(SortableTable)<Props>`
  ${StyledHeaderCell} {
    align-items: flex-start;
    justify-content: center;
    padding: 1rem 0.5rem;
  }
`;

export const StyledFilterList = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`;

export const StyledTestVersionTag = styled(Tag)<Props>`
  border-radius: ${calculateRem(100)};
`;

export const StyledButton = styled(Button)`
  margin-left: 0.5rem;
  font-size: 1rem;
  font-weight: 400;
`;

export const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  margin-left: 1rem;
`;

export const StyledSearchBoxWrapper = styled.div<Props>`
  padding: 0.5rem;
  margin-left: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  > div {
    :first-child {
      width: 50%;
    }
  }

  > button {
    color: ${({ theme }: Props) => theme.palette.primary.brand};
    border: 1px solid ${({ theme }: Props) => theme.palette.primary.brand};
    background: ${({ theme }: Props) => theme.palette.neutral.white};
  }
`;

export const StyledIconButton = styled(IconButton)`
  background: none;
`;
