import styled from 'styled-components/macro';

import { Link } from 'components/Link';
import { ThemeProps } from 'models/styles/theme';
import { calculateRem } from 'styles/mixins/calculateRem';
import { Button } from 'components/Button';

interface LinkProps extends ThemeProps {
  $selected: boolean;
}

export const StyledPaginator = styled.div<ThemeProps>`
  align-items: center;
  display: flex;
  flex-direction: row;
  height: ${calculateRem(80)};
  justify-content: space-around;
  margin-top: ${calculateRem(45)};
  width: 100%;
`;

export const StyledPages = styled.div<ThemeProps>`
  display: flex;
  flex-direction: row;
`;

export const StyledLink = styled(Link)<LinkProps>`
  align-items: center;
  background-color: ${({ theme, $selected }: LinkProps) => $selected && theme.palette.neutral._20};
  border-radius: ${({ theme }: ThemeProps) => theme.borderRadius.rounded};
  height: ${calculateRem(40)};
  margin-right: ${calculateRem(16)};
  width: ${calculateRem(30)};

  &:last-of-type {
    margin-right: 0;
  }
`;

export const StyledButton = styled(Button)<ThemeProps>`
  font-weight: ${({ theme }: ThemeProps) => theme.font.weight.bold};

  &:first-of-type {
    svg {
      margin-right: ${calculateRem(16)};
    }
  }

  &:last-of-type {
    svg {
      fill: ${({ theme }: ThemeProps) => theme.palette.neutral.white};
      margin-left: ${calculateRem(16)};
    }
  }
`;

export const StyledDot = styled.span<ThemeProps>`
  margin: 0 ${calculateRem(16)};
  align-items: center;
  height: ${calculateRem(40)};
`;
