import React from 'react';

import { LevelDashGroup } from 'components/LevelDashGroup';

import { ExerciseTitleBarProps } from './ExerciseTitleBar.model';
import { StyledTitleBar, StyledTitle, StyledProgressBar } from './ExerciseTitleBar.styled';

export const ExerciseTitleBar: React.FC<ExerciseTitleBarProps> = ({ score, title }) => (
  <StyledTitleBar>
    <StyledTitle data-cy="test-exercise-title">{title}</StyledTitle>
    <StyledProgressBar>
      <LevelDashGroup dashSize={2} level={score} numberOfDashes={5} />
      <b data-cy="test-exercise-score">{`${Math.round(score)}%`}</b>
    </StyledProgressBar>
  </StyledTitleBar>
);
