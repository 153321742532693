import * as Yup from 'yup';
import i18n from 'i18n';

export interface BasicAccountFormFields {
  email: string;
  password: string;
  firstName: string;
  middleName?: string;
  lastName: string;
}

export const buildBasicAccountSchema = (): Yup.SchemaOf<BasicAccountFormFields> =>
  Yup.object({
    email: Yup.string()
      .trim()
      .required(i18n.t('auth-pages:createBasicAccountPage.form.fields.email.errors.required'))
      .email(i18n.t('auth-pages:createBasicAccountPage.form.fields.email.errors.invalid')),
    password: Yup.string()
      .trim()
      .required(i18n.t('auth-pages:createBasicAccountPage.form.fields.password.errors.required'))
      .min(8, i18n.t('auth-pages:createBasicAccountPage.form.fields.password.errors.minLength'))
      .matches(/(?=.*[a-z])/, i18n.t('auth-pages:createBasicAccountPage.form.fields.password.errors.missingLowercase'))
      .matches(/(?=.*[A-Z])/, i18n.t('auth-pages:createBasicAccountPage.form.fields.password.errors.missingUppercase'))
      .matches(/(?=.*[0-9])/, i18n.t('auth-pages:createBasicAccountPage.form.fields.password.errors.missingNumbers'))
      .matches(
        /(?=.*[!@#$%^&*()])/,
        i18n.t('auth-pages:createBasicAccountPage.form.fields.password.errors.missingSpecialChars'),
      )
      .matches(
        /^[a-zA-Z0-9!@#$%^&*()]*$/,
        i18n.t('auth-pages:createBasicAccountPage.form.fields.password.errors.unsupportedCharacters'),
      ),
    firstName: Yup.string()
      .trim()
      .required(i18n.t('auth-pages:createBasicAccountPage.form.fields.firstName.errors.required')),
    middleName: Yup.string().trim(),
    lastName: Yup.string()
      .trim()
      .required(i18n.t('auth-pages:createBasicAccountPage.form.fields.lastName.errors.required')),
  });
