import { ProductType } from 'constants/hst-constants';

export enum ModuleType {
  ADAPTIVE = 'adaptive',
  WRITING = 'writing',
}

export interface ProductModules {
  id: number;
  productId: number;
  orderIndex: number;
  title: string;
  type: ModuleType;
  description?: string;
}

export interface ProductSkillLevel {
  id: number;
  level: number;
  label: string;
  selectable: boolean;
  description: string | null;
}

export interface ProductDetails {
  id: number;
  title: string;
  type: ProductType;
  modules: ProductModules[];
  levels: ProductSkillLevel[];
}

export declare type Level = 'default' | 'basics' | 'initial' | 'improvement' | 'good' | 'excellent' | 'custom';
