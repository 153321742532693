import styled from 'styled-components/macro';

import { calculateRem } from 'styles/mixins/mixins';
import { ThemeProps as Props } from 'models/styles/theme';

import welcomeBoxBgImg from 'assets/images/hogeschool-bubbles.png';

export const StyledContainer = styled.main<Props>`
  padding: 2rem 4rem 5rem 4rem;
`;

export const StyledWelcomeBox = styled.section<Props>`
  padding: 5rem 4rem;
  display: flex;
  align-items: center;
  column-gap: 3rem;

  background-color: ${(props: Props) => props.theme.palette.secondary._200};
  background-image: url(${welcomeBoxBgImg});
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: contain;
`;

export const StyledMessage = styled.article<Props>`
  color: ${(props: Props) => props.theme.palette.neutral.black};

  p {
    font-size: ${calculateRem(18)};
    line-height: 180%;
    white-space: pre-line;
  }

  h1 {
    font-size: 4rem;
    line-height: 125%;
    word-break: normal;
  }
`;

export const StyledPreparationBox = styled.section<Props>`
  display: flex;
  column-gap: 5rem;
  margin-top: 4.75rem;
  align-items: flex-start;
`;

export const StyledCollage = styled.img<Props>`
  max-width: 50%;
`;

export const StyledTipContainer = styled.section<Props>`
  > h3 {
    margin-top: 2rem;
    font-size: 1.125rem;
  }
`;
