/* eslint-disable @typescript-eslint/no-use-before-define */
import i18n from 'i18n';
import { action, observable } from 'mobx';

import { profileRepository } from 'repositories';
import {
  RequestAccessRequest,
  RequestAccessResponse,
  ProvideAccessRequest,
  ProvideAccessResponse,
  School,
  SchoolType,
} from 'models/auth/Login';
import { ToastMethods } from 'components/ToastNotification';

interface ErrorResponse {
  status: number;
  message: string;
  type: string;
}

interface LoginInterface {
  loading: boolean;
  userCode: string;
  accessCode: string;
  updateUserInfoLoginSucceed: boolean;
  schoolList: School[];
  requestAccess: (payload: RequestAccessRequest) => void;
  provideAccess: (payload: ProvideAccessRequest) => void;
  getSchoolList: (schoolType?: SchoolType) => void;
  setUserCode: (userCode: string) => void;
  setAccessCode: (accessCode: string) => void;
  setLoading: (loading: boolean) => void;
  setSchoolList: (schools: School[]) => void;
  setUpdateUserInfoLoginSucceed: (succeed: boolean) => void;
  updateUserInfoLogin: (educationId: string, studentId: string) => void;
}
const initialState = {
  userCode: '',
  accessCode: '',
  loading: false,
};

const stateSetters = {
  setUserCode: action((userCode: string) => {
    store.userCode = userCode;
  }),
  setAccessCode: action((accessCode: string) => {
    store.accessCode = accessCode;
  }),
  setLoading: action((loading: boolean) => {
    store.loading = loading;
  }),
  setSchoolList: action((schools: School[]) => {
    store.schoolList = schools;
  }),
  setUpdateUserInfoLoginSucceed: action((succeed: boolean) => {
    store.updateUserInfoLoginSucceed = succeed;
  }),
};

const apiRequests = {
  requestAccess: action((accessPayload: RequestAccessRequest) => {
    profileRepository
      .requestAccess(accessPayload)
      .then((response: RequestAccessResponse) => {
        if (response.accountValidationCode) {
          store.setAccessCode(response.accountValidationCode);
        }
        if (response.accountExists && response.code) {
          store.setUserCode(response.code);
        }
      })
      .catch(() => {
        ToastMethods.showToast(i18n.t('toast:migrateAccess.error'), 'error');
      });
  }),
  provideAccess: action((accessPayload: ProvideAccessRequest) => {
    profileRepository
      .provideAccess(accessPayload)
      .then((response: ProvideAccessResponse) => {
        store.setUserCode(response.code);
      })
      .catch(() => {
        ToastMethods.showToast(i18n.t('toast:migrateAccess.error'), 'error');
      });
  }),
  getSchoolList: action((schoolType?: SchoolType) => {
    store.setLoading(true);
    profileRepository
      .getSchoolList(schoolType)
      .then((schools: School[]) => {
        store.setSchoolList(schools);
      })
      .catch(() => {
        ToastMethods.showToast(i18n.t('toast:profile.getschoolList.error'), 'error');
      })
      .finally(() => {
        store.setLoading(false);
      });
  }),
  updateUserInfoLogin: action((educationId: string, studentId: string) => {
    profileRepository
      .updateUserEducation(educationId, studentId)
      .then(() => {
        store.setUpdateUserInfoLoginSucceed(true);
      })
      .catch((error: ErrorResponse) => {
        store.setUpdateUserInfoLoginSucceed(false);
        if (error.status === 409) {
          ToastMethods.showToast(i18n.t('toast:profile.userAlreadyRegistered.error'), 'error');
        } else {
          ToastMethods.showToast(i18n.t('toast:profile.updateUserInfoLogin.error'), 'error');
        }
      });
  }),
};

const store = observable({
  ...initialState,
  ...stateSetters,
  ...apiRequests,
} as LoginInterface);

export const useLogin = (): LoginInterface => store;
