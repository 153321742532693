import { Language } from '../models/language/Language';

export type AvailableLanguage = 'en' | 'nl' | 'nl-BE';

export const language: Language = {
  values: {
    en: 'en',
    nl: 'nl',
  },
  labels: {
    en: 'English',
    nl: 'Nederlands',
  },
  flags: {
    en: 'englandFlag',
    nl: 'netherlandsFlag',
  },
};
