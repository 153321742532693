/**
 *  This returns the start date of the current school year, assuming a school
 * year starts on September.
 *
 * @returns {Date} Start date of current school year
 */
export const getCurrentSchoolYearStart = (): Date => {
  const today = new Date();
  const currentMonth = today.getMonth() + 1;
  const currentYear = today.getFullYear();

  // If we haven't yet reached September of the on-going year, fetch September from last year
  const lastSeptember = new Date(`${currentMonth < 9 ? currentYear - 1 : currentYear}-09-01`);

  return lastSeptember;
};
