import styled from 'styled-components/macro';

import searchIcon from 'assets/icons/search.svg';
import { Input } from 'components/Input';
import { ThemeProps } from 'models/styles/theme';
import { calculateRem } from 'styles/mixins/calculateRem';
import { Select } from 'components/Select';

export const StyledUsersFilter = styled.div<ThemeProps>`
  display: flex;
  flex-direction: row;
  margin-top: ${calculateRem(16)};
`;

export const StyledField = styled.div<ThemeProps>`
  display: flex;
  flex-direction: column;
  margin-right: ${calculateRem(50)};
  width: ${calculateRem(360)};

  label {
    font-weight: ${({ theme }: ThemeProps) => theme.font.weight.bolder};
    margin-bottom: ${calculateRem(16)};
  }
`;

export const StyledInput = styled(Input)<ThemeProps>`
  background-color: ${({ theme }: ThemeProps) => theme.palette.neutral._200};
  background-image: url(${searchIcon});
  background-position: left;
  background-position-x: ${calculateRem(15)};
  background-repeat: no-repeat;
  background-size: ${calculateRem(25)};
  border-radius: ${calculateRem(8)};
  height: ${calculateRem(48)};
  padding-left: ${calculateRem(50)};
`;

export const StyledSelect = styled(Select)<ThemeProps>`
  height: ${calculateRem(48)};
  margin-left: auto;
  margin-top: ${calculateRem(27)};
  width: ${calculateRem(230)};
`;
