import styled from 'styled-components/macro';
import { ThemeProps as Props } from 'models/styles/theme';

import { Heading3 } from 'styles/elements/Headings';
import { Link } from 'components/Link';
import { calculateRem } from 'styles/mixins/mixins';

export const StyledText = styled(Heading3)<Props>`
  text-align: center;
  font-weight: ${({ theme }: Props) => theme.font.weight.normal};
  line-height: ${calculateRem(31.68)};
  max-width: ${calculateRem(584)};
`;

export const StyledLink = styled(Link)<Props>`
  color: ${({ theme }: Props) => theme.palette.neutral.white};
`;
