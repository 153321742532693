import styled from 'styled-components/macro';

import { CssFlex } from 'styles/helpers/layout';
import { ThemeProps } from 'models/styles/theme';

interface StyledContentProps extends ThemeProps {
  $withMenu?: boolean;
}

export const StyledContent = styled(CssFlex)<StyledContentProps>`
  ${(props: StyledContentProps) =>
    props.$withMenu != true &&
    `
      width: 85%;
      max-width: 71rem;
      justify-self: center;
      margin: 0 3rem;
    `}

  > aside {
    > nav {
      padding-left: 0;
    }
  }

  > main {
    flex-grow: 1;
  }
`;
