import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import format from 'date-fns/format';

import { modalIds } from 'constants/modals-constants';
import { useModal } from 'hooks/useModal';
import { AdminProductItem } from 'models/product/ProductItem';

import { IconButton } from 'components/IconButton/IconButton';
import { StyledProductDescription, StyledProductItem } from './ProductItem.styled';

interface ProductItemProps {
  product: AdminProductItem & { title: string };
  onDeleteProduct: (productId: number) => void;
}

export const ProductItem: React.FC<ProductItemProps> = ({ product, onDeleteProduct }) => {
  const { t } = useTranslation('admin-environment');
  const { toggleModal } = useModal();

  const licenseStartDate = product.validSinceDate && new Date(`${product.validSinceDate}`);
  const licenseExpirationDate = product.validUntilDate && new Date(`${product.validUntilDate}`);

  return (
    <StyledProductItem>
      <IconButton
        dataCy={`delete-product-${product.id}`}
        iconName="trashIcon"
        iconSize={1.5}
        onClick={event => {
          event.preventDefault();
          onDeleteProduct(product.id);
        }}
      />
      <StyledProductDescription>
        <span>{product.title}</span>
        <span>
          {licenseExpirationDate ? (
            <>
              {licenseStartDate && licenseExpirationDate ? (
                <Trans i18nKey="accountDetails.product.validDates" t={t}>
                  {{ validSinceDate: format(licenseStartDate, 'dd-MM-Y') }}
                  {' | '}
                  {{ validSinceHour: format(licenseStartDate, 'hh:mm') }}
                  {' | valid till '}
                  {{ validUntilDate: format(licenseExpirationDate, 'dd-MM-Y') }}
                </Trans>
              ) : (
                <Trans i18nKey="accountDetails.product.withoutStartDate" t={t}>
                  {'Valid till '}
                  {{ validUntilDate: format(licenseExpirationDate, 'dd-MM-Y') }}
                </Trans>
              )}
              <IconButton
                dataCy={`update-product-${product.id}`}
                iconName="pencilIcon"
                iconSize={1}
                onClick={event => {
                  event.preventDefault();
                  toggleModal(modalIds.updateAccountProductModal, {
                    id: product.id,
                    title: product.title,
                    type: product.type || '',
                    validSinceDate: licenseStartDate ? `${licenseStartDate}` : null,
                    validUntilDate: licenseExpirationDate ? `${licenseExpirationDate}` : null,
                  });
                }}
              />
            </>
          ) : (
            t('accountDetails.product.withoutExpirationDate', 'This is a permanent license')
          )}
        </span>
      </StyledProductDescription>
    </StyledProductItem>
  );
};
