import styled from 'styled-components/macro';
import { ThemeProps as Props } from 'models/styles/theme';

export const StyledGridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 1rem;
`;

export const StyledSettingsContainer = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;
  margin-top: 3.5rem;
`;

export const StyledExtraOptionsList = styled.ul`
  padding: 0;
  list-style-type: none;

  li {
    display: flex;
    column-gap: 1rem;
    line-height: 3.5rem;
    align-items: center;

    b {
      font-weight: normal;
      color: ${(props: Props) => props.theme.palette.neutral._900};
    }
  }
`;

export const StyledProctoringList = styled.ul`
  padding: 0;
  list-style-type: none;

  li {
    line-height: 2rem;
  }
`;
