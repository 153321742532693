import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import {
  SignInPage,
  LoginValidatePage,
  SchoolDataRequestPage,
  ProductsOwnedPage,
  SkillDeterminationTestPage,
  AdaptiveTestPage,
  MigrateAccessPage,
  DiagnosticTestExamPage,
  WritingExercisePage,
  StudentDashboard,
  TestPage,
  LandingPage,
  TeacherDashboard,
  TeacherMonitor,
  ResultsPage,
  AdminPage,
  RegistrationPage,
  InvitationPage,
  CreateBasicAccountPage,
} from 'pages';
import { ScheduledTestResultsDetails } from 'pages/TeacherEnvironment/TestResultsReport/TestResultsReport';
import { AccountCreatedPage } from 'pages/Auth/AccountCreatedPage/AccountCreatedPage';
import { ROLES } from '@he-learning/service-he-common';
import cookieStorage, { ROLE } from 'persistence';

import { AuthRoute } from 'layouts/AuthRoute';
import { RoleComponent } from 'layouts/RoleComponent';

import { useFeatureFlags } from 'hooks/useFeatureFlags';

import {
  AUTH_PAGES_ROUTES,
  PRODUCT_PAGES_ROUTES,
  GENERIC_ROUTES,
  TEST_PAGE_ROUTES,
  TEACHER_HOME_PAGE_ROUTES,
  TEST_ENVIRONMENT_ROUTES,
  ADMIN_ENVIRONMENT_ROUTES,
  LEGACY_ROUTES,
  TEACHER_SCHEDULED_TEST_PAGE_ROUTES,
} from 'constants/routes';
import { RootPath } from './RootPath';
import { UnderConstructionMessage } from 'components/UnderConstructionMessage/UnderConstructionMessage';
import { RegistrationSuccessfulPage } from 'pages/Auth/RegistrationSuccessfulPage/RegistrationSuccessfulPage';
import { AccessDeniedPage } from 'pages/Auth/AccessDeniedPage/AccessDeniedPage';
import { AdaptivePracticeSessionResultsPage } from 'pages/StudentEnvironment/StudentDashboard/subpages';

const isUserLoggedIn = !!cookieStorage.getToken();

const AppRouter: () => React.ReactElement = () => {
  const { enableMigrateLegacyAccount, showAdaptivePracticeFlow } = useFeatureFlags();

  return (
    <Switch>
      <Route exact component={() => <h1>NOT FOUND ROUTE</h1>} path={GENERIC_ROUTES.NOT_FOUND} />

      {/* --- Authentication pages --- */}
      <AuthRoute exact publicRoute component={SignInPage} path={AUTH_PAGES_ROUTES.LOG_IN} />
      {enableMigrateLegacyAccount && (
        <AuthRoute exact publicRoute component={MigrateAccessPage} path={AUTH_PAGES_ROUTES.MIGRATE_ACCESS} />
      )}
      <AuthRoute exact publicRoute component={LoginValidatePage} path={AUTH_PAGES_ROUTES.VALIDATE} />
      <AuthRoute
        component={SchoolDataRequestPage}
        path={AUTH_PAGES_ROUTES.USER_MISSING_INFO}
        redirectPath={AUTH_PAGES_ROUTES.LOG_IN}
      />
      <AuthRoute exact publicRoute component={RegistrationPage} path={AUTH_PAGES_ROUTES.REGISTER_ACCOUNT} />
      <AuthRoute
        exact
        publicRoute
        component={RegistrationSuccessfulPage}
        path={AUTH_PAGES_ROUTES.REGISTER_ACCOUNT_FINISHED}
      />
      <AuthRoute exact publicRoute component={CreateBasicAccountPage} path={AUTH_PAGES_ROUTES.CREATE_BASIC_ACCOUNT} />
      <AuthRoute publicRoute component={AccountCreatedPage} path={AUTH_PAGES_ROUTES.REGISTERED_INVITATION} />
      <AuthRoute exact publicRoute component={InvitationPage} path={AUTH_PAGES_ROUTES.INVITATION} />
      <AuthRoute exact publicRoute component={AccessDeniedPage} path={AUTH_PAGES_ROUTES.ACCESS_DENIED} />

      {/* --- Root path --- */}
      <AuthRoute exact component={RootPath} path={GENERIC_ROUTES.HOME_PAGE} redirectPath={AUTH_PAGES_ROUTES.LOG_IN} />

      {/* --- Student pages --- */}
      <AuthRoute
        exact
        component={ProductsOwnedPage}
        path={PRODUCT_PAGES_ROUTES.PRODUCT_LIST}
        redirectPath={AUTH_PAGES_ROUTES.LOG_IN}
      />
      <AuthRoute
        exact
        component={showAdaptivePracticeFlow ? AdaptiveTestPage : UnderConstructionMessage}
        path={TEST_PAGE_ROUTES.ADAPTIVE_PRACTICE_TEST}
      />
      <AuthRoute
        exact
        component={AdaptivePracticeSessionResultsPage}
        path={TEST_PAGE_ROUTES.ADAPTIVE_PRACTICE_SESSION_RESULTS}
      />
      <AuthRoute
        exact
        component={showAdaptivePracticeFlow ? SkillDeterminationTestPage : UnderConstructionMessage}
        path={TEST_PAGE_ROUTES.SKILL_DETERMINATION_TEST}
      />
      <AuthRoute
        exact
        component={DiagnosticTestExamPage}
        path={TEST_PAGE_ROUTES.DIAGNOSTIC_TEST_EXAM}
        redirectPath={AUTH_PAGES_ROUTES.LOG_IN}
      />
      <AuthRoute
        exact
        component={WritingExercisePage}
        path={TEST_PAGE_ROUTES.WRITING_EXERCISE}
        redirectPath={AUTH_PAGES_ROUTES.LOG_IN}
      />
      <AuthRoute
        component={StudentDashboard}
        path={PRODUCT_PAGES_ROUTES.PRODUCT_DETAILS}
        redirectPath={AUTH_PAGES_ROUTES.LOG_IN}
      />
      <AuthRoute
        component={() => <Redirect to={PRODUCT_PAGES_ROUTES.PRODUCT_LIST} />}
        path={LEGACY_ROUTES.STUDENT_DASHBOARD}
        redirectPath={AUTH_PAGES_ROUTES.LOG_IN}
      />

      {/* --- Teacher Pages --- */}
      <AuthRoute
        component={() => (
          <RoleComponent
            allowedRoles={[ROLES.TEACHER as ROLE]}
            fallbackContent={<Redirect to={GENERIC_ROUTES.HOME_PAGE} />}
          >
            <TeacherMonitor />
          </RoleComponent>
        )}
        path={TEACHER_HOME_PAGE_ROUTES.MONITOR_PAGE}
        redirectPath={AUTH_PAGES_ROUTES.LOG_IN}
      />
      <AuthRoute
        exact
        component={() => (
          <RoleComponent
            allowedRoles={[ROLES.TEACHER as ROLE]}
            fallbackContent={<Redirect to={GENERIC_ROUTES.HOME_PAGE} />}
          >
            <ScheduledTestResultsDetails />
          </RoleComponent>
        )}
        path={TEACHER_SCHEDULED_TEST_PAGE_ROUTES.SCHEDULED_TEST_RESULTS}
        redirectPath={AUTH_PAGES_ROUTES.LOG_IN}
      />
      <AuthRoute
        component={() => (
          <RoleComponent
            allowedRoles={[ROLES.TEACHER as ROLE]}
            fallbackContent={<Redirect to={GENERIC_ROUTES.HOME_PAGE} />}
          >
            <TeacherDashboard />
          </RoleComponent>
        )}
        path={TEACHER_HOME_PAGE_ROUTES.HOME_PAGE}
        redirectPath={AUTH_PAGES_ROUTES.LOG_IN}
      />
      <AuthRoute
        component={() => <Redirect to={TEACHER_HOME_PAGE_ROUTES.HOME_PAGE} />}
        path={LEGACY_ROUTES.TEACHER_DASHBOARD}
        redirectPath={AUTH_PAGES_ROUTES.LOG_IN}
      />

      {/* --- Test environment pages --- */}
      <AuthRoute
        exact
        component={LandingPage}
        path={TEST_ENVIRONMENT_ROUTES.LANDING_PAGE}
        redirectPath={AUTH_PAGES_ROUTES.LOG_IN}
      />
      <AuthRoute
        component={ResultsPage}
        path={TEST_ENVIRONMENT_ROUTES.TEST_RESULTS_PATH}
        redirectPath={AUTH_PAGES_ROUTES.LOG_IN}
      />
      <AuthRoute
        component={TestPage}
        path={TEST_ENVIRONMENT_ROUTES.TEST_DETAILS_PAGE}
        redirectPath={AUTH_PAGES_ROUTES.LOG_IN}
      />

      {/* --- Admin Pages --- */}
      <AuthRoute
        component={() => (
          <RoleComponent
            allowedRoles={[ROLES.ADMIN as ROLE]}
            fallbackContent={<Redirect to={GENERIC_ROUTES.HOME_PAGE} />}
          >
            <AdminPage />
          </RoleComponent>
        )}
        path={ADMIN_ENVIRONMENT_ROUTES.USERS_PAGE}
        redirectPath={AUTH_PAGES_ROUTES.LOG_IN}
      />

      <AuthRoute
        component={() => (
          <RoleComponent
            allowedRoles={[ROLES.ADMIN as ROLE]}
            fallbackContent={<Redirect to={GENERIC_ROUTES.HOME_PAGE} />}
          >
            <AdminPage />
          </RoleComponent>
        )}
        path={ADMIN_ENVIRONMENT_ROUTES.USER_SCHEDULED_TESTS}
        redirectPath={AUTH_PAGES_ROUTES.LOG_IN}
      />

      <AuthRoute
        component={() => (
          <RoleComponent
            allowedRoles={[ROLES.ADMIN as ROLE]}
            fallbackContent={<Redirect to={GENERIC_ROUTES.HOME_PAGE} />}
          >
            <AdminPage />
          </RoleComponent>
        )}
        path={ADMIN_ENVIRONMENT_ROUTES.USER_TOOLS}
        redirectPath={AUTH_PAGES_ROUTES.LOG_IN}
      />

      {/* --- Default path --- */}
      <Route
        render={({ location }) => {
          if (isUserLoggedIn) {
            return <Redirect to={GENERIC_ROUTES.NOT_FOUND} />;
          }
          let options;
          const path = AUTH_PAGES_ROUTES.LOG_IN;
          if (location && location.search) {
            options = { pathname: path, search: location.search };
          } else {
            options = { pathname: path };
          }
          return <Redirect to={options} />;
        }}
      />
    </Switch>
  );
};

export default AppRouter;
