import styled from 'styled-components/macro';

import { ThemeProps as Props } from 'models/styles/theme';
import schoolIcon from 'assets/icons/school-icon.svg';
import infoFilledNotCircleIcon from 'assets/icons/info-filled-not-circle.svg';
import bulbIcon from 'assets/icons/bulb.svg';

export const StyledContent = styled.section<Props>`
  iframe {
    margin-bottom: 1rem;
    @media screen and (max-width: 700px) {
      width: 100%;
    }
  }

  img {
    margin: auto;
    display: block;
    max-width: 100%;
  }

  .icon {
    max-width: none;
  }

  .reference {
    font-size: 12px;
    font-style: italic;
  }

  .content1 {
    p {
      line-height: 1.7rem;
    }
    margin-bottom: 30px;
  }

  .content1,
  .content2,
  .content3,
  .content4,
  .content5 {
    font-size: 16px;
  }

  .content1 p,
  .content2 p,
  .content3 p,
  .content4 p,
  .content5 p,
  .content1 ul,
  .content2 ul,
  .content3 ul,
  .content4 ul,
  .content5 ul {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .content2,
  .content3,
  .content5 {
    padding: 16px 16px 16px 64px;
    border: 1px solid #ada689;
    margin-top: 30px;
    margin-bottom: 30px;
    border-radius: 4px;
    position: relative;
    p {
      line-height: 1.7rem;
    }
  }

  .content2::after,
  .content3::after,
  .content5::after {
    font-weight: 900;
    display: block;
    position: absolute;
    top: 16px;
    left: 64px;
    width: calc(100% - 100px);
    border-bottom: 1px solid #c9c9c9;
  }

  .content2::before,
  .content3::before,
  .content4::before,
  .content5::before {
    font-family: 'FontAwesome', serif;
    display: block;
    padding-right: 3px;
    padding-bottom: 6px;
    font-weight: 900;
    font-size: 22px;
    margin-left: -40px;
    margin-bottom: -40px;
  }

  .content2 {
    background: #fafcfd;
    border: 1px solid #a0afb6;
  }

  .content2::before {
    content: url(${infoFilledNotCircleIcon});
    padding-top: 1px;
  }

  .content3 {
    background: #fef7db;
    border: 1px solid #ada689;
  }

  .content3::before {
    content: url(${schoolIcon});
  }

  .content4 p,
  .content4 ul {
    padding-top: 0;
    margin-top: 0;
    line-height: 1.7rem;
  }

  .content5 {
    background: #f0f7fe;
    border: 1px solid #9fb1c2;
  }

  .content5::before {
    content: url(${bulbIcon});
  }

  // TABLE CSS
  .content1 table,
  .content2 table,
  .content3 table,
  .content4 table,
  .content5 table {
    border-collapse: collapse !important;
    overflow: hidden !important;
    border-radius: 4px !important;
    width: 100% !important;
  }
  .content1 thead,
  .content2 thead,
  .content3 thead,
  .content4 thead,
  .content5 thead {
    border: none !important;
    background-color: #f3f3f3 !important;
    border-bottom: 1px solid #9fb1c2 !important;
  }

  .content1 td,
  .content2 td,
  .content3 td,
  .content4 td,
  .content5 td {
    padding: 16px 8px !important;
    text-align: left !important;
  }

  .content1 tbody tr,
  .content2 tbody tr,
  .content3 tbody tr,
  .content4 tbody tr,
  .content5 tbody tr {
    border: none !important;
    line-height: 1.7rem;
    border-bottom: 1px solid black !important;
  }

  .content1 tbody tr:last-child,
  .content2 tbody tr:last-child,
  .content3 tbody tr:last-child,
  .content4 tbody tr:last-child,
  .content5 tbody tr:last-child {
    border-bottom: none !important;
  }

  .content1 td:first-child,
  .content2 td:first-child,
  .content3 td:first-child,
  .content4 td:first-child,
  .content5 td:first-child {
    padding-left: 32px !important;
  }

  .content1 td:last-child,
  .content2 td:last-child,
  .content3 td:last-child,
  .content4 td:last-child,
  .content5 td:last-child {
    padding-right: 32px !important;
  }
`;
