import styled, { css } from 'styled-components/macro';
import { CssGrid } from 'styles/helpers/layout';
import { ThemeProps as Props } from 'models/styles/theme';

interface PageLayoutProps extends Props {
  $hasSideMenu: boolean;
}

export const StyledPageLayout = styled(CssGrid)<PageLayoutProps>`
  gap: 1rem;
  min-height: 100%;
  padding: 1rem 4rem;
  grid-template-rows: auto 1fr auto;

  > main {
    grid-area: content;
  }

  > aside {
    grid-area: navbar;

    > nav {
      padding-left: 0;
    }
  }

  > header {
    grid-area: header;
    padding: 0;
  }

  > footer {
    grid-area: footer;
    padding: 2rem 0;
  }

  ${(props: PageLayoutProps) => {
    if (props.$hasSideMenu) {
      return css`
        grid-template-areas:
          'header header'
          'navbar content'
          'footer footer';
        grid-template-columns: auto 1fr;
      `;
    }

    return css`
      grid-template-areas:
        'header'
        'content'
        'footer';

      > main {
        margin: auto;
        max-width: 80rem;
        width: calc(100vw - 4rem);
      }
    `;
  }}
`;
