import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { ThemeProps } from 'models/styles/theme';
import { Heading3 } from 'styles/elements/Headings';

interface Props extends ThemeProps {
  $disabled: boolean;
}

export const StyledItemContainer = styled(Link)<Props>`
  width: 100%;
  display: flex;
  column-gap: 5rem;
  justify-content: space-between;
  align-items: center;
  border: ${(props: Props) => `1px solid ${props.theme.palette.neutral._400}`};
  padding: 2rem;
  text-decoration: none;
  color: ${(props: Props) => props.theme.palette.neutral.black};
  pointer-events: ${({ $disabled }) => ($disabled ? 'none' : 'inherit')};
`;

export const StyledTitle = styled(Heading3)`
  margin: 0;
  flex-grow: 1;
`;
