import styled from 'styled-components/macro';

import { calculateRem } from 'styles/mixins/mixins';
import { Heading3 } from 'styles/elements/Headings';

import { ThemeProps as Props } from 'models/styles/theme';
import { StyledSearchBox } from 'components/SearchBox/SearchBox.styled';

export const StyledPageContainer = styled.main<Props>`
  margin-bottom: 4rem;
  margin-left: ${calculateRem(32)};
`;

export const StyledContentContainer = styled.section<Props>`
  border: 1px solid ${(props: Props) => props.theme.palette.neutral._400};
  border-radius: 4px;

  ${Heading3} {
    font-weight: normal;
    margin: 2.5rem 2rem 2rem 2rem;
  }
`;

export const StyledFilterContainer = styled.section`
  display: flex;
  margin: 1.5rem;
  column-gap: 1rem;
  align-items: center;

  ${StyledSearchBox} {
    flex-grow: 1;
  }
`;
