import styled from 'styled-components/macro';
import { ThemeProps as Props } from 'models/styles/theme';
import { Heading3 } from 'styles/elements/Headings';

export interface InviteOptionProps extends Props {
  isSelected?: boolean;
}

export const StyledWrapper = styled.div<Props>`
  margin-top: 2rem;
  margin-left: 1rem;
`;

export const StyledInnerWrapper = styled.div<Props>`
  margin-top: 2rem;
  width: 90%;
`;

export const StyledHeading3 = styled(Heading3)<Props>`
  font-size: 1.4em;
  font-weight: 400;
`;

export const StyledHr = styled.hr<Props>`
  margin-top: 3rem;
  width: 100%;
`;
