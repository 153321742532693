import { CorrectTextQuestionWordState, WordAction } from 'models/exam/CorrectTextQuestion';
import React, { useMemo } from 'react';
import classnames from 'classnames';
import { CorrectTextQuestionWordProps } from './CorrectTextQuestionWord.model';
import { StyledCorrectTextQuestionWord } from './CorrectTextQuestionWord.styled';

const wordStateToClassNames: Map<CorrectTextQuestionWordState, string[]> = new Map([
  [CorrectTextQuestionWordState.ACTIVE, ['is-active']],
  [CorrectTextQuestionWordState.CHANGED, ['is-changed']],
  [CorrectTextQuestionWordState.ANSWER_CORRECT, ['is-correct']],
  [CorrectTextQuestionWordState.ANSWER_INCORRECT, ['is-incorrect']],
  [CorrectTextQuestionWordState.ANSWER_UNNECESSARY, ['is-incorrect']],
  [CorrectTextQuestionWordState.ANSWER_MISSED, ['is-missed']],
  [CorrectTextQuestionWordState.ANSWER_MISSED_DELETE, ['is-missed', 'is-removed']],
]);

const wordActionToClassNames: Map<WordAction, string[]> = new Map([
  [WordAction.EDIT_WORD, ['is-edited']],
  [WordAction.ADD_WORD_AFTER, ['is-add-after']],
  [WordAction.MERGE_WORDS, ['is-merged']],
  [WordAction.SWAP_WORDS, ['is-swapped']],
  [WordAction.REMOVE_WORD, ['is-removed']],
]);

export const CorrectTextQuestionWord: React.FC<CorrectTextQuestionWordProps> = ({
  children,
  state,
  wordAction,
  ...props
}) => {
  const classNames = useMemo<readonly string[]>(() => {
    let classNameList: readonly string[] = [];
    classNameList = classNameList.concat(wordStateToClassNames.get(state) ?? []);
    classNameList = classNameList.concat(wordActionToClassNames.get(wordAction) ?? []);
    return classNameList;
  }, [state, wordAction]);

  return (
    <StyledCorrectTextQuestionWord
      className={classnames('CorrectTextQuestionWord', classNames)}
      data-cy="correct-text-question-word"
      type="button"
      {...props}
    >
      {children}
    </StyledCorrectTextQuestionWord>
  );
};
