import React from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { InputWithErrorMessage } from 'components/InputWithErrorMessage';
import { Button } from 'components/Button';
import { useGroups } from 'hooks/useGroups';
import { useDomainHandler } from 'hooks/useDomainHandler';
import { Breadcrumbs } from 'components/Breadcrumbs';
import { TEACHER_HOME_PAGE_ROUTES } from 'constants/routes';
import { Heading2 } from 'styles/elements/Headings/index';
import { buildUrlWithPathParams } from '_helpers/utils/urlBuilder';
import { FormGroupName, FormWrapper, FormGroupNameLine } from './components/Form.styled';

export const NewGroup: React.FC = observer(() => {
  const { groupName, changeGroupName, createGroup, savingGroup, resetGroupName } = useGroups();
  const { t } = useTranslation('groups');
  const { getTeacherTermByDomain } = useDomainHandler();

  const history = useHistory();

  const onFormSubmit = async (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    const newGroupId = await createGroup();
    if (newGroupId) {
      history.replace(buildUrlWithPathParams(TEACHER_HOME_PAGE_ROUTES.EDIT_GROUP, { groupId: newGroupId }));
    }
  };
  React.useEffect(() => {
    resetGroupName();
  }, []);

  return (
    <>
      <Breadcrumbs
        dataCy="new-group-page"
        excludeRoutes={['/', TEACHER_HOME_PAGE_ROUTES.NEW_GROUP]}
        routes={[
          {
            path: TEACHER_HOME_PAGE_ROUTES.HOME_PAGE,
            breadcrumb: t('breadcrumbs.teacher', { teacherTerm: getTeacherTermByDomain() }),
          },
          { path: TEACHER_HOME_PAGE_ROUTES.GROUPS, breadcrumb: t('breadcrumbs.groups') },
        ]}
      />
      <Heading2>{t('newGroup')}</Heading2>
      <FormWrapper>
        <FormGroupName onSubmit={onFormSubmit}>
          <label htmlFor="group-name">{t('groupName')}</label>
          <FormGroupNameLine>
            <InputWithErrorMessage
              autoFocus
              dataCy="group-name"
              hasError={false}
              id="group-name"
              name="group-name"
              value={groupName}
              onChange={changeGroupName}
            />
            <Button
              data-cy="create-group-submit-btn"
              disabled={groupName.trim() === '' || savingGroup}
              isLoading={savingGroup}
              type="submit"
              variant="primary"
            >
              {t('createGroup')}
            </Button>
          </FormGroupNameLine>
        </FormGroupName>
      </FormWrapper>
    </>
  );
});
