import styled from 'styled-components/macro';
import { ThemeProps as Props } from 'models/styles/theme';
import { FontAwesomeIcon } from 'components/FontAwesomeIcon';
import { SortableTable } from 'components/SortableTable/SortableTable';
import { StyledHeaderCell, StyledCell } from 'components/SortableTable/SortableTable.styled';

export const StyledTable = styled(SortableTable)<Props>`
  ${StyledHeaderCell} {
    align-items: flex-start;
    justify-content: center;
    padding: 1rem 1.5rem;
  }

  ${StyledCell} {
    padding: 0.75rem 1.5rem;
  }
`;

export const StyledIcon = styled(FontAwesomeIcon)<Props>`
  color: ${(props: Props) => props.theme.palette.neutral.black};
`;

export const StyledScoreContainer = styled.div<Props>`
  display: flex;
  align-items: center;
  column-gap: 1rem;
`;

export const StyledLabelWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
`;

export const StyledExtraTimeLabel = styled.span<Props>`
  display: block;
  margin-left: 0.25rem;
  padding: 0.25rem 0.5rem;
  border-radius: 5rem;
  font-size: 0.75rem;
  text-align: center;
  background: ${(props: Props) => props.theme.palette.neutral._400};
`;

export const StyledIsInvalidLabel = styled.span<Props>`
  display: block;
  margin-left: 0.25rem;
  padding: 0.25rem 0.5rem;
  border-radius: 5rem;
  font-size: 0.75rem;
  text-align: center;
  color: ${(props: Props) => props.theme.palette.feedback.error.brand};
  background: ${(props: Props) => props.theme.palette.feedback.error._200};
`;
