import React from 'react';

import { IconButton } from 'components/IconButton/IconButton';
import { Heading3 } from 'styles/elements/Headings';
import { Modal } from 'components/Modal';
import { StyledSidebar, StyledTitle } from './SideModal.styled';
import { SideModalProps } from './SideModal.model';

export const SideModal: React.FC<SideModalProps> = ({
  children,
  dataCy,
  title,
  onClose,
  modalAnimation = 'slideRight',
  modalPosition = ['center', 'right'],
  ...modalProps
}) => (
  <Modal
    {...modalProps}
    dataCy={dataCy}
    modalAnimation={modalAnimation}
    modalPosition={modalPosition}
    showCloseButton={false}
    onClose={onClose}
  >
    <StyledSidebar>
      <StyledTitle data-cy={dataCy ? `${dataCy}-title` : 'side-modal-title'}>
        <IconButton
          dataCy={dataCy ? `${dataCy}-close-button` : 'side-modal-close-button'}
          iconName="arrowBackward"
          isCircle={false}
          onClick={onClose}
        />
        {title && <Heading3>{title}</Heading3>}
      </StyledTitle>
      {children}
    </StyledSidebar>
  </Modal>
);
