import { Button } from 'components/Button';
import styled from 'styled-components/macro';
import { ElearningThemeInterface } from 'themes/elearningInterface.theme';

interface Props {
  theme: ElearningThemeInterface;
}

interface FormProgressDashProps extends Props {
  $isActive: boolean;
}

export const StyledSteppedForm = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto;
`;

export const FormProgressDash = styled.div<FormProgressDashProps>`
  width: 40px;
  height: 5px;
  background-color: ${({ $isActive, theme }: FormProgressDashProps) =>
    $isActive ? theme.palette.accent._05 : theme.palette.neutral._400};
  border-radius: 2.5px;
  margin: 1rem 0;
`;

export const FormProgressDashContainer = styled.div`
  display: flex;
  ${FormProgressDash} {
    margin-right: 8px;
  }
`;

export const FormDivider = styled.div`
  height: 1px;
  background-color: #acacac;
  width: 100%;
  margin: 20px 0;
`;

export const FormButtonContainer = styled.section`
  display: flex;
  colum-gap: 1rem;
  align-items: center;
  justify-content: space-between;
`;

export const FormActionButton = styled(Button)`
  min-width: 16rem;
`;
