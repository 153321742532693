import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { modalIds } from 'constants/modals-constants';
import { useModal } from 'hooks/useModal';
import { useScheduledTests } from 'hooks/useScheduledTests';
import { Button } from 'components/Button';
import { InfoStatus } from 'components/InfoStatus';
import { ExamRule } from 'constants/exam-constants';
import { useFailingExamRules } from 'hooks/useExamRules';
import { buildUrlWithPathParams } from '_helpers/utils/urlBuilder';
import { TEST_ENVIRONMENT_ROUTES } from 'constants/routes';
import {
  StyledButtonContainer,
  StyledTestOverview,
  StyledTestOverviewListItem,
  StyledTestOverviewParagraph,
} from './TestOverview.styled';
import { FinishTestModal } from '../FinishTestModal/FinishTestModal';
import { TestOverviewProps } from './TestOverview.model';
import { ExamRuleFailingModal } from '../ExamRuleFailingModal/ExamRuleFailingModal';
import { ContentAPI } from 'generated/types';

export const TestOverview: React.FC<TestOverviewProps> = observer(({ code, onCloseTest: onCloseTestConfirm }) => {
  const { t } = useTranslation('test-environment');
  const { toggleModal } = useModal();
  const { setVisitedExam, scheduledTestContent, scheduledTestStatus } = useScheduledTests();
  const failingRules = useFailingExamRules(Object.values(ExamRule));

  useEffect(() => {
    setVisitedExam(true);
  }, []);
  const onCloseTestButtonClick = () => {
    toggleModal(modalIds.finishTest);
  };

  const getExerciseStatus = (exercise: ContentAPI.ExerciseDetailsSummary) => {
    const exerciseStatus = scheduledTestStatus?.find(s => s.id === exercise.id);
    return exerciseStatus?.status || 'notStarted';
  };

  const getExerciseUrl = (exercise: ContentAPI.ExerciseDetailsSummary) => {
    if (getExerciseStatus(exercise) === 'completed' && scheduledTestContent?.editableAnswers) {
      return buildUrlWithPathParams(TEST_ENVIRONMENT_ROUTES.TEST_EXERCISE_REVIEW_PAGE, {
        code,
        productId: scheduledTestContent?.productId,
        exerciseId: exercise.id,
      });
    }
    // the normal exercise page will just show the message 'you have finished this exercise'
    return buildUrlWithPathParams(TEST_ENVIRONMENT_ROUTES.TEST_EXERCISE_PAGE, {
      code,
      productId: scheduledTestContent?.productId as number,
      exerciseId: exercise.id,
    });
  };

  return (
    <StyledTestOverview>
      <Trans i18nKey="subjects.description" t={t}>
        <StyledTestOverviewParagraph>
          Choose any subject you’d like to start with. When you’ve completed it, you can check your answers by clicking
          on it again. Of course, you can also move on to the next topic rightaway.
        </StyledTestOverviewParagraph>
        <StyledTestOverviewParagraph>
          {'When you’ve finished everything, click the '}
          <b>&quot;Save and close&quot;</b>
          {' button.'}
        </StyledTestOverviewParagraph>
      </Trans>
      {scheduledTestContent?.exercises.map(exercise => (
        <StyledTestOverviewListItem
          key={exercise.id}
          dataCy={`exercise-list-item-${exercise.id}`}
          extraData={
            <InfoStatus dataCy={`exercise-list-item-${exercise.id}-status`} status={getExerciseStatus(exercise)} />
          }
          href={getExerciseUrl(exercise)}
          title={exercise.title}
        />
      ))}
      <StyledButtonContainer>
        <Button dataCy="test-overview-close-test-button" variant="primary" onClick={onCloseTestButtonClick}>
          {t('testOverview.closeTest.button', 'Save and close the test')}
        </Button>
      </StyledButtonContainer>
      <FinishTestModal onConfirm={onCloseTestConfirm} />
      <ExamRuleFailingModal failingRules={failingRules} />
    </StyledTestOverview>
  );
});
