import styled from 'styled-components/macro';

import { ThemeProps as Props } from 'models/styles/theme';

import { Modal } from 'components/Modal';
import { Heading4 } from 'styles/elements/Headings';

export const StyledModal = styled(Modal)<Props>`
  font-size: initial;
  font-weight: initial;

  > div {
    padding: 3.5rem 3rem 2.5rem 3rem;
  }

  ${Heading4} {
    line-height: 1.5rem;
    color: ${({ theme }: Props) => theme.palette.neutral._900};
  }

  label {
    padding: 0;
  }
`;
