import { ResultsAPI } from 'generated/types';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ProgressStage } from './StudentListSection.styled';
import { StyledFontAwesomeIcon, StyledHr, StyledTitle, StyledWrapper } from './StudentListSection.styled';

export interface StudentListSectionProps {
  stage: ProgressStage;
  data: ResultsAPI.WritingSubjectStudent[];
}

export const StudentListSection: FC<StudentListSectionProps> = ({ data, stage }) => {
  const { t } = useTranslation('teacher-dashboard');

  const getTitle = () => {
    switch (stage) {
      case 'in-progress':
        return t('writingResults.progress.inProgress');
      case 'ready':
        return t('writingResults.progress.ready');
      default:
        return t('writingResults.progress.didNotStart');
    }
  };

  return (
    <StyledWrapper data-cy={`${stage}-student-section`}>
      <StyledTitle>{getTitle()}</StyledTitle>
      <StyledHr />
      {data.map(({ id, firstName, lastName, middleName }) => (
        <div key={id} data-cy="student-list-item">
          <StyledFontAwesomeIcon iconName="fas fa-circle" stage={stage} />
          <span data-cy={`full-name-${id}`}>{`${firstName} ${middleName} ${lastName}`}</span>
        </div>
      ))}
    </StyledWrapper>
  );
};
