import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useOnClickOutside } from 'hooks/useOnClickOutside';

import { SelectOptions, SelectProps } from './Select.model';
import {
  StyledSelectContainer,
  StyledSelect,
  StyledOptionList,
  StyledOptionListItem,
  StyledErrorMessage,
  StyledSearchBox,
} from './Select.styled';
import { CssIcon } from 'styles/helpers/layout';

export const Select: React.FC<SelectProps> = ({
  id,
  value: selectedValue,
  options,
  dataCy,
  disabled,
  className,
  placeholder,
  hasError,
  errorMessage,
  showPlaceholderInOptionList = true,
  showSearchBox = false,
  ...selectProps
}) => {
  const { t } = useTranslation('common');
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const selectRef = useRef<HTMLSelectElement>(null);
  const fakeSelectRef = useRef<HTMLDivElement>(null);

  // Automatically hide select options if user clickes outside of the container
  useOnClickOutside({
    ref: fakeSelectRef,
    handler: () => setIsOpen(false),
  });

  // Clear searchbox when dropdown list is closed
  useEffect(() => {
    if (!isOpen) {
      setSearchTerm('');
    }
  }, [isOpen]);

  // Update real select value when an item is selected on the visible list
  const selectOption = (value: string | number) => {
    const currentSelectRef = selectRef.current;
    if (currentSelectRef) {
      setIsOpen(false);
      currentSelectRef.value = value.toString();
      currentSelectRef.dispatchEvent(new Event('change', { bubbles: true }));
    }
  };

  // Always cast option ids to string before comparing
  const selectedOption = options.find(
    (option: SelectOptions) => !!selectedValue && option.value.toString() === selectedValue.toString(),
  );

  // Optionally exclude some of the options if searchbox is enabled and has content
  const optionsToDisplay =
    showSearchBox && !!searchTerm
      ? options.filter(({ label }) => label.toLowerCase().includes(searchTerm.toLowerCase()))
      : options;

  return (
    <StyledSelectContainer ref={fakeSelectRef} className={className}>
      <StyledSelect
        $hasError={hasError}
        $isDisabled={disabled}
        alignItems="center"
        data-cy={`${dataCy}-ui`}
        gap={1}
        gridTemplateColumns="1fr auto"
        onClick={() => !disabled && setIsOpen(!isOpen)}
      >
        <label>{!selectedOption ? placeholder : selectedOption.label}</label>
        <CssIcon iconName="fas fa-chevron-down" />
      </StyledSelect>
      <StyledOptionList $isOpen={isOpen} role="listbox" tabIndex={-1}>
        {showSearchBox && (
          <StyledSearchBox alignItems="center" columnGap={1}>
            <CssIcon iconName="fas fa-search" />
            <input
              data-cy={`${dataCy}-ui-searchbox`}
              placeholder={t('searchBox.placeholder')}
              value={searchTerm}
              onChange={e => setSearchTerm(e.target.value)}
            />
          </StyledSearchBox>
        )}
        {showPlaceholderInOptionList && placeholder && (
          <StyledOptionListItem $isPlaceholder>{placeholder}</StyledOptionListItem>
        )}
        {optionsToDisplay &&
          optionsToDisplay.map((option: SelectOptions) => (
            <StyledOptionListItem
              key={option.value}
              $isSelected={!!selectedValue && option.value.toString() === selectedValue.toString()}
              aria-selected={!!selectedValue && option.value.toString() === selectedValue.toString()}
              data-cy={`${dataCy}-ui-option`}
              data-value={option.value}
              role="option"
              onClick={() => selectOption(option.value)}
              onKeyPress={() => selectOption(option.value)}
            >
              {option.labelView || option.label}
            </StyledOptionListItem>
          ))}
      </StyledOptionList>

      {/* Real select input */}
      <select ref={selectRef} data-cy={dataCy} disabled={disabled} id={id} value={selectedValue} {...selectProps}>
        {options &&
          options.map((option: SelectOptions) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
      </select>

      {hasError && errorMessage && <StyledErrorMessage data-cy={`error-${dataCy}`}>{errorMessage}</StyledErrorMessage>}
    </StyledSelectContainer>
  );
};
