import React, { useCallback } from 'react';

import { Node, ReplaceChildrenFunctionCallback } from 'components/EmbeddedHtml/EmbeddedHtml.model';
import { EmbeddedHtml } from 'components/EmbeddedHtml';
import { TextQuizTextProps } from './TextQuizText.model';

const MyReplaceComponent = (replaceChildren: ReplaceChildrenFunctionCallback) => <p>{replaceChildren()}</p>;

export const TextQuizText: React.FC<TextQuizTextProps> = ({ text }) => {
  const replaceFunction = useCallback(
    (node: Node) => {
      // remove all old inline styling
      if (node.tagType === 'p') {
        return MyReplaceComponent;
      }
      if (node.tagType === 'span') {
        if (node.children.length > 0) {
          return (replaceChildren: ReplaceChildrenFunctionCallback) => replaceChildren();
        }
        if (!node.innerText) {
          return <></>;
        }
        return <span>{node.innerText}</span>;
      }
      return <></>;
    },
    [text],
  );

  return <EmbeddedHtml rawHtml={text} replaceFunction={replaceFunction} />;
};
