import React from 'react';
import { Select } from 'components/Select';
import { StyledErrorMessage, StyledSelectWrapper } from './SelectWithErrorMessage.styled';
import { SelectWithErrorMessageProps } from './SelectWithErrorMessage.model';

export const SelectWithErrorMessage: React.FC<SelectWithErrorMessageProps> = ({
  dataCy,
  hasError,
  errorMessage,
  ...selectProps
}) => (
  <StyledSelectWrapper>
    <Select dataCy={dataCy} {...selectProps} />
    {hasError && <StyledErrorMessage data-cy={`error-${dataCy}`}>{errorMessage}</StyledErrorMessage>}
  </StyledSelectWrapper>
);
