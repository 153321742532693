import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { PrevExamQuestion } from 'models/exam/Exam';
import { Heading3 } from 'styles/elements/Headings';
import { StyledFragment } from '../../OverviewQuestion.styled';
import { StyledHeadingWrapper, StyledInnerText, StyledText, StyledTextWrapper } from './OverviewLetter.styled';

export interface OverviewLetterProps {
  prevQuestions: PrevExamQuestion[];
  highlightedQuestionId: number;
}

export const OverviewLetter: FC<OverviewLetterProps> = ({ prevQuestions, highlightedQuestionId }) => {
  const { t } = useTranslation('test-environment');
  const { values } = useFormikContext<Record<string, string>>();

  return (
    <StyledFragment>
      <StyledHeadingWrapper>
        <Heading3>{t('progressiveTest.overviewLetter.title')}</Heading3>
      </StyledHeadingWrapper>
      <StyledTextWrapper>
        <StyledText>
          {prevQuestions.map(({ id: questionId }) => (
            <StyledInnerText
              key={`innertext-${questionId}`}
              data-cy={`preview-${questionId}`}
              isHighlighted={questionId === highlightedQuestionId}
            >
              {values[`answer-${questionId}`].indexOf('\n') >= 0
                ? values[`answer-${questionId}`].split('\n').map(sentence => (
                    <>
                      {sentence}
                      <br />
                    </>
                  ))
                : values[`answer-${questionId}`]}
            </StyledInnerText>
          ))}
        </StyledText>
      </StyledTextWrapper>
    </StyledFragment>
  );
};
