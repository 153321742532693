import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { TEACHER_HOME_PAGE_ROUTES } from 'constants/routes';
import {
  GroupCardWrapper,
  GroupCardHeader,
  GroupCardFooter,
  GroupCardStudentsIcon,
  GroupCardAction,
} from './GroupCard.styled';
import { buildUrlWithPathParams } from '../../_helpers/utils/urlBuilder';
import { UsersAPI } from 'generated/types';

const truncate = (str: string, maxLength = 30) => {
  const trimmedString = str.trim();
  if (trimmedString.length > maxLength) {
    return `${trimmedString.slice(0, maxLength)}...`;
  }
  return trimmedString;
};

interface GroupCardProps extends UsersAPI.GroupListItem {
  action?: ReactElement;
  onClick?: (evt: React.MouseEvent<HTMLAnchorElement>, id: number) => void;
  href?: string;
  isActive?: boolean;
}

export const GroupCard: React.FC<GroupCardProps> = ({
  name,
  studentCount,
  action,
  id,
  href,
  onClick,
  isActive = false,
}) => {
  const { t } = useTranslation('groups');

  const linkRef = React.useRef<HTMLAnchorElement>(null);

  const onClickWrapper = React.useCallback(() => {
    if (linkRef?.current) {
      linkRef?.current.click();
    }
  }, [linkRef?.current]);

  const onClickLink = React.useCallback(
    (evt: React.MouseEvent<HTMLAnchorElement>) => {
      evt.stopPropagation();
      if (typeof onClick === 'function') {
        onClick(evt, id);
      }
    },
    [onClick],
  );

  return (
    <GroupCardWrapper
      $isActive={isActive}
      aria-label={t('goToGroup', { name })}
      data-cy={`group-card-${id}`}
      data-cy-element="group-card"
      role="button"
      tabIndex={0}
      onClick={onClickWrapper}
      onKeyDown={evt => {
        if (evt.key === ' ') {
          evt.preventDefault();
        }
        [' ', 'Enter'].includes(evt.key) && onClickWrapper();
      }}
    >
      <GroupCardHeader>
        <Link
          ref={linkRef}
          data-cy={`group-link-${id}`}
          to={href || buildUrlWithPathParams(TEACHER_HOME_PAGE_ROUTES.EDIT_GROUP, { groupId: id })}
          onClick={onClickLink}
        >
          {truncate(name)}
        </Link>
        {action && <GroupCardAction>{action}</GroupCardAction>}
      </GroupCardHeader>
      <GroupCardFooter>
        <GroupCardStudentsIcon iconName="group" size={1} />
        <span>{studentCount}</span>
      </GroupCardFooter>
    </GroupCardWrapper>
  );
};
