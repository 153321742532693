import React, { useMemo } from 'react';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import { modalIds } from 'constants/modals-constants';
import { useModal } from 'hooks/useModal';

import { ProductSkillLevel } from 'models/product/ProductDetails';
import { StudentTargetFormSchema, buildStudentTargetFormSchema } from './TargetLevelSelectorModal.schema';

import { CssFlex, CssForm, CssButton } from 'styles/helpers/layout';
import { Heading2, Heading4 } from 'styles/elements/Headings';
import { StyledModal } from './TargetLevelSelectorModal.styled';
import { RadioInput } from 'components/RadioInput/RadioInput';

export interface TargetLevelSelectorModalProps {
  dataCy?: string;
  title?: string;
  description?: string;
  currentGoalLevel?: number;
  selectableGoalLevels: ProductSkillLevel[];
  submitButtonLabel?: string;
  autoCloseOnSubmit?: boolean;
  onSubmitButtonClick: (values: StudentTargetFormSchema) => void;
}

export const TargetLevelSelectorModal: React.FC<TargetLevelSelectorModalProps> = ({
  dataCy,
  title,
  description,
  currentGoalLevel,
  selectableGoalLevels,
  submitButtonLabel,
  onSubmitButtonClick,
  autoCloseOnSubmit = false,
}) => {
  const { t } = useTranslation('modal');

  const { closeModal, isModalOpen } = useModal();

  const studentTargetSelectorModalId = modalIds.studentTargetSelectorModal;
  const isStudentTargetSelectorModalOpen = isModalOpen(studentTargetSelectorModalId);

  const targetLevelOptions = useMemo(() => {
    return selectableGoalLevels.map(skillLevel => ({
      id: skillLevel.id,
      label: skillLevel.label,
      value: skillLevel.level,
      description: skillLevel.description || '',
    }));
  }, [selectableGoalLevels]);

  return (
    <StyledModal
      wrapInModalBox
      dataCy={dataCy || studentTargetSelectorModalId}
      isOpen={isStudentTargetSelectorModalOpen}
      onClose={closeModal}
    >
      <Formik
        enableReinitialize
        initialValues={{
          targetLevel: currentGoalLevel ? `${currentGoalLevel}` : '',
        }}
        validationSchema={buildStudentTargetFormSchema()}
        onSubmit={values => {
          onSubmitButtonClick(values);
          if (autoCloseOnSubmit) {
            closeModal();
          }
        }}
      >
        {formik => (
          <CssForm noValidate width="100%" onSubmit={formik.handleSubmit}>
            <Heading2>{title || t('targetLevelSelectorModal.title')}</Heading2>
            <Heading4>{description || t('targetLevelSelectorModal.description')}</Heading4>

            {/* Target level selector */}
            <CssFlex flexDirection="column" margin="2rem 0" rowGap={0.75}>
              <RadioInput
                dataCy="target-level-radio-button"
                name="targetLevel"
                options={targetLevelOptions}
                value={formik.values.targetLevel}
                onChange={formik.handleChange}
              />
            </CssFlex>

            <CssButton
              dataCy="submit-target-level-button"
              disabled={formik.isSubmitting}
              type="submit"
              variant="primary"
            >
              {submitButtonLabel || t('targetLevelSelectorModal.submitButton')}
            </CssButton>
          </CssForm>
        )}
      </Formik>
    </StyledModal>
  );
};
