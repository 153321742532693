import styled from 'styled-components/macro';

import { ThemeProps as Props } from 'models/styles/theme';

export const StyledUserData = styled.div<Props>`
  display: flex;
  column-gap: 0.5rem;
  align-items: center;
  justify-content: flex-end;

  margin-right: 1rem;

  color: ${(props: Props) => props.theme.palette.neutral._900};
  text-transform: capitalize;
`;
