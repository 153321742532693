import React from 'react';
import { observer } from 'mobx-react';
import { Redirect } from 'react-router-dom';

import { ROLES } from '@he-learning/service-he-common';
import { getCurrentUser } from 'persistence/access';

import {
  ADMIN_ENVIRONMENT_ROUTES,
  GENERIC_ROUTES,
  TEACHER_HOME_PAGE_ROUTES,
  PRODUCT_PAGES_ROUTES,
} from 'constants/routes';

export const RootPath: React.FC = observer(() => {
  const user = getCurrentUser();

  switch (user?.role) {
    case ROLES.ADMIN:
      return <Redirect to={ADMIN_ENVIRONMENT_ROUTES.USERS_PAGE} />;
    case ROLES.TEACHER:
      return <Redirect to={TEACHER_HOME_PAGE_ROUTES.HOME_PAGE} />;
    case ROLES.STUDENT:
      return <Redirect to={PRODUCT_PAGES_ROUTES.PRODUCT_LIST} />;
    default:
      return <Redirect to={GENERIC_ROUTES.NOT_FOUND} />;
  }
});
