import React, { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { QuestionStatus, TestType } from 'constants/exam-constants';
import { PRODUCT_PAGES_ROUTES } from 'constants/routes';
import { modalIds } from 'constants/modals-constants';

import { useModal } from 'hooks/useModal';

import { SkillDeterminationModal } from 'components/SkillDeterminationModal';
import { useTranslation } from 'react-i18next';
import { buildUrlWithPathParams } from '_helpers/utils/urlBuilder';
import { useProduct } from 'hooks/useProduct';
import { SkillDeterminationParams } from './SkillDeterminationTestPage.model';
import { StyledSkillDeterminationTest } from './SkillDeterminationTestPage.styled';
import { ProductInfo } from './components/SkillTestCompletedMessage/SkillTestCompletedMessage.model';
import { useDeterminationTest } from 'hooks/useDeterminationTest';
import { EmptyExamQuestionsMessage } from 'layouts/EmptyExamQuestionsMessage/EmptyExamQuestionsMessage';
import { HstContentPlayer } from 'layouts/HstContentPlayer/HstContentPlayer';
import { QuestionManager } from 'layouts/QuestionManager';
import { Box } from 'styles/helpers/layout';
import { useAnswer } from 'hooks/useAnswer';
import { LoadingSpinner } from 'components/LoadingSpinner';
import { FixedTestHeader } from 'layouts/FixedTestHeader';
import { CompletedQuestions } from '../components/CompletedQuestions/CompletedQuestions';
import { ERROR_TYPES } from 'constants/error-constants';

export const SkillDeterminationTestPage: React.FC = observer(() => {
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState<number>(0);

  const history = useHistory();
  const {
    determinationTestError,
    determinationTest,
    fetchSkillDeterminationTest,
    determinationTestSessionProgress: currentStatus,
    clearDeterminationTestState,
  } = useDeterminationTest();
  const { questionStatus, evaluateAnswer, resetAnswerState } = useAnswer();
  const { moduleDetails, fetchModuleDetails, nonOwnedProductError } = useProduct();
  const { toggleModal } = useModal();
  const { productId, moduleId, subjectId }: SkillDeterminationParams = useParams();
  const { t } = useTranslation('skill-determination-test');

  useEffect(() => {
    fetchSkillDeterminationTest(+productId, +subjectId);

    // Always clear content and progress of current test before leaving the page
    return () => {
      resetAnswerState();
      clearDeterminationTestState();
    };
  }, [fetchSkillDeterminationTest, productId, subjectId]);

  // Check if progress for current test is loaded (always true if resumable test feature is disabled)
  const isTestStatusLoaded = useMemo(
    () => determinationTest?.token && currentStatus?.skillDeterminationTestSessionToken === determinationTest.token,
    [determinationTest, currentStatus],
  );

  // Show start modal only when resume feature is disabled or test is not started yet
  // Otherwise, skip questions to allow the user to resume where they left off
  useEffect(() => {
    if (isTestStatusLoaded) {
      const isCurrentTestInProgress = currentStatus && currentStatus.answeredQuestions.length > 0;
      if (!isCurrentTestInProgress) {
        toggleModal(modalIds.skillDeterminationTestModal);
      } else {
        setSelectedQuestionIndex(currentStatus.answeredQuestions.length);
      }
    }
  }, [isTestStatusLoaded]);

  useEffect(() => {
    if (productId && moduleId && moduleDetails?.id !== +moduleId) {
      fetchModuleDetails(+productId, +moduleId);
    }
  }, [fetchModuleDetails, productId, moduleId]);

  useEffect(() => {
    if (determinationTestError?.type === ERROR_TYPES.GET_DETERMINATION_QUESTIONS_FAILED) {
      history.replace(buildUrlWithPathParams(PRODUCT_PAGES_ROUTES.PRODUCT_DETAILS, { productId }));
    }
  }, [determinationTestError]);

  if (!Number(productId) || nonOwnedProductError) {
    return <Redirect to={PRODUCT_PAGES_ROUTES.PRODUCT_LIST} />;
  }

  // If test content or current progress is still loading, display spinner
  if (!moduleDetails || !determinationTest || !isTestStatusLoaded) {
    return <LoadingSpinner isFullScreen />;
  }

  const productInfo = {
    product: determinationTest.product,
    module: determinationTest.module,
    subject: determinationTest.subject,
  } as ProductInfo;

  const loadNextQuestion = () => {
    setSelectedQuestionIndex(selectedQuestionIndex + 1);
  };

  const onClose = () => {
    history.push(
      buildUrlWithPathParams(PRODUCT_PAGES_ROUTES.PRODUCT_MODULE_DETAILS, {
        productId,
        moduleId: moduleDetails.id,
      }),
    );
  };

  const examQuestions = determinationTest?.questions ? determinationTest.questions : [];
  const examIsEmpty = examQuestions.length === 0;
  const allQuestionsFinished = selectedQuestionIndex === examQuestions.length;
  const examTitle = moduleDetails?.subjects.find(subject => subject.id === +subjectId)?.title || '';

  return (
    <StyledSkillDeterminationTest>
      {!examIsEmpty && <SkillDeterminationModal />}
      <HstContentPlayer
        content={
          <Box margin="auto" width="80%">
            {examQuestions && examQuestions[selectedQuestionIndex] && (
              <QuestionManager
                evaluateAnswer={(productId, questionId, answers, _, skipAnswerValidation) => {
                  evaluateAnswer({
                    productId,
                    questionId,
                    answers,
                    skipAnswerValidation,
                    testType: TestType.SKILL_DETERMINATION_TEST,
                    testToken: determinationTest?.token ?? '',
                  });
                }}
                flags={[]}
                prevAnswerValues={[]}
                question={examQuestions[selectedQuestionIndex]}
                showTheoryButton={false}
                showTip={questionStatus !== QuestionStatus.INITIAL}
              />
            )}
            {!examIsEmpty && allQuestionsFinished && (
              <CompletedQuestions data={{ productInfo, moduleDetails }} testType={TestType.SKILL_DETERMINATION_TEST} />
            )}
            {examIsEmpty && <EmptyExamQuestionsMessage />}
          </Box>
        }
        header={
          <FixedTestHeader
            currentQuestion={examQuestions[selectedQuestionIndex]}
            options={{ showTheory: false }}
            selectedQuestionIndex={selectedQuestionIndex}
            subtitle={t('header.title', 'Skill determination test')}
            title={examTitle}
            totalQuestionCount={examQuestions.length}
            onClose={onClose}
          />
        }
        loadNextQuestion={loadNextQuestion}
        selectedQuestion={examQuestions[selectedQuestionIndex]}
        testType={TestType.SKILL_DETERMINATION_TEST}
        topicDetails={undefined}
      />
    </StyledSkillDeterminationTest>
  );
});
