import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Select } from 'components/SelectUI/Select';
import { Education } from 'models/auth/Login';
import { Box, Typography } from 'styles/helpers/layout';

interface EducationSelectorProps {
  educations?: Education[];
  onChange: (selectedEducationId: string | null) => void;
}

export const EducationSelector: React.FC<EducationSelectorProps> = ({ educations = [], onChange }) => {
  const { t } = useTranslation('teacher-dashboard');

  const [selectedEducationId, setSelectedEducationId] = useState('all');

  const educationOptions = useMemo(
    () => [
      {
        value: 'all',
        label: t('basicProgress.overview.filters.education.defaultOption', 'All educations'),
      },
      ...educations.map(({ id, title }) => ({
        value: id,
        label: title,
      })),
    ],
    [educations],
  );

  return (
    <Box>
      <Typography fontSize="0.75rem">{t('selectors.educationSelectorLabel')}</Typography>
      <Select
        showSearchBox
        dataCy="education-selector"
        options={educationOptions}
        showPlaceholderInOptionList={false}
        value={selectedEducationId}
        onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
          const selectedValue = event.target.value;
          setSelectedEducationId(selectedValue);
          onChange(selectedValue !== 'all' ? selectedValue : null);
        }}
      />
    </Box>
  );
};
