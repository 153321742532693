import { addMinutes, max } from 'date-fns';
import { ScheduledTestDate } from 'models/exam/ScheduledTest';

export const dateToTimePickerString = (date: Date): string => {
  const hours = date.getHours();
  const minutes = date.getMinutes();
  return [hours < 10 ? `0${hours}` : `${hours}`, minutes < 10 ? `0${minutes}` : `${minutes}`].join(':');
};

export const updateDateTime = (date: Date, hours: number, minutes: number, seconds = 0): Date =>
  new Date(date.getFullYear(), date.getMonth(), date.getDate(), hours, minutes, seconds, 0);

export const getLatestTestInstance = (moments: ScheduledTestDate[]): Date => {
  // get end times of all the test instances, taking duration into account
  const endTimes = moments.map(moment => addMinutes(moment.startDate, moment.duration));

  //return the latest one
  return max(endTimes);
};
