import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import { modalIds } from 'constants/modals-constants';
import { HstProducts, ProductType } from 'constants/hst-constants';
import { STUDENT_MODULES } from 'analytics/constants/pageTypes';
import { HST_PROGRESS_EVENTS } from '@he-learning/service-he-common';

import { useModal } from 'hooks/useModal';
import { useProduct } from 'hooks/useProduct';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { useDomainHandler } from 'hooks/useDomainHandler';
import { useProductProgress } from 'hooks/useProductProgress';
import { useAdaptivePractice } from 'hooks/useAdaptivePractice';

import { trackPlatformEvent } from 'analytics/MetabaseTracker/metabase-tracker';

import { Heading3 } from 'styles/elements/Headings';
import { CssClickableIcon, CssFlex } from 'styles/helpers/layout';

import { ProgressStatBox } from '../ProgressStatBox/ProgressStatBox';
import { TargetLevelSelectorModal } from 'components/TargetLevelSelectorModal/TargetLevelSelectorModal';

export const GoalLevelBox: React.FC = observer(() => {
  const { t } = useTranslation('homepage');
  const { getStudentTermByDomain, getTeacherTermByDomain } = useDomainHandler();

  const { toggleModal } = useModal();
  const { productDetails } = useProduct();
  const { updateProductGoalLevel, productGoalLevel } = useProductProgress();
  const { setMixedExercise } = useAdaptivePractice();
  const { showStudentGoalLevelSelector } = useFeatureFlags();

  const currentGoalLevel = useMemo(() => productGoalLevel?.goalLevel, [productGoalLevel]);
  const selectableGoalLevels = useMemo(
    () => productDetails?.levels.filter(({ selectable }) => selectable) || [],
    [productDetails],
  );

  const currentGoalLevelName = useMemo(
    () => productDetails?.levels.find(({ level }) => level === currentGoalLevel)?.label,
    [currentGoalLevel],
  );

  // Goal level should be set by teacher in business products if feature is enabled
  const isGoalLevelSetByTeacher = useMemo(() => {
    const isBusinessProduct = productDetails?.type === ProductType.BUSINESS;
    return showStudentGoalLevelSelector && isBusinessProduct;
  }, [productDetails, showStudentGoalLevelSelector]);

  // Obtain text to show in the tooltip depending on the selected product
  const tooltipText = useMemo(() => {
    if (isGoalLevelSetByTeacher) {
      return t('goalLevelTooltip.setByTeacher', { teacherTerm: getTeacherTermByDomain() });
    }

    // TODO: It'd be good that they set this metadata in the product itself, so it's dynamic
    // This works now that all products (except for english) are dutch based, but it won't make
    // any sense in the future and adding a lot of "if" blocks with advice regarding each specific
    // level set is a terrible solution
    if (productDetails?.id === HstProducts.ENGLISH) {
      return t('goalLevelTooltip.englishLevelAdvice', {
        studentTerm: getStudentTermByDomain({ usePlural: true }),
        teacherTerm: getTeacherTermByDomain(),
      });
    } else {
      return t('goalLevelTooltip.dutchLevelAdvice', {
        studentTerm: getStudentTermByDomain({ usePlural: true }),
        teacherTerm: getTeacherTermByDomain(),
      });
    }
  }, [productDetails, isGoalLevelSetByTeacher]);

  // Save new goal level only if selected option is different from current user level
  const onGoalLevelChange = (selectedGoalLevel: number | string) => {
    if (selectedGoalLevel && selectedGoalLevel !== currentGoalLevel) {
      setMixedExercise(false);
      updateProductGoalLevel(productDetails?.id as number, +selectedGoalLevel);

      trackPlatformEvent({
        module: STUDENT_MODULES.BASIC_PROGRESS,
        itemId: HST_PROGRESS_EVENTS.GOAL_LEVEL_CHANGE,
        value: JSON.stringify({ from: productGoalLevel, to: selectedGoalLevel }),
      });
    }
  };

  return (
    <div>
      <ProgressStatBox
        dataCy="goal-skill-level-box"
        title={t('subjects.recentProgress.targetLevel', 'Doelniveau')}
        tooltip={tooltipText}
        value={
          <CssFlex flexDirection="row" gap={1}>
            <Heading3 data-cy="current-goal-level">{currentGoalLevelName || '-'}</Heading3>
            {!isGoalLevelSetByTeacher && (
              <CssClickableIcon
                data-cy="edit-own-goal-level-button"
                iconName="fas fa-pencil"
                iconSize="lg"
                onClick={() => toggleModal(modalIds.studentTargetSelectorModal)}
              />
            )}
          </CssFlex>
        }
      />
      <TargetLevelSelectorModal
        autoCloseOnSubmit
        currentGoalLevel={currentGoalLevel}
        dataCy="set-own-goal-level-modal"
        selectableGoalLevels={selectableGoalLevels}
        onSubmitButtonClick={({ targetLevel }) => onGoalLevelChange(targetLevel)}
      />
    </div>
  );
});
