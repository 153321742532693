import { Tag } from 'components/Tag/Tag';
import { TagProps } from 'components/Tag/Tag.model';
import { ScheduledTestLogStatus } from 'models/log/Log';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { LogStatusTagProps } from './LogStatusTag.model';

const tagPropsFromStatus = (status: ScheduledTestLogStatus): TagProps => {
  switch (status) {
    case 'testing':
      return {
        variant: 'secondary',
        fillType: 'bordered',
        leftIcon: 'dotIcon',
      };
    case 'finished':
      return {
        variant: 'success',
        fillType: 'filled',
        leftIcon: 'checkIcon',
      };
    case 'termsAccepted':
      return {
        variant: 'dark',
        fillType: 'bordered',
        leftIcon: 'checkIcon',
      };
    default:
      return {
        variant: 'default',
        fillType: 'bordered',
      };
  }
};

export const LogStatusTag: React.FC<LogStatusTagProps> = ({ status }) => {
  const { t } = useTranslation('scheduled-tests');
  return <Tag {...tagPropsFromStatus(status)}>{t(`liveMonitor.status.${status}`, status)}</Tag>;
};
