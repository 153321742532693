import styled, { css } from 'styled-components/macro';
import { ElearningThemeInterface } from 'themes/elearningInterface.theme';
import { calculateRem } from 'styles/mixins/mixins';
import InfoStatusProps from './InfoStatus.model';

interface Props extends InfoStatusProps {
  theme: ElearningThemeInterface;
}

// Status styles with objects instead of switch
const infoStatus = {
  completed: css`
    background-color: ${(props: Props) => props.theme.palette.neutral.white};
    border-color: ${(props: Props) => props.theme.palette.neutral.black};
  `,
  inProgress: css`
    background-color: ${(props: Props) => props.theme.palette.neutral._100};
  `,
  notStarted: css`
    background-color: ${(props: Props) => props.theme.palette.neutral._100};
    color: ${(props: Props) => props.theme.palette.neutral._600};
  `,
  default: css`
    background-color: ${(props: Props) => props.theme.palette.neutral._100};
  `,
};

export const StyledInfoStatus = styled.div<Props>`
  display: flex;
  padding: 0 0.5rem;
  column-gap: 0.5rem;
  align-items: center;

  font-size: ${({ theme }: Props) => theme.font.size._14};
  font-weight: ${(props: Props) => props.theme.font.weight.normal};
  border-radius: ${calculateRem(4)};
  border: 1px solid ${(props: Props) => props.theme.palette.neutral._400};
  ${props => infoStatus[props.status || 'default']};
`;
