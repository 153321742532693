import { ThemeProps as Props } from 'models/styles/theme';
import styled from 'styled-components/macro';

export interface StyledTextProps extends Props {
  color?: string;
}

export const StyledText = styled.div<StyledTextProps>`
  color: ${({ theme, color }: StyledTextProps) => color ?? theme.palette.neutral._600};
`;
