import styled from 'styled-components/macro';
import { ThemeProps as Props } from 'models/styles/theme';

import { CssButton } from 'styles/helpers/layout';

export const StyledOnboardingButton = styled(CssButton)<Props>`
  color: ${(props: Props) => props.theme.palette.neutral.black};
  font-weight: ${(props: Props) => props.theme.font.weight.normal};

  :hover {
    color: ${(props: Props) => props.theme.palette.neutral._600};
    font-weight: ${(props: Props) => props.theme.font.weight.normal};
  }
`;

export const StyledTeacherEnvButton = styled(CssButton)<Props>`
  line-height: 1.5;
  padding: 0.5rem 1rem;
  font-weight: ${(props: Props) => props.theme.font.weight.normal};

  .content {
    display: flex;
    column-gap: 0.5rem;
    align-items: center;
    justify-content: center;
  }
`;
