import styled from 'styled-components/macro';
import { ElearningThemeInterface } from 'themes/elearningInterface.theme';

interface Props {
  theme: ElearningThemeInterface;
}

export const StyledCard = styled.div`
  width: 100%;
  margin-bottom: 40px;
`;

export const StyleHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 0;
  font-size: ${(props: Props) => props.theme.font.size._24};
  background-color: ${(props: Props) => props.theme.palette.neutral._200};
`;
