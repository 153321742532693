import React, { useMemo, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { HorizontalBarGraphItem } from './ExercisesPerSubjectGraph.model';
import { EmptyGraphPlaceholder } from '../EmptyGraphPlaceholder/EmptyGraphPlaceholder';
import { GraphLoading } from '../GraphLoading/GraphLoading';
import { ExercisesPerSubjectGraphProps } from './ExercisesPerSubjectGraph.model';
import { StyledGraphTitle } from '../../PersonalProgressPage.styled';
import { BarChart, Bar, XAxis, YAxis, LabelList, ResponsiveContainer } from 'recharts';
import { useTheme } from 'styled-components/macro';
import { ElearningThemeInterface } from 'themes/elearningInterface.theme';

export const MyExercisesPerSubjectGraph: React.FC<ExercisesPerSubjectGraphProps> = ({
  hasLoadingError,
  exercisePerSubjectResults,
  subjects,
  onRenderComplete,
}) => {
  const theme = useTheme() as ElearningThemeInterface;
  const elRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation('results');

  const subjectExerciseData = useMemo<HorizontalBarGraphItem[] | null>(() => {
    if (!exercisePerSubjectResults || !subjects) {
      return null;
    }
    return subjects.map(subject => ({
      name: subject.title,
      answered: exercisePerSubjectResults.find(result => result.subjectId === subject.id)?.answeredQuestions || 0,
    }));
  }, [exercisePerSubjectResults, subjects]);

  const noResultsFound = subjectExerciseData?.filter(subject => subject.answered !== 0).length === 0;
  const loadingResults = !subjectExerciseData;

  useEffect(() => {
    if (noResultsFound && onRenderComplete && elRef.current) {
      onRenderComplete(elRef.current);
    }
  }, [noResultsFound, onRenderComplete]);

  return (
    <div ref={elRef}>
      <StyledGraphTitle data-cy="exercises-per-subject-graph-title">
        {t('graphTitle.exercisesPerSubject', 'Questions done per subject')}
      </StyledGraphTitle>
      {(loadingResults || hasLoadingError) && (
        <GraphLoading dataCy="exercises-per-subject-graph-loading" hasLoadingError={hasLoadingError} />
      )}
      {!hasLoadingError && noResultsFound && (
        <EmptyGraphPlaceholder>{t('placeholder.notEnoughGraphData')}</EmptyGraphPlaceholder>
      )}
      {subjectExerciseData && !noResultsFound && (
        <div data-cy="exercises-per-subject-graph">
          <ResponsiveContainer debounce={300} height={300} width="100%">
            <BarChart
              barSize={15}
              data={subjectExerciseData}
              layout="vertical"
              margin={{
                top: 5,
                right: 0,
                left: 35,
                bottom: 5,
              }}
            >
              <XAxis type="number" />
              <YAxis dataKey="name" tickLine={false} tickMargin={10} type="category" />
              <Bar dataKey="answered" fill={theme.palette.secondary.brand}>
                <LabelList dataKey="answered" fill={theme.palette.text.primary} position="insideRight" />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </div>
      )}
    </div>
  );
};

export const ExercisesPerSubjectGraph = React.memo(MyExercisesPerSubjectGraph);
