import React, { FC, useState } from 'react';
import { Box, CssButton, CssFlex } from 'styles/helpers/layout';
import { DeleteAccountsModal } from '../DeleteAccountsModal/DeleteAccountsModal';
import { useModal } from 'hooks/useModal';
import { modalIds } from 'constants/modals-constants';
import { useAdmin } from 'hooks/useAdmin';
import { useTranslation } from 'react-i18next';
import {
  EmailType,
  MultipleEmailInput,
} from 'pages/TeacherEnvironment/TeacherDashboard/subpages/StudentLicense/subpages/CreateInviteForm/components/MultipleEmailInput/MultipleEmailInput';

export const DeleteAccounts: FC = () => {
  const [emails, setEmails] = useState<EmailType[]>([]);

  const { openModal } = useModal();
  const { deleteAccounts } = useAdmin();
  const { t } = useTranslation('admin-environment');

  const emailsArr = emails.map(x => x.email);

  return (
    <CssFlex mb={2}>
      <Box width="100%">
        <MultipleEmailInput selectedEmails={emails} width="90%" onSelectedEmailsChanged={setEmails} />
      </Box>
      <CssButton
        dataCy="delete-account"
        disabled={emails.length === 0}
        height="3rem"
        ml={5}
        mt={1}
        p={0}
        variant="primary"
        width="25%"
        onClick={() => openModal(modalIds.deleteAccountsModal)}
      >
        {t('adminTools.deleteAccounts.action')}
      </CssButton>
      <DeleteAccountsModal emails={emailsArr} onSubmit={() => emails.length > 0 && deleteAccounts(emailsArr)} />
    </CssFlex>
  );
};
