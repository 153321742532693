import styled from 'styled-components/macro';
import { ThemeProps as Props } from 'models/styles/theme';
import { Heading5 } from 'styles/elements/Headings';
import { FontAwesomeIcon } from 'components/FontAwesomeIcon';

export type ProgressStage = 'not-started' | 'in-progress' | 'ready';

interface FontAwesomeIconProps extends Props {
  stage: ProgressStage;
}

export const StyledTitle = styled(Heading5)`
  margin-top: 1rem;
  margin-bottom: 0rem;
  text-align: left;
`;

export const StyledWrapper = styled.div`
  width: 90%;
`;

export const StyledHr = styled.hr<Props>`
  height: 0.1rem;
  background: ${({ theme }: Props) => theme.palette.neutral._900};
`;

export const StyledFontAwesomeIcon = styled(FontAwesomeIcon)<FontAwesomeIconProps>`
  margin-right: 0.5rem;
  border: ${({ stage }) => (stage === 'not-started' ? '1px solid black' : 'none')};
  border-radius: 50%;
  color: ${({ stage, theme }: FontAwesomeIconProps) => {
    switch (stage) {
      case 'not-started':
        return theme.palette.neutral.white;
      case 'in-progress':
        return theme.palette.feedback.inProgress.brand;
      case 'ready':
        return theme.palette.feedback.success.brand;
      default:
        return '';
    }
  }};
`;
