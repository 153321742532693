import React, { useCallback, useMemo, useRef, useState, useEffect } from 'react';
import { OrderedMap as ImmutableOrderedMap } from 'immutable';
import { WordAction, WordActionUpdate } from 'models/exam/CorrectTextQuestion';
import { useFullScreen } from 'hooks/useFullScreen';
import { ModalRenderType } from 'components/Modal/Modal.model';
import { EmbeddedHtml } from 'components/EmbeddedHtml';
import { CorrectTextQuestionOptionListPortal } from 'components/CorrectTextQuestionOptionList/CorrectTextQuestionOptionListPortal';
import { Position, TooltipPosition } from '_helpers/tooltip';
import { QuestionStatus } from 'constants/exam-constants';
import { useTranslation } from 'react-i18next';
import { AnswerResult } from 'models/exam/Exam';
import { WordActionFeedbackPortal } from 'components/WordActionFeedback/WordActionFeedbackPortal';
import { InfoCircle } from 'components/InfoCircle/InfoCircle';
import { useOnClickOutside } from 'hooks/useOnClickOutside';

import { useParams } from 'react-router-dom';
import { useSelectedWord } from './hooks/useSelectedWord';
import { CorrectTextQuestionProps } from './CorrectTextQuestion.model';
import {
  StyledCorrectTextQuestion,
  StyledCorrectTextQuestionButtons,
  StyledCorrectTextQuestionContent,
  StyledCorrectTextQuestionParagraph,
} from './CorrectTextQuestion.styled';
import { useWordActions } from './hooks/useWordActions';
import { useTooltip } from './hooks/useTooltip';
import { useHoveredWord } from './hooks/useHoveredWord';
import { CorrectTextQuestionEmbeddedHtml } from './components';
import * as helpers from './helpers/helpers';
import { QuestionsManagerPathParams } from '../../QuestionManager.model';
import { useAnswer } from 'hooks/useAnswer';
import { AnswerButton } from '../AnswerButton/AnswerButton';

const isEditableStatus = (status: QuestionStatus) =>
  [QuestionStatus.INITIAL, QuestionStatus.REVIEWING_ANSWERS].includes(status);

export const CorrectTextQuestion: React.FC<CorrectTextQuestionProps> = ({
  answerFeedbackComponent: answerComponent,
  answerCount,
  availableActions,
  evaluateAnswer,
  isComparingAnswers = false,
  question,
  results,
  status,
  isEvaluatingAnswer,
  userAnswers,
}) => {
  const { t } = useTranslation('correct-text-question');
  const { productId, exerciseId }: QuestionsManagerPathParams = useParams();
  const setExerciseIdValue = !exerciseId ? undefined : +exerciseId;
  const { setAnswerButtonProps } = useAnswer();

  const wordList = useRef<ImmutableOrderedMap<number, string>>(ImmutableOrderedMap());

  const hasResults = !!(results && results.length > 0);
  const viewMode = helpers.getViewMode(hasResults, isComparingAnswers);

  const elRef = useRef<HTMLDivElement>(null);
  const optionListRef = useRef<HTMLDivElement>(null);
  const feedbackPopoverRef = useRef<HTMLDivElement>(null);

  const { fullScreen } = useFullScreen();

  const { selectedWordId, selectedWordValue, selectedWordAction, inEditMode, setSelectedWord, setSelectedWordAction } =
    useSelectedWord();

  const enabledWordActions = useWordActions({
    activeWord: selectedWordValue,
    customEnabledWordActions: availableActions,
  });

  const [wordUpdates, setWordUpdates] = useState<ImmutableOrderedMap<number, WordActionUpdate>>(ImmutableOrderedMap());
  const wordUpdatesArr = useMemo<readonly [number, WordActionUpdate][]>(() => wordUpdates.toArray(), [wordUpdates]);

  // when user is reviewing existing answers, load them into the wordUpdates state on load
  useEffect(() => {
    if (wordList.current && userAnswers) {
      setWordUpdates(ImmutableOrderedMap(helpers.answersToWordActions(userAnswers, wordList.current.toArray())));
    }
  }, []);

  const addWordUpdate = useCallback(
    (wordId: number, wordAction: WordAction, value: string = '') => {
      setWordUpdates(
        wordUpdates.set(wordId, {
          wordId,
          action: wordAction,
          originalValue: selectedWordValue as string,
          updatedValue: value.trim(),
        }),
      );
    },
    [selectedWordValue, wordUpdates],
  );

  const { hoverWordId, setHoverWordId, hoverWordState, hoverWordSolution } = useHoveredWord({
    results: results as AnswerResult[],
    getWordAction: wordId => helpers.getWordUpdateAction(wordUpdatesArr, wordId),
    getWordUpdatedValue: wordId => helpers.getWordUpdateValue(wordUpdatesArr, wordId),
  });

  const toggleSelectedWordId = useCallback(
    (wordId: number, value: string) => {
      hasResults || selectedWordId === wordId
        ? setSelectedWord(null)
        : setSelectedWord({ selectedWordId: wordId, selectedWordValue: value, selectedWordAction: WordAction.NONE });
    },
    [hasResults, selectedWordId, setSelectedWord],
  );

  const [optionListPosition, setOptionListPosition] = useState<Position>({ x: 0, y: 0 });
  const showOptionList = !!(!hasResults && selectedWordId && !inEditMode);

  useOnClickOutside({
    disabled: !showOptionList,
    ref: optionListRef,
    handler: useCallback(
      event => {
        if (!(event.target as HTMLElement)?.classList.contains('CorrectTextQuestionWord')) {
          setSelectedWord(null);
        }
      },
      [setSelectedWord],
    ),
  });

  const [feedbackPopoverPosition, setFeedbackPopoverPosition] = useState<Position | null>(null);
  const showFeedbackPopover = !!(hoverWordId && feedbackPopoverPosition);
  const feedbackPopoverPositionCallback = useCallback((position: Position, calculatedPosition: TooltipPosition) => {
    if (calculatedPosition.y === 'top') {
      setFeedbackPopoverPosition({
        ...position,
        y: position.y - 10,
      });
    } else {
      setFeedbackPopoverPosition({
        ...position,
        y: position.y + 10,
      });
    }
  }, []);

  // option list tooltip for selected word
  useTooltip({
    isEnabled: showOptionList,
    container: elRef.current as HTMLDivElement,
    target: helpers.getWordElementById(question.id, selectedWordId),
    tooltip: optionListRef.current,
    preferredPosition: { x: 'right', y: 'bottom' },
    callback: setOptionListPosition,
  });

  // feedback popover tooltip
  useTooltip({
    isEnabled: hasResults,
    container: elRef.current as HTMLDivElement,
    target: helpers.getWordElementById(question.id, hoverWordId),
    tooltip: feedbackPopoverRef.current,
    preferredPosition: { x: 'center', y: 'bottom' },
    callback: feedbackPopoverPositionCallback,
  });

  const submitSelectedWordAction = useCallback(
    (value: string = '') => {
      const hasEdited = selectedWordAction !== WordAction.EDIT_WORD || value.trim() !== selectedWordValue;
      const hasAddedWordAfter = selectedWordAction !== WordAction.ADD_WORD_AFTER || value.trim() !== '';
      if (selectedWordId && hasEdited && hasAddedWordAfter && selectedWordAction !== WordAction.NONE) {
        addWordUpdate(selectedWordId, selectedWordAction, value);
      }
      setSelectedWord(null);
    },
    [selectedWordAction, selectedWordValue, selectedWordId, setSelectedWord, addWordUpdate],
  );
  const cancelSelectedWordAction = useCallback(() => setSelectedWord(null), [setSelectedWord]);

  const onWordMouseOver = useCallback((wordId: number) => setHoverWordId(wordId), [setHoverWordId]);
  const onWordMouseOut = useCallback(
    (wordId: number) => {
      if (wordId === hoverWordId) {
        setHoverWordId(undefined);
        setFeedbackPopoverPosition(null);
      }
    },
    [hoverWordId, setHoverWordId],
  );
  const onWordReplace = useCallback((wordId: number, innerText: string) => {
    wordList.current = wordList.current.set(wordId, innerText);
  }, []);

  const onSelectWordAction = useCallback(
    (wordAction: WordAction) => {
      if (typeof selectedWordId === 'undefined') {
        return;
      }
      const nextWord = helpers.getNextWord(wordList.current.toArray(), selectedWordId);
      switch (wordAction) {
        case WordAction.EDIT_WORD:
        case WordAction.ADD_WORD_AFTER:
          setSelectedWordAction(wordAction);
          break;
        case WordAction.MERGE_WORDS:
          if (nextWord) {
            addWordUpdate(selectedWordId, wordAction, `${selectedWordValue}${nextWord}`);
            setSelectedWord(null);
          }
          break;
        case WordAction.SWAP_WORDS:
          if (nextWord) {
            addWordUpdate(selectedWordId, wordAction, `${nextWord} ${selectedWordValue}`);
            setSelectedWord(null);
          }
          break;
        default:
          addWordUpdate(selectedWordId, wordAction, selectedWordValue);
          setSelectedWord(null);
          break;
      }
    },
    [addWordUpdate, selectedWordId, selectedWordValue, setSelectedWord, setSelectedWordAction],
  );

  const onUndoWordAction = (wordId: number) => {
    setWordUpdates(wordUpdates.delete(wordId));
    setSelectedWord(null);
  };

  const editsToGo = Math.max(answerCount - wordUpdates.count(), 0);

  const submitAnswers = (skipAnswerValidation?: boolean) => {
    const answers = helpers.wordActionsToAnswers(wordUpdatesArr);
    evaluateAnswer && evaluateAnswer(+productId, question.id, answers, setExerciseIdValue, skipAnswerValidation);
  };

  // Set configuration for answer button in button bar when outside of the review answers page
  useEffect(() => {
    if (status !== QuestionStatus.REVIEWING_ANSWERS) {
      setAnswerButtonProps({
        label: t('button.submitAnswer.label', 'Submit answer'),
        isDisabled: !(isEditableStatus(status) && editsToGo === 0),
        isHidden: !isEditableStatus(status),
        isLoading: isEvaluatingAnswer || false,
        onClick: submitAnswers,
      });
    }
  }, [editsToGo, isEvaluatingAnswer, status, wordUpdatesArr]);

  return (
    <>
      <StyledCorrectTextQuestion
        ref={elRef}
        className="CorrectTextQuestion-wrapper"
        data-cy={`correct-text-question-${question.id}`}
      >
        <StyledCorrectTextQuestionContent>
          {question.introduction && (
            <StyledCorrectTextQuestionParagraph>
              <EmbeddedHtml className="CorrectTextQuestion-introduction" rawHtml={question.introduction} />
            </StyledCorrectTextQuestionParagraph>
          )}
          <CorrectTextQuestionEmbeddedHtml
            editLimitReached={answerCount > 0 && editsToGo === 0}
            getWordAction={useCallback(wordId => helpers.getWordUpdateAction(wordUpdatesArr, wordId), [wordUpdatesArr])}
            getWordUpdatedValue={useCallback(
              wordId => helpers.getWordUpdateValue(wordUpdatesArr, wordId),
              [wordUpdatesArr],
            )}
            htmlContent={question.content}
            questionId={question.id}
            results={results as AnswerResult[]}
            selectedWordAction={selectedWordAction}
            selectedWordId={selectedWordId}
            viewMode={viewMode}
            onEditWordCancel={cancelSelectedWordAction}
            onEditWordOk={submitSelectedWordAction}
            onWordClick={toggleSelectedWordId}
            onWordMouseOut={onWordMouseOut}
            onWordMouseOver={onWordMouseOver}
            onWordReplace={onWordReplace}
          />
        </StyledCorrectTextQuestionContent>
        <div>
          {isEditableStatus(status) && editsToGo > 0 && (
            <InfoCircle
              dataCy="edits-to-go-label"
              radius={24}
              subtext={t('editsToGo.label', { count: editsToGo })}
              value={editsToGo}
              variant="primary"
            />
          )}
          {/* -- Show internal button in review answers page -- */}
          {status === QuestionStatus.REVIEWING_ANSWERS && (
            <StyledCorrectTextQuestionButtons>
              <AnswerButton
                disabled={false}
                isLoading={isEvaluatingAnswer}
                showConfirmAnswerButton={isEditableStatus(status) && editsToGo === 0}
                onSubmitAnswer={submitAnswers}
              >
                {t('button.updateAnswer.label', 'Update answer')}
              </AnswerButton>
            </StyledCorrectTextQuestionButtons>
          )}
          {answerComponent}
        </div>
      </StyledCorrectTextQuestion>
      <CorrectTextQuestionOptionListPortal
        ref={optionListRef}
        activeWordAction={helpers.getWordUpdateAction(wordUpdatesArr, selectedWordId)}
        dataCy="correct-text-question-option-list"
        enabledWordActions={enabledWordActions}
        isActive={showOptionList}
        modalRenderType={fullScreen ? ModalRenderType.INLINE : ModalRenderType.REACT_PORTAL}
        undoEnabled={!!(selectedWordId && wordUpdates.has(selectedWordId))}
        x={optionListPosition.x}
        y={optionListPosition.y}
        onUndo={() => onUndoWordAction(selectedWordId as number)}
        onWordAction={onSelectWordAction}
      />
      {hasResults && (
        <WordActionFeedbackPortal
          ref={feedbackPopoverRef}
          isActive={showFeedbackPopover}
          modalRenderType={fullScreen ? ModalRenderType.INLINE : ModalRenderType.REACT_PORTAL}
          originalWord={wordUpdates.get(hoverWordId as number)?.originalValue}
          solution={hoverWordSolution}
          state={hoverWordState}
          updatedWord={wordUpdates.get(hoverWordId as number)?.updatedValue}
          wordAction={wordUpdates.get(hoverWordId as number)?.action as WordAction}
          x={feedbackPopoverPosition?.x}
          y={feedbackPopoverPosition?.y}
        />
      )}
    </>
  );
};
