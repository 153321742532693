import styled from 'styled-components/macro';
import { ElearningThemeInterface } from 'themes/elearningInterface.theme';
import { StyledPersonalProgressPageProps as StyleProps } from './PersonalProgressPage.model';

interface Props {
  theme: ElearningThemeInterface;
}

export const StyledGraphTitle = styled.h3<Props>`
  color: ${(props: Props) => props.theme.palette.neutral._900};
  text-align: left;
  font-weight: 400;
`;

export const StyledPersonalProgresPage = styled.div<Props>`
  margin-top: 3rem;
  margin-bottom: 4rem;
  text-align: center;
`;
export const StyledContent = styled.div`
  margin: 2rem 0;
  width: 100%;
`;
export const StyledNav = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1.5rem;
  > div {
    display: flex;
  }
`;
export const StyledGraphs = styled.div<StyleProps>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 32px;
`;
export const StyledGraph = styled.div<StyleProps>`
  display: flex;
  flex-direction: column;
  padding: 24px;
  border: 1px solid ${(props: Props) => props.theme.palette.neutral._400};
  > h5 {
    text-align: left;
    font-size: 24px;
    font-weight: normal;
  }
  &:nth-child(1) {
    grid-column: 1 / span 2;
    grid-row: 1;
  }
  &:nth-child(2) {
    grid-column: 1;
    grid-row: 2;
  }
  &:nth-child(3) {
    grid-column: 2;
    grid-row: 2;
    min-height: ${(props: StyleProps) => props.numberOfSubjects && props.numberOfSubjects <= 4 && '280px'};
  }
`;
