import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { format, Locale } from 'date-fns';

import { useDomainHandler } from 'hooks/useDomainHandler';
import { TEST_ENVIRONMENT_ROUTES } from 'constants/routes';
import { useDateLocale } from 'hooks/useDateLocale';
import { useScheduledTests } from 'hooks/useScheduledTests';
import useRequest from 'hooks/user/useRequest';
import { SvgIconReact } from 'components/SvgIconReact';
import { Heading1, Heading2, Heading3, Heading4 } from 'styles/elements/Headings';
import { GeneralHeader } from 'pages/TestEnvironment/components/GeneralHeader/GeneralHeader';
import {
  StyledTestCompletedPage,
  TestCompletedReviewDateContainer,
  TestCompletedReviewDateInfo,
  TestCompletedReviewDateIntro,
  TestCompletedTitleContainer,
} from './TestCompletedPage.styled';

const dateFormat = (dateStr: string, locale: Locale): string => format(new Date(dateStr), 'd MMM, yyyy', { locale });
const timeFormat = (dateStr: string, locale: Locale): string => format(new Date(dateStr), 'h:mm', { locale });

export const TestCompletedPage: React.FC = observer(() => {
  const { t } = useTranslation('test-environment');
  const history = useHistory();
  const dateLocale = useDateLocale();
  const { scheduledTestContent } = useScheduledTests();
  const { logout } = useRequest();
  const { getCustomNameByDomain } = useDomainHandler();

  useEffect(() => {
    if (!scheduledTestContent) {
      // not validated, redirect back to landing page
      history.replace(TEST_ENVIRONMENT_ROUTES.LANDING_PAGE);
    }
  }, [scheduledTestContent]);

  // TODO: when available in content API, check if test is actually completed
  // otherwise redirect

  // Force logout if user has remained in the page for 30 min
  useEffect(() => {
    const timeoutId = setTimeout(() => logout(), 30 * 60 * 1000);
    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <StyledTestCompletedPage>
      <GeneralHeader sideMenuEnabled={false} />
      <TestCompletedTitleContainer data-cy="test-completed-title-container">
        <div>
          <Heading1>
            {t('testCompleted.header.title', 'You are finished with the test. You can close your browser.')}
          </Heading1>
        </div>
      </TestCompletedTitleContainer>
      {scheduledTestContent?.reviewStartDate && scheduledTestContent?.reviewEndDate && (
        <TestCompletedReviewDateContainer>
          <TestCompletedReviewDateIntro data-cy="test-completed-review-date-intro">
            <Heading1>
              {t('testCompleted.reviewDate.title', 'Review of the questions and your answers will be possible from')}
            </Heading1>
            <Heading2>{t('testCompleted.reviewDate.subtitle', { appName: getCustomNameByDomain() })}</Heading2>
          </TestCompletedReviewDateIntro>
          <TestCompletedReviewDateInfo data-cy="test-completed-review-date-info">
            <SvgIconReact iconName="calendarIcon" size={3} />
            <div>
              <Heading3 data-cy="test-completed-review-date-start-date">
                {dateFormat(scheduledTestContent.reviewStartDate, dateLocale)}
              </Heading3>
              <Heading4 data-cy="test-completed-review-date-start-time">
                {timeFormat(scheduledTestContent.reviewStartDate, dateLocale)}
              </Heading4>
            </div>
            <div />
            <span>{t('testCompleted.reviewDate.until', 'until')}</span>
            <SvgIconReact iconName="calendarIcon" size={3} />
            <div>
              <Heading3 data-cy="test-completed-review-date-end-date">
                {dateFormat(scheduledTestContent.reviewEndDate, dateLocale)}
              </Heading3>
              <Heading4 data-cy="test-completed-review-date-end-time">
                {timeFormat(scheduledTestContent.reviewEndDate, dateLocale)}
              </Heading4>
            </div>
          </TestCompletedReviewDateInfo>
        </TestCompletedReviewDateContainer>
      )}
    </StyledTestCompletedPage>
  );
});
