import styled, { css } from 'styled-components/macro';
import { Button } from 'components/Button';

interface StyledIconButtonProps {
  $isCircle?: boolean;
}

const circleCss = css`
  border-radius: 50%;
`;

export const StyledIconButton = styled(Button)<StyledIconButtonProps>`
  line-height: normal;
  ${({ $isCircle: isCircle }) => isCircle && circleCss}
  svg {
    fill: currentColor;
  }
  path: {
    fill: white;
  }
`;
