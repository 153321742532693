import styled from 'styled-components/macro';
import { ThemeProps as Props } from 'models/styles/theme';
import { FontAwesomeIcon } from 'components/FontAwesomeIcon';

export interface FontAwesomeIconProps extends Props {
  color?: string;
}

export const StyledTable = styled.table<Props>`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 3rem;

  thead > tr > th {
    font-weight: bold;
    text-align: left;
    vertical-align: middle;
    background: ${({ theme }: Props) => theme.palette.neutral._200};

    :nth-child(1) {
      width: 4rem;
      text-align: left;
    }

    :nth-child(2) {
      width: 25%;
      text-align: left;
    }

    :nth-child(3) {
      width: 20%;
      text-align: center;
    }
  }

  tbody > tr {
    :nth-child(2n + 2) {
    }
  }

  tbody > tr > td {
    :nth-child(1) {
      width: 4rem;
      text-align: left;
    }

    :nth-child(2) {
      width: 25%;
      text-align: left;
    }

    :nth-child(3) {
      width: 20%;
      text-align: center;
    }
  }

  tr > * {
    height: 2em;
    :first-child {
      width: 10%;
    }

    :nth-child(2),
    :nth-child(3),
    :nth-child(4),
    :nth-child(5) {
      width: 20%;
      padding: 0.75rem 1.5rem;
      text-align: center;
    }

    :nth-child(6) {
      width: 5%;
      padding-right: 1rem;
      text-align: right;

      > i {
        cursor: pointer;
      }
    }
  }
`;

interface TableRowProps extends Props {
  $isClickable?: boolean;
}

export const StyledTableRow = styled.tr<TableRowProps>`
  cursor: ${({ $isClickable }: TableRowProps) => ($isClickable ? 'pointer' : 'default')};

  :hover {
    background: ${({ theme }: Props) => theme.palette.neutral._100};
  }
`;

export const StyledFontAwesomeIcon = styled(FontAwesomeIcon)<FontAwesomeIconProps>`
  cursor: pointer;
  color: ${({ color, theme }: FontAwesomeIconProps) => color ?? theme.palette.neutral.black};
  margin-left: 1rem;
`;
