import React, { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';

import { modalIds } from 'constants/modals-constants';
import { AvailableLanguage } from 'constants/language-constants';

import { useModal } from 'hooks/useModal';
import { useScheduledTests } from 'hooks/useScheduledTests';

import { ScheduledTestResultsReportSettings } from 'models/exam/ScheduledTest';

import { Button } from 'components/Button';
import { Checkbox } from 'components/Checkbox/Checkbox';
import { DatePicker } from 'components/DatePicker/DatePicker';

import { CalendarPosition } from 'components/DatePicker/DatePicker.model';

import { ReportSettingsModalProps } from './ReportSettingsModal.model';
import {
  StyledModal,
  StyledTitle,
  StyledSubtitle,
  StyledSection,
  StyledDateRangeForm,
} from './ReportSettingsModal.styled';

export const ReportSettingsModal: React.FC<ReportSettingsModalProps> = ({ scheduledTestId }) => {
  const { t, i18n } = useTranslation('teacher-dashboard');
  const { closeModal, isModalOpen } = useModal();

  const [useCustomDates, setUseCustomDates] = useState(false);
  const [reportSettings, setReportSettings] = useState<ScheduledTestResultsReportSettings>({});

  const { loading: isLoading, downloadScheduledTestResultsReport } = useScheduledTests();

  const onDownloadReportClick = () => {
    if (!useCustomDates) {
      downloadScheduledTestResultsReport(scheduledTestId);
    } else {
      downloadScheduledTestResultsReport(scheduledTestId, reportSettings);
    }
    closeModal();
  };

  const today = new Date();
  return (
    <StyledModal
      dataCy="scheduled-test-results-report-modal"
      isOpen={isModalOpen(modalIds.scheduledTestResultsReportModal)}
      onClose={closeModal}
    >
      <StyledTitle>{t('ownedScheduledTestResults.details.reportSettingsModal.title', 'Report settings')}</StyledTitle>
      <StyledSection>
        <StyledSubtitle>
          {t(
            'ownedScheduledTestResults.details.reportSettingsModal.cumulativeCalculation.title',
            'Cumulative calculation',
          )}
        </StyledSubtitle>
        <Trans i18nKey="ownedScheduledTestResults.details.reportSettingsModal.cumulativeCalculation.description" t={t}>
          <p>
            This test will be calculated cumulatively. All percentages previously achieved remain unchanged, with the
            highest percentage achieved for each component counting as the result.
          </p>
          <p>
            Fill in the fields if you want to use the cumulative scoring method with results achieved over a specific
            time period. If not, leave the fields blank.
          </p>
        </Trans>
      </StyledSection>
      <StyledSection>
        <StyledSubtitle>
          {t(
            'ownedScheduledTestResults.details.reportSettingsModal.cumulativeDatesForm.title',
            'Select the data you want to export',
          )}
        </StyledSubtitle>
        <StyledDateRangeForm>
          <label htmlFor="min-cumulative-date-picker">
            {t(
              'ownedScheduledTestResults.details.reportSettingsModal.cumulativeDatesForm.fields.cumulativeSinceDate',
              'Start',
            )}
          </label>
          <DatePicker
            dataCy="min-cumulative-date-picker"
            disabled={!useCustomDates}
            language={i18n.language as AvailableLanguage}
            maxDate={today}
            minDate={null}
            value={reportSettings.cumulativeSinceDate || null}
            onChange={date =>
              setReportSettings({
                ...reportSettings,
                cumulativeSinceDate: date,
              })
            }
          />
          <b>{t('ownedScheduledTestResults.details.reportSettingsModal.cumulativeDatesForm.until', 't/m')}</b>
          <label htmlFor="max-cumulative-date-picker">
            {t(
              'ownedScheduledTestResults.details.reportSettingsModal.cumulativeDatesForm.fields.cumulativeUntilDate',
              'End',
            )}
          </label>
          <DatePicker
            calendarPosition={CalendarPosition.BOTTOM_RIGHT}
            dataCy="max-cumulative-date-picker"
            disabled={!useCustomDates}
            language={i18n.language as AvailableLanguage}
            maxDate={today}
            minDate={null}
            value={reportSettings.cumulativeUntilDate || null}
            onChange={date =>
              setReportSettings({
                ...reportSettings,
                cumulativeUntilDate: date,
              })
            }
          />
        </StyledDateRangeForm>
        <Checkbox
          checked={!useCustomDates}
          dataCy="custom-cumulative-dates-checkbox"
          id="custom-cumulative-dates-checkbox"
          label={t(
            'ownedScheduledTestResults.details.reportSettingsModal.cumulativeDatesForm.fields.disableCustomRange',
            'Select all dates until current test start',
          )}
          onChange={() => setUseCustomDates(!useCustomDates)}
        />
      </StyledSection>
      <Button
        dataCy="download-cumulative-report-button"
        disabled={isLoading}
        isLoading={isLoading}
        variant="primary"
        onClick={onDownloadReportClick}
      >
        {t(
          'ownedScheduledTestResults.details.reportSettingsModal.cumulativeDatesForm.buttons.downloadReport.label',
          'Download results',
        )}
      </Button>
    </StyledModal>
  );
};
