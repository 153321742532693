import React from 'react';
import { modalIds } from 'constants/modals-constants';
import { useModal } from 'hooks/useModal';
import { Heading2 } from 'styles/elements/Headings';
import { useTranslation } from 'react-i18next';
import { Paragraph } from 'styles/elements/Texts';
import {
  StyledDialog,
  StyledInnerDialog,
} from 'pages/TeacherEnvironment/TeacherDashboard/subpages/ScheduledTestWizard/steps/TestOptionsStep/components/FormSEBListStep/components/SEBKeyUpsertDialog/SEBKeyUpsertDialog.styled';
import { Button } from 'components/Button';
import { StyledModal } from '../TransferResultsModal/TransferResultsModal.styled';

export interface DeleteAccountsModalProps {
  emails: string[];
  onSubmit: () => void;
}

export const DeleteAccountsModal: React.FC<DeleteAccountsModalProps> = ({ emails, onSubmit }) => {
  const { closeModal, isModalOpen } = useModal();
  const { t } = useTranslation('admin-environment');

  const isOpen = isModalOpen(modalIds.deleteAccountsModal);

  return (
    <StyledModal closeOnClickOutside dataCy="delete-accounts-modal" isOpen={isOpen} onClose={closeModal}>
      <StyledDialog>
        <StyledInnerDialog>
          <Heading2>{t('adminTools.deleteAccounts.modal.title')}</Heading2>
          <Paragraph>{t('adminTools.deleteAccounts.modal.description')}</Paragraph>
          <ul>
            {emails.map((email, index) => (
              <li key={email} data-cy={`modal-email-${index}`}>
                {email}
              </li>
            ))}
          </ul>
          <Button
            dataCy="submit-delete-accounts"
            variant="primary"
            onClick={() => {
              onSubmit();
              closeModal();
            }}
          >
            {t('adminTools.deleteAccounts.action')}
          </Button>
        </StyledInnerDialog>
      </StyledDialog>
    </StyledModal>
  );
};
