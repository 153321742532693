import React from 'react';
import { useReactTooltip } from 'hooks/useReactTooltip';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { InfoTooltipProps } from './InfoTooltip.model';
import { StyledInfoTooltip } from './InfoTooltip.styled';

export const InfoTooltip: React.FC<InfoTooltipProps> = ({
  className,
  tooltipContent = '',
  dataCy,
  tooltipPlace,
  color,
  onClick,
}) => {
  useReactTooltip();
  return (
    <StyledInfoTooltip
      className={className}
      color={color}
      data-cy={dataCy}
      data-place={tooltipPlace}
      data-tip={tooltipContent}
      onClick={onClick}
    >
      <InfoIcon fill={color} />
    </StyledInfoTooltip>
  );
};
