import { useContext } from 'react';
import { UsetifulContext } from 'contexts/UsetifulProvider/UsetifulProvider';

export interface UsetifulHookInterface {
  isUsetifulTourAPIAvailable: () => boolean;
  openTour: (tourId: number) => void;
}

export const useUsetiful = (): UsetifulHookInterface => {
  const usetifulAPI = useContext(UsetifulContext);

  const isUsetifulTourAPIAvailable = () => {
    return !!(usetifulAPI && usetifulAPI.tour);
  };

  const openTour = (tourId: number) => {
    usetifulAPI?.tour?.start(tourId);
  };

  return {
    openTour,
    isUsetifulTourAPIAvailable,
  };
};
