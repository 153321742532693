import styled from 'styled-components/macro';
import { ElearningThemeInterface } from 'themes/elearningInterface.theme';
import { calculateRem } from 'styles/mixins/calculateRem';
import { Button } from 'components/Button';
import { Heading4 } from 'styles/elements/Headings';
import { Input } from 'components/Input/Input';
import { SelectLanguage } from 'components/SelectLanguage';

interface Props {
  theme: ElearningThemeInterface;
}

export const StyledSideMenu = styled.div<Props>`
  position: relative;
  height: 100vh;
  background-color: ${(props: Props) => props.theme.palette.neutral.white};
  width: ${calculateRem(300)};
  padding: 2em 1.4rem;

  svg {
    cursor: pointer;
  }
`;
export const StyledMenuInputs = styled.div<Props>`
  margin-top: 2rem;

  .info-component {
    display: flex;
    flex-direction: column;
    div {
      margin: 0.5rem 0;
    }
  }
`;

export const StyledButtonCloseAndLogout = styled(Button)`
  display: block;
  margin-left: auto;
  font-weight: ${(props: Props) => props.theme.font.weight.bold};
  font-size: ${(props: Props) => props.theme.font.size._16};
`;

export const StyledSubmitButton = styled(Button)`
  margin: 2rem 0 2rem auto;
  display: block;
  font-weight: ${(props: Props) => props.theme.font.weight.bold};
`;

export const StyledTitle = styled.title`
  display: flex;
  column-gap: 1rem;
  align-items: center;

  svg {
    color: ${({ theme }: Props) => theme.palette.neutral._900};
  }
`;

export const StyledTitleHeading4 = styled(Heading4)`
  margin-bottom: 0;
`;

export const StyledInput = styled(Input)`
  height: 3rem;
  color: ${(props: Props) => props.theme.palette.neutral._900};
`;

export const StyledFieldSet = styled.div`
  margin-top: 1rem;
`;

export const StyledLanguageSelector = styled(SelectLanguage)`
  margin-top: 0;
`;
