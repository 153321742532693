import React, { useState } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';

import { signin, SigninInterface } from 'schemas/signin.schema';

import { Heading3, Heading4 } from 'styles/elements/Headings';
import { Paragraph } from 'styles/elements/Texts';
import getSchoolDomain from '_helpers/getSchoolDomain';
import {
  StyledButton,
  StyledField,
  StyledIcon,
  StyledInput,
  StyledInputContainer,
  StyledLink,
} from '../../MigrateAccessPage.styled';
import { StyledPasswordIcon, StyledPasswordInput } from './AccessForm.styled';

const defaultFormValues = { username: '', password: '' } as SigninInterface;

interface AccessFormProps {
  onSubmit: (accessPayload: SigninInterface) => void;
}

export const AccessForm: React.FC<AccessFormProps> = ({ onSubmit }) => {
  const { t } = useTranslation('auth-pages');
  const [showPassword, setShowPassword] = useState(false);

  const formik = useFormik({
    initialValues: defaultFormValues,
    validationSchema: signin({
      username: {
        requiredMessage: t('migrateAccountPage.credentialStep.fields.email.error.required'),
      },
      password: {
        requiredMessage: t('migrateAccountPage.credentialStep.fields.password.error.required'),
      },
    }),
    onSubmit: values => {
      onSubmit(values);
      formik.resetForm();
    },
  });

  return (
    <form noValidate data-cy="migration-credentials-form" onSubmit={formik.handleSubmit}>
      <Heading3>{t('migrateAccountPage.credentialStep.title')}</Heading3>
      <Paragraph>{t('migrateAccountPage.credentialStep.description')}</Paragraph>
      <StyledField>
        <Heading4>{t('migrateAccountPage.credentialStep.fields.email.label')}</Heading4>
        <StyledInputContainer>
          <StyledIcon iconName="fas fa-user" />
          <StyledInput
            dataCy="migrate-access-username"
            errorMessage={formik.errors.username}
            hasError={!!(formik.errors.username && formik.touched.username)}
            name="username"
            placeholder={t('migrateAccountPage.credentialStep.fields.email.placeholder')}
            type="text"
            value={formik.values.username}
            onChange={formik.handleChange}
          />
        </StyledInputContainer>
      </StyledField>
      <StyledField>
        <Heading4>{t('migrateAccountPage.credentialStep.fields.password.label')}</Heading4>
        <StyledInputContainer>
          <StyledIcon iconName="fas fa-lock" />
          <StyledPasswordInput
            dataCy="migrate-access-password"
            errorMessage={formik.errors.password}
            hasError={!!(formik.errors.password && formik.touched.password)}
            name="password"
            placeholder={t('migrateAccountPage.credentialStep.fields.password.placeholder')}
            type={showPassword ? 'text' : 'password'}
            value={formik.values.password}
            onChange={formik.handleChange}
          />
          <StyledPasswordIcon
            iconName={`fal ${!showPassword ? 'fa-eye' : 'fa-eye-slash'}`}
            onClick={() => setShowPassword(!showPassword)}
          />
        </StyledInputContainer>
        <StyledLink
          external
          isBlock
          data-cy="migrate-access-forgot-password-button"
          to={`https://${getSchoolDomain()}.htaalplatform.nl/cursist/forgot/`}
        >
          {t('migrateAccountPage.credentialStep.fields.password.forgot')}
        </StyledLink>
      </StyledField>
      <StyledButton
        isBlock
        dataCy="migrate-access-submit-button"
        disabled={formik.isSubmitting}
        type="submit"
        variant="primary"
      >
        {t('migrateAccountPage.credentialStep.button.continue')}
      </StyledButton>
    </form>
  );
};
