export interface CustomLabelProps {
  cx: string | number;
  cy: string | number;
  value: number;
  type: PROGRESS_TYPE;
  title: string;
}

export enum PROGRESS_TYPE {
  COMPLETED = 'completed',
  UNCOMPLETED = 'uncompleted',
}

export interface SubjectDoughnutChartProps {
  subjectDataForChart: SubjectDataForChart[][];
}

export interface SubjectDataForChart {
  subjectTitle: string;
  type: PROGRESS_TYPE;
  value: number;
  color: string;
}
