// These types are coming from => https://www.notion.so/Basic-tracking-acf5c6e9ea5d475681cca5ad1f326de9
export enum PAGE_TYPES {
  COURSE = 'course',
  DIAGNOSTIC_TEST = 'diagnostic-test',
  DIAGNOSTIC_TEST_DETAILS = 'diagnostic-test-details',
  DIAGNOSTIC_TEST_EXAM = 'diagnostic-test-exam',
  DIAGNOSTIC_TEST_LIST = 'diagnostic-test-list',
  CERTIFICATE = 'certificate',
  CREATE = 'create',
  UPDATE = 'update',
  OVERVIEW = 'overview',
  MONITOR = 'monitor',
  EXERCISE = 'exercise',
  EXERCISE_REVIEW = 'exercise-review',
  MONITOR_LIVE = 'monitor-live',
  DETAILS = 'details',
  INVITE = 'invite',
  INVITE_NEW = 'invite-new',
  INVITE_LIST = 'invite-list',
  LEARNER_DETAILS = 'learner-details',
  LOGS = 'logs',
  GROUP = 'group',
  GROUP_DETAILS = 'group-details',
  SETTINGS = 'settings',
  PRODUCT_LIST_PAGE = 'product-list-page',
  LOGIN = 'login',
  CONTENT_UNIT = 'content-unit',
  USERS = 'users',
  TOOLS = 'tools',
  RESULT = 'result',
  RESULT_CODE = 'result-code',
  AUTHORIZATION = 'authorization',
  DIAGNOSTIC_TEST_GROUPS = 'diagnostic-test-groups',
  WRITING_EXERCISE = 'writing-exercise',
  SCHEDULED_TESTS = 'scheduled-tests',
}

// Page categories
export enum PAGE_CATEGORIES {
  COURSE_OVERVIEW = 'course overview',
  RESULT = 'result',
  TEST = 'test',
  PLANNING = 'planning',
  TEACHER = 'teacher',
  HOME = 'home',
  ADMIN = 'admin',
  ACCOUNT = 'account',
  COURSE = 'course',
  CONTENT = 'content',
}

// Page modules
export enum STUDENT_MODULES {
  BASIC_PROGRESS = 'student-basic-progress',
  DASHBOARD = 'student-dashboard',
}

export enum TEACHER_MODULES {
  BASIC_PROGRESS_FOR_ACCOUNT = 'basic-progress-for-account',
  BASIC_PROGRESS = 'basic-progress',
}
