import styled from 'styled-components/macro';

import { ThemeProps } from 'models/styles/theme';
import { Paragraph } from 'styles/elements/Texts';
import { calculateRem } from 'styles/mixins/mixins';

import { IconButton } from 'components/IconButton/IconButton';
import { ProductFlag } from 'components/ProductFlag/ProductFlag';

export const StyledScheduledTestItem = styled.div<ThemeProps>`
  border: ${(props: ThemeProps) => `1px solid ${props.theme.palette.neutral._400}`};
  display: flex;
  flex-direction: column;
  margin-bottom: ${calculateRem(14)};
  padding: 2rem;
  padding-right: 5rem;
  position: relative;
`;

export const StyledCollapseButton = styled(IconButton)<ThemeProps>`
  color: ${(props: ThemeProps) => props.theme.palette.neutral.black};
  position: absolute;
  right: 2rem;
  top: 2rem;
`;

export const StyledProductFlag = styled(ProductFlag)`
  position: absolute;
  top: 0;
  left: 0;
  height: auto;
`;

export const StyledGeneralInformation = styled.div`
  display: flex;
  flex-grow: 1;
  row-gap: 1rem;
  flex-direction: column;
`;

export const StyledInfoRow = styled.div`
  display: flex;
  column-gap: 1rem;
  align-items: flex-start;
  justify-content: space-between;
`;

export const StyledMetadataContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  row-gap: 1rem;
  column-gap: 1rem;
  align-items: center;
  justify-content: flex-end;
`;

export const StyledTestName = styled(Paragraph)`
  text-align: left;
  line-height: 150%;
`;

const StyledMetadata = styled.div`
  display: flex;
  height: 2rem;
  align-items: center;
  font-size: ${calculateRem(14)};
  column-gap: ${calculateRem(10)};
`;

export const StyledTestLevel = styled(StyledMetadata)`
  width: 4rem;
`;

export const StyledDateMetadata = styled(StyledMetadata)`
  width: 7.5rem;
`;

export const StyledTimeMetadata = styled(StyledMetadata)`
  width: 9rem;
`;

export const StyledTestCode = styled(StyledMetadata)<ThemeProps>`
  width: fit-content;
  min-width: 6rem;
  column-gap: 0;
  color: ${(props: ThemeProps) => props.theme.palette.secondary.brand};

  > svg > path {
    fill: ${(props: ThemeProps) => props.theme.palette.secondary.brand};
  }
`;
