import React from 'react';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { Heading4 } from 'styles/elements/Headings';
import { redirectToIdpLogin } from '_helpers/utils/idp';
import { Button } from 'components/Button';
import { useDomainHandler } from 'hooks/useDomainHandler';
import { StyledInnerForm, StyledParagraph } from '../../InvitationPage.styled';
import { StyledButtonWrapper } from '../CreateAccount/CreateAccount.styled';
import { StyledLogo, StyledLogoWrapper } from './AccountExists.styled';

export const AccountExists: React.FC = () => {
  const { t } = useTranslation('auth-pages');
  const location = useLocation();
  const queryParamsPath = queryString.parse(location.search);
  const { getCustomNameByDomain, getCustomLogoByDomain } = useDomainHandler();

  return (
    <StyledInnerForm data-cy="account-exists-info">
      <Heading4>{t('invitationPage.acountExists.title')}</Heading4>
      <StyledParagraph>
        {t('invitationPage.acountExists.description', { appName: getCustomNameByDomain() })}
      </StyledParagraph>
      <StyledLogoWrapper>
        <StyledLogo iconName={getCustomLogoByDomain()} />
      </StyledLogoWrapper>
      <StyledButtonWrapper>
        <Button variant="primary" onClick={() => redirectToIdpLogin({ ...queryParamsPath, state: location.pathname })}>
          {t('invitationPage.acountExists.action')}
        </Button>
      </StyledButtonWrapper>
    </StyledInnerForm>
  );
};
