import React, { useMemo } from 'react';

import { ScheduledTestStudentLog } from 'models/log/Log';
import { StyledIpAddressList, StyledIpAddressListItem } from './IpAdressList.styled';

interface IpAddressListProps {
  studentLogs?: ScheduledTestStudentLog[] | null;
}

export const IpAddressList: React.FC<IpAddressListProps> = ({ studentLogs = [] }) => {
  const studentIpAddresses = useMemo(() => {
    if (!studentLogs) {
      return [];
    }

    // Obtain list of unique IPs for the user
    const uniqueIPs = new Set<string>(
      studentLogs.filter(({ ipAddress }) => ipAddress).map(({ ipAddress }) => ipAddress),
    );

    return Array.from(uniqueIPs);
  }, [studentLogs]);

  return (
    <StyledIpAddressList>
      {studentIpAddresses.map(ipAddress => (
        <StyledIpAddressListItem key={ipAddress} data-cy="student-unique-ip-address">
          {`IP: ${ipAddress}`}
        </StyledIpAddressListItem>
      ))}
    </StyledIpAddressList>
  );
};
