import img from 'assets/images/bg-sign-up-blue.jpg';
import { ElearningThemeInterface } from './elearningInterface.theme';

const blackColor = '#292F3B';
const whiteColor = '#FFFFFF';
export const hogesTheme: ElearningThemeInterface = {
  name: 'hoges',
  base: '16px',
  grid: 5,
  // colors -----------
  palette: {
    feedback: {
      // green
      success: {
        brand: '#5BDD87',
        _200: '#EDFFF3',
        _400: '#198038',
      },
      // red
      error: {
        brand: '#CA0000',
        _200: '#FFEBEB',
      },
      // yellow
      inProgress: {
        brand: '#FFD54F',
        _200: '#FFF7D8',
      },
    },
    // pink
    primary: {
      brand: '#E00B7E',
      _900: '#B70063',
      _400: '#F9CEE5',
      _200: '#FFE9F5',
    },
    // blue
    secondary: {
      brand: '#1285C7',
      _900: '#106AA7',
      _700: '#0d77b5',
      _400: '#A0CEE9',
      _300: '#c6e4fd',
      _200: '#F0F8FF',
    },
    // different blue
    secondaryNew: {
      brand: '#9ABCE4',
      _200: '#a6b5d7',
    },
    tertiary: {
      brand: '#F5EBC8',
      _200: '#AA7A1E',
    },
    text: {
      primary: whiteColor,
      secondary: blackColor,
    },
    // gray
    neutral: {
      black: blackColor,
      _1000: '#454545',
      _900: '#757575',
      _600: '#ACACAC',
      _400: '#DADADA',
      _200: '#F2F2F2',
      _100: '#F9F9F9',
      _20: '#CEDBF8',
      white: whiteColor,
      light: '#1A1A1A',
      light_02: '#EBF1F4',
    },
    accent: {
      _01: '#FCF9F4',
      _02: '#FFA4A2',
      _03: '#EE98FB',
      _04: '#399D92',
      _05: '#F09E00',
      _06: '#FFF7D8',
      _07: '#EEF7FF',
    },
    shadow: {
      _06: '0px 2px 6px rgba(0, 0, 0, 0.218231)',
      _11: '0px 3px 11px rgba(0, 0, 0, 0.3)',
    },
  },

  // Typography -----------

  font: {
    family: {
      main: 'Inter, Helvetica, Arial, sans-serif',
      secondary: 'Px Grotesk, Helvetica, Arial, sans-serif',
    },
    weight: {
      light: 300,
      normal: 400,
      medium: 500,
      bold: 700,
      bolder: 800,
    },
    size: {
      _10: '0.625rem', // 10px,
      _12: '0.75rem', // 12px,
      _14: '0.875rem', // 14px,
      _16: '1rem', // 16px base
      _18: '1.125rem', // 18px
      _24: '1.5rem', // 24px
      _32: '2rem', // 32px
      _48: '3rem', // 48px
      _64: '4rem', // 64px
    },
    iconSize: {
      _5: 0.3125, // 5px,
      _10: 0.625, // 10px,
      _15: 0.9375, // 15px,
      _20: 1.25, // 20px
      _30: 1.875, // 30px
      _40: 2.5, // 40px
      _50: 3.125, // 50px
      _60: 3.75, // 60px
      _70: 4.5,
    },
  },

  // Responsive -----------
  breakpoints: {
    mobile: '425px',
    tablet: '768px',
    laptop: '1024px',
    laptopL: '1440px',
  },

  // Border radius -----------
  borderRadius: {
    rounded: '.25rem',
    roundedPill: '50rem',
  },

  pageLayout: {
    bottomRowHeight: '200px',
    topRowHeight: '5rem',
    numberOfColumns: 12,
    columnWidth: '1fr',
  },

  // Background images
  backgroundPages: {
    loginValidate: img,
  },

  // Margins and paddings ----------
  margin: (value: number) => `${([0, 0.5, 1, 1.5, 2][value] ?? 3).toString()}rem`,
  padding: (value: number) => `${([0, 0.5, 1, 1.5, 2][value] ?? 3).toString()}rem`,
  space: ['0', '0.5rem', '1rem', '1.5rem', '2rem', '3rem'], // shorthand used by styled-system props
};
