import { ZendeskSettings } from '../../models/ZendeskWidget.model';

// Default options that will be shared across all variants (can be overriden if needed)
export const commonWidgetOptions: ZendeskSettings = {
  webWidget: {
    helpCenter: {
      title: { '*': 'Helpdesk' },
      messageButton: { '*': 'Neem contact op' },
      searchPlaceholder: { '*': 'Zoek in de kennisbank' },
    },
    contactForm: {
      title: { '*': 'Stuur ons een bericht' },
      selectTicketForm: { '*': 'Selecteer een onderwerp:' },
    },
    position: {
      horizontal: 'left',
      vertical: 'bottom',
    },
    offset: {
      vertical: '70px',
    },
  },
};
