import styled from 'styled-components/macro';
import { ThemeProps as Props } from 'models/styles/theme';
import { FontAwesomeIcon } from 'components/FontAwesomeIcon';

export interface TableRowProps extends Props {
  isHighlighted?: boolean;
}

export interface FontAwesomeProps extends Props {
  finished?: boolean;
}

export const StyledWrapper = styled.div<Props>`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;

  > div {
    :first-child {
      width: 30%;
      height: 10rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    :last-child {
      width: 68%;
      height: 10rem;
    }
  }
`;

export const StyledTableRow = styled.div<TableRowProps>`
  background: ${({ theme, isHighlighted }: TableRowProps) =>
    isHighlighted ? theme.palette.neutral._100 : theme.palette.neutral.white};
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.5rem;
`;

export const StyledFontAwesomeIcon = styled(FontAwesomeIcon)<FontAwesomeProps>`
  color: ${({ theme, finished }: FontAwesomeProps) =>
    finished ? theme.palette.feedback.success.brand : theme.palette.neutral._900};
`;
