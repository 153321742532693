import React from 'react';
import { useTranslation } from 'react-i18next';

import { modalIds } from 'constants/modals-constants';
import { useModal } from 'hooks/useModal';
import { Modal } from 'components/Modal';
import { StyledHeading, StyledParagraph, StyledButton } from './FinishTestModal.styled';
import { FinishTestModalProps } from './FinishTestModal.model';

const MyFinishTestModal: React.FC<FinishTestModalProps> = ({ onConfirm }) => {
  const { t } = useTranslation('test-environment');
  const { closeModal, isModalOpen } = useModal();
  const finishTestModalId = modalIds.finishTest;

  return (
    <Modal
      wrapInModalBox
      dataCy={finishTestModalId}
      isOpen={isModalOpen(finishTestModalId)}
      showCloseButton={false}
      onClose={closeModal}
    >
      <StyledHeading>{t('finishTestModal.title', 'Are you sure?')}</StyledHeading>
      <StyledParagraph>
        {t(
          'finishTestModal.description',
          'Have you completed all the subjects in the test? The test will be submitted and you will no longer be able to change anything.',
        )}
      </StyledParagraph>
      <div>
        <StyledButton dataCy="finish-test-modal-no-button" variant="secondary" onClick={closeModal}>
          {t('finishTestModal.cancel', 'Cancel')}
        </StyledButton>
        <StyledButton dataCy="finish-test-modal-yes-button" variant="primary" onClick={onConfirm}>
          {t('finishTestModal.finishTest', 'Yes, I am sure')}
        </StyledButton>
      </div>
    </Modal>
  );
};

export const FinishTestModal = React.memo(MyFinishTestModal);
