import styled from 'styled-components/macro';
import { ThemeProps as Props } from 'models/styles/theme';

import { Button } from 'components/Button';

export const StyledButton = styled(Button)<Props>`
  line-height: 1.5;
  padding: 0.5rem 1rem;
  font-weight: ${(props: Props) => props.theme.font.weight.normal};

  .content {
    display: flex;
    column-gap: 0.5rem;
    align-items: center;
    justify-content: center;
  }
`;
