import styled, { css } from 'styled-components/macro';

import { SvgIconReact } from 'components/SvgIconReact';
import { ThemeProps } from 'models/styles/theme';

export const GroupCardWrapper = styled.div<{ $isActive?: boolean }>`
  position: relative;
  padding-bottom: 58px;
  width: 100%;
  box-shadow: 0px 1px 5px rgba(17, 17, 17, 0.1), 0px 3px 1px rgba(17, 17, 17, 0.06), 0px 2px 2px rgba(17, 17, 17, 0.07);
  border-radius: 4px;
  background-color: ${(props: ThemeProps) => props.theme.palette.neutral.white};
  cursor: pointer;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    opacity: 0;
    border-radius: 4px;
    box-shadow: 0px 3px 11px rgba(0, 0, 0, 0.3);
    transition: opacity 0.2s ease-in-out;
  }

  &:hover:after {
    opacity: 1;
  }
  &:active:after {
    opacity: 0;
  }
  &:active {
    box-shadow: 0px 1px 5px rgba(17, 17, 17, 0.05), 0px 3px 1px rgba(17, 17, 17, 0.03),
      0px 2px 2px rgba(17, 17, 17, 0.05);
  }

  ${({ $isActive }) =>
    $isActive &&
    css`
      background-color: ${(props: ThemeProps) => props.theme.palette.neutral._200};
      box-shadow: none;
    `}
`;

export const GroupCardHeader = styled.div`
  padding: 1rem;
  padding-right: 2.6rem;

  > a {
    color: ${(props: ThemeProps) => props.theme.palette.neutral.black};
    text-decoration: none;
  }
`;

export const GroupCardAction = styled.div`
  position: absolute;
  top: 10px;
  right: 14px;

  > button {
    padding: 0.2rem;
  }

  > button:active {
    position: relative;
    top: 1px;
  }
`;

export const GroupCardStudentsIcon = styled(SvgIconReact)`
  margin-right: 0.5rem;
`;

export const GroupCardFooter = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 1rem;
  border-top: 1px solid ${({ theme }: ThemeProps) => theme.palette.neutral._400};
`;
