/* eslint-disable @typescript-eslint/no-unsafe-return */
import axios, { AxiosError } from 'axios';
import { ERROR_TYPES } from 'constants/error-constants';
import {
  OverviewQuestionAnswer,
  OverviewQuestionResult,
  SubjectResponse,
  TopicExerciseStatus,
  TopicsStatus,
  WritingSubjectStatus,
} from 'models/writing/WritingDetail';
import cookieStorage from 'persistence';
import { handlingResponse, logError } from 'repositories/utils';
import { RequestError, ApiRequestError as Error } from 'models/error/Error';
import { ResultsAPI } from 'generated/types';
import { OverviewFilters, SubjectsProgressOverviewFilters } from 'models/progress/Progress';
import { Exercise, ExerciseStatus } from 'models/exam/Exam';

const getWritingDetail = async (productId: number, moduleId: number, subject: number): Promise<SubjectResponse> => {
  try {
    const response = await axios.get(`/api/products/${productId}/modules/${moduleId}/subjects/${subject}`, {
      headers: { Authorization: cookieStorage.getToken() },
    });
    return handlingResponse<SubjectResponse>([200], 'Error getting writing detail')(response);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<Error>);
    throw new RequestError({ message, status, type: ERROR_TYPES.GET_SUBJECT_DETAILS_FAILED });
  }
};

export const fetchExerciseStatus = async (exerciseId: number, testToken?: string): Promise<ExerciseStatus[]> => {
  try {
    const response = await axios.get(`/api/writing/exercises/${exerciseId}/status`, {
      headers: testToken
        ? {
            test_token: testToken,
            Authorization: cookieStorage.getToken(),
          }
        : {
            Authorization: cookieStorage.getToken(),
          },
    });
    return handlingResponse<ExerciseStatus[]>([200, 204], 'Error checking exercise status')(response);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<Error>);
    throw new RequestError({ message, status, type: ERROR_TYPES.GET_WRITING_SUBJECTS_STATUS_FAILED });
  }
};

// Get status of the writing topics on a given subject (for student environment)
const getTopicStatus = async (productId: number, subjectId: number): Promise<TopicsStatus[]> => {
  try {
    const response = await axios.get(`/api/writing/products/${productId}/subjects/${subjectId}/status`, {
      headers: { Authorization: cookieStorage.getToken() },
    });
    return handlingResponse<TopicsStatus[]>([200], 'Error getting writing detail')(response);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<Error>);
    throw new RequestError({ message, status, type: ERROR_TYPES.GET_TOPICS_STATUS_FAILED });
  }
};

// Get status of the exercises on a given topic (for student environment)
const getTopicExercisesStatus = async (productId: number, topicId: number): Promise<TopicExerciseStatus[]> => {
  try {
    const response = await axios.get(`/api/writing/products/${productId}/topics/${topicId}/status`, {
      headers: { Authorization: cookieStorage.getToken() },
    });
    return handlingResponse<TopicExerciseStatus[]>(
      [200],
      'Error getting the status of the exercises in the topic',
    )(response);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<Error>);
    throw new RequestError({ message, status, type: ERROR_TYPES.GET_TOPIC_EXERCISES_STATUS_FAILED });
  }
};

const getWritingSubjectsStatus = async (productId: number, moduleId: number): Promise<WritingSubjectStatus[]> => {
  try {
    const response = await axios.get(`/api/writing/products/${productId}/modules/${moduleId}/status`, {
      headers: { Authorization: cookieStorage.getToken() },
    });
    return handlingResponse<WritingSubjectStatus[]>(
      [200],
      'Error getting the status of the writing subjects',
    )(response);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<Error>);
    throw new RequestError({ message, status, type: ERROR_TYPES.GET_WRITING_SUBJECTS_STATUS_FAILED });
  }
};

const getOverviewQuestionResults = async (
  productId: number,
  questionId: number,
  testSessionToken: string,
): Promise<OverviewQuestionResult[]> => {
  try {
    const response = await axios.get(`/api/product/${productId}/writing/overview-question/${questionId}/answers`, {
      headers: {
        Authorization: cookieStorage.getToken(),
        test_session_token: testSessionToken,
      },
    });
    return handlingResponse<OverviewQuestionResult[]>([200], 'Error getting the overview question result')(response);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<Error>);
    throw new RequestError({ message, status, type: ERROR_TYPES.GET_OVERVIEW_QUESTION_RESULT_FAILED });
  }
};

const fetchGeneratedReport = async (
  productId: number,
  { educationId, schoolYearStart, schoolYearEnd, searchTerm }: OverviewFilters,
): Promise<ResultsAPI.WritingGeneratedReport> => {
  try {
    const response = await axios.get(`/api/writing/product/${productId}/generate-report`, {
      headers: { Authorization: cookieStorage.getToken() },
      params: {
        educationId,
        schoolYearStart,
        schoolYearEnd,
        searchTerm,
      },
    });
    return handlingResponse<ResultsAPI.WritingGeneratedReport>([200], 'Error getting generated report')(response);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<Error>);
    throw new RequestError({ message, status, type: ERROR_TYPES.GET_GENERATED_WRITING_REPORT_FAILED });
  }
};

// Submit answers for questions linked to the specified collection / overview question
const updateOverviewQuestionAnswers = async (
  productId: number,
  exerciseId: number,
  overviewQuestionId: number,
  linkedQuestionAnswers: OverviewQuestionAnswer,
  testSessionToken: string,
): Promise<void> => {
  try {
    await axios.put(
      `/api/product/${productId}/writing/${exerciseId}/progressive-collection/${overviewQuestionId}/result`,
      linkedQuestionAnswers,
      {
        headers: {
          Authorization: cookieStorage.getToken(),
          test_session_token: testSessionToken,
        },
      },
    );
  } catch (error) {
    const { message, status } = logError(error as AxiosError<Error>);
    throw new RequestError({ message, status, type: ERROR_TYPES.UPDATE_OVERVIEW_QUESTION_RESULT_FAILED });
  }
};

// Fetch the overview of all the writing subjects progress
const getwritingSubjectsResultsOverview = async (
  productId: number,
  { educationId, schoolYearStart, schoolYearEnd, searchTerm, groupId }: SubjectsProgressOverviewFilters,
): Promise<ResultsAPI.ProductWritingSubjects> => {
  try {
    const response = await axios.get(`/api/product/${productId}/writing-results/subjects-overview`, {
      headers: { Authorization: cookieStorage.getToken() },
      params: {
        educationId,
        schoolYearStart,
        schoolYearEnd,
        searchTerm,
        groupId,
      },
    });
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return handlingResponse<ResultsAPI.ProductWritingSubjects>(
      [200],
      'Error getting writing subjects progress overview',
    )(response);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<Error>);
    throw new RequestError({ message, status, type: ERROR_TYPES.GET_WRITING_SUBJECTS_RESULTS_OVERVIEW_FAILED });
  }
};

// fetch the overview of all the writing students progress
const getwritingStudentsResultsOverview = async (
  productId: number,
  { educationId, schoolYearStart, schoolYearEnd, searchTerm, page, resultsPerPage, groupId }: OverviewFilters,
): Promise<ResultsAPI.WritingStudentsDetails[]> => {
  try {
    const response = await axios.get(`/api/product/${productId}/writing-results/students-overview`, {
      headers: { Authorization: cookieStorage.getToken() },
      params: {
        educationId,
        schoolYearStart,
        schoolYearEnd,
        searchTerm,
        page,
        resultsPerPage,
        groupId,
      },
    });
    return handlingResponse<ResultsAPI.WritingStudentsDetails[]>(
      [200],
      'Error getting writing students progress overview',
    )(response);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<Error>);
    throw new RequestError({ message, status, type: ERROR_TYPES.GET_WRITING_ACCOUNTS_RESULTS_OVERVIEW_FAILED });
  }
};

// Retrieve an overview of the writing progress made by a specific student (for teacher dashboard)
const getWritingResultsForAccount = async (
  productId: number,
  accountId: number,
): Promise<ResultsAPI.WritingResultsForAccount> => {
  try {
    const response = await axios.get(`/api/writing/products/${productId}/accounts/${accountId}`, {
      headers: { Authorization: cookieStorage.getToken() },
    });
    return handlingResponse<ResultsAPI.WritingResultsForAccount>(
      [200],
      'Error getting writing progress overview for the selected account',
    )(response);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<Error>);
    throw new RequestError({ message, status, type: ERROR_TYPES.GET_WRITING_RESULTS_FOR_ACCOUNT_FAILED });
  }
};

const getWritingSubjectStudentsDetails = async (
  productId: number,
  subjectId: number,
  { educationId, schoolYearStart, schoolYearEnd, searchTerm }: OverviewFilters,
): Promise<ResultsAPI.WritingSubjectStudents> => {
  try {
    const response = await axios.get(`/api/writing/product/${productId}/subjects/${subjectId}/students`, {
      headers: { Authorization: cookieStorage.getToken() },
      params: {
        educationId,
        schoolYearStart,
        schoolYearEnd,
        searchTerm,
      },
    });
    return handlingResponse<ResultsAPI.WritingSubjectStudents>(
      [200],
      'Error getting writing subject students details',
    )(response);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<Error>);
    throw new RequestError({ message, status, type: ERROR_TYPES.GET_WRITING_SUBJECT_STUDENTS_DETAILS });
  }
};

const fetchWritingExercise = async (productId: number, exerciseId: number): Promise<Exercise> => {
  try {
    const result = await axios.get(`/api/products/${productId}/exercises/${exerciseId}`, {
      headers: { Authorization: cookieStorage.getToken() },
    });
    return handlingResponse<Exercise>([200], 'Error retrieving test', 'exerciseExamError')(result);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<Error>);
    throw new RequestError({ message, status, type: ERROR_TYPES.GET_EXERCISE_FAILED });
  }
};

export {
  getWritingDetail,
  getTopicStatus,
  getTopicExercisesStatus,
  getWritingSubjectsStatus,
  getOverviewQuestionResults,
  updateOverviewQuestionAnswers,
  fetchGeneratedReport,
  getwritingSubjectsResultsOverview,
  getwritingStudentsResultsOverview,
  getWritingResultsForAccount,
  getWritingSubjectStudentsDetails,
  fetchWritingExercise,
};
