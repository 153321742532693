import { ThemeProps } from 'models/styles/theme';
import styled from 'styled-components/macro';
import { Box } from 'styles/helpers/layout';

export const StyledStatusTag = styled(Box)<ThemeProps>`
  border-radius: 15px;
  font-family: ${({ theme }: ThemeProps) => theme.font.family.main};
  font-size: ${({ theme }: ThemeProps) => theme.font.size._12};
  font-weight: ${({ theme }: ThemeProps) => theme.font.weight.bold};
  height: fit-content;
  padding: 0.25rem ${({ theme }: ThemeProps) => theme.padding(1)};
  text-align: center;
  width: 100px;
`;
