import axios, { AxiosError } from 'axios';
import { Login } from 'models/auth/Login';
import { redirectToIdpLogout } from '_helpers/utils/idp';
import { clearCookiesLogout, clearUsetifulUserDataOnLogout } from 'persistence/access';
import { handlingResponse, logError } from 'repositories/utils';
import { RequestError, ApiRequestError as Error } from 'models/error/Error';

const validateLoginUser = async (code = ''): Promise<Login> => {
  try {
    const result = await axios.post('/api/validate', { code });
    return handlingResponse<Login>([200], 'Error validating code')(result);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<Error>);
    throw new RequestError({ message, status, type: 'validateUserLoginFailed' });
  }
};

const logout = (): void => {
  clearCookiesLogout();
  clearUsetifulUserDataOnLogout();
  redirectToIdpLogout();
};

export { validateLoginUser, logout };
