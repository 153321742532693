import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Heading4 } from 'styles/elements/Headings';
import { SvgIconReact } from 'components/SvgIconReact';
import { FontAwesomeIcon } from 'components/FontAwesomeIcon';
import { DatePicker } from 'components/DatePicker/DatePicker';
import { AvailableLanguage } from 'constants/language-constants';
import { Link } from 'react-router-dom';
import { ZENDESK_ROUTES } from 'constants/routes';
import { TimePicker } from 'components/TimePicker/TimePicker';
import { add, addDays, isSameDay } from 'date-fns';
import { IconButton } from 'components/IconButton/IconButton';
import { Checkbox } from 'components/Checkbox/Checkbox';
import { InfoTooltip } from 'components/InfoTooltip/InfoTooltip';
import { ScheduledTestDate } from 'models/exam/ScheduledTest';
import { useFormUpdate } from 'pages/TeacherEnvironment/TeacherDashboard/subpages/ScheduledTestWizard/hooks/useFormUpdate';
import { FormErrorMessage } from 'pages/TeacherEnvironment/TeacherDashboard/subpages/ScheduledTestWizard/components/FormErrorMessage/FormErrorMessage';

import { useDomainHandler } from 'hooks/useDomainHandler';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { useLanguage } from 'hooks/useSelectedLanguage';

import {
  AddMomentButton,
  CustomReviewDateWrapper,
  DatesRow,
  DatesRowAddColumn,
  DatesRowColumn,
  ExtraTimeWrapper,
  TestDurationInput,
  InlineInfoTooltip,
  StudentCountRowColumn,
  DateRowContainer,
  StyledRecommendedDuration,
} from './TestDatesStep.styled';
import {
  FormH4Label,
  FormInput,
  FormStep,
  FormStepHeader,
  FormStepHeading,
  FormStepIntro,
  StyledFontAwesomeIcon,
  StyledParagraph,
  StyledTeacherWarning,
} from '../FormStep.styled';
import { createValidationSchema } from './TestDatesStep.validation';
import { TestDatesStepFormValues, TestDatesStepProps } from './TestDatesStep.model';
import { dateToTimePickerString, getLatestTestInstance, updateDateTime } from './utils';
import { CustomReviewDate } from './components/CustomReviewDate/CustomReviewDate';

export const TestDatesStep: React.FC<TestDatesStepProps> = ({
  formValues,
  formErrors,
  isEditingScheduledTest = false,
  isDisabled,
  onFormUpdate,
}) => {
  const { t, i18n } = useTranslation('scheduled-tests');
  const { showSupportUnavailabilityNotice, supportUnavailabilityNoticeMessage } = useFeatureFlags();
  const { currentLanguage } = useLanguage();
  const { getStudentTermByDomain } = useDomainHandler();

  const supportUnavailableMessage =
    showSupportUnavailabilityNotice && JSON.parse(supportUnavailabilityNoticeMessage)[currentLanguage];

  const [isInfoMessageOpen, setIsInfoMessageOpen] = useState(true);
  const [values, setValues] = useState<TestDatesStepFormValues>(
    (({
      schedule = [],
      extraTime = null,
      useCustomReviewDate = false,
      reviewStartDate = null,
      reviewEndDate = null,
      estimatedStudents,
    }) => ({
      schedule,
      extraTime,
      useCustomReviewDate,
      reviewStartDate,
      reviewEndDate,
      estimatedStudents,
    }))(formValues) as TestDatesStepFormValues,
  );

  useEffect(() => {
    const { schedule, reviewStartDate, reviewEndDate } = values;
    if (schedule && reviewStartDate && reviewEndDate) {
      const endDate = add(schedule[0].startDate, { minutes: schedule[0].duration });
      if (
        endDate.toISOString() === reviewStartDate?.toISOString() &&
        add(reviewStartDate, { days: 1 }).toISOString() === reviewEndDate?.toISOString()
      ) {
        setValues({
          ...values,
          useCustomReviewDate: false,
        });
      }
    }
  }, []);

  const validationSchema = createValidationSchema(isEditingScheduledTest);
  useFormUpdate<typeof validationSchema>({
    values,
    validationSchema,
    onFormUpdate,
  });

  const addRemoveDatesEnabled = !isEditingScheduledTest;

  const addEmptyDate = (copyLast = false) => {
    const dummyDate =
      copyLast && values.schedule.length > 0
        ? values.schedule[values.schedule.length - 1]
        : {
            startDate: addDays(new Date(), 1),
            duration: 60,
            estimatedStudents: 0,
          };
    setValues({
      ...values,
      schedule: [...values.schedule, dummyDate],
    });
  };

  useEffect(() => {
    if (values.schedule.length === 0) {
      // always make sure there is one (empty) test date row
      addEmptyDate();
    }
  }, [values]);

  const onUpdateCustomReviewDate =
    (propName: 'reviewStartDate' | 'reviewEndDate') =>
    ({ date, hours, minutes }: { date?: Date; hours?: number; minutes?: number }) => {
      const currentValue = values[propName] || new Date();

      const newValue = updateDateTime(
        date ?? currentValue,
        hours ?? currentValue.getHours(),
        minutes ?? currentValue.getMinutes(),
      );

      const newValues = {
        ...values,
        [propName]: newValue,
      };

      // If we're updating the review period start date, check
      // potentially also update the end date if the start date
      // has been moved past the end date
      if (propName === 'reviewStartDate' && values['reviewEndDate']) {
        if (newValue > values['reviewEndDate']) {
          newValues.reviewEndDate = addDays(newValue, 1);
        }
      }

      setValues(newValues);
    };

  const onAddDateRowClick = (event: React.MouseEvent) => {
    event.preventDefault();
    // copy the last date to the new row,
    // because it's likely that it's on or around the same date
    // with a different time
    addEmptyDate(true);
  };

  const onRemoveDateRowClick = (event: React.MouseEvent, index: number) => {
    event.preventDefault();
    // 'remove' date (but actually keep it so we can give each date a unique key)
    setValues({
      ...values,
      schedule: values.schedule.filter((_, i) => i !== index),
    });
  };

  const updateRowDateValue = (index: number, propName: keyof ScheduledTestDate, value: Date | number) => {
    setValues(previous => {
      const newSchedule = previous.schedule.map((date, i) => {
        if (i === index) {
          return {
            ...date,
            [propName]: value,
          };
        }
        return date;
      });

      const latestTestInstance = getLatestTestInstance(newSchedule);

      return {
        ...previous,
        schedule: newSchedule,
        reviewStartDate: latestTestInstance,
        reviewEndDate: addDays(latestTestInstance, 1),
      };
    });
  };

  const onRowDateChange = (date: Date, index: number) => {
    // update date (but not time!)
    const currentDate = values.schedule[index]?.startDate;
    if (!currentDate || isSameDay(currentDate, date)) {
      // not actually changed, skip
      return;
    }
    const newDate = updateDateTime(date, currentDate.getHours(), currentDate.getMinutes());
    updateRowDateValue(index, 'startDate', newDate);
  };

  const onRowTimeChange = (hours: number, minutes: number, index: number) => {
    // update the current date at this index, but time only
    const currentDate = values.schedule[index]?.startDate;
    if (!currentDate || (currentDate.getHours() === hours && currentDate.getMinutes() === minutes)) {
      // not actually changed, skip
      return;
    }
    updateRowDateValue(index, 'startDate', updateDateTime(currentDate, hours, minutes));
  };

  const onRowDurationChange = (event: React.ChangeEvent<HTMLInputElement>, key: number) => {
    updateRowDateValue(key, 'duration', parseInt(event.target.value, 10) || 1);
  };

  const onRowEstimatedStudentsChange = (event: React.ChangeEvent<HTMLInputElement>, key: number) => {
    updateRowDateValue(key, 'estimatedStudents', parseInt(event.target.value, 10));
  };

  const onUseCustomReviewDateChange = () => {
    setValues(previous => {
      const enableCustomReviewDates = !values.useCustomReviewDate;
      const latestTestInstance = getLatestTestInstance(previous.schedule);

      // The review start and end times are always a date, but just not shown when useCustomReviewDate is false
      return {
        ...previous,
        useCustomReviewDate: enableCustomReviewDates,
        reviewStartDate: latestTestInstance,
        reviewEndDate: addDays(latestTestInstance, 1),
      };
    });
  };

  const onToggleExtraTime = () => {
    setValues({ ...values, extraTime: values.extraTime === null ? 20 : null });
  };

  const onExtraTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      extraTime: parseInt(event.target.value, 10),
    });
  };

  return (
    <FormStep>
      <FormStepHeader>
        <FormStepHeading>{t('testDateStep.header', 'Datum en tijd  - stap 3')}</FormStepHeading>
        <FormStepIntro>
          {t('testDateStep.intro', { studentTerm: getStudentTermByDomain({ usePlural: true }) })}
        </FormStepIntro>
        <StyledTeacherWarning data-cy="teacher-warning-message">
          <div>
            <div>
              <FontAwesomeIcon iconName="fal fa-info-circle" iconSize="2x" />
              <Heading4>{t('testDateStep.warning.title')}</Heading4>
            </div>
            <StyledFontAwesomeIcon
              iconName={isInfoMessageOpen ? 'fas fa-minus' : 'fas fa-plus'}
              onClick={() => setIsInfoMessageOpen(!isInfoMessageOpen)}
            />
          </div>
          {isInfoMessageOpen && (
            <>
              <StyledParagraph>{t('testDateStep.warning.intro')}</StyledParagraph>
              <StyledParagraph>{t('testDateStep.warning.description')}</StyledParagraph>
              {supportUnavailableMessage && (
                <StyledParagraph data-cy="unavailability-notice">
                  <b>{supportUnavailableMessage}</b>
                </StyledParagraph>
              )}
            </>
          )}
        </StyledTeacherWarning>
      </FormStepHeader>
      <div>
        <div>
          <DateRowContainer>
            {React.Children.toArray(
              values.schedule.map((item, index) => {
                // get actual index of this date item in values.schedule,
                // because that is the array we are validating with Yup
                const startDateError = formErrors[`schedule[${index}].startDate`];
                const durationError = formErrors[`schedule[${index}].duration`];
                return (
                  <DatesRow key={`row-${index}`} $hasIconButton={addRemoveDatesEnabled} data-cy="test-dates-row">
                    <DatesRowColumn>
                      <FormH4Label>{t('testDateStep.dateInput.label', 'Date of the test')}</FormH4Label>
                      <DatePicker
                        dataCy="start-date-picker"
                        disabled={isDisabled}
                        hasError={!!startDateError}
                        language={i18n.language as AvailableLanguage}
                        minDate={!isEditingScheduledTest ? new Date() : null}
                        value={item.startDate}
                        onChange={date => onRowDateChange(date, index)}
                      />
                      <FormErrorMessage errors={startDateError?.errors} />
                    </DatesRowColumn>
                    <DatesRowColumn>
                      <FormH4Label>{t('testDateStep.timeInput.label', 'Start time of the test')}</FormH4Label>
                      <TimePicker
                        dataCy="start-time-picker"
                        disabled={isDisabled}
                        hasError={!!startDateError}
                        language={i18n.language as AvailableLanguage}
                        value={dateToTimePickerString(item.startDate)}
                        onChange={(hours, minutes) => onRowTimeChange(hours, minutes, index)}
                      />
                    </DatesRowColumn>
                    <DatesRowColumn>
                      <FormH4Label>{t('testDateStep.durationInput.label', 'Duration of the test')}</FormH4Label>
                      <TestDurationInput
                        disabled={isDisabled}
                        hasError={!!durationError}
                        min="1"
                        postfixLabel={t('testDateStep.durationInput.postfix.label', 'minutes')}
                        step="1"
                        style={{ lineHeight: '1.5rem' }}
                        type="number"
                        value={item.duration}
                        onChange={event => onRowDurationChange(event, index)}
                      />
                      <StyledRecommendedDuration>
                        <span>
                          <Link target="_blank" to={{ pathname: ZENDESK_ROUTES.RECOMMENDED_TEST_DURATION }}>
                            {t('testDateStep.durationInput.extra', 'What is the recommended timespan?')}
                          </Link>
                          <FontAwesomeIcon iconName="fas fa-external-link-alt" />
                        </span>
                      </StyledRecommendedDuration>
                      <FormErrorMessage errors={durationError?.errors} />
                    </DatesRowColumn>
                    {addRemoveDatesEnabled && (
                      <DatesRowAddColumn>
                        {!(index === values.schedule.length - 1) && (
                          <IconButton
                            dataCy="test-dates-remove-date-button"
                            disabled={isDisabled}
                            iconName="trashIcon"
                            variant="secondary"
                            onClick={event => onRemoveDateRowClick(event, index)}
                          />
                        )}
                      </DatesRowAddColumn>
                    )}
                    <StudentCountRowColumn>
                      <FormH4Label>
                        {t('testDateStep.estimatedStudents.label', {
                          studentTerm: getStudentTermByDomain({ usePlural: true }),
                        })}
                        <InlineInfoTooltip
                          tooltipContent={t(
                            'testDateStep.estimatedStudents.infoTooltip',
                            "We'll use this to how many members of our support team you may need during the test.",
                          )}
                        />
                      </FormH4Label>
                      <FormInput
                        dataCy="test-request-estimated-students-input"
                        disabled={isDisabled}
                        hasError={!!formErrors.estimatedStudents}
                        min="1"
                        placeholder={t('testDateStep.estimatedStudents.placeholder', 'e.g. 12')}
                        type="number"
                        value={Number.isNaN(item.estimatedStudents) ? '' : item.estimatedStudents}
                        onChange={event => onRowEstimatedStudentsChange(event, index)}
                      />
                      <FormErrorMessage
                        dataCy="test-request-estimated-students-errors"
                        errors={formErrors.estimatedStudents?.errors}
                      />
                    </StudentCountRowColumn>
                  </DatesRow>
                );
              }),
            )}
          </DateRowContainer>
          <AddMomentButton
            dataCy="test-dates-add-date-button"
            disabled={isDisabled}
            variant="default"
            onClick={onAddDateRowClick}
          >
            <SvgIconReact iconName="plusIcon" />
            <span>{t('testDateStep.addAdditionalDate.button', 'Add extra moment')}</span>
          </AddMomentButton>
        </div>
        <ExtraTimeWrapper>
          <div>
            <Checkbox
              checked={values.extraTime !== null}
              dataCy="test-request-extra-time-checkbox"
              disabled={isDisabled}
              id="test-request-extra-time"
              label={t('testDateStep.extraTime.label', 'Add extra time')}
              type="checkbox"
              onChange={onToggleExtraTime}
            />
            <InfoTooltip
              tooltipContent={t('testDateStep.extraTime.infoTooltip', {
                studentTerm: getStudentTermByDomain({ usePlural: true }),
              })}
            />
          </div>
          <div>
            {values.extraTime !== null && (
              <div>
                <FormInput
                  dataCy="test-request-extra-time-input"
                  disabled={isDisabled}
                  hasError={!!formErrors.extraTime}
                  min="1"
                  postfixLabel={t('testDateStep.extraTime.postfix.label', 'minutes')}
                  type="number"
                  value={Number.isNaN(values.extraTime) ? '' : values.extraTime}
                  onChange={onExtraTimeChange}
                />
                <FormErrorMessage dataCy="test-request-extra-time-errors" errors={formErrors.extraTime?.errors} />
              </div>
            )}
          </div>
          <div />
        </ExtraTimeWrapper>
        <CustomReviewDateWrapper>
          <div>
            <Checkbox
              checked={values.useCustomReviewDate}
              dataCy="test-request-custom-review-date-checkbox"
              disabled={isDisabled}
              id="custom_review_date"
              label={t('testDateStep.customReviewDate.label', 'Custom review date')}
              type="checkbox"
              onChange={onUseCustomReviewDateChange}
            />
            <InfoTooltip
              tooltipContent={t('testDateStep.customReviewDate.infoTooltip', {
                studentTerm: getStudentTermByDomain({ usePlural: true }),
              })}
            />
          </div>
          {values.useCustomReviewDate && (
            <>
              <CustomReviewDate
                dataCy="test-request-custom-review-date-fields"
                hasError={!!(formErrors.reviewStartDate || formErrors.reviewEndDate)}
                isDisabled={isDisabled}
                isEditingScheduledTest={isEditingScheduledTest}
                language={i18n.language as AvailableLanguage}
                reviewEndDateValue={values.reviewEndDate || new Date()}
                reviewStartDateValue={values.reviewStartDate || new Date()}
                onEndDateUpdate={onUpdateCustomReviewDate('reviewEndDate')}
                onStartDateUpdate={onUpdateCustomReviewDate('reviewStartDate')}
              />
              <FormErrorMessage
                errors={(formErrors.reviewStartDate?.errors || []).concat(formErrors.reviewEndDate?.errors || [])}
              />
            </>
          )}
        </CustomReviewDateWrapper>
      </div>
    </FormStep>
  );
};
