import React, { useEffect, useMemo, useState } from 'react';

import { QuestionStatus } from 'constants/exam-constants';

import { EmbeddedHtml } from 'components/EmbeddedHtml';
import { Node } from 'components/EmbeddedHtml/EmbeddedHtml.model';
import { commonSanitizeOptions } from 'layouts/QuestionManager/QuestionManager.constants';

import { getNextSibling } from '_helpers/elements';
import { useParams } from 'react-router-dom';
import { InputQuestionAnswers, InputQuestionProps } from './InputQuestion.model';
import { StyledInputField, StyledInputQuestion } from './InputQuestion.styled';
import { QuestionsManagerPathParams } from '../../QuestionManager.model';
import { useAnswer } from 'hooks/useAnswer';
import { useTranslation } from 'react-i18next';

const inputPlaceholderRegExp = /\[hier\]/g;

const sanitizeOptions = {
  ...commonSanitizeOptions,
  allowedTags: [...commonSanitizeOptions.allowedTags, 'input'],
  allowedAttributes: {
    ...commonSanitizeOptions.allowedAttributes,
    input: ['name', 'data-index'],
  },
};

export const InputQuestion: React.FC<InputQuestionProps> = ({
  answerFeedbackComponent: answerComponent,
  className,
  evaluateAnswer,
  results,
  status,
  question,
  isEvaluatingAnswer,
  prevAnswerValues,
}) => {
  const { t } = useTranslation('skill-determination-test');
  const { productId, exerciseId }: QuestionsManagerPathParams = useParams();
  const { setAnswerButtonProps } = useAnswer();
  const setExerciseIdValue = !exerciseId ? undefined : +exerciseId;
  const [currentAnswers, setCurrentAnswers] = useState<InputQuestionAnswers>([]);

  const arrPrevQuestionValue = prevAnswerValues ? prevAnswerValues.map(obj => obj.value) : [];

  const htmlString = useMemo<string>(() => {
    let inputCount = 0;
    return question.content.replace(inputPlaceholderRegExp, () => {
      inputCount += 1;
      return `<input name="answer-gap" data-index=${inputCount - 1} />`;
    });
  }, [question.content]);

  const gapCount = useMemo<number>(
    () => question.content.match(inputPlaceholderRegExp)?.length ?? 0,
    [question.content],
  );

  useEffect(() => {
    setCurrentAnswers(arrPrevQuestionValue as string[]);
  }, [question]);

  const getInputStatus = (index: number) => {
    if (!results || status === QuestionStatus.INITIAL) {
      return QuestionStatus.INITIAL;
    }
    const isCorrect = results.find(result => result.order === index)?.answerCorrect;
    return isCorrect ? QuestionStatus.CORRECT : QuestionStatus.INCORRECT;
  };

  const onSubmitAnswer = (answersToSubmit: string[], skipAnswerValidation?: boolean) => {
    const parsedAnswers = answersToSubmit.map((answer: string, index: number) => ({
      order: index,
      value: answer,
    }));
    evaluateAnswer && evaluateAnswer(+productId, question.id, parsedAnswers, setExerciseIdValue, skipAnswerValidation);
  };

  const onInputKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      const nextSibling = getNextSibling<HTMLInputElement>(event.target as HTMLInputElement, 'input');
      if (nextSibling === null) {
        onSubmitAnswer(currentAnswers);
      } else {
        nextSibling.focus();
      }
    }
  };

  /* eslint-disable consistent-return */
  const replaceFunction = ({ tagType, attributes }: Node) => {
    if (tagType === 'input' && attributes.name === 'answer-gap') {
      const index = +attributes['data-index'];

      return (
        <StyledInputField
          data-cy={`input-question-field-${question.id}-${index}`}
          disabled={status !== QuestionStatus.INITIAL}
          spellCheck={false}
          status={getInputStatus(index)}
          value={currentAnswers[index] ? currentAnswers[index] : ''}
          onChange={e => {
            const updatedValues = [...currentAnswers];
            updatedValues[index] = e.target.value;
            setCurrentAnswers(updatedValues);
          }}
          onKeyPress={onInputKeyPress}
        />
      );
    }
  };

  // Set configuration for answer button
  useEffect(() => {
    setAnswerButtonProps({
      label: t('button.checkAnswer.label', 'Check answer'),
      isDisabled: currentAnswers.length !== gapCount || currentAnswers.some(value => !value),
      isHidden: status !== QuestionStatus.INITIAL,
      isLoading: isEvaluatingAnswer || false,
      onClick: skipAnswerValidation => {
        onSubmitAnswer(currentAnswers, skipAnswerValidation);
      },
    });
  }, [isEvaluatingAnswer, currentAnswers, gapCount, status]);

  return (
    <StyledInputQuestion className={className} status={status}>
      <EmbeddedHtml
        rawHtml={htmlString}
        replaceFunction={replaceFunction}
        sanitizeOptions={sanitizeOptions}
        tagName="section"
      />
      {answerComponent}
    </StyledInputQuestion>
  );
};
