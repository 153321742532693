import sanitizeHtml, { IOptions as SanitizeOptions } from 'sanitize-html';

export const contentSanitizeOptions: SanitizeOptions = {
  allowedTags: [...sanitizeHtml.defaults.allowedTags, 'img', 'iframe'],
  allowedAttributes: {
    ...sanitizeHtml.defaults.allowedAttributes,
    a: [...sanitizeHtml.defaults.allowedAttributes.a, 'data-module-id', 'data-subject-id', 'data-topic-id'],
    iframe: [
      'src',
      'height',
      'width',
      'referrerpolicy',
      'allow',
      'allowfullscreen',
      'msallowfullscreen',
      'frameborder',
      'scrolling',
      'marginheight',
      'marginwidth',
    ],
    ol: ['start'],
    img: [...sanitizeHtml.defaults.allowedAttributes.img, 'width', 'height'],
  },
  allowedIframeHostnames: ['player.vimeo.com', 'mediasite.hr.nl'],
  allowedClasses: {
    '*': ['content1', 'content2', 'content3', 'content4', 'content5'],
    a: ['theory_crosslink'],
    img: ['icon'],
    p: ['reference'],
  },
};
