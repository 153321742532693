import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { SteppedFormProps, SteppedFormDash } from './SteppedForm.model';
import {
  FormActionButton,
  FormDivider,
  FormProgressDash,
  FormProgressDashContainer,
  StyledSteppedForm,
  FormButtonContainer,
} from './SteppedForm.styled';

export const SteppedForm: React.FC<SteppedFormProps> = ({
  children,
  totalSteps,
  currentStep,
  submitButtonTitle,
  nextStepDisabled = false,
  prevStepDisabled = false,
  isLoading = false,
  onNextStep,
  onPrevStep,
  onSubmit,
}) => {
  const { t } = useTranslation('form');

  // make sure the current step is not greater than the total steps
  let actualStep = currentStep > totalSteps ? totalSteps : currentStep;
  // make sure the current step is not less than 1 (the first step)
  if (currentStep < 1) {
    actualStep = 1;
  }

  useEffect(() => {
    if (nextStepDisabled) {
      return () => undefined;
    }
    const onKeyUp = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        onNextStep(actualStep + 1);
      }
    };
    document.addEventListener('keyup', onKeyUp);
    return () => document.removeEventListener('keyup', onKeyUp);
  }, [actualStep, nextStepDisabled]);

  const isLastStep = actualStep === totalSteps;
  const isFirstStep = actualStep === 1;
  const stepsActive: readonly SteppedFormDash[] = new Array(totalSteps).fill(null).map((_, index) => ({
    key: `${index + 1}/${totalSteps}`,
    isActive: index < actualStep,
  }));
  const activeChildIndex = actualStep - 1;
  const onNextStepClick = () => {
    onNextStep(actualStep + 1);
  };
  const onPrevStepClick = () => {
    onPrevStep(actualStep - 1);
  };
  return (
    <StyledSteppedForm>
      <FormProgressDashContainer>
        {stepsActive.map(({ key, isActive }) => (
          <FormProgressDash key={key} $isActive={isActive} />
        ))}
      </FormProgressDashContainer>
      <div>{children[activeChildIndex]}</div>
      <FormDivider />
      <FormButtonContainer>
        {!isFirstStep && (
          <FormActionButton
            dataCy="stepped-form-prev-button"
            disabled={isLoading || prevStepDisabled}
            variant="secondary"
            onClick={onPrevStepClick}
          >
            {t('button.prevStep.label', { step: actualStep - 1 })}
          </FormActionButton>
        )}
        {!isLastStep && (
          <FormActionButton
            dataCy="stepped-form-next-button"
            disabled={nextStepDisabled}
            variant="primary"
            onClick={onNextStepClick}
          >
            {t('button.nextStep.label', { step: actualStep + 1 })}
          </FormActionButton>
        )}
        {isLastStep && (
          <FormActionButton
            dataCy="stepped-form-next-button"
            disabled={nextStepDisabled}
            isLoading={isLoading}
            variant="primary"
            onClick={onSubmit}
          >
            {submitButtonTitle}
          </FormActionButton>
        )}
      </FormButtonContainer>
    </StyledSteppedForm>
  );
};
