import { observer } from 'mobx-react';
import React, { useEffect, useMemo, useState } from 'react';
import { useProduct } from 'hooks/useProduct';
import { Button } from 'components/Button';
import { SvgIconReact } from 'components/SvgIconReact';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useProductResults } from 'hooks/useProductResults';
import { useProductProgress } from 'hooks/useProductProgress';
import { useProfile } from 'hooks/useProfile';
import { DownloadReportPage } from './components/DownloadReportPage/DownloadReportPage';
import {
  StyledPersonalProgresPage,
  StyledGraphs,
  StyledGraph,
  StyledContent,
  StyledNav,
} from './PersonalProgressPage.styled';
import { ProgressBySubjectGraph } from './components/ProgressBySubjectGraph/ProgressBySubjectGraph';
import { ExercisesPerSubjectGraph } from './components/ExercisesPerSubjectGraph/ExercisesPerSubjectGraph';
import { TotalExercisesGraph } from './components/TotalExercisesGraph/TotalExercisesGraph';
import { PersonalProgressPagePathParams } from './PersonalProgressPage.model';

export const PersonalProgressPage: React.FC = observer(() => {
  const { t } = useTranslation('results');
  const [isDownloadingReport, setIsDownloadingReport] = useState(false);

  const { productId }: PersonalProgressPagePathParams = useParams();

  const { userDetails } = useProfile();

  // Fetch product subjects for ExercisesPerSubjectGraph and ProgressBySubjectGraph
  const { productSubjects, fetchProductSubjects } = useProduct();

  useEffect(() => {
    if (!productSubjects && productId) {
      fetchProductSubjects(+productId);
    }
  }, [productSubjects, productId]);

  // Fetch finished exercises over time for TotalExercisesGraph
  const { exercisesOverTimeResults, getExercisesOverTimeResults, exercisesOverTimeResultsFetchException } =
    useProductResults();

  useEffect(() => {
    getExercisesOverTimeResults(+productId);
  }, [productId]);

  // Fetch finished exercises per subject for ExercisesPerSubjectGraph
  const { exercisePerSubjectResults, getExercisePerSubjectResults, exercisePerSubjectResultsFetchException } =
    useProductResults();

  useEffect(() => {
    getExercisePerSubjectResults(+productId);
  }, []);

  // Fetch goalLevel progress per subject for ProgressBySubjectGraph
  const { productProgressSubjects, getProductProgressSubjects, productProgressSubjectsFetchException } =
    useProductProgress();
  const { productDetails } = useProduct();

  // Fetch user goal level for ProgressBySubjectGraph
  const { productGoalLevel, fetchProductGoalLevel } = useProductProgress();

  useEffect(() => {
    if (!productGoalLevel) {
      fetchProductGoalLevel(+productId);
    }
    getProductProgressSubjects(+productId);
  }, [productId]);

  // only results for adaptive subjects should be shown on this page
  const subjects = useMemo(() => productSubjects?.filter(subject => subject.type === 'adaptive'), [productSubjects]);

  const onDownloadReportClick = () => {
    setIsDownloadingReport(true);
  };
  const onDownloadReportDone = () => {
    // either downloaded or canceled
    setIsDownloadingReport(false);
  };

  const hasAnyLoadingError = !!(
    productProgressSubjectsFetchException ||
    exercisePerSubjectResultsFetchException ||
    exercisesOverTimeResultsFetchException
  );

  const canDownloadReport = !!(exercisePerSubjectResults && exercisesOverTimeResults && productProgressSubjects);

  return (
    <StyledPersonalProgresPage>
      <h3>{t('title.personalProgressPage', 'Personal Progress')}</h3>
      <StyledContent>
        <StyledNav>
          <div>
            <div>
              <Button
                hasIconRight
                dataCy="personal-progress-download-report-button"
                disabled={!canDownloadReport}
                isLoading={isDownloadingReport}
                variant="secondary"
                onClick={onDownloadReportClick}
              >
                <span>{t('button.downloadReport', 'Report')}</span>
                <SvgIconReact iconName="downloadIcon" />
              </Button>
            </div>
          </div>
        </StyledNav>
        <StyledGraphs>
          <StyledGraph data-cy="progress-by-subject-graph-container">
            <ProgressBySubjectGraph
              hasLoadingError={!!productProgressSubjectsFetchException}
              productDetails={productDetails}
              productGoalLevel={productGoalLevel}
              productProgressSubjects={productProgressSubjects}
              subjects={subjects}
            />
          </StyledGraph>
          <StyledGraph data-cy="exercises-per-subject-graph-container">
            <ExercisesPerSubjectGraph
              exercisePerSubjectResults={exercisePerSubjectResults}
              hasLoadingError={!!exercisePerSubjectResultsFetchException}
              subjects={subjects}
            />
          </StyledGraph>
          <StyledGraph data-cy="exercise-over-time-graph-container" numberOfSubjects={subjects ? subjects.length : 0}>
            <TotalExercisesGraph
              exercisesOverTimeResults={exercisesOverTimeResults}
              hasLoadingError={!!exercisesOverTimeResultsFetchException}
            />
          </StyledGraph>
        </StyledGraphs>
      </StyledContent>
      {isDownloadingReport && (
        <DownloadReportPage
          exercisePerSubjectResults={exercisePerSubjectResults}
          exercisesOverTimeResults={exercisesOverTimeResults}
          hasLoadingError={hasAnyLoadingError}
          productDetails={productDetails}
          productGoalLevel={productGoalLevel}
          productProgressSubjects={productProgressSubjects}
          subjects={subjects}
          userDetails={userDetails}
          onDone={onDownloadReportDone}
        />
      )}
    </StyledPersonalProgresPage>
  );
});
