import styled, { css } from 'styled-components/macro';
import { spin } from 'styles/mixins/animations';
import { ElearningThemeInterface } from 'themes/elearningInterface.theme';

import LoadingSpinnerProps from './LoadingSpinner.model';

interface Props extends LoadingSpinnerProps {
  theme: ElearningThemeInterface;
  $isFullScreen: boolean;
}

const getFullScreenCss = ({ theme }: Props) => css`
  background-color: ${theme.palette.neutral.white};
  width: 100vw;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LoadingSpinnerWrapper = styled.div<Props>`
  ${props => props.$isFullScreen && getFullScreenCss(props)}
  > div {
    width: ${(props: Props) => props.size}px;
    height: ${(props: Props) => props.size}px;

    &::after {
      border: ${(props: Props) => props.stroke}px solid
        ${(props: Props) => props.color ?? props.theme.palette.primary.brand};
      border-radius: 9999px;
      border-right-color: transparent;
      border-top-color: transparent;
      content: '';
      display: block;
      height: ${(props: Props) => props.size}px;
      position: relative;
      width: ${(props: Props) => props.size}px;
      animation: ${spin} 0.7s infinite linear;
    }
  }
`;

LoadingSpinnerWrapper.defaultProps = {
  size: 80,
  stroke: 8,
};
