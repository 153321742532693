import React from 'react';
import { Slide, toast, ToastContainerProps, ToastOptions, TypeOptions as ToastTypes } from 'react-toastify';

import { FontAwesomeIcon } from '../FontAwesomeIcon';

export const initToast = (config: ToastContainerProps): void =>
  toast.configure({
    transition: Slide,
    ...config,
  });

const ToastIconTypes: Record<ToastTypes, string> = {
  default: 'far fa-info',
  success: 'far fa-check',
  warning: 'far fa-exclamation-triangle',
  error: 'far fa-times',
  info: 'far fa-info',
  dark: 'far fa-info',
};

type ToastPanelProps = {
  text: string;
  type: ToastTypes;
};

const ToastPanel: React.FC<ToastPanelProps> = ({ text, type }) => (
  <>
    <FontAwesomeIcon className="info-icon" fontSize="1rem" iconName={ToastIconTypes[type] || ToastIconTypes.default} />
    {text}
  </>
);

export const showToast = (text = '', type: ToastTypes = 'default', options: ToastOptions = {}): void => {
  const toastOptions = {
    progress: undefined,
    ...options,
  };

  const triggerToastFn = type === 'default' ? toast : toast[type];
  triggerToastFn(<ToastPanel text={text} type={type} />, toastOptions);
};
