import styled from 'styled-components/macro';
import { calculateRem } from 'styles/mixins/calculateRem';
import { ElearningThemeInterface } from 'themes/elearningInterface.theme';
import { Heading2 } from 'styles/elements/Headings';

interface Props {
  theme: ElearningThemeInterface;
}

export const StyledDiagnosticMain = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
`;
export const StyledDiagnosticTestContentTitle = styled(Heading2)`
  margin: ${calculateRem(16)};
  text-align: center;
`;
export const StyledDiagnosticTestContentSubtitle = styled.div`
  display: grid;
  justify-content: center;
  margin: ${calculateRem(16)};
  text-align: center;
`;
export const StyledDiagnosticTestContentDescription = styled.p`
  text-align: center;
  color: ${(props: Props) => props.theme.palette.neutral._900};
`;
