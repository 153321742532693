import styled, { css } from 'styled-components/macro';

import { calculateRem } from 'styles/mixins/mixins';
import { Heading4, Heading5 } from 'styles/elements/Headings';

import confettiImg from 'assets/icons/confetti.svg';
import { SvgIconReact } from 'components/SvgIconReact';

import { ThemeProps } from 'models/styles/theme';

interface Props extends ThemeProps {
  visible?: boolean;
  showConfetti?: boolean;
}

export const StyledMessage = styled.article<Props>`
  width: 29rem;
  margin: auto;
  padding: ${calculateRem(24)};
  overflow: hidden;

  background: ${(props: Props) => props.theme.palette.neutral.black};
  border-radius: ${calculateRem(4)};
  box-shadow: ${calculateRem(3)} ${calculateRem(3)} ${calculateRem(1)} 0 #0000004d;

  display: flex;
  align-items: center;
  column-gap: ${calculateRem(24)};

  position: fixed;
  top: 6.75rem;
  left: 50%;
  margin-left: -14.5rem;

  transition: opacity 0.25s;
  opacity: ${(props: Props) => (props.visible ? '1' : '0')};

  /* -- Confetti -- */
  ${(props: Props) =>
    props.showConfetti
      ? css`
          ::before {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            right: 0;
            background-image: url(${confettiImg});
            background-repeat: repeat;
            background-size: 470px 125px;

            animation-name: confetti-fall;
            animation-delay: 0;
            animation-duration: 1s;
            animation-timing-function: linear;
            animation-iteration-count: infinite;
          }

          @keyframes confetti-fall {
            from {
              background-position-y: 0;
            }
            to {
              background-position-y: 125px;
            }
          }
        `
      : ''}
`;

export const StyledMessageTitle = styled(Heading4)<Props>`
  color: ${(props: Props) => props.theme.palette.neutral.white};
  line-height: ${calculateRem(24)};
`;

export const StyledMessageText = styled(Heading5)<Props>`
  color: ${(props: Props) => props.theme.palette.neutral.white};
  font-weight: normal;
  margin-bottom: 0;
  line-height: ${calculateRem(23.8)};
`;

export const StyledIcon = styled(SvgIconReact)`
  flex-shrink: 0;
`;
