import styled from 'styled-components/macro';

import { ThemeProps as Props } from 'models/styles/theme';

import { SanitizedHtml } from 'components/SanitizedHtml';
import { CardWithTitleBar } from 'components/CardWithTitleBar';

import { CssFlex, Typography } from 'styles/helpers/layout';

export const StyledModuleCard = styled(CardWithTitleBar)<Props>`
  width: 21rem;
  min-height: 21rem;
  max-height: 25rem;
`;

export const StyledSubtitle = styled.span<Props>`
  font-size: ${(props: Props) => props.theme.font.size._18};
  color: ${(props: Props) => props.theme.palette.neutral._900};
`;

export const StyledLastPracticedDate = styled(CssFlex)`
  font-size: ${(props: Props) => props.theme.font.size._12};

  ${Typography} {
    text-wrap: nowrap;
  }
`;

export const StyledLastPracticedTopicTitle = styled(SanitizedHtml)`
  font-size: 1.2rem;
  font-weight: ${(props: Props) => props.theme.font.weight.bold};
`;

export const StyledProgressBarLabel = styled(Typography)`
  font-size: ${(props: Props) => props.theme.font.size._14};
`;
