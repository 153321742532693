import styled, { css } from 'styled-components/macro';
import {
  color,
  ColorProps,
  fontSize,
  FontSizeProps,
  fontWeight,
  FontWeightProps,
  padding,
  PaddingProps,
} from 'styled-system';
import { Layout } from 'styles/elements/ElementsInterface';

export const ParagraphCss = css`
  font-weight: ${(props: Layout) => props.theme.font.weight.normal};
  font-size: ${(props: Layout) => props.theme.font.size._16};
  margin-bottom: ${(props: Layout) => props.theme.font.size._14};
  line-height: 150%;
  margin-top: 0;
  word-break: break-word;
  color: ${(props: Layout) => props.theme.palette.neutral.black};
`;

export const Paragraph = styled.p<Layout & FontSizeProps & FontWeightProps & ColorProps & PaddingProps>`
  ${ParagraphCss}
  ${fontSize}
  ${fontWeight}
  ${color}
  ${padding}
`;

export const ParagraphBanner = styled.p<Layout>`
  font-size: 1.1rem;
  letter-spacing: 0;
  line-height: 1.2rem;
  margin: 0.7rem 1rem 0 0;
`;
