import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Select } from 'components/SelectUI/Select';
import { SchoolYear } from 'models/product/SchoolYear';
import { Box, Typography } from 'styles/helpers/layout';

interface SchoolYearSelectorProps {
  schoolYears?: SchoolYear[];
  onChange: (selectedSchoolYear: SchoolYear | null) => void;
}

export const SchoolYearSelector: React.FC<SchoolYearSelectorProps> = ({ schoolYears = [], onChange }) => {
  const { t } = useTranslation('teacher-dashboard');

  const [selectedOption, setSelectedOption] = useState('all');

  const schoolYearOptions = useMemo(
    () => [
      {
        value: 'all',
        label: t('basicProgress.overview.filters.schoolYear.defaultOption', 'All school years'),
      },
      ...schoolYears.map(({ startDate, endDate }, index) => ({
        value: index,
        label: `${new Date(startDate).getFullYear()} / ${new Date(endDate).getFullYear()}`,
      })),
    ],
    [schoolYears],
  );

  return (
    <Box>
      <Typography fontSize="0.75rem">{t('selectors.schoolYearSelectorLabel')}</Typography>
      <Select
        showSearchBox
        dataCy="school-year-selector"
        options={schoolYearOptions}
        showPlaceholderInOptionList={false}
        value={selectedOption}
        onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
          const selectedValue = event.target.value;
          setSelectedOption(selectedValue);
          onChange(selectedValue !== 'all' ? schoolYears[+selectedValue] : null);
        }}
      />
    </Box>
  );
};
