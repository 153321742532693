import React from 'react';
import { Modal } from 'components/Modal';
import { modalIds } from 'constants/modals-constants';
import { useModal } from 'hooks/useModal';
import { Heading2 } from 'styles/elements/Headings';

import { StyledPasswordWrapper } from './ShowPasswordModal.styled';
import { ShowPasswordModalProps } from './ShowPasswordModal.model';

export const ShowPasswordModal: React.FC<ShowPasswordModalProps> = ({ headingTitle, onCopyPassword }) => {
  const { closeModal, isModalOpen, modalParams } = useModal();

  return (
    <Modal dataCy="scheduled-test-password-modal" isOpen={isModalOpen(modalIds.showPasswordModal)} onClose={closeModal}>
      <StyledPasswordWrapper>
        <Heading2>{headingTitle}</Heading2>
        <button
          data-class="modal-tooltip"
          data-for="password-copy-tooltip"
          data-tip=""
          type="button"
          onClick={() => onCopyPassword(`${modalParams?.password}`)}
        >
          {modalParams?.password}
        </button>
      </StyledPasswordWrapper>
    </Modal>
  );
};
