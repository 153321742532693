import React, { useState } from 'react';

import { ResetSubjectProgressModal } from 'components/ResetSubjectProgress/ResetSubjectProgressModal';
import { ToastMethods } from 'components/ToastNotification';
import i18n from 'i18n';
import { observer } from 'mobx-react';
import { progressRepository } from 'repositories';
import { useProductProgress } from 'hooks/useProductProgress';
import { useTranslation } from 'react-i18next';
import { ChapterOverview } from '../ChapterOverview/ChapterOverview';
import {
  StyledSubjectProgressBar,
  StyledResetSubjectProgress,
  SyledChapterOverviewWrapper,
  StyledSubjectAccordionItem,
} from './AdaptiveSubject.styled';
import { CssButton } from 'styles/helpers/layout';
import { ContentAPI } from 'generated/types';

interface AdaptiveSubjectProps {
  subject: ContentAPI.SubjectListItem;
  productId: number;
  moduleId: number;
}

export const AdaptiveSubject: React.FC<AdaptiveSubjectProps> = observer(({ subject, productId, moduleId }) => {
  const { t } = useTranslation('subject');
  const [showResetModal, setShowResetModal] = useState(false);
  const [isResettingProgress, setIsResettingProgress] = useState(false);
  const { moduleSubjectsProgress, productGoalLevel, fetchModuleProgress } = useProductProgress();

  const onResetSubjectProgressClick = () => {
    setShowResetModal(true);
  };

  const onResetSubjectProgressConfirm = async () => {
    setIsResettingProgress(true);
    try {
      await progressRepository.resetSubjectProgress(productId, subject.id);
      setShowResetModal(false);
      // reset was successful, reload the module subject data
      fetchModuleProgress(productId, moduleId);
    } catch (e) {
      ToastMethods.showToast(i18n.t('toast:progress.resetsubjectprogress.error'), 'error');
    }
    setIsResettingProgress(false);
  };

  const goalLevel = productGoalLevel?.goalLevel || 0;

  const subjectProgress = moduleSubjectsProgress.find(selectedSubject => selectedSubject.id === subject.id);
  if (subjectProgress === undefined) return null; // in case of backend 500, this might be undefined

  // We will also consider subject not started if goal level if not set
  const isSubjectStarted = !!goalLevel && subjectProgress.isStarted;
  return (
    <>
      <StyledSubjectAccordionItem
        key={subject.id}
        dataCy="subject-list-item"
        description={subject.description ?? ''}
        headerPanelRight={
          <StyledSubjectProgressBar
            currentProgress={subjectProgress.progress}
            goalLevel={goalLevel}
            isSubjectStarted={isSubjectStarted}
            moduleId={moduleId}
            productId={productId}
            subjectId={subject.id}
            subjectName={subject.title}
          />
        }
        title={subject.title}
      >
        {isSubjectStarted && (
          <StyledResetSubjectProgress>
            <CssButton dataCy="reset-subject-button" mr="1.5rem" type="button" onClick={onResetSubjectProgressClick}>
              {t('button.resetSubjectProgress', 'Reset your score and start again')}
            </CssButton>
          </StyledResetSubjectProgress>
        )}
        <SyledChapterOverviewWrapper>
          {subject.chapters.map(chapter => (
            <ChapterOverview
              key={chapter.id}
              chapter={chapter}
              isDisabled={!isSubjectStarted}
              normalTopicProgress={subjectProgress.normalTopics}
              subjectId={subject.id}
              themeAssignmentProgress={subjectProgress.themeAssignments}
            />
          ))}
        </SyledChapterOverviewWrapper>
      </StyledSubjectAccordionItem>
      {showResetModal && (
        <ResetSubjectProgressModal
          isLoading={isResettingProgress}
          onCancel={() => setShowResetModal(false)}
          onConfirm={onResetSubjectProgressConfirm}
        />
      )}
    </>
  );
});
