import styled from 'styled-components/macro';
import { ElearningThemeInterface } from 'themes/elearningInterface.theme';

interface Props {
  theme: ElearningThemeInterface;
}

export interface ImageWrapperProps extends Props {
  background: string;
}

export const StyledScheduleTest = styled.div<Props>`
  width: 32%;
  border: 1px solid ${({ theme }: Props) => theme.palette.neutral._400};
  text-align: left;

  > div {
    padding: 1rem;

    > h3 {
      font-weight: 400;
    }

    > h4 {
      font-weight: 400;
      width: 80%;
      margin-bottom: 2rem;
      color: ${({ theme }: Props) => theme.palette.neutral._900};
    }
  }
`;

export const StyledImageWrapper = styled.div<ImageWrapperProps>`
  height: 9rem;
  background: url(${({ background }: ImageWrapperProps) => background});
  background-size: cover;
  background-position: center;
`;
