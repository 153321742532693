// We have to include this comments to include this locales
// when we run the locales script generator
// t('test-environment:instructions.firstStep.title', 'Terms & Conditions')
// t('test-environment:instructions.firstStep.subTitle', 'Important notice')
// t('test-environment:instructions.secondStep.title', 'Rules')
// t('test-environment:instructions.secondStep.subTitle', 'Rules')
// t('test-environment:subjects.title', 'Test overview')
// t('test-environment:subjects.subtitle', 'Select the subject you want to attempt')
export const stepsConfig = [
  {
    title: 'instructions.firstStep.title',
    subTitle: 'instructions.firstStep.subTitle',
    button: 'instructions.firstStep.button',
  },
  {
    title: 'instructions.secondStep.title',
    subTitle: 'instructions.secondStep.subTitle',
    button: 'instructions.secondStep.button',
  },
  {
    title: 'subjects.title',
    subTitle: 'subjects.subtitle',
    button: 'subjects.button',
  },
];
