import React, { Fragment } from 'react';
import { getYear } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { FooterConstants } from 'footer-utils';

import { Link } from 'components/Link';

import { StyledBottomBar } from './CopyrightBottomBar.styled';

interface CopyrightBottomBarProps {
  className?: string;
}

export const CopyrightBottomBar: React.FC<CopyrightBottomBarProps> = ({ className }) => {
  const { t } = useTranslation('common');
  const { genericLinks } = FooterConstants();

  const today = new Date();

  return (
    <StyledBottomBar alignItems="center" className={className} columnGap={3} justifyContent="space-between">
      {/* Copyright notice */}
      <span>{t('footer.copyrightNotice', { currentYear: getYear(today) })}</span>
      {/* Miscellaneous links */}
      <span>
        {genericLinks.links.map((link, index) => (
          <Fragment key={index}>
            {index > 0 && ' | '}
            <Link external={link.external} size="xs" to={link.url}>
              {!link.staticLabel ? t(link.label) : link.label}
            </Link>
          </Fragment>
        ))}
      </span>
    </StyledBottomBar>
  );
};
