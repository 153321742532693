import styled from 'styled-components/macro';

import { calculateRem } from 'styles/mixins/mixins';
import { ElearningThemeInterface } from '../../themes/elearningInterface.theme';

export interface Props {
  theme: ElearningThemeInterface;
}

export const StyledErrorMessage = styled.span`
  display: block;
  text-align: left;
  margin-top: 0.5rem;
  font-size: ${calculateRem(14)};
  color: ${(props: Props) => props.theme.palette.feedback.error.brand};
`;
