const customMediaQuery = (maxWidth: number): string => `@media (max-width: ${maxWidth}px)`;

export interface MediaQueryInterface {
  custom: (maxWidth: number) => string;
  mobile: string;
  tablet: string;
  laptop: string;
  laptopL: string;
}

const sizes = {
  mobile: 425,
  tablet: 768,
  laptop: 1024,
  laptopL: 1440,
};

export const media: MediaQueryInterface = {
  custom: customMediaQuery,
  mobile: customMediaQuery(sizes.mobile),
  tablet: customMediaQuery(sizes.tablet),
  laptop: customMediaQuery(sizes.laptop),
  laptopL: customMediaQuery(sizes.laptopL),
};
