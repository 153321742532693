import React from 'react';
import { Tab } from './TabFilter.styled';
import { CssFlex } from 'styles/helpers/layout';

interface FiltersValueProps {
  value: string;
  dataCy: string;
  text: string;
}

interface TabFilterProps {
  onTabChange: (tab: string) => void;
  selectedTab: string;
  tabsFiltersValues: FiltersValueProps[];
  $justifyContent?: string;
  $marginBottom?: string;
}

export const TabFilter: React.FC<TabFilterProps> = ({
  onTabChange,
  selectedTab,
  $justifyContent,
  $marginBottom,
  tabsFiltersValues,
}) => (
  <CssFlex flexDirection="row" justifyContent={$justifyContent ?? 'center'} margin="0 auto" mb={$marginBottom}>
    {tabsFiltersValues.map(({ value, dataCy, text }) => (
      <Tab key={value} $selected={selectedTab === value} data-cy={dataCy} onClick={() => onTabChange(value)}>
        {text}
      </Tab>
    ))}
  </CssFlex>
);
