import styled from 'styled-components/macro';
import { ElearningThemeInterface } from 'themes/elearningInterface.theme';

interface Props {
  theme: ElearningThemeInterface;
}

export const StyledQuestionManager = styled.div<Props>`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  align-items: center;
  overflow-y: auto;
`;

export const StyledQuestion = styled.article<Props>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 3.5rem 1rem 3.5rem;
  width: 100%;

  div:has(.opdracht) {
    width: 100%;
  }

  .opdracht {
    text-align: left;
    color: ${(props: Props) => props.theme.palette.neutral.black};
    font-weight: ${(props: Props) => props.theme.font.weight.bold};
    font-size: ${(props: Props) => props.theme.font.size._24};
    line-height: 2rem;
  }

  .vraag {
    text-align: left;
    font-size: 1rem;
    line-height: 1.5rem;
    text-align: left;
    color: ${(props: Props) => props.theme.palette.neutral.black};
    font-weight: ${(props: Props) => props.theme.font.weight.normal};
  }

  p {
    color: ${(props: Props) => props.theme.palette.neutral._900};
  }

  img {
    margin: auto;
    display: block;
    max-width: 100%;
    height: auto !important;
  }
`;
