import { Variant as ButtonVariant } from 'components/Button/Button.model';
import { ElearningThemeInterface } from 'themes/elearningInterface.theme';

export interface ButtonPalette {
  color: {
    default: string;
    hover: string;
    focus: string;
  };
  background: {
    default: string;
    hover: string;
    focus: string;
  };
}

export const getButtonColorPalette = (
  theme: ElearningThemeInterface,
  variant: ButtonVariant,
  disabled: boolean,
): ButtonPalette => {
  switch (variant) {
    case 'dark':
      return disabled
        ? {
            color: {
              default: theme.palette.neutral.white,
              hover: theme.palette.neutral.white,
              focus: theme.palette.neutral.white,
            },
            background: {
              default: '#5F5F60',
              hover: theme.palette.neutral._900,
              focus: theme.palette.neutral._900,
            },
          }
        : {
            color: {
              default: theme.palette.neutral.white,
              hover: theme.palette.neutral.white,
              focus: theme.palette.neutral.white,
            },
            background: {
              default: '#5F5F60',
              hover: theme.palette.neutral._900,
              focus: theme.palette.neutral._900,
            },
          };
    case 'primary':
      return disabled
        ? {
            color: {
              default: theme.palette.text.primary,
              hover: theme.palette.text.primary,
              focus: theme.palette.text.primary,
            },
            background: {
              default: theme.palette.primary._400 as string,
              hover: theme.palette.primary._400 as string,
              focus: theme.palette.primary._400 as string,
            },
          }
        : {
            color: {
              default: theme.palette.text.primary,
              hover: theme.palette.text.primary,
              focus: theme.palette.text.primary,
            },
            background: {
              default: theme.palette.primary.brand,
              hover: theme.palette.primary._900 as string,
              focus: theme.palette.primary.brand,
            },
          };
    case 'primary-outline':
      return disabled
        ? {
            color: {
              default: theme.palette.primary._900 as string,
              hover: theme.palette.primary._900 as string,
              focus: theme.palette.primary._900 as string,
            },
            background: {
              default: theme.palette.neutral.white,
              hover: theme.palette.neutral.white,
              focus: theme.palette.neutral.white,
            },
          }
        : {
            color: {
              default: theme.palette.primary._900 as string,
              hover: theme.palette.primary._900 as string,
              focus: theme.palette.primary._900 as string,
            },
            background: {
              default: theme.palette.neutral.white,
              hover: theme.palette.neutral._400,
              focus: theme.palette.neutral.white,
            },
          };
    case 'secondary':
      return disabled
        ? {
            color: {
              default: theme.palette.neutral._600,
              hover: theme.palette.neutral._600,
              focus: theme.palette.neutral._600,
            },
            background: {
              default: theme.palette.neutral._200,
              hover: theme.palette.neutral._200,
              focus: theme.palette.neutral._200,
            },
          }
        : {
            color: {
              default: theme.palette.text.secondary,
              hover: theme.palette.text.secondary,
              focus: theme.palette.text.secondary,
            },
            background: {
              default: theme.palette.neutral._400,
              hover: theme.palette.neutral._100,
              focus: theme.palette.neutral._400,
            },
          };
    case 'border':
      return disabled
        ? {
            color: {
              default: theme.palette.neutral._400,
              hover: theme.palette.neutral._400,
              focus: theme.palette.neutral._400,
            },
            background: {
              default: theme.palette.neutral.white,
              hover: theme.palette.neutral._400,
              focus: theme.palette.neutral.white,
            },
          }
        : {
            color: {
              default: theme.palette.text.secondary,
              hover: theme.palette.text.secondary,
              focus: theme.palette.text.secondary,
            },
            background: {
              default: theme.palette.neutral.white,
              hover: theme.palette.neutral._400,
              focus: theme.palette.neutral._200,
            },
          };
    case 'brand':
      return disabled
        ? {
            color: {
              default: theme.palette.text.primary,
              hover: theme.palette.text.primary,
              focus: theme.palette.text.primary,
            },
            background: {
              default: theme.palette.secondary.brand,
              hover: theme.palette.secondary.brand,
              focus: theme.palette.secondary.brand,
            },
          }
        : {
            color: {
              default: theme.palette.text.primary,
              hover: theme.palette.text.primary,
              focus: theme.palette.text.primary,
            },
            background: {
              default: theme.palette.secondary.brand,
              hover: theme.palette.secondary._400 || theme.palette.secondary._200,
              focus: theme.palette.secondary.brand,
            },
          };
    default:
      return disabled
        ? {
            color: {
              default: theme.palette.neutral._400,
              hover: theme.palette.neutral._400,
              focus: theme.palette.neutral._400,
            },
            background: {
              default: theme.palette.neutral.white,
              hover: theme.palette.neutral._400,
              focus: theme.palette.neutral.white,
            },
          }
        : {
            color: {
              default: theme.palette.secondary.brand,
              hover: theme.palette.secondary.brand,
              focus: theme.palette.secondary.brand,
            },
            background: {
              default: theme.palette.neutral.white,
              hover: theme.palette.neutral.white,
              focus: theme.palette.neutral.white,
            },
          };
  }
};
