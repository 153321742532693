import styled from 'styled-components/macro';

import { ThemeProps as Props } from 'models/styles/theme';

import { CssFlex } from 'styles/helpers/layout';
import { Heading4 } from 'styles/elements/Headings';

export const StyledCard = styled(CssFlex)<Props>`
  min-width: 20rem;

  border: solid 1px;
  border-color: ${(props: Props) => props.theme.palette.neutral._900};

  &:hover {
    transition: all 0.2s ease-in-out;
    box-shadow: 0px 11px 27px 0px rgba(84, 84, 84, 0.2);
  }
`;

export const StyledCardHeader = styled(CssFlex)<Props>`
  padding: 1rem;
  border-bottom: solid 1px;
  border-color: ${(props: Props) => props.theme.palette.neutral._900};

  ${Heading4} {
    margin: 0;
    font-size: ${(props: Props) => props.theme.font.size._18};
    line-height: normal;

    max-width: 100%;
    overflow: hidden;
    text-wrap: nowrap;
    text-overflow: ellipsis;
  }
`;

export const StyledCardContent = styled(CssFlex)<Props>`
  height: 100%;
  padding: 1rem;
  overflow: auto;
`;
