import styled, { css } from 'styled-components/macro';
import { buttonColorsMixin } from 'styles/mixins/buttonColorsMixin/buttonColorsMixin';
import { fontColorMixin } from 'styles/mixins/mixins';
import { ElearningThemeInterface } from 'themes/elearningInterface.theme';
import { StyledButtonProps } from './Button.model';

interface Props extends StyledButtonProps {
  theme: ElearningThemeInterface;
}

export const StyledButton = styled.button<Props>`
  font-weight: ${({ theme, $fontWeight }: Props) =>
    $fontWeight === 'bold' ? theme.font.weight.bold : theme.font.weight.normal};

  border: 0;
  cursor: pointer;
  line-height: 1.5rem;
  position: relative;
  text-align: center;
  padding: ${props => (props.$isNarrow ? '0.6875rem' : '0.6875rem 2.125rem')};
  display: inline-block;
  transition: all 0.15s ease-in-out;
  box-shadow: none;
  border-radius: 4px;

  &:focus,
  &:visited,
  &:active,
  &:focus-within {
    outline: none;
  }

  img,
  svg {
    vertical-align: middle;
  }

  ${props =>
    props.$isLoading &&
    css`
      .content {
        user-select: none;
        pointer-events: none;
        opacity: 0;
        > * {
          visibility: hidden;
        }
      }
      .spinner {
        left: calc(50% - (12px));
        top: calc(50% - (12px));
        position: absolute !important;
      }
    `};

  &.icon-left {
    .icon {
      display: inline-block;
      margin-left: 15px;
    }
  }

  &.icon-right {
    .icon {
      display: inline-block;
      margin-left: 15px;
    }
  }

  ${props =>
    props.$isBlock &&
    css`
      width: 100%;
      display: block;
    `}

  ${(props: Props) => {
    switch (props.$size) {
      case 'small':
        return css`
          font-size: ${props.theme.font.size._12};
        `;
      case 'medium':
        return css`
          font-size: ${props.theme.font.size._16};
        `;
      case 'large':
        return css`
          font-size: ${props.theme.font.size._24};
        `;
      default:
        return '';
    }
  }}

  ${(props: Props) =>
    buttonColorsMixin({
      theme: props.theme,
      variant: props.$variant ?? 'default',
      disabled: props.disabled,
    })}
  ${props => props.$fontColor && fontColorMixin({ ...props, fontColor: props.$fontColor })}
  &:hover {
    ${props => props.$fontColor && fontColorMixin({ ...props, fontColor: props.$fontColor })}
  }

  ${(props: Props) =>
    props.$variant === 'border' &&
    css`
      border: 2px solid ${props.theme.palette.neutral._200};
      :disabled {
        border: 2px solid ${props.theme.palette.neutral._400};
      }
    `}

  ${(props: Props) =>
    props.$variant === 'primary-outline' &&
    css`
      border-radius: 4px;
      border: 1px solid ${props.theme.palette.primary._900};
    `}

  ${(props: Props) =>
    props.$variant === 'dark' &&
    css`
      border-radius: 4px;
      border: 1px solid ${props.theme.palette.neutral.white};
    `}

  ${(props: Props) =>
    props.$variant === 'default' &&
    css`
      padding: 0;
      &:hover {
        font-weight: bold;
        text-decoration: none;
      }
    `}

  ${(props: Props) =>
    props.$variant === 'text' &&
    css`
      padding: 0;
      font-weight: 400;
      text-decoration: none;
      color: ${props.theme.palette.primary._700};
      background-color: transparent;
      &:hover {
        background-color: transparent;
        opacity: 0.8;
      }
      &:focus {
        background-color: transparent;
      }
    `}
`;
