import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useProduct } from 'hooks/useProduct';

import { Button } from 'components/Button';
import { TestCompletedMessage } from 'layouts/TestCompletedMessage';
import { EmptyExamQuestionsMessageProps } from './EmptyExamQuestionsMessage.model';
import { useAdaptivePractice } from 'hooks/useAdaptivePractice';
import { LoadingSpinner } from 'components/LoadingSpinner';

export const EmptyExamQuestionsMessage: React.FC<EmptyExamQuestionsMessageProps> = ({ className }) => {
  const { t } = useTranslation('skill-determination-test');
  const history = useHistory();
  const { loading, isMixedExerciseActive } = useAdaptivePractice();

  const { moduleDetails, productDetails } = useProduct();

  if (isMixedExerciseActive) return null;

  if (loading) {
    return <LoadingSpinner isFullScreen />;
  }

  return (
    <TestCompletedMessage
      buttons={
        <>
          {productDetails && moduleDetails && (
            <Button
              dataCy="back-button"
              fontColor="black"
              variant="secondary"
              onClick={() => history.push(`/product/${productDetails.id}/module/${moduleDetails.id}`)}
            >
              {t('button.goBack.label', 'Go back')}
            </Button>
          )}
        </>
      }
      className={className}
      dataCy="empty-skill-determination-test"
      description={t(
        'emptyTest.description',
        'There are no available questions for you to answer. This subject may exceed your current goal level, so try again when you aim for a bigger goal.',
      )}
      iconName="emptyFolderIcon"
      title={t('emptyTest.title', 'This test is unavailable!')}
    />
  );
};
