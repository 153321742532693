import styled from 'styled-components/macro';
import { calculateRem } from 'styles/mixins/mixins';
import { ElearningThemeInterface } from 'themes/elearningInterface.theme';
import { CssButton } from 'styles/helpers/layout';

interface Props {
  theme: ElearningThemeInterface;
}

export const StyledButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`;

export const StyledButton = styled(CssButton)`
  font-size: ${(props: Props) => props.theme.font.size._18};
  font-weight: bold;

  &.showTip {
    padding: 0 1rem;
    color: ${(props: Props) => props.theme.palette.neutral.black};
    &:hover {
      background-color: ${(props: Props) => props.theme.palette.neutral._200};
    }
    span {
      font-size: ${(props: Props) => props.theme.font.size._16};
      font-weight: bold;
      margin-left: ${calculateRem(14)};
    }
  }
`;

export const StyledExercise = styled.div<Props>`
  display: flex;
  flex: 1 1 0;
  align-items: center;
  flex-direction: column;
  width: 75%;
  margin: 0 auto;
  button.submit {
    width: 9rem;
    font-size: 1rem;
    padding: 0.8rem 1rem;
    border-radius: 4px;
    margin: ${calculateRem(32)} 0;
  }
`;
