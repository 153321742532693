import { EnvironmentType } from 'constants/env-constants';

export const IdpRegistrationURL: Record<EnvironmentType, string> = {
  [EnvironmentType.TEST]: '#create-idp-account',
  [EnvironmentType.LOCAL]: '#create-idp-account',
  // eslint-disable-next-line max-len
  [EnvironmentType.DEVELOPMENT]:
    'https://ssp.tst.noordhoff.nl/CreateAccount.aspx?RedirectUrl=https://identity.acc.noordhoff.nl/ui/#/login?ReturnUrl=%2Fconnect%2Fauthorize%2Fcallback%3Fclient_id%3Dhogeschooltaal-he%26redirect_uri%3Dhttps%253A%252F%252Fhogeschooltaal.dev.noordhoff.nl%252Fsignin-oidc.asp%26scope%3Dopenid%2520profile%2520email%2520role%2520offline_access%26response_type%3Dcode%26nonce%3Dguid%26state%3D%257B%2522signInUrl%2522%253A%2522%252Fidp%253Fstatus%253Dsuccess%2522,%2522signOutUrl%2522%253A%2522%252Fidp%253Fstatus%253Derror%2522%257D',
  // eslint-disable-next-line max-len
  [EnvironmentType.STAGING]:
    'https://sspacc.noordhoff.nl/CreateAccount.aspx?RedirectUrl=https://identity.acc.noordhoff.nl/ui/#/login?ReturnUrl=%2Fconnect%2Fauthorize%2Fcallback%3Fclient_id%3Dhogeschooltaal-he%26redirect_uri%3Dhttps%253A%252F%252Fhogeschooltaal.stg.noordhoff.nl%252Fsignin-oidc.asp%26scope%3Dopenid%2520profile%2520email%2520role%2520offline_access%26response_type%3Dcode%26nonce%3Dguid%26state%3D%257B%2522signInUrl%2522%253A%2522%252Fidp%253Fstatus%253Dsuccess%2522,%2522signOutUrl%2522%253A%2522%252Fidp%253Fstatus%253Derror%2522%257D',
  // eslint-disable-next-line max-len
  [EnvironmentType.PRODUCTION]:
    'https://services.noordhoffuitgevers.nl/CreateAccount.aspx?RedirectUrl=https://identity.noordhoff.nl/ui/#/login?ReturnUrl=%2Fconnect%2Fauthorize%2Fcallback%3Fclient_id%3Dhogeschooltaal-he%26redirect_uri%3Dhttps%253A%252F%252Fhogeschooltaal.noordhoff.nl%252Fsignin-oidc.asp%26scope%3Dopenid%2520profile%2520email%2520role%2520offline_access%26response_type%3Dcode%26nonce%3Dguid%26state%3D%257B%2522signInUrl%2522%253A%2522%252Fidp%253Fstatus%253Dsuccess%2522,%2522signOutUrl%2522%253A%2522%252Fidp%253Fstatus%253Derror%2522%257D',
};

export const HogesWebshopURL: Record<EnvironmentType, string> = {
  [EnvironmentType.TEST]: '#hoges-webshop',
  [EnvironmentType.LOCAL]: '#hoges-webshop',
  [EnvironmentType.DEVELOPMENT]: 'https://test.hogeschooltaal.nl/webshop/test',
  [EnvironmentType.STAGING]: 'https://test.hogeschooltaal.nl/webshop/test',
  [EnvironmentType.PRODUCTION]: 'https://www.hogeschooltaal.nl/#pakketten',
};

export const HogesLegacyURL: Record<EnvironmentType, string> = {
  [EnvironmentType.TEST]: '#hoges-legacy',
  [EnvironmentType.LOCAL]: '#hoges-legacy',
  [EnvironmentType.DEVELOPMENT]: 'https://api-test.htaal.nl/signin-oidc',
  [EnvironmentType.STAGING]: 'https://api-stg.htaal.nl/signin-oidc',
  [EnvironmentType.PRODUCTION]: 'https://api.htaal.nl/signin-oidc',
};
