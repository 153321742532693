import { Button } from 'components/Button';
import styled from 'styled-components/macro';
import { Heading3, Heading4 } from 'styles/elements/Headings';
import { Paragraph } from 'styles/elements/Texts';
import { calculateRem } from 'styles/mixins/calculateRem';
import { ElearningThemeInterface } from 'themes/elearningInterface.theme';

interface Props {
  theme: ElearningThemeInterface;
}

export const StyledTestHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  padding: 1rem 4rem 2rem;
  flex-direction: row;

  ${Heading3} {
    margin-bottom: 0.5rem;
  }

  ${Heading4} {
    font-weight: ${({ theme }: Props) => theme.font.weight.normal};
  }

  ${Paragraph} {
    margin-bottom: 1rem;
  }
`;

export const StyledCloseIconButton = styled(Button)`
  margin-left: ${calculateRem(36.8)};
  color: ${({ theme }: Props) => theme.palette.neutral.black};
`;

export const StyledCenterContent = styled.div`
  display: flex;
  justify-content: center;
`;

export const StyledRightContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
