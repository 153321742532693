import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { ADMIN_ENVIRONMENT_ROUTES } from 'constants/routes';
import { DEFAULT_PAGE, DEFAULT_ROWS_PER_PAGE, PAGE_FILTER_KEY } from 'constants/admin-constants';
import { buildURLwithQueryParams, URLFields } from '_helpers/utils/urlBuilder';
import { useHistory, useLocation } from 'react-router-dom';
import { Paginator } from 'components/Paginator/Paginator';
import queryString from 'query-string';
import useOnLocationChange from 'hooks/useOnLocationChange';
import { useAdmin } from 'hooks/useAdmin';
import { ScheduledTestStatus } from 'models/exam/ScheduledTest';
import { OptionProps } from 'components/Select/Select.model';
import { useLogin } from 'hooks/useLogin';
import { ROLES } from '@he-learning/service-he-common';
import { TabFilter } from '../../components/TabFilter/TabFilter';
import { StyledUserScheduledTests } from './UserScheduledTests.styled';
import { AdminScheduledTestsQueryParams } from './UserScheduleTest.model';
import { TestsTable } from './components/TestsTable/TestsTable';
import { ScheduledTestsFilters } from './components/ScheduleTestsFilters/ScheduleTestsFilters';

export const UserScheduledTests: React.FC = observer(() => {
  const { t } = useTranslation('admin-environment');
  const [tabValue, setTabValue] = useState<ScheduledTestStatus>(ScheduledTestStatus.UPCOMING);

  const {
    fetchScheduledTests,
    sheduledTests,
    fetchFilteredAccounts,
    filteredAccounts,
    fetchProductSkillLevels,
    selectableSkillLevels,
  } = useAdmin();
  const { getSchoolList, schoolList = [] } = useLogin();

  const history = useHistory();
  const location = useLocation();
  const { queryParams } = useOnLocationChange(history);
  const query = queryString.parse(window.location.search);
  const [storedParams, setStoredParams] = useState<string>('');

  useEffect(() => {
    setStoredParams(location.search);
  }, [queryParams]);

  const {
    testStatus = tabValue,
    page = DEFAULT_PAGE,
    results = DEFAULT_ROWS_PER_PAGE,
    school = '',
    education = '',
    teacher = '',
    skillLevel = '',
    input = '',
  }: AdminScheduledTestsQueryParams = queryString.parse(queryParams);

  const [productSelectedId, skillLevelId]: Array<string> = skillLevel ? skillLevel.split('-') : [];

  const sendStatusQuery = query.status !== ScheduledTestStatus.ONGOING ? tabValue : undefined;

  useEffect(() => {
    fetchScheduledTests({
      testStatus: sendStatusQuery,
      page: +page,
      resultsPerPage: +results,
      teacher,
      productId: productSelectedId,
      skillLevel: skillLevelId,
      input,
    });
  }, [query.status, tabValue, page, results, teacher, education, skillLevel, input]);

  useEffect(() => {
    fetchFilteredAccounts({
      page: +page,
      results: +results,
      education,
      school,
      role: ROLES.TEACHER,
    });
  }, [school, education, teacher]);

  useEffect(() => {
    fetchProductSkillLevels();
  }, [school, education, teacher, skillLevel]);

  const paginationFilters = {
    status: testStatus,
    page,
    results,
    school,
    education,
    teacher,
    skillLevel,
    input,
  };

  useEffect(() => {
    if (!schoolList.length) {
      getSchoolList();
    }
  }, [getSchoolList, schoolList]);

  const filters = Object.keys(paginationFilters).map(key => ({
    key,
    value: paginationFilters[key as keyof typeof paginationFilters],
  })) as URLFields[];

  const handleFilters = (filterValue: string | number, key: string) =>
    filters
      .map(filter => {
        if (filter.key === key) {
          filter.key === 'status' && setTabValue(filterValue as ScheduledTestStatus);
          return { ...filter, value: filterValue };
        }
        if (filter.key === PAGE_FILTER_KEY && key !== PAGE_FILTER_KEY) {
          return { key: PAGE_FILTER_KEY, value: 1 };
        }
        return filter;
      })
      .filter(newFilter => newFilter.value);

  const onFilterChange = (filterValue: string | number, key: string) => {
    history.push(
      buildURLwithQueryParams(ADMIN_ENVIRONMENT_ROUTES.USER_SCHEDULED_TESTS, handleFilters(filterValue, key)),
    );
  };

  const links = useMemo(
    () =>
      [...Array(sheduledTests.totalPages)].map((value, filterPage: number) => {
        const newFilters = filters.filter(filterValue => filterValue.value !== '');
        newFilters[newFilters.findIndex(newFilter => newFilter.key === PAGE_FILTER_KEY)].value = filterPage + 1;
        const searchURL = buildURLwithQueryParams(ADMIN_ENVIRONMENT_ROUTES.USER_SCHEDULED_TESTS, newFilters);

        return {
          path: searchURL,
          title: filterPage + 1,
        };
      }),
    [sheduledTests.totalPages],
  );

  const tabsFiltersValues = [
    {
      value: ScheduledTestStatus.UPCOMING,
      dataCy: 'upcoming-filter',
      text: t('userFilter.scheduled.upcoming', 'Upcomming tests'),
    },
    {
      value: ScheduledTestStatus.ONGOING,
      dataCy: 'ongoing-filter',
      text: t('userFilter.scheduled.ongoing', 'Tests archive'),
    },
  ];

  const { schoolsFilterList, educationsFilterList, teachersFilterList, skillLevelsFilterList } = useMemo(() => {
    const schools: OptionProps[] = [
      {
        label: t('userFilter.school.defaultValue.label', 'All schools'),
        value: 'all',
      },
      ...schoolList.map(schoolItem => ({
        value: schoolItem.id,
        label: schoolItem.title,
      })),
    ];

    const schoolEducations = schoolList?.find(schoolItem => schoolItem.id === school)?.educations || [];
    const educations = [
      {
        label: t('userFilter.education.defaultValue.label', 'All educations'),
        value: 'all',
      },
      ...schoolEducations.map(educationItem => ({
        value: educationItem.id,
        label: educationItem.title,
      })),
    ];

    const teachers: OptionProps[] = [
      {
        label: t('userFilter.teachers.label', 'All teachers'),
        value: 'all',
      },
      ...(filteredAccounts.data
        ? filteredAccounts.data.map(account => ({
            value: account.registrationId,
            label: account.fullName,
          }))
        : []),
    ];

    const skillLevels = [
      {
        label: `${t('userFilter.skillLevel.label', 'Skill level')}`,
        value: 'all',
      },
      ...(!!selectableSkillLevels && selectableSkillLevels.length > 0
        ? selectableSkillLevels.map(product => ({
            value: `${product.productId}-${product.skillLevel}`,
            label: product.productTitle.concat(' - ', product.skillLabel),
          }))
        : []),
    ];

    return {
      schoolsFilterList: schools,
      educationsFilterList: educations,
      teachersFilterList: teachers,
      skillLevelsFilterList: skillLevels,
    };
  }, [schoolList, school, filteredAccounts.data, selectableSkillLevels]);

  return (
    <StyledUserScheduledTests>
      <TabFilter
        selectedTab={(query.status as string) || tabValue}
        tabsFiltersValues={tabsFiltersValues}
        onTabChange={selectedTab => onFilterChange(selectedTab, 'status')}
      />

      <ScheduledTestsFilters
        educations={educationsFilterList}
        inputValue={input}
        schools={schoolsFilterList}
        skillLevels={skillLevelsFilterList}
        teachers={teachersFilterList}
        onInputChange={(selectedInput: string) => onFilterChange(selectedInput, 'input')}
        onSelectChange={(selectedValue: string, key: string) => onFilterChange(selectedValue, key)}
      />

      <TestsTable
        selectedResults={+results}
        sheduledTests={sheduledTests}
        storedParams={storedParams}
        onResultsChange={selectedResults => onFilterChange(selectedResults, 'results')}
      />

      {sheduledTests.data && sheduledTests.data.length > 0 && (
        <Paginator
          currentPage={+page}
          links={links}
          totalPages={sheduledTests.totalPages}
          onChangePage={selectedPage => onFilterChange(selectedPage, 'page')}
        />
      )}
    </StyledUserScheduledTests>
  );
});
