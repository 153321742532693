import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import format from 'date-fns/format';
import addYears from 'date-fns/addYears';
import { ModalRenderType } from 'components/Modal/Modal.model';
import { AvailableLanguage } from 'constants/language-constants';
import { modalIds } from 'constants/modals-constants';

import { DatePicker } from 'components/DatePicker/DatePicker';
import { Modal } from 'components/Modal';
import { useModal } from 'hooks/useModal';
import { useProduct } from 'hooks/useProduct';
import { Heading3 } from 'styles/elements/Headings';

import {
  StyledAccountProductModal,
  StyledButton,
  StyledInformationLabel,
  StyledInformationContainer,
  StyledInformation,
  StyledSelect,
} from './AddProductModal.styled';
import { AddProductModalProps } from './AddProductModal.model';

export const AddProductModal: React.FC<AddProductModalProps> = ({ onAddNewProduct, addedProducts }) => {
  const { closeModal, isModalOpen } = useModal();
  const { productsList } = useProduct();
  const { t, i18n } = useTranslation('admin-environment');
  const [productId, setProductId] = useState<number>(0);
  const today = new Date();
  const [validUntilDate, setValidUntilDate] = useState<Date>(addYears(today, 1));
  const productsOptions = [
    {
      value: 0,
      label: t('accountDetails.newProduct.productsSelector.defaultValue', 'Select a product...'),
    },
    ...(productsList
      ?.filter(product => !addedProducts.some(addedProduct => addedProduct.id === product.id))
      .map(filteredProduct => ({
        value: filteredProduct.id,
        label: filteredProduct.title,
      })) || []),
  ];

  return (
    <Modal
      dataCy="add-account-product-modal"
      isOpen={isModalOpen(modalIds.addAccountProductModal)}
      renderToElementType={ModalRenderType.REACT_PORTAL}
      onClose={closeModal}
    >
      <StyledAccountProductModal>
        <Heading3>{t('accountDetails.newProduct.header', 'Add new product')}</Heading3>
        <StyledSelect
          ariaLabel="product-selector"
          dataCy="product-selector"
          options={productsOptions}
          onChange={event => setProductId(+event.target.value)}
        />
        <StyledInformationContainer>
          <div>
            <StyledInformationLabel>
              {t('accountDetails.productUpdate.dateOfPurchase', 'Date of purchase')}
            </StyledInformationLabel>
            <StyledInformation>{format(today, 'dd-MM-yyyy')}</StyledInformation>
          </div>
          <div>
            <StyledInformationLabel>
              {t('accountDetails.productUpdate.timeOfPurchase', 'Time of purchase')}
            </StyledInformationLabel>
            <StyledInformation>{format(today, 'HH:mm')}</StyledInformation>
          </div>
        </StyledInformationContainer>
        <StyledInformationLabel>
          {t('accountDetails.productUpdate.validUntilDate', 'Valid till')}
        </StyledInformationLabel>
        <DatePicker
          dataCy="valid-date-picker"
          language={i18n.language as AvailableLanguage}
          minDate={today}
          value={validUntilDate}
          onChange={date => setValidUntilDate(date)}
        />
        <StyledButton
          dataCy="save-product-button"
          disabled={!productId}
          variant="primary"
          onClick={() => {
            onAddNewProduct({
              id: productId,
              type: productsList?.find(product => product.id === productId)?.type,
              validSinceDate: today.toISOString(),
              validUntilDate: validUntilDate.toISOString(),
            });
            closeModal();
          }}
        >
          {t('scheduledTestList.item.updateTestTime.button.label', 'Save')}
        </StyledButton>
      </StyledAccountProductModal>
    </Modal>
  );
};
