import { useParams } from 'react-router-dom';
import { useProduct } from 'hooks/useProduct';

interface ProductPathParams {
  productId: string;
}

export const useSelectedProductId = (): number | null => {
  const { productId: pathProductId }: ProductPathParams = useParams();
  const { productDetails, productsList } = useProduct();

  const prevSelectedProductId = parseInt(localStorage.getItem('selectedProduct') ?? '');

  let selectedProductId = null;

  // Id in the URL shall always prevail
  if (pathProductId && !isNaN(+pathProductId)) {
    selectedProductId = +pathProductId;
  }

  // If URL has no product id, we recover the last selection from the local storage (if available)
  if (!selectedProductId && !isNaN(prevSelectedProductId)) {
    return prevSelectedProductId;
  }

  // If no product was found in the storage, we'll try to guess it from the contents of the MobX store
  if (!selectedProductId && productDetails?.id) {
    selectedProductId = productDetails.id;
  }

  // If we still cannot find any specific selection, we automatically pick up the first product in the list
  if (!selectedProductId && productsList && productsList.length > 0 && productsList[0]?.id) {
    selectedProductId = productsList[0]?.id;
  }

  // If value in storage is outdated, we make sure to modify it
  if (prevSelectedProductId !== selectedProductId) {
    localStorage.setItem('selectedProduct', String(selectedProductId));
  }

  return selectedProductId;
};
