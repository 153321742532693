import { CorrectTextViewMode, WordAction, WordActionUpdate } from 'models/exam/CorrectTextQuestion';
import { UserAnswer } from 'models/exam/Exam';

type WordId = number;
type WordUpdatesList = readonly [WordId, WordActionUpdate][];

export const getWordElementId = (questionId: number, wordId: WordId): string =>
  `correct-text-${questionId}-word-${wordId}`;

// eslint-disable-next-line max-len
export const getWordElementById = (questionId: number, wordId?: WordId | null): HTMLElement | null =>
  wordId ? document.getElementById(getWordElementId(questionId, wordId)) : null;

const findWordUpdate = (wordUpdates: WordUpdatesList, wordId: WordId): WordActionUpdate | undefined => {
  const update = wordUpdates.find(([wId]) => wId === wordId);
  if (!update) {
    return undefined;
  }
  return update[1];
};

export const wordActionsToAnswers = (userWordActions: WordUpdatesList): UserAnswer[] =>
  userWordActions.map(([wordId, wordUpdate]) => ({
    order: wordId,
    actionType: wordUpdate.action as WordAction,
    value: wordUpdate.updatedValue,
  }));

export const answersToWordActions = (userAnswers: UserAnswer[], wordList: [number, string][]): WordUpdatesList =>
  userAnswers.map(userAnswer => [
    userAnswer.order,
    {
      wordId: userAnswer.order,
      action: userAnswer.actionType as Omit<WordAction, WordAction.NONE>,
      updatedValue: userAnswer.value as string,
      originalValue: wordList.find(([wordId]) => wordId === userAnswer.order)?.[1] || '',
    },
  ]);

export const getWordUpdateAction = (wordUpdates: WordUpdatesList, wordId?: WordId | null): WordAction => {
  if (!wordId) {
    return WordAction.NONE;
  }
  const update = findWordUpdate(wordUpdates, wordId);
  return update ? (update.action as WordAction) : WordAction.NONE;
};

export const getWordUpdateValue = (wordUpdates: WordUpdatesList, wordId?: number | null): string => {
  if (!wordId) {
    return '';
  }
  const update = findWordUpdate(wordUpdates, wordId);
  return update ? update.updatedValue : '';
};

export const getNextWord = (wordList: readonly [number, string][], wordId: number): string | undefined => {
  const current = wordList.find(([wId]) => wId === wordId);
  if (!current) return undefined;
  const next = wordList[wordList.indexOf(current) + 1];
  return next && next[1] ? next[1] : undefined;
};

export const getViewMode = (hasResults: boolean, isComparingAnswers: boolean): CorrectTextViewMode => {
  if (!hasResults) {
    return CorrectTextViewMode.EDITING;
  }
  return isComparingAnswers ? CorrectTextViewMode.COMPARE : CorrectTextViewMode.RESULTS;
};
