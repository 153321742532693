import styled from 'styled-components/macro';

import { ThemeProps as Props } from 'models/styles/theme';
import { ExerciseStatus } from 'models/writing/WritingDetail';

import { Button } from 'components/Button';

export const StyledSelector = styled.section<Props>`
  position: relative;
`;

export const StyledButton = styled(Button)<Props>`
  display: flex;
  align-items: center;

  font-weight: bold;
  padding-left: 1rem;
  padding-right: 1rem;

  > i {
    padding-left: 0.75rem;
  }
`;

export const StyledList = styled.ul<Props>`
  position: absolute;
  right: 0;
  top: 2rem;
  min-width: 16rem;

  background: ${(props: Props) => props.theme.palette.neutral.white};
  box-shadow: rgb(0 0 0 / 21.82%) 0 2px 8px;

  padding: 1rem;
  list-style: none;
  border-radius: 4px;
`;

export const StyledListItem = styled.li<Props>`
  :not(:last-child) {
    margin-bottom: 1.25rem;
  }

  display: flex;
  align-items: center;
  justify-content: space-between;

  :hover {
    cursor: pointer;
  }
`;

interface StyledStatusLabelProps extends Props {
  status?: ExerciseStatus;
}

export const StyledStatusLabel = styled.span<StyledStatusLabelProps>`
  font-size: 0.8rem;
  border-radius: 4px;
  padding: 0.25rem 0.5rem;

  background: ${(props: StyledStatusLabelProps) => {
    if (props.status === ExerciseStatus.CORRECT) {
      return props.theme.palette.feedback.success.brand;
    }

    if (props.status === ExerciseStatus.INCORRECT) {
      return props.theme.palette.feedback.error._200;
    }

    return props.theme.palette.neutral._200;
  }};
`;
