import styled from 'styled-components/macro';

import { calculateRem } from 'styles/mixins/mixins';
import { ThemeProps as Props } from 'models/styles/theme';

import { ListNavigationItem } from 'components/ListNavigationItem';

export const StyledContainer = styled.main<Props>`
  width: 70%;
  margin: 2.5rem auto;
  max-width: {${calculateRem(976)};
`;

export const StyledExerciseList = styled.section<Props>`
  padding: 2.5rem 0;

  > * {
    margin: 1rem 0;
  }
`;

export const StyledExerciseListItem = styled(ListNavigationItem)<Props>`
  margin: 1rem 0;
`;

export const StyledProgressBar = styled.div<Props>`
  display: flex;
  align-items: center;
  column-gap: 1rem;
  width: 10.5rem;
  justify-content: space-between;
`;
