import React, { FC, ReactNode, useRef, useState } from 'react';
import { StyledAbsoluteToolTip } from './AbsoluteToolTip.styled';

export interface AbsoluteToolTipProps {
  children: ReactNode;
  text: string;
}

interface Position {
  top: number;
  left: number;
}

export const AbsoluteToolTip: FC<AbsoluteToolTipProps> = ({ children, text }) => {
  const [shouldShow, setShouldShow] = useState<boolean>(false);
  const [childPosition, setChildPosition] = useState<Position>({ left: 0, top: 0 });
  const toolTipRef = useRef<HTMLDivElement>(null);

  const onChildUpdated = (div: HTMLDivElement | null) => {
    if (!div) return;

    const { top, left, height, width } = div.getBoundingClientRect();
    const desiredLeft = left + width / 2;

    if (top && left && (top + height !== childPosition?.top || desiredLeft !== childPosition.left)) {
      setChildPosition({ top: top + height, left: desiredLeft });
    }
  };

  return (
    <>
      <div
        ref={reference => onChildUpdated(reference)}
        onMouseEnter={() => setShouldShow(true)}
        onMouseLeave={() => setShouldShow(false)}
      >
        {children}
      </div>
      {shouldShow && (
        <StyledAbsoluteToolTip ref={toolTipRef} $left={childPosition.left} $top={childPosition.top}>
          {text}
        </StyledAbsoluteToolTip>
      )}
    </>
  );
};
