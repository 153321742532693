import styled from 'styled-components/macro';

import { ThemeProps as Props } from 'models/styles/theme';
import { Heading2, Heading3 } from 'styles/elements/Headings';

export const StyledContainer = styled.main<Props>`
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  padding: 2rem;
  align-items: center;
`;

export const StyledTitle = styled(Heading2)<Props>`
  margin-bottom: 0;
`;

export const StyledNotice = styled(Heading3)<Props>`
  display: flex;
  align-items: center;
  font-weight: normal;
  column-gap: 1rem;
`;
