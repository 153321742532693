import styled from 'styled-components/macro';

export const StyledErrorRendererContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  text-align: center;
`;

export const StyledErrorRendererTitle = styled.h1`
  font-size: 2em;
  margin: 0.5em 0;
`;

export const StyledErrorRendererDetails = styled.p`
  font-size: 1.5em;
  margin: 0.5em 0;
`;
