import styled from 'styled-components/macro';
import { calculateRem } from 'styles/mixins/calculateRem';

import { ElearningThemeInterface } from 'themes/elearningInterface.theme';

interface Props {
  selected: boolean;
  theme: ElearningThemeInterface;
  onClick?: (filter: string) => void;
}

export const StyledIcon = styled.div<Props>`
  span {
    color: ${({ selected, theme }: Props) => (selected ? theme.palette.neutral.white : theme.palette.neutral.black)};
    font-size: ${({ theme }: Props) => theme.font.size._24};
    font-weight: ${({ theme }: Props) => theme.font.weight.bold};
    margin-left: ${calculateRem(5)};
  }

  .icon > path {
    fill: ${({ selected, theme }: Props) => (selected ? theme.palette.neutral.white : theme.palette.neutral.black)};
  }
`;

export const StyledFilter = styled.div<Props>`
  align-self: center;
  background-color: ${({ selected, theme }: Props) =>
    selected ? theme.palette.secondary.brand : theme.palette.neutral.white};
  border: ${calculateRem(1)} solid ${({ theme }: Props) => theme.palette.neutral._400};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: ${calculateRem(104)};
  margin: ${calculateRem(24)} ${calculateRem(8)};
  padding: ${calculateRem(16)};
  width: ${calculateRem(160)};

  &:first-of-type {
    margin-left: ${calculateRem(24)};
  }

  & > span {
    color: ${({ selected, theme }: Props) => (selected ? theme.palette.neutral.white : theme.palette.neutral.black)};
    font-size: ${({ theme }: Props) => theme.font.size._14};
  }
`;
