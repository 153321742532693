import React from 'react';
import { OptionProps } from 'components/Select/Select.model';
import { StyledInputSelectAdmin } from './InputSelectAdmin.styled';

interface InputSelectProps {
  dataCy?: string;
  ariaLabel?: string;
  options?: OptionProps[];
  onSelectChange: React.ChangeEventHandler<HTMLSelectElement>;
  defaultValue?: string | number;
  disabled?: boolean;
  value?: string;
  id?: string;
}

export const InputSelectAdmin: React.FC<InputSelectProps> = ({
  defaultValue,
  dataCy,
  ariaLabel,
  options,
  onSelectChange,
  disabled,
  value,
  id,
}) => (
  <StyledInputSelectAdmin
    ariaLabel={ariaLabel || ''}
    dataCy={dataCy}
    defaultValue={defaultValue}
    disabled={disabled}
    id={id}
    options={options}
    value={value}
    onChange={onSelectChange}
  />
);
