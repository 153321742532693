import styled from 'styled-components/macro';
import { ThemeProps as Props } from 'models/styles/theme';

import registerSucessfulBackground from 'assets/images/registersuccessful.png';

export const StyledPage = styled.div<Props>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: url(${registerSucessfulBackground});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;

export const StyledInnerText = styled.div<Props>`
  width: 40rem;
  margin-bottom: 10rem;
  text-align: center;

  > button {
    margin-top: 1rem;
  }
`;

export const StyledIconWrapper = styled.div<Props>`
  background: ${({ theme }: Props) => theme.palette.feedback.success._200};
  width: 12rem;
  height: 12rem;
  margin: auto;
  margin-bottom: 2rem;
  padding-top: 0.5rem;
  border-radius: 50%;
`;
