import { SvgIconReact } from 'components/SvgIconReact';
import React from 'react';
import { CheckboxProps } from './Checkbox.model';
import { StyledCheckbox, StyledCheckmark, StyledLabel } from './Checkbox.styled';

export const Checkbox: React.FC<CheckboxProps> = ({
  label,
  checked = false,
  disabled = false,
  dataCy,
  onChange,
  ...checkboxProps
}) => (
  <StyledLabel checked={checked} checkedColor={checkboxProps.color} data-cy={dataCy} disabled={disabled}>
    {label}
    <StyledCheckbox checked={checked} disabled={disabled} type="checkbox" onChange={onChange} {...checkboxProps} />
    <StyledCheckmark checked={checked} disabled={disabled}>
      <SvgIconReact iconName="checkIcon" />
    </StyledCheckmark>
  </StyledLabel>
);
