import styled from 'styled-components/macro';

import { ThemeProps as Props } from 'models/styles/theme';

import { SvgIconReact } from 'components/SvgIconReact';

export const StyledPage = styled.div`
  width: 100%;
  min-height: 100%;
  padding: 5rem 0;

  display: flex;
  align-items: center;
  justify-content: center;

  background-size: 100%;
  background-image: url(${(props: Props) => props.theme.backgroundPages.loginValidate});
`;

export const StyledCard = styled.div`
  width: 40rem;
  height: fit-content;
  background: ${(props: Props) => props.theme.palette.neutral.white};
  padding: 4rem 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledContainer = styled.main`
  width: 100%;
  display: flex;
  margin-top: 3rem;
  flex-direction: column;
`;

export const StyledHogesLogo = styled(SvgIconReact)`
  width: auto;
  height: auto;
  max-width: 20rem;
  min-height: 40px;
`;
