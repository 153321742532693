import React, { useState } from 'react';

type ModalParameters = Record<string, string | number | boolean | null>;

interface ModalContextValues {
  modalParams?: ModalParameters;
  toggleModal: (modalToOpenId?: string, params?: ModalParameters) => void;
  openModal: (modalToOpenId: string) => void;
  closeModal: () => void;
  isModalOpen: (modalIdToCheck: string) => boolean;
}

const defaultValues: ModalContextValues = {
  modalParams: {},
  openModal: () => undefined,
  toggleModal: () => 0,
  closeModal: () => 0,
  isModalOpen: () => false,
};

export const ModalContext: React.Context<ModalContextValues> = React.createContext<ModalContextValues>(defaultValues);

export const ModalProvider: React.FC = ({ children }) => {
  const [selectedModalId, setSelectedModalId] = useState<string | null>(null);
  const [modalParams, setModalParams] = useState<ModalParameters>({});

  const closeModal = () => {
    setSelectedModalId(null);
    setModalParams({});
  };

  const openModal = (modalToOpenId: string) => {
    setSelectedModalId(modalToOpenId);
  };

  const toggleModal = (modalToOpenId?: string, params?: ModalParameters): void => {
    if (modalToOpenId && modalToOpenId !== selectedModalId) {
      setSelectedModalId(modalToOpenId);
      setModalParams(params || {});
    } else {
      closeModal();
    }
  };

  const isModalOpen = (modalIdToCheck: string): boolean => modalIdToCheck === selectedModalId;

  return (
    <ModalContext.Provider
      // @ts-ignore
      displayName="Modal"
      value={{
        modalParams,
        openModal,
        isModalOpen,
        toggleModal,
        closeModal,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};
