import React from 'react';
import { Typography } from 'styles/helpers/layout';
import { StyledStatusTag } from './StatusTag.styled';

export interface StatusProps {
  className?: string;
  label: string;
  dataCy?: string;
}

export const StatusTag: React.FC<StatusProps> = ({ className, label, dataCy }) => {
  return (
    <StyledStatusTag className={className} data-cy={dataCy}>
      <Typography>{label}</Typography>
    </StyledStatusTag>
  );
};
