import { ErrorRenderer } from 'components/ErrorRenderer/ErrorRenderer';
import { ViewTheoryModal } from 'components/ViewTheoryModal';
import { QuestionType, TestType } from 'constants/exam-constants';
import { useAnswer } from 'hooks/useAnswer';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { useModal } from 'hooks/useModal';
import { QuestionAnswerBox } from 'layouts/QuestionAnswerBox/QuestionAnswerBox';
import { observer } from 'mobx-react';
import { ExamQuestion } from 'models/exam/Exam';
import { TopicDetails } from 'models/product/TopicDetails';
import { logAPMError } from 'observability';
import React, { FC, ReactElement } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Box, CssGrid } from 'styles/helpers/layout';

const textQuestions = [
  QuestionType.PROGRESSIVE_TEXT,
  QuestionType.PROGRESSIVE_OVERVIEW,
  QuestionType.PROGRESSIVE_DOCUMENT,
  QuestionType.TEXT_AREA,
];

export interface HstContentPlayerProps {
  testType: TestType;
  header: ReactElement;
  content: ReactElement;
  selectedQuestion: ExamQuestion | undefined;
  loadNextQuestion: () => void;
  topicDetails: TopicDetails | undefined;
}

export const HstContentPlayer: FC<HstContentPlayerProps> = observer(
  ({ content, header, loadNextQuestion, testType, selectedQuestion, topicDetails }) => {
    const { questionStatus, questionResult } = useAnswer();
    const { minimumTheoryContentLength } = useFeatureFlags();
    const { closeModal } = useModal();

    const hasTheoryToShow = (topicDetails: TopicDetails | undefined): boolean => {
      // Convert the content from html to plain text to evaluate the length of it
      const plainTextContent =
        new DOMParser().parseFromString(topicDetails?.content || '', 'text/html').body.textContent || '';

      return (
        testType !== TestType.SKILL_DETERMINATION_TEST &&
        (selectedQuestion?.topicId !== null || selectedQuestion?.topicId !== undefined) &&
        plainTextContent.length >= +(parseInt(minimumTheoryContentLength as string) || 0)
      );
    };

    return (
      <ErrorBoundary
        fallbackRender={({ error }) => <ErrorRenderer message={error?.message || 'Error loading application'} />}
        onError={(error, { componentStack }) =>
          logAPMError(error, componentStack, {
            component: 'HstContentPlayer',
            testType,
            selectedQuestion: JSON.stringify(selectedQuestion),
            topicDetails: JSON.stringify(topicDetails),
          })
        }
      >
        <CssGrid gridTemplateRows="10rem 1fr auto" height="100vh">
          {topicDetails && <ViewTheoryModal topic={topicDetails} onClose={closeModal} />}
          {header}
          <Box width={selectedQuestion && textQuestions.includes(selectedQuestion.type) ? '100vw' : ''}>{content}</Box>
          {selectedQuestion && (
            <QuestionAnswerBox
              loadNextQuestion={loadNextQuestion}
              question={selectedQuestion}
              questionResult={questionResult}
              shouldAllowShowingTheory={hasTheoryToShow(topicDetails)}
              status={questionStatus}
              testType={testType}
            />
          )}
        </CssGrid>
      </ErrorBoundary>
    );
  },
);
