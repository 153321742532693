import * as Yup from 'yup';
import i18n from 'i18n';

import { AdminProductItem } from 'models/product/ProductItem';

export interface UserUpdateFormFields {
  id: string;
  firstName: string;
  lastName: string;
  middleName?: string;
  educations: Array<string>;
  email: string;
  products: Array<AdminProductItem>;
  studentNumber?: string;
  allowedInviteProducts?: boolean;
}

export const buildUpdateFormSchema = (): Yup.SchemaOf<UserUpdateFormFields> =>
  Yup.object({
    id: Yup.string().required(),
    firstName: Yup.string().required(i18n.t('admin-environment:accountDetails.firstName.errors.required')),
    lastName: Yup.string().required(i18n.t('admin-environment:accountDetails.lastName.errors.required')),
    middleName: Yup.string(),
    educations: Yup.array()
      .of(Yup.string().required())
      .min(1, i18n.t('admin-environment:accountDetails.educations.errors.required'))
      .required(),
    email: Yup.string().required(i18n.t('admin-environment:accountDetails.email.errors.required')),
    studentNumber: Yup.string(),
    products: Yup.array()
      .of(
        Yup.object({
          id: Yup.number().required(),
          type: Yup.string(),
          validSinceDate: Yup.date().nullable(true),
          validUntilDate: Yup.date().nullable(true),
        }),
      )
      .required(),
    allowedInviteProducts: Yup.boolean(),
  }).required();
