import styled from 'styled-components/macro';
import { ThemeProps as Props } from 'models/styles/theme';

import { SingleSelectQuestion } from '../SingleSelectQuestion';

export const StyledSingleSelectQuestion = styled(SingleSelectQuestion)`
  width: 50%;
  .question-wrapper {
    .opdracht {
      font-size: ${(props: Props) => props.theme.font.size._16};
    }
  }
`;
