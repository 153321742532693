import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useLanguage } from 'hooks/useSelectedLanguage';
import { SeparatorLine } from 'components/SeparatorLine';
import { Heading4 } from 'styles/elements/Headings';
import { IconButton } from 'components/IconButton/IconButton';
import {
  StyledSideMenu,
  StyledMenuInputs,
  StyledButtonCloseAndLogout,
  StyledTitle,
  StyledTitleHeading4,
  StyledInput,
  StyledFieldSet,
  StyledSubmitButton,
  StyledLanguageSelector,
} from './SideMenu.styled';
import { InfoComponentsProps, SideMenuProps } from './SideMenu.model';

const InfoComponent = ({ label = '', content = '', dataCy }: InfoComponentsProps) => (
  <div className="info-component" data-cy={dataCy}>
    {label}
    <SeparatorLine direction="horizontal" height={0.0625} />
    <Heading4>{content}</Heading4>
  </div>
);

export const SideMenu: React.FC<SideMenuProps> = ({ userDetails, onClose, onLogout, onSubmit }) => {
  const { t } = useTranslation('modal');
  const { currentLanguage, changeLanguage } = useLanguage();

  const [userName, setUserName] = useState(userDetails?.firstName || '');
  const [userMiddleName, setUserMiddleName] = useState(userDetails?.middleName || '');
  const [userLastName, setUserLastName] = useState(userDetails?.lastName || '');
  const [language, setLanguage] = useState(currentLanguage);

  useEffect(() => {
    if (userDetails) {
      setUserName(userDetails.firstName);
      setUserMiddleName(userDetails.middleName);
      setUserLastName(userDetails.lastName);
    }
  }, [userDetails]);

  const userDetailsChanged = () => {
    if (!userDetails) {
      return false;
    }
    return (
      userDetails.firstName !== userName ||
      userDetails.middleName !== userMiddleName ||
      userDetails.lastName !== userLastName
    );
  };
  const languageChanged = () => language !== currentLanguage;

  const closeAndLogout = () => {
    onClose();
    onLogout();
  };

  const onChangeUserInfo = (e: ChangeEvent<HTMLInputElement>) => {
    e.target.id === 'name' && setUserName(e.target.value);
    e.target.id === 'middle-name' && setUserMiddleName(e.target.value);
    e.target.id === 'last-name' && setUserLastName(e.target.value);
  };

  const onSubmitUserInfo = () => {
    if (userDetailsChanged() && userDetails) {
      onSubmit({
        email: userDetails.email,
        firstName: userName,
        lastName: userLastName,
        middleName: userMiddleName,
      });
    }
    if (languageChanged()) {
      changeLanguage(language);
    }
  };

  return (
    <StyledSideMenu>
      <StyledTitle>
        <IconButton
          dataCy="close-side-menu-button"
          fontColor="grey"
          iconName="hamburgerIcon"
          onClick={() => onClose()}
        />
        <StyledTitleHeading4>{userName}</StyledTitleHeading4>
      </StyledTitle>
      <StyledButtonCloseAndLogout onClick={closeAndLogout}>
        {t('sideMenuModal.logout', 'Log Out')}
      </StyledButtonCloseAndLogout>
      <StyledMenuInputs>
        <InfoComponent content={userDetails?.email} label={t('sideMenuModal.email.label', 'E-mail')} />
        <StyledFieldSet>
          <label htmlFor="name">{t('sideMenuModal.firstName.label', 'Name')}</label>
          <StyledInput
            dataCy="user-first-name-input"
            id="name"
            placeholder={t('sideMenuModal.firstName.placeholder', 'Name')}
            value={userName}
            onChange={e => onChangeUserInfo(e)}
          />
        </StyledFieldSet>
        <StyledFieldSet>
          <label htmlFor="middle-name">{t('sideMenuModal.middleName.label', 'Middle name')}</label>
          <StyledInput
            dataCy="user-middle-name-input"
            id="middle-name"
            placeholder={t('sideMenuModal.middleName.placeholder', 'Middle name')}
            value={userMiddleName}
            onChange={e => onChangeUserInfo(e)}
          />
        </StyledFieldSet>
        <StyledFieldSet>
          <label htmlFor="last-name">{t('sideMenuModal.lastName.label', 'Last Name')}</label>
          <StyledInput
            dataCy="user-last-name-input"
            id="last-name"
            placeholder={t('sideMenuModal.lastName.placeholder', 'Last Name')}
            value={userLastName}
            onChange={e => onChangeUserInfo(e)}
          />
        </StyledFieldSet>
        <StyledFieldSet>
          <label htmlFor="language">{t('sideMenuModal.selectLabel', 'Site language')}</label>
          <StyledLanguageSelector id="language" selectedLanguage={language} onChangeLanguage={setLanguage} />
        </StyledFieldSet>
      </StyledMenuInputs>
      <StyledSubmitButton
        dataCy="update-user-details-btn"
        disabled={!userDetailsChanged() && !languageChanged()}
        size="medium"
        variant="primary"
        onClick={onSubmitUserInfo}
      >
        {t('sideMenuModal.buttonChangeLabel', 'Save Changes')}
      </StyledSubmitButton>
    </StyledSideMenu>
  );
};
