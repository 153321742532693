import React from 'react';
import { observer } from 'mobx-react';

import { SvgIconReact } from 'components/SvgIconReact';
import { buildUrlWithPathParams } from '_helpers/utils/urlBuilder';
import { InfoStatus } from 'components/InfoStatus';
import { useParams } from 'react-router-dom';
import { TEST_PAGE_ROUTES } from 'constants/routes';
import { DiagnosticTestPagePathParams } from 'models/path/path-params';
import { DiagnosticTestListItemProps } from './DiagnosticTestListItem.model';
import {
  StyledAccordionDiagnosticListItem,
  StyledDiagnosticTestRowItem,
  StyledPanelRightHeader,
} from './DiagnosticTestListItem.styled';

export const DiagnosticTestListItem: React.FC<DiagnosticTestListItemProps> = observer(
  ({ item, title = '', testId, status }) => {
    const { productId, moduleId, groupId }: DiagnosticTestPagePathParams = useParams();
    return (
      <StyledDiagnosticTestRowItem
        to={buildUrlWithPathParams(TEST_PAGE_ROUTES.DIAGNOSTIC_TEST_DETAILS, {
          productId,
          moduleId,
          groupId,
          testId,
        })}
      >
        <StyledAccordionDiagnosticListItem
          key={item.id}
          dataCy={`diagnostic-test-list-item-${item.id}`}
          headerPanelRight={
            <StyledPanelRightHeader>
              <InfoStatus status={status} />
              <SvgIconReact iconName="rightArrow" />
            </StyledPanelRightHeader>
          }
          title={title}
        />
      </StyledDiagnosticTestRowItem>
    );
  },
);
