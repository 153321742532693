import React from 'react';

import { useLanguage } from 'hooks/useSelectedLanguage';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { TranslationMessage } from 'models/featureFlag/FeatureFlagsSet';

import { AccessPageLayout } from 'layouts/AccessPageLayout';
import { Heading2 } from 'styles/elements/Headings';
import { StyledMessageText } from './PlatformDownMessagePage.styled';

export const PlatformDownPage: React.FC = () => {
  const { platformDownMessage } = useFeatureFlags();
  const { currentLanguage } = useLanguage();

  const { title, body } = JSON.parse<TranslationMessage>(platformDownMessage)[currentLanguage];

  return (
    <AccessPageLayout>
      <div data-cy="platform-down-message-modal">
        <Heading2>{title}</Heading2>
        <StyledMessageText>
          {body.split('\n').map((line, ix) => (
            <p key={ix}>{line}</p>
          ))}
        </StyledMessageText>
      </div>
    </AccessPageLayout>
  );
};
