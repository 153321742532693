import styled from 'styled-components/macro';
import { ThemeProps } from 'models/styles/theme';

export const StyledSection = styled.div<ThemeProps>`
  width: 90%;
  border-bottom: 1px solid ${({ theme }: ThemeProps) => theme.palette.neutral._600};
  margin-top: 3rem;
`;

export const StyledField = styled.div<ThemeProps>`
  display: flex;
  flex-direction: column;
  margin-right: 3rem;

  label {
    font-weight: ${({ theme }: ThemeProps) => theme.font.weight.bolder};
    margin-bottom: 1rem;
  }
`;

export const StyledForm = styled.form`
  display: flex;
  align-items: flex-end;
  margin-bottom: 1.5rem;

  > :first-child,
  > :nth-child(3) {
    width: 20%;
  }

  > :nth-child(2) {
    width: 15%;
  }

  > button {
    height: 3rem;
  }
`;
