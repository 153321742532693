export const modalIds = {
  modalSideMenu: 'side-menu-modal',
  skillDeterminationTestModal: 'skill-determination-test-modal',
  diagnosticTestModal: 'diagnostic-test-modal',
  progressiveTextQuestionModal: 'progressive-text-question-modal',
  viewTheoryModal: 'view-theory-modal',
  correctTextQuestionModal: 'correct-text-question-modal',
  resetSubjectProgressModal: 'reset-subject-progress-modal',
  completedSubject: 'completed-subject',
  passwordRequiredModal: 'password-required-modal',
  finishTest: 'finish-test-modal',
  examRuleFailingModal: 'exam-rule-failing-modal',
  showPasswordModal: 'show-password-modal',
  updateTestTimeModal: 'update-test-time-modal',
  updateAccountProductModal: 'update-account-product-modal',
  addAccountProductModal: 'add-account-product-modal',
  customPassingGradeModal: 'passing-grade-checkbox-modal',
  cumulativeCheckboxModal: 'cumulative-checkbox-modal',
  customPasswordModal: 'custom-password-checkbox-modal',
  overviewEnabledModal: 'overview-enabled-checkbox-modal',
  proctoringEnabledModal: 'proctoring-enabled-checkbox-modal',
  scheduledTestResultsReportModal: 'scheduled-test-results-report-modal',
  deleteGroupModal: 'deleteGroupModal',
  platformMaintainanceModal: 'platform-maintainance-modal',
  sebKeysModal: 'seb-keys-modal',
  transferResultsModal: 'transfer-results-modal',
  deleteAccountsModal: 'delete-accounts-modal',
  sebKeysInfoModal: 'seb-keys-info-modal',
  updateScheduledTest: 'update-scheduled-test',
  writingStudentListModal: 'writing-student-list-modal',
  studentTargetSelectorModal: 'student-goal-level-selector-modal',
  detailedStudentProgressModal: 'detailed-student-progress-modal',
};
