import styled from 'styled-components/macro';
import { Heading2, Heading4 } from 'styles/elements/Headings';
import { ElearningThemeInterface } from 'themes/elearningInterface.theme';

interface Props {
  theme: ElearningThemeInterface;
}

export const StyledHeading2 = styled(Heading2)<Props>`
  font-weight: 400;
`;

export const StyledHeading4 = styled(Heading4)<Props>`
  font-weight: 400;
`;

export const StyledHomePageWrapper = styled.div<Props>`
  text-align: center;
  width: 100%;
`;

export const LiveTestWrapper = styled.div<Props>`
  margin-left: auto;
  padding: 1.5rem;
  display: flex;
  align-items: center;
  background: ${({ theme }: Props) => theme.palette.accent._01};

  > span {
    :first-child {
      width: 60%;
      text-align: left;
    }

    :nth-child(2) {
      width: 15%;
    }

    :last-child {
      width: 20%;
    }
  }
`;

export const LastCompletedTestsWrapper = styled.div<Props>`
  margin-left: auto;
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
`;
