import { TFunction } from 'i18next';

export const getCorrectTestHeader = (t: TFunction, testType: string): string => {
  switch (testType) {
    case 'formative-test':
      return t('ownedScheduledTestResults.details.testFeatures.metadata.testType.values.formative', 'Formative Test');
    case 'summative-test':
      return t('ownedScheduledTestResults.details.testFeatures.metadata.testType.values.summative', 'Summative Test');
    case 'freebie-test':
      return t('ownedScheduledTestResults.details.testFeatures.metadata.testType.values.freebie', 'Intake Test');
    default:
      return '';
  }
};
