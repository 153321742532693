/* eslint-disable @typescript-eslint/no-use-before-define */
import { action, observable } from 'mobx';
import { FullScreenHandle } from 'react-full-screen';

interface FullScreenStore {
  fullScreen?: FullScreenHandle;
  setFullScreen: (fullScreenHandle: FullScreenHandle) => void;
}

const stateSetters = {
  setFullScreen: action((handle: FullScreenHandle) => {
    store.fullScreen = handle;
  }),
};

const store: FullScreenStore = observable({
  ...stateSetters,
} as FullScreenStore);

export const useFullScreen = (): FullScreenStore => store;
