import React from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import Highlighter from 'react-highlight-words';
import { useDomainHandler } from 'hooks/useDomainHandler';

import { InputWithErrorMessage } from 'components/InputWithErrorMessage';
import { IconButton } from 'components/IconButton/IconButton';
import { GroupStudent } from 'models/groups/Group';
import { LoadingSpinner } from 'components/LoadingSpinner';

import { StudentsTableWrapper, StudentsTable, EllipsisElement, SearchStudentsInputWrapper } from './Form.styled';

interface StudentsListProps {
  students: GroupStudent[];
  onRemove: (studentId: number) => void;
  removingStudentsIds: number[];
}

export const StudentsList: React.FC<StudentsListProps> = observer(({ students, onRemove, removingStudentsIds }) => {
  const { t } = useTranslation('homepage');
  const [searchTerm, setSearchTerm] = React.useState('');
  const { getStudentTermByDomain } = useDomainHandler();

  const onChangeText = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(evt.target.value);
  };

  const filteredStudentsList = React.useMemo(() => {
    const term = searchTerm.trim().toLowerCase();

    if (term === '') {
      return students;
    }

    return students.filter(student =>
      Object.values(student)
        .map(val => String(val).toLowerCase())
        .join('')
        .includes(term),
    );
  }, [searchTerm, students]);

  return (
    <StudentsTableWrapper>
      <SearchStudentsInputWrapper>
        <InputWithErrorMessage
          dataCy="group-search-student-input"
          hasError={false}
          id="group-search-student-input"
          name="group-search-student-input"
          placeholder={t('header.searchBox.placeholder', { studentTerm: getStudentTermByDomain() })}
          value={searchTerm}
          onChange={onChangeText}
        />
      </SearchStudentsInputWrapper>
      {filteredStudentsList.length > 0 && (
        <StudentsTable data-cy="group-students-table">
          <tbody>
            {filteredStudentsList.map((student: GroupStudent) => (
              <tr key={`row-${student.id}`}>
                <td>
                  <EllipsisElement>
                    <Highlighter autoEscape searchWords={searchTerm.split(' ')} textToHighlight={student.name} />
                  </EllipsisElement>
                </td>
                <td>
                  <EllipsisElement>
                    <Highlighter
                      autoEscape
                      searchWords={searchTerm.split(' ')}
                      textToHighlight={String(student.studentNumber)}
                    />
                  </EllipsisElement>
                </td>
                <td>
                  <EllipsisElement>
                    <Highlighter autoEscape searchWords={searchTerm.split(' ')} textToHighlight={student.email} />
                  </EllipsisElement>
                </td>
                <td>
                  {removingStudentsIds.includes(student.id) ? (
                    <LoadingSpinner size={18} stroke={2} />
                  ) : (
                    <IconButton
                      dataCy={`delete-student-${student.id}-button`}
                      fontColor="grey"
                      iconName="trashIcon"
                      onClick={() => onRemove(student.id)}
                    />
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </StudentsTable>
      )}
    </StudentsTableWrapper>
  );
});
