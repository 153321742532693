import styled from 'styled-components/macro';

import { ThemeProps as Props } from 'models/styles/theme';
import TextareaAutosize from 'react-textarea-autosize';

export interface StyledInputProps extends Props {
  highlight: string;
}

export const StyledHighlightDescription = styled.span<Props>`
  font-weight: 400;
  color: ${({ theme }: Props) => theme.palette.neutral._900};
  font-size: 0.8rem;
  margin-left: 0.6rem;
`;

export const StyledAnswerWrapper = styled.div<Props>`
  margin-bottom: 2rem;
`;

export const StyledTextArea = styled(TextareaAutosize)<StyledInputProps>`
  width: 95%;
  resize: none;
  overflow: hidden;
  height: 1rem;
  outline: none;
  padding: 1rem;
  border: 1px solid
    ${({ highlight, theme }: StyledInputProps) =>
      highlight === 'true' ? theme.palette.primary.brand : theme.palette.neutral._400};
`;

export const StyledButtonWrapper = styled.div<Props>`
  text-align: right;
  margin-top: 1rem;
`;
