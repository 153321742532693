import { Button } from 'components/Button';
import React, { FC } from 'react';
import { Heading2, Heading4 } from 'styles/elements/Headings';
import { StyledIconWrapper, StyledInnerText, StyledPage } from './RegistrationSuccessfulPage.styled';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { AUTH_PAGES_ROUTES } from 'constants/routes';
import { SvgIconReact } from 'components/SvgIconReact';

export const RegistrationSuccessfulPage: FC = () => {
  const { t } = useTranslation('auth-pages');
  const history = useHistory();

  return (
    <StyledPage>
      <StyledInnerText>
        <StyledIconWrapper>
          <SvgIconReact iconName="partyIcon" size={10} />
        </StyledIconWrapper>
        <Heading2>{t('registerSuccessful.title')}</Heading2>
        <Heading4>{t('registerSuccessful.description')}</Heading4>
        <Button variant="primary" onClick={() => history.push(AUTH_PAGES_ROUTES.LOG_IN)}>
          {t('registerSuccessful.button')}
        </Button>
      </StyledInnerText>
    </StyledPage>
  );
};
