import { useEffect, useState } from 'react';
import { Map as ImmutableMap } from 'immutable';
import { HST_PROCTORING_EVENTS } from '@he-learning/service-he-common';
import { EnvironmentType, serviceENV as getCurrentEnvironment } from 'constants/env-constants';
import { ExamRule, TestEventTypes } from 'constants/exam-constants';
import { useLogs } from './useLogs';
import { useScheduledTests } from './useScheduledTests';
import { useFullScreen } from './useFullScreen';

type RuleResults = ImmutableMap<ExamRule, boolean>;

const allRules: ExamRule[] = Object.values(ExamRule);

// Define rules that must be excluded based on current environment
const excludedRulesPerEnvironment: Record<EnvironmentType, ExamRule[]> = {
  [EnvironmentType.LOCAL]: allRules,
  [EnvironmentType.DEVELOPMENT]: allRules,
  [EnvironmentType.STAGING]: [],
  [EnvironmentType.PRODUCTION]: [],
  // Ignore the FULL_SCREEN rule during tests because we cannot put Cypress in fullscreen mode
  [EnvironmentType.TEST]: [ExamRule.FULL_SCREEN],
};

export const useFailingExamRules = (rules: ExamRule[] = allRules): readonly ExamRule[] => {
  const [ruleResults, setRuleResults] = useState<RuleResults>(ImmutableMap(allRules.map(rule => [rule, true])));
  const { trackTestEvent } = useLogs();
  const { scheduledTestContent } = useScheduledTests();
  const { fullScreen } = useFullScreen();
  const shouldCheckRule = (rule: ExamRule) => {
    const currentEnv = getCurrentEnvironment() as EnvironmentType;
    const envExclusions = excludedRulesPerEnvironment[currentEnv] || [];
    return rules.includes(rule) && !envExclusions.includes(rule);
  };

  // full screen rule
  useEffect(() => {
    if (!shouldCheckRule(ExamRule.FULL_SCREEN)) {
      if (!ruleResults.get(ExamRule.FULL_SCREEN)) {
        setRuleResults(ruleResults.set(ExamRule.FULL_SCREEN, true));
      }
      return () => undefined;
    }

    const onCheckFullscreen = () => {
      const current = ruleResults.get(ExamRule.FULL_SCREEN);
      if (current !== fullScreen?.active) {
        setRuleResults(ruleResults.set(ExamRule.FULL_SCREEN, fullScreen?.active ?? false));
        const eventName = fullScreen?.active
          ? HST_PROCTORING_EVENTS.STUDENT_GOES_FULL_SCREEN
          : HST_PROCTORING_EVENTS.STUDENT_LEAVES_FULL_SCREEN;
        trackTestEvent(eventName, TestEventTypes.PROCTORING, scheduledTestContent?.token || '');
      }
    };

    // add a checker for this rule
    const intervalTimer = setInterval(onCheckFullscreen, 1000);
    onCheckFullscreen();

    return () => {
      // cleanup all handlers this rule
      clearInterval(intervalTimer);
    };
  }, [rules.includes(ExamRule.FULL_SCREEN), ruleResults, fullScreen?.active]);

  // use mouse document leave rule
  useEffect(() => {
    if (!shouldCheckRule(ExamRule.MOUSE_INSIDE)) {
      if (!ruleResults.get(ExamRule.MOUSE_INSIDE)) {
        setRuleResults(ruleResults.set(ExamRule.MOUSE_INSIDE, true));
      }
      return () => undefined;
    }

    const onMouseLeave = () => {
      if (ruleResults.get(ExamRule.MOUSE_INSIDE)) {
        trackTestEvent(
          HST_PROCTORING_EVENTS.STUDENT_CURSOR_LEAVES_PAGE,
          TestEventTypes.PROCTORING,
          scheduledTestContent?.token || '',
        );
        setRuleResults(ruleResults.set(ExamRule.MOUSE_INSIDE, false));
      }
    };

    const onMouseEnter = () => {
      if (!ruleResults.get(ExamRule.MOUSE_INSIDE)) {
        trackTestEvent(
          HST_PROCTORING_EVENTS.STUDENT_CURSOR_RETURNS_PAGE,
          TestEventTypes.PROCTORING,
          scheduledTestContent?.token || '',
        );
        setRuleResults(ruleResults.set(ExamRule.MOUSE_INSIDE, true));
      }
    };

    document.addEventListener('mouseleave', onMouseLeave);
    document.addEventListener('mouseenter', onMouseEnter);

    return () => {
      // cleanup all handlers this rule
      document.removeEventListener('mouseleave', onMouseLeave);
      document.removeEventListener('mouseenter', onMouseEnter);
    };
  }, [rules.includes(ExamRule.MOUSE_INSIDE), ruleResults]);

  // return only the rules that FAIL
  return ruleResults
    .toArray()
    .filter(([, value]) => !value)
    .map(([key]) => key);
};
