import { action, observable } from 'mobx';
import i18n from 'i18n';
import { ToastMethods } from 'components/ToastNotification';
import { adminRepository } from 'repositories';
import {
  FilteredAccountsResponse,
  AccountDetailsResponse,
  UpdateAccountDetailsRequest,
  RequestFilteredAccounts,
  ScheduledTestsAdminResponse,
  RequestScheduleTests,
  CreateNewAccountRequest,
  ProductSkillLevelResponse,
  RequestSimulateLoginAsTeacher,
  SimulateLoginAsTeacherResponse,
  SimulateLoginState,
} from 'models/admin/Admin';
import { TransferResult } from 'pages/AdminEnvironment/AdminPage/subpages/UserToolsPage/components/TransferResults/TransferResults.schema';
import { ContentAPI, ResultsAPI } from 'generated/types';
import { ProductType } from 'constants/hst-constants';

interface AdminStore {
  filteredAccounts: FilteredAccountsResponse;
  accountDetails: AccountDetailsResponse;
  loading: boolean;
  sheduledTests: ScheduledTestsAdminResponse;
  createUserSuccess: boolean;
  selectableSkillLevels: Array<ProductSkillLevelResponse>;
  simulateLoginState: SimulateLoginState | null;
  scheduledTestsByDate: Array<ContentAPI.ScheduledTestByDate>;
  allParticipatedTestCodes: string[];

  setAccountDetails: (accountDetails: AccountDetailsResponse) => void;
  setFilteredAccounts: (filteredAccounts: FilteredAccountsResponse) => void;
  setSheduledTests: (sheduledTests: ScheduledTestsAdminResponse) => void;
  setLoading: (isLoading: boolean) => void;
  setCreateUserSuccess: (createUserSuccess: boolean) => void;
  setProductSkillLevel: (skillLevels: Array<ProductSkillLevelResponse>) => void;
  setSimulateLoginState: (simulateLoginState: SimulateLoginState | null) => void;
  setSheduledTestsByDate: (sheduledTests: Array<ContentAPI.ScheduledTestByDate>) => void;
  setAllParticipatedTestCodes: (particiaptedTestCodes: string[]) => void;

  updateAccountDetails: (payload: UpdateAccountDetailsRequest, setSubmitting: (isSubmitting: boolean) => void) => void;
  fetchFilteredAccounts: (payload: RequestFilteredAccounts) => void;
  fetchAccountDetails: (accountId: string) => void;
  fetchScheduledTests: (payload: RequestScheduleTests) => void;
  createNewAccount: (payload: CreateNewAccountRequest) => void;
  fetchProductSkillLevels: () => void;
  simulateLoginAsTeacher: (payload: RequestSimulateLoginAsTeacher) => void;
  fetchScheduledTestsByDate: (date: string) => void;
  transferResults: (payload: TransferResult) => void;
  fetchAllParticipatedTestCodes: (email: string) => void;
  deleteAccounts: (emails: string[]) => void;
}

// eslint-disable-next-line prefer-const
let store: AdminStore;

const initialState = {
  error: null,
  loading: false,
  createUserSuccess: false,
  filteredAccounts: {} as FilteredAccountsResponse,
  accountDetails: {} as AccountDetailsResponse,
  sheduledTests: {} as ScheduledTestsAdminResponse,
  selectableSkillLevels: {} as Array<ProductSkillLevelResponse>,
  simulateLoginState: null,
  scheduledTestsByDate: {} as Array<ContentAPI.ScheduledTestByDate>,
  allParticipatedTestCodes: [],
};

const stateSetters = {
  setAccountDetails: action((accountDetails: AccountDetailsResponse) => {
    store.accountDetails = accountDetails;
  }),
  setFilteredAccounts: action((filteredAccounts: FilteredAccountsResponse) => {
    store.filteredAccounts = filteredAccounts;
  }),
  setLoading: action((loading: boolean) => {
    store.loading = loading;
  }),
  setSheduledTests: action((sheduledTests: ScheduledTestsAdminResponse) => {
    store.sheduledTests = sheduledTests;
  }),
  setCreateUserSuccess: action((createUserSuccess: boolean) => {
    store.createUserSuccess = createUserSuccess;
  }),
  setProductSkillLevel: action((skillLevels: Array<ProductSkillLevelResponse>) => {
    store.selectableSkillLevels = skillLevels;
  }),

  setSimulateLoginState: action((simulateLoginState: SimulateLoginState | null) => {
    store.simulateLoginState = simulateLoginState;
  }),
  setSheduledTestsByDate: action((scheduledTestsByDate: Array<ContentAPI.ScheduledTestByDate>) => {
    store.scheduledTestsByDate = scheduledTestsByDate;
  }),
  setAllParticipatedTestCodes: action((allParticipatedTestCodes: string[]) => {
    store.allParticipatedTestCodes = allParticipatedTestCodes;
  }),
};

const apiRequests = {
  updateAccountDetails: action(
    (payload: UpdateAccountDetailsRequest, setSubmitting: (isSubmitting: boolean) => void) => {
      store.setLoading(true);
      let account = payload;
      if (payload.products) {
        const modifiedProducts = payload.products.map(({ type, ...product }) => product);
        account = { ...payload, products: modifiedProducts };
      }
      adminRepository
        .updateAccountDetails(account)
        .then((accountDetails: AccountDetailsResponse) => {
          ToastMethods.showToast(i18n.t('toast:admin.updateAccountDetails.success'), 'success');
          store.setAccountDetails(accountDetails);
        })
        .catch(() => {
          ToastMethods.showToast(i18n.t('toast:admin.updateAccountDetails.error'), 'error');
        })
        .finally(() => {
          store.setLoading(false);
          setSubmitting(false);
        });
    },
  ),

  fetchFilteredAccounts: action((payload: RequestFilteredAccounts) => {
    store.setLoading(true);
    adminRepository
      .fetchFilteredAccounts(payload)
      .then((filteredAccounts: FilteredAccountsResponse) => {
        store.setFilteredAccounts(filteredAccounts);
      })
      .catch(() => {
        ToastMethods.showToast(i18n.t('toast:profile.fetchFilteredAccounts.error'), 'error');
      })
      .finally(() => {
        store.setLoading(false);
      });
  }),

  fetchAccountDetails: action((accountId: string) => {
    store.setLoading(true);
    adminRepository
      .fetchAccountDetails(accountId)
      .then((accountDetails: AccountDetailsResponse) => {
        store.setAccountDetails(accountDetails);
      })
      .catch(() => {
        ToastMethods.showToast(i18n.t('toast:profile.fetchAccountDetails.error'), 'error');
      })
      .finally(() => {
        store.setLoading(false);
      });
  }),

  fetchScheduledTests: action((payload: RequestScheduleTests) => {
    store.setLoading(true);
    adminRepository
      .fetchScheduledTests(payload)
      .then((sheduledTests: ScheduledTestsAdminResponse) => {
        store.setSheduledTests(sheduledTests);
      })
      .catch(() => {
        ToastMethods.showToast(i18n.t('toast:admin.fetchSheduledTests.error'), 'error');
      })
      .finally(() => {
        store.setLoading(false);
      });
  }),

  createNewAccount: action((payload: CreateNewAccountRequest) => {
    store.setLoading(true);
    const isBusinessAccount = payload.metadata.products.find(product => product.type === ProductType.BUSINESS);
    adminRepository
      .createNewAccount({ ...payload, isBusinessAccount: !!isBusinessAccount })
      .then(() => {
        ToastMethods.showToast(i18n.t('toast:admin.createNewUser.success'), 'success');
        store.setCreateUserSuccess(true);
      })
      .catch(() => {
        ToastMethods.showToast(i18n.t('toast:admin.createNewUser.error'), 'error');
        store.setCreateUserSuccess(false);
      })
      .finally(() => {
        store.setLoading(false);
      });
  }),

  fetchProductSkillLevels: action(() => {
    store.setLoading(true);
    adminRepository
      .fetchProductSkillLevels()
      .then((skillLevels: Array<ProductSkillLevelResponse>) => {
        store.setProductSkillLevel(skillLevels);
      })
      .catch(() => {
        ToastMethods.showToast(i18n.t('toast:admin.fetchProductSkillLevels.error'), 'error');
      })
      .finally(() => {
        store.setLoading(false);
      });
  }),

  simulateLoginAsTeacher: action(({ id }: RequestSimulateLoginAsTeacher) => {
    store.setLoading(true);
    adminRepository
      .simulateLoginAsTeacher({ id })
      .then(({ url }: SimulateLoginAsTeacherResponse) => {
        ToastMethods.showToast(i18n.t('toast:admin.simulateLoginAsTeacher.success'), 'success');
        store.setSimulateLoginState({ userId: id, loginURL: url });
      })
      .catch(() => {
        ToastMethods.showToast(i18n.t('toast:admin.simulateLoginAsTeacher.error'), 'error');
      })
      .finally(() => {
        store.setLoading(false);
      });
  }),

  fetchScheduledTestsByDate: action((date: string) => {
    store.setLoading(true);
    adminRepository
      .fetchScheduledTestsByDate(date)
      .then((scheduledTestsByDate: Array<ContentAPI.ScheduledTestByDate>) => {
        store.setSheduledTestsByDate(scheduledTestsByDate);
      })
      .catch(() => {
        ToastMethods.showToast(i18n.t('toast:admin.fetchSheduledTests.error'), 'error');
      })
      .finally(() => {
        store.setLoading(false);
      });
  }),

  fetchAllParticipatedTestCodes: action((email: string) => {
    store.setLoading(true);
    adminRepository
      .fetchAllParticipatedTestCodes(email)
      .then((testCodes: string[]) => {
        store.setAllParticipatedTestCodes(testCodes);
      })
      .catch(() => {
        ToastMethods.showToast(i18n.t('toast:admin.fetchSheduledTests.error'), 'error');
      })
      .finally(() => {
        store.setLoading(false);
      });
  }),

  transferResults: action((payload: TransferResult) => {
    store.setLoading(true);
    adminRepository
      .transferResults(payload)
      .then(({ transferredResults }: ResultsAPI.ScheduledTestSessionTransferResponse) => {
        ToastMethods.showToast(
          i18n.t('admin-environment:adminTools.transferResults.success', { transferredResults }),
          'success',
        );
      })
      .catch(() => {
        ToastMethods.showToast(i18n.t('toast:admin.fetchSheduledTests.error'), 'error');
      })
      .finally(() => {
        store.setLoading(false);
      });
  }),

  deleteAccounts: action((payload: string[]) => {
    store.setLoading(true);
    adminRepository
      .deleteAccounts(payload)
      .then(() => {
        ToastMethods.showToast(i18n.t('admin-environment:adminTools.deleteAccounts.success'), 'success');
      })
      .catch(() => {
        ToastMethods.showToast(i18n.t('admin-environment:adminTools.deleteAccounts.error'), 'error');
      })
      .finally(() => {
        store.setLoading(false);
      });
  }),
};

store = observable({
  ...stateSetters,
  ...apiRequests,
  ...initialState,
} as AdminStore);

export const useAdmin = (): AdminStore => store;
