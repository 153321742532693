import styled, { css, createGlobalStyle } from 'styled-components/macro';
import { ThemeProps as Props } from 'models/styles/theme';
import { CssFlex } from 'styles/helpers/layout';

interface ExerciseLayoutProps extends Props {
  quizLayout?: boolean;
  correctTextLayout?: boolean;
}

export const GlobalCSS = createGlobalStyle`
  body {
    overflow-x: hidden;
  }
`;

const getLayoutCss = ({ quizLayout, correctTextLayout }: ExerciseLayoutProps) => {
  if (quizLayout) {
    return css`
      width: auto;
    `;
  }
  if (correctTextLayout) {
    return css`
      max-width: 880px;
    `;
  }
  return css`
    width: 50%;
  `;
};

export const StyledExercisePage = styled.div<ExerciseLayoutProps>`
  display: flex;
  flex: 1 1 0;
  align-items: center;
  flex-direction: column;
  ${(props: ExerciseLayoutProps) => getLayoutCss(props)}
  margin: 0 auto;
  padding-bottom: 150px;
`;

export const StyledBottomBar = styled(CssFlex)<Props>`
  position: fixed;
  width: 100%;
  bottom: 0;
  padding: 20px;
  min-height: 72px;
  background: ${(props: Props) => props.theme.palette.neutral.white};
  border-top: 1px solid ${(props: Props) => props.theme.palette.neutral._20};
`;

export const StyledTextQuizExerciseText = styled.div`
  min-height: 300px;
  max-height: calc(100vh - 7rem - 200px);
  margin: 3.5rem 0 0 3.5rem;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 0.5rem;
`;

export const StyledButtonContainer = styled.section<Props>`
  width: fit-content;

  > * {
    width: fit-content;
  }
`;
