import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import { useModal } from 'hooks/useModal';
import { useProfile } from 'hooks/useProfile';
import useRequest from 'hooks/user/useRequest';

import { modalIds } from 'constants/modals-constants';

import { Footer } from 'components/Footer';
import noOwnedlandingPageImg from 'assets/images/no-owned-landing.png';
import { NoProductsOwnedNavigationMenu } from '../components/NoProductsOwnedNavigationMenu';
import { StudentHeader } from '../components/Header/StudentHeader';
import { NoOwnedBanner, NoOwnedTextContent } from './components';

import { StyledContainer, StyledImage, StyledNoOwnedHomePage } from './StudentNoOwnedDashboard.styled';

export const StudentNoOwnedDashboard: React.FC = observer(() => {
  const { toggleModal } = useModal();
  const { fetchUserDetails, userDetails, updateUserDetails } = useProfile();
  const { logout } = useRequest();

  useEffect(() => {
    fetchUserDetails();
  }, [fetchUserDetails]);

  const onToggleSideMenu = () => {
    toggleModal(modalIds.modalSideMenu);
  };

  return (
    <>
      <StudentHeader
        toggleSideMenu={onToggleSideMenu}
        updateUserDetails={updateUserDetails}
        userDetails={userDetails}
        onLogout={logout}
      />
      <StyledNoOwnedHomePage data-cy="student-landing-no-owned-products">
        <NoProductsOwnedNavigationMenu className="aside-menu" dataCy="no-product-navigation-menu" />
        <StyledContainer>
          <NoOwnedBanner userDetails={userDetails} />
          <StyledImage alt="" data-cy="noOwned-landing-image" src={noOwnedlandingPageImg} />
          <NoOwnedTextContent />
        </StyledContainer>
      </StyledNoOwnedHomePage>
      <Footer />
    </>
  );
});
