import styled from 'styled-components/macro';

import { calculateRem } from 'styles/mixins/calculateRem';
import { ThemeProps as Props } from 'models/styles/theme';

import { SvgIconReact } from 'components/SvgIconReact';

export const StyledContainer = styled.div`
  display: grid;
  grid-template-areas:
    'icon label'
    'icon value';
  grid-template-columns: max-content auto;
  column-gap: 1rem;

  margin: 0;
  font-size: ${calculateRem(14)};
  line-height: ${calculateRem(24)};
  grid-template-rows: max-content max-content;
`;

export const StyledIcon = styled(SvgIconReact)`
  grid-area: icon;
  margin: 0.75rem 0;
`;

export const StyledLabel = styled.label`
  grid-area: label;
  color: ${(props: Props) => props.theme.palette.neutral._900};
`;

export const StyledValue = styled.span`
  grid-area: value;
`;
