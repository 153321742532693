/* eslint-disable no-underscore-dangle */
import styled from 'styled-components/macro';
import { ThemeProps as Props } from 'models/styles/theme';

export interface StyledAbsoluteToolTipProps extends Props {
  $left: number;
  $top: number;
}

const calculateLeftPos = ($left: number) => `${$left - 10 * 16}px`;

export const StyledAbsoluteToolTip = styled.div<StyledAbsoluteToolTipProps>`
  top: ${({ $top }: StyledAbsoluteToolTipProps) => $top}px;
  left: ${({ $left }: StyledAbsoluteToolTipProps) => calculateLeftPos($left)};
  margin-top: 1rem;
  padding: 1rem;
  width: 20rem;
  background: ${({ theme }: StyledAbsoluteToolTipProps) => theme.palette.neutral._1000};
  border-radius: 0.5rem;
  position: fixed;
  color: ${({ theme }: StyledAbsoluteToolTipProps) => theme.palette.neutral.white};

  ::before,
  ::after {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 41%;
    border: 0.8rem solid transparent;
    border-bottom-color: ${({ theme }: StyledAbsoluteToolTipProps) => theme.palette.neutral._1000};
  }
`;
