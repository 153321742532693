import React from 'react';
import classnames from 'classnames';

import { SvgIconReact } from '../SvgIconReact';
import { OptionProps, SelectProps } from './Select.model';
import { StyledLabel, StyledSelect, StyledWrapper } from './Select.styled';

export const Select: React.FC<SelectProps> = ({
  id,
  ariaLabel,
  label,
  defaultValue,
  options,
  iconName,
  dataCy,
  className,
  width = '100%',
  ...selectProps
}) => (
  <>
    {label && <StyledLabel>{label}</StyledLabel>}
    <StyledWrapper className={className}>
      {iconName && <SvgIconReact iconName={iconName} size={2} />}
      <StyledSelect
        $width={width}
        aria-label={ariaLabel}
        className={classnames(iconName && 'has-icon', className || '')}
        data-cy={dataCy}
        id={id}
        value={defaultValue}
        {...selectProps}
      >
        {options &&
          options.map((option: OptionProps) => (
            <option key={option.value} data-testid="select-option" disabled={option.disabled} value={option.value}>
              {option.label}
            </option>
          ))}
      </StyledSelect>
    </StyledWrapper>
  </>
);
