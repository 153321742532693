import styled from 'styled-components/macro';
import { ElearningThemeInterface } from 'themes/elearningInterface.theme';

import { Input } from 'components/Input';

interface Props {
  theme: ElearningThemeInterface;
}

export const StyledSearchBox = styled.div`
  background-color: ${(props: Props) => props.theme.palette.neutral._200};
  display: flex;
  height: 3rem;
  min-width: 19rem;
  justify-content: flex-start;
  align-items: center;
  padding: 0.3rem 1rem;
  border-radius: 0.5rem;

  > :first-child {
    fill: ${(props: Props) => props.theme.palette.neutral._600};
  }
`;

export const StyledInput = styled(Input)<Props>`
  border: 0;
  outline: none;
  color: ${(props: Props) => props.theme.palette.neutral._900};

  ::placeholder {
    color: ${(props: Props) => props.theme.palette.neutral._900};
  }
`;
