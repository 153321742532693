import axios, { AxiosError, AxiosResponse } from 'axios';
import { ERROR_TYPES } from 'constants/error-constants';
import cookieStorage from 'persistence';
import { handlingResponse, logError } from 'repositories/utils';
import { RequestError, ApiRequestError as Error } from 'models/error/Error';
import { ExercisePerSubjectResults, ExercisesOverTimeResults } from 'models/progress/Progress';
import {
  AssignScheduledTestCertificatesResponse,
  RecentProgress,
  RequestScheduledTestsCertificatesResults,
  ResponseScheduledTestsCertificatesResults,
  ScheduledTestExercisesStatus,
} from 'models/results/Results';
import { TestType } from 'constants/exam-constants';
import { ResultsAPI } from 'generated/types';
import { QuestionResult } from 'models/exam/Exam';

const fetchExercisePerSubjectResults = async (productId: number): Promise<ExercisePerSubjectResults[]> => {
  try {
    const exercisePerSubjectResults = await axios.get(`/api/account/stats/products/${productId}/subjects`, {
      headers: { Authorization: cookieStorage.getToken() },
    });
    return handlingResponse<ExercisePerSubjectResults[]>(
      [200],
      'Error getting exercises per subject results',
    )(exercisePerSubjectResults);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<Error>);
    throw new RequestError({ message, status, type: ERROR_TYPES.GET_EXERCISE_PER_SUBJECT_RESULTS });
  }
};

const fetchExercisesOverTimeResults = async (productId: number): Promise<ExercisesOverTimeResults[]> => {
  try {
    const exercisesOverTimeResults = await axios.get(`/api/account/results/products/${productId}/questions`, {
      headers: { Authorization: cookieStorage.getToken() },
    });
    return handlingResponse<ExercisesOverTimeResults[]>(
      [200],
      'Error getting exercises over time results',
    )(exercisesOverTimeResults);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<Error>);
    throw new RequestError({ message, status, type: ERROR_TYPES.GET_EXERCISES_OVER_TIME_RESULTS });
  }
};

const fetchScheduledTestStatus = async (code: string): Promise<ScheduledTestExercisesStatus[]> => {
  try {
    const scheduledTestStatus = await axios.get(`/api/tests/${code}/status`, {
      headers: {
        Authorization: cookieStorage.getToken(),
      },
    });
    return handlingResponse<ScheduledTestExercisesStatus[]>(
      [200],
      'Error getting scheduled tests status',
    )(scheduledTestStatus);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<Error>);
    throw new RequestError({ message, status, type: ERROR_TYPES.GET_SCHEDULED_TEST_STATUS });
  }
};

const getStudentScheduledTestsResultsCertificate = async ({
  page,
  resultsPerPage,
}: RequestScheduledTestsCertificatesResults): Promise<ResponseScheduledTestsCertificatesResults> => {
  try {
    const approvedScheduledTests = await axios.get('/api/certificates', {
      headers: {
        Authorization: cookieStorage.getToken(),
      },
      params: {
        page,
        resultsPerPage,
      },
    });

    return handlingResponse<ResponseScheduledTestsCertificatesResults>(
      [200],
      'Error getting scheduled tests approved results',
    )(approvedScheduledTests);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<Error>);
    throw new RequestError({ message, status, type: ERROR_TYPES.GET_SCHEDULED_TEST_RESULTS_CERTIFICATE_FAILED });
  }
};

const updateStudentScheduledTestsResultsCertificate = async (
  scheduledTestId: number,
): Promise<AssignScheduledTestCertificatesResponse> => {
  try {
    const response = await axios.put(
      '/api/certificates/assign',
      {},
      {
        headers: {
          Authorization: cookieStorage.getToken(),
        },
        params: {
          scheduledTestId,
        },
      },
    );

    return handlingResponse<AssignScheduledTestCertificatesResponse>([200], 'Error assigning certificates')(response);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<Error>);
    throw new RequestError({ message, status, type: ERROR_TYPES.PUT_SCHEDULED_TEST_RESULTS_CERTIFICATE_FAILED });
  }
};

const invalidateScheduledTestOfStudent = async (scheduledTestId: number, studentId: number): Promise<void> => {
  try {
    await axios.post(
      '/api/scheduled-test/results/invalidate',
      { scheduledTestId, studentId },
      { headers: { Authorization: cookieStorage.getToken() } },
    );
  } catch (error) {
    const { message, status } = logError(error as AxiosError<Error>, false);
    throw new RequestError({ message, status, type: ERROR_TYPES.POST_INVALIDATE_SCHEDULED_TEST_FAILED });
  }
};

const evaluateQuestion = async (
  productId: number,
  testType: TestType,
  testToken: string,
  payload: ResultsAPI.SubmittedAnswer,
): Promise<QuestionResult> => {
  try {
    const result = await axios.post(`/api/products/${productId}/test/${testType}/answer`, payload, {
      headers: {
        Authorization: cookieStorage.getToken(),
        'content-service-authorization': testToken,
      },
    });
    const { status, data } = result as AxiosResponse<QuestionResult>;
    return handlingResponse<QuestionResult>(
      [200, 204],
      'Error retrieving test',
    )({
      ...result,
      data: status === 204 ? [] : data,
    });
  } catch (error) {
    const { message, status } = logError(error as AxiosError<Error>);
    throw new RequestError({ message, status, type: ERROR_TYPES.EVALUATE_QUESTION_FAILED });
  }
};

const getRecentProgress = async (productId: number): Promise<RecentProgress> => {
  try {
    const result = await axios.get(`/api/results/account/products/${productId}/recentProgress`, {
      headers: {
        Authorization: cookieStorage.getToken(),
      },
    });
    return handlingResponse<RecentProgress>([200], 'Error retrieving recent progress')(result);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<Error>);
    throw new RequestError({ message, status, type: ERROR_TYPES.GET_RECENT_PROGRESS });
  }
};

const fetchLastPracticedWritingTopicStatuses = async (
  productId: number,
): Promise<ResultsAPI.LastPracticedWritingTopicStatus[]> => {
  try {
    const result = await axios.get(`/api/results/products/${productId}/writing-modules/last-practiced-topic`, {
      headers: {
        Authorization: cookieStorage.getToken(),
      },
    });
    return handlingResponse<ResultsAPI.LastPracticedWritingTopicStatus[]>(
      [200],
      'Error getting last practiced writing topic status',
    )(result);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<Error>);
    throw new RequestError({ message, status, type: ERROR_TYPES.FETCH_LAST_PRACTICED_WRITING_TOPIC_STATUS_FAILED });
  }
};

export {
  fetchExercisePerSubjectResults,
  fetchExercisesOverTimeResults,
  fetchScheduledTestStatus,
  getStudentScheduledTestsResultsCertificate,
  updateStudentScheduledTestsResultsCertificate,
  invalidateScheduledTestOfStudent,
  evaluateQuestion,
  getRecentProgress,
  fetchLastPracticedWritingTopicStatuses,
};
