import styled from 'styled-components/macro';

import { ThemeProps } from 'models/styles/theme';
import { calculateRem } from 'styles/mixins/calculateRem';
import { Heading3 } from 'styles/elements/Headings';
import { Box, Typography } from 'styles/helpers/layout';

export const StyledAccountProductModal = styled.div<ThemeProps>`
  background: ${({ theme }: ThemeProps) => theme.palette.neutral._200};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  height: 90vh;
  padding: ${calculateRem(32)};
  width: ${calculateRem(1000)};
  overflow-y: scroll;
`;

export const StyledModalSubtitle = styled(Typography)`
  color: ${({ theme }: ThemeProps) => theme.palette.neutral._900};
  font-weight: 300;
  margin-top: 0;
  margin-bottom: 1rem;
`;

export const StyledSubjectHeader = styled(Heading3)`
  cursor: pointer;
  i {
    margin-right: 0.75rem;
  }
  &:hover {
    color: ${({ theme }: ThemeProps) => theme.palette.neutral._900};
    transition: 0.1s ease-in-out;
  }
`;

export const StyledSubjectContainer = styled(Box)`
  // these are rendered in a list, so every one except the first should have a top border
  border-top: 1px solid ${({ theme }: ThemeProps) => theme.palette.neutral._400};
  &:first-of-type {
    border-top: none;
  }
`;

export const StyledDivider = styled(Box)`
  height: 1px;
  background-color: ${({ theme }: ThemeProps) => theme.palette.neutral._400};
  width: 100%;
`;

export const StyledProgressColumn = styled(Box)`
  width: 200px;
  display: flex;
  justify-content: center;

  &:first-of-type {
    margin-left: auto;
  }

  & > div {
    width: 100%;
    margin-right: 1rem;
  }
`;

export const StyledAnswersColumn = styled(Box)`
  width: ${calculateRem(120)};
  display: flex;
  justify-content: center;
`;

export const StyledHeaderProgressBar = styled(Box)`
  display: grid;
  align-items: center;
  grid-template-columns: auto 8rem;
  padding: ${calculateRem(16)};
  > div {
    width: ${calculateRem(200)};
    margin-right: 1rem;
  }
`;

export const StyledTopicColumnHeader = styled(Typography)`
  font-weight: bold;
  text-align: center;
`;

export const StyledActiveFilterText = styled(Typography)`
  color: ${({ theme }: ThemeProps) => theme.palette.neutral._900};

  b {
    color: ${({ theme }: ThemeProps) => theme.palette.neutral.black};
  }
`;
