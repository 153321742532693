import React, { useMemo } from 'react';
import classnames from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { ProgressAPI, ContentAPI } from 'generated/types';
import { AssignmentTopicProgressionStatusType, NormalTopicProgressionStatus } from 'models/progress/Progress';

import { useReactTooltip } from 'hooks/useReactTooltip';
import { useProduct } from 'hooks/useProduct';
import { useProductProgress } from 'hooks/useProductProgress';

import { SeparatorLine } from 'components/SeparatorLine';
import { LevelDash } from 'components/LevelDash';
import { SvgIconReact } from 'components/SvgIconReact';
import { EmbeddedHtml } from 'components/EmbeddedHtml';

import { buildUrlWithPathAndQueryParams } from '_helpers/utils/urlBuilder';
import { TEST_PAGE_ROUTES } from 'constants/routes';

import {
  StyledChapterOverview,
  StyledProgressPercentage,
  StyledChapterTitle,
  TopicButton,
} from './ChapterOverview.styled';
import { CorrectTextTopic } from '../CorrectTextTopic/CorrectTextTopic';
import { useAdaptivePractice } from 'hooks/useAdaptivePractice';
import { Box, CssFlex, Typography } from 'styles/helpers/layout';
import { calculateRem } from 'styles/mixins/calculateRem';
import { useTheme } from 'styled-components/macro';
import { ElearningThemeInterface } from 'themes/elearningInterface.theme';

interface ChapterOverViewParams {
  productId: string;
  moduleId: string;
}

interface ChapterOverviewProps {
  chapter: ContentAPI.SubjectListItem['chapters'][0];
  normalTopicProgress: ProgressAPI.StudentNormalTopicProgress[];
  themeAssignmentProgress: ProgressAPI.StudentAssignmentTopicProgress[];
  isDisabled: boolean;
  subjectId: number;
}

export const ChapterOverview: React.FC<ChapterOverviewProps> = ({
  chapter,
  normalTopicProgress,
  themeAssignmentProgress,
  isDisabled,
  subjectId,
}) => {
  useReactTooltip();

  const { t } = useTranslation('subject');
  const hogesTheme = useTheme() as ElearningThemeInterface;
  const history = useHistory();
  const { productId, moduleId }: ChapterOverViewParams = useParams();

  const { productDetails } = useProduct();
  const { productGoalLevel } = useProductProgress();
  const { setNextTopicId } = useAdaptivePractice();

  const goalLevel = useMemo(
    () =>
      !productGoalLevel?.goalLevel
        ? null
        : productDetails?.levels.find(({ level }) => level === productGoalLevel.goalLevel),
    [productGoalLevel, productDetails],
  );

  // Fetch only topics that are available for current goal level
  const availableTopics = useMemo(
    () =>
      chapter.topics.filter(topic => {
        // Theme assignments will only be visible if they have questions available for current goal
        if (topic.isTrialTopic) {
          const assignmentStatus = themeAssignmentProgress.find(ta => ta.id === topic.id);

          // If progress API is not returning the assignment, it means it should not be visible
          //  (the endpoint excludes assignment topics which have no questions available for current
          //  user's goal level, instead of returning it with a state that makes this explicit)
          return !!assignmentStatus;
        }

        // Regular topics will be visible unless they require a higher goal level
        const topicStatus = normalTopicProgress.find(selectedTopic => selectedTopic.id === topic.id);
        return topicStatus?.state !== NormalTopicProgressionStatus.REQUIRES_HIGHER_GOAL;
      }),
    [chapter, normalTopicProgress, themeAssignmentProgress],
  );

  if (availableTopics.length === 0) {
    return null;
  }

  const onSelectTopic = (topicId: number) => {
    if (isDisabled) {
      return;
    }

    setNextTopicId(null);
    history.push(
      buildUrlWithPathAndQueryParams(
        TEST_PAGE_ROUTES.ADAPTIVE_PRACTICE_TEST,
        { productId, moduleId, subjectId },
        { topicId },
      ),
    );
  };

  const onKeyPress = (event: React.KeyboardEvent, topicId: number) => {
    if (event.key === 'Enter') {
      onSelectTopic(topicId);
    }
  };

  return (
    <StyledChapterOverview key={chapter.id} data-cy="chapter-list-item">
      <Box p={'0 1.5rem 0 4rem'} textAlign="left">
        <StyledChapterTitle>
          <EmbeddedHtml rawHtml={chapter.title} />
        </StyledChapterTitle>
        <SeparatorLine direction="horizontal" height={0.0625} />
      </Box>

      <div className="accordion-content-body">
        {availableTopics.map(topic => {
          if (topic.isTrialTopic) {
            const assignmentProgress = themeAssignmentProgress.find(ta => ta.id === topic.id);

            // If progress is not returning the assignment, it means it should not be visible
            //  (the endpoint excludes assignment topics which have no questions available for current
            //  user's goal level, instead of returning it with a state that makes this explicit)
            if (!assignmentProgress) {
              return null;
            }

            return (
              <CorrectTextTopic
                key={topic.id}
                isDisabled={isDisabled}
                progressState={assignmentProgress.state as AssignmentTopicProgressionStatusType}
                subjectId={subjectId}
                topic={topic}
              />
            );
          }

          // if Content API sends a topic, but we haven't found any progress for it, we assume it's READY to be practiced
          const tp = normalTopicProgress.find(selectedTopic => selectedTopic.id === topic.id);
          const state = tp?.state ?? NormalTopicProgressionStatus.READY;

          // Topics only available for higher goal levels will not be visible in UI
          if (state === NormalTopicProgressionStatus.REQUIRES_HIGHER_GOAL) {
            return null;
          }

          const progress = tp?.progress ?? 0;
          const progressSkillLevel = tp?.skillLevel ?? -1;
          const skillLevel = {
            level: progressSkillLevel,
            label: productDetails?.levels.find(({ level }) => level === progressSkillLevel)?.label ?? '',
          };
          const progressExceedsCurrentGoal = goalLevel && skillLevel.level > goalLevel.level;

          return (
            <TopicButton
              key={topic.id}
              className={classnames(isDisabled && 'disabled')}
              data-cy="topic-list-item"
              role="button"
              tabIndex={0}
              onClick={() => onSelectTopic(topic.id)}
              onKeyPress={(event: React.KeyboardEvent<Element>) => onKeyPress(event, topic.id)}
            >
              <CssFlex alignItems="center" gap={0.5} height={calculateRem(40)}>
                <EmbeddedHtml rawHtml={topic.title} tagName="section" />
              </CssFlex>
              {!isDisabled && (
                <CssFlex alignItems="center" gap={0} height={calculateRem(40)}>
                  {state === NormalTopicProgressionStatus.COMPLETED ? (
                    <SvgIconReact dataCy="topic-completed-icon" iconName="greenCheckIcon" />
                  ) : (
                    <LevelDash level="default" />
                  )}
                  <StyledProgressPercentage data-cy={`topic-progress-${topic.id}`}>
                    {!progressExceedsCurrentGoal ? `${Math.floor(progress)}% ` : '100% '}
                  </StyledProgressPercentage>
                  <Typography fontSize={hogesTheme.font.size._12}>
                    <Trans i18nKey="topicProgress.ofLevel" t={t}>
                      of level
                      <b>{{ skillLevel: progressExceedsCurrentGoal ? goalLevel.label : skillLevel.label }}</b>
                    </Trans>
                  </Typography>
                </CssFlex>
              )}
            </TopicButton>
          );
        })}
      </div>
    </StyledChapterOverview>
  );
};
