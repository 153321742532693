import styled from 'styled-components/macro';
import { ThemeProps as Props } from 'models/styles/theme';
import { ElearningThemeInterface } from 'themes/elearningInterface.theme';
import { CssGrid } from 'styles/helpers/layout';

export interface DottedScoreProps extends Props {
  score: number;
  currIndex: number;
}

export const getScoreBackgroundColor = (score: number, currIndex: number, theme: ElearningThemeInterface): string => {
  let shouldColor = false;
  const color = score >= 50 ? theme.palette.feedback.success.brand : theme.palette.feedback.error.brand;

  switch (currIndex) {
    case 0:
      shouldColor = score >= 15;
      break;
    case 1:
      shouldColor = score >= 30;
      break;
    case 2:
      shouldColor = score >= 50;
      break;
    case 3:
      shouldColor = score >= 70;
      break;
    case 4:
      shouldColor = score >= 100;
      break;
    default:
      shouldColor = false;
      break;
  }
  return shouldColor ? color : theme.palette.neutral._400;
};

export const StyledExercise = styled(CssGrid)<Props>`
  width: 100%;
  padding: 1.5rem;
  margin-top: 1rem;
  align-items: center;
  border: 1px solid ${({ theme }: Props) => theme.palette.neutral._400};

  h3 {
    margin: 0;
  }
`;

export const StyledDottedScore = styled.div<DottedScoreProps>`
  width: 1rem;
  height: 0.5rem;
  border-radius: 30%;
  background: ${({ score, currIndex, theme }: DottedScoreProps) => getScoreBackgroundColor(score, currIndex, theme)};
`;

export const StyledScoreText = styled.div`
  text-align: right;
`;
