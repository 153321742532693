import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionMessageModal } from 'components/ActionMessage/ActionMessageModal';
import { modalIds } from 'constants/modals-constants';
import { useModal } from 'hooks/useModal';
import { ResetSubjectProgressProps } from './ResetSubjectProgress.model';

export const ResetSubjectProgressModal: React.FC<ResetSubjectProgressProps> = ({
  isActive = true,
  isLoading = false,
  onConfirm,
  onCancel,
}) => {
  const { t } = useTranslation('modal');
  const { toggleModal, isModalOpen } = useModal();

  const modalId = modalIds.resetSubjectProgressModal;

  useEffect(() => {
    const modalIsOpen = isModalOpen(modalId);
    if ((isActive && !modalIsOpen) || (!isActive && modalIsOpen)) {
      toggleModal(modalId);
    }
  }, [isActive]);

  return (
    <ActionMessageModal
      actionButtonClick={onConfirm}
      actionButtonLabel={t('resetSubjectProgress.actionButtonTitle')}
      cancelButtonClick={onCancel}
      cancelButtonLabel={t('resetSubjectProgress.cancelButtonTitle')}
      iconName="warningExclamationTriangle"
      isLoading={isLoading}
      modalId={modalId}
      title={t('resetSubjectProgress.title', 'Are you sure? \n Your score will be deleted')}
    />
  );
};
