import { Link } from 'components/Link';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledFooter } from './TermsFooter.styled';

export const TermsFooter: FC = () => {
  const { t } = useTranslation('auth-pages');

  return (
    <StyledFooter>
      <Link
        external
        to="https://www.hogeschooltaal.nl/wp-content/uploads/2021/03/algemene-voorwaarden-digitaalbedrijf-29maart2021.pdf"
        variant="tertiary"
      >
        {t('registrationPage.footer.links.terms', 'Terms & Conditions')}
      </Link>
      {' | '}
      <Link external to="https://www.hogeschooltaal.nl/privacy-cookies/" variant="tertiary">
        {t('registrationPage.footer.links.privacy', 'Privacy statement')}
      </Link>
      {' | '}
      {t('registrationPage.footer.copyright', 'Noordhoff Uitgevers')}
    </StyledFooter>
  );
};
