import styled from 'styled-components/macro';

import { ThemeProps } from 'models/styles/theme';
import { calculateRems, calculateRem } from 'styles/mixins/calculateRem';
import { fontColorMixin } from 'styles/mixins/mixins';
import { ElearningThemeInterface } from 'themes/elearningInterface.theme';
import { Paragraph } from 'styles/elements/Texts';
import { Heading3 } from 'styles/elements/Headings';
import { ProductFlag } from 'components/ProductFlag/ProductFlag';

interface Props extends ThemeProps {
  testType?: string;
  alignStart?: boolean;
  fontColor?: string;
}

const getTagColorAndBg = (theme: ElearningThemeInterface, testType?: string): [string, string] => {
  switch (testType) {
    case 'formative-test':
      return [theme.palette.secondary.brand, theme.palette.secondary._200];
    case 'summative-test':
      return [theme.palette.primary.brand, theme.palette.primary._200];
    case 'freebie-test':
      return [theme.palette.feedback.success.brand, theme.palette.feedback.success._200];
    default:
      return ['', ''];
  }
};

export const StyledICardScheduledTest = styled.div<Props>`
  padding: ${calculateRems([28, 24])};
  margin-bottom: ${calculateRem(32)};
  background: ${({ theme }: Props) => theme.palette.neutral.white};
  border-radius: ${calculateRem(4)};
  box-shadow: 0 0 ${calculateRem(8)} rgba(0, 0, 0, 0.1);

  ${Heading3} {
    color: ${({ theme }: Props) => theme.palette.neutral.black};
  }
`;

export const WrapperInline = styled.div<Props>`
  display: flex;
  justify-content: ${({ alignStart }: Props) => (alignStart ? 'flex-start' : 'space-between')};
  align-items: center;

  button {
    font-weight: ${({ theme }: Props) => theme.font.weight.normal};
    font-size: ${({ theme }: Props) => theme.font.size._12};
    margin: ${calculateRems([0, 0, 10, 8])};
  }
`;

export const StyledText = styled(Paragraph)<Props>`
  line-height: ${calculateRem(24)};

  ${(props: Props) => props.fontColor && fontColorMixin};

  & > svg {
    margin-right: ${calculateRem(14)};
    vertical-align: middle;

    path {
      fill: ${({ theme }: Props) => theme.palette.secondary.brand};
    }
  }
`;

export const StyledTextTime = styled(StyledText)`
  margin-right: ${calculateRem(10)};
`;

export const StyledTag = styled(Paragraph)<Props>`
  color: ${({ theme, testType }: Props) => getTagColorAndBg(theme, testType)[0]};
  background: ${({ theme, testType }: Props) => getTagColorAndBg(theme, testType)[1]};
  padding: ${calculateRems([4, 16])};
  font-size: ${({ theme }: Props) => theme.font.size._16};
  border-radius: ${calculateRem(40)};
  font-weight: ${({ theme }: Props) => theme.font.weight.bold};
`;

export const StyledProductFlag = styled(ProductFlag)`
  margin-left: ${calculateRem(16)};
  vertical-align: middle;
`;
