import styled from 'styled-components/macro';
import { ThemeProps as Props } from 'models/styles/theme';
import { Heading1 } from 'styles/elements/Headings';
import { InfoText } from 'components/InfoText';

export const StyledModuleDetailsPage = styled.div<Props>`
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
  margin-bottom: 4rem;
  text-align: center;
  row-gap: 2.5rem;
`;

export const StyledModuleTitle = styled(Heading1)`
  font-size: ${(props: Props) => props.theme.font.size._32};
  text-align: center;
`;

export const StyledInfoTextNoLevelSelected = styled(InfoText)`
  margin: 0 auto;
`;
