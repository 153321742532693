import { ThemeProps } from 'models/styles/theme';
import styled from 'styled-components/macro';
import { Heading2, Heading4 } from 'styles/elements/Headings';

interface StyledDialogProps extends ThemeProps {
  $width?: string;
  $left?: string;
}

type StyledInnerDialogProps = Omit<StyledDialogProps, '$left'>;

export const StyledDialog = styled.div<StyledDialogProps>`
  position: fixed;
  z-index: 100;
  top: 5%;
  left: ${({ $left }: StyledDialogProps) => $left ?? '20%'};
  width: ${({ $width }: StyledDialogProps) => $width ?? '60%'};
  background: ${({ theme }: StyledDialogProps) => theme.palette.neutral.white};
`;

export const StyledInnerDialog = styled.div<StyledInnerDialogProps>`
  margin: auto;
  width: ${({ $width }: StyledDialogProps) => $width ?? '80%'};
`;

export const StyledTitle = styled(Heading2)`
  text-align: center;
  margin: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: ${({ theme }: ThemeProps) => theme.palette.neutral.black};
`;

export const StyledDescription = styled(Heading4)`
  margin: auto;
  color: ${({ theme }: ThemeProps) => theme.palette.neutral.black};
`;

export const StyledInputsWrapper = styled.div`
  margin: auto;
  margin-top: 1rem;
  width: 70%;
`;

export const StyledLabel = styled.p`
  font-size: 0.8rem;
`;

export const StyledSpacer = styled.div`
  height: 2rem;
`;

export const StyledButtonWrapper = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  margin-bottom: 1rem;
  justify-content: center;

  > button:first-child {
    color: ${({ theme }: ThemeProps) => theme.palette.primary.brand};
  }
`;
