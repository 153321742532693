import styled from 'styled-components/macro';

import { ThemeProps } from 'models/styles/theme';
import { Button } from 'components/Button';
import { FontAwesomeIcon } from 'components/FontAwesomeIcon';

export const StyledButton = styled(Button)<ThemeProps>`
  color: ${({ theme }: ThemeProps) => theme.palette.neutral.black};
  background-color: ${({ theme }: ThemeProps) => theme.palette.neutral._200};
  max-height: 3rem;
`;

export const StyledFontAwesomeIcon = styled(FontAwesomeIcon)<ThemeProps>`
  margin-left: 0.5rem;
`;
