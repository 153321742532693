import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DiagnosticTestGroup } from 'models/exam/DiagnosticTest';
import { DiagnosticTestPagePathParams } from 'models/path/path-params';
import { StyledDiagnosticTestGroupsPage } from './DiagnosticTestGroupsPage.styled';
import { DiagnosticTestBreadcrumbs } from '../components/DiagnosticTestBreadcrumbs/DiagnosticTestBreadcrumbs';
import { DiagnosticTestContent } from '../components/DiagnosticTestContent/DiagnosticTestContent';
import { DiagnosticTestGroupItem } from './components/DiagnosticTestGroupItem/DiagnosticTestGroupItem';
import { useDiagnosticTest } from 'hooks/useDiagnosticTest';

export const DiagnosticTestGroupsPage: React.FC = observer(() => {
  const { fetchDiagnosticTestGroups, diagnosticTestGroups } = useDiagnosticTest();
  const { t } = useTranslation('diagnostic-test');
  const { productId, moduleId }: DiagnosticTestPagePathParams = useParams();

  useEffect(() => {
    if (productId && moduleId) {
      fetchDiagnosticTestGroups(+productId, +moduleId);
    }
  }, [productId, moduleId]);

  return (
    <StyledDiagnosticTestGroupsPage>
      <DiagnosticTestBreadcrumbs />
      <DiagnosticTestContent title={t('diagnosticTestGroups.title', 'Diagnostic tests')}>
        {diagnosticTestGroups.map((group: DiagnosticTestGroup) => (
          <DiagnosticTestGroupItem key={group.id} groupId={group.id} item={group} title={group.title} />
        ))}
      </DiagnosticTestContent>
    </StyledDiagnosticTestGroupsPage>
  );
});
