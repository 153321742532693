import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { buildUrlWithPathParams } from '_helpers/utils/urlBuilder';
import { TEST_ENVIRONMENT_ROUTES } from 'constants/routes';

import { useScheduledTests } from 'hooks/useScheduledTests';
import { useTestStatusAndTimeLeft } from 'hooks/useTestStatusAndTimeLeft';

import { LevelDashGroup } from 'components/LevelDashGroup';
import { LoadingSpinner } from 'components/LoadingSpinner';
import { StatusBanner } from 'components/StatusBanner/StatusBanner';

import { Heading2 } from 'styles/elements/Headings';

import { ResultsHeader } from '../../components/ResultsHeader/ResultsHeader';
import { ResultsBreadcrumbs } from '../../components/ResultsBreadcrumbs/ResultsBreadcrumbs';

import { TestResultsPagePathParams } from './TestResultsPage.model';

import {
  StyledContainer,
  StyledExerciseList,
  StyledExerciseListItem,
  StyledProgressBar,
} from './TestResultsPage.styled';

const getExerciseResultsPageUrl = (code: string, exerciseId: number) =>
  buildUrlWithPathParams(TEST_ENVIRONMENT_ROUTES.TEST_EXERCISE_RESULTS_PAGE, {
    code,
    exerciseId,
  });

export const TestResultsPage: React.FC = observer(() => {
  const history = useHistory();
  const { t } = useTranslation('test-environment');
  const { code }: TestResultsPagePathParams = useParams();

  const { scheduledTestScore, fetchScheduledTestScore, scheduledTestStatusAndTimeLeft } = useScheduledTests();

  useTestStatusAndTimeLeft(code);

  useEffect(() => {
    if (!scheduledTestScore) {
      fetchScheduledTestScore(code).then(success => {
        if (!success) {
          history.back();
        }
      });
    }
  }, [scheduledTestScore]);

  if (!scheduledTestScore) {
    return <LoadingSpinner isFullScreen />;
  }

  return (
    <>
      <ResultsHeader secondsLeft={scheduledTestStatusAndTimeLeft?.counter || 0} title={scheduledTestScore.title} />
      <StyledContainer>
        <ResultsBreadcrumbs dataCy="test-results-page-breadcrumbs" />
        <Heading2>{t('testResults.title', 'Test results')}</Heading2>
        <StatusBanner
          dataCy="test-status-banner"
          percentage={Math.round(scheduledTestScore.score)}
          status="info"
          title={t('testResults.testCompleted', 'You have completed the test!')}
        />
        <StyledExerciseList>
          {scheduledTestScore.exercises.map(exercise => (
            <StyledExerciseListItem
              key={exercise.id}
              dataCy="test-results-exercise-item"
              extraData={
                <StyledProgressBar>
                  <LevelDashGroup dashSize={1} level={exercise.score} numberOfDashes={5} />
                  {`${Math.round(exercise.score)}%`}
                </StyledProgressBar>
              }
              href={getExerciseResultsPageUrl(code, exercise.id)}
              title={exercise.title}
            />
          ))}
        </StyledExerciseList>
      </StyledContainer>
    </>
  );
});
