import {
  flexbox,
  grid,
  space,
  layout,
  FlexboxProps,
  GridProps,
  SpaceProps,
  LayoutProps as SLayoutProps,
  textAlign,
  TextAlignProps,
  PositionProps,
  position,
  typography,
  TypographyProps,
  BackgroundProps,
  background,
  GridAreaProps,
  gridArea,
  BorderProps,
  BorderRadiusProps,
  border,
  borderRadius,
} from 'styled-system';
import styled, { css, CSSObject } from 'styled-components/macro';
import { ElearningThemeInterface } from 'themes/elearningInterface.theme';
import { FontAwesomeIcon } from 'components/FontAwesomeIcon';
import { Input } from 'components/Input';
import { Button } from 'components/Button';
import { ProgressBar } from 'components/ProgressBar/ProgressBar';
import { Link } from 'components/Link';

export const FlexCenterBetweenMixin = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export interface LayoutProps {
  layout?: typeof FlexCenterBetweenMixin;
}

// TODO: Add Css prefix for consistency
export const Box = styled.div<
  SpaceProps &
    SLayoutProps &
    TextAlignProps &
    PositionProps &
    BackgroundProps &
    GridAreaProps &
    TypographyProps &
    BorderProps &
    BorderRadiusProps &
    FlexboxProps
>`
  ${space}
  ${layout}
  ${textAlign}
  ${position}
  ${background}
  ${gridArea}
  ${typography}
  ${border}
  ${borderRadius}
  ${flexbox}
`;

export type GridFlexProps = Pick<FlexboxProps, 'alignItems' | 'justifyContent'>;

interface CssGapProp {
  gap?: number;
  rowGap?: number;
  columnGap?: number;
}

const cssGap = (props: CssGapProp & { theme: ElearningThemeInterface }) => `
  gap: ${(props.rowGap || props.gap) ?? '0'}rem
    ${(props.columnGap || props.gap) ?? '0'}rem;
`;

export const CssFlex = styled.div<
  FlexboxProps &
    SpaceProps &
    SLayoutProps &
    CssGapProp &
    PositionProps &
    GridAreaProps &
    BorderProps &
    BorderRadiusProps & { hover?: CSSObject } & { transition?: string }
>`
  display: flex;
  &:hover {
    ${props =>
      props.hover &&
      css`
        ${props.hover}
      `}
  }
  ${cssGap}
  ${space}
  ${layout}
  ${flexbox}
  ${position}
  ${gridArea}
  ${border}
  ${borderRadius}
  ${props =>
    props.transition &&
    css`
      transition: ${props.transition};
    `}
`;

export const CssGrid = styled.div<GridProps & GridFlexProps & SpaceProps & SLayoutProps & CssGapProp>`
  display: grid;
  ${cssGap}
  ${space}
  ${layout}
  ${flexbox}
  ${grid}
`;

export const CssIcon = styled(FontAwesomeIcon)<SpaceProps & SLayoutProps>`
  ${space}
  ${layout}
`;

export const CssClickableIcon = styled(CssIcon)<SpaceProps & SLayoutProps>`
  cursor: pointer;
`;

export const CssInput = styled(Input)<SpaceProps & SLayoutProps & PositionProps>`
  ${space}
  ${layout}
  ${position}
`;

export const CssButton = styled(Button)<SpaceProps & SLayoutProps & PositionProps & GridAreaProps>`
  ${space}
  ${layout}
  ${position}
  ${gridArea}
`;

// TODO: Add Css prefix for consistency
export const Typography = styled.span<TypographyProps & FlexboxProps>`
  ${typography}
  ${flexbox}
  color: ${props => props.color || 'inherit'};
`;

export const CssForm = styled.form<SpaceProps & SLayoutProps>`
  ${space}
  ${layout}
`;

export const CssProgressBar = styled(ProgressBar)<SpaceProps & SLayoutProps>`
  ${space}
  ${layout}
`;

export const CssLink = styled(Link)<SpaceProps & SLayoutProps & PositionProps>`
  ${space}
  ${layout}
  ${position}
`;
