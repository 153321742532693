import React, { ReactNode, useEffect } from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from 'components/FontAwesomeIcon';
import { useProduct } from 'hooks/useProduct';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { useSelectedProductId } from 'hooks/useSelectedProductId';
import { useDomainHandler } from 'hooks/useDomainHandler';
import { useModuleAvailability } from 'hooks/useModuleAvailability';
import { BreadcrumbsRoute } from 'components/Breadcrumbs/Breadcrumbs.model';
import { buildUrlWithPathParams } from '_helpers/utils/urlBuilder';
import {
  TEACHER_HOME_PAGE_ROUTES,
  TEACHER_INSIGHT_PAGE_ROUTES,
  TEACHER_SCHEDULED_TEST_PAGE_ROUTES,
} from 'constants/routes';
import { SkillsSelectorType } from 'constants/teacher-constants';
import { DownloadReportButton } from '../DownloadReportButton/DownloadReportButton';
import { BasicProgressForAccountPagePathParams } from '../../subpages/BasicProgressForAccount/BasicProgressForAccount.model';
import { StyledPageContainer } from '../../subpages/BasicProgress/BasicProgress.styled';
import {
  StyledBreadcrumbs,
  StyledWrapper,
} from '../../subpages/BasicProgressForAccount/BasicProgressForAccount.styled';
import { SkillsSelector } from '../../subpages/BasicProgressForAccount/components/SkillsSelector/SkillsSelector';
import { StudentNavigation } from '../../subpages/BasicProgressForAccount/components/StudentNavigation/StudentNavigation';
import { ProductType } from 'constants/hst-constants';
import { GoalLevelSelector } from 'pages/TeacherEnvironment/TeacherDashboard/subpages/BasicProgressForAccount/components/GoalLevelSelector/GoalLevelSelector';
import { ProductSkillLevel } from 'models/product/ProductDetails';
import { SvgIconReact } from 'components/SvgIconReact';
import {
  StyledEditStudentLink,
  StyledPageHeader,
  StyledPageSubHeader,
  StyledPageSubHeaderWrapper,
  StyledUserInfoTextLabel,
  StyledUserInfoWrapper,
} from './ForAccountHeading.styled';
import { StyledFilterContainer } from '../TeacherDashboardFilters.styled';
import { Heading4 } from 'styles/elements/Headings';
import { trackPlatformEvent } from 'analytics/MetabaseTracker/metabase-tracker';
import { TEACHER_MODULES } from 'analytics/constants/pageTypes';
import { PLATFORM_EVENT_TYPES } from 'analytics/constants/platformEventTypes';

export interface ForAccountProps {
  fullName: string;
  studentNumber: string;
  email: string;
  studentIds: number[] | undefined;
  children: ReactNode;
  breadCrumbRoutes: BreadcrumbsRoute[];
  skillType: SkillsSelectorType;
  isReportLoading?: boolean;
  onReportButtonClick?: () => void;
  targetLevel?: number;
  selectableGoalLevels?: ProductSkillLevel[];
  onTargetLevelChange?: (targetLevelIndex: number) => void;
}

export const ForAccountHeading: React.FC<ForAccountProps> = ({
  fullName,
  email,
  studentNumber,
  studentIds,
  children,
  breadCrumbRoutes,
  skillType,
  isReportLoading = false,
  onReportButtonClick,
  targetLevel,
  selectableGoalLevels,
  onTargetLevelChange,
}) => {
  const { productId, accountId }: BasicProgressForAccountPagePathParams = useParams();
  const { showGoalLevelSelectorOnTeacherDashboard } = useFeatureFlags();
  const { isBusinessDomain } = useDomainHandler();

  const { t } = useTranslation('teacher-dashboard');

  const { productDetails, productsList, nonOwnedProductError, fetchProductDetails, fetchProducts } = useProduct();
  const isTeacherABusinessUser = productsList?.every(product => product.type === ProductType.BUSINESS);
  const history = useHistory();

  useEffect(() => {
    if (productId && (!productDetails || productDetails.id !== +productId)) {
      fetchProductDetails(+productId);
    }
  }, [productId]);

  useEffect(() => {
    !productsList && fetchProducts();
  }, [productsList]);

  const actualProductId = useSelectedProductId();
  const { isAdaptiveModuleAvailable, isWritingModuleAvailable } = useModuleAvailability();

  const onSkillTypeChanged = (currSkillType: SkillsSelectorType) => {
    trackPlatformEvent({
      module: TEACHER_MODULES.BASIC_PROGRESS_FOR_ACCOUNT,
      itemId: PLATFORM_EVENT_TYPES.BASIC_PROGRESS_FOR_ACCOUNT_EVENT_TYPES.UPDATE_VIEW_BY_SKILL_TYPE,
      value: JSON.stringify({ currSkillType, productId, accountId }),
    });

    switch (currSkillType) {
      case SkillsSelectorType.BASIC_SKILLS:
        history.push(
          buildUrlWithPathParams(TEACHER_INSIGHT_PAGE_ROUTES.PROGRESS_FOR_ACCOUNT, {
            productId: actualProductId ?? 68,
            accountId,
          }),
        );
        break;
      case SkillsSelectorType.WRITING_SKILLS:
        history.push(
          buildUrlWithPathParams(TEACHER_INSIGHT_PAGE_ROUTES.WRITING_RESULTS_FOR_ACCOUNT, {
            productId: actualProductId ?? 68,
            accountId,
          }),
        );
        break;
      case SkillsSelectorType.SCHEDULED_TEST_RESULTS:
        history.push(
          buildUrlWithPathParams(TEACHER_SCHEDULED_TEST_PAGE_ROUTES.SCHEDULED_TESTS_RESULTS_FOR_STUDENT, { accountId }),
        );
        break;
    }
  };

  let skillTypeArr = [
    ...(isAdaptiveModuleAvailable ? [SkillsSelectorType.BASIC_SKILLS] : []),
    ...(isWritingModuleAvailable ? [SkillsSelectorType.WRITING_SKILLS] : []),
    ...(!isTeacherABusinessUser ? [SkillsSelectorType.SCHEDULED_TEST_RESULTS] : []),
  ];
  skillTypeArr = [skillType, ...skillTypeArr.filter(x => x !== skillType)];

  if (nonOwnedProductError) {
    return <Redirect to={TEACHER_HOME_PAGE_ROUTES.DASHBOARD} />;
  }

  return (
    <StyledPageContainer>
      <StyledBreadcrumbs dataCy="student-progress-breadcrumbs" routes={breadCrumbRoutes} showAllRoutes={false} />
      <div>
        <StyledPageHeader>{t('basicProgress.forAccount.title')}</StyledPageHeader>
        <StyledPageSubHeaderWrapper>
          <StyledPageSubHeader>
            <SvgIconReact iconName="user" size={3} />
            <span data-cy="student-name">{fullName}</span>
          </StyledPageSubHeader>
          <div>
            {!isBusinessDomain() && (
              <StyledEditStudentLink
                data-cy="edit-student-button"
                to={buildUrlWithPathParams(TEACHER_INSIGHT_PAGE_ROUTES.EDIT_ACCOUNT, { productId, accountId })}
              >
                <SvgIconReact iconName="pencilIcon" size={1} /> {t('basicProgress.forAccount.editAccountButton')}
              </StyledEditStudentLink>
            )}
          </div>
        </StyledPageSubHeaderWrapper>
        <div>
          {onReportButtonClick && <DownloadReportButton loading={isReportLoading} onClick={onReportButtonClick} />}
        </div>
      </div>

      <StyledUserInfoWrapper>
        <Heading4>
          <StyledUserInfoTextLabel>{t('basicProgress.forAccount.email')}: </StyledUserInfoTextLabel>
          <span data-cy="student-info-email">{email}</span>
        </Heading4>
        {!isBusinessDomain() && (
          <Heading4>
            <StyledUserInfoTextLabel>{t('basicProgress.forAccount.studentNumber')}: </StyledUserInfoTextLabel>
            <span data-cy="student-info-number">{studentNumber}</span>
          </Heading4>
        )}
      </StyledUserInfoWrapper>

      <StyledWrapper>
        <StyledFilterContainer gap={1}>
          {skillType === SkillsSelectorType.BASIC_SKILLS &&
            onTargetLevelChange &&
            showGoalLevelSelectorOnTeacherDashboard && (
              <GoalLevelSelector
                selectableGoalLevels={selectableGoalLevels}
                targetLevel={targetLevel}
                onChange={onTargetLevelChange}
              />
            )}
          <SkillsSelector skillTypes={skillTypeArr} onChange={onSkillTypeChanged} />
        </StyledFilterContainer>
      </StyledWrapper>
      {children}
      {studentIds ? (
        <StudentNavigation skillType={skillType} studentIds={studentIds} />
      ) : (
        <FontAwesomeIcon iconName="fas fa-spinner fa-spin" iconSize="2x" />
      )}
    </StyledPageContainer>
  );
};
