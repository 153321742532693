import styled from 'styled-components/macro';

import { ThemeProps as Props } from 'models/styles/theme';

import { Button } from 'components/Button';

export const StyledSection = styled.section<Props>`
  padding: 1.5rem 0;
  margin: 2rem 2rem 2rem 1.5rem;
  border-top: 1px solid ${(props: Props) => props.theme.palette.neutral._600};

  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1rem;
`;

export const StyledButton = styled(Button)`
  width: fit-content;
`;

export const StyledText = styled.div`
  font-size: ${(props: Props) => props.theme.font.size._14};
  color: ${(props: Props) => props.theme.palette.neutral._600};
`;
