import { ApplicationInsights } from '@microsoft/applicationinsights-web';

const rootElement = document.getElementById('root');
const localAppInsight = { applicationinsights: null };

const { applicationinsights } = rootElement ? rootElement.dataset : localAppInsight;
let appInsights: ApplicationInsights | null = null;

const mockAppInsight = {
  trackException: () => 0,
};

const getAppInsight = (): Partial<ApplicationInsights> => {
  if (!applicationinsights || applicationinsights === '{{appInsightsInstrumentationKey}}') {
    return mockAppInsight;
  }
  if (!appInsights) {
    appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: applicationinsights,
      },
    });
    appInsights.loadAppInsights();
    appInsights.trackPageView();
  }
  return appInsights;
};

export default getAppInsight;
