import styled, { css } from 'styled-components/macro';
import { ThemeProps as Props } from 'models/styles/theme';

export const FormWrapper = styled.main<Props>`
  padding: 2rem;
  background-color: ${(props: Props) => props.theme.palette.neutral._100};
  box-shadow: 0px 1px 5px rgba(17, 17, 17, 0.1), 0px 3px 1px rgba(17, 17, 17, 0.06), 0px 2px 2px rgba(17, 17, 17, 0.07);
  border-radius: 8px;

  hr {
    border: none;
    height: 1px;
    background-color: ${(props: Props) => props.theme.palette.neutral._400};
    margin: 2rem 0;
  }
`;

export const FormGroupName = styled.form`
  label {
    font-size: ${(props: Props) => props.theme.font.size._14};
    margin-bottom: 0.5rem;
  }
`;

export const FormGroupNameLine = styled.div`
  display: flex;
  align-items: center;

  input {
    background-color: ${(props: Props) => props.theme.palette.neutral.white};
    width: 250px;
    margin-right: 1rem;
  }
`;

export const SearchStudentsInputWrapper = styled.div`
  position: sticky;
  top: 0;
  z-index: 2;
  isolation: isolate;
  margin: 0 -1rem;
  margin-bottom: 0.5rem;
  padding: 1rem;
  padding-bottom: 0;
  background-color: rgba(255, 255, 255, 0.87);

  input {
    background-color: ${(props: Props) => props.theme.palette.neutral.white};
  }
`;

export const FormStudentsWrapper = styled.div<{ $hasEmptyStudentsList: boolean }>`
  display: flex;
  align-items: flex-start;

  h3 {
    font-size: ${(props: Props) => props.theme.font.size._18};
  }

  > div {
    width: 100%;
  }

  ${({ $hasEmptyStudentsList }) =>
    !$hasEmptyStudentsList &&
    css`
      > div {
        width: 50%;
      }
      > div:first-child {
        padding-right: 1rem;
      }
      > div:last-child {
        padding-left: 1rem;
      }
    `}

  textarea {
    width: 100%;
    max-width: 100%;
    min-height: 80px;
    background-color: ${(props: Props) => props.theme.palette.neutral.white};
    resize: vertical;
  }
`;

export const NotAddedStudentsWrapper = styled.div`
  ul {
    color: ${(props: Props) => props.theme.palette.feedback.error.brand};
    font-size: ${(props: Props) => props.theme.font.size._14};
  }
`;

export const StudentsTableWrapper = styled.div`
  padding: 1rem;
  padding-top: 0;
  background-color: ${(props: Props) => props.theme.palette.neutral.white};
  border: 1px solid ${(props: Props) => props.theme.palette.neutral._400};
  border-radius: 4px;

  max-height: 300px;
  overflow-y: auto;
`;

export const EllipsisElement = styled.div`
  position: relative;
  padding: 0.2rem;

  &:before {
    content: '&nbsp;';
    visibility: hidden;
  }

  > span {
    position: absolute;
    left: 0;
    right: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const StudentsTable = styled.table`
  width: 100%;
  font-size: ${(props: Props) => props.theme.font.size._14};

  td:nth-child(2) {
    width: 28%;
  }

  td:not(:last-child) {
    padding-right: 1rem;
  }

  button:hover svg {
    color: ${(props: Props) => props.theme.palette.accent._02};
  }
`;
