import styled, { css } from 'styled-components/macro';

import { media } from 'styles/media';
import { calculateRems } from 'styles/mixins/calculateRem';
import { ElearningThemeInterface } from 'themes/elearningInterface.theme';

import { Heading6 } from 'styles/elements/Headings';
import { SvgIconReact } from 'components/SvgIconReact';
import { CopyrightBottomBar } from 'components/CopyrightBottomBar/CopyrightBottomBar';

interface Props {
  theme: ElearningThemeInterface;
}

export const FooterBackground = css`
  background-color: ${({ theme }: Props) => theme.palette.neutral.white};
`;
export const StyledFooter = styled.footer`
  display: grid;
  row-gap: 1rem;
  grid-template-columns: minmax(26rem, 1fr) 1fr;
  padding: ${calculateRems([48, 64])};
  position: relative;
  ::before {
    height: 1px;
    content: '';
    left: 2rem;
    right: 2rem;
    position: absolute;
    background: ${(props: Props) => props.theme.palette.neutral._400};
  }
`;
export const StyledLinksWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
`;
export const StyledLinks = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 0.2rem;
  ${media.tablet} {
    padding: 1rem;
  }
`;
export const StyledGroupLinkTitle = styled(Heading6)`
  font-weight: ${(props: Props) => props.theme.font.weight.bold};
  font-size: ${(props: Props) => props.theme.font.size._14};
  font-weight: bold;
  letter-spacing: 0.6px;
  line-height: 20px;
`;
export const StyledLinkItem = styled.span`
  margin-bottom: 0.5rem;
  font-size: ${(props: Props) => props.theme.font.size._14};
  ${media.laptop} {
    font-size: 0.8rem;
  }
`;
export const HstStyledLogoFooter = styled(SvgIconReact)`
  height: 8rem;
  width: 11rem;
`;
export const NoordhoffStyledLogoFooter = styled(SvgIconReact)`
  height: 8rem;
  width: 8rem;
`;
export const StyledSeparatorFooterLine = css`
  width: calc(100vw - 4rem);
  margin: 0;
`;

export const StyledCopyrightBottomBar = styled(CopyrightBottomBar)`
  grid-column: span 2;
  margin: 0 -4rem -3rem -4rem;
`;
