import styled from 'styled-components/macro';

import { calculateRem } from 'styles/mixins/mixins';
import { ThemeProps as Props } from 'models/styles/theme';

import { Select } from 'components/SelectUI/Select';
import { CssFlex } from 'styles/helpers/layout';

export const StyledSelect = styled(Select)<Props>`
  width: ${calculateRem(396)};
`;

export const StyledSubtitle = styled.span<Props>`
  font-size: ${(props: Props) => props.theme.font.size._18};
  color: ${(props: Props) => props.theme.palette.neutral._900};
`;

export const StyledMainSection = styled(CssFlex)<Props>`
  max-width: max-content;
`;
