import styled, { css } from 'styled-components/macro';
import { Heading3 } from 'styles/elements/Headings';
import { calculateRem } from 'styles/mixins/mixins';
import { calculateRems } from 'styles/mixins/calculateRem';
import { Button } from 'components/Button';
import { ThemeProps } from 'models/styles/theme';
import { StyledCardStatusProps as Props } from './CardStatus.model';
import { SanitizedHtml } from 'components/SanitizedHtml';

export const StyledCardStatus = styled.div<Props>`
  display: grid;
  grid-template-columns: 70% 2fr 1fr;
  align-items: center;
  height: ${(props: Props) => (props.options?.smallSize ? calculateRem(136) : calculateRem(172))};
  border: 1px solid ${(props: Props) => props.theme.palette.neutral._400};
  padding: ${calculateRems([20, 24, 20, 32])};
`;

export const StyledDescription = styled(SanitizedHtml)<Props>`
  font-size: ${(props: Props) => props.theme.font.size._14};
  color: ${(props: Props) => props.theme.palette.neutral._900};
`;

export const StyledButton = styled(Button)`
  justify-self: flex-end;
  font-size: ${(props: ThemeProps) => props.theme.font.size._16};
`;

export const StyledHeading3 = styled(Heading3)<Props>`
  ${(props: Props) =>
    !props.description &&
    css`
      margin-bottom: 0;
    `}
`;
