import styled from 'styled-components/macro';
import { ThemeProps } from 'models/styles/theme';
import { InputWithErrorMessage } from 'components/InputWithErrorMessage';

export const StyledInput = styled(InputWithErrorMessage)<ThemeProps>`
  width: 410px;
`;

export const StyledField = styled.div<ThemeProps>`
  display: flex;
  flex-direction: column;
  margin-top: 21px;

  label {
    font-weight: ${({ theme }: ThemeProps) => theme.font.weight.bold};
    margin-bottom: 5px;
  }

  p {
    color: ${({ theme }: ThemeProps) => theme.palette.neutral._900};
    font-size: ${({ theme }: ThemeProps) => theme.font.size._14};
  }
`;

export const StyledLoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledPageHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledPageHeader = styled.h2`
  align-items: center;
  display: flex;
  gap: 1rem;
`;

export const StyledUserInfoText = styled.p`
  color: gray;
`;

export const StyledUserInfoTextData = styled.span`
  font-weight: bold;
`;
