import React from 'react';
import { TopicDetails } from 'models/product/TopicDetails';

import { Button } from 'components/Button';
import { SanitizedHtml } from 'components/SanitizedHtml';
import { SvgIconReact } from 'components/SvgIconReact';
import { LoadingSpinner } from 'components/LoadingSpinner';
import { TheoryRenderer } from 'components/TheoryRenderer';
import { CopyrightBottomBar } from 'components/CopyrightBottomBar/CopyrightBottomBar';

import { Heading1 } from 'styles/elements/Headings';
import { StyledViewTheory, StyledContentContainer } from './ViewTheory.styled';

export interface ViewTheoryProps {
  onClose: () => void;
  topic: TopicDetails;
}

export const ViewTheory: React.FC<ViewTheoryProps> = ({ onClose, topic }) => {
  if (!topic?.id) {
    return (
      <StyledViewTheory>
        <LoadingSpinner />
      </StyledViewTheory>
    );
  }

  return (
    <StyledViewTheory>
      <Button dataCy="close-view-theory-moda-button" onClick={() => onClose()}>
        <SvgIconReact iconName="closeX" />
      </Button>
      <StyledContentContainer flexDirection="column" justifyContent="space-between">
        <div className="theory">
          <Heading1 mt={1}>
            <SanitizedHtml dirtyHtml={topic.title || ''} />
          </Heading1>
          <TheoryRenderer content={topic.content} productId={topic.product.id} />
        </div>
        <CopyrightBottomBar />
      </StyledContentContainer>
    </StyledViewTheory>
  );
};
