import React from 'react';
import { useTranslation } from 'react-i18next';

import { Paragraph } from 'styles/elements/Texts';
import { Heading4 } from 'styles/elements/Headings';

import { ToggleSwitchProps } from './ToggleSwitch.model';
import { StyledToggleWrapper, StyledToggleSwitch, StyledSlider } from './ToggleSwitch.styled';
import { Box } from 'styles/helpers/layout';

export const ToggleSwitch: React.FC<ToggleSwitchProps> = ({
  isActive,
  onChange,
  className,
  dataCy,
  labelTitle,
  labelSubtitle,
  name,
  variant,
  showStatusText = false,
}) => {
  const { t } = useTranslation('common');
  const onText = t('toggle.on', 'on');
  const offText = t('toggle.off', 'off');

  return (
    <StyledToggleWrapper className={className} variant={variant}>
      <Box marginBottom={showStatusText ? '0.25rem' : 0}>
        {labelTitle && <Heading4>{labelTitle}</Heading4>}
        {labelSubtitle && <Paragraph>{labelSubtitle}</Paragraph>}
      </Box>
      <StyledToggleSwitch data-cy={dataCy} variant={variant}>
        <input checked={isActive} name={name} type="checkbox" onChange={onChange} />
        <StyledSlider />
        {showStatusText && <Paragraph>{isActive ? onText : offText}</Paragraph>}
      </StyledToggleSwitch>
    </StyledToggleWrapper>
  );
};
