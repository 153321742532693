import styled from 'styled-components/macro';
import { ElearningThemeInterface } from 'themes/elearningInterface.theme';

interface Props {
  theme: ElearningThemeInterface;
}

export const StyledWordActionFeedback = styled.div<Props>`
  width: 235px;
  padding: 24px 16px;
  border: 1px solid ${(props: Props) => props.theme.palette.neutral._400};
  border-radius: ${(props: Props) => props.theme.borderRadius.rounded};
  background: ${(props: Props) => props.theme.palette.neutral.white};
`;
