import { AssignmentTopicProgressionStatus, AssignmentTopicProgressionStatusType } from 'models/progress/Progress';
import styled from 'styled-components/macro';
import { CssFlex, CssGrid } from 'styles/helpers/layout';
import { ElearningThemeInterface } from 'themes/elearningInterface.theme';

interface Props {
  theme: ElearningThemeInterface;
}

export interface StyledCorrectTextTopicProps extends Props {
  topicState: AssignmentTopicProgressionStatusType;
  isDisabled: boolean;
}

export const StyledCorrectTextTopic = styled(CssGrid)<StyledCorrectTextTopicProps>`
  font-size: ${(props: Props) => props.theme.font.size._14};

  ${({ theme, isDisabled, topicState }: StyledCorrectTextTopicProps) => {
    if (isDisabled) {
      return `color: ${theme.palette.neutral._600};`;
    }

    switch (topicState) {
      case AssignmentTopicProgressionStatus.LOCKED:
        return `
          color: ${theme.palette.secondaryNew._200};
        `;
      case AssignmentTopicProgressionStatus.READY:
        return `
          background: ${theme.palette.neutral._200};
          color: ${theme.palette.neutral.black};
          cursor: pointer;

          :hover {
            background: #E6E6E6;
          }
        `;
      case AssignmentTopicProgressionStatus.FAILED:
        return `
          :hover {
            background: #E6E6E6;
          }
        `;
      case AssignmentTopicProgressionStatus.COMPLETED:
        return `
          border: none;
          background: ${theme.palette.tertiary.brand};
          color: ${theme.palette.tertiary._200};
        `;
    }
  }};
`;

export const StyledTitle = styled(CssFlex)<Props>`
  font-weight: bold;
  font-size: ${(props: Props) => props.theme.font.size._16};
`;

export const StyledNeedReviewMessage = styled(CssGrid)<Props>`
  column-gap: 1rem;
  grid-template-columns: auto 1fr;
  justify-items: start;
  grid-column: 2 / span 3;
`;

export const StyledTopicList = styled.ul<Props>`
  padding-left: 1.5rem;

  li {
    color: #505775;
    text-align: left;
  }
`;
