import { UnderConstructionMessage } from 'components/UnderConstructionMessage/UnderConstructionMessage';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Heading3 } from 'styles/elements/Headings';
import { TransferResults } from './components/TransferResults/TransferResults';
import { StyledSection } from './UserToolsPage.styled';
import { DeleteAccounts } from './components/DeleteAccounts/DeleteAccounts';

export const UserToolsPage: FC = observer(() => {
  const { showDeleteAccountsTool } = useFeatureFlags();
  const { t } = useTranslation('admin-environment');
  return (
    <div>
      <StyledSection>
        <Heading3>{t('adminTools.transferResults.title')}</Heading3>
        <TransferResults />
      </StyledSection>
      <StyledSection>
        <Heading3>{t('adminTools.deleteAccounts.title')}</Heading3>
        {showDeleteAccountsTool ? <DeleteAccounts /> : <UnderConstructionMessage />}
      </StyledSection>
    </div>
  );
});
