import styled from 'styled-components/macro';

import { calculateRem } from 'styles/mixins/mixins';
import { ThemeProps as Props } from 'models/styles/theme';

import { Link } from 'components/Link';
import { Button } from 'components/Button';
import { SvgIconReact } from 'components/SvgIconReact';

export const StyledPageLayout = styled.div<Props>`
  min-height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: ${(props: Props) => props.theme.palette.neutral._100};
`;

export const StyledHeader = styled.header<Props>`
  width: 100%;
  color: ${(props: Props) => props.theme.palette.neutral.black};
  background: ${(props: Props) => props.theme.palette.neutral.white};
  display: flex;
  padding: 1rem 3rem;
  column-gap: 3rem;
  align-items: center;
  justify-content: space-between;
`;

export const StyledLogo = styled(SvgIconReact)`
  height: 4rem;
  width: 12rem;
`;

export const StyledLoginSection = styled.section<Props>`
  display: flex;
  column-gap: 2rem;
  align-items: center;
`;

export const StyledButton = styled(Button)<Props>`
  font-size: 1rem;
  font-weight: bold;
`;

export const StyledFooter = styled.footer<Props>`
  width: 100%;
  color: ${(props: Props) => props.theme.palette.neutral._900};
  background: ${(props: Props) => props.theme.palette.neutral.white};
  padding: 1rem 3rem;
  text-align: center;
  font-size: ${calculateRem(12)};

  > a {
    font-size: ${calculateRem(12)};
  }
`;

export const StyledContainer = styled.main<Props>`
  flex-grow: 1;
  padding: 5rem;
  max-width: 100%;
  width: ${calculateRem(940)};
`;

export const StyledTitle = styled.div<Props>`
  margin: 0 4.5rem 1.5rem 4.5rem;
`;

export const StyledCard = styled.div<Props>`
  padding: 2.5rem 4.5rem;
  color: ${(props: Props) => props.theme.palette.neutral._900};
  background: ${(props: Props) => props.theme.palette.neutral.white};

  p {
    line-height: 150%;
    color: ${(props: Props) => props.theme.palette.neutral._900};
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    > li {
      line-height: 200%;
      margin-bottom: 0.5rem;

      ::before {
        content: '✓';
        width: fit-content;
        margin-right: 0.5em;
        display: inline-block;
        color: ${(props: Props) => props.theme.palette.primary.brand};
      }
    }
  }

  > :last-child {
    margin-bottom: 0;
  }
`;

export const StyledLinkButton = styled(Link)<Props>`
  display: block;
  margin: 1.5rem 0 1rem 0;

  span {
    color: ${(props: Props) => props.theme.palette.neutral.white} !important;
  }
`;

export const StyledSeparator = styled.div<Props>`
  display: flex;
  align-items: center;
  justify-content: center;

  margin: 2.5rem 0;
  text-transform: uppercase;
  color: ${(props: Props) => props.theme.palette.neutral._600};

  ::before {
    content: '';
    height: 1px;
    width: 15rem;
    background-color: ${(props: Props) => props.theme.palette.neutral._600};
    display: block;
    margin-right: 1rem;
  }

  ::after {
    content: '';
    height: 1px;
    width: 15rem;
    background-color: ${(props: Props) => props.theme.palette.neutral._600};
    display: block;
    margin-left: 1rem;
  }
`;

export const StyledAvailabilityNote = styled.p<Props>`
  margin: 0;
  font-size: ${calculateRem(14)};
`;
