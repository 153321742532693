import styled from 'styled-components/macro';
import { ThemeProps as Props } from 'models/styles/theme';

import { Heading3 } from 'styles/elements/Headings';
import { FontAwesomeIcon } from 'components/FontAwesomeIcon';

export const StyledContainer = styled.section<Props>`
  padding: 5rem;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  align-items: center;
  justify-content: center;
`;

export const StyledIcon = styled(FontAwesomeIcon)<Props>`
  margin-bottom: 1.5rem;
  font-size: 10rem;
  color: ${(props: Props) => props.theme.palette.secondary._200};
`;

export const StyledTitle = styled(Heading3)<Props>`
  font-size: 1.25rem;
  font-weight: bold;
  margin: 0;
  letter-spacing: 0.25rem;
  text-transform: uppercase;
  color: ${(props: Props) => props.theme.palette.secondary.brand};
`;

export const StyledMessage = styled.article<Props>`
  font-size: 1rem;
  text-align: center;
  color: ${(props: Props) => props.theme.palette.neutral._900};
`;
