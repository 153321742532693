import styled from 'styled-components/macro';

import { calculateRem } from 'styles/mixins/mixins';
import { ThemeProps as Props } from 'models/styles/theme';

import { Heading2 } from 'styles/elements/Headings';

import { Input } from 'components/Input';
import { Button } from 'components/Button';

export const StyledForm = styled.form<Props>`
  width: 60rem;
  padding: ${calculateRem(40)};
  height: fit-content;
  border-radius: ${calculateRem(4)};
  background-color: ${({ theme }: Props) => theme.palette.neutral.white};
`;

export const StyledTitle = styled(Heading2)<Props>``;

export const StyledMessage = styled.p<Props>`
  font-size: 1rem;
  line-height: 150%;
`;

export const StyledFieldRow = styled.div<Props>`
  display: flex;
  margin-top: 2rem;
`;

export const StyledInput = styled(Input)<Props>`
  border-right: 0;
  max-height: none;
`;

export const StyledButton = styled(Button)<Props>`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
`;
