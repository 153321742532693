import { SvgIconReact } from 'components/SvgIconReact';
import React from 'react';
import { TimePickerProps } from './TimePicker.model';
import { StyledLeftIcon, StyledReactTimePicker } from './TimePicker.styled';

const defaultTime = `${new Date().getHours()}:${new Date().getMinutes()}`;

export const TimePicker: React.FC<TimePickerProps> = ({
  dataCy,
  value = defaultTime,
  disabled = false,
  hasError = false,
  language,
  minTime,
  maxTime,
  onChange,
}) => {
  const onValueChange = (t: string | null) => {
    const [hours = '0', minutes = '0', seconds = '0'] = t ? t.split(':') : [];
    onChange(parseInt(hours, 10), parseInt(minutes, 10), parseInt(seconds, 10));
  };
  return (
    <div data-cy={dataCy}>
      <StyledLeftIcon>
        <SvgIconReact iconName="clockIcon" size={1.5} />
      </StyledLeftIcon>
      <StyledReactTimePicker
        disableClock
        $hasError={hasError}
        clearIcon={null}
        clockIcon={null}
        disabled={disabled}
        format="HH:mm"
        locale={language}
        maxTime={maxTime}
        minTime={minTime}
        value={value}
        onChange={t => onValueChange(t as string)}
      />
    </div>
  );
};
