import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

import { ScheduledTestResultList } from 'models/exam/ScheduledTest';

import { SvgIconReact } from 'components/SvgIconReact';
import { useScheduledTests } from 'hooks/useScheduledTests';

import { ScheduledTestResultsTable } from '../ScheduledTestResultsTable/ScheduledTestResultsTable';

import { StyledContainer, StyledTitle, StyledNotice } from './ScheduledTestResultsPageLayout.styled';
import { ScheduledNoOwnedTestResultsTable } from '../ScheduledNoOwnedTestResultsTable/ScheduledNoOwnedTestResultsTable';

interface Props {
  title: string;
  testResults: ScheduledTestResultList[] | null;
  noResultsMessage: string;
  dataCy?: string;
  ownedProducts?: boolean;
}

export const ScheduledTestResultsPageLayout: React.FC<Props> = observer(
  ({ title, testResults = [], noResultsMessage, dataCy, ownedProducts = true }) => {
    const { t } = useTranslation('results');
    const {
      fetchScheduledTestsReviewPeriodTimer,
      scheduledTestsReviewPeriodTimer,
      setScheduledTestsReviewPeriodTimer,
    } = useScheduledTests();

    useEffect(() => {
      fetchScheduledTestsReviewPeriodTimer();

      const interval = setInterval(() => {
        fetchScheduledTestsReviewPeriodTimer();
      }, 10000);

      return () => {
        clearInterval(interval);
        setScheduledTestsReviewPeriodTimer([]);
      };
    }, [fetchScheduledTestsReviewPeriodTimer]);

    useEffect(() => {
      const interval = setInterval(() => {
        if (scheduledTestsReviewPeriodTimer && scheduledTestsReviewPeriodTimer.length > 0) {
          setScheduledTestsReviewPeriodTimer(
            scheduledTestsReviewPeriodTimer.map(timer => ({
              ...timer,
              counter: timer.counter - 1,
            })),
          );
        }
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    });

    const latestScore = (testResults && testResults[0]?.score) || 0;
    const hasResultsAvailable = testResults && testResults?.length > 0;
    const hasPassedLatest = testResults && testResults[0]?.hasPassed;

    const renderScheduledTestResultsTable = (hasOwnedProducts: boolean) => {
      if (!hasOwnedProducts) {
        return (
          <ScheduledNoOwnedTestResultsTable data={testResults || []} timers={scheduledTestsReviewPeriodTimer || []} />
        );
      }
      return <ScheduledTestResultsTable data={testResults || []} timers={scheduledTestsReviewPeriodTimer || []} />;
    };

    return (
      <StyledContainer data-cy={dataCy}>
        <StyledTitle>{title}</StyledTitle>
        <StyledNotice>
          {hasPassedLatest && (
            <span data-cy="medal-icon">
              <SvgIconReact iconName="medal" size={3} />
            </span>
          )}
          {hasResultsAvailable ? (
            <span data-cy="latest-score-message">
              {t('latestScoreNotice', `You scored an average of ${Math.round(latestScore)}% on your last test!`, {
                score: Math.round(latestScore),
              })}
            </span>
          ) : (
            <span data-cy="no-results-message">{noResultsMessage}</span>
          )}
        </StyledNotice>
        {hasResultsAvailable && renderScheduledTestResultsTable(ownedProducts)}
      </StyledContainer>
    );
  },
);
