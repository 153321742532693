import React, { FC } from 'react';
import { LayoutProps, SpaceProps } from 'styled-system';
import { StyledCard } from './Card.styled';

export interface CardProps {
  className?: string;
  layout?: SpaceProps & LayoutProps;
}

export const Card: FC<CardProps> = ({ className, children, layout }) => {
  return (
    <StyledCard className={className} {...layout}>
      {children}
    </StyledCard>
  );
};
