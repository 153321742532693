import styled from 'styled-components/macro';
import { StyledCountdownTimer } from 'components/CountdownTimer/CountdownTimer.styled';
import { TestHeader } from 'components/TestHeader/TestHeader';
import { ThemeProps } from 'models/styles/theme';

export const StyledTestHeader = styled(TestHeader)`
  ${StyledCountdownTimer} {
    color: ${({ theme }: ThemeProps) => theme.palette.neutral._900};
  }
`;
