export enum ProductTestGroupType {
  FORMATIVE = 'formative-test',
  SUMMATIVE = 'summative-test',
  FREEBIE = 'freebie-test',
}

export interface ProductTest {
  id: number;
  title: string;
}

export interface ProductTestGroup {
  id: number;
  label: string;
  level: number;
  tests: ProductTest[];
}
