import styled from 'styled-components/macro';

import { ThemeProps as Props } from 'models/styles/theme';
import { CssFlex, CssIcon } from 'styles/helpers/layout';
import { Heading3, Heading4 } from 'styles/elements/Headings';

export const StyledHeader = styled.section<Props>`
  display: flex;
  width: 100%;
  margin: 1rem 0 0 0;
  align-items: flex-start;
  justify-content: space-between;
`;

export const StyledSummary = styled(CssFlex)`
  border: solid 1px #ada689;
  border-radius: 4px;
  background: transparent;
  padding: 1.5rem;
  margin-bottom: 3rem;

  ${CssIcon} {
    color: #767d83;
  }
`;

export const StyledSummaryTitle = styled(Heading3)`
  font-size: 1.3rem;
  font-weight: bold;
`;

export const StyledSummarySubtitle = styled(Heading4)`
  font-size: 1.13rem;
  font-weight: bold;
  border-top: 1px #eeeeee solid;
  padding-top: 1.7rem;
  margin-top: 1.7rem;
`;
