import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import { ResultsAPI } from 'generated/types';
import { modalIds } from 'constants/modals-constants';
import { useDomainHandler } from 'hooks/useDomainHandler';
import { useModal } from 'hooks/useModal';
import { useSEBKeys } from 'hooks/useSEBKeys';

import { useTheme } from 'styled-components/macro';
import { ElearningThemeInterface } from 'themes/elearningInterface.theme';
import { Checkbox } from 'components/Checkbox/Checkbox';
import { ToggleSwitch } from 'components/ToggleSwitch/ToggleSwitch';
import { FormDivider } from 'components/SteppedForm/SteppedForm.styled';
import { SEBKeyUpsertDialog } from './components/SEBKeyUpsertDialog/SEBKeyUpsertDialog';
import {
  StyledTable,
  StyledSEBCheckBoxWrapper,
  StyledFontAwesomeIcon,
  StyledCreateNewButton,
  StyledIconWrapper,
  KeyWrapper,
} from './FormSEBListStep.styled';
import { TooltipModal } from '../TooltipModal/TooltipModal';

export interface FormSEBListStepProps {
  scheduledTestId: number | null;
  onKeysSelected: (keys: number[]) => void;
}

export const FormSEBListStep: React.FC<FormSEBListStepProps> = observer(({ scheduledTestId, onKeysSelected }) => {
  const [showSEBKeysList, setShowSEBKeysList] = useState(false);
  const [currentKeys, setCurrentKeys] = useState<ResultsAPI.SEBKey[]>([]);
  const [updatingKeyId, setUpdatingKeyId] = useState<number | null>(null);
  const { availableKeys, insertKey, updateKey, fetchAvailableKeys } = useSEBKeys();
  const { toggleModal, closeModal } = useModal();
  const { getCustomNameByDomain } = useDomainHandler();
  const { t } = useTranslation('scheduled-tests');
  const hogesTheme = useTheme() as ElearningThemeInterface;

  useEffect(() => {
    fetchAvailableKeys(scheduledTestId);
  }, []);

  useEffect(() => {
    setCurrentKeys(availableKeys);

    if (availableKeys.some(({ isActiveInTest }) => isActiveInTest)) {
      setShowSEBKeysList(true);
    }
  }, [availableKeys]);

  const onCheckedKey = (id: number, checked: boolean) => {
    const modifiedKeys = currentKeys.map(key => (key.id === id ? { ...key, isActiveInTest: checked } : key));
    setCurrentKeys(modifiedKeys);
    onKeysSelected(modifiedKeys.filter(({ isActiveInTest }) => isActiveInTest === true).map(({ id }) => id));
  };

  const handleSubmitted = (key: ResultsAPI.InsertSEBKey | ResultsAPI.UpdateSEBKey) => {
    if (updatingKeyId !== null) updateKey(key as ResultsAPI.UpdateSEBKey, () => fetchAvailableKeys(scheduledTestId));
    else insertKey(key as ResultsAPI.InsertSEBKey, () => fetchAvailableKeys(scheduledTestId));
    setUpdatingKeyId(null);
    closeModal();
  };

  return (
    <>
      <FormDivider />
      <SEBKeyUpsertDialog
        editSEBKey={currentKeys.find(({ id }) => id === updatingKeyId) ?? null}
        onSubmit={handleSubmitted}
      />
      <StyledSEBCheckBoxWrapper>
        <span>{t('testOptionsStep.sebKeys.modal.title')}</span>
        <>
          <ToggleSwitch
            dataCy="toggle-seb-keys"
            isActive={showSEBKeysList}
            variant="primary"
            onChange={() => setShowSEBKeysList(!showSEBKeysList)}
          />
          <TooltipModal
            content={{
              title: t('testOptionsStep.sebKeys.modal.title'),
              paragraph: t('testOptionsStep.sebKeys.modal.paragraph', { appName: getCustomNameByDomain() }),
            }}
            dataCy="seb-modal"
            modalId={modalIds.sebKeysInfoModal}
          />
        </>
      </StyledSEBCheckBoxWrapper>
      {showSEBKeysList && (
        // TODO: rethink this design. Recommended to use grid https://github.com/infinitaslearning/app-he-hoges-web/issues/1167
        <StyledTable>
          <thead>
            <tr>
              <th>{t('testOptionsStep.sebKeys.modal.availableKeys')}</th>
              <th>{t('testOptionsStep.sebKeys.modal.keys')}</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {currentKeys.map(({ id, isActiveInTest, key, name }) => (
              <tr key={`checkbox-wrapper-${id}`}>
                <td>
                  <Checkbox
                    checked={isActiveInTest}
                    dataCy={`checkbox-${id}`}
                    id={`checkbox-${id}`}
                    label=""
                    onChange={e => onCheckedKey(id, e.target.checked)}
                  />
                  <span data-cy={`name-${id}`}>{name}</span>
                </td>
                <td>
                  <KeyWrapper data-cy={`key-${id}`}>{key}</KeyWrapper>
                </td>
                <td>
                  <StyledFontAwesomeIcon
                    data-cy={`modify-seb-key-${id}`}
                    iconName="fas fa-edit"
                    onClick={() => {
                      toggleModal(modalIds.sebKeysModal);
                      setUpdatingKeyId(id);
                    }}
                  />
                </td>
              </tr>
            ))}
            <tr>
              <td>
                <StyledIconWrapper>
                  <StyledFontAwesomeIcon iconName="fas fa-plus" style={{ color: hogesTheme.palette.primary.brand }} />
                  <StyledCreateNewButton
                    data-cy="new-seb-key"
                    variant="default"
                    onClick={() => {
                      toggleModal(modalIds.sebKeysModal);
                      setUpdatingKeyId(null);
                    }}
                  >
                    {t('testOptionsStep.sebKeys.modal.new')}
                  </StyledCreateNewButton>
                </StyledIconWrapper>
              </td>
            </tr>
          </tbody>
        </StyledTable>
      )}
    </>
  );
});
