/* eslint-disable @typescript-eslint/no-use-before-define */
import { DEFAULT_PAGE, DEFAULT_ROWS_PER_PAGE, PAGE_FILTER_KEY } from 'constants/admin-constants';
import { SchoolYear } from 'models/product/SchoolYear';
import { Education } from 'models/auth/Login';
import { OverviewFilters, SubjectsProgressOverviewFilters } from 'models/progress/Progress';
import {
  BasicProgressOverviewPagePathParams,
  BasicProgressOverviewPageQueryParams,
} from 'pages/TeacherEnvironment/TeacherDashboard/subpages/BasicProgress/BasicProgress.model';
import { useEffect } from 'react';
import { buildUrlWithPathAndQueryParams, URLFields } from '_helpers/utils/urlBuilder';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { ProductDetails } from 'models/product/ProductDetails';
import { useProduct } from './useProduct';
import { useProfile } from './useProfile';
import useOnLocationChange from './useOnLocationChange';
import { useFeatureFlags } from './useFeatureFlags';

interface TeacherOverviewInterface {
  page: number;
  productId: string;
  showEducationFilter: boolean | undefined;
  showSchoolYearFilter: boolean | undefined;
  nonOwnedProductError: boolean | undefined;
  currentEducationTitle: string | undefined;
  currentYearsTitle: string | undefined;
  buildPaginatedURL: (key: string, value?: string | number | string[]) => string;
  productDetails: ProductDetails | undefined;
  productSchoolYears: SchoolYear[] | undefined;
  userEducations: Education[] | undefined;
  paginationFilters: OverviewFilters | SubjectsProgressOverviewFilters;
}

export const useTeacherOverview = (route: string): TeacherOverviewInterface => {
  const { t } = useTranslation('teacher-dashboard');
  const history = useHistory();
  const { productId }: BasicProgressOverviewPagePathParams = useParams();
  const { queryParams } = useOnLocationChange(history);
  const { showSchoolYearFilter: enableSchoolYearFilter } = useFeatureFlags();

  const {
    page = DEFAULT_PAGE,
    resultsPerPage = DEFAULT_ROWS_PER_PAGE,
    educationId,
    schoolYearStart,
    schoolYearEnd,
    searchTerm,
    groupId,
  }: BasicProgressOverviewPageQueryParams = queryString.parse(queryParams, { parseNumbers: true });

  const paginationFilters: OverviewFilters = {
    page,
    resultsPerPage,
    educationId,
    schoolYearStart,
    schoolYearEnd,
    groupId,
    searchTerm,
  };

  const filters = Object.keys(paginationFilters).map(key => ({
    key,
    value: paginationFilters[key as keyof typeof paginationFilters],
  })) as URLFields[];

  // Method to generate the URLs that will be used when clicking on the pagination controls
  const buildPaginatedURL = (key: string, value?: string | number | string[]) => {
    const newPageFilters = filters
      .map(filter => {
        if (key === 'schoolYear' && filter.key === 'schoolYearStart' && Array.isArray(value)) {
          return { key: 'schoolYearStart', value: value[0] };
        }
        if (key === 'schoolYear' && filter.key === 'schoolYearEnd' && Array.isArray(value)) {
          return { key: 'schoolYearEnd', value: value[1] };
        }
        if (key === filter.key && !Array.isArray(value)) {
          return { ...filter, value };
        }
        if (filter.key === PAGE_FILTER_KEY && key !== PAGE_FILTER_KEY) {
          return { key: PAGE_FILTER_KEY, value: 1 };
        }
        return filter;
      })
      .flat()
      .filter(newFilter => newFilter.value)
      .reduce((accumFilters, filter) => ({ ...accumFilters, [filter.key]: filter.value }), {});

    return buildUrlWithPathAndQueryParams(route, { productId }, newPageFilters);
  };

  const { userEducations, fetchUserEducations } = useProfile();

  const { productDetails, productSchoolYears, fetchProductDetails, fetchProductSchoolYears, nonOwnedProductError } =
    useProduct();

  useEffect(() => {
    fetchUserEducations();
  }, []);

  // Fetch list of available school years for the selected product
  // only if the school selector filter feature is enabled
  useEffect(() => {
    if (enableSchoolYearFilter && productId) {
      fetchProductSchoolYears(+productId);
    }
  }, [enableSchoolYearFilter, productId]);

  useEffect(() => {
    if (productDetails?.id !== +productId) fetchProductDetails(+productId);
  }, [productDetails, productId]);

  const showEducationFilter = userEducations && userEducations.length > 0;

  // Should only show school year filter if that feature is enabled and there is at least
  // one school year available for the selected product
  const showSchoolYearFilter = !!enableSchoolYearFilter && productSchoolYears && productSchoolYears.length > 0;

  const currentEducationTitle = paginationFilters?.educationId
    ? userEducations?.find(d => d.id === paginationFilters.educationId)?.title
    : t('basicProgress.overview.filters.education.defaultOption');
  const currentYearsTitle = paginationFilters?.schoolYearStart
    ? `${new Date(paginationFilters.schoolYearStart).getFullYear()} / ${new Date(
        paginationFilters.schoolYearEnd ?? '',
      ).getFullYear()}`
    : t('basicProgress.overview.filters.schoolYear.defaultOption');

  return {
    page,
    productId,
    productDetails,
    nonOwnedProductError,
    showEducationFilter,
    showSchoolYearFilter,
    currentEducationTitle,
    currentYearsTitle,
    productSchoolYears,
    userEducations,
    paginationFilters,
    buildPaginatedURL,
  };
};
