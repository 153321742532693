import React from 'react';
import ReactDOM from 'react-dom';
import { AnimatePresence } from 'framer-motion';
import { StyledOverlay, StyledModal, StyledButton, StyledModalContainer, StyledModalBox } from './Modal.styled';
import { SvgIconReact } from '../SvgIconReact';
import { ModalProps, ModalRenderType } from './Modal.model';
import { backgroundAnimationVariants, modalAnimationVariants } from './Modal.animation';

const ConditionalWrap = ({
  condition,
  wrap,
  children,
}: {
  condition: boolean;
  wrap: (children: React.ReactNode) => React.ReactNode;
  children: React.ReactNode;
}) => <>{condition ? wrap(children) : children}</>;

/**
 * Component to display a modal or popup. The `useModal` hook will be used
 * to handle modals, to perform toggle operations and ensuring only one is
 * displayed at a time.
 *
 * @param {boolean} isOpen - Indicates if the modal is opened or closed.
 * @param {boolean} [closeOnClickOutside] - Indicates if the modal shall be
 *  automatically closed when the user clicks somewhere outside the modal. This
 *  setting will be disabled by default.
 * @param {object} [children] - JSX view with the content of the modal.
 * @param {string} [dataCy] - Name that will be assigned to the element in Cypress tests.
 * @param className- Name that will be assigned to the className for extending component with styled components.
 * @param onClose- Event FC when closing the modal component.
 * @param showCloseButton- Boolean flag to show or hide close modal icon button.
 */
export const Modal: React.FC<ModalProps> = ({
  closeOnClickOutside = false,
  children,
  dataCy,
  className,
  onClose,
  showCloseButton = true,
  isOpen = false,
  modalAnimation = 'fade',
  modalPosition = ['center', 'center'],
  wrapInModalBox = false,
  renderToElementType = ModalRenderType.INLINE,
}) => {
  const containerRef = React.useRef<HTMLDivElement>(null);

  const onContainerClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (closeOnClickOutside && onClose && containerRef.current && event.target === containerRef.current) {
      onClose && onClose();
    }
  };

  const modal = (
    <>
      <AnimatePresence exitBeforeEnter>
        {isOpen && (
          <StyledOverlay
            animate="to"
            data-cy="modal-overlay"
            exit="from"
            initial="from"
            transition={{
              type: 'tween',
            }}
            variants={backgroundAnimationVariants}
          />
        )}
      </AnimatePresence>
      <AnimatePresence exitBeforeEnter>
        {isOpen && (
          <StyledModalContainer
            ref={containerRef}
            $position={modalPosition}
            animate="to"
            data-cy="modal-container"
            exit="from"
            initial="from"
            transition={{
              type: 'tween',
            }}
            variants={modalAnimationVariants[modalAnimation]}
            onClick={onContainerClick}
          >
            <StyledModal className={className} data-cy={dataCy}>
              {showCloseButton && (
                <StyledButton dataCy="modal-close-x-button" onClick={onClose}>
                  <SvgIconReact iconName="closeX" />
                </StyledButton>
              )}
              <ConditionalWrap condition={wrapInModalBox} wrap={c => <StyledModalBox>{c}</StyledModalBox>}>
                {children}
              </ConditionalWrap>
            </StyledModal>
          </StyledModalContainer>
        )}
      </AnimatePresence>
    </>
  );

  return (
    <>{renderToElementType === ModalRenderType.REACT_PORTAL ? ReactDOM.createPortal(modal, document.body) : modal}</>
  );
};
