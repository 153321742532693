import styled from 'styled-components/macro';
import { ThemeProps } from 'models/styles/theme';

export const StyledInnerWrapper = styled.div<ThemeProps>`
  padding: 1rem 0;
  > div {
    margin-bottom: 3rem;
    > ul {
      list-style-type: none;
    }

    :last-child {
      margin-bottom: 0.5rem;
    }
  }
`;

export const StyledReviewDateWrapper = styled.div<ThemeProps>`
  display: flex;
  gap: 1rem;
  margin-bottom: 1.5rem;

  > div {
    > div {
      > input {
        height: 1.9rem;
        border: 1px solid ${({ theme }: ThemeProps) => theme.palette.neutral.black};
      }
    }
  }
`;
