import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';

import { TEST_ENVIRONMENT_ROUTES } from 'constants/routes';
import { getPassingScoreColorV2 } from '_helpers/colorProgressLevel';
import { buildUrlWithPathParams } from '_helpers/utils/urlBuilder';
import { ScheduledTestResultList } from 'models/exam/ScheduledTest';

import { LevelDash } from 'components/LevelDash';

import {
  StyledRow,
  StyledReviewPeriodNotice,
  StyledButton,
  StyledMessage,
  StyledCountdownTimer,
  StyledCollapsibleIcon,
  StyledScoreContainer,
} from './ScheduledTestResultRow.styled';

interface ScheduledTestResultRowProps {
  isOddRow?: boolean;
  reviewPeriodTimeLeft: number;
  scheduledTestOverview: ScheduledTestResultList;
}

export const ScheduledTestResultRow: React.FC<ScheduledTestResultRowProps> = ({
  isOddRow = false,
  reviewPeriodTimeLeft,
  scheduledTestOverview,
}) => {
  const { t } = useTranslation('results');
  const history = useHistory();
  const [collapsed, setCollapsed] = useState(true);

  const { code, exercises, hasPassed } = scheduledTestOverview;

  const startDate = new Date(scheduledTestOverview.startDate);
  const isReviewPeriodOpen = !!reviewPeriodTimeLeft;

  const avgScore = scheduledTestOverview.score || 0;
  return (
    <>
      <StyledRow $isOdd={isOddRow} data-cy="test-result-row">
        <td data-cy="test-start-date">{format(startDate, 'dd-MM-yyyy HH:mm')}</td>
        <td data-cy="test-skill-level">{scheduledTestOverview.skillLevel}</td>
        <td data-cy="test-title">{scheduledTestOverview.title}</td>
        <td data-cy="test-subjects">
          {collapsed ? (
            <b>{exercises.map(({ title }) => title).join(', ')}</b>
          ) : (
            <>
              <b>{t('testResultOverviewTable.averageScore', 'Average')}</b>
              {exercises.map(({ title }) => (
                <div key={`row-${title}`}>{title}</div>
              ))}
            </>
          )}
        </td>
        <td data-cy="test-scores">
          <StyledScoreContainer>
            <LevelDash level={getPassingScoreColorV2(avgScore, hasPassed).levelType} />
            <b data-cy="avg-test-score">{`${Math.round(avgScore)}%`}</b>
          </StyledScoreContainer>
          {!collapsed &&
            exercises.map(({ id, score, hasPassed: exericsePassed }) => (
              <StyledScoreContainer key={`row-${id}`} data-cy="test-exercise-score">
                <LevelDash level={getPassingScoreColorV2(score, exericsePassed).levelType} />
                {`${Math.round(score)}%`}
              </StyledScoreContainer>
            ))}
        </td>
        <td>
          <StyledCollapsibleIcon
            data-cy={`test-${collapsed ? 'expand' : 'collapse'}-button`}
            iconName={collapsed ? 'fas fa-chevron-down' : 'fas fa-chevron-up'}
            onClick={() => setCollapsed(!collapsed)}
          />
        </td>
      </StyledRow>
      {isReviewPeriodOpen && (
        <StyledRow $isOdd={isOddRow} data-cy="test-review-period-message" data-test-code={code}>
          <td colSpan={6}>
            <StyledReviewPeriodNotice>
              <StyledMessage>
                {t('testResultOverviewTable.reviewPeriodActive', 'Your test has been graded! View your results.')}
              </StyledMessage>
              <StyledCountdownTimer secondsLeft={reviewPeriodTimeLeft} />
              <StyledButton
                dataCy="view-detailed-test-results-button"
                variant="primary"
                onClick={() =>
                  history.push(buildUrlWithPathParams(TEST_ENVIRONMENT_ROUTES.TEST_RESULTS_PAGE, { code }))
                }
              >
                {t('button.viewResults', 'View results')}
              </StyledButton>
            </StyledReviewPeriodNotice>
          </td>
        </StyledRow>
      )}
    </>
  );
};
