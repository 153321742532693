import React from 'react';

import { GENERIC_ROUTES } from 'constants/routes';
import { modalIds } from 'constants/modals-constants';
import { useModal } from 'hooks/useModal';
import { useDomainHandler } from 'hooks/useDomainHandler';

import { Link } from 'components/Link';
import { SideMenuModal } from 'components/SideMenuModal/SideMenuModal';

import { HeaderProps } from './Header.model';

import { CssIcon, Typography } from 'styles/helpers/layout';
import { StyledHeader, StyledLogo, StyledExtraContent, StyledProfileButton } from './Header.styled';

export interface PathParams {
  productId?: string;
}

export const Header: React.FC<HeaderProps> = ({
  children,
  sideMenuEnabled = true,
  userDetails,
  toggleSideMenu,
  updateUserDetails,
  onLogout,
}) => {
  const { toggleModal, isModalOpen } = useModal();
  const { isBusinessDomain, getCustomLogoByDomain } = useDomainHandler();

  const onCloseSideMenu = () => {
    if (isModalOpen(modalIds.modalSideMenu)) {
      toggleModal(modalIds.modalSideMenu);
    }
  };

  const onMenuButtonClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    toggleSideMenu();
  };

  return (
    <StyledHeader>
      {!isBusinessDomain() && (
        <Link to={GENERIC_ROUTES.HOME_PAGE}>
          <StyledLogo dataCy="header-app-logo" iconName={getCustomLogoByDomain()} />
        </Link>
      )}
      <StyledExtraContent>{children}</StyledExtraContent>
      {sideMenuEnabled && (
        <>
          <StyledProfileButton alignItems="center" data-cy="open-side-menu-button" gap={1} onClick={onMenuButtonClick}>
            <Typography data-cy="account-header-name">{`${userDetails?.firstName} ${userDetails?.lastName}`}</Typography>
            <CssIcon iconName="far fa-angle-down" />
          </StyledProfileButton>
          <SideMenuModal
            userDetails={userDetails}
            onClose={onCloseSideMenu}
            onLogout={onLogout}
            onSubmit={updateUserDetails}
          />
        </>
      )}
    </StyledHeader>
  );
};
