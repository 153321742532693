import styled from 'styled-components/macro';
import { StyledButton } from 'components/Button/Button.styled';
import { ThemeProps } from 'models/styles/theme';

export const StyledCorrectTextQuestionOptionList = styled.div<ThemeProps>`
  display: flex;
  flex-direction: column;
  min-width: 300px;
  z-index: 1002;

  padding: 8px;
  border: 1px solid ${(props: ThemeProps) => props.theme.palette.neutral._400};
  border-radius: ${(props: ThemeProps) => props.theme.borderRadius.rounded};
  background: ${(props: ThemeProps) => props.theme.palette.neutral.white};

  > ${StyledButton} {
    color: ${({ theme }: ThemeProps) => theme.palette.neutral.black};
    border: none;
    display: flex;
    padding: 0.6875rem;
    justify-content: start;
    align-items: center;

    > span {
      font-size: 16px;
    }

    img {
      // svg image icon
      width: 14px;
      height: 14px;
      margin-right: 0.6875rem;
      margin-bottom: 3px;
    }
  }
`;
