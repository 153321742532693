import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useModal } from 'hooks/useModal';
import { QuestionManager } from 'layouts/QuestionManager';
import { QuestionFlag, QuestionType, TestType } from 'constants/exam-constants';
import { modalIds } from 'constants/modals-constants';
import { StyledButtonWrapper, StyledButton, StyledExercise } from './AdaptiveTestContent.styled';
import { TestContentProps } from './AdaptiveTestContent.model';
import { CorrectTextQuestionMessageModal } from '../CorrectTextQuestionMessageModal/CorrectTextQuestionMessageModal';
import { useAdaptivePractice } from 'hooks/useAdaptivePractice';
import { LoadingSpinner } from 'components/LoadingSpinner';

export const AdaptiveTestContent: React.FC<TestContentProps> = observer(
  ({ showContinueButton, setShowContinueButton, evaluateAnswer, onSelectedQuestionChanged }) => {
    const { toggleModal } = useModal();
    const { t } = useTranslation('skill-determination-test');
    const { loading, adaptiveTestDetails, selectedQuestion, setSubjectCompleted, setFailedToLoadQuestion } =
      useAdaptivePractice();

    const { index, results } = selectedQuestion;

    const examQuestions = adaptiveTestDetails?.questions ? adaptiveTestDetails.questions : [];
    const selectedQuestionDetails = examQuestions[index];

    useEffect(() => {
      return () => {
        setFailedToLoadQuestion(false);
        setSubjectCompleted(false);
      };
    }, []);

    useEffect(() => {
      if (results && results.length > 0) {
        setShowContinueButton(true);
      }
    }, [JSON.stringify(results)]);

    useEffect(() => {
      onSelectedQuestionChanged(selectedQuestionDetails);
    }, [selectedQuestion]);

    const [enabledQuestionFlags, setEnabledQuestionFlags] = useState<readonly QuestionFlag[]>([]);
    const toggleQuestionFlag = useCallback(
      (flag: QuestionFlag) => {
        if (enabledQuestionFlags.includes(flag)) {
          setEnabledQuestionFlags(enabledQuestionFlags.filter(f => f !== flag));
        } else {
          setEnabledQuestionFlags(enabledQuestionFlags.concat(flag));
        }
      },
      [enabledQuestionFlags],
    );

    useEffect(() => {
      if (selectedQuestionDetails?.type === QuestionType.CORRECT_TEXT) {
        toggleModal(modalIds.correctTextQuestionModal);
      }
    }, [selectedQuestionDetails?.type]);

    if (!examQuestions) {
      return null;
    }

    if (loading) {
      return <LoadingSpinner isFullScreen />;
    }

    return (
      <StyledExercise>
        {selectedQuestion && (
          <QuestionManager
            evaluateAnswer={(productId, questionId, answers, _, skipAnswerValidation) =>
              evaluateAnswer(productId, questionId, answers, skipAnswerValidation)
            }
            flags={enabledQuestionFlags}
            question={selectedQuestionDetails}
            showTheoryButton={adaptiveTestDetails?.type !== TestType.DIAGNOSTIC_TEST}
            showTip={false}
          />
        )}
        {/* Display button to compare answers when there's feedback available for correct-text question */}
        {showContinueButton && selectedQuestionDetails?.type === QuestionType.CORRECT_TEXT && (
          <StyledButtonWrapper>
            <StyledButton
              key="compare-answerbutton"
              dataCy="compare-answer-button"
              style={{ marginBottom: '2rem' }}
              variant="secondary"
              onClick={() => toggleQuestionFlag(QuestionFlag.CORRECT_TEXT_COMPARE_MODE)}
            >
              {t('button.compareAnswer.label')}
            </StyledButton>
          </StyledButtonWrapper>
        )}
        <CorrectTextQuestionMessageModal />
      </StyledExercise>
    );
  },
);
