import { Select } from 'components/SelectUI/Select';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { ProductSkillLevel } from 'models/product/ProductDetails';
import { Box, Typography } from 'styles/helpers/layout';
import { useTranslation } from 'react-i18next';

interface GoalLevelSelectorProps {
  selectableGoalLevels?: ProductSkillLevel[];
  onChange: (targetLevelIndex: number) => void;
  targetLevel?: number;
}

export const GoalLevelSelector: FC<GoalLevelSelectorProps> = ({ selectableGoalLevels = [], onChange, targetLevel }) => {
  const [selectedOption, setSelectedOption] = useState<string | number>(0);
  const { t } = useTranslation('teacher-dashboard');

  const goalsOptions = useMemo(
    () =>
      selectableGoalLevels.map(({ label, level }) => ({
        value: level,
        label: label,
      })),
    [selectableGoalLevels],
  );

  const handleOnChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);
    onChange(+selectedValue);
  };

  useEffect(() => {
    if (goalsOptions.length > 0) {
      setSelectedOption(targetLevel || goalsOptions[0].value);
    }
  }, [targetLevel, goalsOptions]);

  return (
    <Box>
      <Typography fontSize="0.75rem">{t('selectors.goalLevelSelectorLabel')}</Typography>
      <Select
        dataCy="goal-level-selector"
        options={goalsOptions}
        showPlaceholderInOptionList={false}
        value={selectedOption}
        onChange={handleOnChange}
      />
    </Box>
  );
};
