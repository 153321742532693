import { Level } from 'models/product/ProductDetails';

interface ColorProgressLevel {
  index: number;
  levelType: Level;
}

const setLevetType = (index: number, level: Level): ColorProgressLevel => ({
  index,
  levelType: level,
});

export const colorProgressLevel = (progressLevel = 0): ColorProgressLevel => {
  if (progressLevel === null) return setLevetType(0, 'default');
  if (progressLevel < 35) return setLevetType(1, 'basics');
  if (progressLevel >= 35 && progressLevel < 55) return setLevetType(2, 'initial');
  if (progressLevel >= 55 && progressLevel < 74) return setLevetType(3, 'improvement');
  if (progressLevel >= 74 && progressLevel < 90) return setLevetType(4, 'good');
  if (progressLevel >= 90) return setLevetType(5, 'excellent');

  return setLevetType(5, 'default');
};

// Return a level status type depending on the difference between the current and goal levels
export const getGoalLevelProximityStatus = (currentLevel?: number, goalLevel?: number): Level => {
  if (!currentLevel || !goalLevel) return 'default';

  if (currentLevel > goalLevel) return 'excellent';
  if (currentLevel === goalLevel) return 'good';

  const levelsToReachGoal = goalLevel - currentLevel;
  if (levelsToReachGoal === 1) return 'improvement';
  if (levelsToReachGoal === 2) return 'initial';

  return 'basics';
};

// TODO: #192531 move the requiredPassingScore logic to backend
export const getPassingScoreColor = (
  obtainedScore: number | null = 0,
  requiredPassingScore: number,
): ColorProgressLevel => {
  if (obtainedScore === null) return setLevetType(0, 'default');
  if (Math.round(obtainedScore) >= requiredPassingScore) return setLevetType(4, 'good');
  return setLevetType(1, 'basics');
};

export const getPassingScoreColorV2 = (obtainedScore: number, hasPassed: boolean): ColorProgressLevel => {
  if (obtainedScore === null) return setLevetType(0, 'default');
  return hasPassed ? setLevetType(4, 'good') : setLevetType(1, 'basics');
};
