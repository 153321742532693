import React, { useCallback, useState } from 'react';
import classnames from 'classnames';

import { useReactTooltip } from 'hooks/useReactTooltip';
import { useOnClickOutside } from 'hooks/useOnClickOutside';

import { CssIcon } from 'styles/helpers/layout';

import {
  StyledDropdown,
  StyledButton,
  StyledTitle,
  StyledHeader,
  StyledContentList,
  StyledListItem,
} from './Dropdown.styled';
import { DropdownProps, Option } from './Dropdown.model';

export const Dropdown: React.FC<DropdownProps> = ({
  className,
  dataCy,
  dataCyTitle,
  defaultTitle,
  disabled = false,
  expandedByDefault = false,
  options,
  selectedId,
  onChange,
  width = 360,
  tooltip,
}) => {
  useReactTooltip();
  const elRef = React.useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(expandedByDefault);
  const selectOption = (option: Option) => {
    setIsOpen(!isOpen);
    onChange(option.id);
  };
  const selectedOption = selectedId ? options.find(option => option.id === selectedId) : null;
  const headerTitle =
    selectedOption && typeof selectedOption.title === 'string'
      ? selectedOption.title[0].toUpperCase() + selectedOption.title.slice(1)
      : defaultTitle;

  useOnClickOutside({
    ref: elRef,
    handler: useCallback(() => setIsOpen(false), []),
  });

  return (
    <StyledDropdown ref={elRef} className={className} data-cy={dataCy} data-tip={tooltip} isOpen={isOpen}>
      <StyledHeader
        alignItems="center"
        className={classnames('dropdown-header', disabled && 'disabled')}
        columnGap={0.5}
        isOpen={isOpen}
        justifyContent="space-between"
        role="button"
        tabIndex={0}
        onClick={() => !disabled && setIsOpen(!isOpen)}
        onKeyPress={() => !disabled && setIsOpen(!isOpen)}
      >
        <StyledTitle className="dropdown-title" data-cy={dataCyTitle}>
          {headerTitle}
        </StyledTitle>
        {!disabled && <CssIcon iconName={`fas fa-caret-${!isOpen ? 'down' : 'up'}`} />}
      </StyledHeader>
      <StyledContentList className="dropdown-list" isOpen={isOpen} width={width}>
        {options.map(option => (
          <StyledListItem key={option.id} disabled={option.disabled} selected={option.id === selectedId} width={width}>
            <StyledButton
              dataCy="dropdown-button"
              disabled={option.disabled}
              fontColor="black"
              selected={option.id === selectedId}
              size="medium"
              titleView={!!option.titleView}
              variant="default"
              onClick={() => selectOption(option)}
            >
              {option.titleView || option.title}
            </StyledButton>
          </StyledListItem>
        ))}
      </StyledContentList>
    </StyledDropdown>
  );
};
