/* eslint-disable @typescript-eslint/no-unsafe-call */
import { BreadcrumbsRoute } from 'components/Breadcrumbs/Breadcrumbs.model';
import { SkeletonLoader } from 'components/SkeletonLoader/SkeletonLoader';
import { TEACHER_INSIGHT_PAGE_ROUTES } from 'constants/routes';
import { SkillsSelectorType } from 'constants/teacher-constants';
import { useWriting } from 'hooks/useWriting';
import { useDomainHandler } from 'hooks/useDomainHandler';
import { observer } from 'mobx-react';
import { TopicProgressType } from 'models/progress/Progress';
import { ForAccountHeading } from 'pages/TeacherEnvironment/TeacherDashboard/components/ForAccountHeading/ForAccountHeading';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { BasicProgressForAccountPagePathParams } from '../../../BasicProgressForAccount/BasicProgressForAccount.model';
import { StyledStatusTag, StyledTable } from './WritingResultsForAccount.styled';
import { Card } from 'components/Card/Card';

export const WritingResultsForAccount: FC = observer(() => {
  const { t } = useTranslation('teacher-dashboard');
  const { productId, accountId }: BasicProgressForAccountPagePathParams = useParams();
  const {
    writingResultsForAccount,
    writingSubjectsResultsOverview,
    fetchWritingResultsForAccount,
    fetchWritingSubjectsResultsOverview,
  } = useWriting();
  const { getStudentTermByDomain } = useDomainHandler();

  useEffect(() => {
    if (!writingSubjectsResultsOverview) fetchWritingSubjectsResultsOverview(+productId, {});
  }, [productId, writingSubjectsResultsOverview]);

  useEffect(() => {
    fetchWritingResultsForAccount(+productId, +accountId);
  }, [productId, accountId]);

  if (!writingResultsForAccount || !writingSubjectsResultsOverview) return <SkeletonLoader $marginTop={2} count={8} />;

  const { firstName, lastName, middleName, email, studentNumber, subjectDetails } = writingResultsForAccount;

  const fullName = middleName ? `${firstName} ${middleName} ${lastName}` : `${firstName} ${lastName}`;

  const breadCrumbRoutes: BreadcrumbsRoute[] = [
    {
      path: TEACHER_INSIGHT_PAGE_ROUTES.WRITING_SKILLS,
      breadcrumb: t('writingResults.title'),
    },
    {
      path: TEACHER_INSIGHT_PAGE_ROUTES.WRITING_RESULTS_FOR_ACCOUNT,
      breadcrumb: fullName,
    },
  ];

  const getSubjectStatusType = (subjectStatus: string): TopicProgressType => {
    switch (subjectStatus) {
      case 'completed':
        return 'completed';
      case 'notStarted':
        return 'not-started';
      default:
        return 'in-progress';
    }
  };

  const getSubjectProgressText = (subjectStatus: string): string => {
    const topicProgressType = getSubjectStatusType(subjectStatus);
    switch (topicProgressType) {
      case 'not-started':
        return t('basicProgress.forWritingAccount.progressType.notStarted');
      case 'in-progress':
        return t('basicProgress.forWritingAccount.progressType.inProgress');
      default:
        return t('basicProgress.forWritingAccount.progressType.completed');
    }
  };

  return (
    <ForAccountHeading
      breadCrumbRoutes={breadCrumbRoutes}
      email={email}
      fullName={fullName}
      skillType={SkillsSelectorType.WRITING_SKILLS}
      studentIds={writingSubjectsResultsOverview.studentIds}
      studentNumber={studentNumber ?? ''}
    >
      <Card layout={{ mt: '2' }}>
        <StyledTable>
          <thead>
            <tr>
              <th>{t('basicProgress.forWritingAccount.topic', 'Topic')}</th>
              <th>{t('basicProgress.forWritingAccount.progress', 'Progress')}</th>
              <th>{t('basicProgress.forWritingAccount.completedTopics', 'Completed topics')}</th>
              <th>{t('basicProgress.forWritingAccount.completedExercises', 'Completed exercises')}</th>
              <th>{t('basicProgress.forWritingAccount.completedCases', 'Completed cases')}</th>
            </tr>
          </thead>
          <tbody>
            {subjectDetails.length === 0 && (
              <tr>
                <td colSpan={3} data-cy="no-writing-exercises-linked">
                  {t('writingResults.noResultsFound', { studentTerm: getStudentTermByDomain() })}
                </td>
              </tr>
            )}
            {subjectDetails.map(
              ({
                subjectId,
                subjectTitle,
                totalExercises,
                completedExercises,
                totalCases,
                completedCases,
                totalTopics,
                completedTopics,
                subjectStatus,
              }) => (
                <tr key={subjectId} data-cy="student-writing-subject-progress-row">
                  <td data-cy={`subject-${subjectId}-title`}>{subjectTitle}</td>
                  <td data-cy={`subject-${subjectId}-status`}>
                    <StyledStatusTag
                      label={getSubjectProgressText(subjectStatus)}
                      statusType={getSubjectStatusType(subjectStatus)}
                    />
                  </td>
                  <td data-cy={`subject-${subjectId}-topic-stats`}>{`${completedTopics}/${totalTopics}`}</td>
                  <td data-cy={`subject-${subjectId}-exercise-stats`}>{`${completedExercises}/${totalExercises}`}</td>
                  <td data-cy={`subject-${subjectId}-assignment-stats`}>{`${completedCases}/${totalCases}`}</td>
                </tr>
              ),
            )}
          </tbody>
        </StyledTable>
      </Card>
    </ForAccountHeading>
  );
});
