import { SortArrows } from 'components/SortArrows/SortArrows';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SortableTableProps, TableRow } from './SortableTable.model';
import {
  StyledCell,
  StyledHeaderCell,
  StyledNoResultsMessage,
  StyledTable,
  StyledTableRow,
} from './SortableTable.styled';
import { useHistory } from 'react-router-dom';

interface ActiveSort {
  index: number;
  direction: 'asc' | 'desc';
}

export const SortableTable: React.FC<SortableTableProps> = ({
  headerCells,
  className,
  rows,
  noResultsMessage,
  flexStartCellHeaders = false,
}) => {
  const { t } = useTranslation('common');
  const history = useHistory();
  const [activeSort, setActiveSort] = useState<ActiveSort | null>(null);
  const sortedRows = useMemo(() => {
    if (activeSort === null || rows.length <= 1) {
      return rows;
    }
    let sorted = [...rows];
    const activeHeaderCell = headerCells[activeSort.index];
    const { sortFn } = activeHeaderCell;
    if (sortFn) {
      sorted = rows
        .map((item, index) => [index, item])
        .sort(([aIndex, a], [bIndex, b]) => {
          const cellA = (a as TableRow).cells[activeSort.index];
          const cellB = (b as TableRow).cells[activeSort.index];
          return sortFn(
            {
              cell: cellA,
              index: aIndex as number,
            },
            {
              cell: cellB,
              index: bIndex as number,
            },
          );
        })
        .map(([, item]) => item) as TableRow[];
    } else {
      sorted = rows.sort((a, b) => {
        const cellA = a.cells[activeSort.index];
        const cellB = b.cells[activeSort.index];
        const aValue = cellA.sortValue ?? cellA.content;
        const bValue = cellB.sortValue ?? cellB.content;

        if (typeof aValue === 'number' && typeof bValue === 'number') {
          return aValue - bValue;
        }
        if (typeof aValue === 'boolean' && typeof bValue === 'boolean') {
          return Number(aValue) - Number(bValue);
        }
        if (typeof aValue === 'string' && typeof bValue === 'string') {
          return aValue.localeCompare(bValue);
        }
        // is a custom JSX element that cannot be sorted automatically, should use a custom sort function
        return 0;
      });
    }
    return activeSort.direction === 'desc' ? sorted.reverse() : sorted;
  }, [rows, activeSort]);

  const onSortToggle = (index: number) => {
    if (activeSort === null || activeSort.index !== index) {
      setActiveSort({ index, direction: 'asc' });
    } else if (activeSort.index === index) {
      setActiveSort({ index, direction: activeSort.direction === 'asc' ? 'desc' : 'asc' });
    }
  };

  return (
    <StyledTable $gridSizes={headerCells.map(({ size }) => size || '1fr')} className={className}>
      <StyledTableRow>
        {headerCells.map((cell, index) => (
          <StyledHeaderCell
            key={cell.id}
            $flexStartCellHeaders={flexStartCellHeaders}
            className={`header-cell-${cell.id}`}
          >
            {cell.sortable ? (
              <SortArrows
                activeDirection={activeSort?.index === index ? activeSort.direction : 'none'}
                onClick={() => onSortToggle(index)}
              >
                <b>{cell.title}</b>
              </SortArrows>
            ) : (
              <b>{cell.title}</b>
            )}
            {cell.content ? cell.content : null}
          </StyledHeaderCell>
        ))}
      </StyledTableRow>
      {sortedRows.length === 0 && (
        <StyledNoResultsMessage $cellCount={headerCells.length} data-cy="sortable-table-no-results-message">
          {noResultsMessage ?? t('noResults.message', 'No results')}
        </StyledNoResultsMessage>
      )}
      {sortedRows.map((row, index) => (
        <StyledTableRow
          key={row.id}
          $highlightColor={row.highlightColor}
          $link={row.link}
          onClick={() => {
            if (row.link) {
              history.push(row.link);
            }
          }}
        >
          {row.cells.map(cell => (
            <StyledCell
              key={cell.id}
              $centerContent={!!cell.centerContent}
              $rowIndex={index}
              data-cy={cell.dataCy}
              style={{ backgroundColor: cell.backgroundColor }}
            >
              {cell.content}
            </StyledCell>
          ))}
        </StyledTableRow>
      ))}
    </StyledTable>
  );
};
