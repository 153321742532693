import i18n from 'i18n';
import { useEffect, useState } from 'react';
import queryString from 'query-string';
import { useHistory } from 'react-router-dom';

import { ROLES } from '@he-learning/service-he-common';
import { productsListRepository, authRepository } from 'repositories';

import { setAccess } from 'persistence/access';
import { Login } from 'models/auth/Login';
import { ToastMethods } from 'components/ToastNotification';
import {
  GENERIC_ROUTES,
  AUTH_PAGES_ROUTES,
  PRODUCT_PAGES_ROUTES,
  TEACHER_HOME_PAGE_ROUTES,
  ADMIN_ENVIRONMENT_ROUTES,
} from 'constants/routes';
import { buildUrlWithPathParams } from '_helpers/utils/urlBuilder';

import { useProfile } from 'hooks/useProfile';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import useMigrationStatus from './useMigrationStatus';
import { USER_TYPE_PER_ROLE } from 'analytics/constants/userTypes';
import { trackIdentifyEvent } from 'analytics/MetabaseTracker/metabase-tracker';
import { useSelectedProductId } from 'hooks/useSelectedProductId';

export default (): void => {
  const [loggedUser, setLoggedUser] = useState<Login | null>(null);
  const [emailAddress, setEmailAddress] = useState<string>();

  const history = useHistory();
  const { refreshFeatureFlagValues } = useFeatureFlags();

  useMigrationStatus(emailAddress);

  const { isRegistrationComplete, setIsRegistrationComplete } = useProfile();

  const lastSelectedProductId = useSelectedProductId();

  // Redirect user to application depending on their role and situation
  /* eslint consistent-return: off */
  const redirect = (user: Login): void => {
    const { role, schoolNumber } = user;

    if (!schoolNumber && role === ROLES.STUDENT) {
      return history.replace(AUTH_PAGES_ROUTES.USER_MISSING_INFO);
    }

    // If user was coming from a specific url, go back to that page
    const { state } = queryString.parse(window.location.search);

    if (state) {
      return history.replace(state as string);
    }

    // Redirect to main page depending on user role
    if (role === ROLES.TEACHER) {
      return history.replace(TEACHER_HOME_PAGE_ROUTES.HOME_PAGE);
    }

    if (role === ROLES.ADMIN) {
      return history.replace(ADMIN_ENVIRONMENT_ROUTES.USERS_PAGE);
    }

    productsListRepository
      .getProductList()
      .then(productsList => {
        const lastSelectedProduct = productsList?.find(({ id: productId }) => productId === lastSelectedProductId);

        if (lastSelectedProduct) {
          history.replace(
            buildUrlWithPathParams(PRODUCT_PAGES_ROUTES.PRODUCT_DETAILS, { productId: +lastSelectedProduct.id }),
          );
        } else {
          history.replace(
            buildUrlWithPathParams(PRODUCT_PAGES_ROUTES.PRODUCT_DETAILS, { productId: productsList[0].id }),
          );
        }
      })
      .catch(() => {
        history.replace(GENERIC_ROUTES.HOME_PAGE);
      });
  };

  // Call "validate" endpoint to perform login
  useEffect(() => {
    setLoggedUser(null);
    setIsRegistrationComplete(null);
    const { code } = queryString.parse(window.location.search);
    authRepository
      .validateLoginUser(code as string)
      .then((response: Login) => {
        const { id: userId, jwt, role, refreshToken, email } = response;

        setEmailAddress(email);

        // Store current user data in cookies
        setAccess({ jwt, refreshToken });

        // Determine user sync is still in progress
        setIsRegistrationComplete(false);

        // Keep track of successful login in Metabase
        trackIdentifyEvent({ id: userId.toString(), type: USER_TYPE_PER_ROLE[role] });

        // Store logged user data in state to redirect when needed
        setLoggedUser(response);
      })
      .catch(() => {
        ToastMethods.showToast(i18n.t('toast:user.validate.error'), 'error');
      });
  }, [history]);

  // Redirect user only if registration has been completed
  useEffect(() => {
    if (loggedUser && isRegistrationComplete) {
      redirect(loggedUser);

      // Force refresh of feature flags after successful login
      refreshFeatureFlagValues();
    }
  }, [loggedUser, isRegistrationComplete]);
};
