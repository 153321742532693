import React from 'react';

import { SvgIconReact } from 'components/SvgIconReact';
import { OptionProps } from 'components/Select/Select.model';

import { StyledEducationItem, StyledSelect, StyledButton } from './EducationItem.styled';

interface EducationItemProps {
  disabled: boolean;
  educationOptions: OptionProps[];
  education: string;
  onDeleteEducation: () => void;
  onUpdateEducation: (educationId: string) => void;
  hasError?: boolean;
  errorMessage?: string;
}

export const EducationItem: React.FC<EducationItemProps> = ({
  disabled,
  education,
  hasError,
  errorMessage,
  educationOptions,
  onDeleteEducation,
  onUpdateEducation,
}) => (
  <StyledEducationItem>
    <StyledSelect
      ariaLabel="account-education"
      dataCy="account-education"
      disabled={disabled}
      errorMessage={errorMessage}
      hasError={!!hasError}
      options={educationOptions}
      value={education}
      onChange={event => onUpdateEducation(event.target.value)}
    />
    <StyledButton
      variant="default"
      onClick={event => {
        event.preventDefault();
        onDeleteEducation();
      }}
    >
      <SvgIconReact iconName="trashIcon" />
    </StyledButton>
  </StyledEducationItem>
);
