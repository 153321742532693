import styled from 'styled-components/macro';
import { ElearningThemeInterface } from 'themes/elearningInterface.theme';

import SvgIconReactProps from './SvgIconReact.model';
import { IconComponent } from './IconComponent';

interface Props extends SvgIconReactProps {
  theme: ElearningThemeInterface;
}

export const StyledSvgIconReact = styled(IconComponent)<Props>`
  height: ${(props: Props) => props.size}rem;
  width: ${(props: Props) => props.size}rem;
`;
