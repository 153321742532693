import { ElearningThemeInterface } from 'themes/elearningInterface.theme';

export const mapProgressToColor = (progress: number, hogesTheme: ElearningThemeInterface): string => {
  if (progress < 25) {
    return hogesTheme.palette.feedback.error.brand;
  }

  if (progress < 50) {
    return hogesTheme.palette.accent._05;
  }

  if (progress < 75) {
    return hogesTheme.palette.feedback.success.brand;
  }

  return hogesTheme.palette.accent._04;
};
