import React, { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useLanguage } from 'hooks/useSelectedLanguage';
import { useProduct } from 'hooks/useProduct';
import { useWriting } from 'hooks/useWriting';
import { useFeatureFlags } from 'hooks/useFeatureFlags';

import { PRODUCT_PAGES_ROUTES } from 'constants/routes';
import { buildUrlWithPathParams } from '_helpers/utils/urlBuilder';

import { BackButton } from 'components/BackButton';
import { SanitizedHtml } from 'components/SanitizedHtml';
import { TheoryRenderer } from 'components/TheoryRenderer';
import { EmbeddedHtml } from 'components/EmbeddedHtml';
import { contentSanitizeOptions } from 'components/TheoryRenderer/TheoryRenderer.constants';

import { Heading2 } from 'styles/elements/Headings';
import { CssFlex, CssIcon, Box } from 'styles/helpers/layout';
import { StyledHeader, StyledSummary, StyledSummaryTitle, StyledSummarySubtitle } from './TopicDetailsPage.styled';

import { TopicDetailsPagePathParams as PathParams } from './TopicDetailsPage.model';
import { ExerciseSelector, TopicNavigation, TopicDetailsBreadcrumb } from './components';
import { LegacyExerciseSelector } from './components/ExerciseSelector/LegacyExerciseSelector';

export const TopicDetailsPage: React.FC = observer(() => {
  const { t } = useTranslation('writing-module');
  const { productId, subjectId, moduleId, topicId }: PathParams = useParams();

  const { topicDetails: topic, fetchTopicDetails } = useProduct();
  const { fetchTopicExerciseStatus } = useWriting();
  const { currentLanguage } = useLanguage();
  const { showLegacyExerciseDropDownSelector } = useFeatureFlags();

  const backButtonRedirectURL = useMemo(
    () =>
      buildUrlWithPathParams(PRODUCT_PAGES_ROUTES.PRODUCT_WRITING_MODULE_DETAILS, {
        productId,
        moduleId,
        subjectId,
      }),
    [productId, moduleId, subjectId],
  );

  useEffect(() => {
    fetchTopicDetails(+productId, +topicId);
    fetchTopicExerciseStatus(+productId, +topicId);
  }, [productId, topicId, currentLanguage]);

  const totalQuestions = topic?.exercises?.reduce((total, { questionCount }) => total + questionCount, 0);

  return !topic ? null : (
    <Box>
      <BackButton
        dataCy="topic-back-btn"
        label={t('topicDetails.button.back.label', 'Back')}
        redirectUrl={backButtonRedirectURL}
      />

      <TopicDetailsBreadcrumb />

      <StyledHeader>
        <Heading2 data-cy="topic-title" marginBottom="4rem">
          <SanitizedHtml dirtyHtml={topic.title || ''} />
        </Heading2>
        {showLegacyExerciseDropDownSelector && <LegacyExerciseSelector />}
      </StyledHeader>

      {/* Summary of the topic including duration and learning goals */}
      <StyledSummary flexDirection="column">
        <StyledSummaryTitle>{t('topicDetails.summaryBox.title')}</StyledSummaryTitle>
        <CssFlex alignItems="center" flexDirection="row" gap={1.5}>
          {!!topic.estimatedDuration && (
            <>
              <CssFlex alignItems="center" flexDirection="row" gap={0.5}>
                <CssIcon fixedWidth iconName="fas fa-clock" />
                <span>{t('topicDetails.summaryBox.estimatedDuration')}</span>
                <b data-cy="topic-estimated-duration">
                  {t('topicDetails.summaryBox.minuteCount', { count: topic.estimatedDuration })}
                </b>
              </CssFlex>
              <span>|</span>
            </>
          )}
          <CssFlex alignItems="center" flexDirection="row" gap={0.5}>
            <CssIcon fixedWidth iconName="fas fa-tasks" />
            <span>{t('topicDetails.summaryBox.numberOfQuestions')}</span>
            <b data-cy="topic-question-count">
              {t('topicDetails.summaryBox.questionCount', { count: totalQuestions || 0 })}
            </b>
          </CssFlex>
        </CssFlex>
        {topic.learningGoals && (
          <section data-cy="topic-learning-goals">
            <StyledSummarySubtitle>{t('topicDetails.summaryBox.learningGoals')}</StyledSummarySubtitle>
            <EmbeddedHtml rawHtml={topic.learningGoals} sanitizeOptions={contentSanitizeOptions} />
          </section>
        )}
      </StyledSummary>

      {/* Detailed topic information */}
      <TheoryRenderer content={topic.content} productId={topic.product.id} />
      {!showLegacyExerciseDropDownSelector && <ExerciseSelector topic={topic} />}

      <TopicNavigation />
    </Box>
  );
});
