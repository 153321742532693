import styled, { css } from 'styled-components/macro';
import ReactDatePicker from 'react-date-picker';
import { ElearningThemeInterface } from 'themes/elearningInterface.theme';
import { errorInputCss } from 'styles/helpers/errors';
import { CalendarPosition } from './DatePicker.model';

interface Props {
  theme: ElearningThemeInterface;
  $hasError?: boolean;
  $calendarPosition?: CalendarPosition;
}

interface RightIconProps {
  $isOpen: boolean;
}

const paddingHeight = '0.75rem';
const inputLineHeight = '1.4rem';

const getTriangleRotationCss = (isOpen: boolean) =>
  !isOpen
    ? css`
        transform: rotate(0);
        margin-top: 2px;
      `
    : css`
        transform: rotate(180deg);
        margin-top: 2px;
      `;

const getCalendarPosition = (position: CalendarPosition = CalendarPosition.BOTTOM_LEFT) => {
  if (position === CalendarPosition.BOTTOM_RIGHT) {
    return css`
      right: 0 !important;
      left: unset !important;
    `;
  }

  return css``;
};

export const StyledLeftIcon = styled.div`
  color: ${({ theme }: Props) => theme.palette.neutral.black};
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${paddingHeight};
  margin-top: 0.85rem;
  width: ${inputLineHeight};
  height: ${inputLineHeight};
`;

export const StyledRightIcon = styled.div<RightIconProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }: Props) => theme.palette.neutral.black};
  position: absolute;
  top: ${paddingHeight};
  right: ${paddingHeight};
  width: ${inputLineHeight};
  height: ${inputLineHeight};
  pointer-events: none;
  ${({ $isOpen }) => getTriangleRotationCss($isOpen)};
`;

export const StyledReactDatePicker = styled(ReactDatePicker)<Props>`
  width: 100%;
  font-family: ${({ theme }: Props) => theme.font.family.main};
  .react-calendar__month-view__weekdays {
    font-family: ${({ theme }: Props) => theme.font.family.main};
    font-size: 1rem;
    color: #208ccb;
    text-transform: none;
    abbr[title] {
      text-decoration: none;
    }
  }
  .react-calendar__month-view__days__day--weekend {
    color: inherit;
  }
  .react-date-picker__wrapper {
    width: 100%;
    border: 1px solid ${({ theme }: Props) => theme.palette.neutral._600};
    ${({ $hasError }) => $hasError && errorInputCss};
  }
  .react-date-picker__inputGroup {
    width: 100%;
    cursor: pointer;
    white-space: nowrap;
    padding: ${paddingHeight} 2.7rem;
  }
  .react-calendar__tile:disabled,
  .react-calendar__navigation button[disabled] {
    background-color: ${({ theme }: Props) => theme.palette.neutral._200};
  }
  .react-calendar__tile--now {
    background-color: transparent;
    border: 1px solid black;
  }
  .react-calendar__tile--now:enabled:hover,
  .react-calendar__tile--now:enabled:focus {
    background-color: inherit;
  }
  .react-calendar__tile--active,
  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    background-color: #208ccb;
    color: ${({ theme }: Props) => theme.palette.neutral.white};
  }
  .react-calendar__navigation__arrow {
    &.react-calendar__navigation__next2-button,
    &.react-calendar__navigation__prev2-button {
      display: none;
    }
  }
  .react-calendar__navigation button[disabled] {
    cursor: not-allowed;
  }
  .react-date-picker__calendar {
    // These !important are necessary to set the calendar below the date picker component
    top: 100% !important;
    bottom: unset !important;
    z-index: 1002;

    ${({ $calendarPosition }: Props) => getCalendarPosition($calendarPosition)}
  }
  input {
    color: ${({ theme }: Props) => theme.palette.neutral.black}!important;
    line-height: ${inputLineHeight};
  }
`;

export const StyledDatePicker = styled.div`
  color: ${({ theme }: Props) => theme.palette.neutral.black};
  position: relative;
`;
