import styled from 'styled-components/macro';
import { ThemeProps as Props } from 'models/styles/theme';
import { Box } from 'styles/helpers/layout';

export interface StyledSnackBarProps extends Props {
  backColor: string;
  color: string;
}

export const StyledSnackBar = styled(Box)<StyledSnackBarProps>`
  background: ${({ backColor }: StyledSnackBarProps) => backColor};
  border: 1px solid ${({ color }: StyledSnackBarProps) => color};
  border-radius: 0.5rem;

  > div {
    > div {
      color: ${({ color }: StyledSnackBarProps) => color};

      :last-child {
        color: ${({ theme }: StyledSnackBarProps) => theme.palette.neutral.black};
      }
    }
  }
`;
