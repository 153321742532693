import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import { modalIds } from 'constants/modals-constants';
import { PRODUCT_PAGES_ROUTES } from 'constants/routes';

import { useModal } from 'hooks/useModal';
import { useProfile } from 'hooks/useProfile';
import { useProduct } from 'hooks/useProduct';
import { useSelectedProductId } from 'hooks/useSelectedProductId';
import useRequest from 'hooks/user/useRequest';

import { Footer } from 'components/Footer';

import { TeacherHeader } from '../Header/TeacherHeader';
import { StyledContent } from './TeacherPageLayout.styled';
import { TeacherNavigationMenu } from '../NavigationMenu/TeacherNavigationMenu';
import { CssGrid } from 'styles/helpers/layout';
import { buildUrlWithPathParams } from '_helpers/utils/urlBuilder';

interface TeacherPageLayoutProps {
  children: React.ReactNode;
  className?: string;
  withMenu?: boolean;
}

export const TeacherPageLayout: React.FC<TeacherPageLayoutProps> = observer(({ children, className, withMenu }) => {
  const history = useHistory();
  const { toggleModal } = useModal();
  const { logout } = useRequest();
  const { fetchProducts } = useProduct();
  const { fetchUserDetails, userDetails, updateUserDetails } = useProfile();
  const selectedProductId = useSelectedProductId();

  useEffect(() => {
    fetchProducts();
    fetchUserDetails();
  }, []);

  const onToggleSideMenu = () => {
    toggleModal(modalIds.modalSideMenu);
  };

  const navigateToStudentEnvironment = () => {
    if (selectedProductId) {
      return history.push(
        buildUrlWithPathParams(PRODUCT_PAGES_ROUTES.PRODUCT_DETAILS, { productId: selectedProductId }),
      );
    }
  };

  return (
    <CssGrid gridGap={5} gridTemplateRows="auto 1fr auto" minHeight="100%">
      <TeacherHeader
        navigateToStudentEnvironment={navigateToStudentEnvironment}
        toggleSideMenu={onToggleSideMenu}
        updateUserDetails={updateUserDetails}
        userDetails={userDetails}
        onLogout={logout}
      />
      <StyledContent $withMenu={withMenu} as="section" gap={4} ml={4} mr={'4rem'}>
        {withMenu && <TeacherNavigationMenu className="aside-menu" userDetails={userDetails} />}
        <main className={className} data-cy="teacher-main-page">
          {children}
        </main>
      </StyledContent>
      <Footer />
    </CssGrid>
  );
});
