import styled from 'styled-components/macro';

import { calculateRem } from 'styles/mixins/mixins';
import { ThemeProps as Props } from 'models/styles/theme';

import { Heading2 } from 'styles/elements/Headings';

export const StyledTitleBar = styled.header<Props>`
  display: flex;
  padding: 2.5rem;
  column-gap: 2.5rem;
  align-items: center;
  justify-content: space-between;
  border-radius: ${calculateRem(4)};
  background-color: ${(props: Props) => props.theme.palette.neutral._100};
  margin: 2.5rem 0 2rem 0;
`;

export const StyledProgressBar = styled.div<Props>`
  display: flex;
  column-gap: 1rem;
  align-items: center;
  font-weight: bold;
`;

export const StyledTitle = styled(Heading2)<Props>`
  margin: 0;
`;
