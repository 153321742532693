import styled from 'styled-components/macro';

import { ThemeProps as Props } from 'models/styles/theme';
import { Button } from 'components/Button';
import { Heading3 } from 'styles/elements/Headings';
import { Paragraph } from 'styles/elements/Texts';
import { calculateRem } from 'styles/mixins/calculateRem';

type ParagraphProps = Props & {
  $marginTop?: number;
};

type ButtonProps = Props & {
  $transparentBackground?: boolean;
};

export const StyledHeading = styled(Heading3)<Props>`
  line-height: ${calculateRem(36)};
  margin-top: ${calculateRem(24)};
`;

export const StyledParagraph = styled(Paragraph)<ParagraphProps>`
  color: ${(props: Props) => props.theme.palette.neutral._900};
  line-height: ${calculateRem(24)};
  margin-bottom: 0;
  margin-top: ${({ $marginTop }: ParagraphProps) => calculateRem($marginTop || 0)};
`;

export const StyledButton = styled(Button)<ButtonProps>`
  font-size: ${calculateRem(16)};
  font-weight: unset;
  margin: ${calculateRem(16)};
  border: ${({ $transparentBackground, theme }: ButtonProps) =>
    $transparentBackground ? `solid ${theme.palette.primary.brand}` : 'none'};
  background: ${({ $transparentBackground, theme }: ButtonProps) =>
    $transparentBackground ? theme.palette.neutral._200 : theme.palette.primary.brand};
  color: ${({ $transparentBackground, theme }: ButtonProps) =>
    $transparentBackground ? theme.palette.primary.brand : theme.palette.text.primary};
  :hover {
    background: ${({ $transparentBackground, theme }: ButtonProps) =>
      $transparentBackground && theme.palette.neutral._200};
  }
`;
