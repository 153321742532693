import styled from 'styled-components/macro';

import { calculateRem } from 'styles/mixins/mixins';

import { ThemeProps } from 'models/styles/theme';

interface Props extends ThemeProps {
  type?: 'success' | 'warning';
}

export const StyledMessage = styled.article<Props>`
  margin: auto;
  min-width: 25rem;
  text-align: center;
  padding: 0.75rem 2rem;
  border-radius: ${calculateRem(4)};

  color: ${(props: Props) => props.theme.palette.neutral.black};

  background: ${(props: Props) => {
    switch (props.type) {
      case 'success':
        return props.theme.palette.feedback.success._200;
      case 'warning':
        return props.theme.palette.feedback.inProgress._200;
      default:
        return props.theme.palette.neutral._200;
    }
  }};
`;
