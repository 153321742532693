import styled from 'styled-components/macro';
import { ThemeProps as Props } from 'models/styles/theme';
import testBackgroundImage from 'assets/images/test-countdown-img.png';
import { Heading2 } from 'styles/elements/Headings';
import { calculateRem } from 'styles/mixins/calculateRem';

export const StyledCountdownContainer = styled.div<Props>`
  background-image: ${({ theme }: Props) => `url(${testBackgroundImage}), linear-gradient(
    90deg, ${theme.palette.neutral.white} 50%, ${theme.palette.secondary._200} 50%
  )`};
  background-position: bottom right;
  background-repeat: no-repeat;
  height: 100vh;
`;

export const StyledCountdownTimer = styled.div<Props>`
  margin-top: ${calculateRem(200)};
  text-align: center;
  width: 50%;
`;

export const StyledCountdownText = styled(Heading2)<Props>`
  color: ${({ theme }: Props) => theme.palette.neutral._900};
  font-size: ${({ theme }: Props) => theme.font.size._32};
  font-weight: ${({ theme }: Props) => theme.font.weight.normal};
`;

export const StyledStartDate = styled(Heading2)<Props>`
  color: ${({ theme }: Props) => theme.palette.neutral.black};
  font-size: ${({ theme }: Props) => theme.font.size._48};
  font-weight: ${({ theme }: Props) => theme.font.weight.bolder};
  line-height: 70px;
  margin: 0 auto ${calculateRem(50)};
  width: 70%;
`;
