import { LoadingSpinner } from 'components/LoadingSpinner';
import { SvgIconReact } from 'components/SvgIconReact';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ActionMessageProps } from './ActionMessage.model';
import {
  StyledBackButton,
  StyledButton,
  StyledContent,
  StyledDividerSpan,
  StyledHeading,
  StyledIcon,
} from './ActionMessage.styled';

export const ActionMessage: React.FC<ActionMessageProps> = ({
  iconName,
  title,
  message,
  isLoading = false,
  actionButtonLabel,
  actionButtonClick,
  cancelButtonLabel,
  cancelButtonClick,
}) => {
  const { t } = useTranslation('modal');
  return (
    <>
      {iconName && (
        <StyledIcon>
          <SvgIconReact iconName={iconName} size={5} />
        </StyledIcon>
      )}
      <StyledHeading>{title}</StyledHeading>
      {message && <StyledContent>{message}</StyledContent>}
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <StyledButton dataCy="action-message-modal-confirm-button" variant="primary" onClick={actionButtonClick}>
            {actionButtonLabel}
          </StyledButton>
          {cancelButtonLabel && cancelButtonClick && (
            <>
              <StyledDividerSpan>{t('general.buttonDividerTitle', 'or')}</StyledDividerSpan>
              <StyledBackButton
                data-cy="action-message-modal-cancel-button"
                size="small"
                variant="border"
                onClick={() => cancelButtonClick()}
              >
                {cancelButtonLabel}
              </StyledBackButton>
            </>
          )}
        </>
      )}
    </>
  );
};
