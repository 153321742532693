import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { RelatedTopic } from 'models/exam/Exam';
import { Box, CssFlex } from 'styles/helpers/layout';
import { EmbeddedHtml } from 'components/EmbeddedHtml';

interface RelatedTopicListProps {
  relatedTopics: RelatedTopic[];
}

export const RelatedTopicList: React.FC<RelatedTopicListProps> = ({ relatedTopics = [] }) => {
  const { t } = useTranslation('adaptive-test');
  const relatedTopicCount = relatedTopics.length;

  if (relatedTopicCount === 0) {
    return null;
  }

  return (
    <Box data-cy="related-topics" marginTop={1}>
      {relatedTopicCount > 1 ? (
        <>
          {t('footer.feedback.affectedTopics.multiple')}
          <CssFlex flexWrap="wrap">
            <ul style={{ columnCount: 3, columnGap: '3rem', width: '100%' }}>
              {relatedTopics.map(({ id, title }) => (
                <EmbeddedHtml key={`list-item-${id}`} rawHtml={title} tagName="li" />
              ))}
            </ul>
          </CssFlex>
        </>
      ) : (
        <Trans i18nKey="footer.feedback.affectedTopics.single" t={t}>
          {'The topic "'}
          <EmbeddedHtml rawHtml={relatedTopics[0].title} tagName="span" />
          {'" was covered in the text.'}
        </Trans>
      )}
    </Box>
  );
};
