import React, { ReactNode } from 'react';

import { CssClickableIcon } from 'styles/helpers/layout';

import { StyledBox, StyledTitle } from './ProgressStatBox.styled';
import { useReactTooltip } from 'hooks/useReactTooltip';

interface ProgressStatBoxProps {
  dataCy?: string;
  title: string;
  tooltip?: string;
  value: ReactNode; // Value is expected to be a node of these types: CssFlex, Heading3
}

export const ProgressStatBox: React.FC<ProgressStatBoxProps> = ({ dataCy, title, tooltip, value }) => {
  useReactTooltip(!!tooltip);

  return (
    <StyledBox data-cy={dataCy} gap={1} gridTemplateRows="max-content 1fr">
      <StyledTitle>
        {title}
        {tooltip && (
          <CssClickableIcon
            data-cy="info-icon"
            data-tip={tooltip}
            iconName="far fa-info-circle"
            iconSize="lg"
            marginLeft={1}
          />
        )}
      </StyledTitle>
      {value}
    </StyledBox>
  );
};
