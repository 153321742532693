import styled from 'styled-components/macro';

import { ThemeProps as Props } from 'models/styles/theme';
import { CssClickableIcon } from 'styles/helpers/layout';

export const StyledEditIcon = styled(CssClickableIcon)<Props>`
  font-size: 1.25rem;
  transition: 0.2s ease-in-out;

  :hover {
    color: ${(props: Props) => props.theme.palette.secondary.brand};
  }
`;
