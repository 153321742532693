import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';

import { TEST_ENVIRONMENT_ROUTES } from 'constants/routes';
import { buildUrlWithPathParams } from '_helpers/utils/urlBuilder';
import { useScheduledTests } from 'hooks/useScheduledTests';
import { CountdownTimer } from 'components/CountdownTimer/CountdownTimer';

import { TestHeader } from 'pages/TestEnvironment/components/TestHeader/TestHeader';
import { TestPagePathParams } from '../../subpages/TestOverviewPage/TestOverviewPage.model';
import { ScheduledTestHeaderProps } from './ScheduledTestHeader.model';

export const ScheduledTestHeader: React.FC<ScheduledTestHeaderProps> = observer(({ counter, title, onClose }) => {
  const { t } = useTranslation('test-environment');
  const { scheduledTestStatusAndTimeLeft } = useScheduledTests();
  const history = useHistory();
  const { code }: TestPagePathParams = useParams();

  return (
    <TestHeader
      rightComponent={
        scheduledTestStatusAndTimeLeft?.counter !== undefined && (
          <div>
            <CountdownTimer
              prefix={t('navbar.timeLeft', 'Time left: ')}
              secondsLeft={counter}
              warningTimeSeconds={10 * 60}
              onCountdownFinish={() =>
                history.replace(buildUrlWithPathParams(TEST_ENVIRONMENT_ROUTES.TEST_COMPLETED_PAGE, { code }))
              }
            />
          </div>
        )
      }
      title={title}
      onClose={onClose}
    />
  );
});
