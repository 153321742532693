import React, { ReactNode, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

interface Props {
  children: ReactNode;
}

// This component, added inside `Router` instance, will ensure the scroll is
// set back to the top whenever a navigation to a different page takes place
const ScrollToTop: React.FC<Props> = ({ children }) => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return <>{children}</>;
};

export default ScrollToTop;
