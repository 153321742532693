import React from 'react';
import { observer } from 'mobx-react';
import { Trans, useTranslation } from 'react-i18next';

import landingPageImg from 'assets/images/landing-page-img.png';

import { Footer } from 'components/Footer';

import { useProfile } from 'hooks/useProfile';
import { TestAccessForm, GeneralHeader } from './components';

import {
  StyledContainer,
  StyledWelcomeBox,
  StyledMessage,
  StyledPreparationBox,
  StyledCollage,
  StyledTipContainer,
} from './LandingPage.styled';

export const LandingPage: React.FC = observer(() => {
  const { t } = useTranslation('test-environment');
  const { userDetails } = useProfile();

  return (
    <>
      <GeneralHeader />
      <StyledContainer>
        <StyledWelcomeBox>
          <StyledMessage>
            <h1>{t('welcomeBox.title', { name: userDetails?.firstName })}</h1>
            <Trans i18nKey="welcomeBox.description" t={t}>
              <p>
                Enter your test code on the right and press the button to continue. After that, you can take the test.
                Good luck!
              </p>
              <p>
                Arrived a little too early? Scroll down and check out our test tips. With this wisdom, you’ll be set to
                succeed.
              </p>
            </Trans>
          </StyledMessage>
          <TestAccessForm />
        </StyledWelcomeBox>

        <StyledPreparationBox>
          <StyledCollage alt="" src={landingPageImg} />
          <StyledMessage>
            <h1>{t('preparationBox.title', 'Our 5 best test tips')}</h1>
            <StyledTipContainer>
              <Trans i18nKey="preparationBox.description" t={t}>
                <p>Got some time left before the test starts? We’ve listed our 5 best test tips for you.</p>
                <h3>1. Collect everything you need</h3>
                <p>
                  Do you have everything you need to take the test? If you take the test at home, make sure you’re in a
                  quiet place with a stable internet connection. Saves you a lot of hassle!
                </p>
                <h3>2. Read the questions carefully</h3>
                <p>
                  People often times make mistakes because they read the questions too quickly. Such a shame! Take time
                  to read through the question so you’ll have a bigger chance at giving the right answer.
                </p>
                <h3>3. Make sure to always answer something</h3>
                <p>
                  It’s okay to have doubts or not know the answer at all. As long as you fill in something, there’s
                  always a chance you’ll have it right.
                </p>
                <h3>4. Use all your time</h3>
                <p>
                  If you finish early, it’s tempting to hand in your test rightaway. Instead, go through your answers
                  again. You can adjust them until the very last moment.
                </p>
                <h3>5. Take a deep breath</h3>
                <p>
                  It’s no use trying to practice all of the theory right before the test starts. So take a deep breath,
                  relax and clear your head. You’ve got this!
                </p>
              </Trans>
            </StyledTipContainer>
          </StyledMessage>
        </StyledPreparationBox>
      </StyledContainer>
      <Footer />
    </>
  );
});
