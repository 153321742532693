import styled, { css } from 'styled-components/macro';

import { ThemeProps } from 'models/styles/theme';
import { ScheduledTestLogEventType, ScheduledTestLogEventName } from 'models/log/Log';
import { HST_PROCTORING_EVENTS } from '@he-learning/service-he-common';

interface Props extends ThemeProps {
  eventType?: ScheduledTestLogEventType;
  eventName?: ScheduledTestLogEventName;
}

export const StyledEventDescription = styled.span<Props>`
  flex-grow: 1;

  color: ${({ theme, eventName }: Props) => {
    switch (eventName) {
      case HST_PROCTORING_EVENTS.STUDENT_CURSOR_LEAVES_PAGE:
      case HST_PROCTORING_EVENTS.STUDENT_LEAVES_FULL_SCREEN:
        return theme.palette.feedback.error.brand;
      case HST_PROCTORING_EVENTS.STUDENT_CURSOR_RETURNS_PAGE:
      case HST_PROCTORING_EVENTS.STUDENT_GOES_FULL_SCREEN:
        return theme.palette.secondary._900;
      default:
        return theme.palette.neutral._900;
    }
  }};
`;

export const StyledEventItemIpAddress = styled.span<Props>`
  flex-shrink: 0;
  color: ${(props: Props) => props.theme.palette.neutral.black};
`;

export const StyledEventTime = styled.span<Props>`
  flex-shrink: 0;
  font-weight: normal;
`;

export const StyledEventItem = styled.li<Props>`
  padding: 0.5rem;
  font-weight: bold;
  color: ${(props: Props) => props.theme.palette.neutral._900};

  display: flex;
  column-gap: 2rem;
  justify-content: space-between;

  ${({ theme, eventType }: Props) => {
    if (eventType === 'fraud') {
      return css`
        background: ${theme.palette.feedback.error.brand};

        ${StyledEventDescription} {
          color: ${theme.palette.neutral.white};
        }

        ${StyledEventTime} {
          color: ${theme.palette.neutral.white};
        }
      `;
    }

    return css`
      background: ${theme.palette.neutral._100};
    `;
  }}
`;
