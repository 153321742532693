import { Document, Paragraph, TextRun } from 'docx';
import { OverviewAnswerResult } from 'models/exam/Exam';

const spacing = {
  after: 300,
};

/*
 * This method will convert strings to DOCX-Paragraphs.
 * - Paragraph: each answer gets its own Paragraph in the DOCX-document, with spacing after.
 * - TextRun: When an `answer` contains multiple lines, we want the DOCX document
 *            to add spacing between these lines.
 *            The way the docx-generator supports this is by adding `{break: 1}`:
 *            Which insert some space before every line (excluding the first line).
 */
const answerToParagraph: (answer: string) => Paragraph[] = answer => {
  const lines = answer.split('\n');
  if (lines.length === 1) {
    return lines[0] === ''
      ? []
      : [
          new Paragraph({
            spacing,
            text: lines[0],
          }),
        ];
  }
  return [
    new Paragraph({
      spacing,
      children: [
        new TextRun(lines[0]),
        ...lines.slice(1).map(
          line =>
            new TextRun({
              text: line,
              break: 1, // enters a newline on line before
            }),
        ),
      ],
    }),
  ];
};

/*
 * This method will convert OverviewAnswerResults from backend to a DOCX-Document, which can be download.
 */
export const createDocx: (answers: OverviewAnswerResult[]) => Document = answers =>
  new Document({
    sections: [
      {
        children: answers.flatMap(a => answerToParagraph(a.value)),
      },
    ],
  });
