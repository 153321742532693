import styled from 'styled-components/macro';
import { ThemeProps } from 'models/styles/theme';

import { calculateRem } from 'styles/mixins/calculateRem';

export const StyledTableContainer = styled.div<ThemeProps>`
  margin-top: ${calculateRem(44)};
  padding: ${calculateRem(16)};
  width: 100%;
`;

export const StyledCertificatesTable = styled.table<ThemeProps>`
  width: 100%;
  vertical-align: middle;
  text-align: left;
  border-collapse: collapse;

  tr th {
    padding: ${calculateRem(16)};
  }

  tbody tr td {
    white-space: nowrap;
    width: auto;
    padding: ${calculateRem(16)};

    &:nth-last-child(-n + 4) {
      font-weight: ${({ theme }: ThemeProps) => theme.font.weight.bold};
    }
    &:last-child {
      text-align: center;
    }
  }

  tbody tr:nth-child(odd) {
    background: ${({ theme }: ThemeProps) => theme.palette.neutral._100};
  }
`;
