import styled from 'styled-components/macro';

import { calculateRem } from 'styles/mixins/mixins';
import { ThemeProps as Props } from 'models/styles/theme';

export const StyledContainer = styled.main<Props>`
  width: 70%;
  margin: 2.5rem auto;
  max-width: ${calculateRem(976)};
`;
