import * as Yup from 'yup';
import { GenericValidator } from 'pages/TeacherEnvironment/TeacherDashboard/subpages/ScheduledTestWizard/validate';

export const createValidationSchema = (): GenericValidator =>
  Yup.object({
    productId: Yup.number().required().defined(),
    testType: Yup.string()
      .matches(/(summative-test|formative-test|freebie-test)/)
      .required()
      .defined(),
  });
