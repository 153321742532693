import styled, { css } from 'styled-components/macro';
import { ElearningThemeInterface } from 'themes/elearningInterface.theme';
import { calculateRem } from 'styles/mixins/mixins';
import { calculateRems } from 'styles/mixins/calculateRem';

import { QuestionStatus } from 'constants/exam-constants';
import { bgColorCalc } from '_helpers/statusColorCalc';
import { Button } from 'components/Button';
import { ThemeProps } from 'models/styles/theme';
import { StyledAnswerBoxProps } from './AnswerBox.model';

interface Props extends StyledAnswerBoxProps {
  theme: ElearningThemeInterface;
}
export const StyledAnswerBox = styled.div<Props>`
  width: ${calculateRem(640)};
  justify-self: center;
`;

export const StyledTitle = styled.div<Props>`
  min-height: ${calculateRem(72)};
  display: flex;
  align-items: center;
  border-radius: ${calculateRem(4)};

  span {
    margin-left: ${calculateRem(5)};
  }

  .status-icon {
    font-size: 2.5rem;
    margin-right: 1rem;
  }

  .title-container {
    display: flex;
    flex-direction: column;
    align-items: start;
  }

  ${(props: Props) => {
    if (props.status === QuestionStatus.CORRECT) {
      return css`
        justify-content: center;
        border: 0.13rem solid ${props.theme.palette.feedback.success.brand};
        padding: ${calculateRems([16, 40])};

        .status-icon {
          color: ${props.theme.palette.feedback.success.brand};
        }
      `;
    }

    if (props.status === QuestionStatus.INCORRECT) {
      return css`
        justify-content: flex-start;
        border: 0.13rem solid ${props.theme.palette.feedback.error.brand};
        padding: ${calculateRems([24, 18])};

        .title {
          font-weight: ${props.theme.font.weight.bold};
          color: ${props.theme.palette.feedback.error.brand};
        }

        .status-icon {
          color: ${props.theme.palette.feedback.error.brand};
        }
      `;
    }
    return '';
  }}
`;

export const StyledFeedback = styled.div`
  background-color: ${(props: Props) => bgColorCalc(props)};
  border-radius: ${calculateRems([0, 0, 4, 4])};
  padding: ${calculateRems([24, 18])};
  a {
    font-weight: ${(props: Props) => props.theme.font.weight.bold};
  }
  div {
    line-height: ${calculateRem(24)};
    margin-bottom: 0.875rem;
  }
`;

export const StyledButton = styled(Button)`
  font-weight: ${(props: ThemeProps) => props.theme.font.weight.bold};
  background-color: transparent;
`;
