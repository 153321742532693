import styled, { css } from 'styled-components/macro';
import { ElearningThemeInterface } from 'themes/elearningInterface.theme';
import SeparatorLineProps from './SeparatorLine.model';

interface Props extends SeparatorLineProps {
  theme: ElearningThemeInterface;
}

const getDirectionalCss = (props: Props) => {
  switch (props.direction) {
    case 'vertical':
      return css`
        height: ${props.height ? `${props.height}rem` : '2rem'};
        width: 0.125rem;
        margin: 0 10px;
      `;
    case 'horizontal':
      return css`
        width: ${props.width ? `${props.width}rem` : '100%'};
        height: ${props.height ? `${props.height}rem` : '0.125rem'};
        margin: 10px 0;
      `;
    default:
      return '';
  }
};

export const StyledSeparatorLine = styled.div<Props>`
  display: inline-block;
  background-color: ${(props: Props) => (props.color ? props.color : props.theme.palette.neutral._400)};
  ${props => getDirectionalCss(props)}
`;
