import React from 'react';
import { useTranslation } from 'react-i18next';
import format from 'date-fns/format';

import { Heading3 } from 'styles/elements/Headings';
import { SvgIconReact } from 'components/SvgIconReact';

import {
  StyledICardScheduledTest,
  StyledText,
  StyledTag,
  WrapperInline,
  StyledTextTime,
  StyledProductFlag,
} from './CardScheduledTest.styled';
import { SimulateLoginButton } from '../../../../components/SimulateLoginButton/SimulateLoginButton';
import { Button } from 'components/Button';
import { useModal } from 'hooks/useModal';
import { modalIds } from 'constants/modals-constants';
import { ContentAPI } from 'generated/types';
import { ModifyScheduledTestModal } from '../ModifyScheduledTestModal/ModifyScheduledTestModal';

export const CardScheduledTest: React.FC<ContentAPI.ScheduledTestGroupedData> = ({
  id,
  title,
  date,
  estimatedStudents,
  teacherName,
  teacherLastName,
  code,
  skillLevel,
  duration,
  testType,
  testVersion,
  productId,
  teacherId,
  cumulative,
  reviewStartDate,
  reviewEndDate,
}) => {
  const { t } = useTranslation('admin-environment');
  const { toggleModal } = useModal();
  const teacherInformation = teacherName.concat(' ', `${teacherLastName}`);
  const formatedDate = format(new Date(date), 'dd-MM-Y');
  const formatedTime = format(new Date(date), 'HH:mm:ss');
  const dateAndTime = formatedDate.concat(`${t('scheduledTestDateList.card.time', ' at ')}`, formatedTime);

  return (
    <StyledICardScheduledTest data-cy="card-scheduled-test">
      <ModifyScheduledTestModal id={id} />
      <WrapperInline>
        <Heading3>
          {title}
          <StyledProductFlag productId={productId} />
        </Heading3>
        <StyledTag testType={testType}>{testType}</StyledTag>
      </WrapperInline>

      <WrapperInline alignStart>
        <StyledTextTime>{dateAndTime}</StyledTextTime>
        <StyledText>
          <strong>{t('scheduledTestDateList.card.teacher', 'Teacher: ')}</strong>
          {teacherInformation}
        </StyledText>
        <SimulateLoginButton accountId={teacherId} activeStateVariant="default" variant="default" />
      </WrapperInline>

      <StyledText>
        <strong>{t('scheduledTestDateList.card.estimatedStudents', 'Estimated amount of Students: ')}</strong>
        {estimatedStudents}
      </StyledText>
      <WrapperInline>
        <StyledText>
          <strong>{t('scheduledTestDateList.card.skillLevel', 'Skill level: ')}</strong>
          {skillLevel}
        </StyledText>
        <StyledText>
          <strong>{t('scheduledTestDateList.card.testVersion', 'Test version: ')}</strong>
          {testVersion}
        </StyledText>
      </WrapperInline>
      <WrapperInline>
        <StyledText data-cy="card-scheduled-test-duration">
          <strong>{t('scheduledTestDateList.card.duration', 'Duration: ')}</strong>
          {`${duration} min`}
        </StyledText>
        <StyledText data-cy="card-scheduled-test-code" fontColor="tertiary">
          <SvgIconReact iconName="codeIcon" size={2} />
          {code}
        </StyledText>
      </WrapperInline>
      <Button
        dataCy={`modify-scheduled-test-${id}`}
        variant="primary"
        onClick={() =>
          toggleModal(modalIds.updateScheduledTest, {
            cumulative,
            reviewStartDate,
            reviewEndDate,
            testCode: code,
            id,
          })
        }
      >
        {t('adminTools.scheduledTest.action')}
      </Button>
    </StyledICardScheduledTest>
  );
};
