import React, { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import format from 'date-fns/format';

import { useReactTooltip } from 'hooks/useReactTooltip';
import { FilterTableProps, ScheduledTestsAdminResponse, ScheduledTestsAdminResponseData } from 'models/admin/Admin';
import { Link } from 'components/Link';
import { SvgIconReact } from 'components/SvgIconReact';
import { DEFAULT_ROWS_PER_PAGE, ROWS_PER_PAGE } from 'constants/admin-constants';
import { buildUrlWithPathParams } from '_helpers/utils/urlBuilder';
import { ADMIN_ENVIRONMENT_ROUTES } from 'constants/routes';
import {
  StyledTableContainer,
  StyledTableHeader,
  StyledHeader,
  StyledTable,
  StyledSelectContainer,
  StyledSelect,
} from './TestsTable.styled';

interface TestTableProps {
  sheduledTests: ScheduledTestsAdminResponse;
}

interface TestTableHeaderProps {
  id: string;
  title: string;
}

export const TestsTable: React.FC<FilterTableProps & TestTableProps> = observer(
  ({ onResultsChange, selectedResults, sheduledTests, storedParams }) => {
    useReactTooltip();
    const { t } = useTranslation('admin-environment');
    const resultsPerPage = ROWS_PER_PAGE.map(rows => ({
      value: rows,
      label: `${rows}`,
    }));

    const hasContent = sheduledTests.data?.length > 0;

    const headerCells = useMemo<TestTableHeaderProps[]>(
      () => [
        {
          id: 'date',
          title: t('scheduleTestsTable.day', 'Date of the day'),
        },
        {
          id: 'amount-scheduled',
          title: t('scheduleTestsTable.amountScheduled', 'Amount scheduled tests'),
        },
        {
          id: 'total-estimated',
          title: t('scheduleTestsTable.totalEstimated', 'Total estimated amount of numbers'),
        },
        {
          id: 'details',
          title: t('scheduleTestsTable.details', 'Details'),
        },
      ],
      [sheduledTests.data],
    );

    const rowData = useMemo(
      () =>
        hasContent &&
        sheduledTests.data?.map(({ day, testsAmount, estimatedStudents }: ScheduledTestsAdminResponseData) => [
          {
            dataCy: 'item-date',
            content: format(new Date(day), 'dd-MM-Y'),
          },
          {
            dataCy: 'item-amount',
            content: testsAmount,
          },
          {
            dataCy: 'item-estimated',
            content: estimatedStudents,
          },
          {
            dataCy: 'item-details',
            content: (
              <Link
                data-cy={`group-${day}-details`}
                to={{
                  pathname: `${buildUrlWithPathParams(ADMIN_ENVIRONMENT_ROUTES.USER_SCHEDULED_TESTS_GROUP, {
                    date: day,
                  })}`,
                  state: {
                    params: storedParams,
                  },
                }}
              >
                <SvgIconReact iconName="rightArrow" size={1} />
              </Link>
            ),
          },
        ]),
      [sheduledTests.data],
    );

    return (
      <StyledTableContainer>
        <StyledTableHeader>
          <StyledHeader>
            <Trans i18nKey="scheduleTestsTable.tests" t={t}>
              Students (<span data-cy="scheduled-count">{{ scheduledCount: sheduledTests.data?.length || 0 }}</span>)
            </Trans>
          </StyledHeader>
          <StyledSelectContainer>
            {t('userFilter.results.label', 'Show:')}
            <StyledSelect
              ariaLabel="results-selector"
              dataCy="results-selector"
              defaultValue={DEFAULT_ROWS_PER_PAGE}
              options={resultsPerPage}
              value={selectedResults}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => onResultsChange(parseInt(e.target.value, 10))}
            />
          </StyledSelectContainer>
        </StyledTableHeader>
        <StyledTable>
          <thead>
            <tr>
              {headerCells.map(({ id, title }) => (
                <th key={id}>{title}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rowData ? (
              rowData.slice(0, selectedResults).map((items, index) => (
                <tr key={`row-${index}`}>
                  {items.map(({ content, dataCy }) => (
                    <td key={dataCy} data-cy={dataCy}>
                      {content}
                    </td>
                  ))}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={4}>{t('noResults.message', 'No results')}</td>
              </tr>
            )}
          </tbody>
        </StyledTable>
      </StyledTableContainer>
    );
  },
);
