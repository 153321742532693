import React from 'react';
import { StyledInput, StyledInputPostFix, StyledInputWrapper } from './Input.styled';
import InputProps from './Input.model';

export const Input: React.FC<InputProps> = ({ dataCy, postfixLabel, ...props }) => (
  <StyledInputWrapper $hasError={props.hasError} $hasPostFix={!!postfixLabel}>
    <StyledInput $hasError={props.hasError} $hasPostFix={!!postfixLabel} data-cy={dataCy} {...props} />
    {postfixLabel && (
      <StyledInputPostFix $hasError={props.hasError}>
        <span>{postfixLabel}</span>
      </StyledInputPostFix>
    )}
  </StyledInputWrapper>
);

export default Input;
