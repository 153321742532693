import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { useReactTooltip } from 'hooks/useReactTooltip';
import { ContentAPI } from 'generated/types';
import { AssignmentTopicProgressionStatus, AssignmentTopicProgressionStatusType } from 'models/progress/Progress';
import { TEST_PAGE_ROUTES } from 'constants/routes';

import { buildUrlWithPathAndQueryParams } from '_helpers/utils/urlBuilder';
import { LevelDash } from 'components/LevelDash';
import { EmbeddedHtml } from 'components/EmbeddedHtml';
import { AdaptiveTestPageParams } from 'pages/StudentEnvironment/AdaptiveTestPage/AdaptiveTestPage.model';

import { Box, CssFlex, CssIcon } from 'styles/helpers/layout';
import {
  StyledCorrectTextTopic,
  StyledTitle,
  StyledNeedReviewMessage,
  StyledTopicList,
} from './CorrectTextTopic.styled';

import starSvg from '../../../../../../../assets/star.svg';
import improvementChartSvg from '../../../../../../../assets/images/improvement-chart.svg';
import { calculateRem } from 'styles/mixins/calculateRem';

export interface CorrectTextTopicProps {
  topic: ContentAPI.TopicListItem;
  subjectId: number;
  isDisabled: boolean;
  progressState: AssignmentTopicProgressionStatusType;
}

export const CorrectTextTopic: React.FC<CorrectTextTopicProps> = ({ topic, subjectId, isDisabled, progressState }) => {
  useReactTooltip();

  const { t } = useTranslation('adaptive-test');
  const { productId, moduleId }: AdaptiveTestPageParams = useParams();
  const history = useHistory();

  const { id, title, relatedTopicsToReview } = topic;
  const topicState: AssignmentTopicProgressionStatusType =
    relatedTopicsToReview && relatedTopicsToReview.length > 0 ? AssignmentTopicProgressionStatus.FAILED : progressState;

  const onTopicClicked = () => {
    if (
      topicState === AssignmentTopicProgressionStatus.READY ||
      topicState === AssignmentTopicProgressionStatus.FAILED
    ) {
      history.push(
        buildUrlWithPathAndQueryParams(
          TEST_PAGE_ROUTES.ADAPTIVE_PRACTICE_TEST,
          { productId, moduleId, subjectId },
          { topicId: id },
        ),
      );
    }
  };

  const getTopicStateText = () => {
    switch (topicState) {
      case AssignmentTopicProgressionStatus.COMPLETED:
        return t('correctText.completed');
      case AssignmentTopicProgressionStatus.LOCKED:
        return t('correctText.locked');
      case AssignmentTopicProgressionStatus.FAILED:
        return t('correctText.failed');
      default:
        return t('correctText.unlocked');
    }
  };

  return (
    <StyledCorrectTextTopic
      alignItems="center"
      data-cy={`correct-text-topic-${id}`}
      gridTemplateColumns="2.5rem 2fr 1fr"
      isDisabled={isDisabled}
      ml={'-2.5rem'}
      p={'0 1.5rem 0 4rem'}
      topicState={topicState}
      onClick={onTopicClicked}
    >
      <Box textAlign="center">
        {topicState === AssignmentTopicProgressionStatus.LOCKED && <CssIcon iconName={'fas fa-lock'} />}
        {topicState === AssignmentTopicProgressionStatus.READY && <CssIcon iconName={'fas fa-lock-open'} />}
      </Box>
      <Box textAlign="left">
        <StyledTitle alignItems="center" height={calculateRem(40)}>
          <EmbeddedHtml rawHtml={title} />
        </StyledTitle>
      </Box>
      {!isDisabled && (
        <CssFlex alignItems="center" data-cy="topic-state-text" gap={0.75}>
          {topicState === AssignmentTopicProgressionStatus.COMPLETED ? (
            <img className="star-icon" src={starSvg} />
          ) : (
            <LevelDash level={topicState === AssignmentTopicProgressionStatus.FAILED ? 'basics' : 'custom'} />
          )}
          <div>{getTopicStateText()}</div>
        </CssFlex>
      )}
      {topicState === AssignmentTopicProgressionStatus.FAILED &&
        relatedTopicsToReview &&
        relatedTopicsToReview.length > 0 && (
          <StyledNeedReviewMessage data-cy="topics-needing-review">
            <img className="chart-icon" src={improvementChartSvg} />
            <section>
              <p>{t('correctText.requireMorePractice')}</p>
              <StyledTopicList>
                {relatedTopicsToReview?.map(relatedTopicTitle => (
                  <EmbeddedHtml key={relatedTopicTitle} rawHtml={relatedTopicTitle} tagName="li" />
                ))}
              </StyledTopicList>
            </section>
          </StyledNeedReviewMessage>
        )}
    </StyledCorrectTextTopic>
  );
};
