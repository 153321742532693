import React, { useCallback, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useProfile } from 'hooks/useProfile';
import { useProduct } from 'hooks/useProduct';
import { useWriting } from 'hooks/useWriting';
import { useRandomFact } from 'hooks/useRandomFact';
import { useAdaptivePractice } from 'hooks/useAdaptivePractice';
import { useSelectedProductId } from 'hooks/useSelectedProductId';

import { ResultsAPI, ProgressAPI } from 'generated/types';
import { ModuleType, ProductModules } from 'models/product/ProductDetails';

import { PRODUCT_PAGES_ROUTES } from 'constants/routes';
import { buildUrlWithPathParams } from '_helpers/utils/urlBuilder';

import { CssFlex, Typography } from 'styles/helpers/layout';
import { Heading2, Heading3 } from 'styles/elements/Headings';

import { StyledMainSection, StyledSelect } from './StudentDashboardPage.styled';
import { ModuleCard } from './components/ModuleCard/ModuleCard';
import { useProductProgress } from 'hooks/useProductProgress';

export const StudentDashboardPage: React.FC = observer(() => {
  const history = useHistory();
  const { t } = useTranslation('homepage');

  const randomFact = useRandomFact();
  const { userDetails } = useProfile();
  const selectedProductId = useSelectedProductId();
  const { productDetails, productsList } = useProduct();
  const { lastPracticedAdaptiveTopicStatuses, fetchLastPracticedAdaptiveTopicStatuses } = useAdaptivePractice();
  const { lastPracticedWritingTopicStatuses, fetchLastPracticedWritingTopicStatuses } = useWriting();
  const { fetchProductGoalLevel, productGoalLevel } = useProductProgress();
  const productSelectorOptions = useMemo(
    () => productsList?.map(({ id, title }) => ({ label: title, value: id })) || [],
    [productsList],
  );

  // Obtain object with current goal level
  const currentGoalLevel = useMemo(() => {
    if (!productGoalLevel?.goalLevel || !productDetails) {
      return null;
    }

    return productDetails.levels.find(({ level }) => level === productGoalLevel.goalLevel);
  }, [productGoalLevel, productDetails]);

  useEffect(() => {
    if (selectedProductId) {
      fetchLastPracticedAdaptiveTopicStatuses(selectedProductId);
      fetchLastPracticedWritingTopicStatuses(selectedProductId);
      fetchProductGoalLevel(selectedProductId);
    }
  }, [selectedProductId]);

  const getLastPracticedTopicForModule = useCallback(
    (
      module: ProductModules,
    ): ResultsAPI.LastPracticedWritingTopicStatus | ProgressAPI.LastPracticedAdaptiveTopicStatus | null => {
      switch (module.type) {
        case ModuleType.ADAPTIVE:
          return lastPracticedAdaptiveTopicStatuses?.find(({ moduleId }) => moduleId === module.id) || null;
        case ModuleType.WRITING:
          return lastPracticedWritingTopicStatuses?.find(({ moduleId }) => moduleId === module.id) || null;
        default:
          return null;
      }
    },
    [lastPracticedAdaptiveTopicStatuses, lastPracticedWritingTopicStatuses],
  );

  const onSelectProductId = (productId: number) => {
    history.push(buildUrlWithPathParams(PRODUCT_PAGES_ROUTES.PRODUCT_DETAILS, { productId }));
  };

  return (
    <StyledMainSection flexDirection="column" marginLeft="2rem" rowGap={3}>
      <section>
        <Heading2>{t('studentDashboard.welcomeMessage', { userName: userDetails?.firstName })}</Heading2>
        <Typography data-cy="random-fact">{randomFact}</Typography>
      </section>
      {/* -- Product select section -- */}
      {productSelectorOptions.length > 1 && (
        <section>
          <Heading3>{t('studentDashboard.productSelector.label')}</Heading3>
          <StyledSelect
            dataCy="product-selector"
            options={productSelectorOptions}
            placeholder={t('studentDashboard.productSelector.placeholder')}
            showPlaceholderInOptionList={false}
            value={selectedProductId?.toString()}
            onChange={event => onSelectProductId(+event.target.value)}
          />
        </section>
      )}
      {/* -- Module cards section -- */}
      {selectedProductId && productDetails?.id === selectedProductId && (
        <section>
          <Heading3>{t('studentDashboard.continueSection.title')}</Heading3>
          <CssFlex flexDirection="row" flexWrap="wrap" gap={2}>
            {productDetails?.modules.map(module => (
              <ModuleCard
                key={module.id}
                currentGoalLevel={currentGoalLevel}
                lastPracticedTopicStatus={getLastPracticedTopicForModule(module)}
                moduleDetails={module}
                productId={selectedProductId}
              />
            ))}
          </CssFlex>
        </section>
      )}
    </StyledMainSection>
  );
});
