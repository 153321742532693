import React from 'react';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import { ResultsAPI } from 'generated/types';
import { modalIds } from 'constants/modals-constants';
import { useDomainHandler } from 'hooks/useDomainHandler';

import { useModal } from 'hooks/useModal';

import { Modal } from 'components/Modal';
import { Button } from 'components/Button';
import { InputWithErrorMessage } from 'components/InputWithErrorMessage';

import { buildSEBKeySchema } from './SEBKeyUpsertDialog.schema';
import {
  StyledButtonWrapper,
  StyledDialog,
  StyledInnerDialog,
  StyledInputsWrapper,
  StyledLabel,
  StyledSpacer,
  StyledTitle,
} from './SEBKeyUpsertDialog.styled';

export interface SEBKeyUpsertDialogProps {
  editSEBKey: ResultsAPI.SEBKey | null;
  onSubmit: (key: ResultsAPI.InsertSEBKey | ResultsAPI.UpdateSEBKey) => void;
}

export const SEBKeyUpsertDialog: React.FC<SEBKeyUpsertDialogProps> = ({ editSEBKey: sebKey, onSubmit }) => {
  const { t } = useTranslation('scheduled-tests');
  const { isModalOpen, closeModal } = useModal();
  const { getCustomNameByDomain } = useDomainHandler();

  const title = sebKey
    ? t('testOptionsStep.sebKeys.modal.upsert.editTitle')
    : t('testOptionsStep.sebKeys.modal.upsert.createTitle');
  const showDescription = sebKey === null;
  return (
    <Formik
      enableReinitialize
      initialValues={{
        name: sebKey?.name ?? '',
        key: sebKey?.key ?? '',
      }}
      validationSchema={buildSEBKeySchema()}
      onSubmit={(values, { resetForm }) => {
        onSubmit(
          sebKey?.id
            ? ({
                id: sebKey.id,
                name: values.name,
                key: values.key,
              } as ResultsAPI.UpdateSEBKey)
            : ({
                name: values.name,
                key: values.key,
              } as ResultsAPI.InsertSEBKey),
        );
        resetForm({ values: { key: '', name: '' } });
      }}
    >
      {formik => (
        <form noValidate onSubmit={formik.handleSubmit}>
          <Modal showCloseButton isOpen={isModalOpen(modalIds.sebKeysModal)} onClose={() => closeModal()}>
            <StyledDialog>
              <StyledInnerDialog>
                <StyledTitle>{title}</StyledTitle>
                {showDescription &&
                  t('testOptionsStep.sebKeys.modal.upsert.description', { appName: getCustomNameByDomain() })}
                <StyledInputsWrapper>
                  {t('testOptionsStep.sebKeys.modal.upsert.name')}
                  <InputWithErrorMessage
                    dataCy="name-input"
                    errorMessage={formik.errors.name}
                    hasError={!!(formik.errors.name && formik.touched.name)}
                    name="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                  />
                  {!formik.touched.name && (
                    <StyledLabel data-cy="name-label">
                      {t('testOptionsStep.sebKeys.modal.upsert.nameLabel')}
                    </StyledLabel>
                  )}
                  <StyledSpacer />
                  {t('testOptionsStep.sebKeys.modal.upsert.key')}
                  <InputWithErrorMessage
                    dataCy="key-input"
                    errorMessage={formik.errors.key}
                    hasError={!!(formik.errors.key && formik.touched.key)}
                    name="key"
                    value={formik.values.key}
                    onChange={formik.handleChange}
                  />
                  {!formik.touched.key && (
                    <StyledLabel data-cy="key-label">{t('testOptionsStep.sebKeys.modal.upsert.keyLabel')}</StyledLabel>
                  )}
                  <StyledSpacer />
                  <StyledButtonWrapper>
                    <Button dataCy="cancel" type="button" variant="default" onClick={() => closeModal()}>
                      {t('testOptionsStep.sebKeys.modal.upsert.cancel')}
                    </Button>
                    <Button dataCy="submit" type="submit" variant="primary">
                      {t('testOptionsStep.sebKeys.modal.upsert.submit')}
                    </Button>
                  </StyledButtonWrapper>
                </StyledInputsWrapper>
              </StyledInnerDialog>
            </StyledDialog>
          </Modal>
        </form>
      )}
    </Formik>
  );
};
