import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormUpdate } from 'pages/TeacherEnvironment/TeacherDashboard/subpages/ScheduledTestWizard/hooks/useFormUpdate';
import { FormStepProps } from '../FormStep.model';
import { FormLabel, FormStep, FormStepHeader, FormStepHeading, FormStepIntro } from '../FormStep.styled';
import { MessageTextArea } from './TestSubmitStep.styled';
import { TestSubmitStepFormValues } from './TestSubmitStep.model';
import { createValidationSchema } from './TestSubmitStep.validation';

export const TestSubmitStep: React.FC<FormStepProps> = ({ formValues, isDisabled = false, onFormUpdate }) => {
  const { t } = useTranslation('scheduled-tests');

  const [values, setValues] = useState<TestSubmitStepFormValues>(
    (({ message = '' }) => ({
      message,
    }))(formValues) as TestSubmitStepFormValues,
  );

  const validationSchema = createValidationSchema();
  useFormUpdate<typeof validationSchema>({
    values,
    validationSchema,
    onFormUpdate,
  });

  const onMessageChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValues({
      ...values,
      message: event.target.value,
    });
  };

  return (
    <FormStep>
      <FormStepHeader>
        <FormStepHeading>{t('testSubmitStep.header', 'Extra support - step 5')}</FormStepHeading>
        <FormStepIntro>{t('testSubmitStep.intro')}</FormStepIntro>
      </FormStepHeader>
      <div>
        <FormLabel htmlFor="test-request-message-input">
          {t('testSubmitStep.messageInput.label', 'Optionally add a message for our support team')}
        </FormLabel>
        <MessageTextArea
          autoFocus
          dataCy="test-request-message-input"
          disabled={isDisabled}
          id="test-request-message-input"
          placeholder={t('testSubmitStep.messageInput.placeholder', 'Enter your questions or requests here')}
          value={values.message}
          onChange={onMessageChange}
        />
      </div>
    </FormStep>
  );
};
