import { ThemeProps } from 'models/styles/theme';
import styled, { css } from 'styled-components/macro';
import { Heading2 } from 'styles/elements/Headings';

const fullPageCss = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 100vh;
`;

export const StyledDownloadReportPage = styled.div`
  --a4-ratio: 1.4142;
  --a4-width: 1330px;
  ${fullPageCss}
  pointer-events: none;
  padding: 50px;
`;

export const StyledPage = styled.div`
  opacity: 0;
  ${fullPageCss}
  > div {
    /** actual a4 page size */
    width: var(--a4-width);
    height: calc(var(--a4-width) * var(--a4-ratio));

    padding: 100px 150px;
  }
`;

export const StyledHeader = styled(Heading2)`
  text-align: center;
`;

export const StyledGraphContainer = styled.div`
  padding: 0 150px;
  margin-bottom: 50px;
  > div {
    padding: 50px 0;
    border-bottom: 1px solid #e0e0e0;
    :last-child {
      border-bottom: none;
    }
  }
`;

export const StyledFooter = styled.div`
  display: block;
  text-align: left;
`;

export const StyledLoader = styled.div<ThemeProps>`
  background: ${({ theme }: ThemeProps) => theme.palette.neutral._200};
  border-radius: 4px;
  padding: 2.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
