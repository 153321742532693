import { Box } from 'styles/helpers/layout';
import React from 'react';
import { useTheme } from 'styled-components/macro';
import { ElearningThemeInterface } from 'themes/elearningInterface.theme';

interface DividerProps {
  // eslint-disable-next-line react/require-default-props
  width?: string;
}

export const Divider = ({ width = '100%' }: DividerProps): JSX.Element => {
  const theme = useTheme() as ElearningThemeInterface;
  return <Box background={theme.palette.neutral._400} height="1px" margin="0 auto" maxWidth={width} />;
};
