import React from 'react';

import { Card } from 'components/Card/Card';
import { EncircledIcon } from 'components/EncircledIcon';

import { CssFlex } from 'styles/helpers/layout';
import { Heading3 } from 'styles/elements/Headings';
import { Paragraph } from 'styles/elements/Texts';

import { StyledCardLayout } from './StatCard.styled';
import { EncircledIconVariant } from 'components/EncircledIcon/EncircledIcon.styled';

export interface StatCardProps {
  className?: string;
  dataCy?: string;
  iconName: string;
  iconVariant?: EncircledIconVariant;
  label: string;
  value: React.ReactNode;
}

export const StatCard: React.FC<StatCardProps> = ({ className, dataCy, iconName, label, value, iconVariant }) => {
  return (
    <Card className={className} layout={{ height: '9rem' }}>
      <StyledCardLayout>
        <EncircledIcon iconName={iconName} variant={iconVariant} />
        <CssFlex flexDirection="column" rowGap={0.3}>
          <Heading3 data-cy={`${dataCy}-value`}>{value}</Heading3>
          <Paragraph>{label}</Paragraph>
        </CssFlex>
      </StyledCardLayout>
    </Card>
  );
};
