import React from 'react';
import { useTranslation } from 'react-i18next';

import { UserByEducations } from 'models/user/User';
import { buildUrlWithPathParams } from '_helpers/utils/urlBuilder';
import { TEACHER_SCHEDULED_TEST_PAGE_ROUTES } from 'constants/routes';

import { Link } from 'components/Link';
import { LoadingSpinner } from 'components/LoadingSpinner';

import noResultsImg from 'assets/images/search_empty.svg';

import { CssFlex } from 'styles/helpers/layout';
import { Paragraph } from 'styles/elements/Texts';
import { StyledResultsContainer, StyledResultList, StyledNoResultsMessage } from './SearchResultsList.styled';

interface SearchResultsListProps {
  isActive: boolean;
  isLoading: boolean;
  results: UserByEducations[];
}

export const SearchResultsList: React.FC<SearchResultsListProps> = ({ isActive, isLoading, results }) => {
  const { t } = useTranslation('teacher-dashboard');

  return (
    <StyledResultsContainer $isActive={isActive} data-cy="student-search-results-container">
      {isLoading ? (
        <CssFlex justifyContent="center" padding="2rem">
          <LoadingSpinner />
        </CssFlex>
      ) : results.length > 0 ? (
        <StyledResultList data-cy="student-search-results-list">
          {results.map(({ id, fullName, groups }) => (
            <li key={id}>
              <Link
                data-cy="student-list-item"
                to={buildUrlWithPathParams(TEACHER_SCHEDULED_TEST_PAGE_ROUTES.SCHEDULED_TESTS_RESULTS_FOR_STUDENT, {
                  accountId: id,
                })}
                variant="default"
              >
                {fullName}
                {groups.length > 0 && <span>{groups.join(', ')}</span>}
              </Link>
            </li>
          ))}
        </StyledResultList>
      ) : (
        <StyledNoResultsMessage data-cy="no-results-found">
          <img alt="No results found" src={noResultsImg} />
          <Paragraph>
            {t(
              'ownedScheduledTestResults.overview.filters.studentSearchBox.noResults',
              "Oops, we couldn't find anything that looks like that!",
            )}
          </Paragraph>
        </StyledNoResultsMessage>
      )}
    </StyledResultsContainer>
  );
};
