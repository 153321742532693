/* eslint-disable @typescript-eslint/no-use-before-define */
import { action, observable } from 'mobx';
import { resultsRepository } from 'repositories';
import { ExercisePerSubjectResults, ExercisesOverTimeResults } from 'models/progress/Progress';
import { RecentProgress } from 'models/results/Results';

interface ProductResultsStoreInterface {
  getExercisePerSubjectResults: (productId: number) => void;
  getRecentProgress: (productId: number) => void;
  getExercisesOverTimeResults: (productId: number) => void;
  setExercisePerSubjectResultsFetchException: (error: Error) => void;
  setExercisesOverTimeResultsFetchException: (error: Error) => void;
  setExercisePerSubjectResults: (progress: ExercisePerSubjectResults[]) => void;
  setRecentProgress: (RecentProgress: RecentProgress) => void;
  exercisePerSubjectResults: ExercisePerSubjectResults[] | null;
  setExercisesOverTimeResults: (progress: ExercisesOverTimeResults[]) => void;
  exercisesOverTimeResults: ExercisesOverTimeResults[] | null;
  recentProgress: RecentProgress | null;
  exercisePerSubjectResultsFetchException?: Error;
  exercisesOverTimeResultsFetchException?: Error;
}

// initial state should be undefined because they are not loaded yet...
const initialState = {
  exercisePerSubjectResults: null,
  exercisesOverTimeResults: null,
};

const stateSetters = {
  setExercisePerSubjectResultsFetchException: action((error: Error) => {
    store.exercisePerSubjectResultsFetchException = error;
  }),
  setExercisesOverTimeResultsFetchException: action((error: Error) => {
    store.exercisesOverTimeResultsFetchException = error;
  }),
  setExercisePerSubjectResults: action((progress: ExercisePerSubjectResults[]) => {
    store.exercisePerSubjectResults = progress;
  }),
  setExercisesOverTimeResults: action((progress: ExercisesOverTimeResults[]) => {
    store.exercisesOverTimeResults = progress;
  }),
  setRecentProgress: action((recentProgress: RecentProgress) => {
    store.recentProgress = recentProgress;
  }),
};

const apiRequests = {
  getExercisePerSubjectResults: action((productId: number) => {
    resultsRepository
      .fetchExercisePerSubjectResults(productId)
      .then((exercisePerSubjectResults: ExercisePerSubjectResults[]) => {
        store.setExercisePerSubjectResults(exercisePerSubjectResults);
      })
      .catch(error => {
        store.setExercisePerSubjectResultsFetchException(error);
      });
  }),
  getExercisesOverTimeResults: action((productId: number) => {
    resultsRepository
      .fetchExercisesOverTimeResults(productId)
      .then((exercisesOverTimeResults: ExercisesOverTimeResults[]) => {
        store.setExercisesOverTimeResults(exercisesOverTimeResults);
      })
      .catch(error => {
        store.setExercisesOverTimeResultsFetchException(error);
      });
  }),
  getRecentProgress: action((productId: number) => {
    resultsRepository.getRecentProgress(productId).then((recentProgress: RecentProgress) => {
      store.setRecentProgress(recentProgress);
    });
  }),
};

const store = observable({
  ...initialState,
  ...stateSetters,
  ...apiRequests,
} as ProductResultsStoreInterface);

export const useProductResults = (): ProductResultsStoreInterface => store;
