import styled from 'styled-components/macro';
import { ThemeProps as Props } from 'models/styles/theme';

export const StyledNoticeListItem = styled.li<Props>`
  padding: 12px 35px;
  background-color: ${(props: Props) => props.theme.palette.feedback.inProgress._200};
  margin-bottom: 12px;
  list-style-type: none;
  &:before {
    counter-increment: OrderedListItemsCounter;
    content: counter(OrderedListItemsCounter) '.';
    margin-right: 0.5em;
    font-weight: 700;
  }
`;

export const StyleOrderedList = styled.ol<Props>`
  counter-reset: OrderedListItemsCounter;
`;

export const StyledButtonContainer = styled.div`
  text-align: right;
`;
