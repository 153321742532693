/* eslint-disable @typescript-eslint/no-unsafe-call */
import { FontAwesomeIcon } from 'components/FontAwesomeIcon';
import React, { FC } from 'react';
import { useTheme } from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { ResultsAPI } from 'generated/types';
import { StyledHeading3 } from '../../WritingResults.styled';
import { StyledFontAwesomeIcon, StyledTable, StyledTableRow } from './WritingStudentsResultsOverview.styled';
import { buildUrlWithPathParams } from '_helpers/utils/urlBuilder';
import { TEACHER_INSIGHT_PAGE_ROUTES } from 'constants/routes';
import { useHistory } from 'react-router-dom';
import { AbsoluteToolTip } from 'components/AbsoluteToolTip/AbsoluteToolTip';
import { useDomainHandler } from 'hooks/useDomainHandler';
import { ElearningThemeInterface } from 'themes/elearningInterface.theme';
import { Card } from 'components/Card/Card';

export interface WritingStudentsResultsOverviewProps {
  students?: ResultsAPI.WritingStudentsDetails[];
  studentCount: number | undefined;
  productId: number;
}

export const WritingStudentsResultsOverview: FC<WritingStudentsResultsOverviewProps> = ({
  students = [],
  studentCount,
  productId,
}) => {
  const { t } = useTranslation('teacher-dashboard');
  const history = useHistory();
  const { getStudentTermByDomain } = useDomainHandler();
  const hogesTheme = useTheme() as ElearningThemeInterface;

  return (
    <Card layout={{ mt: 2, p: 0 }}>
      {studentCount === undefined ? (
        <FontAwesomeIcon iconName="fas fa-spinner fa-spin" />
      ) : (
        <StyledHeading3 p={2}>
          {t('writingResults.student.title', {
            studentCount,
            studentTerm: getStudentTermByDomain({ usePlural: true }),
          })}
        </StyledHeading3>
      )}
      <StyledTable>
        <thead>
          <tr>
            <th />
            <th>{t('writingResults.student.name')}</th>
            <th>{t('writingResults.student.subjectsFinished')}</th>
            <th>{t('writingResults.student.exercisesFinished')}</th>
            <th>{t('writingResults.student.casesFinished')}</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {students.map(student => (
            <StyledTableRow
              key={student.id}
              $isClickable={student.totalExercises !== 0}
              data-cy={`writing-results-for-account-${student.id}`}
              onClick={() => {
                if (student.totalExercises !== 0) {
                  history.push(
                    buildUrlWithPathParams(TEACHER_INSIGHT_PAGE_ROUTES.WRITING_RESULTS_FOR_ACCOUNT, {
                      productId,
                      accountId: student.id,
                    }),
                  );
                }
              }}
            >
              <td>
                {student.totalExercises === 0 && (
                  <AbsoluteToolTip text={t('writingResults.noResultsFound', { studentTerm: getStudentTermByDomain() })}>
                    <StyledFontAwesomeIcon
                      color={hogesTheme.palette.feedback.inProgress.brand}
                      data-cy={`tooltip-${student.id}`}
                      iconName="fal fa-exclamation-circle"
                      iconSize="2x"
                    />
                  </AbsoluteToolTip>
                )}
              </td>
              <td data-cy={`td-name-${student.firstName}-${student.lastName}`}>
                {`${student.lastName}, ${student.firstName} ${
                  student.middleName ? student.middleName.split('')[0] : ''
                }`}
              </td>
              <td>{`${student.subjectsCompleted}/${student.totalSubjects}`}</td>
              <td>{`${student.exercisesCompleted}/${student.totalExercises}`}</td>
              <td>{`${student.casesCompleted}/${student.totalCases}`}</td>
              <td>
                {student.totalExercises !== 0 && (
                  <FontAwesomeIcon
                    data-cy={`writing-results-arrow-for-account-${student.id}`}
                    iconName="fas fa-chevron-right"
                  />
                )}
              </td>
            </StyledTableRow>
          ))}
        </tbody>
      </StyledTable>
    </Card>
  );
};
