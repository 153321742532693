export type TooltipYPosition = 'top' | 'bottom';
export type TooltipXPosition = 'left' | 'center' | 'right';
export type TooltipPosition = {
  x: TooltipXPosition;
  y: TooltipYPosition;
};

export type Position = { x: number; y: number };

export type TooltipCallbackFunction = (position: Position, calculatedPosition: TooltipPosition) => void;

type TooltipCleanupFunction = () => void;

// helper function that takes two elements (hoverElement and tooltipElement)
// and on every 'frame' calculates the desired position of the tooltipElement
export const tooltipPosition = (
  {
    target,
    tooltip,
    preferredPosition,
    container,
  }: {
    target: HTMLElement;
    tooltip: HTMLElement;
    preferredPosition: TooltipPosition;
    container?: HTMLElement;
  },
  callback: TooltipCallbackFunction,
): TooltipCleanupFunction => {
  let isEnabled = true;
  let lastPosition: Position | null = null;

  const animationFrame = () => {
    if (!isEnabled) {
      lastPosition = null;
      return;
    }

    const calculatedPosition = preferredPosition;
    const containerHeight = container ? container.clientHeight : window.innerHeight;
    const containerWidth = container ? container.clientWidth : window.innerWidth;
    const top = target.offsetTop - tooltip.clientHeight;
    const bottom = target.offsetTop + target.clientHeight;
    const left = target.offsetLeft - tooltip.clientWidth + target.clientWidth;
    const center = target.offsetLeft - (tooltip.clientWidth - target.clientWidth) / 2;
    const right = target.offsetLeft;

    let position: Position | null = null;
    const preferredPositionStr = `${preferredPosition.y}-${preferredPosition.x}`;
    switch (preferredPositionStr) {
      case 'top-left':
        position = { x: left, y: top };
        break;
      case 'top-center':
        position = { x: center, y: top };
        break;
      case 'top-right':
        position = { x: right, y: top };
        break;
      case 'bottom-left':
        position = { x: left, y: bottom };
        break;
      case 'bottom-center':
        position = { x: center, y: bottom };
        break;
      case 'bottom-right':
        position = { x: right, y: bottom };
        break;
      default:
        break;
    }

    if (position) {
      if (position.x + tooltip.clientWidth > containerWidth) {
        position.x = left;
        calculatedPosition.x = 'left';
      } else if (position.x - tooltip.clientWidth <= 0) {
        position.x = right;
        calculatedPosition.x = 'right';
      }

      if (position.y - tooltip.clientHeight <= 0) {
        position.y = bottom;
        calculatedPosition.y = 'bottom';
      } else if (position.y + tooltip.clientHeight > containerHeight) {
        position.y = top;
        calculatedPosition.y = 'top';
      }

      if (position.x !== lastPosition?.x || position.y !== lastPosition?.y) {
        callback(position, calculatedPosition);
        lastPosition = position;
      }
    }

    requestAnimationFrame(animationFrame);
  };

  requestAnimationFrame(animationFrame);

  return () => {
    // tooltip cleanup function
    isEnabled = false;
    lastPosition = null;
  };
};
