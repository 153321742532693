import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { format } from 'date-fns';

import {
  ResponseScheduledTestsCertificatesResults,
  ScheduledTestsCertificatesResultsData,
} from 'models/results/Results';

import { Button } from 'components/Button';
import { SvgIconReact } from 'components/SvgIconReact';
import { DownloadcertificatePage } from '../DownloadCertificatePage/DownloadCertificatePage';
import { StyledCertificatesTable, StyledTableContainer } from './CertificatesStudentsTable.styled';

interface CertificateStudentsTableProps {
  certificates: ResponseScheduledTestsCertificatesResults;
  selectedResults: number;
}

export const CertificateStudentsTable: React.FC<CertificateStudentsTableProps> = observer(
  ({ certificates, selectedResults }) => {
    const { t } = useTranslation('results');
    const [isDownloadingReport, setIsDownloadingReport] = useState<boolean>(false);
    const [filteredCertificateData, setCertificateData] = useState<ScheduledTestsCertificatesResultsData[]>([]);

    const tableCertificateText = t('certificatesTestPage.testResultCertificatesTable.certificateText', 'Certificate');
    const hasData = certificates.data?.length > 0;
    const selectedItem = (id: string) => certificates.data.filter(item => item.id === id);

    const handleDownloadCertificate = (event?: React.MouseEvent<HTMLButtonElement>) => {
      setIsDownloadingReport(!isDownloadingReport);

      if (event) {
        const elementId = event.currentTarget && event.currentTarget.parentElement?.id;
        const filterElements = elementId?.length && selectedItem(elementId);
        setCertificateData(filterElements as ScheduledTestsCertificatesResultsData[]);
      }
    };

    const headerCells = [
      {
        id: 'date',
        title: t('certificatesTestPage.testResultCertificatesTable.columns.date', 'Date'),
        size: 'auto',
      },
      {
        id: 'skill-level',
        title: t('certificatesTestPage.testResultCertificatesTable.columns.skillLevel', 'Level'),
        size: 'auto',
      },
      {
        id: 'certifiate-type',
        title: t('certificatesTestPage.testResultCertificatesTable.columns.certificateType', 'Type'),
        size: 'auto',
      },
      {
        id: 'test-title',
        title: t('certificatesTestPage.testResultCertificatesTable.columns.testTitle', 'Title'),
        size: 'auto',
      },
    ];

    const rowData = useMemo(
      () =>
        hasData &&
        certificates.data?.map(
          ({
            day,
            skillLevel,
            certificateType = tableCertificateText,
            title,
            id,
          }: ScheduledTestsCertificatesResultsData) => [
            {
              id,
              dataCy: 'item-date',
              content: format(new Date(day), 'dd-MM-Y'),
            },
            {
              id,
              dataCy: 'item-skill-level',
              content: skillLevel,
            },
            {
              id,
              dataCy: 'item-type',
              content: certificateType,
            },
            {
              id,
              dataCy: 'item-title',
              content: title,
            },
            {
              id,
              dataCy: 'item-download',
              content: (
                <Button
                  data-cy={`item-${day}-download-certificate`}
                  fontColor="black"
                  isLoading={isDownloadingReport}
                  variant="default"
                  onClick={handleDownloadCertificate}
                >
                  <SvgIconReact iconName="downloadIcon" size={1} />
                </Button>
              ),
            },
          ],
        ),
      [certificates.data],
    );

    return (
      <StyledTableContainer>
        <StyledCertificatesTable>
          <thead>
            <tr>
              {headerCells.map(({ id, title }) => (
                <th key={id}>{title}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rowData ? (
              rowData.slice(0, selectedResults).map((items, index) => (
                <tr key={`row-${index}`}>
                  {items.map(({ id, dataCy, content }) => (
                    <td key={dataCy} data-cy={dataCy} id={id}>
                      {content}
                    </td>
                  ))}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={4}>{t('noResults.message', 'No results')}</td>
              </tr>
            )}
          </tbody>
        </StyledCertificatesTable>
        {isDownloadingReport &&
          filteredCertificateData.length &&
          filteredCertificateData.map(
            ({ firstName, middleName, lastName, schoolNumber, skillLevel, id, day, productId }) => (
              <DownloadcertificatePage
                key={id}
                date={day}
                firstName={firstName}
                id={id}
                lastName={lastName}
                middleName={middleName}
                productId={+productId}
                schoolNumber={schoolNumber}
                skillLevel={skillLevel}
                onDone={handleDownloadCertificate}
              />
            ),
          )}
      </StyledTableContainer>
    );
  },
);
