import { FontAwesomeIcon } from 'components/FontAwesomeIcon';
import styled from 'styled-components/macro';
import { ElearningThemeInterface } from 'themes/elearningInterface.theme';

interface Props {
  theme: ElearningThemeInterface;
}

export interface AdditionalTimeProps extends Props {
  $visible: boolean;
}

export interface TestProps extends Props {
  even: boolean;
}

export interface ProgressIndicatorProps extends Props {
  percentage: number;
}

export interface LastCompletedTestsProps extends Props {
  $width: string;
}

export const StyledLastCompletedTests = styled.div<LastCompletedTestsProps>`
  width: ${({ $width }: LastCompletedTestsProps) => $width};
  border: 1px solid ${({ theme }: Props) => theme.palette.neutral._400};
  text-align: left;

  > h3 {
    padding: 1rem;
    font-weight: 400;
  }

  > button {
    margin: 1rem;
  }
`;

export const StyledTest = styled.div<TestProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
  background: ${({ even, theme }: TestProps) => (even ? theme.palette.neutral._100 : theme.palette.neutral.white)};

  > span {
    :first-child {
      padding-left: 1rem;
    }
  }

  > * {
    :first-child {
      width: 40%;
    }
    :nth-child(2) {
      width: 35%;
      text-align: center;
    }
    :nth-child(3) {
      width: 7%;
    }
    :nth-child(4) {
      width: 7%;
    }
    :last-child {
      width: 5%;
      padding-right: 1rem;
    }
  }
`;

export const StyledProgressIndicator = styled.span<ProgressIndicatorProps>`
  border-radius: 0.5rem;
  width: 1.3rem;
  height: 0.6rem;
  background: ${({ percentage, theme }: ProgressIndicatorProps) => {
    if (percentage < 50) return theme.palette.feedback.error.brand;
    if (percentage === 50) return theme.palette.feedback.inProgress.brand;
    return theme.palette.feedback.success.brand;
  }};
`;

export const StyledAdditionalTime = styled.span<AdditionalTimeProps>`
  visibility: ${({ $visible }: AdditionalTimeProps) => ($visible ? 'visible' : 'hidden')};
  color: ${({ theme }: Props) => theme.palette.neutral.black};
  background: ${({ theme }: Props) => theme.palette.neutral._400};
  padding: 0.3rem;
  border-radius: 5rem;
`;

export const StyledFontAwesomeIcon = styled(FontAwesomeIcon)<Props>`
  cursor: pointer;
`;
