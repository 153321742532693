import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDomainHandler } from 'hooks/useDomainHandler';
import { Heading2 } from 'styles/elements/Headings';
import { StyledHeading3, StyledInnerWrapper, StyledWrapper } from './InviteStudent.styled';

export const InviteStudent: React.FC = () => {
  const { t } = useTranslation('teacher-dashboard');
  const { getStudentTermByDomain } = useDomainHandler();

  return (
    <StyledWrapper>
      <Heading2>{t('studentLicense.inviteStudents.title')}</Heading2>
      <StyledInnerWrapper>
        <StyledHeading3>
          {t('studentLicense.inviteStudents.description.introduction', {
            studentTerm: getStudentTermByDomain({ usePlural: true }),
          })}
        </StyledHeading3>
        <StyledHeading3>
          {t('studentLicense.inviteStudents.description.middle', {
            studentTerm: getStudentTermByDomain({ usePlural: true }),
          })}
        </StyledHeading3>
        <StyledHeading3>{t('studentLicense.inviteStudents.description.end')}</StyledHeading3>
      </StyledInnerWrapper>
    </StyledWrapper>
  );
};
