import React, { FC, useEffect } from 'react';
import { Formik } from 'formik';
import { observer } from 'mobx-react';
import { AUTH_PAGES_ROUTES } from 'constants/routes';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useRegistration } from 'hooks/useRegistration';
import { Heading4 } from 'styles/elements/Headings';
import { StyledField } from 'pages/Auth/MigrateAccessPage/MigrateAccessPage.styled';
import { InputWithErrorMessage } from 'components/InputWithErrorMessage';
import { Button } from 'components/Button';
import { StyledButtonWrapper } from './CreateAccount.styled';
import { StyledInnerForm, StyledParagraph } from '../../InvitationPage.styled';
import { buildCreateAccountSchema } from './CreateAccount.schema';
import { CreateAccountParams } from './CreateAccount.model';

export const CreateAccount: FC = observer(() => {
  const { t } = useTranslation('auth-pages');
  const { registeredEmail, createRegistration } = useRegistration();
  const history = useHistory();
  const { invitationId }: CreateAccountParams = useParams();

  useEffect(() => {
    if (registeredEmail) {
      history.push(AUTH_PAGES_ROUTES.REGISTERED_INVITATION);
    }
  }, [registeredEmail]);

  return (
    <StyledInnerForm>
      <Heading4>{t('invitationPage.createAccount.title')}</Heading4>
      <StyledParagraph>{t('invitationPage.createAccount.description')}</StyledParagraph>
      <Formik
        enableReinitialize
        initialValues={{
          firstName: '',
          middleName: '',
          lastName: '',
        }}
        validationSchema={buildCreateAccountSchema()}
        onSubmit={values => createRegistration(invitationId, values)}
      >
        {formik => (
          <form noValidate onSubmit={formik.handleSubmit}>
            <StyledField>
              <label htmlFor="firstName">{t('invitationPage.createAccount.fields.firstName', 'First Name')}</label>
              <InputWithErrorMessage
                dataCy="firstname-input"
                errorMessage={formik.errors.firstName}
                hasError={!!(formik.errors.firstName && formik.touched.firstName)}
                name="firstName"
                value={formik.values.firstName}
                onChange={formik.handleChange}
              />
            </StyledField>
            <StyledField>
              <label htmlFor="middleName">{t('invitationPage.createAccount.fields.middleName', 'Middle Name')}</label>
              <InputWithErrorMessage
                dataCy="middlename-input"
                errorMessage={formik.errors.middleName}
                hasError={!!(formik.errors.middleName && formik.touched.middleName)}
                name="middleName"
                value={formik.values.middleName}
                onChange={formik.handleChange}
              />
            </StyledField>
            <StyledField>
              <label htmlFor="lastName">{t('invitationPage.createAccount.fields.lastName', 'Last Name')}</label>
              <InputWithErrorMessage
                dataCy="lastname-input"
                errorMessage={formik.errors.lastName}
                hasError={!!(formik.errors.lastName && formik.touched.lastName)}
                name="lastName"
                value={formik.values.lastName}
                onChange={formik.handleChange}
              />
            </StyledField>
            <StyledButtonWrapper>
              <Button dataCy="submit-new-account" type="submit" variant="brand">
                {t('invitationPage.createAccount.action')}
              </Button>
            </StyledButtonWrapper>
          </form>
        )}
      </Formik>
    </StyledInnerForm>
  );
});
