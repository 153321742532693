import styled from 'styled-components/macro';
import { ThemeProps as Props } from 'models/styles/theme';
import { Alignment } from './CustomReviewDate.model';

export interface CustomReviewDateRowStyle extends Props {
  alignment: Alignment;
}

export const CustomReviewDateForm = styled.section`
  border-top: 1px solid ${(props: Props) => props.theme.palette.neutral._900};
  margin: 3rem 0 1rem 0;
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
`;

export const CustomReviewDateRow = styled.div<CustomReviewDateRowStyle>`
  ${({ alignment }: CustomReviewDateRowStyle) =>
    alignment === 'horizontal'
      ? `  display: grid;
  grid-column-gap: 1rem;
  grid-template-columns: 1fr auto 1fr;`
      : 'display: block'}
`;

export const CustomReviewDateColumn = styled.div`
  display: grid;
  grid-column-gap: 1rem;
  grid-template-columns: 2fr 1fr;
`;

export const InputSeparator = styled.div`
  display: flex;
  margin-top: 2rem;
  align-items: center;
  justify-content: center;
  font-weight: ${({ theme }: Props) => theme.font.weight.bold};
`;
