import styled from 'styled-components/macro';
import { ThemeProps } from 'models/styles/theme';

import { Modal } from 'components/Modal';
import { ModalProps } from 'components/Modal/Modal.model';

import { Heading2 } from 'styles/elements/Headings';
import { Paragraph } from 'styles/elements/Texts';
import { calculateRem } from 'styles/mixins/calculateRem';

export const StyledTooltipModal = styled(Modal)<ModalProps>`
  & > div {
    align-items: flex-start;
  }
`;

export const StyledHeading = styled(Heading2)`
  line-height: ${calculateRem(39)};
  text-align: left;
`;

export const StyledParagraph = styled(Paragraph)<ThemeProps>`
  line-height: ${calculateRem(23)};
  font-size: ${(props: ThemeProps) => props.theme.font.size._14};
  text-align: left;
`;
