import React from 'react';
import { useTranslation } from 'react-i18next';

import { SvgIconReact } from 'components/SvgIconReact';
import { StyledSearchBox, StyledInput } from './SearchBox.styled';
import { SearchBoxProps } from './SearchBox.model';

const MySearchBox = (
  { className, dataCy, name, placeholder, value, onBlur, onChange, onEnter, onFocus }: SearchBoxProps,
  ref?: React.Ref<HTMLDivElement>,
) => {
  const { t } = useTranslation('common');
  return (
    <StyledSearchBox ref={ref} className={className}>
      <SvgIconReact iconName="searchIcon" />
      <StyledInput
        dataCy={dataCy || 'search-box'}
        name={name || 'search-box'}
        placeholder={placeholder || t('searchBox.placeholder')}
        value={value}
        onBlur={e => onBlur && onBlur(e.target.value)}
        onChange={e => onChange && onChange(e.target.value)}
        onFocus={e => onFocus && onFocus(e.target.value)}
        onKeyUp={e => e.key === 'Enter' && onEnter && onEnter()}
      />
    </StyledSearchBox>
  );
};

export const SearchBox = React.forwardRef<HTMLDivElement, SearchBoxProps>(MySearchBox);
