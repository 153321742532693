import React, { useMemo, useEffect, useState, ReactNode } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { QuestionStatus } from 'constants/exam-constants';
import { AnswerOption } from 'models/exam/Exam';
import { sortAnswerOptions } from '_helpers/questionHelper';

import { EmbeddedHtml } from 'components/EmbeddedHtml';
import { Node } from 'components/EmbeddedHtml/EmbeddedHtml.model';
import { commonSanitizeOptions } from 'layouts/QuestionManager/QuestionManager.constants';
import { DragAndDropQuestionProps, SelectedAnswerType } from './DragAndDropQuestion.model';
import {
  StyledDragAndDropQuestion,
  StyledAnswerField,
  StyledDragAndDropList,
  StyledDragAndDropOption,
} from './DragAndDropQuestion.styled';
import { QuestionsManagerPathParams } from '../../QuestionManager.model';
import { useAnswer } from 'hooks/useAnswer';

const sanitizeOptions = {
  ...commonSanitizeOptions,
  allowedAttributes: {
    ...commonSanitizeOptions.allowedAttributes,
    span: ['data-name'],
  },
};

export const DragAndDropQuestion: React.FC<DragAndDropQuestionProps> = ({
  answerFeedbackComponent: answerComponent,
  evaluateAnswer,
  status = QuestionStatus.INITIAL,
  answerOptions,
  question,
  results,
  isEvaluatingAnswer,
  prevAnswerValues,
}) => {
  const { t } = useTranslation('skill-determination-test');
  const [selectedAnswer, setSelectedAnswer] = useState<SelectedAnswerType[]>([]);
  const [draggedAnswer, selectDraggedAnswer] = useState('');
  const { productId, exerciseId }: QuestionsManagerPathParams = useParams();
  const setExerciseIdValue = !exerciseId ? undefined : +exerciseId;
  const parsedQuestionHtml = useMemo<string>(
    () => question.content.replaceAll('[hier]', '<span data-name="answer-field"></span>'),
    [question.content],
  );
  const { setAnswerButtonProps } = useAnswer();

  const orderedAnswerOptions = useMemo(() => sortAnswerOptions(answerOptions), [answerOptions]);

  const submitAnswer = (skipAnswerValidation?: boolean) => {
    evaluateAnswer &&
      evaluateAnswer(
        +productId,
        question.id,
        selectedAnswer.map((answer, index) => ({ order: index, value: answer.id })),
        setExerciseIdValue,
        skipAnswerValidation,
      );
  };

  // Set configuration for answer button
  useEffect(() => {
    setAnswerButtonProps({
      label: t('button.checkAnswer.label', 'Check answer'),
      isDisabled: selectedAnswer.length === 0 || selectedAnswer[0]?.content === '',
      isHidden: status !== QuestionStatus.INITIAL,
      isLoading: isEvaluatingAnswer || false,
      onClick: submitAnswer,
    });
  }, [isEvaluatingAnswer, selectedAnswer, status]);

  // Reset selected answer when question changes
  useEffect(() => {
    setSelectedAnswer([]);
  }, [question]);

  useEffect(() => {
    if (selectedAnswer.length === 0) {
      return;
    }
    const onKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        submitAnswer();
      }
    };
    document.addEventListener('keypress', onKeyPress);
    // eslint-disable-next-line consistent-return
    return () => document.removeEventListener('keypress', onKeyPress);
  }, [selectedAnswer]);

  const getAnswerData = () => {
    const answer = answerOptions.find((option: AnswerOption) => option.content === draggedAnswer);
    if (answer) setSelectedAnswer([answer]);
  };

  const getAnswerStatusClass = (answerId: number | string): string => {
    // Verify if current option exists among the valid answers
    const answerResult = results?.find(result =>
      result.validAnswers?.find(answer => answer.value.toString() === answerId.toString()),
    );

    if (
      status === QuestionStatus.INCORRECT &&
      answerResult?.validAnswers?.some(validAnswer => validAnswer.value.toString() === answerId.toString())
    ) {
      return 'success';
    }

    if (answerId.toString() !== selectedAnswer[0]?.id.toString() || status === QuestionStatus.INITIAL) {
      return '';
    }

    return status === QuestionStatus.CORRECT ? 'success' : 'error';
  };

  const replaceFunction = ({ tagType, attributes }: Node): ReactNode | undefined => {
    if (tagType === 'span' && attributes['data-name'] === 'answer-field') {
      return (
        <StyledAnswerField
          data-cy={`answer-field-${question.id}`}
          dropAnswer={!!selectedAnswer[0]?.content}
          status={status}
          onDragOver={e => e.preventDefault()}
          onDrop={() => getAnswerData()}
        >
          {selectedAnswer[0]?.content || t('button.drop.placeholder', 'Drop here')}
        </StyledAnswerField>
      );
    }
    return undefined;
  };

  const prevSelectedAnswerId = (answerId: number | string) =>
    prevAnswerValues?.find(({ value }) => +value === +answerId)?.value;

  return (
    <StyledDragAndDropQuestion status={status}>
      <EmbeddedHtml
        rawHtml={parsedQuestionHtml}
        replaceFunction={replaceFunction}
        sanitizeOptions={sanitizeOptions}
        tagName="section"
      />
      <StyledDragAndDropList>
        {orderedAnswerOptions.map(({ id: answerId, content }: AnswerOption, index: number) => (
          <StyledDragAndDropOption
            key={answerId}
            draggable
            className={
              prevSelectedAnswerId(answerId) === answerId.toString() && selectedAnswer.length === 0
                ? 'submitted'
                : getAnswerStatusClass(answerId)
            }
            data-cy={`drag-and-drop-answer-${index}`}
            onDragEnd={() => selectDraggedAnswer('')}
            onDragOver={e => e.preventDefault()}
            onDragStart={e => {
              const answer = e.target as HTMLElement;
              selectDraggedAnswer(answer.innerText.trim());
            }}
          >
            <EmbeddedHtml rawHtml={content} tagName="section" />
          </StyledDragAndDropOption>
        ))}
      </StyledDragAndDropList>
      {answerComponent}
    </StyledDragAndDropQuestion>
  );
};
