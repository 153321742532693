// teacher env specific navigation menu
import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import { NavigationMenu } from 'components/NavigationMenu/NavigationMenu';
import { ADMIN_ENVIRONMENT_ROUTES } from 'constants/routes';

interface ComponentProps {
  className?: string;
}

export const AdminNavigationMenu: React.FC<ComponentProps> = observer(({ className }) => {
  const { t } = useTranslation('admin-environment');

  const menuItems = useMemo(
    () => [
      {
        id: 'admin-users',
        dataCy: 'admin-users',
        expandedByDefault: true,
        title: t('adminSideMenu.users.title', 'Users'),
        redirectPath: ADMIN_ENVIRONMENT_ROUTES.USERS_PAGE,
      },
      {
        id: 'admin-scheduled-test',
        dataCy: 'admin-scheduled-test',
        expandedByDefault: true,
        title: t('adminSideMenu.scheduled.title', 'Scheduled Tests'),
        redirectPath: `${ADMIN_ENVIRONMENT_ROUTES.USER_SCHEDULED_TESTS}`,
      },
      {
        id: 'admin-tools',
        dataCy: 'admin-tools',
        expandedByDefault: true,
        title: t('adminSideMenu.tools.title', 'Tools'),
        redirectPath: `${ADMIN_ENVIRONMENT_ROUTES.USER_TOOLS}`,
      },
    ],
    [t],
  );

  return <NavigationMenu className={className} menuItems={menuItems} />;
});
