import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';

import { AUTH_PAGES_ROUTES } from 'constants/routes';

import { AccessPageLayout } from 'layouts/AccessPageLayout';

import { redirectToIdpLogin } from '_helpers/utils/idp';
import { useLanguage } from 'hooks/useSelectedLanguage';
import { useFeatureFlags } from 'hooks/useFeatureFlags';

import { Heading4 } from 'styles/elements/Headings';

import {
  StyledButton,
  StyledLanguageSelector,
  StyledSection,
  StyledSeparator,
  StyledMigrationNotice,
} from './SignInPage.styled';
import { Lang } from 'models/language/Language';
import { ToastMethods } from 'components/ToastNotification';

export const SignInPage: React.FC = () => {
  const { t } = useTranslation('auth-pages');
  const history = useHistory();
  const location = useLocation();
  const { enableMigrateLegacyAccount } = useFeatureFlags();
  const { currentLanguage, changeLanguage } = useLanguage();
  const [language, setLanguage] = useState(currentLanguage);

  const queryParamsPath = queryString.parse(location.search);

  useEffect(() => {
    if (queryParamsPath.error === 'true') {
      ToastMethods.showToast(t('loginPage.toast.idpLoginError'), 'error');
    }

    // If we got unverified teacher error, redirect to access denied page
    if (queryParamsPath.state === AUTH_PAGES_ROUTES.ACCESS_DENIED) {
      history.replace(AUTH_PAGES_ROUTES.ACCESS_DENIED);
    }
  }, [queryParamsPath]);

  const onLanguageSelect = (selectedLanguage: keyof Lang) => {
    setLanguage(selectedLanguage);
    // update immediately after selecting
    changeLanguage(selectedLanguage);
  };

  return (
    <AccessPageLayout>
      <StyledLanguageSelector
        selectedLanguage={language}
        selectLanguageLabel={t('loginPage.languageSelector.label', 'Site language:')}
        onChangeLanguage={onLanguageSelect}
      />

      {enableMigrateLegacyAccount && (
        <StyledMigrationNotice>
          {t(
            'loginPage.migrationNotice.description',
            `From now on you can only log in with us with a new Noordhoff account.
            Do you still have an old account?
            Transfer it to the new account within 1 minute.`,
          )}
        </StyledMigrationNotice>
      )}

      <StyledSection>
        <Heading4>
          {enableMigrateLegacyAccount
            ? t('loginPage.migratedAccount.title', 'Already have a converted account?')
            : t('loginPage.existingAccount.title', 'Already have an account?')}
        </Heading4>
        <StyledButton
          isBlock
          dataCy="idp-login-button"
          variant="primary"
          onClick={() => redirectToIdpLogin(queryParamsPath)}
        >
          {enableMigrateLegacyAccount
            ? t('loginPage.migratedAccount.button', 'Login')
            : t('loginPage.existingAccount.button', 'Login')}
        </StyledButton>
      </StyledSection>

      {enableMigrateLegacyAccount && (
        <StyledSection>
          <Heading4>{t('loginPage.nonMigratedAccount.title', 'Existing account not converted yet?')}</Heading4>
          <StyledButton
            isBlock
            dataCy="legacy-login-button"
            variant="primary"
            onClick={() =>
              history.push({
                pathname: AUTH_PAGES_ROUTES.MIGRATE_ACCESS,
                search: location.search,
              })
            }
          >
            {t('loginPage.nonMigratedAccount.button', 'Migrate account')}
          </StyledButton>
        </StyledSection>
      )}

      <StyledSeparator>{t('loginPage.separator.text', 'or')}</StyledSeparator>

      <StyledSection>
        <Heading4>{t('loginPage.noAccount.title', 'No account yet?')}</Heading4>
        <StyledButton
          isBlock
          dataCy="register-button"
          variant="primary"
          onClick={() => history.push(AUTH_PAGES_ROUTES.REGISTER_ACCOUNT)}
        >
          {t('loginPage.noAccount.button', 'Create account')}
        </StyledButton>
      </StyledSection>
    </AccessPageLayout>
  );
};
