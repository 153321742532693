import styled from 'styled-components/macro';
import { Box } from 'styles/helpers/layout';
import { ThemeProps as Props } from 'models/styles/theme';
import { Paragraph } from 'styles/elements/Texts';

export interface StyledSubTitleProps extends Props {
  variant?: 'thin' | 'thick';
}

export const StyledCard = styled(Box)<Props>`
  background: #fdfdfd;
  border: 1px solid ${(props: Props) => props.theme.palette.neutral._400};
  border-radius: 4px;
`;

export const StyledTitle = styled(Paragraph)`
  color: ${({ theme }: Props) => theme.palette.neutral._900};
  font-weight: 700;
`;

export const StyledSubTitle = styled(Paragraph)<StyledSubTitleProps>`
  color: ${({ theme }: StyledSubTitleProps) => theme.palette.neutral.black};
  font-weight: ${({ variant }: StyledSubTitleProps) => (variant === 'thick' ? '700' : '400')};
`;

export const StyledHr = styled.hr<Props>`
  color: ${({ theme }: Props) => theme.palette.neutral.black};
`;
