import React from 'react';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

import { FooterConstants } from 'footer-utils';
import { useDomainHandler } from 'hooks/useDomainHandler';

import { Link } from 'components/Link';
import { SeparatorLine } from 'components/SeparatorLine';
import { CssFlex } from 'styles/helpers/layout';

import {
  StyledFooter,
  StyledLinks,
  StyledGroupLinkTitle,
  StyledLinkItem,
  HstStyledLogoFooter,
  NoordhoffStyledLogoFooter,
  StyledCopyrightBottomBar,
} from './Footer.styled';

import { LinkProp, FooterLinks } from './Footer.model';

const linkRender = (link: LinkProp, index: number, t: TFunction) => (
  <Link key={index} external={link.external} size="small" to={link.url} variant="default">
    <StyledLinkItem>{!link.staticLabel ? t(link.label) : link.label}</StyledLinkItem>
  </Link>
);

const linksGroupRender = (groupLink: FooterLinks, t: TFunction) => (
  <StyledLinks>
    <StyledGroupLinkTitle>{t(groupLink.title)}</StyledGroupLinkTitle>
    {groupLink.links.map((link, index) => linkRender(link, index, t))}
  </StyledLinks>
);

export const Footer: React.FC = () => {
  const { t } = useTranslation('common');
  const { contactLinks } = FooterConstants();
  const { isBusinessDomain, getCustomLogoByDomain } = useDomainHandler();

  return (
    <StyledFooter>
      <CssFlex alignItems="center" gap={0.625}>
        {/* For business domain we only show text with Noordhoff business logo */}
        {!isBusinessDomain() ? (
          <>
            <HstStyledLogoFooter dataCy="footer-app-logo" iconName={getCustomLogoByDomain()} />
            <SeparatorLine direction="vertical" height={3.5} />
            <NoordhoffStyledLogoFooter dataCy="footer-noordhoff-logo" iconName="logoNoordhoff" />
          </>
        ) : (
          <HstStyledLogoFooter dataCy="footer-noordhoff-business-logo" iconName="logoNoordhoffBusiness" />
        )}
      </CssFlex>
      {/* Contact links */}
      {linksGroupRender(contactLinks, t)}
      <StyledCopyrightBottomBar />
    </StyledFooter>
  );
};
