import * as Yup from 'yup';
import i18n from 'i18n';

export interface CreateAccountFields {
  firstName: string;
  lastName: string;
  middleName?: string;
}

export const buildCreateAccountSchema = (): Yup.SchemaOf<CreateAccountFields> =>
  Yup.object({
    firstName: Yup.string().required(i18n.t('admin-environment:accountDetails.firstName.errors.required')),
    lastName: Yup.string().required(i18n.t('admin-environment:accountDetails.lastName.errors.required')),
    middleName: Yup.string(),
  }).required();
