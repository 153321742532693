import React, { ReactElement } from 'react';
import ReactDOM from 'react-dom';
import { ModalRenderType } from 'components/Modal/Modal.model';
import { AbsolutePositionedView } from 'components/AbsolutePositionedView/AbsolutePositionedView';
import { WordActionFeedback } from './WordActionFeedback';
import { WordActionFeedbackProps } from './WordActionFeedback.model';

interface Props extends WordActionFeedbackProps {
  isActive?: boolean;
  x?: number;
  y?: number;
  dataCy?: string;
  element?: Element;
  key?: string | null;
  modalRenderType: ModalRenderType;
}

const WordActionFeedbackPortal = React.forwardRef<HTMLDivElement, Props>(
  (
    { isActive = true, element = document.body, modalRenderType, x = 0, y = 0, dataCy, key, ...props },
    ref,
  ): ReactElement => {
    const domElement: ReactElement = (
      <AbsolutePositionedView ref={ref} data-cy={dataCy} x={x} y={y}>
        <div style={{ visibility: isActive ? 'visible' : 'hidden' }}>
          <WordActionFeedback {...(props as WordActionFeedbackProps)} />
        </div>
      </AbsolutePositionedView>
    );

    return modalRenderType === ModalRenderType.INLINE ? domElement : ReactDOM.createPortal(domElement, element, key);
  },
);
WordActionFeedbackPortal.displayName = 'WordActionFeedbackPortal';

export { WordActionFeedbackPortal };
