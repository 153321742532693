import styled from 'styled-components/macro';
import { ThemeProps } from 'models/styles/theme';
import { calculateRem } from 'styles/mixins/calculateRem';
import { Heading3 } from 'styles/elements/Headings';
import { SvgIconReact } from 'components/SvgIconReact';
import { Select } from 'components/Select';

export const StyledTableContainer = styled.div<ThemeProps>`
  border: 1px solid ${({ theme }: ThemeProps) => theme.palette.neutral._400};
  margin-top: ${calculateRem(44)};
  padding: ${calculateRem(16)};
`;

export const StyledTableHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: ${calculateRem(32)};
`;

export const StyledHeader = styled(Heading3)<ThemeProps>`
  font-weight: ${({ theme }: ThemeProps) => theme.font.weight.normal};
`;

export const StyledSelect = styled(Select)`
  height: ${calculateRem(48)};
  margin-left: ${calculateRem(32)};
`;

export const StyledSelectContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
`;

export const StyledTable = styled.table<ThemeProps>`
  width: 100%;
  vertical-align: middle;
  text-align: left;
  border-collapse: collapse;

  tr th {
    padding: ${calculateRem(16)};
  }

  tbody tr td {
    white-space: nowrap;
    width: auto;
    padding: ${calculateRem(16)};

    &:last-child {
      text-align: center;
    }
  }

  tbody tr:nth-child(odd) {
    background: ${({ theme }: ThemeProps) => theme.palette.neutral._100};
  }
`;

export const StyledIcon = styled(SvgIconReact)`
  cursor: pointer;
`;
