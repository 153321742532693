import React from 'react';
import { format } from 'date-fns';
import { Trans, useTranslation } from 'react-i18next';
import { getCorrectTestHeader } from '_helpers/tests';
import { ScheduledTestDetails } from 'models/exam/ScheduledTest';
import { useDomainHandler } from 'hooks/useDomainHandler';
import { Card } from 'components/Card/Card';
import { SvgIconReact } from 'components/SvgIconReact';

import { Heading4, Heading3 } from 'styles/elements/Headings';
import {
  StyledGridContainer,
  StyledSettingsContainer,
  StyledExtraOptionsList,
  StyledProctoringList,
} from './TestFeatureBox.styled';

import { TestMetadata } from '../TestMetadata/TestMetadata';

interface TestFeatureBoxProps {
  scheduledTest: ScheduledTestDetails;
}

export const TestFeatureBox: React.FC<TestFeatureBoxProps> = ({ scheduledTest }) => {
  const { t } = useTranslation('teacher-dashboard');
  const { getStudentTermByDomain } = useDomainHandler();

  const startDate = new Date(scheduledTest.startDate);

  return (
    <Card layout={{ mt: 2, p: 2 }}>
      <Heading3 fontWeight="normal">
        {t('ownedScheduledTestResults.details.testFeatures.title', 'Test features')}
      </Heading3>
      <StyledGridContainer>
        <TestMetadata
          iconName="graduationCapIcon"
          label={t('ownedScheduledTestResults.details.testFeatures.metadata.skillLevel.label', 'Skill level')}
          value={scheduledTest.testLevel}
        />
        <TestMetadata
          iconName="studyIcon"
          label={t('ownedScheduledTestResults.details.testFeatures.metadata.testVersion.label', 'Version')}
          value={scheduledTest.testVersion}
        />
        <TestMetadata
          iconName="studyIcon"
          label={t('ownedScheduledTestResults.details.testFeatures.metadata.testType.label', 'Type')}
          value={getCorrectTestHeader(t, scheduledTest.testType)}
        />
        <TestMetadata
          iconName="graduationCapIcon"
          label={t('ownedScheduledTestResults.details.testFeatures.metadata.estimatedStudents.label', {
            studentTerm: getStudentTermByDomain({ usePlural: true }),
          })}
          value={scheduledTest.estimatedStudents}
        />
        <TestMetadata
          iconName="calendarIcon"
          label={t('ownedScheduledTestResults.details.testFeatures.metadata.startDate.label', 'Date')}
          value={format(startDate, 'dd MMM yyyy')}
        />
        <TestMetadata
          iconName="clockIcon"
          label={t('ownedScheduledTestResults.details.testFeatures.metadata.startTime.label', 'Start time')}
          value={format(startDate, 'HH:mm')}
        />
        <TestMetadata
          iconName="clockIcon"
          label={t('ownedScheduledTestResults.details.testFeatures.metadata.duration.label', 'Duration')}
          value={`${scheduledTest.duration} min`}
        />
        <TestMetadata
          iconName="clockIcon"
          label={t('ownedScheduledTestResults.details.testFeatures.metadata.extraTime.label', 'Extra time')}
          value={`+ ${scheduledTest.extraTime} min`}
        />
      </StyledGridContainer>
      <StyledSettingsContainer>
        <section>
          <Heading4>
            {t('ownedScheduledTestResults.details.testFeatures.extraOptions.title', 'Chosen extra options')}
          </Heading4>
          <StyledExtraOptionsList>
            <li>
              <SvgIconReact iconName="checkIcon" size={1.5} />
              <span>
                <Trans
                  i18nKey="ownedScheduledTestResults.details.testFeatures.extraOptions.settings.passingScore"
                  t={t}
                >
                  {'Required passing score: '}
                  <b data-cy="required-passing-score">{{ passingScore: scheduledTest.passingScore }}%</b>
                </Trans>
              </span>
            </li>
            {scheduledTest.cumulative && (
              <li>
                <SvgIconReact iconName="checkIcon" size={1.5} />
                <span data-cy="is-cumulative">
                  {t('ownedScheduledTestResults.details.testFeatures.extraOptions.settings.cumulative', 'Cumulative')}
                </span>
              </li>
            )}
            {scheduledTest.editableAnswers && (
              <li>
                <SvgIconReact iconName="checkIcon" size={1.5} />
                <span data-cy="editable-answers">
                  {t(
                    'ownedScheduledTestResults.details.testFeatures.extraOptions.settings.editableAnswers',
                    'User can edit answers later',
                  )}
                </span>
              </li>
            )}
          </StyledExtraOptionsList>
        </section>
        {scheduledTest.secureMode && (
          <section data-cy="proctoring-settings">
            <Heading4>
              {t('ownedScheduledTestResults.details.testFeatures.proctoring.title', 'Proctoring settings')}
            </Heading4>
            <StyledProctoringList>
              {scheduledTest.proctoringSettings.programFocused && (
                <li>
                  {t(
                    'ownedScheduledTestResults.details.testFeatures.proctoring.programFocused',
                    'Program must be on focus',
                  )}
                </li>
              )}
              {scheduledTest.proctoringSettings.online && (
                <li>{t('ownedScheduledTestResults.details.testFeatures.proctoring.online', 'Online')}</li>
              )}
              {scheduledTest.proctoringSettings.fullScreen && (
                <li>{t('ownedScheduledTestResults.details.testFeatures.proctoring.fullScreen', 'Full screen')}</li>
              )}
              {scheduledTest.proctoringSettings.otherSoftwareClosed && (
                <li>
                  {t(
                    'ownedScheduledTestResults.details.testFeatures.proctoring.otherSoftwareClosed',
                    'Other applications must be closed',
                  )}
                </li>
              )}
              {scheduledTest.proctoringSettings.onlyOneScreen && (
                <li>{t('ownedScheduledTestResults.details.testFeatures.proctoring.onlyOneScreen', 'Max. 1 screen')}</li>
              )}
              {scheduledTest.proctoringSettings.webcam && (
                <li>
                  {t('ownedScheduledTestResults.details.testFeatures.proctoring.webcam', 'Webcam must be enabled')}
                </li>
              )}
              {scheduledTest.proctoringSettings.faceDetection && (
                <li>
                  {t(
                    'ownedScheduledTestResults.details.testFeatures.proctoring.faceDetection',
                    'Face detection enabled',
                  )}
                </li>
              )}
              {scheduledTest.proctoringSettings.screenshots && (
                <li>
                  {t('ownedScheduledTestResults.details.testFeatures.proctoring.screenshots', 'Screen observation')}
                </li>
              )}
            </StyledProctoringList>
          </section>
        )}
      </StyledSettingsContainer>
    </Card>
  );
};
