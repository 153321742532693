import styled from 'styled-components/macro';
import { ElearningThemeInterface } from 'themes/elearningInterface.theme';
import { InputQuestion } from 'layouts/QuestionManager/components/InputQuestion';
import { StyledInputQuestionProps } from '../InputQuestion/InputQuestion.model';

interface Props extends StyledInputQuestionProps {
  theme: ElearningThemeInterface;
}

export const StyledMultiInputQuestion = styled(InputQuestion)<Props>`
  .better-form-styling {
    line-height: 2rem !important;

    div {
      margin-top: 1rem;
      border-color: #e9e9e9 !important;
    }

    input {
      width: 99.5%;
      padding: 1rem;
      margin: 0.5rem 0;
      text-align: left;
    }
  }
`;
