import { SvgIconReact } from 'components/SvgIconReact';
import React from 'react';
import { Heading3, Heading4 } from 'styles/elements/Headings';
import { TestHeaderProps } from './TestHeader.model';
import { StyledCenterContent, StyledCloseIconButton, StyledRightContent, StyledTestHeader } from './TestHeader.styled';

export const TestHeader: React.FC<TestHeaderProps> = ({
  dataCy,
  children,
  rightComponent,
  title,
  subtitle,
  showCloseButton,
  onClose,
}) => (
  <StyledTestHeader data-cy={dataCy}>
    <div>
      {title && <Heading3>{title}</Heading3>}
      {subtitle && <Heading4>{subtitle}</Heading4>}
    </div>
    <StyledCenterContent>{children}</StyledCenterContent>
    <StyledRightContent>
      {rightComponent}
      {showCloseButton && (
        <StyledCloseIconButton data-cy="close-test-button" onClick={onClose}>
          <SvgIconReact iconName="closeX" />
        </StyledCloseIconButton>
      )}
    </StyledRightContent>
  </StyledTestHeader>
);
