import styled from 'styled-components/macro';

import { ThemeProps as Props } from 'models/styles/theme';

import { ProgressBarVariant } from './ProgressBar.model';

interface ProgressBarProps extends Props {
  $variant: ProgressBarVariant;
  $width: number;
}

export const StyledProgressBar = styled.div`
  height: 0.5rem;
  min-width: 3rem;
  border-radius: 1rem;
  background-color: #f5eef0;
`;

export const StyledProgressBarActiveSegment = styled.div<ProgressBarProps>`
  height: 100%;
  width: ${({ $width }: ProgressBarProps) => $width || 0}%;

  border-radius: 1rem;
  background-color: ${({ theme, $variant }: ProgressBarProps) => {
    switch ($variant) {
      case 'primary':
        return theme.palette.primary.brand;
      default:
        return theme.palette.secondary.brand;
    }
  }};
`;
