import React, { useMemo, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import { AnswerOption, AnswerResult } from 'models/exam/Exam';
import { QuestionStatus } from 'constants/exam-constants';
import { sortAnswerOptions } from '_helpers/questionHelper';

import { EmbeddedHtml } from 'components/EmbeddedHtml';
import { commonSanitizeOptions } from 'layouts/QuestionManager/QuestionManager.constants';

import { SingleSelectQuestionProps } from './SingleSelectQuestion.model';
import { StyledSingleSelectQuestion } from './SingleSelectQuestion.styled';
import { QuestionsManagerPathParams } from '../../QuestionManager.model';
import { useAnswer } from 'hooks/useAnswer';

const isEditableStatus = (status: QuestionStatus) =>
  [QuestionStatus.INITIAL, QuestionStatus.REVIEWING_ANSWERS].includes(status);

const getAnswerStatusClass = (
  answerId: number | string,
  isSelectedAnswer: boolean,
  hideAnswers: boolean,
  status: QuestionStatus,
  results?: AnswerResult[],
): string => {
  if (status === QuestionStatus.INITIAL && isSelectedAnswer) {
    return 'submitted';
  }

  if (status === QuestionStatus.INITIAL) {
    return '';
  }

  // For some status, highlight current answer without saying if it's correct or not
  const hideCorrectStatus =
    hideAnswers || status === QuestionStatus.SUBMITTED || status === QuestionStatus.REVIEWING_ANSWERS;
  if (isSelectedAnswer && hideCorrectStatus) {
    return 'submitted';
  }

  // Verify if selected answer is among the accepted solutions
  const isValidAnswer = !!results?.find(result =>
    result.validAnswers?.find(answer => answer.value.toString() === answerId.toString()),
  );

  // Always hightlight right answer, whether the status of the question is correct or not
  if (isValidAnswer) {
    return 'success';
  }

  if (isSelectedAnswer && !isValidAnswer) {
    return 'error';
  }

  return '';
};

export const SingleSelectQuestion: React.FC<SingleSelectQuestionProps> = ({
  answerFeedbackComponent: feedbackComponent,
  answerOptions,
  className,
  evaluateAnswer,
  question,
  results,
  status,
  isEvaluatingAnswer,
  hideAnswers = false,
  selectedOptionId = null,
  prevAnswerValues,
  submitOnOptionSelect = false,
  onOptionSelected = () => 0,
}) => {
  const { t } = useTranslation('skill-determination-test');
  const [selectedAnswerId, setSelectedAnswerId] = useState<number | string | null>(selectedOptionId);
  const parsedQuestionHtml = useMemo<string>(
    () => question.content.replaceAll(/(\.\.\.|…)+/g, '__________'),
    [question.content],
  );
  const { productId, exerciseId }: QuestionsManagerPathParams = useParams();
  const { setAnswerButtonProps } = useAnswer();
  const setExerciseIdValue = !exerciseId ? undefined : +exerciseId;

  // Reset selected answer when question changes
  useEffect(() => {
    setSelectedAnswerId(selectedOptionId ?? null);
  }, [question]);

  const orderedAnswerOptions = useMemo(() => sortAnswerOptions(answerOptions), [answerOptions]);

  const selectAnswer = (answerId: number | string): void => {
    setSelectedAnswerId(answerId);
  };

  const submitAnswer = (answerId: number | string | null, skipAnswerValidation?: boolean) => {
    const actualAnswerId = answerId ?? orderedAnswerOptions[0].id;
    evaluateAnswer &&
      evaluateAnswer(
        +productId,
        question.id,
        [{ order: 0, value: actualAnswerId }],
        setExerciseIdValue,
        skipAnswerValidation,
      );
  };

  const onKeyPress = (event: React.KeyboardEvent, answerId: number | string) => {
    if (isEditableStatus(status) && event.key === 'Enter') {
      selectAnswer(answerId);
    }
  };

  // add possibility to select an answerOption by pressing a number key
  useEffect(() => {
    if (!isEditableStatus(status)) {
      return;
    }
    const onDocumentKeyPress = (event: KeyboardEvent) => {
      const eventKey: number = parseInt(event.key, 10);
      if (!eventKey) {
        return;
      }
      const index = eventKey - 1;
      if (orderedAnswerOptions.length > index) {
        selectAnswer(orderedAnswerOptions[index].id);
      }
    };
    document.addEventListener('keypress', onDocumentKeyPress);
    // eslint-disable-next-line consistent-return
    return () => document.removeEventListener('keypress', onDocumentKeyPress);
  }, [status, orderedAnswerOptions]);

  const onSelectAnswerChoice = (answerId: number | string) => {
    isEditableStatus(status) && selectAnswer(answerId);
    onOptionSelected(answerId);

    if (submitOnOptionSelect) {
      submitAnswer(answerId);
    }
  };

  const prevSelectedAnswerId = prevAnswerValues ? prevAnswerValues.map(({ value }) => value) : '';

  // Set configuration for answer button
  useEffect(() => {
    setAnswerButtonProps({
      label: hideAnswers ? t('button.continue.label', 'Continue') : t('button.checkAnswer.label', 'Save change'),
      isDisabled: !selectedAnswerId,
      isHidden: status !== QuestionStatus.INITIAL && status !== QuestionStatus.REVIEWING_ANSWERS,
      isLoading: isEvaluatingAnswer || false,
      onClick: skipAnswerValidation => {
        submitAnswer(selectedAnswerId, skipAnswerValidation);
      },
    });
  }, [isEvaluatingAnswer, hideAnswers, selectedAnswerId, status]);

  return (
    <StyledSingleSelectQuestion className={className}>
      <EmbeddedHtml className="question-wrapper" rawHtml={parsedQuestionHtml} sanitizeOptions={commonSanitizeOptions} />
      <ul>
        {orderedAnswerOptions.map(({ id: answerId, content }: AnswerOption) => {
          const isSelectedAnswer = !!selectedAnswerId && answerId.toString() === selectedAnswerId.toString();
          return (
            <li key={answerId}>
              <div
                className={
                  +prevSelectedAnswerId === +answerId && !selectedAnswerId
                    ? 'submitted'
                    : classnames(
                        isSelectedAnswer && 'selected',
                        (!isEditableStatus(status) || isEvaluatingAnswer) && 'disabled',
                        getAnswerStatusClass(answerId, isSelectedAnswer, hideAnswers, status, results),
                      )
                }
                data-cy="single-select-answer"
                role="button"
                tabIndex={0}
                onClick={() => onSelectAnswerChoice(answerId)}
                onKeyPress={(event: React.KeyboardEvent<Element>) => onKeyPress(event, answerId)}
              >
                <EmbeddedHtml rawHtml={content} sanitizeOptions={commonSanitizeOptions} />
              </div>
            </li>
          );
        })}
      </ul>
      {feedbackComponent}
    </StyledSingleSelectQuestion>
  );
};
