import styled from 'styled-components/macro';

import { Paragraph } from 'styles/elements/Texts';
import { Button } from 'components/Button';
import { FontAwesomeIcon } from 'components/FontAwesomeIcon';
import { InputWithErrorMessage } from 'components/InputWithErrorMessage';
import { ThemeProps as Props } from 'models/styles/theme';

export const StyledParagraph = styled(Paragraph)`
  color: ${(props: Props) => props.theme.palette.neutral._900};
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  text-align: center;
  padding-top: 1rem;
`;

export const StyledButton = styled(Button)`
  width: 15rem;
  font-size: 1rem;
  font-weight: bold;
  margin-top: 1.5rem;
`;

export const StyledPasswordField = styled.div`
  width: 23rem;
  margin: 1rem;
  position: relative;
`;

export const StyledInput = styled(InputWithErrorMessage)`
  padding-right: 3rem;
`;

export const StyledIcon = styled(FontAwesomeIcon)<Props>`
  position: absolute;
  top: 0.75rem;
  right: 1rem;
  margin-top: 1px;
  color: ${(props: Props) => props.theme.palette.neutral._600};
`;
