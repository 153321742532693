import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Formik, FormikHelpers } from 'formik';

import { modalIds } from 'constants/modals-constants';
import { useModal } from 'hooks/useModal';
import { useQueryParam } from 'hooks/useQueryParam';
import { useScheduledTests } from 'hooks/useScheduledTests';
import { useDomainHandler } from 'hooks/useDomainHandler';

import { TEST_ENVIRONMENT_ROUTES } from 'constants/routes';
import { buildUrlWithPathParams } from '_helpers/utils/urlBuilder';

import { buildTestAccessSchema, TestAccessFormFields } from './TestAccessForm.schema';

import { RequestPasswordModal } from '../PasswordModal/PasswordModal';

import {
  StyledForm,
  StyledTitle,
  StyledMessage,
  StyledFieldRow,
  StyledInput,
  StyledButton,
} from './TestAccessForm.styled';

export const TestAccessForm: React.FC = () => {
  const { t } = useTranslation('test-environment');
  const history = useHistory();
  const { toggleModal } = useModal();
  const testCode = useQueryParam('code');
  const { getTeacherTermByDomain } = useDomainHandler();
  const { fetchScheduledTestContent } = useScheduledTests();

  const onFormSubmit = (values: TestAccessFormFields, actions: FormikHelpers<TestAccessFormFields>) => {
    fetchScheduledTestContent(values.code, values.password).then(
      ({ accessGranted, requestPassword, isTestFinished }) => {
        if (requestPassword) {
          actions.setSubmitting(false);
          toggleModal(modalIds.passwordRequiredModal);
        } else {
          actions.resetForm();
          if (accessGranted) {
            const destinationURL = isTestFinished
              ? TEST_ENVIRONMENT_ROUTES.TEST_COMPLETED_PAGE
              : TEST_ENVIRONMENT_ROUTES.TEST_DETAILS_PAGE;
            history.push(buildUrlWithPathParams(destinationURL, { code: values.code.trim() }));
          }
        }
      },
    );
  };

  return (
    <Formik
      initialValues={{ code: testCode || '', password: '' } as TestAccessFormFields}
      validationSchema={buildTestAccessSchema()}
      onSubmit={onFormSubmit}
    >
      {formik => (
        <StyledForm noValidate onSubmit={formik.handleSubmit}>
          <StyledTitle>{t('testAccessForm.title', 'Do you have a test code?')}</StyledTitle>
          <StyledMessage>{t('testAccessForm.description', { teacherTerm: getTeacherTermByDomain() })}</StyledMessage>
          <StyledFieldRow>
            <StyledInput
              dataCy="test-code-input"
              disabled={formik.isSubmitting}
              hasError={!!(formik.errors.code && formik.touched.code)}
              id="test-code"
              name="code"
              placeholder={t('testAccessForm.fields.code.placeholder', 'Enter your test code')}
              value={formik.values.code}
              onChange={formik.handleChange}
            />
            <StyledButton dataCy="access-test-button" disabled={formik.isSubmitting} type="submit" variant="primary">
              {t('testAccessForm.buttons.submit.label', 'Go to test')}
            </StyledButton>
          </StyledFieldRow>
          <RequestPasswordModal />
        </StyledForm>
      )}
    </Formik>
  );
};
