import React from 'react';

import { SvgIconReact } from 'components/SvgIconReact';
import { IconComponentName } from 'components/SvgIconReact/IconNames';

// TODO: ideally this shouldn't be hard coded
export const productFlagIconNames = new Map<number, IconComponentName>([
  [1, 'englandFlag'],
  [2, 'netherlandsFlag'],
  [68, 'netherlandsFlag'],
  [69, 'englandFlag'],
]);

interface ProductFlagProps {
  className?: string;
  productId: number;
  size?: number;
}

export const ProductFlag: React.FC<ProductFlagProps> = ({ className, productId, size = 1.5 }) => {
  const flagIconName = productFlagIconNames.get(productId) || 'netherlandsFlag';
  return <SvgIconReact className={className} iconName={flagIconName} size={size} />;
};

export default ProductFlag;
