import styled from 'styled-components/macro';
import { ElearningThemeInterface } from 'themes/elearningInterface.theme';
import { AccordionItem } from 'components/AccordionItem';
import { Link } from 'components/Link';

interface Props {
  theme: ElearningThemeInterface;
}

export const StyledDiagnosticTestGroupItem = styled(Link)<Props>`
  width: 100%;
`;
export const StyledAccordionDiagnosticListItem = styled(AccordionItem)<Props>`
  .tab-label::before {
    content: none;
  }
`;

export const StyledPanelRightHeader = styled.div<Props>`
  display: flex;
  align-items: center;
  width: 18rem;
  justify-content: flex-end;
`;
