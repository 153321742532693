import styled from 'styled-components/macro';

import { ThemeProps as Props } from 'models/styles/theme';
import { FontAwesomeIcon } from 'components/FontAwesomeIcon';
import { Link } from 'components/Link';

export const StyledNavContainer = styled.section<Props>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 4rem 0 2rem 0;
`;

export const StyledNavLink = styled(Link)`
  display: flex;
  align-items: center;

  > :not(:first-child) {
    margin-left: 0.75rem;
  }
`;

interface StyledNavTextProps extends Props {
  align: 'left' | 'right';
}

export const StyledNavText = styled.div<StyledNavTextProps>`
  text-align: ${(props: StyledNavTextProps) => props.align};
  font-size: 1rem;

  > :nth-child(2) {
    font-size: 0.875rem;
    color: ${(props: Props) => props.theme.palette.neutral._600};
  }
`;

export const StyledNavIcon = styled(FontAwesomeIcon)<Props>`
  width: 3rem;
  height: 3rem;
  display: block;
  line-height: 3rem;
  text-align: center;
  border-radius: 0.25rem;
  vertical-align: middle;
  background: ${(props: Props) => props.theme.palette.neutral._200};
`;

export const StyledNavPlaceholder = styled.div<Props>``;
