import React, { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { LineGraphPoint } from './TotalExercisesGraph.model';
import { GraphLoading } from '../GraphLoading/GraphLoading';
import { EmptyGraphPlaceholder } from '../EmptyGraphPlaceholder/EmptyGraphPlaceholder';
import { TotalExercisesGraphProps } from './TotalExercisesGraph.model';
import { StyledGraphTitle } from '../../PersonalProgressPage.styled';
import { XAxis, YAxis, LineChart, Line, ResponsiveContainer } from 'recharts';
import { useTheme } from 'styled-components/macro';
import { ElearningThemeInterface } from 'themes/elearningInterface.theme';

export const MyTotalExercisesGraph: React.FC<TotalExercisesGraphProps> = ({
  exercisesOverTimeResults,
  hasLoadingError,
  onRenderComplete,
}) => {
  const theme = useTheme() as ElearningThemeInterface;
  const { t } = useTranslation('results');
  const elRef = useRef<HTMLDivElement>(null);

  const allExerciseData = useMemo<LineGraphPoint[] | null>(() => {
    if (!exercisesOverTimeResults) return null;

    return exercisesOverTimeResults.map(result => ({
      xAxisLabel: result.weekStartDate,
      value: result.questionCount,
    }));
  }, [exercisesOverTimeResults]);

  const noResultsFound = allExerciseData?.length === 0;
  const loadingResults = !allExerciseData;

  useEffect(() => {
    if (noResultsFound && onRenderComplete && elRef.current) {
      onRenderComplete(elRef.current);
    }
  }, [noResultsFound, onRenderComplete]);

  return (
    <div ref={elRef}>
      <StyledGraphTitle data-cy="exercises-over-time-graph-title">
        {t('graphTitle.exercisesOverTime', 'Questions done over time')}
      </StyledGraphTitle>
      {(loadingResults || hasLoadingError) && (
        <GraphLoading dataCy="exercises-over-time-graph-loading" hasLoadingError={hasLoadingError} />
      )}
      {!hasLoadingError && noResultsFound && (
        <EmptyGraphPlaceholder>{t('placeholder.notEnoughGraphData')}</EmptyGraphPlaceholder>
      )}
      {allExerciseData && !noResultsFound && (
        <div data-cy="exercises-over-time-graph">
          <ResponsiveContainer debounce={300} height={300} width="100%">
            <LineChart data={allExerciseData}>
              <XAxis dataKey="xAxisLabel" padding={{ left: 10, right: 10 }} />
              <YAxis />
              <Line
                dataKey="value"
                dot={{ fill: theme.palette.secondary.brand }}
                stroke={theme.palette.secondary.brand}
                strokeWidth={5}
                type="monotone"
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      )}
    </div>
  );
};

export const TotalExercisesGraph = React.memo(MyTotalExercisesGraph);
