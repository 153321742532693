import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LineChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Line, ResponsiveContainer } from 'recharts';

import { ProgressAPI } from 'generated/types';

import { useLanguage } from 'hooks/useSelectedLanguage';
import { useDomainHandler } from 'hooks/useDomainHandler';

import { Card } from 'components/Card/Card';
import { Divider } from 'components/Divider/Divider';
import { InfoMessage } from 'components/InfoMessage/InfoMessage';
import { RechartsCustomLegend } from 'components/RechartsCustomLegend/RechartsCustomLegend';

import { CssIcon } from 'styles/helpers/layout';
import { Heading3, Heading4 } from 'styles/elements/Headings';
import {
  StyledContentContainer,
  StyledSubjectFilterWrapper,
  StyledSelect,
} from './AccountSubjectProgressOverTime.styled';

export interface AccountSubjectProgressOverTimeProps {
  studentSubjectProgressOverTime: ProgressAPI.SubjectProgressOverTime[] | null;
}

const lineColors = ['#1292E9', '#EE5398', '#6929C5', '#009D9A', '#E9BE40', '#9F1852'];

export const AccountSubjectProgressOverTime: React.FC<AccountSubjectProgressOverTimeProps> = ({
  studentSubjectProgressOverTime,
}) => {
  const { t } = useTranslation('teacher-dashboard');
  const { currentLanguage } = useLanguage();
  const { getStudentTermByDomain } = useDomainHandler();
  const [selectedSubjectLine, setSelectedSubjectLine] = useState('all');

  // Create array with data to initialize all lines in the chart
  const lineData = useMemo(
    () =>
      studentSubjectProgressOverTime
        ? studentSubjectProgressOverTime.map(({ id, title }, idx) => ({
            key: `sb${id}`,
            name: title,
            color: lineColors[idx],
          }))
        : [],
    [studentSubjectProgressOverTime],
  );

  // Initialize payload object with data to represent in the chart
  const chartData = useMemo(() => {
    if (!studentSubjectProgressOverTime) return [];

    const monthData: Record<string, Record<string, number>> = {};
    studentSubjectProgressOverTime.forEach(({ id: subjectId, monthlyProgress }) => {
      monthlyProgress.forEach(({ month, progress }) => {
        // Endpoint is returning progress at the end of each month, so we need to reassign displayed
        // month in the graph (progress to display at start of sept 2023 would be in 2023-08)
        const targetMonth = parseInt(month.slice(-2)) + 1;
        const targetYear = parseInt(month.slice(0, -3));
        const chartMonthKey =
          targetMonth > 12
            ? `${targetYear + 1}-01`
            : `${targetYear}-${targetMonth > 9 ? targetMonth : `0${targetMonth}`}`;

        if (!monthData[chartMonthKey]) {
          monthData[chartMonthKey] = {};
        }

        monthData[chartMonthKey] = {
          ...monthData[chartMonthKey],
          [`sb${subjectId}`]: progress,
        };
      });
    });

    return Object.keys(monthData).map(month => ({
      month,
      ...monthData[month],
    }));
  }, [studentSubjectProgressOverTime]);

  // Generate options for subject selector, which can be used to hide certain lines in the chart
  const subjectSelectOptions = useMemo(
    () => [
      {
        value: 'all',
        label: t('basicProgress.forAccount.progressOverTime.filters.subject.defaultOption', 'All subjects'),
      },
      ...lineData.map(({ key, name }) => ({
        value: key,
        label: name,
      })),
    ],
    [lineData],
  );

  return (
    <Card data-cy="progress-over-time-chart-card" layout={{ p: 2 }}>
      <Heading3 fontWeight="normal">{t('basicProgress.forAccount.progressOverTime.title')}</Heading3>
      <Heading4 fontWeight="normal" mb="2rem">
        {t('basicProgress.forAccount.progressOverTime.description', {
          studentTerm: getStudentTermByDomain(),
        })}
      </Heading4>
      <Divider />
      {chartData.length === 0 ? (
        <InfoMessage dataCy="no-chart-data-message" icon="fas fa-empty-set">
          {t('basicProgress.forAccount.progressOverTime.chart.noData')}
        </InfoMessage>
      ) : (
        <StyledContentContainer flexDirection="column">
          {/* -- Filter to show only one specific subject -- */}
          <StyledSubjectFilterWrapper alignItems="center" justifyContent="flex-end">
            <CssIcon iconName="fas fa-book-open" />
            <StyledSelect
              dataCy="chart-subject-selector"
              options={subjectSelectOptions}
              showPlaceholderInOptionList={false}
              value={selectedSubjectLine}
              onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                const selectedValue = event.target.value;
                setSelectedSubjectLine(selectedValue);
              }}
            />
          </StyledSubjectFilterWrapper>
          {/* -- Line chart with progress over time -- */}
          <ResponsiveContainer aspect={1.75} debounce={300}>
            <LineChart data={chartData} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
              <CartesianGrid fill="#e5ecf6" stroke="#ffffff" />
              <XAxis
                dataKey="month"
                label={{ value: t('basicProgress.forAccount.progressOverTime.chart.month'), position: 'bottom' }}
                padding={{ left: 25, right: 25 }}
              />
              <YAxis
                domain={[0, 100]}
                label={{
                  value: t('basicProgress.forAccount.progressOverTime.chart.progress'),
                  angle: -90,
                  position: 'insideLeft',
                }}
                padding={{ top: 25, bottom: 25 }}
                tickCount={6}
                type="number"
              />
              <Tooltip
                cursor={false}
                formatter={value => `${value}%`}
                labelFormatter={value =>
                  new Date(`${value}-01`).toLocaleDateString(currentLanguage, { dateStyle: 'long' })
                }
                labelStyle={{ fontWeight: 'bold' }}
              />
              {/* Legend with subject names */}
              <Legend
                content={({ payload }) => <RechartsCustomLegend payload={payload} />}
                iconType="circle"
                wrapperStyle={{ paddingTop: '3rem', paddingBottom: '0' }}
              />
              {/* Display one line per subject */}
              {lineData.map(({ key, name, color }) => (
                <Line
                  key={key}
                  dataKey={key}
                  dot={{ fill: color }}
                  hide={selectedSubjectLine !== key && selectedSubjectLine !== 'all'}
                  id={`subject-line-${key}`}
                  name={name}
                  stroke={color}
                  strokeWidth={3}
                  type="monotone"
                />
              ))}
            </LineChart>
          </ResponsiveContainer>
        </StyledContentContainer>
      )}
    </Card>
  );
};
