/* eslint-disable max-len */
import styled from 'styled-components/macro';
import { ElearningThemeInterface } from 'themes/elearningInterface.theme';
import { StyledSelectProps } from './Select.model';

interface WrapperProps extends StyledSelectProps {
  theme: ElearningThemeInterface;
}

interface SelectProps extends WrapperProps {
  $width?: string;
}

export const StyledSelect = styled.select<SelectProps>`
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  user-select: none;
  border: 1px solid ${(props: SelectProps) => props.theme.palette.neutral._600};
  appearance: none;
  background-color: ${(props: SelectProps) => props.theme.palette.neutral.white};
  background-image: url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSdibGFjaycgaGVpZ2h0PScyNCcgdmlld0JveD0nMCAwIDI0IDI0JyB3aWR0aD0nMjQnIHhtbG5zPSdodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2Zyc+PHBhdGggZD0nTTcgMTBsNSA1IDUtNXonLz48cGF0aCBkPSdNMCAwaDI0djI0SDB6JyBmaWxsPSdub25lJy8+PC9zdmc+);
  background-repeat: no-repeat;
  background-position: 96% 50%;
  background-size: 1.5rem 1.5rem;
  padding: 0.7rem 1.3rem 0.7rem 0.7rem;
  width: ${(props: SelectProps) => props.$width};
  outline: none;

  &.has-icon {
    padding-left: 3.3rem;
  }
  &[disabled] {
    cursor: not-allowed;
  }
`;

export const StyledLabel = styled.label`
  display: block;
  margin: 0.3125rem 0;
`;

export const StyledWrapper = styled.div<WrapperProps>`
  display: flex;
  align-items: center;
  position: relative;

  svg {
    position: absolute;
    left: 0.85rem;
    top: 0.5rem;
  }
`;
