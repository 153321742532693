import React from 'react';
import { useTranslation } from 'react-i18next';

import { SvgIconReact } from 'components/SvgIconReact';
import {
  StyledTitle,
  StyledTestCompletedMessage,
  StyledButtonsSection,
  StyledParagraphFinishedQuestion,
  StyledHeaderFinishedQuestion,
} from './TestCompletedMessage.styled';
import TestCompletedMessageProps from './TestCompletedMessage.model';

export const TestCompletedMessage: React.FC<TestCompletedMessageProps> = ({
  dataCy,
  title,
  iconName,
  description,
  buttonsTitle,
  buttons,
  className,
  hideSubTitle,
}) => {
  const { t } = useTranslation('skill-determination-test');
  return (
    <StyledTestCompletedMessage className={className} data-cy={dataCy}>
      <StyledTitle>{title || t('finishedQuestions.title', 'Well done!')}</StyledTitle>
      <SvgIconReact iconName={iconName} size={14} />
      {description && <StyledParagraphFinishedQuestion>{description}</StyledParagraphFinishedQuestion>}
      {buttons && (
        <StyledHeaderFinishedQuestion>
          {!hideSubTitle && (buttonsTitle || t('finishedQuestions.subtitle', 'What would you like to do next?'))}
        </StyledHeaderFinishedQuestion>
      )}
      <StyledButtonsSection>{buttons}</StyledButtonsSection>
    </StyledTestCompletedMessage>
  );
};
