import React from 'react';
import { useTranslation } from 'react-i18next';

import { useModal } from 'hooks/useModal';

import { ProductSkillLevel } from 'models/product/ProductDetails';
import { useProductProgress } from 'hooks/useProductProgress';

import { TargetLevelSelectorModal } from 'components/TargetLevelSelectorModal/TargetLevelSelectorModal';

export interface StudentTargetSelectorModalProps {
  accountId: number;
  productId: number;
  currentGoalLevel?: number;
  selectableGoalLevels: ProductSkillLevel[];
}

export const StudentTargetSelectorModal: React.FC<StudentTargetSelectorModalProps> = ({
  accountId,
  productId,
  currentGoalLevel,
  selectableGoalLevels,
}) => {
  const { t } = useTranslation('teacher-dashboard');

  const { closeModal } = useModal();
  const { updateStudentGoalLevel, fetchProductProgressForAccount } = useProductProgress();

  return (
    <TargetLevelSelectorModal
      currentGoalLevel={currentGoalLevel}
      description={t(
        'basicProgress.forAccount.studentGoalSelectorModal.description',
        'Select the desired target level for this employee',
      )}
      selectableGoalLevels={selectableGoalLevels}
      submitButtonLabel={t('basicProgress.forAccount.studentGoalSelectorModal.submitButton', 'Save target level')}
      title={t('basicProgress.forAccount.studentGoalSelectorModal.title', 'Set target level')}
      onSubmitButtonClick={async values => {
        await updateStudentGoalLevel(accountId, productId, +values.targetLevel).then(() => {
          closeModal();

          // We must reload the progress of the user after a change, as percentages may differ for the new goal
          fetchProductProgressForAccount(+productId, +accountId);
        });
      }}
    />
  );
};
