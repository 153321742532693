import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import { format } from 'date-fns';

import { copyTextToClipboard } from '_helpers/clipboard';
import { useReactTooltip } from 'hooks/useReactTooltip';
import { useModal } from 'hooks/useModal';
import { useDomainHandler } from 'hooks/useDomainHandler';

import { ShowPasswordModal } from 'components/ShowPasswordModal';
import { SvgIconReact } from 'components/SvgIconReact';
import { modalIds } from 'constants/modals-constants';

import { TimerBar } from './components/TimerBar/TimerBar';
import { UpdateTestDurationModal } from './components/UpdateTestDurationModal/UpdateTestDurationModal';

import {
  StyledScheduledTestItem,
  StyledTestName,
  StyledTestCode,
  StyledProductFlag,
  StyledGeneralInformation,
  StyledInfoRow,
  StyledMetadataContainer,
  StyledTestMetadata,
  StyledInfo,
  StyledInfoHeader,
  StyledInfoContent,
  StyledButton,
} from './ScheduledTestDetails.styled';
import { ScheduledTestDetailsProps } from './ScheduledTestDetails.model';

export const ScheduledTestDetails: React.FC<ScheduledTestDetailsProps> = ({ scheduledTest, onChangeTestDuration }) => {
  useReactTooltip();
  const {
    accessCodes,
    duration,
    extraTime,
    password,
    productId,
    startDate: startDateString,
    testType,
    testVersion,
    testLevel,
    title,
    estimatedStudents,
  } = scheduledTest;
  const [passwordCopied, setPasswordCopied] = useState(false);
  const { toggleModal } = useModal();
  const { t } = useTranslation('scheduled-tests');
  const { getStudentTermByDomain } = useDomainHandler();

  const startDate = new Date(startDateString);

  const testTypeTitle = useMemo(() => {
    switch (testType) {
      case 'summative-test':
        return t('scheduledTestList.item.testType.summative', 'Summative test');
      case 'formative-test':
        return t('scheduledTestList.item.testType.formative', 'Formative test');
      case 'freebie-test':
        return t('scheduledTestList.item.testType.freebie', 'Freebie test');
      default:
        return '';
    }
  }, [testType]);

  const onRevealPasswordClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    toggleModal(modalIds.showPasswordModal, { password });
    setPasswordCopied(false);
  };

  const onUpdateTestTimeClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    toggleModal(modalIds.updateTestTimeModal, { duration });
  };

  const onCopyPassword = () => {
    copyTextToClipboard(password);
    setPasswordCopied(true);
  };

  return (
    <>
      <StyledTestName data-cy="scheduled-test-name">{title}</StyledTestName>
      <StyledScheduledTestItem data-cy="scheduled-test-item">
        <StyledProductFlag productId={productId} />
        <StyledGeneralInformation>
          <StyledInfoRow>
            <StyledMetadataContainer>
              <StyledTestMetadata>
                <SvgIconReact iconName="graduationCapIcon" size={1.5} />
                <StyledInfo>
                  <StyledInfoHeader>{t('scheduledTestList.item.level', 'Skill Level')}</StyledInfoHeader>
                  <StyledInfoContent data-cy="scheduled-test-level">{testLevel}</StyledInfoContent>
                </StyledInfo>
              </StyledTestMetadata>
              <StyledTestMetadata>
                <SvgIconReact iconName="studyIcon" size={1.5} />
                <StyledInfo>
                  <StyledInfoHeader>{t('scheduledTestList.item.version', 'Version')}</StyledInfoHeader>
                  <StyledInfoContent data-cy="scheduled-test-version">{testVersion}</StyledInfoContent>
                </StyledInfo>
              </StyledTestMetadata>
              <StyledTestMetadata>
                <SvgIconReact iconName="studyIcon" size={1.5} />
                <StyledInfo>
                  <StyledInfoHeader>{t('scheduledTestList.item.type', 'Type')}</StyledInfoHeader>
                  <StyledInfoContent data-cy="scheduled-test-type">{testTypeTitle}</StyledInfoContent>
                </StyledInfo>
              </StyledTestMetadata>
              <StyledTestMetadata>
                <SvgIconReact iconName="graduationCapIcon" size={1.5} />
                <StyledInfo>
                  <StyledInfoHeader>
                    {t('scheduledTestList.item.students', {
                      studentTerm: getStudentTermByDomain({ usePlural: true }),
                    })}
                  </StyledInfoHeader>
                  <StyledInfoContent data-cy="scheduled-test-estimated-students">{estimatedStudents}</StyledInfoContent>
                </StyledInfo>
              </StyledTestMetadata>
            </StyledMetadataContainer>
            <StyledTestCode data-cy="scheduled-test-code">
              <SvgIconReact iconName="codeIcon" size={2} />
              {accessCodes.standard}
            </StyledTestCode>
          </StyledInfoRow>
          <StyledInfoRow>
            <StyledMetadataContainer>
              <StyledTestMetadata>
                <SvgIconReact iconName="calendarIcon" size={1.5} />
                <StyledInfo>
                  <StyledInfoHeader>{t('scheduledTestList.item.startDate', 'Start Date')}</StyledInfoHeader>
                  <StyledInfoContent data-cy="scheduled-test-start-date">
                    {format(startDate, 'dd MMMM yyyy')}
                  </StyledInfoContent>
                </StyledInfo>
              </StyledTestMetadata>
              <StyledTestMetadata>
                <SvgIconReact iconName="clockIcon" size={1.5} />
                <StyledInfo>
                  <StyledInfoHeader>{t('scheduledTestList.item.startTime', 'Start Time')}</StyledInfoHeader>
                  <StyledInfoContent data-cy="scheduled-test-start-time">
                    {format(startDate, 'HH:mm')}
                  </StyledInfoContent>
                </StyledInfo>
              </StyledTestMetadata>
              <StyledTestMetadata>
                <SvgIconReact iconName="clockIcon" size={1.5} />
                <StyledInfo>
                  <StyledInfoHeader>{t('scheduledTestList.item.duration', 'Duration')}</StyledInfoHeader>
                  <StyledInfoContent data-cy="scheduled-test-duration">{`${duration} min.`}</StyledInfoContent>
                </StyledInfo>
              </StyledTestMetadata>
              <StyledTestMetadata>
                <SvgIconReact iconName="clockIcon" size={1.5} />
                <StyledInfo>
                  <StyledInfoHeader>{t('scheduledTestList.item.extraTime', 'Extra Time')}</StyledInfoHeader>
                  <StyledInfoContent data-cy="scheduled-test-extra-time">{`+${extraTime} min.`}</StyledInfoContent>
                </StyledInfo>
              </StyledTestMetadata>
            </StyledMetadataContainer>
            <StyledTestCode data-cy="scheduled-test-code">
              {extraTime > 0 && (
                <>
                  <SvgIconReact iconName="codeIcon" size={2} />
                  {accessCodes.withExtraTime}
                </>
              )}
            </StyledTestCode>
          </StyledInfoRow>
          <StyledInfoRow>
            <StyledButton dataCy="update-test-duration-button" onClick={onUpdateTestTimeClick}>
              <SvgIconReact iconName="pencilIcon" size={1} />
              {t('scheduledTestList.item.changeDuration', 'Change duration of test')}
            </StyledButton>
          </StyledInfoRow>
          <StyledInfoRow>
            <TimerBar code={accessCodes.withExtraTime} duration={duration + extraTime} />
            {!!password && (
              <StyledButton onClick={onRevealPasswordClick}>
                <SvgIconReact iconName="eyeIcon" size={1} />
                {t('scheduledTestList.item.viewPassword', 'View password')}
              </StyledButton>
            )}
          </StyledInfoRow>
        </StyledGeneralInformation>
      </StyledScheduledTestItem>
      <ShowPasswordModal
        headingTitle={t('scheduledTestList.item.passwordModal.heading', 'Password')}
        onCopyPassword={onCopyPassword}
      />
      <UpdateTestDurationModal onChangeTestDuration={onChangeTestDuration} />
      <ReactTooltip
        getContent={() =>
          passwordCopied
            ? t('scheduledTestList.item.passwordModal.copiedToClipboardTooltip', 'Password copied')
            : t('scheduledTestList.item.passwordModal.copyToClipboardTooltip', 'Copy to clipboard')
        }
        id="password-copy-tooltip"
      />
    </>
  );
};
