import React, { useEffect, useState } from 'react';

import { StyledIcon, StyledMessage, StyledMessageTitle, StyledMessageText } from './AchievementToast.styled';
import { AchievementToastProps } from './AchievementToast.model';

export const AchievementToast: React.FC<AchievementToastProps> = ({
  className,
  dataCy,
  iconName = 'trophyIcon',
  children,
  duration,
  title,
  showConfetti = false,
}) => {
  const [isVisible, setIsVisible] = useState(false);

  // Automatically hide message after a few seconds
  useEffect(() => {
    setIsVisible(true);
    if (duration) {
      setTimeout(() => {
        setIsVisible(false);
      }, duration + 500);
    }
  }, []);

  return (
    <StyledMessage className={className} data-cy={dataCy} showConfetti={showConfetti} visible={isVisible}>
      <StyledIcon iconName={iconName} size={3} />
      <div>
        <StyledMessageTitle>{title}</StyledMessageTitle>
        <StyledMessageText>{children}</StyledMessageText>
      </div>
    </StyledMessage>
  );
};
