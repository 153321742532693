import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useScheduledTests } from 'hooks/useScheduledTests';
import { useDomainHandler } from 'hooks/useDomainHandler';
import { UserScheduledTestResults } from 'models/exam/ScheduledTest';

import { buildUrlWithPathParams } from '_helpers/utils/urlBuilder';
import { TEACHER_SCHEDULED_TEST_PAGE_ROUTES } from 'constants/routes';

import { BackButton } from 'components/BackButton';
import { ToastMethods } from 'components/ToastNotification';
import { ExercisesList } from './components/ExercisesList/ExercisesList';
import { InvalidateTest } from './components/InvalidateTest/InvalidateTest';

import { Heading3 } from 'styles/elements/Headings';
import { StyledEmailWrapper, StyledParagraph, StyledWrapper } from './ScheduledTestResultsStudent.styled';

export interface ScheduledTestResultsStudentParams {
  scheduledTestId: string;
  studentId: string;
}

export const ScheduledTestResultsStudent: React.FC = observer(() => {
  const [studentResult, setStudentResult] = useState<UserScheduledTestResults>();
  const { t } = useTranslation('teacher-dashboard');
  const { t: tToast } = useTranslation('toast');
  const { getStudentTermByDomain, isBusinessDomain } = useDomainHandler();

  const { scheduledTestId, studentId }: ScheduledTestResultsStudentParams = useParams();
  const {
    scheduledTest,
    invalidatedScheduledTest,
    ownedScheduledTestResults,
    fetchScheduledTest,
    fetchOwnedScheduledTestResults,
    invalidateScheduledTestOfStudent,
    setInvalidatedScheduledTest,
  } = useScheduledTests();

  useEffect(() => {
    if (!scheduledTest || scheduledTest.id !== +scheduledTestId) {
      fetchScheduledTest(+scheduledTestId);
      fetchOwnedScheduledTestResults(+scheduledTestId);
    }
  }, [scheduledTestId, scheduledTest]);

  useEffect(() => {
    if (ownedScheduledTestResults && studentId) {
      setStudentResult(ownedScheduledTestResults.students.find(student => student.id === +studentId));
    }
  }, [ownedScheduledTestResults]);

  useEffect(() => {
    if (invalidatedScheduledTest && scheduledTestId) {
      ToastMethods.showToast(
        tToast('scheduledTest.success.postInvalidateScheduledTestSuccess', {
          studentTerm: getStudentTermByDomain(),
        }),
        'success',
      );
      fetchOwnedScheduledTestResults(+scheduledTestId);
    }
    return () => {
      setInvalidatedScheduledTest(false);
    };
  }, [invalidatedScheduledTest]);

  if (!ownedScheduledTestResults || !studentResult) {
    return null;
  }

  return (
    <>
      <BackButton
        dataCy="back-to-test-results-button"
        label={t('scheduledTestForStudent.header.backToTestResults', 'Back')}
        redirectUrl={buildUrlWithPathParams(TEACHER_SCHEDULED_TEST_PAGE_ROUTES.SCHEDULED_TEST_RESULTS, {
          scheduledTestId,
        })}
      />
      {/* Information on the student for which the displayed results belong */}
      <Heading3 data-cy="full-student-name">
        {`${studentResult.firstName} ${studentResult.middleName} ${studentResult.lastName}`}
      </Heading3>
      {!isBusinessDomain() && (
        <StyledParagraph>
          {t('scheduledTestForStudent.header.studentNumber')}
          <span data-cy="student-number">{studentResult.id}</span>
        </StyledParagraph>
      )}
      <StyledEmailWrapper data-cy="student-email">{studentResult.email}</StyledEmailWrapper>
      <StyledWrapper>
        {/* Only the test owner should be able to invalidate sessions */}
        <InvalidateTest
          enableInvalidateButton={scheduledTest?.ownedBySelf || false}
          isInvalid={studentResult.isInvalid ?? false}
          scheduledTestId={scheduledTestId ? +scheduledTestId : -1}
          studentFullName={`${studentResult.firstName} ${studentResult.lastName}`}
          studentId={studentResult.id}
          onSubmit={() => scheduledTestId && invalidateScheduledTestOfStudent(+scheduledTestId, studentResult.id)}
        />
        <ExercisesList exercises={ownedScheduledTestResults.exercises} student={studentResult} />
      </StyledWrapper>
    </>
  );
});
