import React, { useEffect } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { observer } from 'mobx-react';

import {
  AUTH_PAGES_ROUTES,
  TEACHER_HOME_PAGE_ROUTES,
  TEACHER_SCHEDULED_TEST_PAGE_ROUTES,
  TEACHER_INSIGHT_PAGE_ROUTES,
  TEACHER_SUPPORT_PAGE_ROUTES,
} from 'constants/routes';
import { AuthRoute } from 'layouts/AuthRoute';
import { useProduct } from 'hooks/useProduct';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { useCurrentPathMatch } from 'hooks/useCurrentPathMatch';
import { buildUrlWithPathParams } from '_helpers/utils/urlBuilder';

import { UnderConstructionMessage } from 'components/UnderConstructionMessage/UnderConstructionMessage';
import { TeacherPageLayout } from '../components/TeacherPageLayout/TeacherPageLayout';

import {
  LiveMonitorOverviewPage,
  ScheduledTestRequestPage,
  ScheduledTestsPage,
  BasicProgressOverviewPage,
  BasicProgressForAccountPage,
  EditAccountPage,
  OwnedScheduledTestResultsOverviewPage,
  ScheduledTestResultsForAccountPage,
  StudentLicense,
  WritingResultsOverviewPage,
  ScheduledTestResultsStudent,
  TeacherHomePage,
  DiagnosticPage,
  Groups,
  NewGroup,
  EditGroup,
  WritingResultsForAccount,
} from './subpages';
import { trackPageEvent } from 'analytics/MetabaseTracker/metabase-tracker';
import { useSelectedProductId } from 'hooks/useSelectedProductId';

// Pages where the side menu should not appear
const routesWithoutSideMenu: string[] = [TEACHER_SCHEDULED_TEST_PAGE_ROUTES.SCHEDULED_TEST_RESULTS_FOR_STUDENT];

export const TeacherDashboard: React.FC = observer(() => {
  const { productsList, fetchProducts } = useProduct();
  const selectedProductId = useSelectedProductId();
  const { currentRouteMatchesAny } = useCurrentPathMatch();
  const {
    showWritingResultsPage,
    showWritingResultsForAccountPage,
    showDiagnosticTestProgressOverview,
    showTeacherNavigationDashboardOverview,
  } = useFeatureFlags();

  useEffect(() => {
    trackPageEvent({ category: 'home', type: 'home-landing' });
  }, []);

  useEffect(() => {
    if (!productsList) {
      fetchProducts();
    }
  }, [productsList]);

  const buildURLWithProductId = (path: string) => {
    const productId = selectedProductId ? +selectedProductId : null;
    return buildUrlWithPathParams(path, { productId: `${productId}` });
  };

  if (!productsList) {
    return <></>;
  }

  const shouldShowSideNav = !currentRouteMatchesAny(routesWithoutSideMenu);

  return (
    <TeacherPageLayout withMenu={shouldShowSideNav}>
      <Switch>
        <AuthRoute
          exact
          component={() => (
            <Redirect
              to={
                showTeacherNavigationDashboardOverview
                  ? buildURLWithProductId(TEACHER_HOME_PAGE_ROUTES.DASHBOARD)
                  : buildURLWithProductId(TEACHER_INSIGHT_PAGE_ROUTES.PROGRESS_OVERVIEW)
              }
            />
          )}
          path={TEACHER_HOME_PAGE_ROUTES.HOME_PAGE}
          redirectPath={AUTH_PAGES_ROUTES.LOG_IN}
        />

        <AuthRoute
          exact
          component={
            showTeacherNavigationDashboardOverview
              ? TeacherHomePage
              : () => <Redirect to={buildURLWithProductId(TEACHER_INSIGHT_PAGE_ROUTES.PROGRESS_OVERVIEW)} />
          }
          path={TEACHER_HOME_PAGE_ROUTES.DASHBOARD}
          redirectPath={AUTH_PAGES_ROUTES.LOG_IN}
        />

        <AuthRoute
          component={StudentLicense}
          path={TEACHER_HOME_PAGE_ROUTES.STUDENT_LICENSE}
          redirectPath={AUTH_PAGES_ROUTES.LOG_IN}
        />

        {/* --- Insights --- */}
        <AuthRoute
          exact
          component={BasicProgressOverviewPage}
          path={TEACHER_INSIGHT_PAGE_ROUTES.PROGRESS_OVERVIEW}
          redirectPath={AUTH_PAGES_ROUTES.LOG_IN}
        />
        <AuthRoute
          exact
          component={BasicProgressForAccountPage}
          path={TEACHER_INSIGHT_PAGE_ROUTES.PROGRESS_FOR_ACCOUNT}
          redirectPath={AUTH_PAGES_ROUTES.LOG_IN}
        />
        <AuthRoute
          exact
          component={EditAccountPage}
          path={TEACHER_INSIGHT_PAGE_ROUTES.EDIT_ACCOUNT}
          redirectPath={AUTH_PAGES_ROUTES.LOG_IN}
        />
        <AuthRoute
          exact
          component={UnderConstructionMessage}
          path={TEACHER_INSIGHT_PAGE_ROUTES.ACTIVITY}
          redirectPath={AUTH_PAGES_ROUTES.LOG_IN}
        />
        <AuthRoute
          exact
          component={showWritingResultsPage ? WritingResultsOverviewPage : UnderConstructionMessage}
          path={TEACHER_INSIGHT_PAGE_ROUTES.WRITING_SKILLS}
          redirectPath={AUTH_PAGES_ROUTES.LOG_IN}
        />
        <AuthRoute
          exact
          component={showWritingResultsForAccountPage ? WritingResultsForAccount : UnderConstructionMessage}
          path={TEACHER_INSIGHT_PAGE_ROUTES.WRITING_RESULTS_FOR_ACCOUNT}
          redirectPath={AUTH_PAGES_ROUTES.LOG_IN}
        />
        <AuthRoute
          exact
          component={showDiagnosticTestProgressOverview ? DiagnosticPage : UnderConstructionMessage}
          path={TEACHER_INSIGHT_PAGE_ROUTES.DIAGNOSTIC_TEST}
          redirectPath={AUTH_PAGES_ROUTES.LOG_IN}
        />

        {/* --- Testing --- */}
        <AuthRoute
          exact
          component={ScheduledTestsPage}
          path={TEACHER_SCHEDULED_TEST_PAGE_ROUTES.SCHEDULED_TESTS_OVERVIEW}
          redirectPath={AUTH_PAGES_ROUTES.LOG_IN}
        />
        <AuthRoute
          exact
          component={ScheduledTestRequestPage}
          path={TEACHER_SCHEDULED_TEST_PAGE_ROUTES.SCHEDULED_TEST_REQUEST}
          redirectPath={AUTH_PAGES_ROUTES.LOG_IN}
        />
        <AuthRoute
          exact
          component={ScheduledTestRequestPage}
          path={TEACHER_SCHEDULED_TEST_PAGE_ROUTES.SCHEDULED_TEST_EDIT_REQUEST}
          redirectPath={AUTH_PAGES_ROUTES.LOG_IN}
        />
        <AuthRoute
          exact
          component={LiveMonitorOverviewPage}
          path={TEACHER_SCHEDULED_TEST_PAGE_ROUTES.SCHEDULED_TEST_LIVE_MONITOR_OVERVIEW}
          redirectPath={AUTH_PAGES_ROUTES.LOG_IN}
        />
        <AuthRoute
          exact
          component={OwnedScheduledTestResultsOverviewPage}
          path={TEACHER_SCHEDULED_TEST_PAGE_ROUTES.SCHEDULED_TEST_RESULTS_OVERVIEW}
          redirectPath={AUTH_PAGES_ROUTES.LOG_IN}
        />

        <AuthRoute
          exact
          component={ScheduledTestResultsStudent}
          path={TEACHER_SCHEDULED_TEST_PAGE_ROUTES.SCHEDULED_TEST_RESULTS_FOR_STUDENT}
          redirectPath={AUTH_PAGES_ROUTES.LOG_IN}
        />

        <AuthRoute
          exact
          component={ScheduledTestResultsForAccountPage}
          path={TEACHER_SCHEDULED_TEST_PAGE_ROUTES.SCHEDULED_TESTS_RESULTS_FOR_STUDENT}
          redirectPath={AUTH_PAGES_ROUTES.LOG_IN}
        />

        {/* --- Support --- */}
        <AuthRoute
          exact
          component={UnderConstructionMessage}
          path={TEACHER_SUPPORT_PAGE_ROUTES.GUIDES}
          redirectPath={AUTH_PAGES_ROUTES.LOG_IN}
        />
        <AuthRoute
          exact
          component={UnderConstructionMessage}
          path={TEACHER_SUPPORT_PAGE_ROUTES.CONTACT}
          redirectPath={AUTH_PAGES_ROUTES.LOG_IN}
        />

        {/* --- Group Management --- */}
        <AuthRoute
          exact
          component={Groups}
          path={TEACHER_HOME_PAGE_ROUTES.GROUPS}
          redirectPath={AUTH_PAGES_ROUTES.LOG_IN}
        />
        <AuthRoute
          exact
          component={NewGroup}
          path={TEACHER_HOME_PAGE_ROUTES.NEW_GROUP}
          redirectPath={AUTH_PAGES_ROUTES.LOG_IN}
        />
        <AuthRoute
          exact
          component={EditGroup}
          path={TEACHER_HOME_PAGE_ROUTES.EDIT_GROUP}
          redirectPath={AUTH_PAGES_ROUTES.LOG_IN}
        />
        {/* TODO: Add a 404 page here, currently it renders nothing if no match  */}
      </Switch>
    </TeacherPageLayout>
  );
});
