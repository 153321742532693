import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import { useScheduledTests } from 'hooks/useScheduledTests';
import { useProduct } from 'hooks/useProduct';
import { ProductTestGroupType } from 'models/product/ProductTestGroups';

import { ScheduledTestResultsPageLayout } from '../../components/ScheduledTestResultsPageLayout/ScheduledTestResultsPageLayout';

export const FreebieTestResultsPage: React.FC = observer(() => {
  const { t } = useTranslation('results');

  const { productsList: ownedProducts, fetchProducts } = useProduct();

  const { fetchStudentScheduledTestResultsList, studentScheduledTestResultsList } = useScheduledTests();

  useEffect(() => {
    fetchProducts();
    fetchStudentScheduledTestResultsList(ProductTestGroupType.FREEBIE);
  }, []);

  const hasNoOwnedProducts = !!ownedProducts?.length;

  return (
    <ScheduledTestResultsPageLayout
      dataCy="freebie-test-results"
      noResultsMessage={t('placeholder.noFreebieTestData', 'You have not taken any freebie test yet')}
      ownedProducts={hasNoOwnedProducts}
      testResults={studentScheduledTestResultsList}
      title={t('title.freebieTestPage', 'Freebie tests')}
    />
  );
});
