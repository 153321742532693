import styled from 'styled-components/macro';
import { ThemeProps as Props } from 'models/styles/theme';
import { Heading3 } from 'styles/elements/Headings';

export interface EmailWrapperProps extends Props {
  isIncorrect: boolean;
}

export const StyledInnerWrapper = styled.div<Props>`
  margin-top: 3rem;
  margin-bottom: 3rem;
  width: 80%;

  > ${Heading3} {
    font-weight: normal;
    margin-bottom: 0.1rem;
  }
`;

export const StyledText = styled.div<Props>`
  width: 75%;
`;
