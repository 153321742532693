import { ThemeProps } from 'models/styles/theme';
import styled from 'styled-components/macro';

export const StyledQuestionContainer = styled.div<ThemeProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px solid ${({ theme }: ThemeProps) => theme.palette.neutral._400};

  > span {
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${({ theme }: ThemeProps) => theme.palette.neutral._200};
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin: 1rem;
    font-weight: ${({ theme }: ThemeProps) => theme.font.weight.bold};
  }
`;
