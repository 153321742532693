export const ERROR_TYPES = {
  EVALUATE_QUESTION_FAILED: 'evaluateQuestionFailed',
  GET_DETERMINATION_QUESTIONS_FAILED: 'getDeterminationQuestionsFailed',
  GET_DETERMINATION_QUESTIONS_SESSION_FAILED: 'getDeterminationQuestionsSessionFailed',
  GET_HAS_PRACTICED: 'hasPracticed',
  GET_RECENT_PROGRESS: 'getRecentProgress',
  GET_PRODUCT_DETAILS_FAILED: 'getProductDetailsFailed',
  GET_SUBJECT_DETAILS_FAILED: 'getSubjectsDetailsFailed',
  GET_MODULE_DETAILS_FAILED: 'getModuleDetailsFailed',
  GET_TOPIC_DETAILS_FAILED: 'getTopicDetailsFailed',
  GET_PRODUCT_LIST_FAILED: 'getProductListFailed',
  GET_SCHOOLS_FAILED: 'getSchoolsFailed',
  GET_THEORY_FAILED: 'getTheoryFailed',
  GET_USER_ACCOUNT_DETAILS_FAILED: 'getUserAccountDetailsFailed',
  GET_MODULE_PROGRESS_FAILED: 'getModuleProgressFailed',
  GET_PRODUCT_SUBJECTS_PROGRESS_FAILED: 'getProductSubjectsProgressFailed',
  GET_PRODUCT_PROGRESS_FAILED: 'getProductProgressFailed',
  GET_TOPICS_STATUS_FAILED: 'getTopicsStatus',
  GET_ACCOUNT_PROGRESS_FAILED: 'getAccountProgressFailed',
  GET_MODULE_RESULTS_FAILED: 'getModuleResultsFailed',
  GET_ACCOUNT_RESULTS_FAILED: 'getAccountResultsFailed',
  VALIDATE_USER_LOGIN_FAILED: 'validateUserLoginFailed',
  GET_GOAL_LEVEL_FAILED: 'getGoalLevelFailed',
  UPDATE_GOAL_LEVEL_FAILED: 'updateGoalLevelFailed',
  UPDATE_USER_INFO_LOGIN_FAILED: 'updateUserInfoLoginFailed',
  RESET_SUBJECT_PROGRESS_FAILED: 'resetSubjectProgressFailed',
  GET_ADAPTIVE_PRACTICE_FAILED: 'getAdaptivePracticeTestFailed',
  GET_MIXED_EXERCISE_FAILED: 'getMixedExerciseTestFailed',
  IS_NEXT_QUESTION_READY_FAILED: 'getNextQuestionReadyFailed',
  GET_TEST_GROUPS: 'getTestGroupsFailed',
  GET_DIAGNOSTIC_TEST_GROUPS: 'getDiagnosticTestGroupsFailed',
  GET_DIAGNOSTIC_TEST_LIST: 'getDiagnosticTestListFailed',
  GET_DIAGNOSTIC_TEST_LIST_RESULTS: 'getDiagnosticTestListResultsFailed',
  GET_DIAGNOSTIC_TEST_DETAILS: 'getDiagnosticTestDetailsFailed',
  GET_DIAGNOSTIC_TEST_DETAILS_RESULTS: 'getDiagnosticTestDetailsResultsFailed',
  GET_DIAGNOSTIC_TEST_STATUS: 'getDiagnosticTestStatusFailed',
  GET_EXERCISE_FAILED: 'getExerciseFailed',
  GET_EXERCISE_ANSWERS_FAILED: 'getExerciseAnswersFailed',
  GET_SCHEDULED_TESTS_RESULTS_USER: 'getScheduledTestsResultsUser',
  GET_EXERCISE_PER_SUBJECT_RESULTS: 'getExercisePerSubjectResults',
  GET_EXERCISES_OVER_TIME_RESULTS: 'getExercisesOverTimeResults',
  GET_TOPIC_EXERCISES_STATUS_FAILED: 'getExercisesStatusFailed',
  GET_WRITING_SUBJECTS_STATUS_FAILED: 'getWritingSubjectsStatusFailed',
  GET_OVERVIEW_QUESTION_RESULT_FAILED: 'getOverviewQuestionResultFailed',
  GET_GENERATED_WRITING_REPORT_FAILED: 'getGeneratedWritingReportFailed',
  UPDATE_OVERVIEW_QUESTION_RESULT_FAILED: 'updateOverviewQuestionResultFailed',
  UPDATE_USER_PROFILE_FAILED: 'updateUserDetailsFailed',
  GET_SCHEDULED_TESTS_FAILED: 'getScheduledTestsFailed',
  GET_SCHEDULED_TEST_DETAILS_FAILED: 'getScheduledTestDetailsFailed',
  POST_SCHEDULED_TEST_FAILED: 'postScheduledTestFailed',
  UPDATE_SCHEDULED_TEST_FAILED: 'updateScheduledTestFailed',
  GET_SCHEDULED_TEST_CONTENT_FAILED: 'getScheduledTestContentFailed',
  SCHEDULED_TEST_PASSWORD_REQUIRED: 'scheduledTestPasswordRequired',
  GET_SCHEDULED_TEST_STATUS: 'getScheduledTestStatusFailed',
  GET_SCHEDULED_TEST_EXERCISE_FAILED: 'getScheduledTestExerciseFailed',
  FINISH_SCHEDULED_TEST_FAILED: 'finishScheduledTestFailed',
  GET_SCHEDULED_TEST_LIVE_DATA: 'getScheduledTestLiveDataFailed',
  GET_SCHEDULED_TEST_STUDENT_LOGS: 'getScheduledTestStudentLogsFailed',
  USER_ALREADY_REGISTERED: 'userAlreadyRegistered',
  TRACK_TEST_EVENT_FAILED: 'trackTestEventFailed',
  GET_STUDENT_SCHEDULED_TEST_RESULTS_OVERVIEW_FAILED: 'getStudentScheduledTestResultsOverviewFailed',
  GET_STUDENT_SCHEDULED_TEST_SCORES_FAILED: 'getStudentScheduledTestScoresFailed',
  GET_STUDENTS_PROGRESS_OVERVIEW_FAILED: 'getStudentsProgressOverviewFailed',
  GET_SUBJECTS_PROGRESS_OVERVIEW_FAILED: 'getSubjectsProgressOverviewFailed',
  GET_BASIC_PROGRESS_FOR_ACCOUNT_FAILED: 'getBasicProgressForAccountFailed',
  GET_WRITING_RESULTS_FOR_ACCOUNT_FAILED: 'getWritingProgressForAccountFailed',
  GET_WRITING_SUBJECT_STUDENTS_DETAILS: 'getWritingSubjectStudentsDetails',
  GET_WRITING_SUBJECTS_RESULTS_OVERVIEW_FAILED: 'getwritingSubjectsResultsOverviewFailed',
  GET_WRITING_ACCOUNTS_RESULTS_OVERVIEW_FAILED: 'getWritingAccountsProgressOverviewFailed',
  GET_AVERAGE_KNOWLEDGE_LEVELS_FAILED: 'getAverageKnowledgeLevelsFailed',
  GET_ANSWERED_QUESTIONS_FOR_ACCOUNT_FAILED: 'getAnsweredQuestionsForAccountFailed',
  GET_ACCOUNT_EDUCATIONS_FAILED: 'getUserEducationsFailed',
  GET_REGISTRATION_DETAILS_FAILED: 'getRegistrationDetailsFailed',
  GET_PRODUCT_SCHOOL_YEARS_FAILED: 'getProductSchoolYearsFailed',
  POST_INVITE_STUDENTS_FAILED: 'postInviteStudentsFailed',
  DELETE_INVITE_FAILED: 'deleteInviteFailed',
  GET_FILTERED_ACCOUNTS_FAILED: 'getFilteredAccountsFailed',
  GET_ACCOUNT_DETAILS_FAILED: 'getAccountDetailsFailed',
  UPDATE_ACCOUNT_ADMIN_PANEL: 'updateAccountAdminPanelFailed',
  CREATE_ACCOUNT_ADMIN_PANEL: 'createAccountAdminPanelFailed',
  CREATE_REGISTRATION_FAILED: 'createRegistrationFailed',
  GENERATE_BASIC_PROGRESS_REPORT_FAILED: 'generateBasicProgressReportFailed',
  GET_SCHEDULED_ADMIN_TESTS_FAILED: 'getScheduledAdminTestsFailed',
  GET_ADMIN_PRODUCTS_SKILLS_FAILED: 'getAdminProductsSkillsFailed',
  SIMULATE_LOGIN_AS_TEACHER_FAILED: 'simulateLoginAsTeacherFailed',
  DELETE_ACCOUNTS_FAILED: 'deleteAccountsFailed',
  GET_STUDENT_SCHEDULED_TEST_RESULTS_LIST_FAILED: 'getStudentScheduledTestResultsListFailed',
  GET_SCHEDULED_TEST_RESULTS_OVERVIEW_FAILED: 'getScheduledTestResultsOverviewFailed',
  GET_OWNED_SCHEDULED_TEST_RESULTS_FAILED: 'getOwnedScheduledTestResultsFailed',
  GET_SCHEDULED_TEST_RESULTS_REPORT_FAILED: 'getScheduledTestResultsReportFailed',
  GET_SCHEDULED_ADMIN_TESTS_BY_DATE_FAILED: 'getScheduledAdminTestsByDateFailed',
  GET_ALL_PARTICIPATED_TEST_CODES_FAILED: 'getAllParticipatedTestCodesFailed',
  GET_SCHEDULED_TESTS_REVIEW_PERIOD_TIMERS: 'getScheduledTestsReviewPeriodTimer',
  GET_SCHEDULED_TEST_STATUS_AND_TIME_FAILED: 'getScheduledTestStatusAndTimeFailed',
  GET_SCHEDULED_TEST_RESULTS_CERTIFICATE_FAILED: 'getOwnedScheduledTestResultsFailed',
  GET_LAST_COMPLETED_TESTS_FAILED: 'getLastCompletedTestsFailed',
  GET_DIAGNOSTIC_TESTS_FAILED: 'getDiagnosticTestsFailed',
  POST_INVALIDATE_SCHEDULED_TEST_FAILED: 'postInvalidateScheduledTestFailed',
  ACCEPT_PRODUCT_INVITATION_FAILED: 'acceptProductInvitationFailed',
  ALLOW_STUDENT_TO_REENTER_FAILED: 'allowStudentToReEnterFailed',
  GET_ALL_FEATURE_FLAGS: 'getAllFeatureFlagsFailed',
  GET_TEACHER_GROUPS_FAILED: 'getTeacherGroupsFailed',
  GET_GROUP_FAILED: 'getGroupFailed',
  DELETE_GROUP_FAILED: 'deleteGroupFailed',
  CREATE_GROUP_FAILED: 'createGroupFailed',
  EDIT_GROUP_NAME_FAILED: 'editGroupNameFailed',
  ADD_STUDENTS_TO_GRUP_FAILED: 'addStudentsToGroupFailed',
  REMOVE_STUDENTS_FROM_GRUP_FAILED: 'removeStudentsFromGroupFailed',
  GET_EXERCISE_PREV_ANSWERS_FAILED: 'getExercisePrevAnswersFailed',
  PUT_SCHEDULED_TEST_RESULTS_CERTIFICATE_FAILED: 'updateStudentScheduledTestsResultsCertificate',
  REGISTER_BASIC_ACCOUNT_FAILED: 'registerBasicAccountFailed',
  GET_ACCOUNT_USERS_EDUCATIONS_FAILED: 'getUsersByEducationsForTeacherFailed',
  GET_AVAILABLE_SEB_KEYS_FAILED: 'getAvailableSEBKeysFailed',
  GET_SEB_HEADER_KEY: 'getSEBHeaderKey',
  GET_SEB_AUTHORIZATION: 'getSEBAuthorization',
  UPSERT_SEB_KEY_FAILED: 'upsertSEBKeyFailed',
  FETCH_USER_CAN_DO_SURVEY: 'fetchUserCanDoSurvey',
  PUT_SUBMIT_SURVEY: 'submitSurvey',
  FETCH_TALLY_TOKEN: 'fetchTallyToken',
  FETCH_LAST_PRACTICED_ADAPTIVE_TOPIC_STATUS_FAILED: 'fetchLastPracticedAdaptiveTopicStatusFailed',
  FETCH_LAST_PRACTICED_WRITING_TOPIC_STATUS_FAILED: 'fetchLastPracticedWritingTopicStatusFailed',
  GET_STUDENT_SUBJECT_PROGRESS_OVER_TIME_FAILED: 'getStudentSubjectProgressOverTimeFailed',
  GET_STUDENT_SUBJECT_INSIGHTS_FAILED: 'getStudentSubjectInsightsFailed',
};
