import { authRepository } from 'repositories';

interface UseRequestInterface {
  logout: () => void;
}

export default (): UseRequestInterface => {
  const logout = (): void => {
    authRepository.logout();
  };

  return { logout };
};
