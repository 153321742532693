import React from 'react';
import { useTranslation } from 'react-i18next';

import { User } from 'models/user/User';
import { useDomainHandler } from 'hooks/useDomainHandler';

import img from 'assets/images/bannerBlueBG.jpg';
import { Heading3 } from 'styles/elements/Headings';
import { ParagraphBanner } from 'styles/elements/Texts';
import { StyledMessage, StyledNoOwnedBanner } from './NoOwnedBanner.styled';

interface NoOwnedBannerProps {
  userDetails?: User;
}

export const NoOwnedBanner: React.FC<NoOwnedBannerProps> = ({ userDetails }) => {
  const { t } = useTranslation('homepage');
  const { getCustomNameByDomain } = useDomainHandler();

  return (
    <StyledNoOwnedBanner background={img} data-cy="no-owned-banner">
      <StyledMessage>
        <Heading3>
          {t('noOwned.banner.title', { name: userDetails?.firstName || '', appName: getCustomNameByDomain() })}
        </Heading3>
        <ParagraphBanner>
          {t('noOwned.banner.paragraph', 'Ready to get started? Start your test or look at your previous results.')}
        </ParagraphBanner>
      </StyledMessage>
    </StyledNoOwnedBanner>
  );
};
