import React from 'react';
import { RadioButton } from 'components/RadioButton/RadioButton';
import { Box } from 'styles/helpers/layout';
import { ProductSkillLevel } from 'models/product/ProductDetails';

export interface SkillLevelSelectorProps {
  availableSkillLevels: ProductSkillLevel[];
  selectedRadioButtonIndex: number;
  onRadioButtonChange: (radioButtonIndex: number, selectedSkillLevel: number) => void;
}

export const SkillLevelSelector: React.FC<SkillLevelSelectorProps> = ({
  availableSkillLevels,
  selectedRadioButtonIndex,
  onRadioButtonChange,
}) => {
  return (
    <Box>
      {availableSkillLevels.map((productSkillLevel, index) => {
        return (
          <Box key={index} margin={3}>
            <RadioButton
              bulletColor="primary"
              bulletStyle="thin"
              checked={selectedRadioButtonIndex == index}
              dataCy={`skill-level-radio-button-${productSkillLevel.id}`}
              description={productSkillLevel.description || ''}
              disabled={false}
              label={productSkillLevel.label}
              name={`skill-level-desired-${productSkillLevel.id}`}
              value={productSkillLevel.level}
              onChange={() => {
                onRadioButtonChange(index, productSkillLevel.level);
              }}
            />
          </Box>
        );
      })}
    </Box>
  );
};
