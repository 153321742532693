import styled from 'styled-components/macro';

import { ThemeProps as Props } from 'models/styles/theme';

import { AccordionItem } from 'components/AccordionItem';

// same width as header brand column (as minimum width)
export const StyledNavigationMenu = styled.nav<Props>`
  padding-left: 2rem;
  min-width: 20rem;
`;

export const StyledNavigationItemGroup = styled(AccordionItem)`
  .tab-label .tab-title .tab-title-text {
    font-size: 1rem;
    letter-spacing: initial;
  }
`;
