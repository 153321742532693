import React from 'react';
import { RadioButtonProps } from './RadioButton.model';
import { StyledLabel, StyledCheckmark } from './RadioButton.styled';
import { CssFlex, Typography } from 'styles/helpers/layout';
import { useTheme } from 'styled-components/macro';
import { ElearningThemeInterface } from 'themes/elearningInterface.theme';

export const RadioButton: React.FC<RadioButtonProps> = ({
  label,
  description,
  disabled = false,
  bulletColor = 'secondary',
  bulletStyle = 'thick',
  dataCy,
  ...inputProps
}) => {
  const hogesTheme = useTheme() as ElearningThemeInterface;

  return (
    <StyledLabel $bulletColor={bulletColor} $bulletStyle={bulletStyle} data-cy={dataCy} disabled={disabled}>
      <CssFlex alignContent="center" alignItems="center" marginRight={2}>
        <input disabled={disabled} {...inputProps} data-cy="input-field" type="radio" />
        <StyledCheckmark disabled={disabled} />
      </CssFlex>
      <CssFlex alignItems="flex-start" flexDirection="column">
        <Typography data-cy="radio-button-title" fontWeight={hogesTheme.font.weight.bold}>
          {label}
        </Typography>
        <Typography
          color={hogesTheme.palette.neutral._900}
          data-cy="radio-button-description"
          fontStyle="italic"
          textAlign="initial"
        >
          {description}
        </Typography>
      </CssFlex>
    </StyledLabel>
  );
};
