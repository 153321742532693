import { Button } from 'components/Button';
import { TEACHER_SCHEDULED_TEST_PAGE_ROUTES } from 'constants/routes';
import { useProfile } from 'hooks/useProfile';
import { useScheduledTests } from 'hooks/useScheduledTests';
import { observer } from 'mobx-react';
import { ScheduledTestStatus } from 'models/exam/ScheduledTest';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Heading3, Heading5 } from 'styles/elements/Headings';
import { BasicProgressOverviewPagePathParams } from '../BasicProgress/BasicProgress.model';
import { AvgKnowledgeLevel } from './components/AvgKnowledgeLevel/AvgKnowledgeLevel';
import { LastCompletedTests } from './components/LastCompletedTests/LastCompletedTests';
import { ScheduleTest } from './components/ScheduleTest/ScheduleTest';
import {
  LastCompletedTestsWrapper,
  LiveTestWrapper,
  StyledHeading2,
  StyledHeading4,
  StyledHomePageWrapper,
} from './TeacherHomePage.styled';
import { useProduct } from 'hooks/useProduct';
import { ProductType } from 'constants/hst-constants';
import { useDomainHandler } from 'hooks/useDomainHandler';

export const TeacherHomePage: FC = observer(() => {
  const { t } = useTranslation('teacher-dashboard');

  const { productId }: BasicProgressOverviewPagePathParams = useParams();
  const history = useHistory();
  const { userDetails, setUserDetails, fetchUserDetails } = useProfile();
  const { scheduledTests, setScheduledTests, fetchScheduledTests } = useScheduledTests();
  const { productsList } = useProduct();
  const { getStudentTermByDomain } = useDomainHandler();
  const isTeacherABusinessUser = productsList?.every(product => product.type === ProductType.BUSINESS);

  useEffect(() => {
    if (!userDetails) fetchUserDetails();
    if (!scheduledTests) fetchScheduledTests(ScheduledTestStatus.ONGOING);
    return () => {
      setUserDetails();
      setScheduledTests(null);
    };
  }, []);

  return (
    <StyledHomePageWrapper>
      <StyledHeading2>
        {t('homePage.title', {
          firstName: userDetails?.firstName,
          studentTerm: getStudentTermByDomain({ usePlural: true }),
        })}
      </StyledHeading2>
      {scheduledTests && scheduledTests?.length > 0 && (
        <LiveTestWrapper>
          <span>
            <Heading3>{t('homePage.liveTest.title')}</Heading3>
            <StyledHeading4>
              {t('homePage.liveTest.description', {
                studentTerm: getStudentTermByDomain({ usePlural: true }),
              })}
            </StyledHeading4>
          </span>
          <span>
            <Heading3 data-cy="scheduled-test-count">{scheduledTests?.length}</Heading3>
            <Heading5>{t('homePage.liveTest.test')}</Heading5>
          </span>
          <span>
            <Button
              variant="primary"
              onClick={() => history.push(TEACHER_SCHEDULED_TEST_PAGE_ROUTES.SCHEDULED_TEST_LIVE_MONITOR_OVERVIEW)}
            >
              {t('homePage.liveTest.button')}
            </Button>
          </span>
        </LiveTestWrapper>
      )}
      <LastCompletedTestsWrapper>
        <LastCompletedTests width={isTeacherABusinessUser ? '100%' : '64%'} />
        {!isTeacherABusinessUser && <ScheduleTest />}
      </LastCompletedTestsWrapper>
      <AvgKnowledgeLevel productId={+productId} />
    </StyledHomePageWrapper>
  );
});
