import { useHistory } from 'react-router-dom';

interface RedirectMethods {
  redirectToUrl: (url: string) => void;
}

export const useRedirect = (): RedirectMethods => {
  const history = useHistory();

  const isExternalUrl = (url: string) => url.indexOf('://') !== -1;

  const redirectToUrl = (url: string): void => {
    if (isExternalUrl(url)) {
      window.location.href = url;
    } else {
      history.push(url);
    }
  };

  return {
    redirectToUrl,
  };
};
