import { Education } from 'models/auth/Login';

import { OptionProps } from 'components/Select/Select.model';

export const filterEducations = (
  schoolEducations: Education[],
  selectedEducations: string[],
  currentEducation: string,
): OptionProps[] => {
  const educationsFiltered = schoolEducations.filter(schoolEducation => {
    // Check if the education is added to the user so we can discard it.
    const isEducationAdded = !selectedEducations.some(selectedEducation => selectedEducation === schoolEducation.id);
    // Check if the education is the option selected in the current Select component.
    const isCurrentEducationSelected = schoolEducation.id === currentEducation;
    return isEducationAdded || isCurrentEducationSelected;
  });
  // Return the array of options for the select component.
  return (
    educationsFiltered.map(education => ({
      label: education.title,
      value: education.id,
      disabled: education.archived || false,
    })) || []
  );
};
