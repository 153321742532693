export interface RequestErrorModel {
  type: string;
  extra?: unknown;
  status: number;
  message: string;
  errCode?: string;
}

// Custom error instances to use in frontend
export class RequestError extends Error {
  errCode?: string;

  status: number;

  type: string;

  extra?: unknown;

  constructor({ errCode, message, status, type, extra }: RequestErrorModel) {
    super(message);
    this.errCode = errCode;
    this.status = status;
    this.type = type;
    this.extra = extra;
  }
}

// Interface with error structure as returned from the API gateway
interface ApiRequestErrorExtraData {
  errCode: string;
}

export interface ApiRequestError {
  type: string;
  message: string;
  extra?: ApiRequestErrorExtraData;
}
