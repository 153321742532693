import { FontAwesomeIcon } from 'components/FontAwesomeIcon';
import { Paginator } from 'components/PaginatorRemake/Paginator';
import { SkeletonLoader } from 'components/SkeletonLoader/SkeletonLoader';
import { TEACHER_HOME_PAGE_ROUTES, TEACHER_INSIGHT_PAGE_ROUTES } from 'constants/routes';
import { useScheduledTests } from 'hooks/useScheduledTests';
import { useTeacherOverview } from 'hooks/useTeacherOverview';
import { observer } from 'mobx-react';
import { SubjectsProgressOverviewFilters } from 'models/progress/Progress';
import { TeacherReportType } from 'models/report/Report';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import { TeacherDashboardFilters } from '../../components/TeacherDashboardFilters';
import { TestDetails } from './components/TestDetails/TestDetails';
import { StyledDiagnosticInnerWrapper, StyledDiagnosticWrapper, StyledHeading3 } from './DiagnosticPage.styled';

export const DiagnosticPage: FC = observer(() => {
  const { t } = useTranslation('teacher-dashboard');

  const numPages = 100;
  const {
    page,
    productId,
    currentEducationTitle,
    nonOwnedProductError,
    currentYearsTitle,
    showEducationFilter,
    showSchoolYearFilter,
    userEducations,
    productSchoolYears,
    paginationFilters,
    buildPaginatedURL,
  } = useTeacherOverview(TEACHER_INSIGHT_PAGE_ROUTES.WRITING_SKILLS);

  const { diagnosticTestDetails, fetchDiagnosticTestDetails } = useScheduledTests();

  useEffect(() => {
    const filters: SubjectsProgressOverviewFilters = {
      educationId: paginationFilters?.educationId,
      searchTerm: paginationFilters?.searchTerm,
      groupId: paginationFilters?.groupId,
      schoolYearEnd: paginationFilters?.schoolYearEnd,
      schoolYearStart: paginationFilters?.schoolYearStart,
    };

    if (!diagnosticTestDetails) fetchDiagnosticTestDetails(filters);
  }, [diagnosticTestDetails]);

  if (!Number(productId) || nonOwnedProductError) {
    return <Redirect to={TEACHER_HOME_PAGE_ROUTES.DASHBOARD} />;
  }

  return (
    <StyledDiagnosticWrapper>
      <TeacherDashboardFilters
        buildPaginatedURL={buildPaginatedURL}
        currentEducationTitle={currentEducationTitle}
        currentYearsTitle={currentYearsTitle}
        productId={+productId}
        productSchoolYears={productSchoolYears}
        showEducationFilter={showEducationFilter}
        showSchoolYearFilter={showSchoolYearFilter}
        teacherReportType={TeacherReportType.DIAGNOSTIC_PROGRESS}
        userEducations={userEducations}
      />

      <StyledDiagnosticInnerWrapper>
        <div>
          <StyledHeading3>{t('diagnosticTest.title')}</StyledHeading3>
          <FontAwesomeIcon iconName="far fa-angle-down" iconSize="2x" />
        </div>
        {diagnosticTestDetails ? (
          <TestDetails diagnosticTestDetails={diagnosticTestDetails} />
        ) : (
          <SkeletonLoader $marginTop={4} />
        )}
      </StyledDiagnosticInnerWrapper>

      {page && numPages > 1 && (
        <Paginator currentPage={page} linkBuilderFunction={buildPaginatedURL} totalPages={numPages} />
      )}
    </StyledDiagnosticWrapper>
  );
});
