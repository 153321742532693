import styled from 'styled-components/macro';
import { CssButton, CssGrid } from 'styles/helpers/layout';
import { ThemeProps as Props } from 'models/styles/theme';
import { QuestionStatus } from 'constants/exam-constants';
import { ElearningThemeInterface } from 'themes/elearningInterface.theme';

export interface StyledAnswerBoxProps extends Props {
  status: QuestionStatus;
}

const handleColor = (status: QuestionStatus, theme: ElearningThemeInterface) => {
  switch (status) {
    case QuestionStatus.CORRECT:
      return theme.palette.feedback.success.brand;
    case QuestionStatus.INCORRECT:
      return theme.palette.feedback.error.brand;
    default:
      return theme.palette.primary.brand;
  }
};

const handleBackgroundColor = (status: QuestionStatus, theme: ElearningThemeInterface) => {
  switch (status) {
    case QuestionStatus.CORRECT:
      return theme.palette.feedback.success._200;
    case QuestionStatus.INCORRECT:
      return theme.palette.feedback.error._200;
    default:
      return theme.palette.neutral.white;
  }
};

const handleBarColor = (status: QuestionStatus, theme: ElearningThemeInterface) => {
  switch (status) {
    case QuestionStatus.CORRECT:
      return theme.palette.feedback.success._400;
    default:
      return theme.palette.secondary._400;
  }
};

export const StyledAnswerBox = styled(CssGrid)<StyledAnswerBoxProps>`
  border-top: 1px solid ${({ status, theme }: StyledAnswerBoxProps) => handleBarColor(status, theme)};
  background: ${({ status, theme }: StyledAnswerBoxProps) => handleBackgroundColor(status, theme)};
  bottom: 0;
  > :first-child {
    > button {
      background: ${({ status, theme }: StyledAnswerBoxProps) => handleBackgroundColor(status, theme)};
    }
  }
`;

export const StyledNextButton = styled(CssButton)<StyledAnswerBoxProps>`
  background: ${({ status, theme }: StyledAnswerBoxProps) => handleColor(status, theme)};
  color: ${({ status, theme }: StyledAnswerBoxProps) => handleBackgroundColor(status, theme)};

  &:hover {
    background: ${({ status, theme }: StyledAnswerBoxProps) => handleColor(status, theme)};
    color: ${({ status, theme }: StyledAnswerBoxProps) => handleBackgroundColor(status, theme)};
  }

  &:disabled {
    opacity: 0.3;
  }
`;
