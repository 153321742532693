import styled from 'styled-components/macro';

import { ThemeProps as Props } from 'models/styles/theme';

import { SortableTable } from 'components/SortableTable/SortableTable';
import { StyledCell, StyledHeaderCell } from 'components/SortableTable/SortableTable.styled';
import { FontAwesomeIcon } from 'components/FontAwesomeIcon';
import { layout, LayoutProps } from 'styled-system';

export const StyledSortableTable = styled(SortableTable)<Props & LayoutProps>`
  ${layout}
  ${StyledHeaderCell}, ${StyledCell} {
    padding: 1rem 1.5rem;
  }

  ${StyledHeaderCell} {
    button {
      width: 100%;
      text-align: left;
    }
  }
`;

export const StyledFontAwesomeIcon = styled(FontAwesomeIcon)<Props>`
  color: green;
`;
