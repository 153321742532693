export enum WordAction {
  NONE = 'none',
  EDIT_WORD = 'edit',
  REMOVE_WORD = 'remove',
  ADD_WORD_AFTER = 'add-after',
  MERGE_WORDS = 'merge',
  SWAP_WORDS = 'swap',
}

export enum CorrectTextQuestionWordState {
  NONE,
  ACTIVE,
  CHANGED,
  ANSWER_CORRECT,
  ANSWER_INCORRECT,
  ANSWER_MISSED,
  ANSWER_UNNECESSARY,
  ANSWER_MISSED_DELETE,
}

export enum CorrectTextViewMode {
  EDITING,
  RESULTS,
  COMPARE,
}

export type WordActionUpdate = {
  wordId: number;
  action: Omit<WordAction, WordAction.NONE>;
  originalValue: string;
  updatedValue: string;
};
