import sanitizeHtml from 'sanitize-html';

export const commonSanitizeOptions = {
  ...sanitizeHtml.defaults,
  allowedTags: [...sanitizeHtml.defaults.allowedTags, 'img'],
  allowedClasses: {
    '*': ['vraag', 'opdracht'],
  },
  allowedAttributes: {
    ...sanitizeHtml.defaults.allowedAttributes,
    img: [...sanitizeHtml.defaults.allowedAttributes.img, 'style'],
  },
};
