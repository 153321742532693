import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Formik, FormikHelpers } from 'formik';

import { Heading2 } from 'styles/elements/Headings';

import { AUTH_PAGES_ROUTES } from 'constants/routes';
import { useDomainHandler } from 'hooks/useDomainHandler';

import { useReactTooltip } from 'hooks/useReactTooltip';
import { useRegistration } from 'hooks/useRegistration';

import { Link } from 'components/Link';
import { PasswordInput } from 'components/PasswordInput';
import { InputWithErrorMessage } from 'components/InputWithErrorMessage';

import { TermsFooter } from '../components/TermsFooter/TermsFooter';

import {
  StyledPageLayout,
  StyledContainer,
  StyledLogo,
  StyledForm,
  StyledField,
  StyledFieldRow,
  StyledButton,
  StyledLoginSection,
  StyledCheckList,
  StyledCheckListItem,
} from './CreateBasicAccountPage.styled';
import { BasicAccountFormFields, buildBasicAccountSchema } from './BasicAccountForm.schema';
import { useHistory } from 'react-router-dom';

export const CreateBasicAccountPage: React.FC = () => {
  useReactTooltip();
  const { t } = useTranslation('auth-pages');
  const { createBasicAccount } = useRegistration();
  const history = useHistory();
  const { getCustomLogoByDomain } = useDomainHandler();

  const onFormSubmit = (values: BasicAccountFormFields, actions: FormikHelpers<BasicAccountFormFields>) => {
    createBasicAccount(values).then(newAccountCreated => {
      actions.setSubmitting(false);
      if (newAccountCreated) {
        actions.resetForm();
        history.push(AUTH_PAGES_ROUTES.REGISTER_ACCOUNT_FINISHED);
      }
    });
  };

  return (
    <StyledPageLayout>
      <StyledContainer>
        <StyledLogo iconName={getCustomLogoByDomain()} />
        <Heading2>{t('createBasicAccountPage.header', 'Create an account')}</Heading2>
        <Formik
          initialValues={
            {
              firstName: '',
              middleName: '',
              lastName: '',
              email: '',
              password: '',
            } as BasicAccountFormFields
          }
          validationSchema={buildBasicAccountSchema()}
          onSubmit={onFormSubmit}
        >
          {formik => (
            <StyledForm noValidate onSubmit={formik.handleSubmit}>
              <StyledFieldRow>
                <StyledField>
                  <label htmlFor="firstName">
                    {t('createBasicAccountPage.form.fields.firstName.label', 'First Name')}
                  </label>
                  <InputWithErrorMessage
                    dataCy="first-name-input"
                    errorMessage={formik.errors.firstName}
                    hasError={!!(formik.errors.firstName && formik.touched.firstName)}
                    name="firstName"
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                  />
                </StyledField>
              </StyledFieldRow>
              <StyledFieldRow>
                <StyledField>
                  <label htmlFor="middleName">
                    {t('createBasicAccountPage.form.fields.middleName.label', 'Middle Name')}
                  </label>
                  <InputWithErrorMessage
                    dataCy="middle-name-input"
                    errorMessage={formik.errors.middleName}
                    hasError={!!(formik.errors.middleName && formik.touched.middleName)}
                    name="middleName"
                    value={formik.values.middleName}
                    onChange={formik.handleChange}
                  />
                </StyledField>

                <StyledField>
                  <label htmlFor="lastName">
                    {t('createBasicAccountPage.form.fields.lastName.label', 'Last Name')}
                  </label>
                  <InputWithErrorMessage
                    dataCy="last-name-input"
                    errorMessage={formik.errors.lastName}
                    hasError={!!(formik.errors.lastName && formik.touched.lastName)}
                    name="lastName"
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                  />
                </StyledField>
              </StyledFieldRow>

              <StyledFieldRow>
                <StyledField>
                  <label htmlFor="email">{t('createBasicAccountPage.form.fields.email.label', 'Email')}</label>
                  <InputWithErrorMessage
                    dataCy="email-input"
                    errorMessage={formik.errors.email}
                    hasError={!!(formik.errors.email && formik.touched.email)}
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                  />
                </StyledField>
              </StyledFieldRow>

              <StyledFieldRow>
                <StyledField>
                  <label
                    data-tip={t(
                      'createBasicAccountPage.form.fields.password.tip',
                      'The password must contain letters, numbers and special characters from this list: !@#$%^&*()',
                    )}
                    htmlFor="password"
                  >
                    {`${t('createBasicAccountPage.form.fields.password.label', 'Password')}*`}
                  </label>
                  <PasswordInput
                    dataCy="password-input"
                    errorMessage={formik.errors.password}
                    hasError={!!(formik.errors.password && formik.touched.password)}
                    name="password"
                    type="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                  />
                  <StyledCheckList>
                    <StyledCheckListItem
                      data-cy="password-lowercase-indicator"
                      hidden={!/(?=.*[a-z])/.test(formik.values.password)}
                    >
                      {t(
                        'createBasicAccountPage.form.fields.password.requisites.mustIncludeLowercase',
                        'one lowercase',
                      )}
                    </StyledCheckListItem>
                    <StyledCheckListItem
                      data-cy="password-uppercase-indicator"
                      hidden={!/(?=.*[A-Z])/.test(formik.values.password)}
                    >
                      {t(
                        'createBasicAccountPage.form.fields.password.requisites.mustIncludeUppercase',
                        'one uppercase',
                      )}
                    </StyledCheckListItem>
                    <StyledCheckListItem
                      data-cy="password-number-indicator"
                      hidden={!/(?=.*[0-9])/.test(formik.values.password)}
                    >
                      {t('createBasicAccountPage.form.fields.password.requisites.mustIncludeNumbers', 'one number')}
                    </StyledCheckListItem>
                    <StyledCheckListItem
                      data-cy="password-special-char-indicator"
                      hidden={!/(?=.*[!@#$%^&*()])/.test(formik.values.password)}
                    >
                      {t(
                        'createBasicAccountPage.form.fields.password.requisites.mustIncludeSpecialChars',
                        'one special character',
                      )}
                    </StyledCheckListItem>
                    <StyledCheckListItem
                      data-cy="password-min-length-indicator"
                      hidden={formik.values.password.length < 8}
                    >
                      {t(
                        'createBasicAccountPage.form.fields.password.requisites.mustReachMinLength',
                        '8 characters minimun',
                      )}
                    </StyledCheckListItem>
                  </StyledCheckList>
                </StyledField>
              </StyledFieldRow>

              <StyledButton
                dataCy="create-account-button"
                disabled={formik.isSubmitting}
                type="submit"
                variant="primary"
              >
                {t('createBasicAccountPage.form.submitButton.label', 'Register')}
              </StyledButton>
            </StyledForm>
          )}
        </Formik>
        <StyledLoginSection>
          <Trans i18nKey="createBasicAccountPage.alreadyHaveAccount" t={t}>
            {'Already have an account? '}
            <Link data-cy="go-to-login-button" to={AUTH_PAGES_ROUTES.LOG_IN} variant="tertiary">
              Login
            </Link>
          </Trans>
        </StyledLoginSection>
      </StyledContainer>
      <TermsFooter />
    </StyledPageLayout>
  );
};
