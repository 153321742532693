import React from 'react';
import { Input } from 'components/Input';
import { StyledErrorMessage } from './InputWithErrorMessage.styled';
import { InputWithErrorMessageProps } from './InputWithErrorMessage.model';

export const InputWithErrorMessage: React.FC<InputWithErrorMessageProps> = ({
  dataCy,
  hasError,
  errorMessage,
  ...inputProps
}) => (
  <>
    <Input dataCy={dataCy} hasError={hasError} {...inputProps} />
    {hasError && <StyledErrorMessage data-cy={`error-${dataCy}`}>{errorMessage}</StyledErrorMessage>}
  </>
);
