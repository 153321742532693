import { FormLabel } from 'pages/TeacherEnvironment/TeacherDashboard/subpages/ScheduledTestWizard/steps/FormStep.styled';
import styled from 'styled-components/macro';

export const StyledFormLabel = styled(FormLabel)`
  margin-top: 1rem;
  line-height: 0.3rem;
`;

export const StyledButtonWrapper = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 2rem;
`;
