import React from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { useRedirect } from 'hooks/useRedirect';
import { NavigationMenuItemProps } from './NavigationMenuItem.model';
import { StyledNavigationMenuItem } from './NavigationMenuItem.styled';

export const NavigationMenuItem: React.FC<NavigationMenuItemProps> = ({
  children,
  dataCy,
  onlyActiveOnExactPath = false,
  redirectPath,
}) => {
  const location = useLocation();
  const { redirectToUrl } = useRedirect();

  const currentLocationExactlyMatchesRedirectPath = !!useRouteMatch({ path: redirectPath, exact: true });

  const isActive = onlyActiveOnExactPath
    ? currentLocationExactlyMatchesRedirectPath
    : location.pathname.includes(redirectPath);

  return (
    <StyledNavigationMenuItem
      className={isActive ? 'active' : ''}
      data-cy={dataCy}
      selected={isActive}
      onClick={() => redirectToUrl(redirectPath)}
    >
      {children}
    </StyledNavigationMenuItem>
  );
};
