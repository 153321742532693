import styled from 'styled-components/macro';

import { ThemeProps } from 'models/styles/theme';
import { Button } from 'components/Button';
import { InputWithErrorMessage } from 'components/InputWithErrorMessage';
import { Select } from 'components/Select';
import { calculateRem } from 'styles/mixins/calculateRem';
import { ToggleSwitch } from 'components/ToggleSwitch/ToggleSwitch';

export const StyledBasicInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const StyledInput = styled(InputWithErrorMessage)<ThemeProps>`
  width: 410px;

  &:disabled {
    background-color: ${({ theme }: ThemeProps) => theme.palette.neutral._200};
  }
`;

export const StyledSelect = styled(Select)`
  width: 410px;
`;

export const StyledButton = styled(Button)<ThemeProps>`
  font-size: ${({ theme }: ThemeProps) => theme.font.size._16};
  font-weight: ${({ theme }: ThemeProps) => theme.font.weight.bold};
  margin-top: 40px;

  &:first-of-type {
    margin-right: ${calculateRem(20)};
  }
`;

export const StyledField = styled.div<ThemeProps>`
  display: flex;
  flex-direction: column;
  margin-top: 21px;

  label {
    font-weight: ${({ theme }: ThemeProps) => theme.font.weight.bold};
    margin-bottom: 5px;
  }

  p {
    color: ${({ theme }: ThemeProps) => theme.palette.neutral._900};
    font-size: ${({ theme }: ThemeProps) => theme.font.size._14};
  }

  ${StyledButton} {
    width: fit-content;
  }
`;

export const StyledLoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledToggleSwitch = styled(ToggleSwitch)`
  display: grid;
  grid-template-columns: 1fr auto;
  width: 30rem;
  max-width: 100%;
`;
