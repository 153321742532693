import axios, { AxiosError } from 'axios';
import cookieStorage from 'persistence';
import { handlingResponse, logError } from 'repositories/utils';

import { ERROR_TYPES } from 'constants/error-constants';
import { FeatureFlagsSet } from 'models/featureFlag/FeatureFlagsSet';
import { RequestError, ApiRequestError as Error } from 'models/error/Error';

export type ConfigCatClientInterface = {
  fetchAllFeatureFlags: () => Promise<FeatureFlagsSet>;
};

export const createConfigCatRepository = (): ConfigCatClientInterface => {
  const fetchAllFeatureFlags = async (): Promise<FeatureFlagsSet> => {
    try {
      const result = await axios.get('/api/configcat/feature-flags', {
        headers: { Authorization: cookieStorage.getToken() },
      });
      return handlingResponse<FeatureFlagsSet>([200], 'Error retrieving features flags')(result);
    } catch (error) {
      const { message, status } = logError(error as AxiosError<Error>);
      throw new RequestError({ message, status, type: ERROR_TYPES.GET_ALL_FEATURE_FLAGS });
    }
  };

  return {
    fetchAllFeatureFlags,
  };
};
