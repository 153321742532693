import { Variant } from 'components/Button/Button.model';
import { css, FlattenSimpleInterpolation } from 'styled-components/macro';
import { getButtonColorPalette } from 'styles/helpers/button';
import { ElearningThemeInterface } from 'themes/elearningInterface.theme';

interface Props {
  theme: ElearningThemeInterface;
  variant: Variant;
  disabled?: boolean;
}

export const buttonColorsMixin = (props: Props): FlattenSimpleInterpolation => {
  const palette = getButtonColorPalette(props.theme, props.variant, props.disabled ?? false);
  return css`
    color: ${palette.color.default};
    background: ${palette.background.default};
    &:hover {
      color: ${palette.color.hover};
      background: ${palette.background.hover};
    }
    &:focus {
      color: ${palette.color.focus};
      background: ${palette.background.focus};
    }
    &:disabled {
      cursor: not-allowed;
    }
    &:disabled:hover {
      text-decoration: none;
    }
  `;
};
