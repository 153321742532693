import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainerProps } from 'react-toastify';

import { StyledToastNotification } from './ToastNotification.styled';

export const ToastNotification: React.FC<ToastContainerProps> = props => (
  <div data-cy="toast-notification-container">
    <StyledToastNotification
      closeOnClick
      draggable
      hideProgressBar
      newestOnTop
      autoClose={3000}
      pauseOnHover={false}
      position={toast.POSITION.TOP_CENTER}
      toastClassName="toast-notification"
      {...props}
    />
  </div>
);
