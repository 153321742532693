import styled from 'styled-components/macro';
import { ElearningThemeInterface } from 'themes/elearningInterface.theme';
import { Heading3 } from 'styles/elements/Headings';

interface Props {
  theme: ElearningThemeInterface;
}

export const StyledMessageText = styled(Heading3)<Props>`
  font-weight: ${({ theme }: Props) => theme.font.weight.normal};
`;
