import { Select } from 'components/SelectUI/Select';
import { SkillsSelectorType } from 'constants/teacher-constants';
import React, { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Box } from 'styles/helpers/layout';

interface SkillsSelectorProps {
  skillTypes?: SkillsSelectorType[];
  onChange: (skillsSelectorType: SkillsSelectorType) => void;
}

export const SkillsSelector: FC<SkillsSelectorProps> = ({ skillTypes = [], onChange }) => {
  const [selectedOption, setSelectedOption] = useState('0');
  const { t } = useTranslation('teacher-dashboard');

  const skillsOptions = useMemo(
    () =>
      skillTypes.map((skill, index) => ({
        value: index,
        label: t(skill.toString()),
      })),
    [skillTypes],
  );

  return (
    <Box>
      <Typography fontSize="0.75rem">{t('selectors.skillSelectorLabel')}</Typography>
      <Select
        dataCy="skill-insight-type-selector"
        options={skillsOptions}
        showPlaceholderInOptionList={false}
        value={selectedOption}
        onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
          const selectedValue = event.target.value;
          setSelectedOption(selectedValue);
          onChange(skillTypes[+selectedValue]);
        }}
      />
    </Box>
  );
};
