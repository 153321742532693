import styled from 'styled-components/macro';
import { ElearningThemeInterface } from 'themes/elearningInterface.theme';
import LevelDashProps from './LevelDash.model';

interface Props extends LevelDashProps {
  theme: ElearningThemeInterface;
  $size: number;
  $isStateless?: boolean;
}

export const StyledLevelDash = styled.div<Props>`
  height: 0.5rem;
  width: ${({ $size }) => $size}rem;
  border-radius: 0.375rem;
  background-color: ${(props: Props) => {
    if (props.$isStateless) {
      return props.level === 'default' ? props.theme.palette.secondary._400 : props.theme.palette.secondary.brand;
    }

    switch (props.level) {
      case 'custom':
        return props.theme.palette.secondaryNew.brand;
      case 'basics':
        return props.theme.palette.feedback.error.brand;
      case 'initial':
        return props.theme.palette.accent._05;
      case 'improvement':
        return props.theme.palette.feedback.inProgress.brand;
      case 'good':
        return props.theme.palette.feedback.success.brand;
      case 'excellent':
        return props.theme.palette.accent._04;
      default:
        return props.theme.palette.neutral._400;
    }
  }};
`;
