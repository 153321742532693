import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import { useProductProgress } from 'hooks/useProductProgress';
import { QuestionStatus } from 'constants/exam-constants';
import { StyledButton } from './NextQuestion.styled';
import { NextQuestionProps } from './NextQuestion.model';
import { useAnswer } from 'hooks/useAnswer';
import { useScheduledTests } from 'hooks/useScheduledTests';

export const NextQuestion: React.FC<NextQuestionProps> = observer(
  ({ questions, label, showButtonWhileEvaluating = false }) => {
    const { t } = useTranslation('skill-determination-test');

    const {
      setShowProgressButton,
      error,
      setError,
      showProgressButton,
      isEvaluatingAnswer,
      setQuestionTip,
      setQuestionStatus,
      resetAnswerButtonProps,
    } = useAnswer();
    const { selectedQuestion, shouldClickNextQuestion, setSelectedQuestion, setShouldClickNextQuestion } =
      useScheduledTests();
    const { setModuleSubjectsProgress } = useProductProgress();

    const goNextQuestion = () => {
      if (error) {
        setError(null);
        setShouldClickNextQuestion(false);
        return;
      }

      if (questions) {
        setShowProgressButton(false);
        setQuestionTip(null);

        const lastQuestion = questions.length - 1;
        if (selectedQuestion.index < lastQuestion) {
          setSelectedQuestion({
            results: [],
            index: selectedQuestion.index + 1,
            status: QuestionStatus.INITIAL,
          });
        } else {
          setSelectedQuestion({
            results: [],
            status: QuestionStatus.INITIAL,
            index: questions.length,
          });
          setModuleSubjectsProgress([]);
        }
        setShouldClickNextQuestion(false);
        setQuestionStatus(QuestionStatus.INITIAL);
        resetAnswerButtonProps();
      }
    };

    useEffect(() => {
      if (shouldClickNextQuestion) {
        goNextQuestion();
      }
    }, [shouldClickNextQuestion]);

    const shouldShowForwardButton = showProgressButton || (showButtonWhileEvaluating && isEvaluatingAnswer);

    return (
      <div>
        {shouldShowForwardButton && (
          <StyledButton
            dataCy="go-forward-button"
            isLoading={isEvaluatingAnswer}
            variant="primary"
            onClick={() => goNextQuestion()}
          >
            {label || t('button.continue.label', 'Continue')}
          </StyledButton>
        )}
      </div>
    );
  },
);
