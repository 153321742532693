import axios, { AxiosError } from 'axios';
import { ERROR_TYPES } from 'constants/error-constants';
import { handlingResponse, logError } from 'repositories/utils';
import { RequestError, ApiRequestError as Error } from 'models/error/Error';
import cookieStorage from 'persistence';
import {
  AdaptivePracticeQuestion,
  MixedExerciseForSubject,
  MixedExerciseForTopic,
  NextQuestionIsReadyResponse,
  HasPracticed,
} from 'models/adaptive-practice/AdaptivePractice';
import { ContentAPI } from 'generated/types';

export const fetchAdaptivePracticeQuestion = async (
  productId: number,
  subjectId: number,
  topicId?: number,
): Promise<AdaptivePracticeQuestion> => {
  try {
    const result = await axios.get(`/api/products/${productId}/subjects/${subjectId}/adaptive-practice`, {
      headers: { Authorization: cookieStorage.getToken() },
      params: { topicId },
    });
    return handlingResponse<AdaptivePracticeQuestion>(
      [200],
      'Error retrieving question',
      'adaptivePracticeTestError',
    )(result);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<Error>);
    throw new RequestError({
      message,
      status,
      type: ERROR_TYPES.GET_ADAPTIVE_PRACTICE_FAILED,
      extra: (error as AxiosError<ContentAPI.ErrorMessage>)?.response?.data?.extra,
    });
  }
};

export const fetchMixedExerciseQuestion = async (
  params: MixedExerciseForSubject | MixedExerciseForTopic,
): Promise<AdaptivePracticeQuestion> => {
  const { productId, subjectId } = params;
  try {
    const result = await axios.get(`/api/products/${productId}/subjects/${subjectId}/mixed-exercise`, {
      headers: { Authorization: cookieStorage.getToken() },
      params: { topicId: (params as MixedExerciseForTopic)?.topicId },
    });
    return handlingResponse<AdaptivePracticeQuestion>([200], 'Error retrieving test', 'mixedExerciseTestError')(result);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<Error>);
    throw new RequestError({ message, status, type: ERROR_TYPES.GET_MIXED_EXERCISE_FAILED });
  }
};

export const isNextQuestionReady = async (testToken: string): Promise<NextQuestionIsReadyResponse> => {
  try {
    const result = await axios.get('/api/tests/next-question-ready', {
      headers: {
        Authorization: cookieStorage.getToken(),
        'hst-content-test-token': testToken,
      },
    });
    return handlingResponse<NextQuestionIsReadyResponse>(
      [200],
      'Error checking for progress status',
      'nextQuestionReadyError',
    )(result);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<Error>);
    throw new RequestError({ message, status, type: ERROR_TYPES.IS_NEXT_QUESTION_READY_FAILED });
  }
};

export const hasPracticed = async (): Promise<HasPracticed> => {
  try {
    const result = await axios.get('/api/has-practiced', {
      headers: { Authorization: cookieStorage.getToken() },
    });
    return handlingResponse<HasPracticed>([200], 'Error checking if user has practiced', 'hasPracticedError')(result);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<Error>);
    throw new RequestError({ message, status, type: ERROR_TYPES.GET_HAS_PRACTICED });
  }
};
