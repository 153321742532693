import React from 'react';

import LinkProps from './Link.model';
import { StyledLink } from './Link.styled';

export const Link: React.FC<LinkProps> = ({
  variant = 'default',
  borderVariant = 'default',
  fontColor,
  size = 'default',
  to,
  children,
  isBlock = false,
  disabled = false,
  external = false,
  onClick,
  ...props
}: LinkProps) => {
  return (
    <StyledLink
      $borderVariant={borderVariant}
      $disabled={disabled}
      $fontColor={fontColor}
      $isBlock={isBlock}
      $size={size}
      $variant={variant}
      target={external ? '_blank' : ''}
      to={external ? { pathname: to } : to}
      onClick={onClick}
      {...props}
    >
      {children}
    </StyledLink>
  );
};
