import styled from 'styled-components/macro';

import { ThemeProps as Props } from 'models/styles/theme';

export const StyledOverviewQuestion = styled.div<Props>`
  margin-top: 1rem;
  margin-bottom: 2rem;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const StyledFragment = styled.div<Props>`
  position: relative;
  width: 49%;
`;
