import React from 'react';
import { observer } from 'mobx-react';
import {
  StyledDiagnosticTestContentTitle,
  StyledDiagnosticTestContentSubtitle,
  StyledDiagnosticTestContentDescription,
} from './DiagnosticTestContent.styled';
import { DiagnosticTestContentProps } from './DiagnosticTestContent.model';

export const DiagnosticTestContent: React.FC<DiagnosticTestContentProps> = observer(
  ({ title, subtitle, description, children }) => (
    <div>
      <StyledDiagnosticTestContentTitle data-cy="diagnostic-content-title">{title}</StyledDiagnosticTestContentTitle>
      <StyledDiagnosticTestContentSubtitle data-cy="diagnostic-content-subtitle">
        {subtitle}
      </StyledDiagnosticTestContentSubtitle>
      <StyledDiagnosticTestContentDescription data-cy="diagnostic-content-description">
        {description}
      </StyledDiagnosticTestContentDescription>
      {children}
    </div>
  ),
);
