import styled from 'styled-components/macro';

import { ThemeProps as Props } from 'models/styles/theme';

export const StyledButtons = styled.div`
  display: flex;
  flex-direction: column;
  > button {
    font-weight: ${(props: Props) => props.theme.font.weight.bold};
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
    color: #000000;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.6px;
    line-height: 20px;
    text-align: center;
    > span {
      display: flex;
      flex: 1;
      justify-content: center;
      align-items: center;
    }
  }
`;
