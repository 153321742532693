import React from 'react';
import { useModal } from 'hooks/useModal';
import { IconButton } from 'components/IconButton/IconButton';
import { ToolTipModalProps } from './TooltipModal.model';
import { StyledTooltipModal, StyledHeading, StyledParagraph } from './TooltipModal.styled';

export const TooltipModal: React.FC<ToolTipModalProps> = ({ dataCy, modalId, content }) => {
  const { closeModal, isModalOpen, toggleModal } = useModal();
  const { title, paragraph } = content;
  return (
    <>
      <IconButton
        dataCy={`open-tooltip-modal-${dataCy}`}
        fontColor="grey"
        iconName="infoIcon"
        iconSize={1.25}
        onClick={() => toggleModal(modalId)}
      />
      <StyledTooltipModal
        closeOnClickOutside
        wrapInModalBox
        dataCy={dataCy}
        isOpen={isModalOpen(modalId)}
        onClose={closeModal}
      >
        <StyledHeading>{title}</StyledHeading>
        <StyledParagraph>{paragraph}</StyledParagraph>
      </StyledTooltipModal>
    </>
  );
};
