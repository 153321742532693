import React from 'react';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { useReactTooltip } from 'hooks/useReactTooltip';

import { SvgIconReact } from 'components/SvgIconReact';

import {
  StyledScheduledTestItem,
  StyledTestName,
  StyledTestCode,
  StyledCollapseButton,
  StyledProductFlag,
  StyledGeneralInformation,
  StyledInfoRow,
  StyledMetadataContainer,
  StyledTestLevel,
  StyledDateMetadata,
  StyledTimeMetadata,
} from './ScheduledTestItem.styled';
import { ScheduledTestItemProps } from './ScheduledTestItem.model';

export const ScheduledTestItem: React.FC<ScheduledTestItemProps> = ({ scheduledTest, onItemSelected }) => {
  useReactTooltip();
  const { accessCodes, duration, extraTime, startDate: startDateString, testLevel, title, productId } = scheduledTest;
  const { t } = useTranslation('scheduled-tests');

  const startDate = new Date(startDateString);

  return (
    <StyledScheduledTestItem data-cy="scheduled-test-item">
      <StyledProductFlag productId={productId} />
      <StyledCollapseButton
        data-cy="scheduled-test-collapse-button"
        iconName="rightArrow"
        iconSize={1.5}
        onClick={() => onItemSelected(scheduledTest)}
      />
      <StyledGeneralInformation>
        <StyledInfoRow>
          <StyledTestName data-cy="scheduled-test-name">{title}</StyledTestName>
          <StyledMetadataContainer>
            <StyledTestLevel data-cy="scheduled-test-level">
              <SvgIconReact iconName="graduationCapIcon" size={1.5} />
              {testLevel}
            </StyledTestLevel>
            <StyledDateMetadata data-cy="scheduled-test-date">
              <SvgIconReact iconName="calendarIcon" size={1.5} />
              {format(startDate, 'dd MMM yyyy')}
            </StyledDateMetadata>
            <StyledTimeMetadata data-cy="scheduled-test-time">
              <SvgIconReact iconName="clockIcon" size={1.5} />
              <span data-cy="scheduled-test-time">{format(startDate, 'HH:mm')}</span>
              {' | '}
              <span data-cy="scheduled-test-duration">{`${duration} min`}</span>
            </StyledTimeMetadata>
          </StyledMetadataContainer>
          <StyledTestCode data-cy="scheduled-test-code">
            <SvgIconReact iconName="codeIcon" size={2} />
            {accessCodes.standard}
          </StyledTestCode>
        </StyledInfoRow>
        {extraTime > 0 && (
          <StyledInfoRow>
            <StyledTestName>{t('scheduledTestList.item.extraTimeVersion', 'Additional time version')}</StyledTestName>
            <StyledMetadataContainer>
              <StyledTimeMetadata data-cy="scheduled-test-extra-time">
                <SvgIconReact iconName="clockIcon" size={1.5} />
                {`+${extraTime} min`}
              </StyledTimeMetadata>
            </StyledMetadataContainer>
            <StyledTestCode data-cy="scheduled-test-extra-time-code">
              <SvgIconReact iconName="codeIcon" size={2} />
              {accessCodes.withExtraTime}
            </StyledTestCode>
          </StyledInfoRow>
        )}
      </StyledGeneralInformation>
    </StyledScheduledTestItem>
  );
};
