import { WordAction } from 'models/exam/CorrectTextQuestion';
import { useCallback, useState } from 'react';

type SelectableWordAction = WordAction.NONE | WordAction.EDIT_WORD | WordAction.ADD_WORD_AFTER;

type SelectedWord = {
  selectedWordId?: number;
  selectedWordValue?: string;
  selectedWordAction: SelectableWordAction;
  inEditMode: boolean;
  setSelectedWord: (state: SelectedWordState) => void;
  setSelectedWordAction: (action: SelectableWordAction) => void;
};

type SelectedWordState = null | Pick<SelectedWord, 'selectedWordId' | 'selectedWordValue' | 'selectedWordAction'>;

export const useSelectedWord = (): SelectedWord => {
  const [selectedWord, setSelectedWord] = useState<SelectedWordState>(null);
  const { selectedWordId, selectedWordValue, selectedWordAction = WordAction.NONE } = selectedWord || {};

  const setSelectedWordAction = useCallback(
    (wordAction: SelectableWordAction) => {
      if (selectedWordId && selectedWordValue) {
        setSelectedWord({
          selectedWordId,
          selectedWordValue,
          selectedWordAction: wordAction,
        });
      }
    },
    [selectedWordId, selectedWordValue],
  );

  return {
    selectedWordId,
    selectedWordValue,
    selectedWordAction,
    inEditMode: selectedWordAction !== WordAction.NONE,
    setSelectedWord,
    setSelectedWordAction,
  };
};
