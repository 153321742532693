import styled from 'styled-components/macro';
import { QuestionStatus } from 'constants/exam-constants';
import { bgColorCalc, borderColorCalc } from '_helpers/statusColorCalc';
import { ThemeProps } from 'models/styles/theme';
import { EmbeddedHtml } from 'components/EmbeddedHtml';

interface AnswerFieldProps extends ThemeProps {
  selected?: boolean;
  status: QuestionStatus;
}

export const StyledSelectTextQuestion = styled.div<AnswerFieldProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledSelectTextWord = styled.span<AnswerFieldProps>`
  background-color: ${(props: AnswerFieldProps) => {
    return bgColorCalc(props);
  }};

  ${(props: AnswerFieldProps) => {
    if (props.status === QuestionStatus.INITIAL && props.selected) {
      return `
        background-color: ${props.theme.palette.secondary.brand};
        font-weight: bold;
        padding: 4px 8px 4px 8px;
        border-radius: 4px;
        color: ${props.theme.palette.neutral.white};
      `;
    }
  }};

  border-color: ${(props: AnswerFieldProps) => borderColorCalc(props)};
  cursor: pointer;

  &.submitted {
    background: ${(props: ThemeProps) => props.theme.palette.secondary._200};
    border-color: ${(props: ThemeProps) => props.theme.palette.secondary.brand};
  }

  ${(props: AnswerFieldProps) => {
    if (props.status === QuestionStatus.MISSED) {
      return `
        font-weight: bold;
        text-decoration: underline;
        color: ${props.theme.palette.feedback.error.brand};
      `;
    }
  }};
`;

export const StyledQuestionText = styled(EmbeddedHtml)`
  margin-bottom: 2rem;
`;

export const StyledQuestionIntroText = styled(StyledQuestionText)`
  text-align: center;
  font-size: ${(props: ThemeProps) => props.theme.font.size._24};
  font-weight: ${(props: ThemeProps) => props.theme.font.weight.bolder};
`;
