import styled from 'styled-components/macro';

import { ThemeProps as Props } from 'models/styles/theme';

import { Button } from 'components/Button';
import { SelectLanguage } from 'components/SelectLanguage';

export const StyledLanguageSelector = styled(SelectLanguage)`
  margin-bottom: 1rem;
`;

export const StyledMigrationNotice = styled.article`
  color: ${(props: Props) => props.theme.palette.neutral._900};
`;

export const StyledSection = styled.section`
  margin-top: 2.5rem;

  h4 {
    font-size: ${(props: Props) => props.theme.font.size._18};
    line-height: 100%;
  }

  :last-child {
    margin-bottom: 0;
  }
`;

export const StyledButton = styled(Button)`
  font-size: 1rem;
`;

export const StyledSeparator = styled.div<Props>`
  display: flex;
  align-items: center;
  justify-content: center;

  text-transform: uppercase;
  color: ${(props: Props) => props.theme.palette.neutral._600};

  margin: 2rem 0;
  ~ section {
    margin: 0;
  }

  ::before {
    content: '';
    height: 1px;
    width: 15rem;
    background-color: ${(props: Props) => props.theme.palette.neutral._600};
    display: block;
    margin-right: 1rem;
  }

  ::after {
    content: '';
    height: 1px;
    width: 15rem;
    background-color: ${(props: Props) => props.theme.palette.neutral._600};
    display: block;
    margin-left: 1rem;
  }
`;
