import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { modalIds } from 'constants/modals-constants';
import { QuestionStatus } from 'constants/exam-constants';

import { useModal } from 'hooks/useModal';
import { useAnswer } from 'hooks/useAnswer';

import { UserAnswer } from 'models/exam/Exam';
import { EmbeddedHtml } from 'components/EmbeddedHtml';
import { commonSanitizeOptions } from 'layouts/QuestionManager/QuestionManager.constants';
import { ProgressiveTextQuestionAnswer, ProgressiveTextQuestionProps } from './ProgressiveTextQuestion.model';
import { StyledProgressiveTextQuestion, StyledTextAreaInput } from './ProgressiveTextQuestion.styled';
import { ReflectiveQuestionsModal } from './components/ReflectiveQuestionsModal';
import { QuestionsManagerPathParams } from '../../QuestionManager.model';

export const ProgressiveTextQuestion: React.FC<ProgressiveTextQuestionProps> = ({
  answerFeedbackComponent: answerComponent,
  evaluateAnswer,
  question,
  status,
  reflectionQuestions = [],
  prevAnswerValues,
}) => {
  const { t } = useTranslation('skill-determination-test');
  const { toggleModal } = useModal();
  const { productId, exerciseId }: QuestionsManagerPathParams = useParams();
  const setExerciseIdValue = !exerciseId ? undefined : +exerciseId;
  const prevCurrentAnswers =
    prevAnswerValues && prevAnswerValues.length > 0 ? prevAnswerValues?.map(({ value }) => value as string) : '' || [];
  const [currentAnswer, setCurrentAnswer] = useState<ProgressiveTextQuestionAnswer>(prevCurrentAnswers);

  const { isEvaluatingAnswer, setAnswerButtonProps } = useAnswer();

  // Set configuration for answer button
  useEffect(() => {
    setAnswerButtonProps({
      label: t('button.checkAnswer.label', 'Check answer'),
      isDisabled: currentAnswer.length === 0,
      isHidden: status !== QuestionStatus.INITIAL,
      isLoading: isEvaluatingAnswer || false,
      onClick: () => {
        toggleModal(modalIds.progressiveTextQuestionModal);
      },
    });
  }, [isEvaluatingAnswer, currentAnswer, status, toggleModal]);

  const onSubmitAnswers = () => {
    const userAnswer: UserAnswer[] = [{ order: 0, value: currentAnswer.toString() }];
    evaluateAnswer && evaluateAnswer(+productId, question.id, userAnswer, setExerciseIdValue);
  };

  return (
    <StyledProgressiveTextQuestion status={status}>
      <ReflectiveQuestionsModal
        reflectionQuestions={reflectionQuestions}
        onCompleteReflectionQuestions={onSubmitAnswers}
      />
      <EmbeddedHtml rawHtml={question.content} sanitizeOptions={commonSanitizeOptions} />
      <div className="answer-container">
        <StyledTextAreaInput
          dataCy="text-area-progress-question"
          id="text-area-question"
          name="text-area-question"
          readOnly={status !== QuestionStatus.INITIAL}
          value={currentAnswer}
          onChange={e => setCurrentAnswer(e.target.value)}
        />
      </div>
      {answerComponent}
    </StyledProgressiveTextQuestion>
  );
};
