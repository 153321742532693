import { ThemeProps as Props } from 'models/styles/theme';
import styled from 'styled-components/macro';
import { Paragraph } from 'styles/elements/Texts';

export const StyledHeader = styled.header<Props>`
  width: 93%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  * > button {
    margin-left: 1rem;
    padding: 0.5rem 1rem;
  }

  * > span {
    font-weight: 400;
    font-size: 0.8rem;
  }
`;

export const StyledBody = styled.div<Props>`
  background: #e5e5e5;
  height: 91%;
  margin: auto;
`;

export const StyledInnerBody = styled.div<Props>`
  margin: auto;
  padding-top: 3rem;
  width: 70%;

  * > p {
    font-size: 1rem;
  }
`;

export const StyledForm = styled.div<Props>`
  background: white;
  max-width: 100%;
  display: flex;
  justify-content: center;
  padding: 2rem 2rem;
`;

export const StyledInnerForm = styled.div<Props>`
  width: 400px;
  display: flex;
  flex-direction: column;
`;

export const StyledParagraph = styled(Paragraph)<Props>`
  font-size: 1rem;
  color: ${({ theme }: Props) => theme.palette.neutral._900};
`;
