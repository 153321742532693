/* eslint-disable @typescript-eslint/no-use-before-define */
import { action, observable } from 'mobx';
import { resultsRepository, writingRepository } from 'repositories';
import {
  OverviewQuestionResult,
  SubjectResponse,
  TopicExerciseStatus,
  TopicsStatus,
  WritingSubjectStatus,
  WritingSubjectDetails,
  OverviewQuestionAnswer,
} from 'models/writing/WritingDetail';
import { ToastMethods } from 'components/ToastNotification';
import { ExamStatus, QuestionStatus } from 'constants/exam-constants';
import i18n from 'i18n';
import { ResultsAPI } from 'generated/types';
import { OverviewFilters, SubjectsProgressOverviewFilters } from 'models/progress/Progress';
import { useAnswer } from './useAnswer';
//TODO: import { useDomainHandler } from 'hooks/useDomainHandler' and convert this in a hook with shared context among other hooks of this kind;
import { getStudentTermByDomain } from '_helpers/domainHandler';

interface WritingStore {
  exerciseStatus: TopicExerciseStatus[];
  writingSubjectDetails?: WritingSubjectDetails;
  writingSubjectsStatus: WritingSubjectStatus[] | null;
  overviewQuestionResults: OverviewQuestionResult[];
  getWritingDetail: (productId: number, moduleId: number, subjectId: number) => void;
  fetchWritingSubjectsStatus: (productId: number, moduleId: number) => void;
  fetchTopicExerciseStatus: (productId: number, topicId: number) => void;
  fetchOverviewQuestionResults: (productId: number, questionId: number, testSessionToken: string) => void;
  submitOverviewQuestionResults: (
    productId: number,
    exercise: number,
    questionId: number,
    linkedQuestionAnswers: OverviewQuestionAnswer,
    testSessionToken: string,
  ) => void;
  setWritingSubjectDetails: (subjectDetails: WritingSubjectDetails) => void;
  writingSubjectsResultsOverview: ResultsAPI.ProductWritingSubjects | null;
  fetchWritingSubjectsResultsOverview: (productId: number, filters: SubjectsProgressOverviewFilters) => void;
  setWritingSubjectsResultsOverview: (writingResultsDetails: ResultsAPI.ProductWritingSubjects | null) => void;
  writingStudentsResultsOverview: ResultsAPI.WritingStudentsDetails[] | null;
  fetchWritingStudentsResultsOverview: (productId: number, filters: OverviewFilters) => void;
  setWritingStudentsResultsOverview: (students: ResultsAPI.WritingStudentsDetails[] | null) => void;
  writingResultsForAccount: ResultsAPI.WritingResultsForAccount | null;
  fetchWritingResultsForAccount: (productId: number, accountId: number) => void;
  setWritingResultsForAccount: (writingResults: ResultsAPI.WritingResultsForAccount | null) => void;
  writingSubjectStudentsDetails: ResultsAPI.WritingSubjectStudents | null;
  fetchWritingSubjectStudentsDetails: (productId: number, subjectId: number, filters: OverviewFilters) => void;
  setWritingSubjectStudentsDetails: (writingSubjectStudentsDetails: ResultsAPI.WritingSubjectStudents | null) => void;
  lastPracticedWritingTopicStatuses: ResultsAPI.LastPracticedWritingTopicStatus[] | null;
  fetchLastPracticedWritingTopicStatuses: (productId: number) => void;
  setLastPracticedWritingTopicStatuses: (
    lastPracticedTopicsPerModule: ResultsAPI.LastPracticedWritingTopicStatus[] | null,
  ) => void;

  // TODO: this will no longer we needed when we refactor the code so that email compose become exercises, instead of a question component
  questionAnswerTextsForEmailPreview: Record<string, string>;
  setQuestionAnswerTextsForEmailPreview: (questionAnswerTexts: Record<string, string>) => void;
}

// eslint-disable-next-line prefer-const
let store: WritingStore;

const initialState = {
  chapters: [],
  exerciseStatus: [],
  writingSubjectsStatus: null,
  overviewQuestionResults: [],
  writingSubjectStudentsDetails: [] as unknown as ResultsAPI.WritingSubjectStudents,
  writingStudentsResultsOverview: null,
  writingSubjectsResultsOverview: null,
  writingResultsForAccount: null,
  questionAnswerTextsForEmailPreview: {},
  lastPracticedWritingTopicStatuses: null,
};

const stateSetters = {
  setWritingSubjectDetails: action((subjectDetails: WritingSubjectDetails) => {
    store.writingSubjectDetails = subjectDetails;
  }),
  setTopicExerciseStatus: action((exerciseStatus: TopicExerciseStatus[]) => {
    store.exerciseStatus = exerciseStatus;
  }),
  setWritingSubjectsStatus: action((writingSubjectsStatus: WritingSubjectStatus[] | null) => {
    store.writingSubjectsStatus = writingSubjectsStatus;
  }),
  setOverviewQuestionResults: action((overviewQuestionResults: OverviewQuestionResult[]) => {
    store.overviewQuestionResults = overviewQuestionResults;
  }),
  setWritingSubjectsResultsOverview: action(
    (writingSubjectsResultsOverview: ResultsAPI.ProductWritingSubjects | null) => {
      store.writingSubjectsResultsOverview = writingSubjectsResultsOverview;
    },
  ),
  setWritingStudentsResultsOverview: action(
    (writingStudentsResultsOverview: ResultsAPI.WritingStudentsDetails[] | null) => {
      store.writingStudentsResultsOverview = writingStudentsResultsOverview;
    },
  ),
  setWritingResultsForAccount: action((writingResults: ResultsAPI.WritingResultsForAccount | null) => {
    store.writingResultsForAccount = writingResults;
  }),
  setWritingSubjectStudentsDetails: action((writingSubjectStudentsDetails: ResultsAPI.WritingSubjectStudents) => {
    store.writingSubjectStudentsDetails = writingSubjectStudentsDetails;
  }),
  setQuestionAnswerTextsForEmailPreview: action((questionAnswerTexts: Record<string, string>) => {
    store.questionAnswerTextsForEmailPreview = questionAnswerTexts;
  }),
  setLastPracticedWritingTopicStatuses: action(
    (lastPracticedTopicsPerModule: ResultsAPI.LastPracticedWritingTopicStatus[] | null) => {
      store.lastPracticedWritingTopicStatuses = lastPracticedTopicsPerModule;
    },
  ),
};

const apiRequests = {
  getWritingDetail: action((productId: number, moduleId: number, subjectId: number) => {
    let subjectDetails: SubjectResponse;
    writingRepository
      .getWritingDetail(productId, moduleId, subjectId)
      .then((response: SubjectResponse) => {
        subjectDetails = response;
        return writingRepository.getTopicStatus(productId, subjectId);
      })
      .then((response: TopicsStatus[]) => {
        const formatChapters = subjectDetails.chapters.map(chapter => {
          const mappTopics = chapter.topics.map(topic => {
            const hasStatus = response.find(({ topicId }) => topicId === topic.id);
            if (hasStatus) {
              return {
                ...topic,
                status: hasStatus.status,
              };
            }
            return { ...topic, status: ExamStatus.DEFAULT };
          });
          return {
            ...chapter,
            topics: mappTopics,
          };
        });
        stateSetters.setWritingSubjectDetails({
          ...subjectDetails,
          chapters: formatChapters,
        });
      })
      .catch(() => {
        ToastMethods.showToast(i18n.t('toast:writingDetail.error'), 'error');
      });
  }),

  // Fetch the status for the different exercises of a topic (for student environment)
  fetchTopicExerciseStatus: action((productId: number, topicId: number) => {
    writingRepository
      .getTopicExercisesStatus(productId, topicId)
      .then((response: TopicExerciseStatus[]) => {
        stateSetters.setTopicExerciseStatus(Array.isArray(response) ? response : []);
      })
      .catch(() => {
        ToastMethods.showToast(i18n.t('toast:writing.error.fetchTopicExerciseStatus'), 'error');
      });
  }),

  // Fetch the status of the writing subjects inside a module (for student environment)
  fetchWritingSubjectsStatus: action((productId: number, moduleId: number) => {
    stateSetters.setWritingSubjectsStatus(null);
    writingRepository
      .getWritingSubjectsStatus(productId, moduleId)
      .then((response: WritingSubjectStatus[]) => {
        stateSetters.setWritingSubjectsStatus(Array.isArray(response) ? response : []);
      })
      .catch(() => {
        ToastMethods.showToast(i18n.t('toast:writing.error.fetchWritingSubjectsStatus'), 'error');
      });
  }),

  // Fetch answers the user has given to questions in the same collection as the overview question
  fetchOverviewQuestionResults: action((productId: number, questionId: number, testSessionToken: string) => {
    writingRepository
      .getOverviewQuestionResults(productId, questionId, testSessionToken)
      .then((response: OverviewQuestionResult[]) => {
        stateSetters.setOverviewQuestionResults(response);
      })
      .catch(() => {
        ToastMethods.showToast(i18n.t('toast:writing.error.fetchOverviewQuestionResult'), 'error');
      });
  }),

  // Submit answers for questions linked to the specified collection / overview question
  submitOverviewQuestionResults: action(
    (
      productId: number,
      exerciseId: number,
      questionId: number,
      linkedQuestionAnswers: OverviewQuestionAnswer,
      testSessionToken: string,
    ) => {
      const { setQuestionStatus } = useAnswer();

      writingRepository
        .updateOverviewQuestionAnswers(productId, exerciseId, questionId, linkedQuestionAnswers, testSessionToken)
        .then(() => {
          ToastMethods.showToast(i18n.t('toast:writing.success.submitOverviewQuestionResults'), 'success');
          setQuestionStatus(QuestionStatus.SUBMITTED);
        })
        .catch(() => {
          ToastMethods.showToast(i18n.t('toast:writing.error.submitOverviewQuestionResults'), 'error');
        });
    },
  ),
  fetchWritingSubjectsResultsOverview: action((productId: number, filters: SubjectsProgressOverviewFilters) => {
    writingRepository
      .getwritingSubjectsResultsOverview(productId, filters)
      .then((writingSubjectsDetails: ResultsAPI.ProductWritingSubjects) => {
        store.setWritingSubjectsResultsOverview(writingSubjectsDetails);
      })
      .catch(() => {
        store.setWritingSubjectsResultsOverview(null);
        ToastMethods.showToast(i18n.t('toast:progress.getwritingSubjectsResultsOverview.error'), 'error');
      });
  }),
  fetchWritingStudentsResultsOverview: action((productId: number, filters: OverviewFilters) => {
    writingRepository
      .getwritingStudentsResultsOverview(productId, filters)
      .then((writingStudentsProgress: ResultsAPI.WritingStudentsDetails[]) => {
        store.setWritingStudentsResultsOverview(writingStudentsProgress);
      })
      .catch(() => {
        store.setWritingStudentsResultsOverview(null);
        ToastMethods.showToast(
          i18n.t('toast:progress.getwritingStudentsResultsOverview.error', {
            studentTerm: getStudentTermByDomain({ usePlural: true }),
          }),
          'error',
        );
      });
  }),
  fetchWritingResultsForAccount: action((productId: number, accountId: number) => {
    writingRepository
      .getWritingResultsForAccount(productId, accountId)
      .then((writingResults: ResultsAPI.WritingResultsForAccount) => {
        store.setWritingResultsForAccount(writingResults);
      })
      .catch(() => {
        store.setWritingResultsForAccount(null);
        ToastMethods.showToast(i18n.t('toast:progress.getWritingProgressForAccount.error'), 'error');
      });
  }),

  fetchWritingSubjectStudentsDetails: action((productId: number, subjectId: number, filters: OverviewFilters) => {
    writingRepository
      .getWritingSubjectStudentsDetails(productId, subjectId, filters)
      .then((result: ResultsAPI.WritingSubjectStudents) => {
        store.setWritingSubjectStudentsDetails(result);
      })
      .catch(() => {
        store.setWritingSubjectStudentsDetails(null);
        ToastMethods.showToast(
          i18n.t('toast:progress.getWritingSubjectStudentsDetails.error', {
            studentTerm: getStudentTermByDomain({ usePlural: true }),
          }),
          'error',
        );
      });
  }),
  fetchLastPracticedWritingTopicStatuses: action((productId: number) => {
    resultsRepository
      .fetchLastPracticedWritingTopicStatuses(productId)
      .then((result: ResultsAPI.LastPracticedWritingTopicStatus[]) => {
        store.setLastPracticedWritingTopicStatuses(result);
      })
      .catch(() => {
        store.setLastPracticedWritingTopicStatuses(null);
        ToastMethods.showToast(i18n.t('toast:writing.error.fetchLastPracticeWritingTopicStatuses'), 'error');
      });
  }),
};

store = observable({
  ...initialState,
  ...stateSetters,
  ...apiRequests,
} as WritingStore);

export const useWriting = (): WritingStore => store;
