import { SvgIconReact } from 'components/SvgIconReact';
import React from 'react';
import { ToggleButtonProps } from './ToggleButton.model';
import { StyledToggleButton, ToggleButtonIcon } from './ToggleButton.styled';

export const ToggleButton: React.FC<ToggleButtonProps> = ({
  children,
  disabled = false,
  iconName,
  isActive,
  dataCy,
  onClick,
}) => (
  <StyledToggleButton
    $hasIcon={!!iconName}
    $isActive={isActive}
    className={isActive ? 'active' : ''}
    data-cy={dataCy}
    disabled={disabled}
    onClick={onClick}
  >
    {iconName && (
      <ToggleButtonIcon>
        <SvgIconReact iconName={iconName} size={1.5} />
      </ToggleButtonIcon>
    )}
    <span>{children}</span>
  </StyledToggleButton>
);
