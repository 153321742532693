import { StatusTag } from 'components/StatusTag/StatusTag';
import styled from 'styled-components/macro';
import { getExerciseToastBgColor, getExerciseToastTextColor } from './CommonExerciseUtils';
import { ExerciseStatus } from 'models/writing/WritingDetail';

interface StatusProps {
  statusType: ExerciseStatus;
}

export const StyledStatusTag = styled(StatusTag)<StatusProps>`
  background: ${({ statusType, theme }) => getExerciseToastBgColor(statusType, theme)};

  > span {
    color: ${({ statusType, theme }) => getExerciseToastTextColor(statusType, theme)};
  }
`;
