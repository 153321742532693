import styled from 'styled-components/macro';

import { ThemeProps as Props } from 'models/styles/theme';
import { calculateRem } from 'styles/mixins/mixins';
import { CssFlex, CssGrid } from 'styles/helpers/layout';

export const StyledEmailPreview = styled(CssGrid)`
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.218231);
`;

export const StyledEmailTitle = styled(CssFlex)`
  background: ${(props: Props) => props.theme.palette.neutral._200};
`;

export const StyledEmailMetadata = styled(CssGrid)`
  border-bottom: 1px solid ${(props: Props) => props.theme.palette.neutral._200};

  > :first-child {
    color: ${(props: Props) => props.theme.palette.neutral._900};
  }
`;

export const StyledEmailButtonBar = styled(CssFlex)`
  button {
    background-color: #b20b87 !important;

    .content {
      display: flex;
      column-gap: 0.5rem;
      align-items: center;
      justify-content: space-between;
      font-size: ${calculateRem(12)};
      font-weight: normal;
      line-height: 1em;
    }
  }

  i {
    cursor: not-allowed;
  }
`;
