import { Card } from 'components/Card/Card';
import { UserProgressPerSubject } from 'models/progress/Progress';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PROGRESS_TYPE } from 'pages/TeacherEnvironment/components/TeacherSection/components/SubjectDoughnutChart/SubjectDoughnutChart.model';
import { mapProgressToColor } from 'color-utils';
import { useTheme } from 'styled-components/macro';
import { SubjectDoughnutChart } from 'pages/TeacherEnvironment/components/TeacherSection/components/SubjectDoughnutChart/SubjectDoughnutChart';
import { ElearningThemeInterface } from 'themes/elearningInterface.theme';
import { Heading3, Heading4 } from 'styles/elements/Headings';
import { Divider } from 'components/Divider/Divider';
import { useDomainHandler } from 'hooks/useDomainHandler';
import { useModal } from 'hooks/useModal';
import { modalIds } from 'constants/modals-constants';
import { Button } from 'components/Button';
import { CssFlex } from 'styles/helpers/layout';
import { useFeatureFlags } from 'hooks/useFeatureFlags';

export interface AccountSubjectProgressProps {
  subjects: UserProgressPerSubject[];
  goalLevel: string;
}

export const AccountSubjectProgress: React.FC<AccountSubjectProgressProps> = ({ subjects, goalLevel }) => {
  const theme = useTheme() as ElearningThemeInterface;
  const { t } = useTranslation('teacher-dashboard');
  const { toggleModal } = useModal();
  const { getStudentTermByDomain } = useDomainHandler();
  const { showGoalLevelSelectorOnTeacherDashboard } = useFeatureFlags();

  const subjectDataForChart = subjects.map(subject => [
    {
      subjectTitle: subject.title,
      type: PROGRESS_TYPE.COMPLETED,
      value: subject.progress,
      color: mapProgressToColor(subject.progress, theme),
    },
    {
      subjectTitle: subject.title,
      type: PROGRESS_TYPE.UNCOMPLETED,
      value: 100 - subject.progress,
      color: theme.palette.neutral.light_02,
    },
  ]);

  return (
    <Card layout={{ p: 2 }}>
      <CssFlex alignItems="flex-start" columnGap={1} justifyContent="space-between">
        <section>
          <Heading3 fontWeight="normal">{t('basicProgress.forAccount.knowledgePerSubject.title')}</Heading3>
          <Heading4 fontWeight="normal" mb="2rem">
            {t('basicProgress.forAccount.knowledgePerSubject.subTitle', {
              goalLevel,
              studentTerm: getStudentTermByDomain(),
            })}
          </Heading4>
        </section>
        {/* If target selection is enabled, show button to allow users to see detailed insights */}
        {showGoalLevelSelectorOnTeacherDashboard && (
          <Button
            dataCy="show-detailed-student-progress-modal-button"
            fontWeight="normal"
            variant="primary-outline"
            onClick={() => {
              toggleModal(modalIds.detailedStudentProgressModal);
            }}
          >
            {t('basicProgress.forAccount.knowledgePerSubject.showModalButtonLabel', 'Show detailed progress')}
          </Button>
        )}
      </CssFlex>
      <Divider />
      <SubjectDoughnutChart subjectDataForChart={subjectDataForChart} />
    </Card>
  );
};
