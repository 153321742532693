import axios, { AxiosError } from 'axios';
import { ERROR_TYPES } from 'constants/error-constants';
import { handlingResponse, logError } from 'repositories/utils';
import { RequestError, ApiRequestError as Error } from 'models/error/Error';

import cookieStorage from 'persistence';
import { GenericTestDetails, DeterminationTestStatus } from 'models/adaptive-practice/AdaptivePractice';
import { SkillDeterminationTestSessionProgress } from 'models/results/Results';

export const fetchSkillDeterminationTest = async (
  productId: number,
  subjectId: number,
): Promise<GenericTestDetails> => {
  try {
    const result = await axios.get(`/api/products/${productId}/subjects/${subjectId}/skill-determination-test`, {
      headers: { Authorization: cookieStorage.getToken() },
    });
    return handlingResponse<GenericTestDetails>([200], 'Error retrieving test', 'determinationTestError')(result);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<Error>);
    throw new RequestError({ message, status, type: ERROR_TYPES.GET_DETERMINATION_QUESTIONS_FAILED });
  }
};

export const fetchSkillDeterminationStatus = async (
  productId: number,
  moduleId: number,
): Promise<DeterminationTestStatus[]> => {
  try {
    const result = await axios.get(`/api/products/${productId}/modules/${moduleId}/skill-determination-test/status`, {
      headers: { Authorization: cookieStorage.getToken() },
    });
    return handlingResponse<DeterminationTestStatus[]>(
      [200],
      'Error retrieving test',
      'determinationTestError',
    )(result);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<Error>);
    throw new RequestError({ message, status, type: ERROR_TYPES.GET_DETERMINATION_QUESTIONS_FAILED });
  }
};

export const fetchSkillDeterminationTestSessionProgress = async (
  skillDeterminationTestSessionToken: string,
  productId: number,
  subjectId: number,
): Promise<SkillDeterminationTestSessionProgress> => {
  try {
    const result = await axios.get(`/api/products/${productId}/subjects/${subjectId}/skill-determination-test/status`, {
      headers: { test_token: skillDeterminationTestSessionToken, Authorization: cookieStorage.getToken() },
    });
    return handlingResponse<SkillDeterminationTestSessionProgress>(
      [200],
      'Error retrieving test session',
      'determinationTestSessionError',
    )(result);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<Error>);
    throw new RequestError({ message, status, type: ERROR_TYPES.GET_DETERMINATION_QUESTIONS_SESSION_FAILED });
  }
};
