import React from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { HE_PLATFORMS } from '@he-learning/service-he-common';

import { useAdmin } from 'hooks/useAdmin';
import { useModal } from 'hooks/useModal';
import { useDomainHandler } from 'hooks/useDomainHandler';
import { useUserForm } from 'hooks/useUserForm';
import { modalIds } from 'constants/modals-constants';

import { InputWithErrorMessage } from 'components/InputWithErrorMessage';
import { Heading3 } from 'styles/elements/Headings';
import { AdminProductItem } from 'models/product/ProductItem';
import { ROLE } from 'persistence';

import { buildNewUserFormSchema } from './NewUserForm.schema';
import { EducationItem } from '../../../../components/EducationItem/EducationItem';
import { AddProductModal } from '../../../../components/AddProductModal/AddProductModal';
import { ProductItem } from '../../../../components/ProductItem/ProductItem';
import { UpdateProductModal } from '../../../../components/UpdateProductModal/UpdateProductModal';
import {
  StyledBasicInfo,
  StyledButton,
  StyledField,
  StyledInput,
  StyledSelect,
} from '../../../UserDetailsPage/components/UserUpdateForm/UserUpdateForm.styled';

export const NewUserForm: React.FC = observer(() => {
  const { t } = useTranslation('admin-environment');
  const { createNewAccount, loading } = useAdmin();
  const { toggleModal } = useModal();
  const { getEducations, getProductTitle, schools } = useUserForm();
  const { isBusinessDomain } = useDomainHandler();

  const roles = [
    { value: ROLE.STUDENT, label: t('accountDetails.role.student', 'Student') },
    { value: ROLE.TEACHER, label: t('accountDetails.role.teacher', 'Teacher') },
  ];

  return (
    <Formik
      enableReinitialize
      initialValues={{
        firstName: '',
        middleName: '',
        lastName: '',
        email: '',
        metadata: {
          products: [] as AdminProductItem[],
          studentNumber: '',
          educations: [''],
        },
        role: ROLE.STUDENT,
        school: '',
        password: '',
        platform: HE_PLATFORMS.HOGES,
      }}
      validationSchema={buildNewUserFormSchema()}
      onSubmit={values => {
        const createUserPayload = {
          firstName: values.firstName,
          middleName: values.middleName,
          lastName: values.lastName,
          email: values.email,
          role: values.role,
          metadata: {
            ...values.metadata,
            studentNumber: values.role === ROLE.STUDENT ? values.metadata.studentNumber : undefined,
          },
          password: values.password || undefined,
          platform: values.platform,
        };
        createNewAccount(createUserPayload);
      }}
    >
      {formik => (
        <form noValidate onSubmit={formik.handleSubmit}>
          <StyledField>
            <label htmlFor="role">{t('accountDetails.role.label', 'Role')}</label>
            <StyledSelect
              ariaLabel="account-role"
              dataCy="account-role"
              name="role"
              options={roles}
              value={formik.values.role}
              onChange={event => {
                formik.resetForm();
                formik.setFieldValue('role', event.target.value);
              }}
            />
          </StyledField>
          <StyledBasicInfo>
            <StyledField>
              <label htmlFor="firstName">{t('accountDetails.firstName.label', 'First Name')}</label>
              <InputWithErrorMessage
                dataCy="account-first-name"
                errorMessage={formik.errors.firstName}
                hasError={!!(formik.errors.firstName && formik.touched.firstName)}
                name="firstName"
                value={formik.values.firstName}
                onChange={formik.handleChange}
              />
            </StyledField>
            <StyledField>
              <label htmlFor="middleName">{t('accountDetails.middleName.label', 'Middle Name')}</label>
              <InputWithErrorMessage
                dataCy="account-middle-name"
                errorMessage={formik.errors.middleName}
                hasError={!!(formik.errors.middleName && formik.touched.middleName)}
                name="middleName"
                value={formik.values.middleName}
                onChange={formik.handleChange}
              />
            </StyledField>
            <StyledField>
              <label htmlFor="lastName">{t('accountDetails.lastName.label', 'Last Name')}</label>
              <InputWithErrorMessage
                dataCy="account-last-name"
                errorMessage={formik.errors.lastName}
                hasError={!!(formik.errors.lastName && formik.touched.lastName)}
                name="lastName"
                value={formik.values.lastName}
                onChange={formik.handleChange}
              />
            </StyledField>
          </StyledBasicInfo>
          {formik.values.role === ROLE.STUDENT && !isBusinessDomain() && (
            <StyledField>
              <label htmlFor="metadata.studentNumber">
                {t('accountDetails.studentNumber.label', 'Student Number')}
              </label>
              <StyledInput
                dataCy="account-studentNumber"
                hasError={!!formik.errors.metadata?.studentNumber}
                name="metadata.studentNumber"
                value={formik.values.metadata.studentNumber}
                onChange={formik.handleChange}
              />
            </StyledField>
          )}

          <StyledField>
            <label htmlFor="school">{t('accountDetails.school.label', 'School')}</label>
            <StyledSelect
              ariaLabel="account-school"
              dataCy="account-school"
              name="school"
              options={schools}
              value={formik.values.school}
              onChange={event => {
                formik.setFieldValue('school', event.target.value);
                formik.setFieldValue('metadata.educations', ['']);
              }}
            />
          </StyledField>
          <StyledField>
            <label htmlFor="education">{t('accountDetails.education.label', 'Education')}</label>
            {formik.values.metadata.educations.map((education, index) => (
              <EducationItem
                key={education}
                disabled={!formik.values.school}
                education={education}
                educationOptions={getEducations(formik.values.school, formik.values.metadata.educations, education)}
                errorMessage={t('admin-environment:accountDetails.educations.errors.required')}
                hasError={!!formik.errors.metadata?.educations}
                onDeleteEducation={() => {
                  const { values, setFieldValue } = formik;
                  if (values.metadata.educations.length > 1) {
                    setFieldValue(
                      'metadata.educations',
                      values.metadata.educations.filter((value, educationIndex) => educationIndex !== index),
                    );
                  } else {
                    setFieldValue('metadata.educations', ['']);
                  }
                }}
                onUpdateEducation={updatedEducation => {
                  const { values, setFieldValue } = formik;
                  const { metadata } = values;
                  metadata.educations[index] = updatedEducation;
                  setFieldValue('metadata.educations', metadata.educations);
                }}
              />
            ))}
            {formik.values.role === ROLE.TEACHER && (
              <StyledButton
                dataCy="add-education"
                disabled={
                  formik.values.metadata.educations.length + 1 === getEducations(formik.values.school).length ||
                  !formik.values.school ||
                  formik.values.metadata.educations.some(education => !education)
                }
                variant="default"
                onClick={event => {
                  const { setFieldValue, values } = formik;
                  event.preventDefault();
                  setFieldValue('metadata.educations', [...values.metadata.educations, '']);
                }}
              >
                {t('accountDetails.button.addEducation', '+ Add education')}
              </StyledButton>
            )}
          </StyledField>
          <StyledField>
            <label htmlFor="email">{t('accountDetails.email.label', 'Email Address')}</label>
            <StyledInput
              dataCy="account-email"
              hasError={!!(formik.errors.email && formik.touched.email)}
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
            />
          </StyledField>
          <StyledField>
            <label htmlFor="password">{t('accountDetails.password.label', 'Password')}</label>
            <StyledInput
              dataCy="account-password"
              hasError={!!(formik.errors.password && formik.touched.password)}
              name="password"
              type="password"
              value={formik.values.password}
              onChange={formik.handleChange}
            />
          </StyledField>
          <Heading3>{t('accountDetails.products.header', 'Products')}</Heading3>
          {formik.values.metadata.products?.map(product => (
            <ProductItem
              key={product.id}
              product={{
                id: product.id,
                title: getProductTitle(product.id) || '',
                type: product.type,
                validSinceDate: product.validSinceDate ? product.validSinceDate : new Date(1),
                validUntilDate: product.validUntilDate ? product.validUntilDate : new Date(1),
              }}
              onDeleteProduct={productId => {
                const { values, setFieldValue } = formik;
                setFieldValue(
                  'metadata.products',
                  values.metadata.products?.filter(selectedProduct => selectedProduct.id !== productId),
                );
              }}
            />
          ))}
          <StyledField>
            <StyledButton
              dataCy="add-account-product-button"
              variant="default"
              onClick={event => {
                event.preventDefault();
                toggleModal(modalIds.addAccountProductModal);
              }}
            >
              {t('accountDetails.button.addProduct', '+ Add product')}
            </StyledButton>
          </StyledField>
          <StyledButton
            dataCy="save-account-button"
            disabled={!!Object.keys(formik.errors).length || loading}
            type="submit"
            variant="primary"
          >
            {t('accountDetails.button.save', 'Save account')}
          </StyledButton>
          <StyledButton
            dataCy="clear-account-button"
            variant="secondary"
            onClick={event => {
              event.preventDefault();
              formik.resetForm();
            }}
          >
            {t('accountDetails.button.reset', 'Reset form')}
          </StyledButton>
          <UpdateProductModal
            onChangeValidUntilDate={updatedProduct => {
              const { values, setFieldValue } = formik;
              const selectedProductIndex = values.metadata.products?.findIndex(
                product => product.id === updatedProduct.id,
              );
              values.metadata.products[selectedProductIndex] = {
                ...values.metadata.products[selectedProductIndex],
                ...updatedProduct,
              };
              setFieldValue('metadata.products', values.metadata.products);
            }}
          />
          <AddProductModal
            addedProducts={formik.values.metadata.products}
            onAddNewProduct={newProduct => {
              const { values, setFieldValue } = formik;
              setFieldValue('metadata.products', [...values.metadata.products, newProduct]);
            }}
          />
        </form>
      )}
    </Formik>
  );
});
