import styled from 'styled-components/macro';

import { calculateRem } from 'styles/mixins/mixins';
import { ElearningThemeInterface } from '../../themes/elearningInterface.theme';

export interface Props {
  theme: ElearningThemeInterface;
}

export const StyledErrorMessage = styled.span`
  display: flex;
  flex-direction: column;
  text-align: left;
  font-size: ${calculateRem(14)};
  color: ${(props: Props) => props.theme.palette.feedback.error.brand};
`;

export const StyledSelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${calculateRem(10)};
`;
