import { QuestionStatus } from 'constants/exam-constants';
import { ElearningThemeInterface } from 'themes/elearningInterface.theme';

interface AnswerFieldProps {
  theme: ElearningThemeInterface;
  status: QuestionStatus;
}

export const borderColorCalc = (props: AnswerFieldProps): string => {
  switch (props.status) {
    case QuestionStatus.CORRECT:
      return props.theme.palette.feedback.success.brand;
    case QuestionStatus.INCORRECT:
      return props.theme.palette.feedback.error.brand;
    default:
      return props.theme.palette.neutral._600;
  }
};

export const bgColorCalc = (props: AnswerFieldProps): string => {
  switch (props.status) {
    case QuestionStatus.CORRECT:
      return props.theme.palette.feedback.success._200;
    case QuestionStatus.INCORRECT:
      return props.theme.palette.feedback.error._200;
    default:
      return props.theme.palette.neutral.white;
  }
};
