import { Button } from 'components/Button';
import { SvgIconReact } from 'components/SvgIconReact';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EditWordInputProps } from './EditWordInput.model';
import { EditWordInputContent, EditWordInputPlaceholder, StyledEditWordInput } from './EditWordInput.styled';

export const EditWordInput: React.FC<EditWordInputProps> = ({
  defaultValue = '',
  submittableWhenEmpty = false,
  width = 300,
  disableSpaces = true,
  onOK,
  onCancel,
}) => {
  const { t } = useTranslation('common');

  const [inputValue, setInputValue] = useState<string>(defaultValue);
  const inputRef = useRef<HTMLInputElement>(null);

  const okButtonDisabled = useMemo<boolean>(
    () => !submittableWhenEmpty && inputValue.trim() === '',
    [inputValue, submittableWhenEmpty],
  );

  useEffect(() => {
    const currentInputRef = inputRef.current;
    currentInputRef?.focus();
    const onKeyPress = (event: KeyboardEvent) => {
      if (disableSpaces && event.key === ' ') {
        event.preventDefault();
      }
    };
    const onKeyUp = (event: KeyboardEvent) => {
      if (event.key === 'Enter' || event.key === 'Escape') {
        // actually submit on Enter press, cancel on Escape
        const isCancelAction = event.key === 'Escape';
        if (isCancelAction) {
          onCancel();
        } else {
          onOK(inputValue.trim());
        }
      }
    };
    document.addEventListener('keypress', onKeyPress);
    document.addEventListener('keyup', onKeyUp);
    return () => {
      document.removeEventListener('keypress', onKeyPress);
      document.removeEventListener('keyup', onKeyUp);
    };
  }, [inputValue, disableSpaces, onCancel, onOK]);

  return (
    <StyledEditWordInput>
      <EditWordInputPlaceholder style={{ width: `${width}px` }} />
      <EditWordInputContent style={{ width: `${width}px` }}>
        <input
          ref={inputRef}
          data-cy="edit-word-input"
          spellCheck={false}
          type="text"
          value={inputValue}
          onChange={e => setInputValue(e.target.value)}
        />
        <div className="buttons">
          <Button
            isNarrow
            dataCy="edit-word-input-cancel"
            title={t('dialog.cancel')}
            variant="border"
            onClick={() => onCancel()}
          >
            <SvgIconReact iconName="closeX" />
          </Button>
          <Button
            isNarrow
            className="is-submit"
            dataCy="edit-word-input-ok"
            disabled={okButtonDisabled}
            title={t('dialog.ok')}
            variant="primary"
            onClick={() => onOK(inputValue.trim())}
          >
            <SvgIconReact iconName="checkIcon" />
          </Button>
        </div>
      </EditWordInputContent>
    </StyledEditWordInput>
  );
};
