// TODO: https://github.com/infinitaslearning/app-he-hoges-web/issues/1159
export type TopicProgressType = 'not-started' | 'in-progress' | 'completed';

export enum NormalTopicProgressionStatus {
  REQUIRES_HIGHER_GOAL = 'requires_higher_goal',
  COMPLETED = 'completed',
  DISABLED = 'disabled',
  READY = 'ready',
}
export type NormalTopicProgressionStatusType = `${NormalTopicProgressionStatus}`;

export enum AssignmentTopicProgressionStatus {
  LOCKED = 'locked',
  COMPLETED = 'completed',
  READY = 'ready',
  FAILED = 'failed',
}
export type AssignmentTopicProgressionStatusType = `${AssignmentTopicProgressionStatus}`;

export interface TopicProgress {
  id: number;
  progress: number;
  skillLevel: number;
  initialSkillLevel: number;
}

export interface ProductProgressSubjects {
  id: number;
  currentSkillLevel: number;
  initialSkillLevel: number;
}

export interface SubjectProgress {
  id: number;
  progress: number;
  skillLevel: number;
  initialSkillLevel: number;
  topics: TopicProgress[];
}

export interface ModuleProgress {
  productId: number;
  moduleId: number;
  subjects: SubjectProgress[];
}

export interface ExercisePerSubjectResults {
  subjectId: number;
  answeredQuestions: number;
}

export interface GoalLevel {
  goalLevel: number;
  productId: number;
}

export interface ExercisesOverTimeResults {
  weekStartDate: string;
  questionCount: number;
}

export interface OverviewFilters {
  educationId?: string;
  schoolYearStart?: string;
  schoolYearEnd?: string;
  searchTerm?: string;
  page?: number;
  resultsPerPage?: number;
  groupId?: number;
  targetLevel?: number;
}

export type SubjectsProgressOverviewFilters = Omit<OverviewFilters, 'page' | 'resultsPerPage'>;

export interface SubjectProgressSummary {
  id: number;
  title: string;
  progress: number;
}

export interface AccountProgressSummary {
  id: number;
  firstName: string;
  middleName?: string | null;
  lastName: string;
  avgProgress: number;
  avgSkillLevel: number;
  goalSkillLevel?: number | null;
  topicCompletionRate: number;
}

interface PaginatedAccountProgressSummary {
  total: number;
  data: AccountProgressSummary[];
}

export interface BasicProgressOverview {
  subjects: SubjectProgressSummary[];
  accounts: PaginatedAccountProgressSummary;
}

export interface UserInfo {
  id: string;
  email: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  studentNumber?: string;
  goalLevel?: string;
}

export interface UserProgressPerSubject {
  id: number;
  title: string;
  progress: number;
}

export interface BasicProgressForAccount {
  account: UserInfo;
  avgProgress: number;
  subjects: UserProgressPerSubject[];
}

export interface WritingSubjectForAccount {
  id: number;
  title: string;
  exercisesFinished: number;
  casesFinished: number;
  totalExercises: number;
  totalCases: number;
}

export interface WritingProgressForAccount {
  id: number;
  firstName: string;
  lastName: string;
  middleName?: string;
  email: string;
  studentNumber: string;
  subjects: WritingSubjectForAccount[];
}

export interface WritingSubject {
  id: number;
  title: string;
  totalNotStarted: number;
  totalInProgress: number;
  totalReady: number;
}

export interface AverageKnowledgeLevel {
  progress: number;
  avgSkillLevel: number;
  title: string;
}

export interface AverageKnowledgeDetails {
  totalStudents: number;
  avgKnowledgeLevels: AverageKnowledgeLevel[];
}

export interface Subject {
  id: number;
  title: string;
  avgProgress: number;
}

export interface SubjectsProgress {
  studentIds: number[];
  subjects: Subject[];
}

export interface StudentProgressOverTimeFilters {
  startDate?: string;
  endDate?: string;
  subjectId?: string;
}
