import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import { useScheduledTests } from 'hooks/useScheduledTests';
import { LoadingSpinner } from 'components/LoadingSpinner';
import { TEACHER_MONITOR_PAGE_ROUTES } from 'constants/routes';
import { ScheduledTestStatus, ScheduledTestSummary } from 'models/exam/ScheduledTest';
import { buildUrlWithPathParams } from '_helpers/utils/urlBuilder';
import {
  StyledScheduledTestsPage,
  StyledScheduledTestsHeader,
  StyledScheduledTestsLoader,
} from './LiveMonitorOverview.styled';
import { ScheduledTestItem } from './components/ScheduledTestItem';

export const LiveMonitorOverviewPage: React.FC = observer(() => {
  const history = useHistory();
  const { t } = useTranslation('scheduled-tests');
  const { fetchScheduledTests, scheduledTests } = useScheduledTests();

  useEffect(() => {
    fetchScheduledTests(ScheduledTestStatus.ONGOING);
  }, []);

  const goToScheduledTestLiveMonitor = (scheduledTest: ScheduledTestSummary) => {
    history.push(
      buildUrlWithPathParams(TEACHER_MONITOR_PAGE_ROUTES.SCHEDULED_TEST_LIVE_MONITOR, {
        scheduledTestId: scheduledTest.id,
      }),
    );
  };

  return (
    <StyledScheduledTestsPage>
      <StyledScheduledTestsHeader>{t('liveMonitor.overview.header', 'Monitor live tests')}</StyledScheduledTestsHeader>
      {scheduledTests &&
        scheduledTests?.map(test => (
          <ScheduledTestItem
            key={test.id}
            data-cy={`scheduled-test-${test.id}`}
            scheduledTest={test}
            onItemSelected={goToScheduledTestLiveMonitor}
          />
        ))}
      {!scheduledTests && (
        <StyledScheduledTestsLoader data-cy="scheduled-tests-overview-loader">
          <LoadingSpinner />
        </StyledScheduledTestsLoader>
      )}
      {scheduledTests && scheduledTests.length === 0 && (
        <p data-cy="no-ongoing-scheduled-tests-message">
          {t('liveMonitor.overview.noResults', 'There are no ongoing tests at the moment')}
        </p>
      )}
    </StyledScheduledTestsPage>
  );
});
