import React from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useRedirect } from 'hooks/useRedirect';

import { serviceENV as getCurrentEnvironment, EnvironmentType } from 'constants/env-constants';
import { HogesWebshopURL } from 'constants/registration-links';
import { useDomainHandler } from 'hooks/useDomainHandler';

import { Paragraph } from 'styles/elements/Texts';
import { StyledButton } from './NoOwnedTextContent.styled';

export const NoOwnedTextContent: React.FC = observer(() => {
  const { t } = useTranslation('homepage');
  const { getCustomNameByDomain } = useDomainHandler();
  const { redirectToUrl } = useRedirect();
  const currentEnv = getCurrentEnvironment() as EnvironmentType;

  return (
    <>
      <Paragraph>{t('noOwned.description', { appName: getCustomNameByDomain() })}</Paragraph>
      <Paragraph>{t('noOwned.button.title', 'Visit our website.')}</Paragraph>
      <StyledButton
        dataCy="go-to-webshop-button"
        variant="primary"
        onClick={() => redirectToUrl(HogesWebshopURL[currentEnv])}
      >
        {t('noOwned.button.text', 'Go to the website')}
      </StyledButton>
    </>
  );
});
