import styled from 'styled-components/macro';

import { Select } from 'components/Select';
import { SvgIconReact } from 'components/SvgIconReact';
import { SortableTable } from 'components/SortableTable/SortableTable';
import { StyledHeaderCell, StyledCell } from 'components/SortableTable/SortableTable.styled';

import { ThemeProps } from 'models/styles/theme';
import { Heading3 } from 'styles/elements/Headings';
import { calculateRem } from 'styles/mixins/calculateRem';

export const StyledHeader = styled(Heading3)<ThemeProps>`
  font-weight: ${({ theme }: ThemeProps) => theme.font.weight.normal};
`;

export const StyledTableContainer = styled.div<ThemeProps>`
  border: 1px solid ${({ theme }: ThemeProps) => theme.palette.neutral._400};
  margin-top: ${calculateRem(44)};
  padding: ${calculateRem(16)};
`;

export const StyledTableHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: ${calculateRem(32)};
`;

export const StyledSortableTable = styled(SortableTable)<ThemeProps>`
  ${StyledHeaderCell} {
    align-items: flex-start;
  }

  .header-cell-email,
  .header-cell-actions {
    align-items: center;
    justify-content: center;
  }

  ${StyledCell} {
    background: ${({ theme }: ThemeProps) => theme.palette.neutral.white};
  }

  ${StyledCell}:nth-child(5n+1) {
    width: ${calculateRem(250)};
  }

  ${StyledCell}:nth-child(5n+2) {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: ${calculateRem(300)};

    &:hover {
      overflow: visible;
      white-space: normal;
    }
  }

  ${StyledCell}:nth-child(5n+3) {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: ${calculateRem(300)};

    &:hover {
      overflow: visible;
      white-space: normal;
    }
  }

  ${StyledCell}:nth-child(5n+4) {
    align-items: center;
    justify-content: center;
  }

  ${StyledCell}:nth-child(5n+5) {
    align-items: center;
    justify-content: center;
  }
`;

export const StyledIcon = styled(SvgIconReact)`
  cursor: pointer;
`;

export const StyledSelectContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
`;

export const StyledSelect = styled(Select)`
  height: ${calculateRem(48)};
  margin-left: ${calculateRem(32)};
`;

export const StyledSpinnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
