import styled from 'styled-components/macro';

import { ThemeProps as Props } from 'models/styles/theme';
import { errorInputCss } from 'styles/helpers/errors';
import { CssFlex, CssGrid, CssIcon } from 'styles/helpers/layout';

export const StyledSelectContainer = styled.div`
  position: relative;

  select {
    display: none;
  }
`;

interface SelectProps extends Props {
  $hasError?: boolean;
  $isDisabled?: boolean;
}

export const StyledSelect = styled(CssGrid)<SelectProps>`
  cursor: pointer;
  border-radius: 2px;
  border: 1px solid ${(props: Props) => props.theme.palette.neutral._600};
  background-color: ${(props: Props) => props.theme.palette.neutral.white};

  width: 100%;

  padding: 0.75rem 1rem;
  font-size: 1rem;
  line-height: 1.5rem;

  label {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    min-height: 1.5rem;
  }

  ${(props: SelectProps) =>
    props.$isDisabled &&
    `
    color: ${props.theme.palette.neutral._600};
    cursor: not-allowed;
  `}

  ${({ $hasError }) => $hasError && errorInputCss}
`;

interface OptionListProps extends Props {
  $isOpen?: boolean;
}

export const StyledOptionList = styled.ul<OptionListProps>`
  position: absolute;
  top: 3.2rem;
  width: 100%;
  padding: 0;
  margin: 0;
  min-width: fit-content;

  z-index: 999;
  list-style: none;
  background: ${(props: Props) => props.theme.palette.neutral.white};
  border: solid 1px ${(props: Props) => props.theme.palette.neutral._400};
  border-radius: 2px;

  ${(props: OptionListProps) =>
    !props.$isOpen &&
    `
    display: none;
  `}
`;

interface OptionListItemProps extends Props {
  $isPlaceholder?: boolean;
  $isSelected?: boolean;
}

export const StyledOptionListItem = styled.li<OptionListItemProps>`
  padding: 0.5rem 1rem;
  font-size: ${(props: Props) => props.theme.font.size._14};

  ${(props: OptionListItemProps) =>
    props.$isPlaceholder &&
    `
    padding: 1rem;
    color: ${props.theme.palette.neutral._600};
  `}

  ${(props: OptionListItemProps) =>
    props.$isSelected &&
    `
    font-weight: bold;
    color: ${props.theme.palette.secondary.brand};
    background: ${props.theme.palette.secondary._200};
  `}

  ${(props: OptionListItemProps) =>
    !props.$isPlaceholder &&
    `
    &:hover {
      background: ${props.theme.palette.neutral._200};
    }
  `}
`;

export const StyledErrorMessage = styled.span`
  display: block;
  text-align: left;
  margin-top: 0.5rem;
  font-size: ${(props: Props) => props.theme.font.size._14};
  color: ${(props: Props) => props.theme.palette.feedback.error.brand};
`;

export const StyledSearchBox = styled(CssFlex)<Props>`
  margin: 0.5rem;
  padding: 0.75rem 1rem;
  background: ${(props: Props) => props.theme.palette.neutral._200};
  border-radius: 0.25rem;

  input {
    border: 0;
    outline: none;
    background: none;
    width: 100%;
    font-size: ${(props: Props) => props.theme.font.size._14};
    color: ${(props: Props) => props.theme.palette.neutral._900};

    ::placeholder {
      color: ${(props: Props) => props.theme.palette.neutral._600};
    }
  }

  ${CssIcon} {
    color: ${(props: Props) => props.theme.palette.neutral._900};
  }
`;
