export enum HstProducts {
  DUTCH = 68,
  ENGLISH = 69,
  BETER_SCHRIJVEN = 86,
}

export enum ProductType {
  REGULAR = 'regular',
  BUSINESS = 'business',
}

export const CombiLicenseProducts = [HstProducts.DUTCH, HstProducts.ENGLISH];

// License duration (in years) per product type
export const LicenseDurationPerType = {
  [ProductType.BUSINESS]: 1,
  [ProductType.REGULAR]: 5,
};

export enum CustomDomains {
  UNIVERSITEITSTAAL = 'universiteitstaal.htaal.nl',
  BETERSCHRIJVEN = 'beterschrijven.htaal.nl',
  BETERSCHRIJVEN_STG = 'beterschrijven.stg.noordhoff.nl',
}

export const BusinessDomainList: string[] = [CustomDomains.BETERSCHRIJVEN, CustomDomains.BETERSCHRIJVEN_STG];

export enum UsetifulTour {
  REGULAR_STUDENT_ONBOARDING = 49796,
  BUSINESS_STUDENT_ONBOARDING = 50280,
}
