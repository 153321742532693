import axios, { AxiosError } from 'axios';
import { ERROR_TYPES } from 'constants/error-constants';
import {
  SubjectsProgress,
  SubjectsProgressOverviewFilters,
  GoalLevel,
  ProductProgressSubjects,
  OverviewFilters,
  BasicProgressForAccount,
  AverageKnowledgeDetails,
  StudentProgressOverTimeFilters,
} from 'models/progress/Progress';
import cookieStorage from 'persistence';
import { handlingResponse, logError } from 'repositories/utils';
import { RequestError, ApiRequestError as Error } from 'models/error/Error';
import { ProgressAPI, ResultsAPI } from 'generated/types';

// Retrieve current progress of the user in the specified module (for student view)
const getModuleProgress = async <T extends ProgressAPI.StudentSubjectProgress>(
  productId: number,
  moduleId: number,
): Promise<T[]> => {
  try {
    const result = await axios.get(`/api/progress/products/${productId}/modules/${moduleId}`, {
      headers: { Authorization: cookieStorage.getToken() },
    });
    return handlingResponse<T[]>([200], 'Error getting module progress')(result);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<Error>);
    throw new RequestError({ message, status, type: ERROR_TYPES.GET_MODULE_PROGRESS_FAILED });
  }
};

const fetchProductProgressSubjects = async (productId: number): Promise<ProductProgressSubjects[]> => {
  try {
    const result = await axios.get(`/api/progress/products/${productId}/subjects`, {
      headers: { Authorization: cookieStorage.getToken() },
    });
    return handlingResponse<ProductProgressSubjects[]>([200], 'Error getting product subjects progress')(result);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<Error>);
    throw new RequestError({ message, status, type: ERROR_TYPES.GET_PRODUCT_SUBJECTS_PROGRESS_FAILED });
  }
};

const getGoalLevel = async (productId: number): Promise<GoalLevel> => {
  try {
    const goalLevel = await axios.get(`/api/progress/products/${productId}/goalLevel`, {
      headers: { Authorization: cookieStorage.getToken() },
    });
    return handlingResponse<GoalLevel>([200], 'Error getting goal level')(goalLevel);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<Error>);
    throw new RequestError({ message, status, type: ERROR_TYPES.GET_GOAL_LEVEL_FAILED });
  }
};

const setGoalLevel = async (productId: number, goalLevel: number): Promise<void> => {
  try {
    await axios.post(
      '/api/progress/goalLevel',
      { productId, goalLevel },
      { headers: { Authorization: cookieStorage.getToken() } },
    );
  } catch (error) {
    const { message, status } = logError(error as AxiosError<Error>);
    throw new RequestError({ message, status, type: ERROR_TYPES.UPDATE_GOAL_LEVEL_FAILED });
  }
};

const setStudentGoalLevel = async (studentId: number, productId: number, goalLevel: number): Promise<void> => {
  try {
    await axios.post(
      `/api/v1/account/${studentId}/goal-level`,
      { productId, goalLevel },
      { headers: { Authorization: cookieStorage.getToken() } },
    );
  } catch (error) {
    const { message, status } = logError(error as AxiosError<Error>);
    throw new RequestError({ message, status, type: ERROR_TYPES.UPDATE_GOAL_LEVEL_FAILED });
  }
};

const resetSubjectProgress = async (productId: number, subjectId: number): Promise<void> => {
  try {
    await axios.put(
      `/api/progress/product/${productId}/subject/${subjectId}/reset`,
      {},
      { headers: { Authorization: cookieStorage.getToken() } },
    );
  } catch (error) {
    const { message, status } = logError(error as AxiosError<Error>);
    throw new RequestError({ message, status, type: ERROR_TYPES.RESET_SUBJECT_PROGRESS_FAILED });
  }
};

// Retrieve an overview of the progress made on a product (for teacher dashboard)
const getStudentsProgressOverview = async (
  productId: number,
  {
    educationId,
    schoolYearStart,
    schoolYearEnd,
    page,
    resultsPerPage,
    searchTerm,
    groupId,
    targetLevel,
  }: OverviewFilters,
): Promise<ProgressAPI.StudentProgressOverviewV2[]> => {
  try {
    const response = await axios.get(`/api/progress/products/${productId}/students-overview`, {
      headers: { Authorization: cookieStorage.getToken() },
      params: {
        educationId,
        schoolYearStart,
        schoolYearEnd,
        page,
        resultsPerPage,
        searchTerm,
        groupId,
        targetLevel,
      },
    });
    return handlingResponse<ProgressAPI.StudentProgressOverviewV2[]>(
      [200],
      'Error getting students progress overview',
    )(response);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<Error>);
    throw new RequestError({ message, status, type: ERROR_TYPES.GET_STUDENTS_PROGRESS_OVERVIEW_FAILED });
  }
};

const getSubjectsProgressOverview = async (
  productId: number,
  { educationId, schoolYearStart, schoolYearEnd, searchTerm, groupId, targetLevel }: SubjectsProgressOverviewFilters,
): Promise<SubjectsProgress> => {
  try {
    const response = await axios.get(`/api/progress/products/${productId}/subjects-overview`, {
      headers: { Authorization: cookieStorage.getToken() },
      params: {
        educationId,
        schoolYearStart,
        schoolYearEnd,
        searchTerm,
        groupId,
        targetLevel,
      },
    });
    return handlingResponse<SubjectsProgress>([200], 'Error getting subjects progress overview')(response);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<Error>);
    throw new RequestError({ message, status, type: ERROR_TYPES.GET_SUBJECTS_PROGRESS_OVERVIEW_FAILED });
  }
};

// Retrieve an overview of the progress made by a specific student (for teacher dashboard)
const getProductProgressForAccount = async (
  productId: number,
  accountId: number,
  targetLevel?: number,
): Promise<BasicProgressForAccount> => {
  try {
    const response = await axios.get(
      `/api/progress/products/${productId}/accounts/${accountId}${targetLevel ? `?targetLevel=${targetLevel}` : ''}`,
      {
        headers: { Authorization: cookieStorage.getToken() },
      },
    );
    return handlingResponse<BasicProgressForAccount>(
      [200],
      'Error getting adaptive progress overview for the selected account',
    )(response);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<Error>);
    throw new RequestError({ message, status, type: ERROR_TYPES.GET_BASIC_PROGRESS_FOR_ACCOUNT_FAILED });
  }
};

// Fetch average knowledge levels
const getAverageKnowledgeDetails = async (productId: number): Promise<AverageKnowledgeDetails> => {
  try {
    const response = await axios.get(`/api/progress/products/${productId}/average-knowledge-level`, {
      headers: { Authorization: cookieStorage.getToken() },
    });
    return handlingResponse<AverageKnowledgeDetails>([200], 'Error getting average knowledge levels')(response);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<Error>);
    throw new RequestError({ message, status, type: ERROR_TYPES.GET_AVERAGE_KNOWLEDGE_LEVELS_FAILED });
  }
};

const fetchAnsweredQuestionsForAccount = async (
  productId: number,
  studentId: number,
): Promise<ResultsAPI.AnsweredQuestionsForAccount> => {
  try {
    const response = await axios.get(
      `/api/account/products/${productId}/students/${studentId}/question-results/for-account`,
      {
        headers: { Authorization: cookieStorage.getToken() },
      },
    );
    return handlingResponse<ResultsAPI.AnsweredQuestionsForAccount>(
      [200],
      'Error getting the answered questions for account',
    )(response);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<Error>);
    throw new RequestError({ message, status, type: ERROR_TYPES.GET_ANSWERED_QUESTIONS_FOR_ACCOUNT_FAILED });
  }
};

const generateBasicProgressReport = async (
  productId: number,
  { educationId, schoolYearStart, schoolYearEnd, searchTerm, groupId, targetLevel }: SubjectsProgressOverviewFilters,
): Promise<string> => {
  try {
    const result = await axios.get(`/api/progress/products/${productId}/generate-basic-report`, {
      headers: { Authorization: cookieStorage.getToken() },
      params: {
        educationId,
        schoolYearStart,
        schoolYearEnd,
        searchTerm,
        groupId,
        targetLevel,
      },
    });
    return handlingResponse<string>([200], 'Failed generating basic progress report')(result);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<Error>);
    throw new RequestError({ message, status, type: ERROR_TYPES.GENERATE_BASIC_PROGRESS_REPORT_FAILED });
  }
};

const fetchLastPracticedAdaptiveTopicStatuses = async (
  productId: number,
): Promise<ProgressAPI.LastPracticedAdaptiveTopicStatus[]> => {
  try {
    const result = await axios.get(`/api/progress/products/${productId}/adaptive-modules/last-practiced-topic`, {
      headers: { Authorization: cookieStorage.getToken() },
    });
    return handlingResponse<ProgressAPI.LastPracticedAdaptiveTopicStatus[]>(
      [200],
      'Failed fetching last practiced adaptive topic status',
    )(result);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<Error>);
    throw new RequestError({ message, status, type: ERROR_TYPES.FETCH_LAST_PRACTICED_ADAPTIVE_TOPIC_STATUS_FAILED });
  }
};

// Fetch information on subject progress over time for a given student relative to a specific goal
const getStudentSubjectProgressOverTime = async (
  productId: number,
  accountId: number,
  targetLevel: number,
  { startDate, endDate, subjectId }: StudentProgressOverTimeFilters = {},
): Promise<ProgressAPI.SubjectProgressOverTime[]> => {
  try {
    const response = await axios.get(
      `/api/progress/products/${productId}/accounts/${accountId}/monthly-subject-progress`,
      {
        headers: { Authorization: cookieStorage.getToken() },
        params: {
          startDate,
          endDate,
          subjectId,
          targetLevel,
        },
      },
    );

    return handlingResponse<ProgressAPI.SubjectProgressOverTime[]>(
      [200],
      'Error getting student progress over time',
    )(response);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<Error>);
    throw new RequestError({ message, status, type: ERROR_TYPES.GET_STUDENT_SUBJECT_PROGRESS_OVER_TIME_FAILED });
  }
};

const fetchStudentDetailedProgress = async (
  productId: number,
  accountId: number,
  targetLevel: number,
): Promise<ProgressAPI.StudentSubjectInsights[]> => {
  try {
    const response = await axios.get(`/api/progress/products/${productId}/accounts/${accountId}/detailed-insights`, {
      headers: { Authorization: cookieStorage.getToken() },
      params: {
        targetLevel,
      },
    });

    return handlingResponse<ProgressAPI.StudentSubjectInsights[]>(
      [200],
      'Error getting detailed student progress',
    )(response);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<Error>);
    throw new RequestError({ message, status, type: ERROR_TYPES.GET_STUDENT_SUBJECT_INSIGHTS_FAILED });
  }
};

export {
  getGoalLevel,
  getModuleProgress,
  setGoalLevel,
  setStudentGoalLevel,
  fetchProductProgressSubjects,
  resetSubjectProgress,
  getStudentsProgressOverview,
  getSubjectsProgressOverview,
  getProductProgressForAccount,
  getAverageKnowledgeDetails,
  generateBasicProgressReport,
  fetchAnsweredQuestionsForAccount,
  fetchLastPracticedAdaptiveTopicStatuses,
  getStudentSubjectProgressOverTime,
  fetchStudentDetailedProgress,
};
