import { TargetAndTransition } from 'framer-motion';
import { ModalAnimationType } from './Modal.model';

type AnimationVariants = {
  from: TargetAndTransition;
  to: TargetAndTransition;
};

export const backgroundAnimationVariants = {
  from: {
    backgroundColor: 'rgba(0,0,0,0.0)',
  },
  to: {
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
};

export const modalAnimationVariants: Record<ModalAnimationType, AnimationVariants> = {
  fade: {
    from: { opacity: 0 },
    to: { opacity: 1 },
  },
  slideTop: {
    from: { top: '-100vh' },
    to: { top: '0vh' },
  },
  slideBottom: {
    from: { top: '100vh' },
    to: { top: '0vh' },
  },
  slideLeft: {
    from: { left: '-100vw' },
    to: { left: '0vh' },
  },
  slideRight: {
    from: { left: '100vh' },
    to: { left: '0vh' },
  },
};
