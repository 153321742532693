import React from 'react';
import { SvgIconReact } from 'components/SvgIconReact';
import { EmbeddedHtml } from 'components/EmbeddedHtml';
import { ListNavigationProps } from './ListNavigationItem.model';
import { StyledTitle, StyledItemContainer } from './ListNavigationItem.styled';

export const ListNavigationItem: React.FC<ListNavigationProps> = ({
  className,
  title,
  dataCy,
  disabled = false,
  href,
  extraData,
}) => (
  <StyledItemContainer $disabled={disabled} className={className} data-cy={dataCy} to={href}>
    <StyledTitle>
      <EmbeddedHtml rawHtml={title} />
    </StyledTitle>
    {extraData}
    <SvgIconReact iconName="rightArrow" />
  </StyledItemContainer>
);
