import styled, { css } from 'styled-components/macro';

import { ThemeProps } from 'models/styles/theme';

interface TableProps extends ThemeProps {
  $gridSizes: string[];
}

interface HeaderCellProps extends ThemeProps {
  $flexStartCellHeaders: boolean;
}

interface CellProps extends ThemeProps {
  $rowIndex: number;
  $centerContent: boolean;
}

interface TableRowProps extends ThemeProps {
  $link?: string;
  $highlightColor?: string;
}

interface NoResultsMessageProps extends ThemeProps {
  $cellCount: number;
}

const getGridTemplateColumnsCss = (gridSizes: string[]) => css`
  grid-template-columns: ${gridSizes.join(' ')};
`;

export const StyledHeaderCell = styled.div<HeaderCellProps>`
  display: flex;
  align-items: ${({ $flexStartCellHeaders }: HeaderCellProps) => ($flexStartCellHeaders ? 'flex-start' : 'center')};
  padding: 0.5rem;
  flex-direction: column;
  background-color: ${({ theme }: ThemeProps) => theme.palette.neutral._200};
`;

export const StyledCell = styled.div<CellProps>`
  display: flex;
  padding: 0.5rem;
  align-items: center;
  justify-content: ${({ $centerContent }: CellProps) => ($centerContent ? 'center' : 'flex-start')};
`;

export const StyledNoResultsMessage = styled.div<NoResultsMessageProps>`
  padding: 0.5rem;
  text-align: center;
  grid-column: 1 / ${({ $cellCount }: NoResultsMessageProps) => $cellCount};
`;

export const StyledTable = styled.div<TableProps>`
  display: grid;
  ${({ $gridSizes }: TableProps) => getGridTemplateColumnsCss($gridSizes)};
`;

export const StyledTableRow = styled.div<TableRowProps>`
  display: contents;

  ${(props: TableRowProps) =>
    props.$highlightColor &&
    css`
      ${StyledCell} {
        background: ${props.$highlightColor};
      }
    `};

  &:hover {
    ${StyledCell} {
      background-color: ${({ theme }: TableRowProps) => theme.palette.neutral._100};
      cursor: ${({ $link }: TableRowProps) => ($link ? 'pointer' : 'default')};
    }
  }
`;
