// student env specific navigation menu
import React, { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import { useProduct } from 'hooks/useProduct';
import { useProfile } from 'hooks/useProfile';

import { PRODUCT_PAGES_ROUTES, RESULT_PAGE_ROUTES } from 'constants/routes';
import { buildUrlWithPathParams } from '_helpers/utils/urlBuilder';
import { TestType } from 'constants/exam-constants';

import { NavigationMenu } from 'components/NavigationMenu/NavigationMenu';
import { NavigationMenuItem } from 'components/NavigationMenu/NavigationMenu.model';

import { TestEnvMenuSection } from 'layouts/TestEnvMenuSection';
import { HogesLegacyURL } from 'constants/registration-links';
import { serviceENV as getCurrentEnvironment, EnvironmentType } from 'constants/env-constants';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { ProductType } from 'constants/hst-constants';
import { useModuleAvailability } from 'hooks/useModuleAvailability';

interface ComponentProps {
  className?: string;
  dataCy?: string;
}

export const StudentNavigationMenu: React.FC<ComponentProps> = observer(({ className, dataCy }) => {
  const { t } = useTranslation('homepage');

  const { productDetails: product, productsList: ownedProducts, fetchProducts } = useProduct();
  const currentEnv = getCurrentEnvironment() as EnvironmentType;
  const isStudentABusinessUser = ownedProducts?.every(product => product.type === ProductType.BUSINESS);
  const { userDetails } = useProfile();
  const {
    showLegacyResultsButton,
    studentNavigationPersonalProgress,
    studentNavigationResultsWritingExercises,
    studentNavigationDiagnosticTest,
  } = useFeatureFlags();

  const getUrlWithProductId = (placeholderUrl: string) =>
    buildUrlWithPathParams(placeholderUrl, { productId: product?.id as number });

  useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);

  const { isAdaptiveModuleAvailable, isWritingModuleAvailable } = useModuleAvailability();

  const menuItems = useMemo<NavigationMenuItem[]>(
    () => [
      {
        id: 'student-dashboard',
        dataCy: 'student-dashboard',
        title: t('sideMenu.dashboard', 'Dashboard'),
        redirectPath: getUrlWithProductId(PRODUCT_PAGES_ROUTES.PRODUCT_DETAILS),
        onlyActiveOnExactPath: true,
      },
      /* Show module as standalone item if product has only one; otherwise we wrap them into a category */
      ...(product?.modules && product.modules.length === 1
        ? [
            {
              id: 'product-module',
              title: product.modules[0].title,
              dataCy: 'product-module-item',
              redirectPath: buildUrlWithPathParams(PRODUCT_PAGES_ROUTES.PRODUCT_MODULE_DETAILS, {
                productId: product.id,
                moduleId: product.modules[0].id,
              }),
            },
          ]
        : [
            {
              id: 'product-items',
              expandedByDefault: true,
              dataCy: 'product-module-list',
              title: t('sideMenu.modules.title', 'Modules'),
              subItems: product?.modules.map(module => ({
                id: module.id,
                title: module.title,
                dataCy: 'module-list-item',
                redirectPath: buildUrlWithPathParams(PRODUCT_PAGES_ROUTES.PRODUCT_MODULE_DETAILS, {
                  productId: product.id,
                  moduleId: module.id,
                }),
              })),
            },
          ]),
      {
        id: 'result-list',
        dataCy: 'result-list',
        expandedByDefault: true,
        title: t('sideMenu.results.title', 'Results'),
        subItems: [
          ...(ownedProducts && ownedProducts.length > 0
            ? [
                ...(studentNavigationPersonalProgress && isAdaptiveModuleAvailable
                  ? [
                      {
                        id: 'results-personal-progress',
                        title: t('sideMenu.results.items.personalProgress', 'Basic skills'),
                        dataCy: 'results-personal-progress',
                        redirectPath: getUrlWithProductId(RESULT_PAGE_ROUTES.PERSONAL_PROGRESS),
                      },
                    ]
                  : []),
                ...(studentNavigationResultsWritingExercises && isWritingModuleAvailable
                  ? [
                      {
                        id: 'results-writing-exercises',
                        title: t('sideMenu.results.items.writingExercises', 'Writing skills'),
                        dataCy: 'results-writing-exercises',
                        redirectPath: getUrlWithProductId(RESULT_PAGE_ROUTES.WRITING_EXERCISES),
                      },
                    ]
                  : []),
                ...(studentNavigationDiagnosticTest && !isStudentABusinessUser
                  ? [
                      {
                        id: 'results-diagnostic-test',
                        dataCy: 'results-diagnostic-test',
                        title: t('sideMenu.results.items.diagnosticTest', 'Practice Test'),
                        redirectPath: getUrlWithProductId(RESULT_PAGE_ROUTES.DIAGNOSTIC_TEST),
                      },
                    ]
                  : []),
                ...(!isStudentABusinessUser
                  ? [
                      {
                        id: 'results-summative-test',
                        dataCy: 'results-summative-test',
                        title: t('sideMenu.results.items.summativeTest', 'Summative Test'),
                        redirectPath: buildUrlWithPathParams(RESULT_PAGE_ROUTES.TEST_TYPE_RESULTS_LIST, {
                          testType: TestType.SUMMATIVE_TEST,
                        }),
                      },
                      {
                        id: 'results-formative-test',
                        dataCy: 'results-formative-test',
                        title: t('sideMenu.results.items.formativeTest', 'Formative Test'),
                        redirectPath: buildUrlWithPathParams(RESULT_PAGE_ROUTES.TEST_TYPE_RESULTS_LIST, {
                          testType: TestType.FORMATIVE_TEST,
                        }),
                      },
                      {
                        id: 'results-freebie-test',
                        dataCy: 'results-freebie-test',
                        title: t('sideMenu.results.items.freebieTest', 'Freebie Test'),
                        redirectPath: buildUrlWithPathParams(RESULT_PAGE_ROUTES.TEST_TYPE_RESULTS_LIST, {
                          testType: TestType.FREEBIE_TEST,
                        }),
                      },
                      {
                        id: 'certificate-results',
                        dataCy: 'certificate-results',
                        title: t('sideMenu.results.items.certificate', 'Certificates'),
                        redirectPath: RESULT_PAGE_ROUTES.OBTAINED_CERTIFICATES,
                      },
                    ]
                  : []),
              ]
            : []),
          ...(showLegacyResultsButton && userDetails?.isMigratedUser
            ? [
                {
                  id: 'old-results',
                  dataCy: 'legacy-hoges-link',
                  title: t('sideMenu.results.items.legacyResults', 'Old Results'),
                  redirectPath: HogesLegacyURL[currentEnv],
                },
              ]
            : []),
        ],
      },
    ],
    [t, product],
  );

  return (
    <aside>
      <NavigationMenu className={className} dataCy={dataCy} menuItems={menuItems} />
      {!isStudentABusinessUser && <TestEnvMenuSection />}
    </aside>
  );
});
