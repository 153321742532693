import styled from 'styled-components/macro';

import { ThemeProps } from 'models/styles/theme';

export const StyledProgressDotsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    margin: 0 0.5rem;
    circle {
      transition: all 0.5s ease-out;
      fill: ${(props: ThemeProps) => props.theme.palette.neutral._400};
    }

    &.active {
      circle {
        fill: ${(props: ThemeProps) => props.theme.palette.primary.brand};
      }
    }
  }
`;
