import styled from 'styled-components/macro';
import { ElearningThemeInterface } from 'themes/elearningInterface.theme';
import { calculateRem } from 'styles/mixins/mixins';
import { ToggleSwitchType } from './ToggleSwitch.model';

interface Props {
  theme: ElearningThemeInterface;
}

interface ToggleSwitchWrapperProps extends Props {
  variant?: ToggleSwitchType;
}

export const StyledToggleWrapper = styled.div<ToggleSwitchWrapperProps>`
  display: ${({ variant }: ToggleSwitchWrapperProps) => (variant === 'primary' ? 'block' : 'flex')};
  flex-direction: row;
  align-items: flex-start;
  margin-top: ${({ variant }: ToggleSwitchWrapperProps) => (variant ? calculateRem(5) : calculateRem(22))};

  & > div {
    width: auto;
    margin-right: ${calculateRem(50)};
  }
`;

export const StyledSlider = styled.span<Props>`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ theme }: Props) => theme.palette.neutral.black};
  border-radius: 34px;
  transition: 0.4s;

  &::before {
    position: absolute;
    content: '';
    height: ${calculateRem(9)};
    width: ${calculateRem(9)};
    left: ${calculateRem(4)};
    bottom: ${calculateRem(4)};
    background-color: ${({ theme }: Props) => theme.palette.neutral.white};
    transition: 0.4s;
    border-radius: ${calculateRem(34)};
  }
`;

export const StyledToggleSwitch = styled.label<ToggleSwitchWrapperProps>`
  position: relative;
  display: inline-block;
  height: ${calculateRem(17)};
  width: ${calculateRem(35)};

  input {
    display: none;

    &[type='checkbox']:checked + ${StyledSlider}::before {
      transform: translateX(${calculateRem(18)});
      background-color: ${({ theme }: ToggleSwitchWrapperProps) => theme.palette.neutral.white};
    }

    &[type='checkbox']:checked + ${StyledSlider} {
      background-color: ${({ theme, variant }: ToggleSwitchWrapperProps) =>
        variant === 'default' ? theme.palette.neutral.black : theme.palette.primary.brand};
    }
  }

  p {
    position: relative;
    top: ${calculateRem(20)};
    font-size: 0.75rem;
    text-transform: uppercase;
    color: ${({ theme }: Props) => theme.palette.neutral._900};
  }
`;
