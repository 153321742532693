import styled from 'styled-components/macro';
import { ThemeProps as Props } from 'models/styles/theme';

export interface SkeletonWrapperProps extends Props {
  $marginTop: number;
}

export const StyledSkeletonWrapper = styled.div<SkeletonWrapperProps>`
  margin-top: ${({ theme, $marginTop }: SkeletonWrapperProps) => theme.margin($marginTop)};
`;
