import styled from 'styled-components/macro';

import { ThemeProps as Props } from 'models/styles/theme';
import { Button } from 'components/Button';
import { Heading3 } from 'styles/elements/Headings';
import { Paragraph } from 'styles/elements/Texts';
import { calculateRem } from 'styles/mixins/calculateRem';

type ParagraphProps = Props & {
  marginTop?: number;
};

export const StyledHeading = styled(Heading3)<Props>`
  line-height: ${calculateRem(36)};
`;

export const StyledParagraph = styled(Paragraph)<ParagraphProps>`
  color: ${(props: Props) => props.theme.palette.neutral._900};
  line-height: ${calculateRem(24)};
  margin-bottom: 0;
  margin-top: ${(props: ParagraphProps) => calculateRem(props.marginTop || 0)};
`;

export const StyledButton = styled(Button)<Props>`
  font-size: ${calculateRem(16)};
  font-weight: bold;
  margin: ${calculateRem(32)} ${calculateRem(27)} 0 ${calculateRem(27)};
`;
