import React from 'react';
import { observer } from 'mobx-react';

import { SvgIconReact } from 'components/SvgIconReact';
import { buildUrlWithPathParams } from '_helpers/utils/urlBuilder';
import { useParams } from 'react-router-dom';
import { TEST_PAGE_ROUTES } from 'constants/routes';
import { DiagnosticTestPagePathParams } from 'models/path/path-params';
import { DiagnosticTestGroupItemProps } from './DiagnosticTestGroupItem.model';
import {
  StyledDiagnosticTestGroupItem,
  StyledAccordionDiagnosticListItem,
  StyledPanelRightHeader,
} from './DiagnosticTestGroupItem.styled';

export const DiagnosticTestGroupItem: React.FC<DiagnosticTestGroupItemProps> = observer(
  ({ item, title = '', groupId }) => {
    const { productId, moduleId }: DiagnosticTestPagePathParams = useParams();
    return (
      <StyledDiagnosticTestGroupItem
        to={buildUrlWithPathParams(TEST_PAGE_ROUTES.DIAGNOSTIC_TEST_LIST, {
          productId,
          moduleId,
          groupId,
        })}
      >
        <StyledAccordionDiagnosticListItem
          key={item.id}
          dataCy={`diagnostic-test-list-item-${item.id}`}
          headerPanelRight={
            <StyledPanelRightHeader>
              <SvgIconReact iconName="rightArrow" />
            </StyledPanelRightHeader>
          }
          title={title}
        />
      </StyledDiagnosticTestGroupItem>
    );
  },
);
