import React from 'react';
import { Switch } from 'react-router-dom';

import { ADMIN_ENVIRONMENT_ROUTES, AUTH_PAGES_ROUTES } from 'constants/routes';
import { AuthRoute } from 'layouts/AuthRoute';

import { AdminHeader } from './components/AdminHeader/AdminHeader';
import { AdminNavigationMenu } from './components/AdminNavigationMenu/AdminNavigationMenu';

import { UsersPage } from './subpages/UsersPage/UsersPage';
import { UserDetailsPage } from './subpages/UserDetailsPage/UserDetailsPage';
import { NewUserPage } from './subpages/NewUserPage/NewUserPage';

import { StyledAdminPage } from './AdminPage.styled';
import { UserScheduledTests } from './subpages/UserScheduledTests/UserScheduledTests';
import { UserScheduledTestsGroup } from './subpages/UserScheduledTestsGroup/UserScheduledTestsGroup';
import { UserToolsPage } from './subpages/UserToolsPage/UserToolsPage';

export const AdminPage: React.FC = () => (
  <>
    <AdminHeader />
    <StyledAdminPage>
      <AdminNavigationMenu className="aside-menu" />
      <Switch>
        <AuthRoute
          exact
          component={UsersPage}
          path={ADMIN_ENVIRONMENT_ROUTES.USERS_PAGE}
          redirectPath={AUTH_PAGES_ROUTES.LOG_IN}
        />
        <AuthRoute
          exact
          component={NewUserPage}
          path={ADMIN_ENVIRONMENT_ROUTES.USER_CREATE_PAGE}
          redirectPath={AUTH_PAGES_ROUTES.LOG_IN}
        />
        <AuthRoute
          exact
          component={UserDetailsPage}
          path={ADMIN_ENVIRONMENT_ROUTES.USER_DETAILS_PAGE}
          redirectPath={AUTH_PAGES_ROUTES.LOG_IN}
        />
        <AuthRoute
          exact
          component={UserScheduledTests}
          path={ADMIN_ENVIRONMENT_ROUTES.USER_SCHEDULED_TESTS}
          redirectPath={AUTH_PAGES_ROUTES.LOG_IN}
        />
        <AuthRoute
          exact
          component={UserScheduledTestsGroup}
          path={ADMIN_ENVIRONMENT_ROUTES.USER_SCHEDULED_TESTS_GROUP}
          redirectPath={AUTH_PAGES_ROUTES.LOG_IN}
        />
        <AuthRoute
          exact
          component={UserToolsPage}
          path={ADMIN_ENVIRONMENT_ROUTES.USER_TOOLS}
          redirectPath={AUTH_PAGES_ROUTES.LOG_IN}
        />
      </Switch>
    </StyledAdminPage>
  </>
);
