import styled from 'styled-components/macro';
import { ElearningThemeInterface } from 'themes/elearningInterface.theme';

interface Props {
  theme: ElearningThemeInterface;
  selected?: boolean;
}

export const StyledNavigationMenuItem = styled.nav<Props>`
  height: 3rem;
  padding-left: 3rem;
  display: flex;
  align-items: center;
  border-radius: 4px;
  background-color: ${(props: Props) => props.selected && props.theme.palette.secondary._200};
  color: ${(props: Props) => props.selected && props.theme.palette.secondary._900};
  font-weight: ${(props: Props) => props.selected && props.theme.font.weight.bolder};
  &:hover {
    background-color: ${(props: Props) => props.theme.palette.secondary._200};
    color: ${(props: Props) => props.theme.palette.secondary._900};
  }
  cursor: pointer;
`;
