export const GENERIC_ROUTES = {
  HOME_PAGE: '/',
  NOT_FOUND: '/not-found',
};

// Routes to which user is redirected after login via legacy related websites
export const LEGACY_ROUTES = {
  TEACHER_DASHBOARD: '/volgsysteem',
  STUDENT_DASHBOARD: '/cursist',
};

// Auth pages
export const AUTH_PAGES_ROUTES = {
  LOG_IN: '/signin',
  VALIDATE: '/validate',
  MIGRATE_ACCESS: '/migrate-access',
  USER_MISSING_INFO: '/userinfo',
  REGISTER_ACCOUNT: '/register',
  REGISTER_ACCOUNT_FINISHED: '/register/finished',
  CREATE_BASIC_ACCOUNT: '/register/form',
  INVITATION: '/invite/:invitationId/accept',
  REGISTERED_INVITATION: '/invite/registered',
  ACCESS_DENIED: '/access-denied',
};

// Student environment pages
export const PRODUCT_PAGES_ROUTES = {
  PRODUCT_LIST: '/products',
  PRODUCT_DETAILS: '/product/:productId',
  PRODUCT_MODULE_DETAILS: '/product/:productId/module/:moduleId',
  PRODUCT_WRITING_MODULE_DETAILS: '/product/:productId/module/:moduleId/subjects/:subjectId',
  PRODUCT_TOPIC_DETAILS: '/product/:productId/module/:moduleId/subjects/:subjectId/topic/:topicId',
};

export const TEST_PAGE_ROUTES = {
  DIAGNOSTIC_TEST_GROUPS: '/product/:productId/module/:moduleId/diagnostic-test/groups',
  DIAGNOSTIC_TEST_LIST: '/product/:productId/module/:moduleId/diagnostic-test/groups/:groupId',
  DIAGNOSTIC_TEST_DETAILS: '/product/:productId/module/:moduleId/diagnostic-test/groups/:groupId/tests/:testId',
  DIAGNOSTIC_TEST_EXAM:
    '/product/:productId/module/:moduleId/diagnostic-test/groups/:groupId/tests/:testId/exercises/:exerciseId',
  WRITING_EXERCISE: '/product/:productId/module/:moduleId/subjects/:subjectId/topic/:topicId/exercises/:exerciseId',
  SKILL_DETERMINATION_TEST: '/product/:productId/module/:moduleId/subject/:subjectId/determination-test',
  ADAPTIVE_PRACTICE_TEST: '/product/:productId/module/:moduleId/subject/:subjectId/adaptive-practice',
  SCHEDULED_TESTS_PAGE: '/product/:productId/scheduled-tests',
  ADAPTIVE_PRACTICE_SESSION_RESULTS: '/product/:productId/module/:moduleId/adaptive-practice/session-results',
};

export const RESULT_PAGE_ROUTES = {
  RESULTS_PATH: '/product/:productId/results',
  PERSONAL_PROGRESS: '/product/:productId/results/progress',
  DIAGNOSTIC_TEST: '/product/:productId/results/diagnostic-test',
  WRITING_EXERCISES: '/product/:productId/results/writing',
  // TODO: None of these routes relate to a specific product, so would make sense to rename then to
  // ':testType/results' and '/certificates'. However, this will require a bigger refactor as they need
  // to be taken out of the `StudentDashboard` component that contains the student environment layout
  TEST_TYPE_RESULTS_LIST: '/product/results/:testType',
  OBTAINED_CERTIFICATES: '/product/certificates',
};

// Teacher environment routes
const teacherBaseRoutePath = '/teacher';
const teacherMonitorBaseRoutePath = '/teacher/monitor';

export const TEACHER_HOME_PAGE_ROUTES = {
  HOME_PAGE: `${teacherBaseRoutePath}`,
  MONITOR_PAGE: `${teacherMonitorBaseRoutePath}`,
  DASHBOARD: `${teacherBaseRoutePath}/dashboard/:productId`,
  STUDENT_LICENSE: `${teacherBaseRoutePath}/invite`,
  CREATE_INVITE: `${teacherBaseRoutePath}/invite/new`,
  SENT_INVITE_LIST: `${teacherBaseRoutePath}/invite/list`,
  GROUPS: `${teacherBaseRoutePath}/groups`,
  NEW_GROUP: `${teacherBaseRoutePath}/groups/new`,
  EDIT_GROUP: `${teacherBaseRoutePath}/groups/edit/:groupId`,
};

export const TEACHER_MONITOR_PAGE_ROUTES = {
  SCHEDULED_TEST_LIVE_MONITOR: `${teacherMonitorBaseRoutePath}/scheduled-tests/live/:scheduledTestId`,
};

const teacherInsightsBaseRoutePath = '/teacher/insights/product/:productId';
export const TEACHER_INSIGHT_PAGE_ROUTES = {
  BASE_PATH: teacherInsightsBaseRoutePath,
  EDIT_ACCOUNT: `${teacherInsightsBaseRoutePath}/account/:accountId/edit`,
  PROGRESS_OVERVIEW: `${teacherInsightsBaseRoutePath}/progress`,
  PROGRESS_FOR_ACCOUNT: `${teacherInsightsBaseRoutePath}/progress/account/:accountId`,
  WRITING_RESULTS_FOR_ACCOUNT: `${teacherInsightsBaseRoutePath}/writing/results/account/:accountId`,
  ACTIVITY: `${teacherInsightsBaseRoutePath}/activity`,
  WRITING_SKILLS: `${teacherInsightsBaseRoutePath}/writing`,
  DIAGNOSTIC_TEST: `${teacherInsightsBaseRoutePath}/diagnostic-test`,
};

export const TEACHER_SCHEDULED_TEST_PAGE_ROUTES = {
  SCHEDULED_TESTS_OVERVIEW: `${teacherBaseRoutePath}/scheduled-tests/created`,
  SCHEDULED_TEST_REQUEST: `${teacherBaseRoutePath}/scheduled-tests/created/new`,
  SCHEDULED_TEST_EDIT_REQUEST: `${teacherBaseRoutePath}/scheduled-tests/created/edit/:scheduledTestId`,
  SCHEDULED_TEST_LIVE_MONITOR_OVERVIEW: `${teacherBaseRoutePath}/scheduled-tests/live`,
  SCHEDULED_TEST_LIVE_MONITOR_LOGS: `${teacherBaseRoutePath}/scheduled-tests/live/:scheduledTestId`,
  SCHEDULED_TEST_RESULTS_OVERVIEW: `${teacherBaseRoutePath}/scheduled-tests/results`,
  SCHEDULED_TEST_RESULTS: `${teacherBaseRoutePath}/scheduled-tests/results/:scheduledTestId`,
  SCHEDULED_TEST_RESULTS_FOR_STUDENT: `${teacherBaseRoutePath}/scheduled-tests/results/:scheduledTestId/student/:studentId`,
  SCHEDULED_TESTS_RESULTS_FOR_STUDENT: `${teacherBaseRoutePath}/scheduled-tests/results/student/:accountId`,
};

export const TEACHER_SUPPORT_PAGE_ROUTES = {
  GUIDES: `${teacherBaseRoutePath}/support/guides`,
  CONTACT: `${teacherBaseRoutePath}/support/contact`,
};

export const ZENDESK_ROUTES = {
  RECOMMENDED_TEST_DURATION:
    'https://hogeschooltaal.zendesk.com/hc/nl/articles/4852944335255-Wat-is-de-aanbevolen-tijdsduur-voor-de-Hogeschooltaaltoetsen-',
};

// Test environment routes
export const TEST_ENVIRONMENT_ROUTES = {
  LANDING_PAGE: '/test',
  TEST_DETAILS_PAGE: '/test/:code',
  TEST_EXERCISE_PAGE: '/test/:code/products/:productId/exercises/:exerciseId',
  TEST_EXERCISE_REVIEW_PAGE: '/test/:code/products/:productId/exercises/:exerciseId/review',
  TEST_COMPLETED_PAGE: '/test/:code/completed',
  TEST_RESULTS_PATH: '/test/results',
  TEST_RESULTS_PAGE: '/test/results/:code',
  TEST_EXERCISE_RESULTS_PAGE: '/test/results/:code/exercises/:exerciseId',
};

export const ADMIN_ENVIRONMENT_ROUTES = {
  USERS_PAGE: '/admin/users',
  USER_DETAILS_PAGE: '/admin/users/:userId',
  USER_CREATE_PAGE: '/admin/users/new',
  USER_SCHEDULED_TESTS: '/admin/scheduled-tests',
  USER_SCHEDULED_TESTS_GROUP: '/admin/scheduled-tests/:date',
  USER_TOOLS: '/admin/tools',
};
