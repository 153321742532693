import styled from 'styled-components/macro';

import { ThemeProps as Props } from 'models/styles/theme';

interface OutlinedProgressBarProps extends Props {
  $width: string;
}

interface PercentageProps extends Props {
  $fontSize: string;
}

export const StyledOutlinedProgressBar = styled.div<OutlinedProgressBarProps>`
  background-color: #d0e2ff;
  width: ${(props: OutlinedProgressBarProps) => props.$width};
  height: 0.5rem;
  border-radius: 1rem;
  margin-right: 1rem;
`;

export const StyledWrapper = styled.div<Props>`
  display: flex;
  align-items: center;
`;

export const StyledPercentage = styled.span<PercentageProps>`
  font-size: ${({ $fontSize }: PercentageProps) => $fontSize};
`;
