import React from 'react';

import { StyledProgressBar } from './LevelProgressBar.styled';

export interface LevelProgressBarProps {
  currentLevel: number;
  maxLevel?: number;
  borderRadius?: string;
  color?: string;
}

export const LevelProgressBar: React.FC<LevelProgressBarProps> = ({
  currentLevel = 0,
  maxLevel = 12,
  borderRadius = '0',
  color,
}) => {
  const progress = (currentLevel / maxLevel) * 100;

  return (
    <StyledProgressBar
      $borderRadius={borderRadius}
      $currentLevel={currentLevel}
      $maxLevel={maxLevel}
      $overrideColor={color}
      $width={progress}
    />
  );
};
