import { OptionProps } from 'components/Select/Select.model';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { filterEducations } from '_helpers/optionsParser';

import { useLogin } from './useLogin';
import { useProduct } from './useProduct';

interface UserFormInterface {
  getEducations: (schoolId: string, educations?: string[], selectedEducation?: string) => OptionProps[];
  getProductTitle: (productId: number) => string;
  schools: OptionProps[];
}

export const useUserForm = (): UserFormInterface => {
  const { t } = useTranslation('admin-environment');
  const { getSchoolList, schoolList = [] } = useLogin();
  const { productsList, fetchProducts } = useProduct();

  useEffect(() => {
    if (!schoolList.length) {
      getSchoolList();
    }
  }, [schoolList, getSchoolList]);

  useEffect(() => {
    if (!productsList) {
      fetchProducts();
    }
  }, [productsList, fetchProducts]);

  const schools = useMemo(
    () => [
      {
        value: '',
        label: t('accountDetails.school.defaultValue.label', 'Select a school'),
      },
      ...schoolList.map(schoolItem => ({
        value: schoolItem.id,
        label: schoolItem.title,
      })),
    ],
    [schoolList],
  );

  const getEducations = (schoolId: string, educations: string[] = [], selectedEducation = '') => {
    const schoolEducations = schoolList.find(school => school.id === schoolId)?.educations || [];
    return [
      {
        value: '',
        label: t('accountDetails.education.defaultValue.label', 'Select an education'),
      },
      ...filterEducations(schoolEducations, educations, selectedEducation),
    ];
  };

  const getProductTitle = (productId: number) => productsList?.find(product => product.id === productId)?.title || '';

  return {
    getEducations,
    getProductTitle,
    schools,
  };
};
