import styled from 'styled-components/macro';

import { ThemeProps as Props } from 'models/styles/theme';
import { calculateRem } from 'styles/mixins/mixins';

import { Heading4 } from 'styles/elements/Headings';

import { Link } from 'components/Link';
import { Button } from 'components/Button';
import { InputWithErrorMessage } from 'components/InputWithErrorMessage';
import { FontAwesomeIcon } from 'components/FontAwesomeIcon';

export const StyledButton = styled(Button)`
  margin-top: 2rem;
`;

export const StyledField = styled.div`
  margin-top: 2rem;

  ${Heading4} {
    margin-bottom: 0.25rem;
  }
`;

export const StyledInputContainer = styled.div`
  position: relative;
`;

export const StyledInput = styled(InputWithErrorMessage)`
  padding: 1rem 1rem 1rem 3rem;
`;

export const StyledIcon = styled(FontAwesomeIcon)<Props>`
  position: absolute;
  top: 1rem;
  left: 1rem;
  margin-top: 1px;
  color: ${(props: Props) => props.theme.palette.neutral._600};
`;

export const StyledLink = styled(Link)<Props>`
  font-size: ${calculateRem(10)};
  color: ${({ theme }: Props) => theme.palette.primary.brand};
  margin-top: ${calculateRem(12)};
  justify-content: flex-end;
  font-weight: ${({ theme }: Props) => theme.font.weight.normal};
`;
