import i18n from 'i18n';
import { AnswerOption, AnswerResult, ExamQuestion, ExamQuestionWithAnswers } from 'models/exam/Exam';
import { QuestionType } from 'constants/exam-constants';

export const questionSolutionBuilder = (results: AnswerResult[], question: ExamQuestion): string => {
  const questionType = question.type;
  // For questions where user must choose among a set of options, return text of the right option
  if (
    questionType === QuestionType.DRAG ||
    questionType === QuestionType.SINGLE_SELECT ||
    questionType === QuestionType.SELECT_TEXT
  ) {
    const answerOptions = (question as ExamQuestionWithAnswers).options;
    return results
      .reduce((solution: string[], result: AnswerResult) => {
        const answerSolution = answerOptions.find(option =>
          result.validAnswers?.find(answer => answer.value.toString() === option.id.toString()),
        )?.content;
        return answerSolution ? solution.concat(answerSolution) : solution;
      }, [])
      .join(', ');
  }

  // For open questions, list all possible answers
  return results
    .reduce((solution: string[], result: AnswerResult) => {
      const answerSolution =
        result.validAnswers &&
        result.validAnswers
          .map(answer => answer.value)
          .join(` ${i18n.t('skill-determination-test:questionEvaluationResults.correctAnswerOr')} `);
      return answerSolution ? solution.concat(answerSolution) : solution;
    }, [])
    .join(', ');
};

// Sort answer options by their "order" field
export const sortAnswerOptions = (answerOptions: readonly AnswerOption[]): AnswerOption[] =>
  [...answerOptions].sort((a, b) => a.order - b.order);

// Receives a set of questions, and rearranges them so that the items in the answered question ids array are put first
export const sortQuestionsWithAnsweredFirst = (
  testQuestions: ExamQuestion[],
  answeredQuestionIds: number[] = [],
): ExamQuestion[] => {
  if (answeredQuestionIds.length === 0) {
    return testQuestions;
  }

  const sortedQuestions: ExamQuestion[] = testQuestions
    .map((question, index) => {
      const hasQuestionBeenAnswered = answeredQuestionIds.find(questionId => question.id === questionId);
      return {
        ...question,
        order: hasQuestionBeenAnswered ? -1 : index,
      };
    })
    .sort((a, b) => a.order - b.order);

  return sortedQuestions;
};
