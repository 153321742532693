import styled from 'styled-components/macro';

import { Paragraph } from 'styles/elements/Texts';
import { Button } from 'components/Button';

import { ThemeProps as Props } from 'models/styles/theme';

export const StyledSkillDeterminationParagraph = styled(Paragraph)`
  color: ${(props: Props) => props.theme.palette.neutral._900};
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  text-align: center;
  padding-top: 1rem;
`;

export const StyledSkillDeterminationButton = styled(Button)`
  padding: 12px 16px;
  border-radius: 4px;
  width: 7.3rem;
  height: 3rem;
  min-width: fit-content;
  max-width: 10rem;
  min-height: fit-content;
  font-size: ${(props: Props) => props.theme.font.size._14};
  margin-top: 1rem;
`;
