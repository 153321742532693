import { FontAwesomeIcon } from 'components/FontAwesomeIcon';
import { Input } from 'components/Input';
import { ThemeProps } from 'models/styles/theme';
import styled, { css } from 'styled-components/macro';
import { Heading2, Heading4Css } from 'styles/elements/Headings';
import { Paragraph } from 'styles/elements/Texts';

export const FormStep = styled.fieldset`
  margin: 0;
  padding: 0;
  border: none;
`;

const labelCss = css`
  ${Heading4Css}
  display: block;
  margin: 0.5rem 0;
`;

export const FormLabel = styled.label`
  ${labelCss}
`;

export const FormH4Label = styled.h4`
  ${labelCss}
`;

export const FormCheckboxLabel = styled.label`
  font-weight: ${({ theme }: ThemeProps) => theme.font.weight.normal};
`;

export const FormStepHeader = styled.div`
  margin-bottom: 2rem;
`;

export const FormStepHeading = styled(Heading2)`
  margin: 1rem 0;
`;

export const FormStepIntro = styled(Paragraph)``;

export const FormInput = styled(Input)`
  line-height: 1.5rem;
`;

export const StyledParagraph = styled(Paragraph)`
  margin: auto;
  width: 90%;
  font-weight: 400;
  color: #0072c3;
`;

export const StyledTeacherWarning = styled.div<ThemeProps>`
  width: 88%;
  background: #e5f6ff;
  padding: 1.5rem;

  > * {
    color: #00539a;
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > div {
      display: flex;
      align-items: center;

      > h4,
      i {
        color: #0072c3;
      }

      > h4 {
        margin-left: 1rem;
        margin-top: 1rem;
        font-weight: 700;
      }
    }
  }

  p:not(:first-child) {
    margin-top: 1rem;
  }
`;

export const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  margin-right: 2rem;
  cursor: pointer;
`;
