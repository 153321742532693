import styled from 'styled-components/macro';

import { Modal } from 'components/Modal';
import { Button } from 'components/Button';

import { ModalProps } from 'components/Modal/Modal.model';
import { ThemeProps as Props } from 'models/styles/theme';

import { Paragraph } from 'styles/elements/Texts';
import { calculateRem } from 'styles/mixins/mixins';

type StyledModalProps = ModalProps & Props;

export const StyledDiagnosticTestModal = styled(Modal)<StyledModalProps>`
  background: linear-gradient(
    0deg,
    #f7fffa 0%,
    ${({ theme }: StyledModalProps) => theme.palette.feedback.success._200} 100%
  );
  width: ${calculateRem(962)};
  padding: ${calculateRem(70)};
  border-radius: 4px;
  display: grid;
  justify-items: center;
`;

export const StyledButton = styled(Button)<Props>`
  padding: 12px 16px;
  border-radius: 4px;
  width: 7.3rem;
  height: 3rem;
  min-width: fit-content;
  max-width: 10rem;
  min-height: fit-content;
`;

export const StyledDiagnosticTestModalHeader = styled.div<Props>`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
`;

export const StyledDiagnosticTestModalParagraph = styled(Paragraph)`
  color: ${(props: Props) => props.theme.palette.neutral._900};
  font-style: normal;
  line-height: 150%;
  text-align: center;
  margin-bottom: ${calculateRem(57)};
`;
