import React from 'react';
import { useTranslation } from 'react-i18next';

import { modalIds } from 'constants/modals-constants';
import { useModal } from 'hooks/useModal';
import { ActionMessageModal } from 'components/ActionMessage/ActionMessageModal';

export const MyCorrectTextQuestionMessageModal: React.FC = () => {
  const { t } = useTranslation('modal');
  const { toggleModal } = useModal();

  const modalId = modalIds.correctTextQuestionModal;

  const onActionButtonClick = () => {
    toggleModal(modalId);
  };

  return (
    <ActionMessageModal
      actionButtonClick={onActionButtonClick}
      actionButtonLabel={t('correctTextQuestionModal.button.label')}
      message={t('correctTextQuestionModal.content')}
      modalId={modalId}
      title={t('correctTextQuestionModal.title')}
    />
  );
};

export const CorrectTextQuestionMessageModal = React.memo(MyCorrectTextQuestionMessageModal);
