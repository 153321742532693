import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyledInfoStatus } from './InfoStatus.styled';
import InfoStatusProps, { StatusObject } from './InfoStatus.model';
import { FontAwesomeIcon } from '../FontAwesomeIcon';
import { ExamStatus } from '../../constants/exam-constants';

export const InfoStatus: React.FC<InfoStatusProps> = ({ dataCy, className, status = 'default' }) => {
  const { t } = useTranslation('diagnostic-test');
  const statusText: StatusObject = {
    completed: t('status.completed', 'Completed'),
    inProgress: t('status.inProgress', 'Work In Progress'),
    notStarted: t('status.notStarted', 'Not Started'),
    default: t('status.unKnown', 'Unknown Status'),
  };

  // Don't show anything if status is unknown
  if (status === ExamStatus.DEFAULT) {
    return null;
  }

  return (
    <StyledInfoStatus className={className} data-cy={dataCy} status={status}>
      {status === ExamStatus.COMPLETED && <FontAwesomeIcon className="info-icon" iconName="fa fa-check" />}{' '}
      {statusText[status]}
    </StyledInfoStatus>
  );
};

export default InfoStatus;
