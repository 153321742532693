import React from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { PRODUCT_PAGES_ROUTES } from 'constants/routes';
import { buildUrlWithPathParams } from '_helpers/utils/urlBuilder';

import { useProduct } from 'hooks/useProduct';

import { SvgIconReact } from 'components/SvgIconReact';

import { StyledButtons } from '../../ProductsOwnedPage.styled';
import { StyledMessage, StyledProductButton, StyledProductName } from './OwnedProductList.styled';

export const OwnedProductList: React.FC = observer(() => {
  const history = useHistory();
  const { t } = useTranslation('owned-products');

  const { productsList: ownedProducts } = useProduct();

  const onClickSelectProduct = (productId: number) => {
    history.push(buildUrlWithPathParams(PRODUCT_PAGES_ROUTES.PRODUCT_DETAILS, { productId }));
    localStorage.setItem('lastSelectedProduct', String(productId));
  };

  return (
    <section data-cy="owned-product-list">
      <StyledMessage>{t('chooseProduct', 'Kies een leermodule')}</StyledMessage>
      <StyledButtons>
        {ownedProducts?.map(product => (
          <StyledProductButton
            key={product.id}
            dataCy="product-button"
            fontColor="black"
            variant="secondary"
            onClick={() => onClickSelectProduct(product.id)}
          >
            <SvgIconReact iconName="rightArrow" />
            <StyledProductName>{product.title}</StyledProductName>
          </StyledProductButton>
        ))}
      </StyledButtons>
    </section>
  );
});
