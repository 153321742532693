import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { useHistory, useLocation } from 'react-router-dom';
import { ROLES } from '@he-learning/service-he-common';
import queryString from 'query-string';

import { buildURLwithQueryParams, URLFields } from '_helpers/utils/urlBuilder';

import { Paginator } from 'components/Paginator/Paginator';

import { DEFAULT_PAGE, DEFAULT_ROWS_PER_PAGE, PAGE_FILTER_KEY } from 'constants/admin-constants';
import { ADMIN_ENVIRONMENT_ROUTES } from 'constants/routes';

import { useAdmin } from 'hooks/useAdmin';
import { useLogin } from 'hooks/useLogin';
import useOnLocationChange from 'hooks/useOnLocationChange';

import { AdminUsersQueryParams } from './UsersPage.model';

import { TabFilter } from '../../components/TabFilter/TabFilter';
import { UsersFilter } from './components/UsersFilter/UsersFilter';
import { StudentsTable } from './components/StudentsTable/StudentsTable';
import { TeachersTable } from './components/TeachersTable/TeachersTable';
import { StyledUsersPage, StyledLink } from './UsersPage.styled';

export const UsersPage: React.FC = observer(() => {
  const { t } = useTranslation('admin-environment');
  const history = useHistory();
  const location = useLocation();
  const { fetchFilteredAccounts, filteredAccounts } = useAdmin();
  const { getSchoolList, schoolList = [] } = useLogin();
  const { queryParams } = useOnLocationChange(history);
  const [storedParams, setStoredParams] = useState<string>('');

  useEffect(() => {
    setStoredParams(location.search);
  }, [queryParams]);

  const {
    page = DEFAULT_PAGE,
    results = DEFAULT_ROWS_PER_PAGE,
    role = ROLES.STUDENT,
    input = '',
    education = 'all',
    school = 'all',
  }: AdminUsersQueryParams = queryString.parse(queryParams);

  useEffect(() => {
    fetchFilteredAccounts({
      page: +page,
      results: +results,
      input,
      education,
      school,
      role: role || ROLES.STUDENT,
    });
  }, [fetchFilteredAccounts, page, results, input, education, school, role]);

  useEffect(() => {
    if (!schoolList.length) {
      getSchoolList();
    }
  }, [getSchoolList, schoolList]);

  const paginationFilters = {
    page,
    results,
    role,
    input,
    education,
    school,
  };

  const filters = Object.keys(paginationFilters).map(key => ({
    key,
    value: paginationFilters[key as keyof typeof paginationFilters],
  })) as URLFields[];

  const onFilterChange = (filterValue: string | number, key: string) => {
    const newFilters = filters
      .map(filter => {
        if (filter.key === key) {
          return { ...filter, value: encodeURIComponent(filterValue) };
        }
        if (filter.key === PAGE_FILTER_KEY && key !== PAGE_FILTER_KEY) {
          return { key: PAGE_FILTER_KEY, value: 1 };
        }
        return filter;
      })
      .filter(newFilter => newFilter.value);

    if (key === 'school') {
      newFilters[newFilters.findIndex(newFilter => newFilter.key === 'education')].value = 'all';
    }

    history.push(buildURLwithQueryParams(ADMIN_ENVIRONMENT_ROUTES.USERS_PAGE, newFilters));
  };

  const { schoolsFilterList, educationsFilterList } = useMemo(() => {
    const schools = [
      {
        label: t('userFilter.school.defaultValue.label', 'All schools'),
        value: 'all',
      },
      ...schoolList.map(schoolItem => ({
        value: schoolItem.id,
        label: schoolItem.title,
      })),
    ];

    const schoolEducations = schoolList?.find(schoolItem => schoolItem.id === school)?.educations || [];

    const educations = [
      {
        label: t('userFilter.education.defaultValue.label', 'All educations'),
        value: 'all',
      },
      ...schoolEducations.map(educationItem => ({
        value: educationItem.id,
        label: educationItem.title,
      })),
    ];

    return { schoolsFilterList: schools, educationsFilterList: educations };
  }, [schoolList, school]);

  const links = useMemo(
    () =>
      [...Array(filteredAccounts.totalPages)].map((value, filterPage: number) => {
        const newFilters = filters;
        newFilters[newFilters.findIndex(newFilter => newFilter.key === 'page')].value = filterPage + 1;
        const searchURL = buildURLwithQueryParams(ADMIN_ENVIRONMENT_ROUTES.USERS_PAGE, newFilters);
        return {
          path: searchURL,
          title: filterPage + 1,
        };
      }),
    [filteredAccounts.totalPages],
  );

  const tabsRoleFilterValues = [
    {
      value: ROLES.STUDENT,
      dataCy: 'students-filter',
      text: t('userFilter.student.label', 'Students'),
    },
    {
      value: ROLES.TEACHER,
      dataCy: 'teachers-filter',
      text: t('userFilter.teachers.label', 'Teachers'),
    },
  ];
  return (
    <StyledUsersPage>
      <TabFilter
        selectedTab={role}
        tabsFiltersValues={tabsRoleFilterValues}
        onTabChange={selectedRole => onFilterChange(selectedRole, 'role')}
      />
      <StyledLink
        borderVariant="rounded"
        data-cy="new-user-link"
        to={{
          pathname: ADMIN_ENVIRONMENT_ROUTES.USER_CREATE_PAGE,
          state: {
            params: storedParams,
          },
        }}
        variant="button"
      >
        {t('button.newUser.label', 'Create new user')}
      </StyledLink>
      <UsersFilter
        educations={educationsFilterList}
        schools={schoolsFilterList}
        searchValueParam={input}
        selectedEducation={education}
        selectedSchool={school}
        onEducationChange={selectedEducation => onFilterChange(selectedEducation, 'education')}
        onSchoolChange={selectedSchool => onFilterChange(selectedSchool, 'school')}
        onSearchValueChange={searchValue => onFilterChange(searchValue, 'input')}
      />
      {filteredAccounts.data && (
        <>
          {role === ROLES.STUDENT ? (
            <StudentsTable
              selectedResults={+results}
              storedParams={storedParams}
              onResultsChange={selectedResults => onFilterChange(selectedResults, 'results')}
            />
          ) : (
            <TeachersTable
              selectedResults={+results}
              storedParams={storedParams}
              onResultsChange={selectedResults => onFilterChange(selectedResults, 'results')}
            />
          )}
          <Paginator
            currentPage={+page}
            links={links}
            totalPages={filteredAccounts.totalPages}
            onChangePage={selectedPage => onFilterChange(selectedPage, 'page')}
          />
        </>
      )}
    </StyledUsersPage>
  );
});
