import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FlexCenterBetweenMixin } from 'styles/helpers/layout';
import { Modal } from 'components/Modal';
import { modalIds } from 'constants/modals-constants';
import { useModal } from 'hooks/useModal';
import { Heading2, Heading4 } from 'styles/elements/Headings';

import {
  StyledTestDurationModal,
  StyledButton,
  StyledInputWrapper,
  StyledInput,
  StyledInputWithSideText,
  StyledLabel,
} from './UpdateTestDurationModal.styled';
import { UpdateTestDurationModalProps } from './UpdateTestDurationModal.model';

export const UpdateTestDurationModal: React.FC<UpdateTestDurationModalProps> = ({ onChangeTestDuration }) => {
  const { closeModal, isModalOpen, modalParams } = useModal();
  const { t } = useTranslation('scheduled-tests');
  const [testDuration, setTestDuration] = useState(modalParams?.duration || 0);

  return (
    <Modal dataCy="update-test-time-modal" isOpen={isModalOpen(modalIds.updateTestTimeModal)} onClose={closeModal}>
      <StyledTestDurationModal>
        <Heading2>{t('scheduledTestList.item.updateTestTime.header', 'Edit test duration')}</Heading2>
        <Heading4>
          {t('scheduledTestList.item.updateTestTime.label-1', 'How long do you want the test to take?')}
        </Heading4>
        <Heading4>
          {t('scheduledTestList.item.updateTestTime.label-2', 'Enter the new test duration (in minutes).')}
        </Heading4>
        <StyledInputWrapper layout={FlexCenterBetweenMixin}>
          <div>
            <StyledLabel disabled>
              {t('scheduledTestList.item.updateTestTime.duration.currentDuration', 'Current duration')}
            </StyledLabel>
            <StyledInput disabled style={{ lineHeight: '1.5rem' }} value={Number(modalParams?.duration) ?? 0} />
          </div>
          <div>
            <StyledLabel>{t('scheduledTestList.item.updateTestTime.duration.newDuration', 'New duration')}</StyledLabel>
            <StyledInputWithSideText>
              <StyledInput
                data-cy="test-duration-input"
                min="1"
                step="1"
                style={{ lineHeight: '1.5rem' }}
                type="number"
                value={Number(testDuration)}
                onChange={event => setTestDuration(event.target.value)}
              />
              <i>Min</i>
            </StyledInputWithSideText>
          </div>
        </StyledInputWrapper>
        <StyledButton
          dataCy="save-duration-button"
          variant="primary"
          onClick={() => onChangeTestDuration(+testDuration)}
        >
          {t('scheduledTestList.item.updateTestTime.button.label', 'Save')}
        </StyledButton>
      </StyledTestDurationModal>
    </Modal>
  );
};
