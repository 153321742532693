import { buildURLwithQueryParams, URLFields } from '_helpers/utils/urlBuilder';

interface QueryParams {
  [key: string]: string | string[] | null;
}

// Redirect to the page to perform login via IDP
const redirectToIdpLogin = (queryParamsPath: QueryParams): void => {
  const queryParams = {
    ...queryParamsPath,
    redirect_uri: `${window.location.origin}/`,
  } as QueryParams;
  const arrayQueryParams = Object.keys(queryParams).map(key => ({ key, value: queryParams[key] })) as URLFields[];
  const baseLoginURL = '/idp-signin';
  const loginURL = Object.keys(arrayQueryParams).length
    ? buildURLwithQueryParams(baseLoginURL, arrayQueryParams)
    : baseLoginURL;
  window.location.href = loginURL;
};

// Redirect to page that cleans up IDP generated cookies
const redirectToIdpLogout = (prevPath?: string): void => {
  const redirectUri = `${window.location.origin}/`;
  const logoutURL = `/idp-logout?redirect_uri=${redirectUri}`;
  const url = prevPath ? `${logoutURL}&state=${prevPath}` : logoutURL;
  window.location.href = url;
};

export { redirectToIdpLogin, redirectToIdpLogout };
