import React from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { useWriting } from 'hooks/useWriting';

import { PRODUCT_PAGES_ROUTES } from 'constants/routes';
import { buildUrlWithPathParams } from '_helpers/utils/urlBuilder';

import { ModuleSubject } from 'models/product/ModuleDetails';
import { ModuleDetailsPageParams } from '../../ModuleDetailsPage.model';

import {
  StyledWritingSubject,
  StyledCardStatus,
  StyledTitleContainer,
  StyledProgressContainer,
  StyledCompletedIcon,
} from './WritingSubject.styled';
import { trackPlatformEvent } from 'analytics/MetabaseTracker/metabase-tracker';
import { STUDENT_MODULES } from 'analytics/constants/pageTypes';
import { PLATFORM_EVENT_TYPES } from 'analytics/constants/platformEventTypes';

interface ComponentProps {
  subject: ModuleSubject;
}

export const WritingSubject: React.FC<ComponentProps> = observer(({ subject }) => {
  const history = useHistory();
  const { productId, moduleId }: ModuleDetailsPageParams = useParams();

  const { t } = useTranslation('homepage');
  const { writingSubjectsStatus } = useWriting();

  const subjectStats = writingSubjectsStatus?.find(({ subjectId }) => subjectId === subject.id);
  const { totalTopics, startedTopics, completedTopics } = subjectStats || {
    totalTopics: 0,
    completedTopics: 0,
    startedTopics: 0,
  };

  const isCompleted = completedTopics === totalTopics;
  const hasWritingExercises = totalTopics > 0;

  const getButtonLabel = () => {
    if (!hasWritingExercises) {
      return t('subjects.button.view.label', 'View');
    }

    if (startedTopics === 0) {
      return t('subjects.button.start.label', 'Start');
    }

    return !isCompleted ? t('subjects.button.continue.label', 'Continue') : t('subjects.button.repeat.label', 'Repeat');
  };

  return (
    <StyledWritingSubject>
      <StyledCardStatus
        buttonLabel={getButtonLabel()}
        circleComponent={
          hasWritingExercises && (
            <StyledProgressContainer data-cy="writing-subject-status">
              <span data-cy="completed-topics">{`${completedTopics}/${totalTopics}`}</span>
              <span>{t('subjects.completedTopics', 'Completed topics')}</span>
            </StyledProgressContainer>
          )
        }
        dataCy="subject-list-item"
        description={subject.description}
        options={{
          smallSize: true,
          buttonVariant: isCompleted || !hasWritingExercises ? 'secondary' : 'primary',
        }}
        title={
          <StyledTitleContainer>
            {subject.title}
            {hasWritingExercises && isCompleted && (
              <StyledCompletedIcon data-cy="completed-icon" iconName="fas fa-badge-check" />
            )}
          </StyledTitleContainer>
        }
        onClickButton={() => {
          trackPlatformEvent({
            module: STUDENT_MODULES.BASIC_PROGRESS,
            itemId: PLATFORM_EVENT_TYPES.STUDENT_MODULES_EVENT_TYPES.CLICK_PRACTICE_SUBJECT,
            value: JSON.stringify({
              productId,
              moduleId,
              subjectId: subject.id,
              subjectTitle: subject.title,
              isCompleted,
              hasWritingExercises,
              buttonLabel: getButtonLabel(),
            }),
          });
          return history.push(
            buildUrlWithPathParams(PRODUCT_PAGES_ROUTES.PRODUCT_WRITING_MODULE_DETAILS, {
              productId,
              moduleId,
              subjectId: subject.id,
            }),
          );
        }}
      />
    </StyledWritingSubject>
  );
});
