import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import { useProfile } from 'hooks/useProfile';
import { Heading3 } from 'styles/elements/Headings';

import { StyledTestHeader } from './TestHeader.styled';
import { TestHeaderProps } from './TestHeader.model';

const trimName = (fullName: string): string =>
  // Remove spaces in case middle name is empty
  fullName.replace(/\s+/g, ' ');

export const TestHeader: React.FC<TestHeaderProps> = observer(({ rightComponent, title, onClose }) => {
  const { userDetails, fetchUserDetails } = useProfile();
  const userName = userDetails
    ? trimName(`${userDetails.firstName} ${userDetails.middleName} ${userDetails.lastName}`)
    : '';

  useEffect(() => {
    if (!userDetails) {
      fetchUserDetails();
    }
  }, [fetchUserDetails]);

  return (
    <StyledTestHeader
      dataCy="scheduled-test-header"
      rightComponent={rightComponent}
      showCloseButton={!!onClose}
      subtitle={userDetails?.schoolNumber}
      title={userName}
      onClose={onClose}
    >
      <Heading3 data-cy="scheduled-test-header-title">{title}</Heading3>
    </StyledTestHeader>
  );
});
