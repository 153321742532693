export enum InviteStudentFormSteps {
  INVITE_GREETING = 1,
  ENTER_STUDENT_EMAILS = 2,
  SELECT_INVITE_PRODUCTS = 3,
}

export enum SkillsSelectorType {
  BASIC_SKILLS = 'basicProgress.forAccount.skillsSelectorType.basicSkills',
  WRITING_SKILLS = 'basicProgress.forAccount.skillsSelectorType.writingSkills',
  SCHEDULED_TEST_RESULTS = 'basicProgress.forAccount.skillsSelectorType.scheduledTestResults',
}
