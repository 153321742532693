import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDomainHandler } from 'hooks/useDomainHandler';
import { SvgIconReact } from 'components/SvgIconReact/SvgIconReact';
import { StyledIcon, StyledFilter } from './EventsFilter.styled';
import { EventsFilterProps } from './EventsFilter.model';

export const EventsFilter: React.FC<EventsFilterProps> = ({
  filter,
  isFilterSelected,
  totalStudents,
  onSelectFilter,
}) => {
  const { t } = useTranslation('scheduled-tests');
  const { getStudentTermByDomain } = useDomainHandler();

  const filterNames: Record<string, () => string> = {
    all: () =>
      t('scheduled-tests:liveMonitor.students.filter.all', {
        studentTerm: getStudentTermByDomain({ usePlural: true }),
      }),
    possibleFraud: () => t('scheduled-tests:liveMonitor.students.filter.possibleFraud', 'Possible fraud'),
    extraTimeVersion: () => t('scheduled-tests:liveMonitor.students.filter.extraTimeVersion', 'Extra time version'),
    termsAccepted: () => t('scheduled-tests:liveMonitor.students.filter.termsAccepted', 'Policies approved'),
    testing: () => t('scheduled-tests:liveMonitor.students.filter.testing', 'Testing'),
    finished: () => t('scheduled-tests:liveMonitor.students.filter.finished', 'Finished'),
  };

  return (
    <StyledFilter data-cy={`logs-filter-${filter}`} selected={isFilterSelected} onClick={() => onSelectFilter(filter)}>
      <StyledIcon selected={isFilterSelected}>
        <SvgIconReact iconName="studentIcon" size={1.5} />
        <span>{totalStudents}</span>
      </StyledIcon>
      <span>{filterNames[filter]()}</span>
    </StyledFilter>
  );
};
