import { ExerciseStatus, TopicExerciseStatus } from 'models/writing/WritingDetail';
import { TFunction } from 'react-i18next';
import { ElearningThemeInterface } from 'themes/elearningInterface.theme';

export const getExerciseStatus = (
  t: TFunction<'writing-module'>,
  topicExerciseStatus: TopicExerciseStatus[],
  exerciseId: number,
): [ExerciseStatus, string] | null => {
  const selectedExerciseStatus = topicExerciseStatus.find(exerciseStatus => exerciseStatus.exerciseId === exerciseId);

  if (!selectedExerciseStatus || selectedExerciseStatus.status === ExerciseStatus.NOT_STARTED) {
    return null;
  }

  const statusLabels = {
    [ExerciseStatus.CORRECT]: t('topicDetails.exerciseStatus.passed', 'Passed'),
    [ExerciseStatus.INCORRECT]: t('topicDetails.exerciseStatus.failed', 'Failed'),
    [ExerciseStatus.IN_PROGRESS]: t('topicDetails.exerciseStatus.inProgress', 'In progress'),
  };

  return [selectedExerciseStatus.status, statusLabels[selectedExerciseStatus.status]];
};

export const getExerciseToastBgColor = (status: ExerciseStatus, hogesTheme: ElearningThemeInterface): string => {
  switch (status) {
    case ExerciseStatus.CORRECT:
      return hogesTheme.palette.feedback.success.brand;
    case ExerciseStatus.INCORRECT:
      return hogesTheme.palette.feedback.error._200;
    default:
      return hogesTheme.palette.neutral._200;
  }
};

export const getExerciseToastTextColor = (status: ExerciseStatus, hogesTheme: ElearningThemeInterface): string => {
  switch (status) {
    case ExerciseStatus.CORRECT:
      return '#0E6027';
    case ExerciseStatus.INCORRECT:
      return '#D3000A';
    default:
      return hogesTheme.palette.secondary.brand;
  }
};
