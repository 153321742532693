import React, { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { PRODUCT_PAGES_ROUTES } from 'constants/routes';
import { buildUrlWithPathParams } from '_helpers/utils/urlBuilder';

import { useWriting } from 'hooks/useWriting';

import { Heading2 } from 'styles/elements/Headings';

import { BackButton } from 'components/BackButton';
import { LoadingSpinner } from 'components/LoadingSpinner';
import { EmbeddedHtml } from 'components/EmbeddedHtml';

import { ChapterList } from './components/ChapterList/ChapterList';

import {
  StyledWritingDetail,
  StyleHeader,
  StyledContainer,
  StyledDescription,
} from './WritingSubjectDetailsPage.styled';

export interface PathParams {
  productId: string;
  moduleId: string;
  subjectId: string;
}

export const WritingSubjectDetailsPage: React.FC = observer(() => {
  const { t } = useTranslation('writing-module');
  const { getWritingDetail, writingSubjectDetails } = useWriting();
  const { productId, moduleId, subjectId }: PathParams = useParams();

  const backButtonRedirectURL = useMemo(
    () =>
      buildUrlWithPathParams(PRODUCT_PAGES_ROUTES.PRODUCT_MODULE_DETAILS, {
        productId,
        moduleId,
      }),
    [productId, moduleId],
  );

  useEffect(() => {
    getWritingDetail(+productId, +moduleId, +subjectId);
  }, [productId, moduleId, subjectId]);

  if (!writingSubjectDetails) {
    return <LoadingSpinner />;
  }

  return (
    <StyledWritingDetail>
      <BackButton
        dataCy="writing-subject-back-btn"
        label={t('subjectDetails.button.back.label', 'Back')}
        redirectUrl={backButtonRedirectURL}
      />
      <StyleHeader>
        <Heading2 data-cy="writing-subject-title">{writingSubjectDetails.title}</Heading2>
        {writingSubjectDetails.description && (
          <StyledDescription data-cy="writing-subject-description">
            <EmbeddedHtml rawHtml={writingSubjectDetails?.description} />
          </StyledDescription>
        )}
      </StyleHeader>
      <StyledContainer>
        {writingSubjectDetails?.chapters?.map(chapter => (
          <ChapterList key={chapter.id} items={chapter.topics} title={chapter.title} />
        ))}
      </StyledContainer>
    </StyledWritingDetail>
  );
});
