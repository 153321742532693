import React from 'react';
import { SvgIconReact } from 'components/SvgIconReact';
import { StyledProgressDotsContainer } from './progress-dots.styled';

interface ProgressDotsProps {
  count: number;
  activeIndex: number;
}

export const ProgressDots: React.FC<ProgressDotsProps> = ({ count, activeIndex }) => (
  <StyledProgressDotsContainer>
    {[...Array(count)].map((_, index) => (
      <SvgIconReact
        // eslint-disable-next-line react/no-array-index-key
        key={index}
        className={index === activeIndex ? 'active' : ''}
        dataCy="dot-icon"
        iconName="dotIcon"
      />
    ))}
  </StyledProgressDotsContainer>
);
