import * as Yup from 'yup';
import i18n from 'i18n';
import { addMinutes, format } from 'date-fns';
import { GenericValidator } from 'pages/TeacherEnvironment/TeacherDashboard/subpages/ScheduledTestWizard/validate';

const formatDate = (date: Date) => format(date, 'dd-MM-yyyy HH:mm');

const getMinDate = (): Date => addMinutes(new Date(), 5);

interface ScheduledDateRow {
  startDate: Date;
  duration: number;
}

export const createValidationSchema = (editMode = false): GenericValidator =>
  Yup.object({
    schedule: Yup.array()
      .of(
        Yup.object({
          startDate: Yup.date()
            .label(i18n.t('test-request-form:formFields.startDate'))
            .required(({ label }) => i18n.t('test-request-form:formErrors.required', { label }))
            .when([], (schema: Yup.DateSchema): Yup.DateSchema => {
              if (editMode) {
                return schema;
              }

              return schema.min(getMinDate(), ({ min, label }) =>
                i18n.t('test-request-form:formErrors.minDate', { min: formatDate(min as Date), label }),
              );
            }),
          duration: Yup.number()
            .label(i18n.t('test-request-form:formFields.duration'))
            .typeError(i18n.t('test-request-form:formErrors.NaN'))
            .min(1, ({ min, label }) => i18n.t('test-request-form:formErrors.min', { min, label }))
            .required(({ label }) => i18n.t('test-request-form:formErrors.required', { label })),
          estimatedStudents: Yup.number()
            .min(1, ({ min, label }) => i18n.t('test-request-form:formErrors.min', { min, label }))
            .typeError(i18n.t('test-request-form:formErrors.NaN'))
            .required(({ label }) => i18n.t('test-request-form:formErrors.required', { label })),
        }),
      )
      .min(1)
      .defined(),
    extraTime: Yup.number()
      .label(i18n.t('test-request-form:formFields.extraTime'))
      .typeError(i18n.t('test-request-form:formErrors.NaN'))
      .min(1, ({ min, label }) => i18n.t('test-request-form:formErrors.min', { min, label }))
      .nullable()
      .defined(),
    useCustomReviewDate: Yup.boolean().defined(),
    reviewStartDate: Yup.date()
      .nullable()
      .label(i18n.t('test-request-form:formFields.reviewStartDate'))
      // @ts-ignore
      .when(
        ['useCustomReviewDate', 'schedule'],
        // @ts-ignore
        (
          useCustomReviewDate: boolean,
          scheduledDateRows: ScheduledDateRow[],
          schema: Yup.DateSchema,
        ): Yup.DateSchema => {
          if (!useCustomReviewDate) {
            return schema;
          }

          // Ensure that the review start date is, at least, equal to the end date for the instance that ends earlier
          let minEndDate: Date | null = null;
          scheduledDateRows.forEach((scheduledDateRow: ScheduledDateRow) => {
            const { startDate, duration } = scheduledDateRow;
            const endDate = addMinutes(startDate, duration);
            if (!minEndDate || minEndDate > endDate) {
              minEndDate = endDate;
            }
          });

          return schema
            .required(({ label }) => i18n.t('test-request-form:formErrors.required', { label }))
            .min(minEndDate, ({ min, label }) =>
              i18n.t('test-request-form:formErrors.minDate', { min: formatDate(min as Date), label }),
            );
        },
      ),
    reviewEndDate: Yup.date()
      .nullable()
      .label(i18n.t('test-request-form:formFields.reviewEndDate'))
      .when('useCustomReviewDate', {
        is: true,
        then: schema =>
          schema
            .defined()
            .required(({ label }) => i18n.t('test-request-form:formErrors.required', { label }))
            .notOneOf([Yup.ref('reviewStartDate')], ({ label }) =>
              i18n.t('test-request-form:formErrors.dateNotSame', { label }),
            )
            .min(Yup.ref('reviewStartDate'), ({ min, label }) =>
              i18n.t('test-request-form:formErrors.minDate', { min: formatDate(min as Date), label }),
            ),
      }),
  });
