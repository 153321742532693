// @ts-nocheck
import React from 'react';

import { ExamQuestion, ExamQuestionWithAnswers, ExerciseAnswer } from 'models/exam/Exam';
import { QuestionStatus, QuestionType } from 'constants/exam-constants';

import { SingleSelectQuestion } from 'layouts/QuestionManager/components/SingleSelectQuestion';
import { CorrectTextQuestion } from 'layouts/QuestionManager/components/CorrectTextQuestion/CorrectTextQuestion';
import { ReviewExerciseAnswersProps } from './ReviewExerciseAnswers.model';
import { StyledQuestionContainer } from './ReviewExerciseAnswers.styled';

export const ReviewExerciseAnswers: React.FC<ReviewExerciseAnswersProps> = ({
  answers,
  evaluateAnswer,
  isEvaluatingAnswer,
  questions,
}) => {
  const getQuestionView = (question: ExamQuestion, answer?: ExerciseAnswer) => {
    const commonProps = {
      evaluateAnswer,
      question,
      status: QuestionStatus.REVIEWING_ANSWERS,
      isEvaluatingAnswer,
      feedbackComponent: null, // When reviewing user answers, we never show feedback
    };
    switch (question.type) {
      case QuestionType.SINGLE_SELECT:
        return (
          <SingleSelectQuestion
            key={question.id}
            submitOnOptionSelect
            answerOptions={(question as ExamQuestionWithAnswers).options}
            selectedOptionId={
              (question as ExamQuestionWithAnswers).options.find(option => option.id === answer?.answers[0]?.value)?.id
            }
            {...commonProps}
          />
        );
      case QuestionType.CORRECT_TEXT:
        return (
          <CorrectTextQuestion
            key={question.id}
            answerCount={question.metadata?.answerCount || 0}
            availableActions={question.metadata?.availableActions}
            userAnswers={answer?.answers}
            {...commonProps}
          />
        );
      default:
        return null;
    }
  };
  return (
    <div>
      {questions.map((question: ExamQuestion, index) => (
        <StyledQuestionContainer key={question.id}>
          <span>{index + 1}</span>
          {getQuestionView(
            question,
            answers.find(a => a.id === question.id),
          )}
        </StyledQuestionContainer>
      ))}
    </div>
  );
};
