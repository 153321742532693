import React from 'react';

export type ModalPosition = 'center' | 'top' | 'bottom' | 'left' | 'right';
export type ModalAnimationType = 'fade' | 'slideTop' | 'slideBottom' | 'slideLeft' | 'slideRight';

export type ModalProps = React.PropsWithChildren<{
  closeOnClickOutside?: boolean;
  dataCy?: string;
  className?: string;
  showCloseButton?: boolean;
  isOpen?: boolean;
  modalPosition?: [ModalPosition, ModalPosition];
  modalAnimation?: ModalAnimationType;
  wrapInModalBox?: boolean;
  renderToElementType?: ModalRenderType;
  onClose?: () => void;
}>;

export enum ModalRenderType {
  REACT_PORTAL = 'react-portal',
  INLINE = 'inline',
}
