import { Button } from 'components/Button';
import { Checkbox } from 'components/Checkbox/Checkbox';
import { SkeletonLoader } from 'components/SkeletonLoader/SkeletonLoader';
import { useAdmin } from 'hooks/useAdmin';
import { useModal } from 'hooks/useModal';
import { useScheduledTests } from 'hooks/useScheduledTests';
import { observer } from 'mobx-react';
import format from 'date-fns/format';
import { useParams } from 'react-router-dom';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Heading4 } from 'styles/elements/Headings';
import { PathParams } from '../../../UserScheduledTestsGroup/UserScheduledTestsGroup';
import { ReviewDates } from './components/ReviewDates/ReviewDates';
import { StyledInnerWrapper } from './UpdateScheduledTest.styled';

type KeyValue = {
  [key: string]: string | number | boolean;
};

export interface UpdateScheduledTestProps {
  id: number;
  cumulative: boolean;
  reviewStartDate: string;
  reviewEndDate: string;
}

export const UpdateScheduledTest: FC<UpdateScheduledTestProps> = observer(
  ({ id, cumulative, reviewEndDate, reviewStartDate }) => {
    const [values, setValues] = useState<KeyValue>();
    const { date }: PathParams = useParams();
    const { closeModal } = useModal();

    const { updateScheduledTest } = useScheduledTests();
    const { t } = useTranslation('admin-environment');
    const { fetchScheduledTestsByDate } = useAdmin();

    useEffect(() => {
      !values &&
        setValues({
          cumulative,
          reviewStartDate,
          reviewEndDate,
        });
    }, [values]);

    const Save = () => {
      if (!values) return;

      updateScheduledTest(id, {
        cumulative: values.cumulative as boolean,
        reviewStartDate: values.reviewStartDate.toString(),
        reviewEndDate: values.reviewEndDate.toString(),
      }).then(() => {
        closeModal();
        fetchScheduledTestsByDate(format(new Date(date), 'Y-MM-dd'));
      });
    };

    if (!values) return <SkeletonLoader $marginTop={1} />;

    return (
      <StyledInnerWrapper>
        <div>
          <Heading4 data-cy="modal-title">{t('adminTools.scheduledTest.modal.title')}</Heading4>
        </div>
        <div>
          <div>
            <Checkbox
              checked={(values?.cumulative as boolean) ?? false}
              dataCy="cumulative-option"
              id="cumulative-option"
              label={t('adminTools.scheduledTest.modal.cumulative', 'Cumulative')}
              onChange={e => setValues({ ...values, cumulative: e.target.checked })}
            />
            <ReviewDates
              endDate={values?.reviewEndDate.toString() ?? ''}
              startDate={values?.reviewStartDate.toString() ?? ''}
              onValueChanged={(reviewStartDate, reviewEndDate) =>
                setValues({ ...values, reviewStartDate, reviewEndDate })
              }
            />
          </div>
        </div>
        <div>
          <Button dataCy="save-update-test" disabled={!values} variant="primary" onClick={() => Save()}>
            {t('adminTools.scheduledTest.modal.save')}
          </Button>
        </div>
      </StyledInnerWrapper>
    );
  },
);
