import React from 'react';
import { SvgIconReact } from 'components/SvgIconReact/SvgIconReact';
import { StyledIconButton } from './IconButton.styled';
import { IconButtonProps } from './IconButton.model';

export const IconButton: React.FC<IconButtonProps> = ({ isCircle = true, iconName, iconSize = 1, ...buttonProps }) => (
  <StyledIconButton {...buttonProps} isNarrow $isCircle={isCircle}>
    <SvgIconReact iconName={iconName} size={iconSize} />
  </StyledIconButton>
);
