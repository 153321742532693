import styled from 'styled-components/macro';

import { ThemeProps as Props } from 'models/styles/theme';

import { CssFlex } from 'styles/helpers/layout';

export const StyledBottomBar = styled(CssFlex)`
  background: #fbfbfb;
  padding: 1rem 4rem;
  font-size: ${(props: Props) => props.theme.font.size._12};
`;
