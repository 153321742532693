import styled from 'styled-components/macro';
import { calculateRems } from 'styles/mixins/calculateRem';

import { bgColorCalc, borderColorCalc } from '_helpers/statusColorCalc';

import { StyledInputQuestionProps, StyledInputQuestionProps as Props } from './InputQuestion.model';

export const StyledInputQuestion = styled.div<Props>`
  display: flex;
  flex-direction: column;
  align-items: center;

  .opdracht {
    text-align: left;
    color: ${(props: Props) => props.theme.palette.neutral.black};
    font-weight: ${(props: Props) => props.theme.font.weight.bolder};
    font-size: ${(props: Props) => props.theme.font.size._24};
  }

  p {
    color: ${(props: Props) => props.theme.palette.neutral._900};
  }

  .input-box {
    display: inline-grid;
    span {
      justify-self: center;
    }
  }
`;

export const StyledInputField = styled.input<StyledInputQuestionProps>`
  background-color: ${(props: Props) => bgColorCalc(props)};
  border-radius: 4px;
  border-style: solid;
  border-color: ${(props: Props) => borderColorCalc(props)};
  height: 3rem;
  width: 15rem;
  text-align: center;
  margin: ${calculateRems([10, 12])};

  :focus {
    outline: none !important;
  }
`;
