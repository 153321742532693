import React, { useEffect, useState } from 'react';
import { intervalToDuration, addSeconds, addDays, addMonths, differenceInHours } from 'date-fns';
import { CountdownTimerProps } from './CountdownTimer.model';
import { StyledCountdownTimer } from './CountdownTimer.styled';

interface CountdownValues {
  hours: number;
  minutes: number;
  seconds: number;
}

const getCountdownValues = (secondsLeft: number): CountdownValues => {
  const duration = intervalToDuration({ start: new Date(0), end: addSeconds(new Date(0), +secondsLeft) });
  const daysToHours = differenceInHours(addDays(new Date(0), duration.days || 0), new Date(0));
  const monthsToHours = differenceInHours(addMonths(new Date(0), duration.months || 0), new Date(0));
  return {
    hours: Math.max(0, (duration.hours ? duration.hours : 0) + daysToHours + monthsToHours),
    minutes: Math.max(0, duration.minutes || 0),
    seconds: Math.max(0, duration.seconds || 0),
  };
};

const twoDigitFormat = (num: number): string => (num < 10 ? `0${num}` : `${num}`);

export const MyCountdownTimer: React.FC<CountdownTimerProps> = ({
  className,
  secondsLeft,
  prefix,
  warningTimeSeconds,
  format = 'default',
  onCountdownFinish = () => 0,
}) => {
  const [countdownValues, setCountdownValues] = useState(getCountdownValues(secondsLeft));

  useEffect(() => {
    setCountdownValues(getCountdownValues(secondsLeft));
  }, [secondsLeft]);

  useEffect(() => {
    if (secondsLeft <= 0) {
      onCountdownFinish();
    }
  }, [secondsLeft]);

  const { hours, minutes, seconds } = countdownValues;
  const showWarning = !!(warningTimeSeconds && secondsLeft <= warningTimeSeconds);

  return (
    <StyledCountdownTimer $format={format} $warning={showWarning} className={className} data-cy="countdown-timer">
      {prefix}
      {`${twoDigitFormat(hours)}:${twoDigitFormat(minutes)}:${twoDigitFormat(seconds)}`}
    </StyledCountdownTimer>
  );
};

export const CountdownTimer = React.memo(MyCountdownTimer);
