import React from 'react';
import { HelmetProvider as DefaultHelmetProvider, Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useDomainHandler } from 'hooks/useDomainHandler';

export const HelmetProvider: React.FC = ({ children }) => {
  const { t, i18n } = useTranslation('common');
  const { getCustomNameByDomain, getCustomFaviconByDomain } = useDomainHandler();
  const appName = getCustomNameByDomain();

  return (
    <DefaultHelmetProvider>
      <Helmet>
        <html lang={i18n.language} />
        <title>{appName}</title>
        <link href={getCustomFaviconByDomain()} rel="icon" type="image/png" />
        <meta content={t('meta.description', { appName })} name="description" />
      </Helmet>
      {children}
    </DefaultHelmetProvider>
  );
};
