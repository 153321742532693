import { SvgIconReact } from 'components/SvgIconReact';
import React from 'react';
import { TagProps } from './Tag.model';
import { StyledTag } from './Tag.styled';

export const Tag: React.FC<TagProps> = ({
  className,
  children,
  variant = 'default',
  fillType = 'bordered',
  boldText = false,
  leftIcon,
}) => (
  <StyledTag $boldText={boldText} $fillType={fillType} $variant={variant} className={className}>
    {leftIcon && <SvgIconReact iconName={leftIcon} size={1} />}
    {children}
  </StyledTag>
);
