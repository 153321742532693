import styled from 'styled-components/macro';
import { ThemeProps } from 'models/styles/theme';
import { calculateRem } from 'styles/mixins/calculateRem';
import { Select } from 'components/Select';

export const StyledInputSelectAdmin = styled(Select)<ThemeProps>`
  height: ${calculateRem(48)};
  margin-left: auto;
  margin-top: ${calculateRem(27)};
  width: ${calculateRem(242)};
`;
