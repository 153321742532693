import React, { FC } from 'react';
import Skeleton, { SkeletonProps } from 'react-loading-skeleton';
import { StyledSkeletonWrapper } from './SkeletonLoader.styled';
import 'react-loading-skeleton/dist/skeleton.css';

export interface SkeletonLoaderProps extends SkeletonProps {
  $marginTop: number;
}

export const SkeletonLoader: FC<SkeletonLoaderProps> = props => {
  const { $marginTop } = props;
  return (
    <StyledSkeletonWrapper $marginTop={$marginTop}>
      <Skeleton {...props} />
    </StyledSkeletonWrapper>
  );
};
