import React from 'react';
import { useParams } from 'react-router-dom';

import { PRODUCT_PAGES_ROUTES } from 'constants/routes';
import { buildUrlWithPathParams } from '_helpers/utils/urlBuilder';

import { InfoStatus } from 'components/InfoStatus';
import { ListNavigationItem } from 'components/ListNavigationItem/ListNavigationItem';
import { EmbeddedHtml } from 'components/EmbeddedHtml';
import { ChapterListProps } from './ChapterList.model';
import { StyledCard, StyleHeader } from './ChapterList.styled';
import { WritingSubjectDetailsPageParams } from '../../WritingSubjectDetailsPage.model';

export const ChapterList: React.FC<ChapterListProps> = ({ title, items = [] }) => {
  const { productId, moduleId, subjectId }: WritingSubjectDetailsPageParams = useParams();

  return (
    <StyledCard>
      <StyleHeader data-cy="chapter-list-item">
        <EmbeddedHtml rawHtml={title} />
      </StyleHeader>
      <div>
        {items.map(item => (
          <ListNavigationItem
            key={item.id}
            dataCy="topic-list-item"
            extraData={<InfoStatus status={item.status} />}
            href={buildUrlWithPathParams(PRODUCT_PAGES_ROUTES.PRODUCT_TOPIC_DETAILS, {
              productId,
              moduleId,
              subjectId,
              topicId: item.id,
            })}
            title={item.title}
          />
        ))}
      </div>
    </StyledCard>
  );
};
