import React, { useEffect, useState } from 'react';
import { endOfDay } from 'date-fns';
import { observer } from 'mobx-react';
import { Trans, useTranslation } from 'react-i18next';

import { useScheduledTests } from 'hooks/useScheduledTests';

import { AvailableLanguage } from 'constants/language-constants';

import { SearchBox } from 'components/SearchBox';
import { DatePicker } from 'components/DatePicker/DatePicker';
import { LoadingSpinner } from 'components/LoadingSpinner';

import { Heading2, Heading3 } from 'styles/elements/Headings';

import { OwnedScheduledTestResultsOverviewFilters } from './OwnedScheduledTestResultsOverview.model';
import {
  StyledPageContainer,
  StyledContentContainer,
  StyledFilterContainer,
} from './OwnedScheduledTestResultsOverview.styled';
import { OwnedTestResultsTable } from './components/OwnedTestResultsTable/OwnedTestResultsTable';
import { StudentSearchBox } from './components/StudentSearchBox';
import { CssGrid } from 'styles/helpers/layout';

export const OwnedScheduledTestResultsOverviewPage: React.FC = observer(() => {
  const { t, i18n } = useTranslation('teacher-dashboard');

  const [searchTerm, setSearchTerm] = useState('');
  const [currentFilters, setCurrentFilters] = useState<OwnedScheduledTestResultsOverviewFilters>({});

  const {
    loading: isLoading,
    ownedScheduledTestResultsOverview,
    fetchScheduledTestResultsOverview,
  } = useScheduledTests();

  useEffect(() => {
    fetchScheduledTestResultsOverview(currentFilters);
  }, [currentFilters]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  const today = new Date();
  return (
    <StyledPageContainer>
      <CssGrid alignItems="end" gridTemplateColumns="1fr auto">
        <Heading2>{t('ownedScheduledTestResults.overview.title', 'Test results')}</Heading2>
        <StudentSearchBox />
      </CssGrid>
      <StyledContentContainer>
        <Heading3>
          <Trans i18nKey="ownedScheduledTestResults.overview.subtitle" t={t}>
            All tests (<span data-cy="test-count">{{ testCount: ownedScheduledTestResultsOverview?.length || 0 }}</span>
            )
          </Trans>
        </Heading3>
        <StyledFilterContainer>
          <SearchBox
            placeholder={t('ownedScheduledTestResults.overview.filters.testSearchBox.placeholder', 'Search test name')}
            value={searchTerm}
            onBlur={value =>
              setCurrentFilters({
                ...currentFilters,
                searchTerm: value.trim() || undefined,
              })
            }
            onChange={value => setSearchTerm(value)}
          />
          <DatePicker
            dataCy="min-start-date-picker"
            language={i18n.language as AvailableLanguage}
            maxDate={today}
            minDate={null}
            value={currentFilters.minStartDate || null}
            onChange={date =>
              setCurrentFilters({
                ...currentFilters,
                minStartDate: date,
              })
            }
          />
          <DatePicker
            dataCy="max-start-date-picker"
            language={i18n.language as AvailableLanguage}
            maxDate={today}
            minDate={null}
            value={currentFilters.maxStartDate || today}
            onChange={date =>
              setCurrentFilters({
                ...currentFilters,
                maxStartDate: endOfDay(date),
              })
            }
          />
        </StyledFilterContainer>
        <OwnedTestResultsTable
          displayOwnershipIcon
          scoreIsAverage
          language={i18n.language as AvailableLanguage}
          ownedScheduledTestResults={ownedScheduledTestResultsOverview || []}
        />
      </StyledContentContainer>
    </StyledPageContainer>
  );
});
