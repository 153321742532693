import styled from 'styled-components/macro';
import { ElearningThemeInterface } from 'themes/elearningInterface.theme';
import { ToastContainer, ToastContainerProps } from 'react-toastify';
import { calculateRems } from '../../styles/mixins/calculateRem';

interface Props extends ToastContainerProps {
  theme: ElearningThemeInterface;
}

export const StyledToastNotification = styled(ToastContainer)<Props>`
  .Toastify__toast {
    padding: ${calculateRems([8, 12])};
  }
  .Toastify__toast-body {
    display: grid;
    grid-template-columns: 10% 80%;
    align-items: center;
  }
  .Toastify__close-button {
    svg {
      margin-right: -3px;
    }
  }
  .Toastify__toast--success {
    background-color: ${({ theme }: Props) => theme.palette.feedback.success.brand};
  }
  .Toastify__toast--warning {
    background-color: ${({ theme }: Props) => theme.palette.feedback.error.brand};
  }
  .Toastify__toast--error {
    background-color: ${({ theme }: Props) => theme.palette.feedback.error.brand};
  }
  .Toastify__toast--info {
    background-color: ${({ theme }: Props) => theme.palette.secondary.brand};
  }
  .Toastify__toast--dark {
    background-color: ${({ theme }: Props) => theme.palette.neutral.black};
  }
`;
