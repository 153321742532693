import React, { useCallback, useState, useEffect, useRef } from 'react';
import { List } from 'immutable';
import { ModalRenderType } from 'components/Modal/Modal.model';
import { LoadingSpinner } from 'components/LoadingSpinner';
import { Trans, useTranslation } from 'react-i18next';
import { Modal } from 'components/Modal';
import { ExercisesPerSubjectGraph } from '../ExercisesPerSubjectGraph/ExercisesPerSubjectGraph';
import { ProgressBySubjectGraph } from '../ProgressBySubjectGraph/ProgressBySubjectGraph';
import { TotalExercisesGraph } from '../TotalExercisesGraph/TotalExercisesGraph';
import {
  StyledDownloadReportPage,
  StyledFooter,
  StyledGraphContainer,
  StyledHeader,
  StyledLoader,
  StyledPage,
} from './DownloadReportPage.styled';
import { DownloadReportPageProps } from './DownloadReportPage.model';

export const DownloadReportPage: React.FC<DownloadReportPageProps> = ({
  exercisePerSubjectResults,
  exercisesOverTimeResults,
  hasLoadingError,
  productDetails,
  productGoalLevel,
  productProgressSubjects,
  subjects,
  userDetails,
  onDone,
}) => {
  const isCanceled = useRef(false);
  const { t } = useTranslation('results');
  const elRef = useRef<HTMLDivElement>(null);
  const [renderedGraphs, setRenderedGraphs] = useState<List<boolean>>(List());
  const onGraphRenderComplete = useCallback((index: number) => {
    setRenderedGraphs(list => list.set(index, true));
  }, []);

  useEffect(() => {
    if (elRef.current && renderedGraphs.size === 3) {
      // Build the actual PDF
      // code splitted because we don't want to load PDF and html2canvas library
      // when not needed
      import('_helpers/pdf')
        .then(({ pdfFromElement }) => {
          if (isCanceled.current) {
            return undefined;
          }
          return pdfFromElement(elRef.current as HTMLElement, t('downloadReport.filename', 'report.pdf'), pdf =>
            isCanceled.current ? undefined : pdf,
          );
        })
        .then(onDone);
    }
  }, [renderedGraphs]);

  const onCancel = () => {
    // make sure the pdf is not downloaded after 'x' press on modal
    isCanceled.current = true;
    onDone();
  };

  const userName = userDetails && `${userDetails.firstName} ${userDetails.middleName} ${userDetails.lastName}`;

  return (
    <StyledDownloadReportPage>
      <StyledPage>
        <div ref={elRef}>
          <StyledHeader>{t('downloadReport.header', 'Personal Progress Report')}</StyledHeader>
          <StyledGraphContainer>
            <div>
              <ProgressBySubjectGraph
                hasLoadingError={hasLoadingError}
                productDetails={productDetails}
                productGoalLevel={productGoalLevel}
                productProgressSubjects={productProgressSubjects}
                subjects={subjects}
                onRenderComplete={useCallback(() => onGraphRenderComplete(0), [])}
              />
            </div>
            <div>
              <ExercisesPerSubjectGraph
                exercisePerSubjectResults={exercisePerSubjectResults}
                hasLoadingError={hasLoadingError}
                subjects={subjects}
                onRenderComplete={useCallback(() => onGraphRenderComplete(1), [])}
              />
            </div>
            <div>
              <TotalExercisesGraph
                exercisesOverTimeResults={exercisesOverTimeResults}
                hasLoadingError={hasLoadingError}
                onRenderComplete={useCallback(() => onGraphRenderComplete(2), [])}
              />
            </div>
          </StyledGraphContainer>
          <StyledFooter>
            <Trans i18nKey="downloadReport.footer" t={t}>
              {'Generated on '}
              <span>{{ date: new Date().toLocaleDateString() }}</span>
              {' for '}
              <span>{{ name: userName }}</span>
            </Trans>
          </StyledFooter>
        </div>
      </StyledPage>
      <Modal isOpen closeOnClickOutside={false} renderToElementType={ModalRenderType.REACT_PORTAL} onClose={onCancel}>
        <StyledLoader>
          <LoadingSpinner />
          <span>{t('downloadReport.loading', 'Generating report...')}</span>
        </StyledLoader>
      </Modal>
    </StyledDownloadReportPage>
  );
};
