import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Heading1, Heading3 } from 'styles/elements/Headings';
import { SvgIconReact } from 'components/SvgIconReact';
import { ReactComponent as WellDoneIcon } from 'assets/icons/well-done-icon.svg';
import { StyledHeader, StyledWrapper } from './AccountCreatedPage.styled';
import { TermsFooter } from '../components/TermsFooter/TermsFooter';
import { useDomainHandler } from 'hooks/useDomainHandler';

export const AccountCreatedPage: FC = () => {
  const { t } = useTranslation('auth-pages');
  const { getCustomLogoByDomain } = useDomainHandler();

  return (
    <>
      <StyledHeader>
        <SvgIconReact iconName={getCustomLogoByDomain()} size={13} />
      </StyledHeader>
      <StyledWrapper>
        <div>
          <WellDoneIcon />
          <Heading1>{t('invitationPage.accountCreated.title')}</Heading1>
          <Heading3>{t('invitationPage.accountCreated.description')}</Heading3>
        </div>
      </StyledWrapper>
      <TermsFooter />
    </>
  );
};
