import { ROLES } from '@he-learning/service-he-common';

import { commonWidgetOptions } from './common';
import { SupportWidgetVariantConfig } from '../../models/variant.model';

const BusinessSupportWidgetConfig: SupportWidgetVariantConfig = {
  apiKey: '5010cab9-2d0d-46cd-8de0-71a2d60e5a2e',
  configPerRole: {
    [ROLES.STUDENT]: {
      options: commonWidgetOptions,
      suggestions: {},
    },
    [ROLES.TEACHER]: {
      options: commonWidgetOptions,
      suggestions: {},
    },
  },
};

export default BusinessSupportWidgetConfig;
