import styled from 'styled-components/macro';

import { Button } from 'components/Button';
import { ThemeProps } from 'models/styles/theme';
import { Heading2, Heading4 } from 'styles/elements/Headings';
import { calculateRem } from 'styles/mixins/calculateRem';
import { LayoutProps } from 'styles/helpers/layout';

export interface LabelProps extends ThemeProps {
  disabled?: boolean;
}

export const StyledTestDurationModal = styled.div<ThemeProps>`
  background: ${({ theme }: ThemeProps) => theme.palette.neutral._200};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: ${calculateRem(40)} ${calculateRem(80)};
  width: ${calculateRem(576)};

  > ${Heading2} {
    margin-bottom: ${calculateRem(44)};
    text-align: center;
  }

  > ${Heading4} {
    text-align: center;
    color: ${({ theme }: ThemeProps) => theme.palette.neutral._900};
    font-weight: ${({ theme }: ThemeProps) => theme.font.weight.normal};
    margin-bottom: ${calculateRem(16)};
  }
`;

export const StyledButton = styled(Button)`
  margin: ${calculateRem(69)} auto 0;
  width: ${calculateRem(240)};
`;

export const StyledInputWrapper = styled.div<LayoutProps>`
  margin-top: ${({ theme }: ThemeProps) => theme.margin(3)};
  height: 5rem;
  ${props => props.layout ?? ''}

  > div {
    display: inline-block;
    width: 45%;
  }
`;

export const StyledInput = styled.input`
  width: 91%;
  padding: ${({ theme }: LabelProps) => theme.padding(2)};
`;

export const StyledInputWithSideText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }: LabelProps) => theme.margin(1)};
`;

export const StyledLabel = styled.div<LabelProps>`
  margin-bottom: ${({ theme }: LabelProps) => theme.margin(1)};
  color: ${({ disabled, theme }: LabelProps) => (disabled ? theme.palette.neutral.light : theme.palette.neutral.black)};
`;
