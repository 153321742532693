import styled from 'styled-components/macro';

import { calculateRem } from 'styles/mixins/mixins';
import { ThemeProps as Props } from 'models/styles/theme';

import { Button } from 'components/Button';
import { SvgIconReact } from 'components/SvgIconReact';

import backgroundImage from 'assets/images/basic-account-bg.png';

export const StyledPageLayout = styled.div<Props>`
  min-height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;

  background-image: ${() => `url(${backgroundImage})`};
  background-position: top left;
  background-repeat: no-repeat;
`;

export const StyledContainer = styled.main<Props>`
  flex-grow: 1;
  width: 45rem;
  max-width: 100%;
  padding: 8rem 4rem;

  display: flex;
  row-gap: 1rem;
  flex-direction: column;
  align-items: flex-start;
`;

export const StyledLogo = styled(SvgIconReact)`
  height: 1.3rem;
  width: auto;
`;

export const StyledForm = styled.form`
  display: flex;
  row-gap: 1.5rem;
  flex-direction: column;
  align-items: flex-start;
`;

export const StyledFieldRow = styled.section`
  width: 100%;
  display: grid;
  column-gap: 1.5rem;
  grid-template-columns: repeat(2, 1fr);
`;

export const StyledField = styled.div`
  > label {
    display: block;
    font-size: 1rem;
    line-height: 1.5rem;
    margin-bottom: 0.25rem;
  }

  input {
    border-radius: ${calculateRem(4)};
  }

  > span {
    font-size: ${calculateRem(10)};
  }
`;

export const StyledButton = styled(Button)<Props>`
  font-weight: normal;
  font-size: ${calculateRem(14)};
`;

export const StyledLoginSection = styled.section<Props>`
  font-size: ${calculateRem(10)};

  a {
    font-size: ${calculateRem(10)};
  }
`;

export const StyledCheckList = styled.ul<Props>`
  padding: 0;
  display: grid;
  list-style: none;
  margin: 0.5rem 0 0 0;
  grid-template-columns: 1fr 1fr;
  font-size: ${calculateRem(10)};
`;

export const StyledCheckListItem = styled.li<Props>`
  vertical-align: middle;

  ::before {
    content: '⬤';
    margin-right: 0.2rem;
    display: inline-block;
    color: ${(props: Props) => props.theme.palette.secondary.brand};
  }

  &[hidden] {
    color: #d9d9d9;
    display: initial;

    ::before {
      color: #d9d9d9;
    }
  }
`;
