import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { useProduct } from 'hooks/useProduct';
import { useWriting } from 'hooks/useWriting';

import { FontAwesomeIcon } from 'components/FontAwesomeIcon';

import { TEST_PAGE_ROUTES } from 'constants/routes';
import { buildUrlWithPathParams } from '_helpers/utils/urlBuilder';

import {
  StyledButton,
  StyledList,
  StyledListItem,
  StyledSelector,
  StyledStatusLabel,
} from './LegacyExerciseSelector.styled';
import { getExerciseStatus } from './CommonExerciseUtils';

export interface PathParams {
  productId: string;
  moduleId: string;
  subjectId: string;
  topicId: string;
}

export const LegacyExerciseSelector: React.FC = observer(() => {
  const [displayExercises, setDisplayExercises] = useState(false);

  const { t } = useTranslation('writing-module');
  const { productId, moduleId, subjectId, topicId }: PathParams = useParams();
  const history = useHistory();
  const { topicDetails: topic } = useProduct();
  const { exerciseStatus: topicExerciseStatus } = useWriting();

  if (!topic || !topic.exercises || topic.exercises.length === 0) {
    return null;
  }

  const goToExercise = (exerciseId: number) => {
    history.push(
      buildUrlWithPathParams(TEST_PAGE_ROUTES.WRITING_EXERCISE, {
        productId,
        moduleId,
        subjectId,
        topicId,
        exerciseId,
      }),
    );
  };

  return (
    <StyledSelector>
      <StyledButton
        dataCy="open-exercise-selector-button"
        size="medium"
        variant="primary"
        onClick={() => setDisplayExercises(!displayExercises)}
      >
        <span>{t('topicDetails.button.startPractice.label', 'Start practising')}</span>
        <FontAwesomeIcon iconName="fas fa-caret-down" />
      </StyledButton>

      {displayExercises && (
        <StyledList data-cy="exercise-list-container" onClick={() => setDisplayExercises(false)}>
          {topic.exercises?.map(exercise => {
            const exerciseStatusTuple = getExerciseStatus(t, topicExerciseStatus, exercise.id);
            return (
              <StyledListItem key={exercise.id} data-cy="exercise-list-item" onClick={() => goToExercise(exercise.id)}>
                {exercise.title}
                {exerciseStatusTuple && (
                  <StyledStatusLabel data-cy="exercise-list-item-status" status={exerciseStatusTuple[0]}>
                    {exerciseStatusTuple[1]}
                  </StyledStatusLabel>
                )}
              </StyledListItem>
            );
          })}
        </StyledList>
      )}
    </StyledSelector>
  );
});
