import { Button } from 'components/Button';
import { InputWithErrorMessage } from 'components/InputWithErrorMessage';
import { modalIds } from 'constants/modals-constants';
import { Formik } from 'formik';
import { useAdmin } from 'hooks/useAdmin';
import { useModal } from 'hooks/useModal';
import { observer } from 'mobx-react';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledField, StyledForm } from '../../UserToolsPage.styled';
import { TransferResultsModal } from '../TransferResultsModal/TransferResultsModal';
import { buildTransferResultsSchema, Emails } from './TransferResults.schema';

export const TransferResults: FC = observer(() => {
  const [emails, setEmails] = useState<Emails>();
  const { t } = useTranslation('admin-environment');
  const { openModal, closeModal } = useModal();
  const { allParticipatedTestCodes, transferResults, fetchAllParticipatedTestCodes } = useAdmin();

  useEffect(() => {
    if (allParticipatedTestCodes.length > 0) {
      openModal(modalIds.transferResultsModal);
    }
  }, [allParticipatedTestCodes]);

  return (
    <>
      <TransferResultsModal
        onSubmit={codes => {
          codes.map(testCode => emails?.sourceEmail && emails.targetEmail && transferResults({ ...emails, testCode }));
          closeModal();
        }}
      />
      <Formik
        enableReinitialize
        initialValues={{
          sourceEmail: '',
          targetEmail: '',
          testCode: '',
        }}
        validationSchema={buildTransferResultsSchema()}
        onSubmit={({ sourceEmail, targetEmail, testCode }) => {
          setEmails({ sourceEmail, targetEmail });
          testCode === ''
            ? fetchAllParticipatedTestCodes(sourceEmail)
            : transferResults({ sourceEmail, targetEmail, testCode });
        }}
      >
        {formik => (
          <StyledForm noValidate onSubmit={formik.handleSubmit}>
            <StyledField>
              <label htmlFor="sourceEmail">{t('accountDetails.sourceEmail', 'From Email')}</label>
              <InputWithErrorMessage
                dataCy="source-email"
                errorMessage={formik.errors.sourceEmail}
                hasError={!!(formik.errors.sourceEmail && formik.touched.sourceEmail)}
                name="sourceEmail"
                value={formik.values.sourceEmail}
                onChange={formik.handleChange}
              />
            </StyledField>

            <StyledField>
              <label htmlFor="testCode">{t('accountDetails.testCode.lable', 'Test Code')}</label>
              <InputWithErrorMessage
                dataCy="test-code"
                errorMessage={formik.errors.testCode}
                hasError={!!(formik.errors.testCode && formik.touched.testCode)}
                name="testCode"
                value={formik.values.testCode}
                onChange={formik.handleChange}
              />
            </StyledField>

            <StyledField>
              <label htmlFor="targetEmail">{t('accountDetails.targetEmail', 'From Email')}</label>
              <InputWithErrorMessage
                dataCy="target-email"
                errorMessage={formik.errors.targetEmail}
                hasError={!!(formik.errors.targetEmail && formik.touched.targetEmail)}
                name="targetEmail"
                value={formik.values.targetEmail}
                onChange={formik.handleChange}
              />
            </StyledField>

            <Button dataCy="submit" type="submit" variant="primary">
              {formik.values.testCode.length > 0
                ? t('adminTools.transferResults.save')
                : t('adminTools.transferResults.fetchCodes')}
            </Button>
          </StyledForm>
        )}
      </Formik>
    </>
  );
});
