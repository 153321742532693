import styled, { css } from 'styled-components/macro';

import { ThemeProps as Props } from 'models/styles/theme';

import { FontAwesomeIcon } from 'components/FontAwesomeIcon';
import { SortableTable } from 'components/SortableTable/SortableTable';
import { StyledHeaderCell, StyledCell } from 'components/SortableTable/SortableTable.styled';
import { CssFlex } from 'styles/helpers/layout';

export const StyledTable = styled(SortableTable)<Props>`
  margin-bottom: 4rem;

  ${StyledHeaderCell} {
    align-items: flex-start;
    justify-content: center;
    padding: 1rem 1.5rem;
  }

  ${StyledCell} {
    padding: 0.75rem 1.5rem;
    word-break: break-word;
  }
`;

export const StyledIcon = styled(FontAwesomeIcon)<Props>`
  color: ${(props: Props) => props.theme.palette.neutral.black};
`;

interface GroupIconContainerProps extends Props {
  $ownedBySelf?: boolean;
}

// IDEA: if you find yourself duplicating this, it might be a good idea to create a shared component
export const StyledGroupIconContainer = styled(CssFlex)<GroupIconContainerProps>`
  border-radius: 50%;

  width: 1.25rem;
  height: 1.25rem;
  font-size: 10px;

  align-items: center;
  justify-content: center;

  ${({ $ownedBySelf }: GroupIconContainerProps) => {
    if ($ownedBySelf) {
      return css`
        color: #f9b002;
        background: #fff3d5;
      `;
    } else {
      return css`
        color: #0078f1;
        background: #eef7ff;
      `;
    }
  }};
`;
