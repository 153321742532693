import { Modal } from 'components/Modal';
import { modalIds } from 'constants/modals-constants';
import { useModal } from 'hooks/useModal';
import { useWriting } from 'hooks/useWriting';
import { useDomainHandler } from 'hooks/useDomainHandler';
import { observer } from 'mobx-react';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  StyledDialog,
  StyledInnerDialog,
} from '../../../ScheduledTestWizard/steps/TestOptionsStep/components/FormSEBListStep/components/SEBKeyUpsertDialog/SEBKeyUpsertDialog.styled';
import { StudentListSection } from '../StudentListSection/StudentListSection';
import {
  StyledCloseButton,
  StyledSelect,
  StyledStudentListSectionWrapper,
  StyledTitle,
} from './WritingStudentListModal.styled';

export const WritingStudentListModal: FC = observer(() => {
  const [selectedValue, setSelectedValue] = useState<string>('all');
  const { writingSubjectStudentsDetails } = useWriting();
  const { t } = useTranslation('teacher-dashboard');
  const { isModalOpen, closeModal } = useModal();
  const { getStudentTermByDomain } = useDomainHandler();

  if (!writingSubjectStudentsDetails) return <></>;

  const { studentsCompleted, studentsInProgress, studentsNotStarted } = writingSubjectStudentsDetails;

  return (
    <Modal
      closeOnClickOutside
      showCloseButton
      isOpen={isModalOpen(modalIds.writingStudentListModal)}
      onClose={() => closeModal()}
    >
      <StyledDialog $left="25%" $width="50%">
        <StyledCloseButton iconName="far fa-times" iconSize="2x" onClick={() => closeModal()} />
        <StyledInnerDialog $width="90%">
          <StyledTitle>
            {t('writingResults.progress.subTitle', {
              studentTerm: getStudentTermByDomain({ usePlural: true }),
            })}
          </StyledTitle>
          <StyledSelect
            ariaLabel=""
            options={[
              {
                label: t('writingResults.progress.all'),
                value: 'all',
              },
              {
                label: t('writingResults.progress.didNotStart'),
                value: 'did-not-start',
              },
              {
                label: t('writingResults.progress.inProgress'),
                value: 'in-progress',
              },
              {
                label: t('writingResults.progress.ready'),
                value: 'ready',
              },
            ]}
            value={selectedValue}
            onChange={e => setSelectedValue(e.target.value)}
          />
          <StyledStudentListSectionWrapper>
            {(selectedValue === 'all' || selectedValue === 'did-not-start') && (
              <StudentListSection data={studentsNotStarted} stage="not-started" />
            )}
            {(selectedValue === 'all' || selectedValue === 'in-progress') && (
              <StudentListSection data={studentsInProgress} stage="in-progress" />
            )}
            {(selectedValue === 'all' || selectedValue === 'ready') && (
              <StudentListSection data={studentsCompleted} stage="ready" />
            )}
          </StyledStudentListSectionWrapper>
        </StyledInnerDialog>
      </StyledDialog>
    </Modal>
  );
});
