import { useTranslation } from 'react-i18next';

import { Lang } from 'models/language/Language';
import { language as languageConstants } from 'constants/language-constants';
import { useProfile } from 'hooks/useProfile';

export interface LanguageInterface {
  flagIcon: string;
  currentLanguage: keyof Lang;
  changeLanguage: (language: keyof Lang) => void;
}

export const useLanguage = (): LanguageInterface => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language as keyof Lang;
  const flagIcon = languageConstants.flags[currentLanguage];

  const { updateHogesUserProfile } = useProfile();

  const changeLanguage = (language: keyof Lang) => {
    updateHogesUserProfile({ language: language as string });
    // i18n.changeLanguage will be automatically called after language
    // is changed in the profile on the server
  };

  return {
    flagIcon,
    currentLanguage,
    changeLanguage,
  };
};
