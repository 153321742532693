/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useEffect, useState } from 'react';
import { useFeatureFlags } from './useFeatureFlags';
import { useTranslation } from 'react-i18next';
import { useProfile } from './useProfile';
import { useAdaptivePractice } from './useAdaptivePractice';
import { getBucket } from 'math-utils';
import { fetchTallyToken } from 'repositories/TallyRepository/TallyRepository';
import { useParams } from 'react-router-dom';

export type TallyForm = {
  nl: string;
  en: string;
};

interface Params {
  productId?: string;
}

export const useTally = () => {
  const [tallyToken, setTallyToken] = useState<string | null>(null);
  const { tallyForm } = useFeatureFlags();
  const { i18n } = useTranslation('common');
  const { hasPracticed, fetchHasPracticed } = useAdaptivePractice();
  const { userDetails, survey, fetchSurvey, submitSurvey } = useProfile();
  const { en, nl } = JSON.parse(tallyForm) as TallyForm;
  const tallyFormId = i18n.language === 'en' ? en : nl;
  const { productId }: Params = useParams();

  useEffect(() => {
    if (!tallyToken) {
      fetchTallyToken().then(token => setTallyToken(token));
    }
  }, [tallyToken]);

  useEffect(() => {
    if (!tallyFormId || !userDetails?.hashId) return;

    // `getBucket` will return a value between 0 and 11 (same as `getMonth`)
    // Only users who're assigned to the current month will be eligible to see the form
    const bucket = getBucket(userDetails.hashId, 12);
    if (bucket !== new Date().getMonth()) return;

    fetchHasPracticed();
  }, [tallyFormId, userDetails?.hashId]);

  useEffect(() => {
    if (hasPracticed) {
      fetchSurvey();
    }
  }, [hasPracticed]);

  useEffect(() => {
    if (tallyToken && survey?.shouldReceiveSurvey) {
      window.Tally.openPopup(tallyFormId, {
        hiddenFields: {
          userId: userDetails?.id,
          organizationId: userDetails?.schoolNumber,
          surveyId: `he-nps-${tallyFormId}`,
          language: i18n.language,
          token: tallyToken,
          role: userDetails?.role,
          productId: productId,
        },
        onSubmit: () => submitSurvey(),
      });
    }
  }, [survey?.shouldReceiveSurvey, tallyToken]);
};
