import * as Yup from 'yup';

export interface UserUpdateFormFields {
  studentNumber: string;
}

export const buildUpdateFormSchema = (): Yup.SchemaOf<UserUpdateFormFields> =>
  Yup.object({
    studentNumber: Yup.string().required(),
  }).required();
