import styled from 'styled-components/macro';
import { ThemeProps as Props } from 'models/styles/theme';
import { Button } from 'components/Button';

export const StyledFilterButtonContainer = styled.div<Props>`
  margin: 2rem 0;
  text-align: right;
`;

export const StyledFilterButton = styled(Button)<Props>`
  font-size: 1rem;
  font-weight: bold;

  border-radius: 0;

  :first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  :last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`;
