import styled from 'styled-components/macro';
import { ThemeProps as Props } from 'models/styles/theme';
import { FullScreen } from 'react-full-screen';

export const StyledContainer = styled.div<Props>`
  max-width: 976px;
  min-height: 294px;
  width: 70%;
  margin: 40px auto 0 auto;
  padding-bottom: 40px;
  h2 {
    font-weight: 700;
    text-align: center;
  }
`;

export const StyledFullScreen = styled(FullScreen)<Props>`
  background: ${({ theme }: Props) => theme.palette.neutral.white};
  overflow: auto;
`;
