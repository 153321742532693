import styled from 'styled-components/macro';
import { CssFlex, Typography } from 'styles/helpers/layout';
import { ElearningThemeInterface } from 'themes/elearningInterface.theme';

import { Button } from 'components/Button';
import { FontAwesomeIcon } from 'components/FontAwesomeIcon';

interface Props {
  theme: ElearningThemeInterface;
}

export const StyledButton = styled(Button)<Props>`
  width: 9rem;
  padding: 0.7rem 1rem;
  font-size: ${(props: Props) => props.theme.font.size._14};
  font-weight: ${(props: Props) => props.theme.font.weight.bold};
`;

export const StyledProgressMessage = styled(CssFlex)<Props>`
  font-size: 0.875rem;
`;

export const StyledDeterminationStatus = styled(Typography)<Props>`
  font-size: 0.875rem;
  color: ${(props: Props) => props.theme.palette.neutral._900};
  width: 14rem;
`;

export const StyledCompleteIcon = styled(FontAwesomeIcon)<Props>`
  color: ${(props: Props) => props.theme.palette.accent._04};
  font-size: 1.5rem;
  padding: 0.5rem 0;
  align-self: center;
`;
