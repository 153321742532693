import styled from 'styled-components/macro';
import { ThemeProps as Props } from 'models/styles/theme';

import { Paragraph } from 'styles/elements/Texts';

export const StyledNotice = styled.div<Props>`
  padding: 35px;
  background-color: ${(props: Props) => props.theme.palette.feedback.inProgress._200};
  margin-bottom: 32px;
  min-height: 18rem;
`;

export const StyledButtonContainer = styled.div`
  text-align: right;
`;

export const StyledParagraph = styled(Paragraph)`
  line-height: 150%;
`;
