import React from 'react';
import { useTranslation } from 'react-i18next';

import { TestHeader } from 'pages/TestEnvironment/components/TestHeader/TestHeader';
import { CountdownTimer } from 'components/CountdownTimer/CountdownTimer';
import { ResultsHeaderProps } from './ResultsHeader.model';

export const ResultsHeader: React.FC<ResultsHeaderProps> = ({ title, onClose, secondsLeft }) => {
  const { t } = useTranslation('test-environment');
  return (
    <TestHeader
      rightComponent={
        <CountdownTimer
          prefix={t('navbar.timeLeft', 'Time left: ')}
          secondsLeft={secondsLeft}
          warningTimeSeconds={10 * 60}
        />
      }
      title={title}
      onClose={onClose}
    />
  );
};
