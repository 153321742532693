import styled from 'styled-components/macro';

import { ThemeProps } from 'models/styles/theme';
import { calculateRem, calculateRems } from 'styles/mixins/calculateRem';

import { CardStatus } from 'components/CardStatus/CardStatus';

type Props = ThemeProps;

export const StyledDiagnosticTestDetailsPage = styled.div`
  width: 100%;
`;

export const StyledCardStatus = styled(CardStatus)<Props>`
  height: auto;
  padding: ${calculateRems([24, 32])};
  grid-template-columns: 70% 1fr 1fr;

  button {
    padding: 0.5rem;
    min-width: ${calculateRem(100)};
    font-weight: ${(props: Props) => props.theme.font.weight.bold};
  }
`;

export const StyledSubtitle = styled.p`
  text-align: center;
  margin-bottom: 5px;
`;
