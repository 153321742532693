import axios, { AxiosError } from 'axios';
import { Registration } from 'models/register/Register';
import { ERROR_TYPES } from 'constants/error-constants';
import { handlingResponse, logError } from 'repositories/utils';
import { RequestError, ApiRequestError as Error } from 'models/error/Error';
import { RegistrationDetailsResponse } from 'models/auth/Login';
import { CreateBasicAccountRequest } from 'models/api/users/request';
import { CreateBasicAccountResponse } from 'models/api/users/response';

const createRegistration = async (
  invitationId: string,
  registration: Registration,
): Promise<RegistrationDetailsResponse> => {
  try {
    return axios.post(`/api/registration/${invitationId}`, registration);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<Error>);
    throw new RequestError({ message, status, type: ERROR_TYPES.CREATE_REGISTRATION_FAILED });
  }
};

// Create a new basic account for the student
const createBasicAccount = async (userData: CreateBasicAccountRequest): Promise<CreateBasicAccountResponse> => {
  try {
    const response = await axios.post('/api/register', userData);
    return handlingResponse<CreateBasicAccountResponse>(
      [200],
      `Error creating basic account for ${userData.email}`,
    )(response);
  } catch (error) {
    const { message, status } = logError(error as AxiosError<Error>);
    throw new RequestError({ message, status, type: ERROR_TYPES.REGISTER_BASIC_ACCOUNT_FAILED });
  }
};

export { createRegistration, createBasicAccount };
