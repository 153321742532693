export interface StoredGoalLevel {
  [key: number]: number;
}

export const getStoredGoalLevelByProductId = (productId: number): null | number => {
  const storedGoalLevels = localStorage.getItem('selectedGoalLevelsByProductId');
  if (!storedGoalLevels) {
    return null;
  } else {
    const parsedStoredGoalLevels: StoredGoalLevel = JSON.parse(storedGoalLevels);
    return parsedStoredGoalLevels[productId];
  }
};

export const storeGoalLevelByProductId = (productId: number, goalLevel: number): void => {
  const storedGoalLevels = localStorage.getItem('selectedGoalLevelsByProductId');
  const parsedStoredGoalLevels: StoredGoalLevel = storedGoalLevels ? JSON.parse(storedGoalLevels) : {};
  localStorage.setItem(
    'selectedGoalLevelsByProductId',
    JSON.stringify({ ...parsedStoredGoalLevels, [productId]: goalLevel }),
  );
};
