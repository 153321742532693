import styled from 'styled-components/macro';
import { calculateRem } from 'styles/mixins/mixins';
import { ThemeProps as Props } from 'models/styles/theme';

export const StyledFooter = styled.footer<Props>`
  width: 100%;
  color: ${(props: Props) => props.theme.palette.neutral._900};
  background: ${(props: Props) => props.theme.palette.neutral.white};
  padding: 1rem 3rem;
  text-align: center;
  font-size: ${calculateRem(12)};

  > a {
    font-size: ${calculateRem(12)};
  }
`;
