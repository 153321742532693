import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import { ROLES } from '@he-learning/service-he-common';
import { ROLE } from 'persistence';

import { useProfile } from 'hooks/useProfile';
import { useRedirect } from 'hooks/useRedirect';

import { HogesLegacyURL } from 'constants/registration-links';
import { serviceENV as getCurrentEnvironment, EnvironmentType } from 'constants/env-constants';

import { RoleComponent } from 'layouts/RoleComponent';

import { StyledSection, StyledButton, StyledText } from './LegacyMenuSection.styled';

export const LegacyMenuSection: React.FC = observer(() => {
  const { redirectToUrl } = useRedirect();
  const { t } = useTranslation('homepage');

  const { userDetails, fetchUserDetails } = useProfile();

  useEffect(() => {
    if (!userDetails) fetchUserDetails();
  }, [userDetails]);

  // Legacy menu section should never show up for non-migrated users
  if (!userDetails?.isMigratedUser) {
    return null;
  }

  const currentEnv = getCurrentEnvironment() as EnvironmentType;

  return (
    <RoleComponent allowedRoles={[ROLES.STUDENT as ROLE, ROLES.TEACHER as ROLE]}>
      <StyledSection>
        <StyledText>{t('sideMenu.legacyResults.title', 'Looking for results from before the migration?')}</StyledText>
        <StyledButton
          dataCy="legacy-hoges-button"
          variant="brand"
          onClick={() => redirectToUrl(HogesLegacyURL[currentEnv])}
        >
          {t('sideMenu.legacyResults.button', 'To the old environment')}
        </StyledButton>
      </StyledSection>
    </RoleComponent>
  );
});
