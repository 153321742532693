import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';

import { useProduct } from 'hooks/useProduct';
import { useProfile } from 'hooks/useProfile';

import { TEACHER_HOME_PAGE_ROUTES } from 'constants/routes';
import { InviteStudentFormSteps } from 'constants/teacher-constants';

import { SteppedForm } from 'components/SteppedForm/SteppedForm';
import { AddProduct, SelectedProduct } from './components/AddProduct/AddProduct';
import { CreateAccount } from './components/CreateAccount/CreateAccount';
import { EmailType } from './components/MultipleEmailInput/MultipleEmailInput';
import { Invitation } from './components/Invitation/Invitation';
import { InviteStudent } from './components/InviteStudent/InviteStudent';
import { useFeatureFlags } from 'hooks/useFeatureFlags';

type StepMethod = 'previous' | 'next';

export const CreateInviteForm: React.FC = observer(() => {
  const history = useHistory();
  const { t } = useTranslation('teacher-dashboard');

  const { userDetails } = useProfile();
  const {
    postInviteStudents,
    studentInvitationDetails,
    productDetails,
    productsList,
    fetchProducts,
    fetchProductDetails,
    setProductDetails,
  } = useProduct();
  const { showStudentGoalLevelSelector } = useFeatureFlags();

  const [currentStep, setCurrentStep] = useState<InviteStudentFormSteps>(InviteStudentFormSteps.INVITE_GREETING);
  const [licenseValidTill, setLicenseValidTill] = useState<Date>();
  const [selectedProducts, setSelectedProducts] = useState<SelectedProduct[]>([]);
  const [selectedEmails, setSelectedEmails] = useState<EmailType[]>([]);
  const [isReadyToInvite, setIsReadyToInvite] = useState<boolean>(false);

  useEffect(() => {
    if (productDetails?.id) {
      // If the user language changed and the user selected a product it we perform another fetch
      // in order to gain the description in the right language
      fetchProductDetails(productDetails.id);
    }
  }, [userDetails?.language]);

  useEffect(() => {
    if (!productsList) fetchProducts();
  }, [productsList]);

  useEffect(() => {
    setProductDetails(undefined);
  }, []);

  const updateToDesiredStep = (stepMethod: StepMethod, currStep: number) => {
    const stepMethodprevious = 'previous';
    switch (currStep) {
      case InviteStudentFormSteps.INVITE_GREETING: {
        setCurrentStep(
          stepMethod === stepMethodprevious
            ? InviteStudentFormSteps.INVITE_GREETING
            : InviteStudentFormSteps.ENTER_STUDENT_EMAILS,
        );
        break;
      }
      case InviteStudentFormSteps.ENTER_STUDENT_EMAILS: {
        setCurrentStep(
          stepMethod === stepMethodprevious
            ? InviteStudentFormSteps.INVITE_GREETING
            : InviteStudentFormSteps.SELECT_INVITE_PRODUCTS,
        );
        break;
      }
      case InviteStudentFormSteps.SELECT_INVITE_PRODUCTS: {
        setCurrentStep(
          stepMethod === stepMethodprevious
            ? InviteStudentFormSteps.ENTER_STUDENT_EMAILS
            : InviteStudentFormSteps.SELECT_INVITE_PRODUCTS,
        );
        break;
      }
      default:
        break;
    }
  };

  const steps: ReactNode[] = useMemo(
    () => [
      <InviteStudent key="invite-student" />,
      <CreateAccount
        key="create-account"
        selectedEmails={selectedEmails}
        onSelectedEmailsChanged={emails => setSelectedEmails(emails)}
      />,
      <AddProduct
        key="add-product"
        products={selectedProducts}
        onSelectedProductChanged={(selectedProds, validTill) => {
          setSelectedProducts(selectedProds);
          setLicenseValidTill(validTill);
          // Here we only fetch the product details is the product length is 1, meaning that a single product
          // has been selected. This is because it doesn't make sense to fetch any product details if a combi
          // solution is selected, since there's no point in showing a skill level selection for a combi solution.
          if (showStudentGoalLevelSelector && selectedProds.length === 1) {
            selectedProds[0].id && fetchProductDetails(selectedProds[0].id);
          }
        }}
        onSelectedSkillLevel={(productId, skillLevel) => {
          const productSkillLevelToUpdate = selectedProducts.find(
            savedProductDetails => savedProductDetails.id === productId,
          );
          if (productSkillLevelToUpdate) {
            productSkillLevelToUpdate.initialSkillLevel = skillLevel;
            setSelectedProducts([
              productSkillLevelToUpdate,
              ...selectedProducts.filter(savedProductDetails => savedProductDetails.id !== productId),
            ]);
          }
          // We don't do anything otherwise because it wouldn't make sense from the application POV
        }}
      />,
    ],
    [selectedEmails, selectedProducts],
  );

  const nextStepDisabled: boolean = useMemo(() => {
    if (currentStep === InviteStudentFormSteps.SELECT_INVITE_PRODUCTS) {
      return selectedProducts.length === 0;
    }
    if (currentStep === InviteStudentFormSteps.ENTER_STUDENT_EMAILS) {
      return selectedEmails.length === 0 || selectedEmails.some(emailObj => emailObj.isIncorrect);
    }
    return false;
  }, [currentStep, selectedEmails, selectedProducts]);

  if (!userDetails?.allowedInviteProducts) {
    return <></>;
  }

  const handleInvitationSubmit = () => {
    if (showStudentGoalLevelSelector) {
      postInviteStudents({
        licenseExpirationDate: format(licenseValidTill ?? new Date(), 'yyyy-MM-dd'),
        productNames: selectedProducts.map(selectedProduct => selectedProduct.name).join(', '),
        productsList: selectedProducts.map(selectedProduct => selectedProduct.id),
        studentsEmailList: selectedEmails.map(emailObj => emailObj.email),
        initialSkillLevel: selectedProducts.length === 1 ? selectedProducts[0].initialSkillLevel : undefined,
      });
    } else {
      postInviteStudents({
        licenseExpirationDate: format(licenseValidTill ?? new Date(), 'yyyy-MM-dd'),
        productNames: selectedProducts.map(selectedProduct => selectedProduct.name).join(', '),
        productsList: selectedProducts.map(selectedProduct => selectedProduct.id),
        studentsEmailList: selectedEmails.map(emailObj => emailObj.email),
      });
    }
  };

  return !isReadyToInvite ? (
    <SteppedForm
      currentStep={currentStep}
      nextStepDisabled={nextStepDisabled}
      prevStepDisabled={currentStep === InviteStudentFormSteps.INVITE_GREETING}
      submitButtonTitle={t('studentLicense.step.nextStep')}
      totalSteps={3}
      onNextStep={currStep => updateToDesiredStep('next', currStep - 1)}
      onPrevStep={currStep => updateToDesiredStep('previous', currStep + 1)}
      onSubmit={() => {
        setProductDetails(undefined);
        setIsReadyToInvite(true);
      }}
    >
      {steps}
    </SteppedForm>
  ) : (
    <Invitation
      existingOwnersCount={studentInvitationDetails?.ownersAmount ?? 0}
      invitedStudentsCount={studentInvitationDetails?.notOwnersAmount ?? 0}
      ownersList={studentInvitationDetails?.ownersList ?? []}
      teacherEmail={userDetails.email}
      onCancel={() => setIsReadyToInvite(false)}
      onClose={() => history.push(TEACHER_HOME_PAGE_ROUTES.SENT_INVITE_LIST)}
      onSubmit={() => handleInvitationSubmit()}
    />
  );
});
