import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  StyledAdaptivePracticeSessionResultsPageContainer,
  StyledAdaptivePracticeSessionResultsPageContent,
  StyledButtonContainer,
  StyledStatistic,
  StyledStatisticCard,
  StyledStatisticDenominator,
  StyledStreak,
  StyledTopicContainer,
  StyledSectionHeading,
} from './AdaptivePracticeSessionResultsPage.styled';
import { Heading1, Heading3 } from 'styles/elements/Headings';
import { Box, CssFlex, CssGrid, Typography } from 'styles/helpers/layout';
import { ProgressBar } from 'components/ProgressBar/ProgressBar';
import { Button } from 'components/Button';
import { SvgIconReact } from 'components/SvgIconReact';
import { EncircledIcon } from 'components/EncircledIcon';
import { buildUrlWithPathParams } from '_helpers/utils/urlBuilder';
import { PRODUCT_PAGES_ROUTES } from 'constants/routes';
import { useHistory, useParams } from 'react-router-dom';
import { AdaptiveTestPageParams } from 'pages/StudentEnvironment/AdaptiveTestPage/AdaptiveTestPage.model';
import { useAdaptivePractice } from 'hooks/useAdaptivePractice';
import { useProduct } from 'hooks/useProduct';
import { LoadingSpinner } from 'components/LoadingSpinner';
import { useProductProgress } from 'hooks/useProductProgress';
import { observer } from 'mobx-react';
import { buildComparedSessionTopicProgress } from './utils';

export const AdaptivePracticeSessionResultsPage: React.FC = observer(() => {
  const { t } = useTranslation('adaptive-test');
  const history = useHistory();
  const { productId, moduleId }: AdaptiveTestPageParams = useParams();

  const { adaptivePracticeSessionResults, resetAdaptivePracticeSessionResults } = useAdaptivePractice();
  const { fetchModuleProgress, moduleSubjectsProgress } = useProductProgress();
  const { productDetails, fetchProductDetails } = useProduct();

  const [showAllImprovedTopics, setShowAllImprovedTopics] = useState(false);

  useEffect(() => {
    if (productId && moduleId) {
      fetchModuleProgress(+productId, +moduleId);
    }
  }, [productId, moduleId]);

  useEffect(() => {
    if (productId) {
      fetchProductDetails(+productId);
    }
  }, [productId]);

  useEffect(() => {
    if (adaptivePracticeSessionResults.answeredQuestions.length === 0) {
      resetAdaptivePracticeSessionResults();
      history.replace(buildUrlWithPathParams(PRODUCT_PAGES_ROUTES.PRODUCT_MODULE_DETAILS, { productId, moduleId }));
    }
  }, [history, adaptivePracticeSessionResults]);

  const handleBackToOverviewClick = () => {
    resetAdaptivePracticeSessionResults();
    return history.push(buildUrlWithPathParams(PRODUCT_PAGES_ROUTES.PRODUCT_MODULE_DETAILS, { productId, moduleId }));
  };

  if (!moduleSubjectsProgress || !productDetails) {
    return <LoadingSpinner />;
  }

  const practicedTopics = buildComparedSessionTopicProgress(
    adaptivePracticeSessionResults,
    moduleSubjectsProgress,
    productDetails,
  );

  // We store the topic progress as soon as it's loaded, so we need to check to make sure the user has actually
  // answered a question for the topic before displaying it in the results
  const negativeProgressTopics = practicedTopics
    .filter(t => !t.isImproved)
    .filter(t => adaptivePracticeSessionResults.answeredQuestions.some(q => q.topicId === t.id));

  const positiveProgressTopics = practicedTopics
    .filter(t => t.isImproved)
    .filter(t => adaptivePracticeSessionResults.answeredQuestions.some(q => q.topicId === t.id));

  return (
    <StyledAdaptivePracticeSessionResultsPageContainer>
      <CssFlex flexGrow={1} justifyContent="center" p={4} pt="4rem">
        <StyledAdaptivePracticeSessionResultsPageContent>
          <Heading1 mb={2} textAlign="center">
            {t('sessionResults.title')}
          </Heading1>
          <Heading3 fontWeight={'300'} mb={4} textAlign="center">
            {t('sessionResults.subtitle')}
          </Heading3>

          <CssGrid gap={1.5} gridTemplateColumns="repeat(3,1fr)">
            <StyledStatisticCard>
              <Typography>{t('sessionResults.statistics.totalTopics')}</Typography>
              <StyledStatistic data-cy="session-results-card-total-topics">
                {negativeProgressTopics.length + positiveProgressTopics.length}
              </StyledStatistic>
            </StyledStatisticCard>
            <StyledStatisticCard>
              <Typography>{t('sessionResults.statistics.totalQuestions')}</Typography>
              <StyledStatistic data-cy="session-results-card-num-questions">
                {adaptivePracticeSessionResults.answeredQuestions.filter(q => q.isAnswerCorrect).length}
                <StyledStatisticDenominator>
                  {` / ${adaptivePracticeSessionResults.answeredQuestions.length}`}
                </StyledStatisticDenominator>
              </StyledStatistic>
            </StyledStatisticCard>
            <StyledStatisticCard>
              <Typography>{t('sessionResults.statistics.highestStreak')}</Typography>
              <StyledStreak>
                <SvgIconReact iconName="flameIcon" />
                <span data-cy="session-results-card-streak">{adaptivePracticeSessionResults.maxStreak}</span>
              </StyledStreak>
            </StyledStatisticCard>
          </CssGrid>

          {negativeProgressTopics.length > 0 && (
            <>
              <StyledSectionHeading>
                <EncircledIcon iconName="fas fa-bullseye" size="small" variant="primary" />
                {t('sessionResults.progressNegative')}
              </StyledSectionHeading>

              <CssGrid data-cy="session-results-negative-progress-list" rowGap={1.5}>
                {negativeProgressTopics.map(topic => {
                  return (
                    <StyledTopicContainer key={topic.id} data-cy={`practice-session-topic-${topic.id}`}>
                      <Box>{topic.title}</Box>
                      <CssGrid alignItems="center" gap={1} gridTemplateColumns="auto 1fr 5rem">
                        <SvgIconReact iconName="chartLineDown" />
                        <ProgressBar currentValue={topic.updatedProgress || 0} />
                        {topic.updatedProgress}% {topic.updatedSkillLevelLabel}
                      </CssGrid>
                    </StyledTopicContainer>
                  );
                })}
              </CssGrid>
            </>
          )}

          {positiveProgressTopics.length > 0 && (
            <>
              <StyledSectionHeading>
                <EncircledIcon iconName="fas fa-thumbs-up" size="small" variant="secondary" />
                {t('sessionResults.progressPositive')}
              </StyledSectionHeading>

              <CssGrid data-cy="session-results-positive-progress-list" rowGap={1.5}>
                {positiveProgressTopics
                  .slice(0, showAllImprovedTopics ? positiveProgressTopics.length : 3)
                  .map(topic => {
                    return (
                      <StyledTopicContainer key={topic.id} data-cy={`practice-session-topic-${topic.id}`}>
                        <Box>{topic.title}</Box>
                        <CssGrid alignItems="center" gap={1} gridTemplateColumns="auto 1fr 5rem">
                          <SvgIconReact iconName="chartLineUp" />
                          <ProgressBar currentValue={topic.updatedProgress || 0} />
                          {topic.updatedProgress}% {topic.updatedSkillLevelLabel}
                        </CssGrid>
                      </StyledTopicContainer>
                    );
                  })}
              </CssGrid>
            </>
          )}

          {!showAllImprovedTopics && positiveProgressTopics.length > 3 && (
            <CssFlex justifyContent="center" mt={5}>
              <Button hasIconRight variant="text" onClick={() => setShowAllImprovedTopics(true)}>
                {t('sessionResults.showMoreTopicsButton')}
                <SvgIconReact iconName="downArrow" />
              </Button>
            </CssFlex>
          )}
        </StyledAdaptivePracticeSessionResultsPageContent>
      </CssFlex>
      <StyledButtonContainer>
        <Button hasIconRight variant="primary" onClick={handleBackToOverviewClick}>
          {t('sessionResults.backToOverviewButton')}
        </Button>
      </StyledButtonContainer>
    </StyledAdaptivePracticeSessionResultsPageContainer>
  );
});
