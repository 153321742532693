import ReactTooltip from 'react-tooltip';
import styled from 'styled-components/macro';

import { calculateRem } from 'styles/mixins/mixins';

import { ThemeProps as Props } from 'models/styles/theme';

export const StyledTooltip = styled(ReactTooltip)<Props>`
  padding: 1rem !important;
  width: fit-content;
  max-width: 20rem;

  z-index: 2000 !important;
  opacity: 0.9 !important;
  color: ${(props: Props) => props.theme.palette.neutral.white} !important;
  background: ${(props: Props) => props.theme.palette.neutral.black} !important;

  font-size: ${calculateRem(14)};
  text-align: center;
  font-weight: ${(props: Props) => props.theme.font.weight.bold} !important;

  ::after {
    display: none;
  }
`;
