import React, { FC, useContext, useEffect, useState } from 'react';
import { Packer } from 'docx';
import saveAs from 'file-saver';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ThemeContext } from 'styled-components/macro';
import { useWriting } from 'hooks/useWriting';

import { QuestionsManagerPathParams } from 'layouts/QuestionManager/QuestionManager.model';
import { ProgressiveDownloadQuestion } from 'models/exam/Exam';
import { EmbeddedHtml } from 'components/EmbeddedHtml';
import { SvgIconReact } from 'components/SvgIconReact';

import { ElearningThemeInterface } from 'themes/elearningInterface.theme';
import { createDocx } from './docx-generator';
import { DownloadButtonCardMixin, StyledDownloadButton } from './ProgressiveDocumentQuestion.styled';
import { useWritingTest } from 'hooks/useWritingTest';

interface ProgressiveDocumentQuestionProps {
  question: ProgressiveDownloadQuestion;
}

export const ProgressiveDocumentQuestion: FC<ProgressiveDocumentQuestionProps> = observer(({ question }) => {
  const { overviewQuestionResults, fetchOverviewQuestionResults, submitOverviewQuestionResults } = useWriting();
  const { writingTest, fetchWritingTest } = useWritingTest();
  const { productId, exerciseId }: QuestionsManagerPathParams = useParams();
  const theme = useContext<ElearningThemeInterface>(ThemeContext);

  const [hasClickedDownload, setHasClickedDownload] = useState(false);
  const { fileName } = question.metadata;

  useEffect(() => {
    productId && exerciseId && fetchWritingTest(+productId, +exerciseId);
  }, [productId, exerciseId]);

  useEffect(() => {
    fetchOverviewQuestionResults(+productId, question.id, writingTest?.token || '');
  }, [question.id, writingTest]);

  const { t } = useTranslation('skill-determination-test');

  const onSavedDocx = () => {
    setHasClickedDownload(true);
    writingTest && submitOverviewQuestionResults(+productId, writingTest.id, question.id, [], writingTest?.token || '');
  };

  const saveDocx = (onSaved: () => void) => {
    if (!question?.prevQuestions) return;

    const doc = createDocx(overviewQuestionResults.flatMap(a => a.answers));
    Packer.toBlob(doc).then((blob: Blob) => {
      saveAs(blob, fileName);
      onSaved();
    });
  };

  return (
    <>
      <EmbeddedHtml rawHtml={question.content} />
      <DownloadButtonCardMixin>
        <SvgIconReact iconName="fileWord" size={theme.font.iconSize._70} />
        <div>
          <h3>{fileName}</h3>
          <span>{t('word.download')}</span>
        </div>
        <StyledDownloadButton
          aria-label={t('word.download')}
          data-cy="download-progressive-question"
          onClick={() => saveDocx(onSavedDocx)}
        >
          {hasClickedDownload ? (
            <SvgIconReact
              dataCy="download-clicked-check-icon"
              iconName="greenCircleCheckIcon"
              size={theme.font.iconSize._40}
            />
          ) : (
            <SvgIconReact iconName="downloadIcon" size={theme.font.iconSize._40} />
          )}
        </StyledDownloadButton>
      </DownloadButtonCardMixin>
    </>
  );
});
