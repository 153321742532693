import React from 'react';
import { Modal } from 'components/Modal';
import { modalIds } from 'constants/modals-constants';
import { useModal } from 'hooks/useModal';
import { ViewTheory, ViewTheoryProps } from './ViewTheory';

export const ViewTheoryModal: React.FC<ViewTheoryProps> = props => {
  const { closeModal, isModalOpen } = useModal();
  const viewTheoryModalId = modalIds.viewTheoryModal;
  return (
    <Modal dataCy={viewTheoryModalId} isOpen={isModalOpen(viewTheoryModalId)} onClose={closeModal}>
      <ViewTheory {...props} />
    </Modal>
  );
};
