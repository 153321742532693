import React, { FC } from 'react';
import { CssFlex } from 'styles/helpers/layout';
import { Cell, Pie, PieChart } from 'recharts';
import { CustomLabelProps, PROGRESS_TYPE, SubjectDoughnutChartProps } from './SubjectDoughnutChart.model';
import { useTheme } from 'styled-components/macro';
import { ElearningThemeInterface } from 'themes/elearningInterface.theme';

export const SubjectDoughnutChart: FC<SubjectDoughnutChartProps> = ({ subjectDataForChart }) => {
  const theme = useTheme() as ElearningThemeInterface;
  const CustomLabel = ({ cx, cy, value, type, title }: CustomLabelProps) => {
    if (type === PROGRESS_TYPE.UNCOMPLETED) return null;
    return (
      <>
        <text
          data-cy="average-progress"
          dy={8}
          fill={theme.palette.text.secondary}
          fontWeight="bold"
          textAnchor="middle"
          x={cx}
          y={cy}
        >
          {`${value}%`}
        </text>
        <text dy={8} fill={theme.palette.text.secondary} fontWeight="bold" textAnchor="middle" x={cx} y={+cy + 70}>
          {title}
        </text>
      </>
    );
  };

  return (
    <CssFlex data-cy="card-large" justifyContent="space-evenly" p={20}>
      {subjectDataForChart.map((sd, sdIndex) => (
        <PieChart key={sdIndex} height={180} width={220}>
          <Pie
            cx={100}
            cy={70}
            data={sd}
            dataKey="value"
            endAngle={-270}
            innerRadius={45}
            isAnimationActive={false}
            label={({ cx, cy, value, type, subjectTitle }) => (
              <CustomLabel cx={cx} cy={cy} title={subjectTitle} type={type} value={value} />
            )}
            labelLine={false}
            outerRadius={60}
            startAngle={90}
          >
            {sd.map(({ color }, cellIndex) => (
              <Cell key={`cell-${cellIndex}`} fill={color} stroke={color} />
            ))}
          </Pie>
        </PieChart>
      ))}
    </CssFlex>
  );
};
