import React, { ReactNode } from 'react';

import { IconComponentName } from 'components/SvgIconReact/IconNames';

import { StyledContainer, StyledIcon, StyledLabel, StyledValue } from './TestMetadata.styled';

interface TestMetadataProps {
  label: string;
  value: string | number | ReactNode;
  iconName?: IconComponentName;
}

export const TestMetadata: React.FC<TestMetadataProps> = ({ iconName = 'infoIcon', label, value }) => (
  <StyledContainer>
    <StyledIcon iconName={iconName} size={1.5} />
    <StyledLabel>{label}</StyledLabel>
    <StyledValue>{value}</StyledValue>
  </StyledContainer>
);
