import styled from 'styled-components/macro';
import { ThemeProps as Props } from 'models/styles/theme';
import { CssFlex } from 'styles/helpers/layout';

export const StyledWrapper = styled.div<Props>`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const StyledFilterContainer = styled(CssFlex)`
  > * {
    width: 13rem;
  }
`;
