import { ROLES } from '@he-learning/service-he-common';
import { ZendeskSettings, ZendeskSuggestions } from './ZendeskWidget.model';

export enum SupportWidgetVariant {
  DEFAULT,
  BETERSCHRIJVEN,
}

export interface SupportWidgetConfig {
  options: ZendeskSettings;
  suggestions: ZendeskSuggestions;
}

export interface SupportWidgetVariantConfig {
  apiKey: string;
  configPerRole: SupportWidgetVariantConfigPerRole;
}

const supportedWidgetUserRoles = [ROLES.STUDENT, ROLES.TEACHER] as const;
export type WidgetUserRoles = (typeof supportedWidgetUserRoles)[number];

export const isValidWidgetUserRole = (userRole: string): boolean => {
  return supportedWidgetUserRoles.indexOf(userRole) != -1;
};

export type SupportWidgetVariantConfigPerRole = Record<WidgetUserRoles, SupportWidgetConfig>;
