import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import { useWriting } from 'hooks/useWriting';

import { EmailQuestionProps } from './EmailQuestion.model';
import { EmailPreview } from './components/EmailPreview/EmailPreview';

import { CssFlex } from 'styles/helpers/layout';
import { StyledSingleSelectQuestion } from './EmailQuestion.styled';

// TODO: This should not be a question type...
// It's an exercise type, and current implementation complicates things because we
// recreate the whole preview everytime we move on to a different question
// We could avoid using mobx to store the preview data by having a wrapper for the exercise
// Component "WritingExercisePage" (and not "QuestionManager") should be the one to contain
// the layout of this email exercise type
// ("content" prop of HstContentPlayer should vary depending on exercise type)
export const EmailQuestion: React.FC<EmailQuestionProps> = observer(
  ({
    question,
    exercise,
    status,
    answerFeedbackComponent,
    hideAnswers,
    evaluateAnswer,
    results,
    isEvaluatingAnswer,
    prevAnswerValues,
    showLoadingButton,
    answerOptions,
  }) => {
    const { questionAnswerTextsForEmailPreview, setQuestionAnswerTextsForEmailPreview } = useWriting();

    useEffect(() => {
      if (results && results.length) {
        results.map(result => {
          if (result.validAnswers && result.validAnswers.length > 0 && result.validAnswers[0].value) {
            const correctAnswerId = result.validAnswers[0].value;
            const correctAnswer = answerOptions.find(({ id }) => id === correctAnswerId)?.content || '';

            // Store text of the right answer to render it in the preview
            setQuestionAnswerTextsForEmailPreview({
              ...questionAnswerTextsForEmailPreview,
              [`${question.id}`]: correctAnswer,
            });
          }
        });
      }
    }, [results]);

    return (
      <CssFlex flexDirection="row" gap={2} maxWidth="80rem">
        {/* --- Email preview --- */}
        <EmailPreview exercise={exercise} questionAnswerText={questionAnswerTextsForEmailPreview} />

        {/* --- Active multiple choice question --- */}
        <StyledSingleSelectQuestion
          answerFeedbackComponent={answerFeedbackComponent}
          answerOptions={answerOptions}
          evaluateAnswer={evaluateAnswer}
          hideAnswers={hideAnswers}
          isEvaluatingAnswer={isEvaluatingAnswer}
          prevAnswerValues={prevAnswerValues}
          question={question}
          results={results}
          showLoadingButton={showLoadingButton}
          status={status}
        />
      </CssFlex>
    );
  },
);
