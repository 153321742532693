import React, { useEffect, useState } from 'react';
import format from 'date-fns/format';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { ScheduledTestTimeLeftStatus } from 'constants/exam-constants';
import { useDomainHandler } from 'hooks/useDomainHandler';
import { Button } from 'components/Button';
import { CountdownTimer } from 'components/CountdownTimer/CountdownTimer';
import { TimeLeftProps } from './TimeLeft.model';
import {
  StyledCountdownContainer,
  StyledCountdownText,
  StyledCountdownTimer,
  StyledStartDate,
} from './TimeLeft.styled';
import { GeneralHeader } from '../../../../../LandingPage/components';

export const TimeLeft: React.FC<TimeLeftProps> = observer(
  ({
    currentStatus,
    showCountdownTimer,
    hasAuthorizedSEBKey,
    secondsLeft,
    startDate,
    onBackToHomepage,
    onStartTestClick,
  }) => {
    const { t } = useTranslation('test-environment');
    const scheduledTestNotStarted = currentStatus === ScheduledTestTimeLeftStatus.NOT_STARTED;
    const [testIsOngoing, setTestIsOngoing] = useState(false);
    const { getCustomNameByDomain } = useDomainHandler();

    useEffect(() => {
      // we need to make sure the button will be enabled when the timer finishes
      if (currentStatus === ScheduledTestTimeLeftStatus.ONGOING) {
        setTestIsOngoing(true);
      }
    }, [currentStatus]);
    const secondsLeftToStart = scheduledTestNotStarted ? secondsLeft : 0;

    return (
      <StyledCountdownContainer data-cy={showCountdownTimer ? 'under-10-minutes-left' : 'over-10-minutes-left'}>
        <GeneralHeader />
        <StyledCountdownTimer>
          {showCountdownTimer ? (
            <>
              <CountdownTimer format="examTimer" secondsLeft={secondsLeftToStart} />
              <StyledCountdownText>{t('countdownTimer.toStartTest', '...to start the test')}</StyledCountdownText>
              <Button
                dataCy="start-test-button"
                disabled={!testIsOngoing || !hasAuthorizedSEBKey}
                variant="primary"
                onClick={onStartTestClick}
              >
                {t('countdownTimer.startTestButton.label', 'Start the test')}
              </Button>
            </>
          ) : (
            <>
              <StyledCountdownText>{t('countdownDate.toStartTest', 'The test will start on')}</StyledCountdownText>
              <StyledStartDate>{`${format(new Date(startDate), 'd MMMM y, HH:mm')}h`}</StyledStartDate>
              <Button variant="primary" onClick={onBackToHomepage}>
                {t('countdownDate.returnButton.label', { appName: getCustomNameByDomain() })}
              </Button>
            </>
          )}
        </StyledCountdownTimer>
      </StyledCountdownContainer>
    );
  },
);
