/* eslint-disable react/no-danger */
import React from 'react';
import sanitizeHtml, { IOptions as SanitizeOptions } from 'sanitize-html';

interface SanitizedHtmlProps {
  className?: string;
  dataCy?: string;
  dirtyHtml: string;
  id?: string;
  options?: SanitizeOptions;
  tagName?: keyof JSX.IntrinsicElements;
}

export const SanitizedHtml: React.FC<SanitizedHtmlProps> = ({
  id,
  className,
  dataCy,
  dirtyHtml,
  options: sanitizeOptions,
  tagName,
}) => {
  const clean = sanitizeHtml(dirtyHtml, sanitizeOptions);

  const Tag = tagName || 'div';
  return <Tag className={className} dangerouslySetInnerHTML={{ __html: clean }} data-cy={dataCy} id={id} />;
};
