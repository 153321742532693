import React from 'react';

import { QuestionStatus, QuestionType } from 'constants/exam-constants';
import { ExamQuestionWithAnswers } from 'models/exam/Exam';

import { CorrectTextQuestion } from 'layouts/QuestionManager/components/CorrectTextQuestion/CorrectTextQuestion';
import { SvgIconReact } from 'components/SvgIconReact';
import { AnswerBox } from 'components/AnswerBox';
import { questionSolutionBuilder } from '_helpers/questionHelper';

import { QuestionViewParams, QuestionWithResultProps } from './QuestionWithResult.model';
import { StyledQuestionContainer, StyledResultCircle, StyledSingleSelectQuestion } from './QuestionWithResult.styled';
import { QuestionPropsInterface } from 'layouts/QuestionManager/interfaces/question';

const getQuestionView = ({ question, questionResult: result, isAnswerIncorrect, questionTip }: QuestionViewParams) => {
  const questionStatus = isAnswerIncorrect ? QuestionStatus.INCORRECT : QuestionStatus.CORRECT;

  // In "correct-text" questions, ensure we add "answerCorrect" flag to know if
  // user failed each word
  const parsedResults =
    question.type !== QuestionType.CORRECT_TEXT
      ? result?.solutions
      : result?.solutions.map(solution => {
          const userAnswer = result?.answers.find(({ order }) => order === solution.order);
          return { ...solution, answerCorrect: userAnswer?.answerCorrect };
        });

  const commonProps: Pick<QuestionPropsInterface, 'question' | 'status' | 'results' | 'answerFeedbackComponent'> = {
    question,
    status: questionStatus,
    results: parsedResults,
    answerFeedbackComponent:
      isAnswerIncorrect && questionTip ? (
        <AnswerBox
          showTip
          explanation={questionTip}
          showTheoryButton={false}
          solution={questionSolutionBuilder(result?.solutions ?? [], question)}
          status={questionStatus}
        />
      ) : null,
  };

  switch (question.type) {
    case QuestionType.SINGLE_SELECT:
      return (
        <StyledSingleSelectQuestion
          key={question.id}
          answerOptions={(question as ExamQuestionWithAnswers).options}
          selectedOptionId={
            (question as ExamQuestionWithAnswers).options.find(option => option.id === result?.answers[0]?.value)?.id
          }
          {...commonProps}
        />
      );
    case QuestionType.CORRECT_TEXT:
      return (
        <CorrectTextQuestion
          key={question.id}
          answerCount={question.metadata?.answerCount || 0}
          availableActions={question.metadata?.availableActions}
          userAnswers={result?.answers}
          {...commonProps}
        />
      );
    default:
      return null;
  }
};

export const QuestionWithResult: React.FC<QuestionWithResultProps> = ({
  index,
  question,
  questionResult,
  isAnswerIncorrect,
  questionTip,
}) => (
  <StyledQuestionContainer
    key={question.id}
    $answerIsCorrect={!isAnswerIncorrect}
    $index={index}
    data-cy="question-item"
  >
    {isAnswerIncorrect ? (
      <StyledResultCircle $answerIsCorrect={!isAnswerIncorrect} data-cy="question-incorrect-icon">
        <SvgIconReact iconName="closeX" />
      </StyledResultCircle>
    ) : (
      <StyledResultCircle $answerIsCorrect={!isAnswerIncorrect} data-cy="question-correct-icon">
        <SvgIconReact iconName="checkIcon" />
      </StyledResultCircle>
    )}
    {getQuestionView({
      question,
      questionResult,
      isAnswerIncorrect,
      questionTip,
    })}
  </StyledQuestionContainer>
);
