import React from 'react';
import { useTranslation } from 'react-i18next';
import { SubjectKnowledgeOverviewProps } from './SubjectKnowledgeOverview.model';
import { Card } from 'components/Card/Card';
import { useTheme } from 'styled-components/macro';
import { mapProgressToColor } from 'color-utils';
import { SubjectDoughnutChart } from 'pages/TeacherEnvironment/components/TeacherSection/components/SubjectDoughnutChart/SubjectDoughnutChart';
import { PROGRESS_TYPE } from 'pages/TeacherEnvironment/components/TeacherSection/components/SubjectDoughnutChart/SubjectDoughnutChart.model';
import { ElearningThemeInterface } from 'themes/elearningInterface.theme';
import { Heading3 } from 'styles/elements/Headings';
import { Divider } from 'components/Divider/Divider';

export const SubjectKnowledgeOverview: React.FC<SubjectKnowledgeOverviewProps> = ({ subjects = [] }) => {
  const theme = useTheme() as ElearningThemeInterface;
  const { t } = useTranslation('teacher-dashboard');
  const subjectDataForChart = subjects.map(subject => [
    {
      subjectTitle: subject.title,
      type: PROGRESS_TYPE.COMPLETED,
      value: subject.avgProgress,
      color: mapProgressToColor(subject.avgProgress, theme),
    },
    {
      subjectTitle: subject.title,
      type: PROGRESS_TYPE.UNCOMPLETED,
      value: 100 - subject.avgProgress,
      color: theme.palette.neutral.light_02,
    },
  ]);

  return (
    <Card layout={{ mt: 2, mb: 2 }}>
      <Heading3 fontWeight="normal" pb={2} pl={2} pt={2}>
        {t('basicProgress.overview.knowledgePerSubject.title', 'Knowledge level per subject')}
      </Heading3>
      <Divider width="97%" />
      <SubjectDoughnutChart subjectDataForChart={subjectDataForChart} />
    </Card>
  );
};
