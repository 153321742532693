import React from 'react';

import { ProgressBarProps } from './ProgressBar.model';
import { StyledProgressBar, StyledProgressBarActiveSegment } from './ProgressBar.styled';

export const ProgressBar: React.FC<ProgressBarProps> = ({
  className,
  currentValue,
  maxValue = 100,
  variant = 'default',
}) => {
  const percentage = (currentValue / maxValue) * 100;

  return (
    <StyledProgressBar className={className}>
      <StyledProgressBarActiveSegment $variant={variant} $width={percentage} />
    </StyledProgressBar>
  );
};
