import styled from 'styled-components/macro';
import { ThemeProps as Props } from 'models/styles/theme';
import { FontAwesomeIcon } from 'components/FontAwesomeIcon';
import { Heading4 } from 'styles/elements/Headings';

export interface StyledHeading4Props extends Props {
  marginLeft?: string;
  marginRight?: string;
}

export const StyledNavigationWrapper = styled.div<Props>`
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
`;

export const StyledButtonWrapper = styled.div<Props>`
  display: flex;
  align-items: center;
`;

export const StyledFontAwesomeIcon = styled(FontAwesomeIcon)<Props>`
  padding: 0.6rem 0.8rem;
  background: ${({ theme }: Props) => theme.palette.neutral._200};
  border: none;
  cursor: pointer;
`;

export const StyledHeading4 = styled(Heading4)<StyledHeading4Props>`
  margin-top: 0.6rem;
  margin-left: ${({ marginLeft }: StyledHeading4Props) => marginLeft};
  margin-right: ${({ marginRight }: StyledHeading4Props) => marginRight};
`;
