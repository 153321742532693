export function getNextSibling<T extends Element>(elem: Element, selector: string): T | null {
  // Get the next sibling element
  let sibling = elem.nextElementSibling;

  // If there's no selector, return the first sibling
  if (!selector) return sibling as T;

  // If the sibling matches our selector, use it
  // If not, jump to the next sibling and continue the loop
  while (sibling) {
    if (sibling.matches(selector)) return sibling as T;
    sibling = sibling.nextElementSibling;
  }
  return null;
}
