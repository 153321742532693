import styled from 'styled-components/macro';

import { ThemeProps } from 'models/styles/theme';
import { Button } from 'components/Button';

export interface AnswerButtonProps extends ThemeProps {
  isCheatButton?: boolean;
}

export const StyledAnswerButton = styled(Button)<AnswerButtonProps>`
  font-size: ${(props: ThemeProps) => props.theme.font.size._18};
  ${({ isCheatButton, theme }: AnswerButtonProps) =>
    isCheatButton && `background: ${theme.palette.feedback.error.brand}`};
`;
