import React from 'react';

import { LevelDashGroup } from 'components/LevelDashGroup';
import { Heading2 } from 'styles/elements/Headings';
import { StatusBannerProps } from './StatusBanner.model';
import { StyledBannerContent, StyledStatusBanner, StyledStatusBannerTitle } from './StatusBanner.styled';

export const StatusBanner: React.FC<StatusBannerProps> = ({ dataCy, title, percentage, status }) => (
  <StyledStatusBanner $status={status} data-cy={dataCy}>
    <StyledBannerContent $status={status}>
      <StyledStatusBannerTitle>{title}</StyledStatusBannerTitle>
      <Heading2 data-cy={`${dataCy}-score`}>{`${percentage}%`}</Heading2>
      <LevelDashGroup isStateless dashSize={2} level={percentage} numberOfDashes={5} />
    </StyledBannerContent>
  </StyledStatusBanner>
);
