import styled from 'styled-components/macro';
import { ThemeProps as Props } from 'models/styles/theme';
import { TopicProgressType } from 'models/progress/Progress';
import { StatusTag } from 'components/StatusTag/StatusTag';
import { ElearningThemeInterface } from 'themes/elearningInterface.theme';

export interface ProgressionBoxProps extends Props {
  topicProgressType: TopicProgressType;
}

interface StatusProps {
  statusType: TopicProgressType;
}

export const StyledTable = styled.table<Props>`
  width: 100%;
  margin-top: 2rem;
  border: 1px solid ${({ theme }: Props) => theme.palette.neutral._400};
  border-radius: 4px;
  border-spacing: 0;

  th,
  td {
    padding: 0.75rem 1.5rem;
  }

  thead {
    background: ${({ theme }: Props) => theme.palette.neutral._200};
    th {
      font-weight: bold;
    }
  }

  tbody {
    tr:hover {
      background: ${(props: Props) => props.theme.palette.neutral._100};
    }
  }
`;

const getBackgroundColor = (progressType: TopicProgressType, theme: ElearningThemeInterface): string => {
  if (progressType === 'not-started') {
    return theme.palette.neutral.white;
  }
  if (progressType === 'in-progress') {
    return theme.palette.secondary._200;
  }
  return theme.palette.feedback.success._200;
};

const getLabelColor = (progressType: TopicProgressType, theme: ElearningThemeInterface): string => {
  if (progressType === 'not-started') {
    return theme.palette.neutral.black;
  }
  if (progressType === 'in-progress') {
    return theme.palette.secondary.brand;
  }
  return theme.palette.feedback.success.brand;
};

export const StyledStatusTag = styled(StatusTag)<StatusProps>`
  background: ${({ statusType, theme }) => getBackgroundColor(statusType, theme)};

  > span {
    color: ${({ statusType, theme }) => getLabelColor(statusType, theme)};
  }
`;
