import styled from 'styled-components/macro';

import { ThemeProps as Props } from 'models/styles/theme';

export interface StyledTextProps extends Props {
  isHighlighted: boolean;
}

export const StyledHeadingWrapper = styled.div<Props>`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 1rem;
`;

export const StyledHeadingDescription = styled.span<Props>`
  margin-right: 1rem;
  font-weight: 400;
  font-size: 0.8rem;
  color: ${({ theme }: Props) => theme.palette.neutral._900};
`;

export const StyledTextWrapper = styled.div<Props>`
  max-height: 40rem;
  overflow-y: auto;
  overflow-wrap: break-word;
  ::-webkit-scrollbar {
    width: 0.3rem;
  }

  ::-webkit-scrollbar-track {
    background: ${({ theme }: Props) => theme.palette.neutral._200};
  }

  ::-webkit-scrollbar-thumb {
    background: ${({ theme }: Props) => theme.palette.neutral._600};
  }
`;

export const StyledText = styled.div<Props>`
  padding-right: 1rem;
`;

export const StyledInnerText = styled.div<StyledTextProps>`
  margin-bottom: 2rem;
  border-left: 1px solid
    ${({ isHighlighted, theme }: StyledTextProps) =>
      isHighlighted ? theme.palette.primary.brand : theme.palette.neutral._400};
  padding-left: 1rem;
`;
