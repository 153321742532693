import styled from 'styled-components/macro';
import { ElearningThemeInterface } from 'themes/elearningInterface.theme';
import { Button } from 'components/Button';

interface Props {
  theme: ElearningThemeInterface;
}

export const StyledButton = styled(Button)`
  font-size: ${(props: Props) => props.theme.font.size._18};
`;
