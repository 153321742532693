import { GENERIC_ROUTES } from 'constants/routes';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams, useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import cookieStorage from 'persistence';
import { useProduct } from 'hooks/useProduct';
import { useDomainHandler } from 'hooks/useDomainHandler';
import { Heading3 } from 'styles/elements/Headings';
import { Paragraph } from 'styles/elements/Texts';
import { StyledLogo } from '../RegistrationPage/RegistrationPage.styled';
import { StyledBody, StyledForm, StyledHeader, StyledInnerBody } from './InvitationPage.styled';
import { AccountExists } from './components/AccountExists/AccountExists';
import { CreateAccount } from './components/CreateAccount/CreateAccount';
import { Separator } from './components/Separator/Separator';
import { CssFlex } from 'styles/helpers/layout';

interface ProductInvitationPathParams {
  invitationId: string;
}

export const InvitationPage: React.FC = observer(() => {
  const { t } = useTranslation('auth-pages');
  const history = useHistory();
  const { isBusinessDomain, getCustomLogoByDomain } = useDomainHandler();

  const { invitationId }: ProductInvitationPathParams = useParams();
  const {
    invitationDetails,
    productInvitationAccepted,
    postAcceptProductInvitation,
    setProductInvitationAccepted,
    fetchInvitationDetails,
  } = useProduct();

  useEffect(() => {
    if (cookieStorage.getToken()) {
      postAcceptProductInvitation(invitationId);
    }
  }, [cookieStorage, postAcceptProductInvitation]);

  useEffect(() => {
    if (productInvitationAccepted) {
      history.push(GENERIC_ROUTES.HOME_PAGE);
    }

    return () => {
      setProductInvitationAccepted(false);
    };
  }, [productInvitationAccepted, setProductInvitationAccepted]);

  useEffect(() => {
    fetchInvitationDetails(invitationId);
  }, []);
  return (
    <>
      <StyledHeader>
        <Link to={GENERIC_ROUTES.HOME_PAGE}>
          <StyledLogo iconName={getCustomLogoByDomain()} />
        </Link>
      </StyledHeader>
      <StyledBody>
        <StyledInnerBody>
          <Heading3>{t('invitationPage.title')}</Heading3>
          <Paragraph>
            {t('invitationPage.description', { productNames: invitationDetails?.productNames ?? '' })}
          </Paragraph>
          <StyledForm>
            {/* remove accountExists component on business product domain */}
            {!isBusinessDomain() && (
              <>
                <AccountExists />
                <CssFlex mx={3}>
                  <Separator />
                </CssFlex>
              </>
            )}
            <CreateAccount />
          </StyledForm>
        </StyledInnerBody>
      </StyledBody>
    </>
  );
});
