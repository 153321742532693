import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ROLE } from 'persistence';
import { ROLES } from '@he-learning/service-he-common';

import { UsetifulTour } from 'constants/hst-constants';
import { PRODUCT_PAGES_ROUTES, TEACHER_HOME_PAGE_ROUTES } from 'constants/routes';

import { useUsetiful } from 'hooks/useUsetiful';
import { useDomainHandler } from 'hooks/useDomainHandler';
import { useCurrentPathMatch } from 'hooks/useCurrentPathMatch';

import { Header } from 'components/Header';
import { StudentHeaderProps } from './StudentHeader.model';
import { CssFlex, CssIcon } from 'styles/helpers/layout';
import { StyledOnboardingButton, StyledTeacherEnvButton } from './StudentHeader.styled';
import { RoleComponent } from 'layouts/RoleComponent';
import { trackPlatformEvent } from 'analytics/MetabaseTracker/metabase-tracker';
import { STUDENT_MODULES } from 'analytics/constants/pageTypes';
import { PLATFORM_EVENT_TYPES } from 'analytics/constants/platformEventTypes';

export const StudentHeader: React.FC<StudentHeaderProps> = ({
  userDetails,
  toggleSideMenu,
  updateUserDetails,
  onLogout,
}) => {
  const { t } = useTranslation('homepage');
  const history = useHistory();
  const { isUsetifulTourAPIAvailable, openTour } = useUsetiful();
  const { isBusinessDomain, getStudentTermByDomain } = useDomainHandler();
  const { currentRouteMatchesAny } = useCurrentPathMatch();

  // Onboarding button should only show in some specific routes
  const showOnboardingButton =
    isUsetifulTourAPIAvailable() &&
    currentRouteMatchesAny([PRODUCT_PAGES_ROUTES.PRODUCT_DETAILS, PRODUCT_PAGES_ROUTES.PRODUCT_MODULE_DETAILS]);

  const handleOnboardingButtonClick = () => {
    trackPlatformEvent({
      module: STUDENT_MODULES.DASHBOARD,
      itemId: PLATFORM_EVENT_TYPES.STUDENT_DASHBOARD_EVENT_TYPES.CLICK_BEGIN_ONBOARDING,
      value: JSON.stringify({
        isBusinessDomain: isBusinessDomain(),
      }),
    });
    openTour(isBusinessDomain() ? UsetifulTour.BUSINESS_STUDENT_ONBOARDING : UsetifulTour.REGULAR_STUDENT_ONBOARDING);
  };

  return (
    <Header
      toggleSideMenu={toggleSideMenu}
      updateUserDetails={updateUserDetails}
      userDetails={userDetails}
      onLogout={onLogout}
    >
      {/* For teachers show button to return to teacher env */}
      <RoleComponent allowedRoles={[ROLES.TEACHER as ROLE]}>
        <StyledTeacherEnvButton
          dataCy="student-header-go-to-teacher-env-button"
          size="medium"
          variant="dark"
          onClick={() => history.push(TEACHER_HOME_PAGE_ROUTES.HOME_PAGE)}
        >
          <CssIcon iconName="fas fa-home-alt" iconSize="lg" />
          {t('header.toTeacherEnvButton.title', { studentTerm: getStudentTermByDomain() })}
        </StyledTeacherEnvButton>
      </RoleComponent>
      {/* Button to open onboarding tour */}
      {showOnboardingButton && (
        <StyledOnboardingButton
          dataCy="open-onboarding-tour-button"
          variant="default"
          onClick={handleOnboardingButtonClick}
        >
          <CssFlex alignItems="center" columnGap={0.5}>
            <CssIcon iconName="far fa-question-circle" iconSize="lg" />
            {t('header.openOnboarding')}
          </CssFlex>
        </StyledOnboardingButton>
      )}
    </Header>
  );
};
