import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { TEST_ENVIRONMENT_ROUTES } from 'constants/routes';
import { HogesWebshopURL } from 'constants/registration-links';
import { serviceENV as getCurrentEnvironment, EnvironmentType, isLocal, isTest } from 'constants/env-constants';

import { SvgIconReact } from 'components/SvgIconReact';

import { StyledButtons } from '../../ProductsOwnedPage.styled';
import { StyledButton, StyledButtonText, StyledMessage } from './NoOwnedProducts.styled';

export const NoOwnedProducts: React.FC = () => {
  const { t } = useTranslation('owned-products');

  const history = useHistory();
  const currentEnv = getCurrentEnvironment() as EnvironmentType;

  const redirectToURL = (url: string) => {
    if (!isTest() && !isLocal()) {
      window.location.href = url;
    } else {
      history.push(url);
    }
  };

  return (
    <section data-cy="no-owned-products">
      <StyledMessage>{t('noLicenseFound', 'Je hebt nog geen licentie voor een leermodule.')}</StyledMessage>
      <StyledButtons>
        <StyledButton
          dataCy="go-to-webshop-button"
          fontColor="black"
          variant="secondary"
          onClick={() => redirectToURL(HogesWebshopURL[currentEnv])}
        >
          <SvgIconReact iconName="rightArrow" />
          <StyledButtonText>{t('buttonLabels.buy', 'Ge naar webshop')}</StyledButtonText>
        </StyledButton>
        <StyledButton
          dataCy="go-to-test-environment-button"
          fontColor="black"
          variant="secondary"
          onClick={() => history.push(TEST_ENVIRONMENT_ROUTES.LANDING_PAGE)}
        >
          <SvgIconReact iconName="rightArrow" />
          <StyledButtonText>{t('buttonLabels.test', 'Ik will allen toetsen')}</StyledButtonText>
        </StyledButton>
      </StyledButtons>
    </section>
  );
};
