import styled from 'styled-components/macro';
import { calculateRem } from 'styles/mixins/calculateRem';
import { ElearningThemeInterface } from 'themes/elearningInterface.theme';
import { InfoTooltip } from 'components/InfoTooltip/InfoTooltip';
import { FontAwesomeIcon } from 'components/FontAwesomeIcon';

interface Props {
  theme: ElearningThemeInterface;
}

export interface StyledOverlayProps extends Props {
  $marginTop?: string;
  $paddingTop?: string;
}

export interface InfoIconProps extends Props {
  $marginLeft?: string;
}

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
`;

export const ProductButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 1rem;
  row-gap: 1rem;
`;

export const TestTypeButtons = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
`;

export const StyledErrorMessage = styled.span<Props>`
  background-color: ${(props: Props) => props.theme.palette.feedback.error._200};
  margin-top: ${calculateRem(40)};
  padding: ${calculateRem(20)};
  width: fit-content;
`;

export const StyledInfoIcon = styled(InfoTooltip)<InfoIconProps>`
  display: inline-block;
  margin-left: ${({ $marginLeft }: InfoIconProps) => $marginLeft ?? '2rem'};
  vertical-align: middle;
`;

export const StyledOverlay = styled.div<StyledOverlayProps>`
  position: relative;
  background: white;
  padding: 2rem;
  padding-top: ${({ $paddingTop }: StyledOverlayProps) => $paddingTop ?? '4rem'};
  margin-top: ${({ $marginTop }: StyledOverlayProps) => $marginTop ?? '7rem'};
  width: 36rem;

  > p {
    margin-bottom: 4rem;
  }
`;

export const StyledFontAwesomeIcon = styled(FontAwesomeIcon)<Props>`
  position: absolute;
  right: 1rem;
  top: 1rem;
  color: ${({ theme }: Props) => theme.palette.neutral._600};
  cursor: pointer;
`;
