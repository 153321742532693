import styled from 'styled-components/macro';

import { calculateRem } from 'styles/mixins/mixins';
import { ThemeProps as Props } from 'models/styles/theme';

interface BarProps extends Props {
  $width: number;
}

export const StyledContainer = styled.div<Props>`
  width: 100%;
  display: flex;
  column-gap: 1rem;
  align-items: center;
`;

export const StyledBar = styled.div<Props>`
  height: 0.5rem;
  border-radius: 0.375rem;
  background: ${(props: Props) => props.theme.palette.neutral._400};
`;

export const StyledOuterBar = styled(StyledBar)<Props>`
  flex-grow: 1;
  position: relative;
`;

export const StyledInnerBar = styled(StyledBar)<BarProps>`
  top: 0;
  left: 0;
  bottom: 0;
  position: absolute;
  max-width: 100%;
  width: ${(props: BarProps) => props.$width}%;
`;

export const StyledSuccessBar = styled(StyledInnerBar)<BarProps>`
  z-index: 1;
  background: ${(props: BarProps) => props.theme.palette.feedback.success.brand};
`;

export const StyledFailureBar = styled(StyledInnerBar)<BarProps>`
  z-index: 2;
  background: ${(props: BarProps) => props.theme.palette.feedback.error.brand};
`;

export const StyledStats = styled.section<Props>`
  font-size: ${calculateRem(14)};
  color: ${(props: Props) => props.theme.palette.neutral._900};

  & > strong {
    font-weight: normal;
    color: ${(props: Props) => props.theme.palette.neutral.black};
  }
`;
