import React from 'react';

import { LoadingSpinnerWrapper } from './LoadingSpinner.styled';
import LoadingSpinnerProps from './LoadingSpinner.model';

export const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({ size = 80, color, stroke, isFullScreen = false }) => (
  <LoadingSpinnerWrapper
    $isFullScreen={isFullScreen}
    color={color}
    data-cy="loading-spinner"
    size={size}
    stroke={stroke ?? Math.ceil(size / 10)}
  >
    <div />
  </LoadingSpinnerWrapper>
);
