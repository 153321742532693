import { StudentSubjectProgress } from 'hooks/useProductProgress';
import { ComparedSessionTopicProgress } from './AdaptivePracticeSessionResultsPage.model';
import { ProductDetails } from 'models/product/ProductDetails';
import { AdaptivePracticeSessionResults } from 'hooks/useAdaptivePractice';

export const buildComparedSessionTopicProgress = (
  sessionResults: AdaptivePracticeSessionResults,
  moduleSubjectsProgress: StudentSubjectProgress[],
  productDetails: ProductDetails | undefined,
): ComparedSessionTopicProgress[] => {
  const allModuleTopics = moduleSubjectsProgress
    .flatMap(x => x.normalTopics)
    .concat(moduleSubjectsProgress.flatMap(x => x.themeAssignments));

  const practicedTopics: ComparedSessionTopicProgress[] = [];

  sessionResults.initialTopicProgress.forEach(topic => {
    const currentProgress = allModuleTopics.find(x => x.id === topic.id);
    // The case that we don't have updated progress for the topic, namely if the topic in question
    // is a theme assignment
    if (!currentProgress?.progress || !currentProgress?.skillLevel) return;

    const initialProgress = topic.currentProgress;
    const initialSkillLevel = topic.currentUserLevel?.level;
    const updatedProgress = currentProgress?.progress;
    const updatedSkillLevel = currentProgress?.skillLevel;

    let isImproved = false;

    if (initialSkillLevel && updatedSkillLevel) {
      // user improved skill level during session
      if (updatedSkillLevel > initialSkillLevel) {
        isImproved = true;
      } else if (updatedProgress && updatedSkillLevel === initialSkillLevel) {
        // user just improved percentage progress for skill level during session
        isImproved = updatedProgress > initialProgress;
      }
    }

    practicedTopics.push({
      id: topic.id,
      title: topic.title,
      initialProgress,
      initialSkillLevel,
      updatedProgress,
      updatedSkillLevel,
      isImproved,
      updatedSkillLevelLabel: productDetails?.levels.find(x => x.level === currentProgress?.skillLevel)?.label || '',
    });
  });

  return practicedTopics;
};
