import React from 'react';

import { TooltipContainerProps } from './TooltipContainer.model';
import { StyledTooltip } from './TooltipContainer.styled';

/**
 *  This component is a wrapper arount the "ReactTooltip" component provided by
 * the "react-tooltip" library, which customizes its styles.
 *
 *  In order to make use of this, it's necessary that we:
 *
 *    1) Add the "data-tip" attribute in the elements where we want a tooltip
 *  to display.
 *
 *    2) Call the "useReactTooltip" hook defined in the "hooks" folder in any
 *  component where a tag with "data-tip" is added, to ensure that the library
 *  is effectively listening to the dynamically added content.
 *
 * @see https://github.com/wwayne/react-tooltip
 */
export const TooltipContainer: React.FC<TooltipContainerProps> = ({ className, id }) => (
  <StyledTooltip multiline className={className} id={id} />
);
